/* eslint-disable no-console */
import { html, css } from 'lit';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { property, state } from 'lit/decorators.js';

import { BaseElement, customElement } from '../../components/base-element';
import { smartService } from '../xstate/smart.xstate';
import { SmartContext } from '../xstate/smart.xstate-utils';

import '../../components/buttons/icon-button/icon-button.wc';

type UniversalUserDropdownTriggerState = {
  firstName?: string;
  lastName?: string;
  profilePhoto?: string;
  email?: string;
};

@customElement('ps-universal-user-dropdown-trigger')
export class UniversalUserDropdownTriggerWC extends BaseElement {
  context: SmartContext;

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  private trigger = 'profile-dropdown-trigger';

  static styles = css`
    :host {
      display: contents;
    }
  `;

  @state() private localState: UniversalUserDropdownTriggerState = {
    firstName: 'FirstName',
    lastName: 'LastName',
    profilePhoto: undefined,
    email: undefined,
  };

  constructor() {
    super();
    this.init = this.init.bind(this);
  }

  async connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();

    smartService.subscribe((smartState) => {
      const currentUserInfo = this.context?.userInfo;
      this.context = smartState.context;
      if (currentUserInfo !== smartState.context.userInfo) {
        this.init();
      }
    });
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();

    document
      .querySelector(`ps-portal-destination[name="profile-dropdown"]`)
      ?.remove();
  }

  private async init() {
    const { userInfo } = this.context;

    const email = userInfo?.email;

    if (!email) {
      return;
    }

    this.localState = {
      ...this.localState,
      email,
      firstName: userInfo?.firstName || 'FirstName',
      lastName: userInfo?.lastName || 'LastName',
      profilePhoto: userInfo?.picture,
    };
  }

  render() {
    return html`
      <ps-icon-button id=${this.trigger} rounded>
        <ps-avatar
          size="medium"
          src=${ifDefined(this.localState.profilePhoto)}
          label=${`${this.localState.firstName} ${this.localState.lastName}`}
        ></ps-avatar>
      </ps-icon-button>
    `;
  }
}
