import { DomainTypeEnum } from '@pypestream/api-services';
import {
  BlockList,
  Button,
  Icon,
  Modal,
  ModalProps,
  Spacer,
  Stack,
  TextBody,
  TextOverline,
  TextTitle,
} from '@pypestream/design-system';
import {
  TranslationComponent,
  useTranslation,
  TranslationKeys,
} from '@pypestream/translations';
import { createPortal } from 'react-dom';
import { UseFieldArrayReturn } from 'react-hook-form';

import { ProjectEnvironmentField } from '../../../../components/create-project/create-project-flow';
import { FormProjectStateType, ReturnFormType } from '../../../../components';
import { sendManagerEvent } from '../../../../xstate/app.xstate';

type ChannelURLsModalPropsType = {
  form: ReturnFormType;
  modalRef: React.RefObject<ModalProps> | null;
  environment: string;
  environmentField: UseFieldArrayReturn<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    `projectReleaseChannelConfigs.${number}.domains`,
    'id'
  >;
  environmentIndex: number;
  projectId: string | undefined;
};

const defaultDomain = { type: DomainTypeEnum.Domain, url: '' };

export const ChannelURLsModal = ({
  form,
  modalRef,
  environment,
  environmentField,
  environmentIndex,
  projectId,
}: ChannelURLsModalPropsType) => {
  const [t] = useTranslation();
  const { handleSubmit } = form;

  const onSubmit = ({
    projectReleaseChannelConfigs,
    ...restData
  }: FormProjectStateType) => {
    sendManagerEvent({
      type: 'manager.updateProject',
      projectId,
      ...restData,
      releaseChannelConfig: projectReleaseChannelConfigs.map((config) => ({
        domains: config.domains || [],
        description: config.description || '',
        name: config.name,
      })),
    });
    handleModalClose();
  };

  const handleModalClose = () => {
    if (modalRef?.current) {
      modalRef.current.open = false;
      form.reset();
    }
  };

  return (
    <>
      {createPortal(
        <Modal
          ref={modalRef}
          usePortal={false}
          size="large"
          onClose={handleModalClose}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <TextTitle size="small" textAlign="center">
              <TranslationComponent
                i18nKey="manager/projects:projectDetails.environmentURLModal.title"
                values={{
                  environmentName:
                    t(
                      `manager/projects:environments.${environment.toLowerCase()}`,
                      {
                        defaultValue: environment,
                      }
                    ) || '',
                }}
              >
                {`Set ${environment} URLs`}
              </TranslationComponent>
            </TextTitle>
            <Spacer size="small" />
            <TextBody variant="secondary" textAlign="center">
              <TranslationComponent i18nKey="manager/projects:projectDetails.environmentURLModal.subTitle">
                Projects organize work around an online property like a website.
                Everything in the project must be tied to a domain and,
                optionally, its subdomains. You can make changes in project
                settings.
              </TranslationComponent>
            </TextBody>
            <Spacer size="xlarge" />
            <Stack gutter="2xsmall" alignItems="center">
              <Icon
                className={`c-environments-urls__icon c-environments-urls__icon--${environment.toLowerCase()}`}
                name="cloud"
                size="xsmall"
                color="teal"
              />
              <TextOverline>
                <TranslationComponent
                  i18nKey={
                    `manager/projects:environments.${environment.toLowerCase()}` as TranslationKeys
                  }
                >
                  {environment}
                </TranslationComponent>
              </TextOverline>
            </Stack>
            <BlockList gutter="small">
              {environmentField.fields.map(({ id }, index) => (
                <Stack
                  key={id}
                  gutter="2xsmall"
                  alignItems="center"
                  justifyContent="space-between"
                  nowrap
                >
                  <ProjectEnvironmentField
                    name={`projectReleaseChannelConfigs.${environmentIndex}.domains`}
                    index={index}
                    remove={environmentField.remove}
                    form={form}
                    placeholder={
                      index === 0 ? 'https://www.pypestream.com/product/' : ''
                    }
                  />
                </Stack>
              ))}
            </BlockList>
            <Spacer size="small" />
            <Button
              variant="ghost"
              size="large"
              onClick={() => environmentField.append(defaultDomain)}
            >
              <TranslationComponent
                i18nKey="manager/projects:projectDetails.environmentURLModal.addURLButton"
                values={{
                  environmentName:
                    t(
                      `manager/projects:environments.${environment.toLowerCase()}`,
                      {
                        defaultValue: environment,
                      }
                    ) || '',
                }}
              >
                {`Add ${environment} URL`}
              </TranslationComponent>
              <Icon slot="prefix" name="add" />
            </Button>
            <Spacer size="xlarge" />
            <Stack justifyContent="end">
              <Button
                variant="primary"
                size="large"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                <TranslationComponent i18nKey="manager/common:save">
                  Save
                </TranslationComponent>
              </Button>
            </Stack>
          </form>
        </Modal>,
        document.body
      )}
    </>
  );
};
