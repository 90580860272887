import React, { useEffect, useRef, useState } from 'react';
import {
  dateFnsLocales,
  fallbackLanguage,
  TranslationComponent,
  useTranslation,
} from '@pypestream/translations';
import {
  DataTable,
  DataTableWC,
  PageBody,
  PageSection,
  Spacer,
  Stack,
  Text,
  TextTitle,
  Pagination,
} from '@pypestream/design-system';
import { html } from 'lit/static-html.js';
import { formatDistance, parseISO } from 'date-fns';

import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
} from '../../xstate/app.xstate';
import { Loader } from '../../components';
import { IPage } from '../types';

export const LogsPage: React.FC<IPage> = ({ title }) => {
  const [skipPages, setSkipPages] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);

  const onPaginationChange = (_index: number, _size: number) => {
    setSkipPages(_index - 1);
    setPageSize(_size);
  };

  const isNewOrgLoading = useManagerStateMatches(
    'orgRelated.ready.orgs.loading'
  );

  const isReadyToLoad = useManagerStateMatches(
    'orgRelated.ready.currentOrg.selected'
  );
  const loaded = useManagerStateMatches('orgRelated.ready.logs.loaded');

  const { defaultLanguage, auditLogs, orgId } = useManagerCtxSelector(
    (ctx) => ({
      orgId: ctx.selectedOrgId,
      auditLogs: ctx.logs,
      defaultLanguage: ctx.userInfo?.defaultLanguage,
    })
  );

  const { rows: logs, count } = auditLogs;

  const tableRef = useRef<DataTableWC>(null);
  const [t] = useTranslation();

  // logs are updated with any changes made by the user, so we need to update the logs every time we get to the page
  useEffect(() => {
    if (isReadyToLoad) {
      sendManagerEvent({
        type: 'manager.logs.loadLogs',
        queryMetadata: {
          skipPages,
          pageSize,
        },
      });
    }
  }, [isReadyToLoad, pageSize, skipPages, orgId]);

  useEffect(() => {
    if (isNewOrgLoading) {
      setSkipPages(0);
    }
  }, [isNewOrgLoading]);

  const pagination =
    logs && logs.length ? (
      <Pagination
        total={count}
        pageSize={pageSize}
        currentPage={skipPages + 1}
        onChange={({ detail: { page, pageSize: _pageSize } }) =>
          onPaginationChange(page, _pageSize)
        }
        onShowSizeChange={({ detail: { page, pageSize: _pageSize } }) =>
          onPaginationChange(page, _pageSize)
        }
      />
    ) : null;

  return (
    <PageBody background="none">
      <>
        <Stack alignItems="center" justifyContent="space-between">
          <TextTitle>
            <TranslationComponent i18nKey="manager/logs:title">
              {title}
            </TranslationComponent>
          </TextTitle>
        </Stack>
        <Spacer size="2xlarge" />
        <PageSection>
          {!loaded ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Loader relative />
            </div>
          ) : (
            <>
              {pagination}
              <Spacer size="xlarge" />
              <DataTable
                actionable={false}
                data={logs}
                ref={tableRef}
                columns={[
                  {
                    accessorKey: 'action',
                    header: () =>
                      t('manager/logs:table.column1', {
                        defaultValue: 'Action',
                      }) || '',
                    cell: ({ row, renderValue }) => {
                      return html`
                        <ps-text
                          size="2xsmall"
                          font-weight="medium"
                          style="max-width: 400px;"
                          >${row.original?.auditLogDetail
                            ?.displayMessage}</ps-text
                        >
                      `;
                    },
                  },
                  {
                    accessorKey: 'user',
                    header: () =>
                      t('manager/logs:table.column2', {
                        defaultValue: 'User',
                      }) || '',
                    cell: ({ row, renderValue }) => {
                      return html`<ps-stack
                        gutter="small"
                        display="inline-flex"
                        direction="row"
                        nowrap
                        alignItems="center"
                      >
                        <ps-avatar
                          label="${row.original?.user?.firstName} ${row.original
                            ?.user?.lastName}"
                          src="${row.original?.user?.picture || ''}"
                        ></ps-avatar>
                        <div>
                          <ps-text-body variant="secondary"
                            >${row.original?.user?.firstName}
                            ${row.original?.user?.lastName}</ps-text-body
                          >
                          <ps-text-body size="small" variant="secondary"
                            >${row.original?.user?.userName}</ps-text-body
                          >
                        </div>
                      </ps-stack>`;
                    },
                  },
                  {
                    accessorKey: 'when',
                    header: () =>
                      t('manager/logs:table.column3', {
                        defaultValue: 'When',
                      }) || '',
                    cell: ({ row, renderValue }) =>
                      html`<ps-text-body variant="secondary" truncate>
                        ${formatDistance(
                          parseISO(row.original.createdAt),
                          new Date(),
                          {
                            addSuffix: true,
                            locale:
                              dateFnsLocales[
                                defaultLanguage || fallbackLanguage
                              ],
                          }
                        )}
                      </ps-text-body> `,
                  },
                ]}
              ></DataTable>
              <Spacer size="xlarge" />
              {!logs ||
                (!logs.length && (
                  <Text
                    size="xsmall"
                    textAlign="center"
                    className="u-margin-top-xlarge"
                    i18nKey="manager/logs:empty"
                  >
                    No data.
                  </Text>
                ))}
              {pagination}
            </>
          )}
        </PageSection>
      </>
    </PageBody>
  );
};
