/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from 'react';
import {
  NavLogo,
  NavLink,
  NavLinkProps,
  NavLinkWC,
  NavLinks,
  UniversalNav,
  UniversalUserDropdown,
  UniversalUserDropdownTrigger,
} from '@pypestream/design-system';
import {
  TransKeyProp,
  TranslationComponent,
  TranslationKeys,
} from '@pypestream/translations';
import {
  useHref,
  useLinkClickHandler,
  NavLinkProps as NavLinkPropsReactRouter,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { removeTrailingSlash } from '@pypestream/utils';
import { ProductName } from '@pypestream/api-services';

import {
  sendUserEvent,
  useGlobalAppCtxSelector,
  useManagerCtxSelector,
  useManagerStateMatchesOneOf,
} from '../../xstate/app.xstate';
import { OrganizationRoleNames, useAuthRole } from '../../hooks';

export const NavLinkWrapper = React.forwardRef<
  NavLinkProps,
  TransKeyProp &
    NavLinkPropsReactRouter & {
      onClick?: React.MouseEventHandler<NavLinkWC>;
      children?: React.ReactNode;
      isActive?: boolean;
      disabled?: boolean;
      external?: boolean;
      label?: string;
    }
>(
  (
    {
      label,
      onClick,
      replace = false,
      state,
      target,
      to,
      children,
      i18nKey,
      isActive = false,
      disabled = false,
      external = false,
    },
    ref
  ) => {
    const href = useHref(to);
    const handleClick = useLinkClickHandler<NavLinkWC>(to, {
      replace,
      state,
      target,
    });

    return (
      <NavLink
        test-id={`nav-link-${label?.toLowerCase()}`}
        active={isActive}
        href={external ? String(to) : href}
        onClick={(event: React.MouseEvent<NavLinkWC, MouseEvent>) => {
          if (!external) {
            onClick?.(event);
            if (!event.defaultPrevented && !disabled) {
              handleClick(event);
            }
          }
        }}
        ref={ref}
        target={target}
        i18nKey={i18nKey}
        disabled={disabled}
      >
        {children}
      </NavLink>
    );
  }
);

export const PrimaryNav: FC = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const shouldUpdateUser = !!useManagerStateMatchesOneOf([
    'orgRelated.ready.orgs.added',
    'orgRelated.ready.orgs.updated',
    'orgRelated.ready.orgs.deleted',
    'orgRelated.ready.userInfo.refetched',
  ]);

  const { orgName, selectedOrgId, selectedProject, routes } =
    useManagerCtxSelector((ctx) => ({
      orgName: ctx.orgs?.find(({ id }) => id === ctx.selectedOrgId)?.name,
      routes: ctx.routes,
      selectedOrgId: ctx.selectedOrgId,
      isPypestreamEmployee: Boolean(ctx.userInfo?.isPypestreamEmployee),
      selectedProject: ctx.selectedProject,
      defaultLanguage: ctx.userInfo?.defaultLanguage,
    }));

  const { pathname } = useLocation();

  const isAdminRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
  ]);

  const pathAsArray = removeTrailingSlash(pathname).split('/').filter(Boolean);
  const isHomePage = pathname === '/' || pathAsArray.length === 2;

  type NavItem = {
    to: string;
    label: string;
    hidden: boolean;
    i18nKey?: string;
  };

  const featureFlags = useGlobalAppCtxSelector((ctx) => ctx.featureFlags);

  const navItems: NavItem[] = [
    {
      to: routes.wipUsers,
      label: 'WIP Users',
      hidden: !featureFlags?.wipUsers,
    },
    {
      to: routes.wipTeams,
      label: 'WIP Teams',
      hidden: !featureFlags?.wipUsers,
    },
    {
      to: routes.users,
      label: 'Users',
      hidden: false,
      i18nKey: 'manager/common:navMenu.users',
    },
    {
      to: routes.teams,
      label: 'Teams',
      hidden: false,
      i18nKey: 'manager/common:navMenu.teams',
    },
    {
      to: routes.roles,
      label: 'Roles',
      hidden: false,
      i18nKey: 'manager/common:navMenu.roles',
    },
    {
      to: routes.projects,
      label: 'Projects',
      hidden: false,
      i18nKey: 'manager/common:navMenu.projects',
    },
    {
      to: routes.orgs,
      label: 'Orgs',
      hidden: false,
      i18nKey: 'manager/common:navMenu.orgs',
    },
    {
      to: routes.logs,
      label: 'Logs',
      hidden: !isAdminRole,
      i18nKey: 'manager/common:navMenu.logs',
    },
  ].filter((item) => item.hidden === false);

  return (
    <UniversalNav
      app={ProductName.Organization}
      preventRedirect
      shouldUpdateUser={shouldUpdateUser}
      slot="nav"
      org={selectedOrgId}
      project={selectedProject}
      hideGoHome={isHomePage}
      onManagerToolClick={navigate}
    >
      <NavLogo
        href={routes.home}
        slot="logo"
        name={isHomePage ? undefined : 'manager'}
      >
        {isHomePage ? (
          'Pypestream'
        ) : (
          <TranslationComponent i18nKey="manager/common:products.manager">
            Manager
          </TranslationComponent>
        )}
        <span slot="suffix">
          <TranslationComponent i18nKey="manager/common:navMenu.orgPrefix">
            for
          </TranslationComponent>{' '}
        </span>
        <span slot="suffix">{orgName}</span>
      </NavLogo>
      <NavLinks slot="navigation">
        {!isHomePage && (
          <>
            {navItems.map(({ to, label, i18nKey }) => (
              <NavLinkWrapper
                label={label}
                key={label}
                to={to}
                isActive={
                  (pathname.endsWith('edit') && label === 'Orgs') ||
                  pathname.includes(to)
                }
                external={to.includes(routes.admin)}
              >
                {i18nKey ? (
                  <TranslationComponent i18nKey={i18nKey as TranslationKeys}>
                    {label}
                  </TranslationComponent>
                ) : (
                  label
                )}
              </NavLinkWrapper>
            ))}
          </>
        )}
      </NavLinks>

      <UniversalUserDropdownTrigger test-id="user-dropdown-trigger" />

      <UniversalUserDropdown
        onPreferencesClick={() => navigate(routes.myAccount)}
        onSelectionChange={({ detail: { selection: orgId } }) => {
          if (orgId) {
            if (selectedOrgId && !selectedProject) {
              navigate(pathname.replace(selectedOrgId, orgId));
            } else if (selectedProject) {
              navigate(`/organization/${orgId}/projects`);
            } else {
              navigate(`/organization/${orgId}`);
            }
          }
        }}
        onLogout={async () => {
          posthog.reset();
          sendUserEvent('user.signOut');
        }}
      ></UniversalUserDropdown>
    </UniversalNav>
  );
};
