import React, { useEffect, useRef } from 'react';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
  useManagerStateMatchesAllOf,
} from '../../../xstate/app.xstate';
import {
  dateFnsLocales,
  fallbackLanguage,
  TranslationComponent,
  useTranslation,
} from '@pypestream/translations';
import {
  Button,
  ButtonGroup,
  DataTable,
  DataTableWC,
  Grid,
  GridCell,
  Icon,
  PageBody,
  PageSection,
  Search,
  Spacer,
  Stack,
  Text,
  TextTitle,
} from '@pypestream/design-system';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../components';
import { html } from 'lit/static-html.js';
import { formatDistance, parseISO } from 'date-fns';
import { OrganizationRoleNames, useAuthRole } from '../../../hooks';

export const Users: React.FC = () => {
  const loaded = useManagerStateMatches('orgRelated.ready.users.loaded');
  const isReadyToLoad = useManagerStateMatchesAllOf([
    'orgRelated.ready.users.idle',
    'orgRelated.ready.currentOrg.selected',
  ]);

  const { orgId, users, defaultLanguage } = useManagerCtxSelector((ctx) => ({
    orgId: ctx.selectedOrgId,
    users: ctx.users,
    defaultLanguage: ctx.userInfo?.defaultLanguage,
  }));

  const navigate = useNavigate();

  const tableRef = useRef<DataTableWC>(null);
  const [t] = useTranslation();
  const isAdminOrManagerRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
    OrganizationRoleNames.MANAGER,
  ]);

  useEffect(() => {
    if (isReadyToLoad) {
      sendManagerEvent('manager.users.loadUsers');
    }
  }, [isReadyToLoad]);

  console.log(
    '%cusers------------------->',
    'color: green; font-size: larger; font-weight: bold',
    users
  );

  return (
    <PageBody background="none">
      <>
        <Stack alignItems="center" justifyContent="space-between">
          <TextTitle>
            <TranslationComponent i18nKey="manager/users:usersList.users">
              Users
            </TranslationComponent>
          </TextTitle>
          {isAdminOrManagerRole && (
            <ButtonGroup>
              <Button size="large" disabled>
                <Icon slot="prefix" name="plus" size="xsmall" />
                <TranslationComponent i18nKey="manager/users:usersList.createUser">
                  Create User
                </TranslationComponent>
              </Button>
            </ButtonGroup>
          )}
        </Stack>
        <Spacer size="2xlarge" />
        <PageSection>
          {!loaded ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Loader relative />
            </div>
          ) : (
            <>
              <Stack gutter="medium" justifyContent="end">
                <Search
                  size="large"
                  style={{ maxWidth: '400px' }}
                  onInput={(e) => {
                    tableRef.current?.table.setGlobalFilter(
                      String(e.target?.value) || ''
                    );
                  }}
                ></Search>
              </Stack>
              <Spacer size="xlarge"></Spacer>
              <DataTable
                ref={tableRef}
                filterColumns={['name', 'email']}
                columns={[
                  {
                    accessorKey: 'name',
                    accessorFn: (row) => `${row.firstName} ${row.lastName}`,
                    header: () =>
                      t('manager/users:usersList.table.name', {
                        defaultValue: 'Name',
                      }) || '',
                    cell: ({ row, renderValue }) => html`
                      <ps-stack
                        gutter="small"
                        justifyContent="start"
                        alignItems="center"
                        direction="row"
                        nowrap
                      >
                        <ps-avatar
                          label="${row.original.firstName}"
                          src="${row.original.picture || ''}"
                        ></ps-avatar>
                        <ps-stack
                          gutter="none"
                          display="inline-flex"
                          direction="column"
                        >
                          <ps-text size="2xsmall" font-weight="medium"
                            >${`${row.original.firstName} ${row.original.lastName}`}</ps-text
                          >
                        </ps-stack>
                      </ps-stack>
                    `,
                  },
                  {
                    accessorKey: 'email',
                    accessorFn: (row) => row.userName,
                    header: () =>
                      t('manager/users:usersList.table.email', {
                        defaultValue: 'Email',
                      }) || '',
                    cell: ({ row, renderValue }) =>
                      html`<ps-text-body variant="secondary"
                        >${row.original.userName}</ps-text-body
                      >`,
                  },
                  {
                    accessorKey: 'lastActive',
                    header: () =>
                      t('manager/users:usersList.table.lastActive', {
                        defaultValue: 'Last Active',
                      }) || '',
                    cell: ({ row, renderValue }) => {
                      return html`<ps-text-body variant="secondary"
                        >${formatDistance(
                          parseISO(row.original.lastActiveAt),
                          new Date(),
                          {
                            addSuffix: true,
                            locale:
                              dateFnsLocales[
                                defaultLanguage || fallbackLanguage
                              ],
                          }
                        )}</ps-text-body
                      >`;
                    },
                  },
                ]}
                data={users?.map((user) => ({
                  ...user,
                  name: `${user.firstName} ${user.lastName}`,
                }))}
                onRowSelectionChange={(event) => {
                  if (event.detail.selected && event.detail.selected.length) {
                    event.detail.selected.map((item) => {
                      const dataTable = event.currentTarget as DataTableWC;

                      dataTable['table'].resetRowSelection();

                      sendManagerEvent({
                        type: 'manager.selectUser',
                        id: item.original.id,
                      });

                      navigate(
                        `/organization/${orgId}/users/${item.original.id}`
                      );

                      return item;
                    });
                  }
                }}
                enableRowSelection
              ></DataTable>

              {!users ||
                (!users.length && (
                  <Text
                    size="xsmall"
                    textAlign="center"
                    className="u-margin-top-xlarge"
                  >
                    <TranslationComponent i18nKey="manager/users:usersList.createToGetStarted">
                      Create a user to get started.
                    </TranslationComponent>
                  </Text>
                ))}
            </>
          )}
        </PageSection>
      </>
    </PageBody>
  );
};
