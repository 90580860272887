import { datadogLogs } from '@datadog/browser-logs';
import type { ErrorInfo } from 'react';
import { ErrorTags } from './types';

export const formatErrorMessage = (message: string) => {
  const result: {
    msg: string | null;
    jsonMsg: string | null;
    query: string | null;
  } = {
    msg: null,
    jsonMsg: null,
    query: null,
  };

  const startIndexOfJson = message.indexOf('{');

  if (
    startIndexOfJson !== -1 &&
    message.slice(startIndexOfJson).endsWith('}')
  ) {
    result.msg = message.slice(0, startIndexOfJson);
    const jsonMessage = JSON.parse(message.slice(startIndexOfJson));
    result.query = jsonMessage.request.query;
    delete jsonMessage.request.query;
    result.jsonMsg = JSON.stringify(jsonMessage, null, 2);
  } else {
    result.msg = message;
  }

  return result;
};

export const logError = (error: Error, info: ErrorInfo) => {
  console.log(
    '%cERROR!>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',
    'color: red; font-size: larger; font-weight: bold',
    error,
    info?.componentStack
  );

  datadogLogs.logger.error(
    error.message,
    {
      stack: info?.componentStack,
    },
    error
  );
};

export const filterErrorTags = (tags?: ErrorTags | ErrorTags[]) => {
  let userTags: Omit<ErrorTags, ErrorTags.local | ErrorTags.global>[] = [];

  if (tags) {
    userTags = (typeof tags === 'string' ? [tags] : tags).filter(
      (tag) => tag && tag !== ErrorTags.global && tag !== ErrorTags.local
    );
  }

  return userTags;
};
