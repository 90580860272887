import { PropsWithChildren } from 'react';
import { FallbackProps } from 'react-error-boundary';

export type ChildrenProps = PropsWithChildren<Record<string, unknown>>;

export interface ErrorPageProps extends FallbackProps {
  error: Error | [Error];
}

export enum ErrorTags {
  local = 'local-error',
  global = 'global-error',
}
