// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pages from '~react-pages';

import { ReactNode } from 'react';
import { RouteObject } from 'react-router-dom';

import { ProjectDetailsTabs } from '../pages/projects/project-details/project-details';
import { OrganizationRoleNames } from '../hooks';
import { IPage } from '../pages/types';

const updateRouteWithTheProps = <T extends IPage>(
  route: RouteObject,
  customProps: T
) => {
  const { element, ...rest } = route;

  return typeof element === 'object'
    ? {
        ...rest,
        element: {
          ...element,
          props: {
            ...(element as ReactNode & { props: T }).props,
            ...customProps,
          },
        },
      }
    : route;
};

export const reactPages: RouteObject[] = pages.map((route: RouteObject) => {
  if (route.path === 'organization/:org_id/projects') {
    return updateRouteWithTheProps(route, {
      title: 'Projects',
    });
  }

  if (route.path === 'organization/:org_id/projects/:project_id') {
    return updateRouteWithTheProps(route, {
      title: 'Project Details',
      background: 'secondary',
      hasSidenav: true,
      tab: ProjectDetailsTabs.general,
    });
  }

  if (
    route?.path?.includes(
      'organization/:org_id/projects/:project_id/environments'
    )
  ) {
    return updateRouteWithTheProps(route, {
      title: 'Project Details',
      background: 'secondary',
      hasSidenav: true,
      tab: ProjectDetailsTabs.environments,
      hideDeleteButton: true,
    });
  }

  if (
    route.path ===
    'organization/:org_id/projects/:project_id/environments/:environment_id/applets/:applet_id'
  ) {
    return updateRouteWithTheProps(route, {
      title: 'Applet Details',
      background: 'secondary',
      hasSidenav: true,
      tab: ProjectDetailsTabs.environments,
      hideDeleteButton: true,
    });
  }

  if (route.path === 'organization/:org_id/my-account') {
    return updateRouteWithTheProps(route, {
      title: 'My Account',
    });
  }

  if (route.path === 'organization/:org_id/super-admin') {
    return updateRouteWithTheProps(route, {
      title: 'Super Admin',
      accessFor: [OrganizationRoleNames.SUPER_ADMIN],
    });
  }

  if (route.path === 'organization/:org_id/users') {
    return updateRouteWithTheProps(route, {
      title: 'Users',
    });
  }

  if (route.path === 'organization/:org_id/users/:user_id') {
    return updateRouteWithTheProps(route, {
      title: 'User Details',
    });
  }

  if (route.path === 'organization/:org_id/teams') {
    return updateRouteWithTheProps(route, {
      title: 'Teams',
    });
  }

  if (route.path === 'organization/:org_id/teams/:team_id') {
    return updateRouteWithTheProps(route, {
      title: 'Team Details',
    });
  }

  if (route.path === 'organization/:org_id/audit-logs') {
    return updateRouteWithTheProps(route, {
      title: 'Logs',
      accessFor: [
        OrganizationRoleNames.ADMIN,
        OrganizationRoleNames.SUPER_ADMIN,
      ],
    });
  }

  if (route.path === 'organization/:org_id/child-orgs/create') {
    return updateRouteWithTheProps(route, {
      accessFor: [
        OrganizationRoleNames.ADMIN,
        OrganizationRoleNames.SUPER_ADMIN,
      ],
    });
  }

  return route;
});
