import { Spacer } from '@pypestream/design-system';
import { useTranslation } from '@pypestream/translations';
import { useFormContext } from 'react-hook-form';

import { useManagerCtxSelector } from '../../../xstate/app.xstate';
import { OutputProjectFormType } from '../..';
import {
  ProjectDescriptionField,
  ProjectIconField,
  ProjectNameField,
} from '../create-project-flow';
import { ProjectFormButtons } from './project-form-buttons';
import { ProjectTitles } from './project-titles';

export type ProjectInfoPropsType = {
  nextStep: (formData?: Partial<OutputProjectFormType>) => void;
};

export const ProjectInfo = ({ nextStep }: ProjectInfoPropsType) => {
  const { organizationId } = useManagerCtxSelector((ctx) => ({
    organizationId: ctx.selectedOrgId,
  }));

  const [t] = useTranslation();

  const form = useFormContext();

  const { handleSubmit, register } = form;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ProjectTitles
        step="project-info"
        title={
          t('manager/projects:createProject.step1.title', {
            defaultValue: 'Create a project',
          }) || ''
        }
        subTitle={
          t('manager/projects:createProject.subTitle', {
            defaultValue:
              'Projects organize work around an online property like a website. Everything in the project must be tied to a domain and, optionally, its subdomains. You can make changes in project settings.',
          }) || ''
        }
      />
      <Spacer size="xlarge"></Spacer>
      <ProjectNameField _form={form} required />
      <Spacer size="xlarge"></Spacer>
      <ProjectDescriptionField _form={form} {...register('description')} />
      <Spacer size="xlarge"></Spacer>
      <ProjectIconField
        form={form}
        uploadProps={{
          accountId: organizationId,
          label:
            t('manager/projects:createProject.step1.icon', {
              defaultValue: 'Project Icon (optional)',
            }) || '',
        }}
      />
      <Spacer size="xlarge"></Spacer>
      <ProjectFormButtons
        step="project-info"
        nextStep={handleSubmit(nextStep)}
      />
    </form>
  );
};
