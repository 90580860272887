/* eslint-disable @typescript-eslint/no-explicit-any */
import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AppletCategory: { input: any; output: any; }
  AppletStatus: { input: any; output: any; }
  AppletType: { input: any; output: any; }
  ConsentStatus: { input: any; output: any; }
  ContactCenterSyncStatus: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  DomainType: { input: any; output: any; }
  DomainVerificationStatus: { input: any; output: any; }
  JSON: { input: any; output: any; }
  PackageType: { input: any; output: any; }
  PasswordRecoveryStatus: { input: any; output: any; }
  ReleaseChannelStatus: { input: any; output: any; }
  Upload: { input: any; output: any; }
  VariableAccessLevel: { input: any; output: any; }
  app_loader_jsonb: { input: any; output: any; }
  app_loader_uuid: { input: any; output: any; }
  bigint: { input: any; output: any; }
  date: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  node_jsonb_scalar: { input: any; output: any; }
  rich_asset_type_enum: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  timetz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

export enum Accept {
  Applicationjson = 'applicationjson',
  Textcsv = 'textcsv'
}

export type Account = {
  __typename?: 'Account';
  accountManagerId?: Maybe<Scalars['String']['output']>;
  allChildAccounts?: Maybe<Array<Maybe<Account>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  immediateChildAccounts?: Maybe<Array<Maybe<Account>>>;
  isManaged?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentAccount?: Maybe<Account>;
  parentId?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  pictureFile?: Maybe<File>;
  projects?: Maybe<PaginatedProjects>;
  roles?: Maybe<PaginatedRoles>;
  settings?: Maybe<Scalars['JSON']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AccountStatus>;
  teams?: Maybe<PaginatedTeams>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<PaginatedUsers>;
  variables?: Maybe<Array<Maybe<Variable>>>;
};


export type AccountProjectsArgs = {
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type AccountRolesArgs = {
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type AccountTeamsArgs = {
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type AccountUsersArgs = {
  queryMetadata?: InputMaybe<QueryMetadata>;
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Locked = 'LOCKED'
}

export type AccountsFilter = {
  accountName?: InputMaybe<Scalars['String']['input']>;
  includeManaged?: InputMaybe<Scalars['Boolean']['input']>;
  includeUnmanaged?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type Action = {
  __typename?: 'Action';
  appletInstance?: Maybe<AppletInstance>;
  appletInstanceId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latestVersionWhileCreating?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  options?: Maybe<Scalars['JSON']['output']>;
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['String']['output']>;
  packageVersion?: Maybe<PackageVersion>;
  packageVersionId?: Maybe<Scalars['String']['output']>;
};

export type ActionCreate = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  packageId: Scalars['String']['input'];
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
};

export type AgentAssistMetadata = {
  __typename?: 'AgentAssistMetadata';
  isAgent?: Maybe<Scalars['Boolean']['output']>;
  isManager?: Maybe<Scalars['Boolean']['output']>;
};

export type Applet = {
  __typename?: 'Applet';
  appletCategory: AppletCategoryEnum;
  appletType: AppletTypeEnum;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<File>;
  id: Scalars['ID']['output'];
  latestVersion?: Maybe<AppletVersion>;
  latestVersionId?: Maybe<Scalars['String']['output']>;
  pkgName: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versions?: Maybe<Array<Maybe<AppletVersion>>>;
};

export enum AppletCategoryEnum {
  Integration = 'INTEGRATION',
  Interface = 'INTERFACE',
  Other = 'OTHER'
}

/** Boolean expression to compare columns of type "AppletCategory". All fields are combined with logical 'AND'. */
export type AppletCategory_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['AppletCategory']['input']>;
  _gt?: InputMaybe<Scalars['AppletCategory']['input']>;
  _gte?: InputMaybe<Scalars['AppletCategory']['input']>;
  _in?: InputMaybe<Array<Scalars['AppletCategory']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['AppletCategory']['input']>;
  _lte?: InputMaybe<Scalars['AppletCategory']['input']>;
  _neq?: InputMaybe<Scalars['AppletCategory']['input']>;
  _nin?: InputMaybe<Array<Scalars['AppletCategory']['input']>>;
};

export type AppletInstance = {
  __typename?: 'AppletInstance';
  actions: Array<Maybe<Action>>;
  applet: AppletVersion;
  appletVersionId: Scalars['String']['output'];
  condition?: Maybe<Condition>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['JSON']['output']>;
  projectReleaseChannelConfig: ProjectReleaseChannelConfig;
  projectReleaseChannelConfigId: Scalars['String']['output'];
  shortId?: Maybe<Scalars['String']['output']>;
  status: AppletStatusEnum;
  triggers: Array<Maybe<Trigger>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  updatedByUser?: Maybe<User>;
};

export enum AppletStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "AppletStatus". All fields are combined with logical 'AND'. */
export type AppletStatus_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['AppletStatus']['input']>;
  _gt?: InputMaybe<Scalars['AppletStatus']['input']>;
  _gte?: InputMaybe<Scalars['AppletStatus']['input']>;
  _in?: InputMaybe<Array<Scalars['AppletStatus']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['AppletStatus']['input']>;
  _lte?: InputMaybe<Scalars['AppletStatus']['input']>;
  _neq?: InputMaybe<Scalars['AppletStatus']['input']>;
  _nin?: InputMaybe<Array<Scalars['AppletStatus']['input']>>;
};

export enum AppletTypeEnum {
  Analytics = 'ANALYTICS',
  Custom = 'CUSTOM',
  Pype = 'PYPE'
}

/** Boolean expression to compare columns of type "AppletType". All fields are combined with logical 'AND'. */
export type AppletType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['AppletType']['input']>;
  _gt?: InputMaybe<Scalars['AppletType']['input']>;
  _gte?: InputMaybe<Scalars['AppletType']['input']>;
  _in?: InputMaybe<Array<Scalars['AppletType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['AppletType']['input']>;
  _lte?: InputMaybe<Scalars['AppletType']['input']>;
  _neq?: InputMaybe<Scalars['AppletType']['input']>;
  _nin?: InputMaybe<Array<Scalars['AppletType']['input']>>;
};

export type AppletVersion = {
  __typename?: 'AppletVersion';
  applet: Applet;
  appletId: Scalars['String']['output'];
  appletInstances?: Maybe<Array<Maybe<AppletInstance>>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  relatedApplet?: Maybe<Applet>;
  schema: Scalars['JSON']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  version: Scalars['String']['output'];
};

export type Asset = {
  __typename?: 'Asset';
  data: Scalars['JSON']['output'];
};

export type AuditLog = {
  __typename?: 'AuditLog';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['String']['output']>;
  applicationId?: Maybe<Scalars['String']['output']>;
  auditLogDetail?: Maybe<AuditLogDetail>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']['output']>;
  relationDetails?: Maybe<Array<Maybe<AuditLogRelationDetail>>>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type AuditLogDetail = {
  __typename?: 'AuditLogDetail';
  displayMessage?: Maybe<Scalars['String']['output']>;
};

export type AuditLogRelationDetail = {
  __typename?: 'AuditLogRelationDetail';
  displayMessage?: Maybe<Scalars['String']['output']>;
  entities?: Maybe<Array<Maybe<Entity>>>;
  operation?: Maybe<Scalars['String']['output']>;
};

export type AuditLogsFilter = {
  accountId: Scalars['String']['input'];
  actingUserIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDateTime?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDateTime?: InputMaybe<Scalars['String']['input']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type Bot = {
  __typename?: 'Bot';
  data: Scalars['JSON']['output'];
};

export type Bot2Input = {
  actionNodeService?: InputMaybe<Scalars['String']['input']>;
  botTemplateLanguage?: InputMaybe<Scalars['String']['input']>;
  botType?: InputMaybe<Scalars['String']['input']>;
  translateInput?: InputMaybe<Scalars['Boolean']['input']>;
  translateOutput?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BotAssetsInput = {
  assetData: Array<InputMaybe<Scalars['Upload']['input']>>;
};

export enum BotVersion {
  V5 = 'v5'
}

export type BotVersionsInput = {
  basedOn?: InputMaybe<Scalars['String']['input']>;
};

export type CompileInput = {
  templateFile: Scalars['Upload']['input'];
};

export type Condition = {
  __typename?: 'Condition';
  appletInstance?: Maybe<AppletInstance>;
  appletInstanceId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  options?: Maybe<Scalars['JSON']['output']>;
};

export enum ConsentStatusEnum {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Seen = 'SEEN',
  Unknown = 'UNKNOWN'
}

/** Boolean expression to compare columns of type "ConsentStatus". All fields are combined with logical 'AND'. */
export type ConsentStatus_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['ConsentStatus']['input']>;
  _gt?: InputMaybe<Scalars['ConsentStatus']['input']>;
  _gte?: InputMaybe<Scalars['ConsentStatus']['input']>;
  _in?: InputMaybe<Array<Scalars['ConsentStatus']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['ConsentStatus']['input']>;
  _lte?: InputMaybe<Scalars['ConsentStatus']['input']>;
  _neq?: InputMaybe<Scalars['ConsentStatus']['input']>;
  _nin?: InputMaybe<Array<Scalars['ConsentStatus']['input']>>;
};

/** Boolean expression to compare columns of type "ContactCenterSyncStatus". All fields are combined with logical 'AND'. */
export type ContactCenterSyncStatus_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  _gt?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  _gte?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  _in?: InputMaybe<Array<Scalars['ContactCenterSyncStatus']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  _lte?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  _neq?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  _nin?: InputMaybe<Array<Scalars['ContactCenterSyncStatus']['input']>>;
};

export type Country2 = {
  __typename?: 'Country2';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  iso3?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  data: Scalars['JSON']['output'];
};

export type CustomerBotsInput = {
  actionNodeService?: InputMaybe<Scalars['String']['input']>;
  botName?: InputMaybe<Scalars['String']['input']>;
  botTemplateLanguage?: InputMaybe<Scalars['String']['input']>;
  botType?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerPackagesInput = {
  actionNodePackages?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerResourcesInput = {
  actionNodeResources?: InputMaybe<Scalars['String']['input']>;
};

export type CustomersInput = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
};

export type Domain = {
  __typename?: 'Domain';
  id?: Maybe<Scalars['ID']['output']>;
  lastVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<DomainTypeEnum>;
  url?: Maybe<Scalars['String']['output']>;
  verificationStatus?: Maybe<DomainVerificationStatusEnum>;
};

export type DomainInput = {
  type: DomainTypeEnum;
  url: Scalars['String']['input'];
};

export enum DomainTypeEnum {
  Domain = 'DOMAIN',
  SubDomain = 'SUB_DOMAIN'
}

/** Boolean expression to compare columns of type "DomainType". All fields are combined with logical 'AND'. */
export type DomainType_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['DomainType']['input']>;
  _gt?: InputMaybe<Scalars['DomainType']['input']>;
  _gte?: InputMaybe<Scalars['DomainType']['input']>;
  _in?: InputMaybe<Array<Scalars['DomainType']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['DomainType']['input']>;
  _lte?: InputMaybe<Scalars['DomainType']['input']>;
  _neq?: InputMaybe<Scalars['DomainType']['input']>;
  _nin?: InputMaybe<Array<Scalars['DomainType']['input']>>;
};

export enum DomainVerificationStatusEnum {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

export type DomainVerificationStatusInput = {
  id: Scalars['String']['input'];
  verificationStatus?: InputMaybe<DomainVerificationStatusEnum>;
};

/** Boolean expression to compare columns of type "DomainVerificationStatus". All fields are combined with logical 'AND'. */
export type DomainVerificationStatus_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['DomainVerificationStatus']['input']>;
  _gt?: InputMaybe<Scalars['DomainVerificationStatus']['input']>;
  _gte?: InputMaybe<Scalars['DomainVerificationStatus']['input']>;
  _in?: InputMaybe<Array<Scalars['DomainVerificationStatus']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['DomainVerificationStatus']['input']>;
  _lte?: InputMaybe<Scalars['DomainVerificationStatus']['input']>;
  _neq?: InputMaybe<Scalars['DomainVerificationStatus']['input']>;
  _nin?: InputMaybe<Array<Scalars['DomainVerificationStatus']['input']>>;
};

export type Entity = Account | Project | Role | Team | User;

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  publicId: Scalars['String']['output'];
  settings?: Maybe<Scalars['JSON']['output']>;
  url: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['Int']['input']>>;
  _eq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _lte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _neq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type List = {
  __typename?: 'List';
  data?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Locale = {
  __typename?: 'Locale';
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isoCode: Scalars['String']['output'];
  languageCode: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
};

export type LocalizationSettings = {
  __typename?: 'LocalizationSettings';
  country?: Maybe<Country2>;
  countryId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locale?: Maybe<Locale>;
  localeId?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<TimeZone>;
  timeZoneId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type LocalizationSettingsConfig = {
  __typename?: 'LocalizationSettingsConfig';
  project: LocalizationSettingsEntityConfig;
  user: LocalizationSettingsEntityConfig;
};

export type LocalizationSettingsEntityConfig = {
  __typename?: 'LocalizationSettingsEntityConfig';
  supportedLanguageCodes: Array<Scalars['String']['output']>;
};

export type LoginInfo = {
  __typename?: 'LoginInfo';
  email?: Maybe<Scalars['String']['output']>;
  passwordRecoveryStatus?: Maybe<PasswordRecoveryStatusEnum>;
  recommendedAuthMethod?: Maybe<Scalars['String']['output']>;
  registeredAuthMethods?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type OperationResult = {
  __typename?: 'OperationResult';
  entityDisplayName?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Scalars['String']['output']>;
  succeeded?: Maybe<Scalars['Boolean']['output']>;
};

export type OrderBy = {
  columnName?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationMetadata = {
  __typename?: 'OrganizationMetadata';
  accountId?: Maybe<Scalars['ID']['output']>;
  canManageAccess?: Maybe<Scalars['Boolean']['output']>;
  canManageChildOrganization?: Maybe<Scalars['Boolean']['output']>;
  canManageDeletedData?: Maybe<Scalars['Boolean']['output']>;
  canManageIntegrationData?: Maybe<Scalars['Boolean']['output']>;
  canManageProjects?: Maybe<Scalars['Boolean']['output']>;
  canManageSettings?: Maybe<Scalars['Boolean']['output']>;
  canViewAuditLogs?: Maybe<Scalars['Boolean']['output']>;
  canViewSettings?: Maybe<Scalars['Boolean']['output']>;
  isMember?: Maybe<Scalars['Boolean']['output']>;
  resourceLimits?: Maybe<OrganizationResourceLimits>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type OrganizationResourceLimits = {
  __typename?: 'OrganizationResourceLimits';
  agentAssistCurrentInstances?: Maybe<Scalars['Int']['output']>;
  agentAssistCurrentUsers?: Maybe<Scalars['Int']['output']>;
  agentAssistMaxInstances?: Maybe<Scalars['Int']['output']>;
  agentAssistMaxUsers?: Maybe<Scalars['Int']['output']>;
  currentUsers?: Maybe<Scalars['Int']['output']>;
  maxUsers?: Maybe<Scalars['Int']['output']>;
};

export type Package = {
  __typename?: 'Package';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latestVersion?: Maybe<PackageVersion>;
  latestVersionId?: Maybe<Scalars['String']['output']>;
  packageTypes?: Maybe<Array<Maybe<PackageTypeEnum>>>;
  pkgName: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versions?: Maybe<Array<Maybe<PackageVersion>>>;
};

export enum PackageTypeEnum {
  Action = 'ACTION',
  Condition = 'CONDITION',
  Function = 'FUNCTION',
  Trigger = 'TRIGGER',
  Undefined = 'UNDEFINED'
}

/** Boolean expression to compare columns of type "PackageType". All fields are combined with logical 'AND'. */
export type PackageType_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['PackageType']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['PackageType']['input']>>;
  _eq?: InputMaybe<Array<Scalars['PackageType']['input']>>;
  _gt?: InputMaybe<Array<Scalars['PackageType']['input']>>;
  _gte?: InputMaybe<Array<Scalars['PackageType']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['PackageType']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['PackageType']['input']>>;
  _lte?: InputMaybe<Array<Scalars['PackageType']['input']>>;
  _neq?: InputMaybe<Array<Scalars['PackageType']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['PackageType']['input']>>>;
};

export type PackageVersion = {
  __typename?: 'PackageVersion';
  actions?: Maybe<Array<Maybe<Action>>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isLatestInMinor?: Maybe<Scalars['Boolean']['output']>;
  package: Package;
  packageId: Scalars['String']['output'];
  packageJson?: Maybe<Scalars['JSON']['output']>;
  relatedPackage?: Maybe<Package>;
  schema?: Maybe<Scalars['JSON']['output']>;
  triggers?: Maybe<Array<Maybe<Trigger>>>;
  version: Scalars['String']['output'];
};

export type PaginatedAccounts = {
  __typename?: 'PaginatedAccounts';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<Account>>>;
};

export type PaginatedAuditLogs = {
  __typename?: 'PaginatedAuditLogs';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<AuditLog>>>;
};

export type PaginatedProjectTeams = {
  __typename?: 'PaginatedProjectTeams';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<ProjectTeam>>>;
};

export type PaginatedProjectUsers = {
  __typename?: 'PaginatedProjectUsers';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<ProjectUser>>>;
};

export type PaginatedProjects = {
  __typename?: 'PaginatedProjects';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<Project>>>;
};

export type PaginatedRoleMembers = {
  __typename?: 'PaginatedRoleMembers';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<RoleMember>>>;
};

export type PaginatedRoles = {
  __typename?: 'PaginatedRoles';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<Role>>>;
};

export type PaginatedTeamMembers = {
  __typename?: 'PaginatedTeamMembers';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<TeamMember>>>;
};

export type PaginatedTeamProjects = {
  __typename?: 'PaginatedTeamProjects';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<TeamProject>>>;
};

export type PaginatedTeamRoles = {
  __typename?: 'PaginatedTeamRoles';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<TeamRole>>>;
};

export type PaginatedTeams = {
  __typename?: 'PaginatedTeams';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<Team>>>;
};

export type PaginatedUserProjects = {
  __typename?: 'PaginatedUserProjects';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<UserProject>>>;
};

export type PaginatedUserRoles = {
  __typename?: 'PaginatedUserRoles';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<UserRole>>>;
};

export type PaginatedUserTeams = {
  __typename?: 'PaginatedUserTeams';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<UserTeam>>>;
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<User>>>;
};

export enum PasswordRecoveryStatusEnum {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

/** Boolean expression to compare columns of type "PasswordRecoveryStatus". All fields are combined with logical 'AND'. */
export type PasswordRecoveryStatus_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['PasswordRecoveryStatus']['input']>;
  _gt?: InputMaybe<Scalars['PasswordRecoveryStatus']['input']>;
  _gte?: InputMaybe<Scalars['PasswordRecoveryStatus']['input']>;
  _in?: InputMaybe<Array<Scalars['PasswordRecoveryStatus']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['PasswordRecoveryStatus']['input']>;
  _lte?: InputMaybe<Scalars['PasswordRecoveryStatus']['input']>;
  _neq?: InputMaybe<Scalars['PasswordRecoveryStatus']['input']>;
  _nin?: InputMaybe<Array<Scalars['PasswordRecoveryStatus']['input']>>;
};

export type PermissionDetail = {
  __typename?: 'PermissionDetail';
  canAssign?: Maybe<Scalars['Boolean']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['ID']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  sectionName?: Maybe<Scalars['String']['output']>;
};

export type Product = {
  __typename?: 'Product';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<ProductName>;
  settings?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ProductName {
  AgentAssist = 'AGENT_ASSIST',
  Analytics = 'ANALYTICS',
  Organization = 'ORGANIZATION'
}

export type Project = {
  __typename?: 'Project';
  accountId: Scalars['String']['output'];
  agentAssistAccountId?: Maybe<Scalars['String']['output']>;
  agentAssistData?: Maybe<Scalars['JSON']['output']>;
  agentAssistEnabled?: Maybe<Scalars['Boolean']['output']>;
  agentAssistLastSyncedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enableSettingsLink?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  localizationSettings?: Maybe<LocalizationSettings>;
  name: Scalars['String']['output'];
  picture?: Maybe<Scalars['String']['output']>;
  pictureFile?: Maybe<File>;
  projectMetadata?: Maybe<ProjectMetadata>;
  projectProductSettings?: Maybe<Array<Maybe<ProjectProductSetting>>>;
  projectReleaseChannelConfig?: Maybe<Array<Maybe<ProjectReleaseChannelConfig>>>;
  shortId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  users?: Maybe<PaginatedUsers>;
  variables?: Maybe<Array<Maybe<Variable>>>;
};

export type ProjectMetadata = {
  __typename?: 'ProjectMetadata';
  accountId?: Maybe<Scalars['ID']['output']>;
  agentAssist?: Maybe<AgentAssistMetadata>;
  projectId?: Maybe<Scalars['ID']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ProjectProductSetting = {
  __typename?: 'ProjectProductSetting';
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['ID']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  settings?: Maybe<Scalars['JSON']['output']>;
};

export type ProjectReleaseChannelConfig = {
  __typename?: 'ProjectReleaseChannelConfig';
  appletInstances?: Maybe<Array<Maybe<AppletInstance>>>;
  description?: Maybe<Scalars['String']['output']>;
  domains?: Maybe<Array<Maybe<Domain>>>;
  id?: Maybe<Scalars['ID']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  releaseChannel?: Maybe<ReleaseChannel>;
  releaseChannelId?: Maybe<Scalars['ID']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ReleaseChannelStatusEnum>;
  variables?: Maybe<Array<Maybe<Variable>>>;
};


export type ProjectReleaseChannelConfigAppletInstancesArgs = {
  status?: InputMaybe<AppletStatusEnum>;
};

export type ProjectReleaseChannelConfigCreate = {
  description?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<InputMaybe<DomainInput>>>;
  name: Scalars['String']['input'];
  status?: InputMaybe<ReleaseChannelStatusEnum>;
};

export type ProjectRolesUpdate = {
  id?: InputMaybe<Scalars['ID']['input']>;
  rolesToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rolesToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProjectTeam = {
  __typename?: 'ProjectTeam';
  linkIssues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  linkedToContactCenter?: Maybe<Scalars['Boolean']['output']>;
  roles?: Maybe<Array<Maybe<RoleWithSource>>>;
  team?: Maybe<Team>;
};

export type ProjectUser = {
  __typename?: 'ProjectUser';
  linkedToContactCenter?: Maybe<Scalars['Boolean']['output']>;
  roles?: Maybe<Array<Maybe<RoleWithSource>>>;
  user?: Maybe<User>;
};

export type ProjectsFilter = {
  accountId: Scalars['String']['input'];
};

export type QueryMetadata = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  skipPages?: InputMaybe<Scalars['Int']['input']>;
};

export type ReleaseChannel = {
  __typename?: 'ReleaseChannel';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ReleaseChannelRolesUpdate = {
  id?: InputMaybe<Scalars['ID']['input']>;
  rolesToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rolesToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum ReleaseChannelStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "ReleaseChannelStatus". All fields are combined with logical 'AND'. */
export type ReleaseChannelStatus_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['ReleaseChannelStatus']['input']>;
  _gt?: InputMaybe<Scalars['ReleaseChannelStatus']['input']>;
  _gte?: InputMaybe<Scalars['ReleaseChannelStatus']['input']>;
  _in?: InputMaybe<Array<Scalars['ReleaseChannelStatus']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['ReleaseChannelStatus']['input']>;
  _lte?: InputMaybe<Scalars['ReleaseChannelStatus']['input']>;
  _neq?: InputMaybe<Scalars['ReleaseChannelStatus']['input']>;
  _nin?: InputMaybe<Array<Scalars['ReleaseChannelStatus']['input']>>;
};

export type Role = {
  __typename?: 'Role';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']['output']>;
  teamCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userCount?: Maybe<Scalars['Int']['output']>;
};

export type RoleMember = {
  __typename?: 'RoleMember';
  id?: Maybe<Scalars['ID']['output']>;
  isMember?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
};

export type RoleMembersFilter = {
  includeNonMembers: Scalars['Boolean']['input'];
  roleId: Scalars['String']['input'];
};

export type RoleMetadata = {
  __typename?: 'RoleMetadata';
  permissions?: Maybe<Array<Maybe<PermissionDetail>>>;
  roleId?: Maybe<Scalars['ID']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type RoleSource = {
  __typename?: 'RoleSource';
  account: Account;
  description: Scalars['String']['output'];
  project?: Maybe<Project>;
  role: Role;
  team?: Maybe<Team>;
};

export type RoleWithSource = {
  __typename?: 'RoleWithSource';
  role?: Maybe<Role>;
  source?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type RolesFilter = {
  accountId: Scalars['String']['input'];
  includeProjectOnlyRoles?: InputMaybe<Scalars['Boolean']['input']>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  teamName?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RolesMetadata = {
  __typename?: 'RolesMetadata';
  accountId?: Maybe<Scalars['ID']['output']>;
  permissions?: Maybe<Array<Maybe<PermissionDetail>>>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum Set_Data_Action {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type ScheduledJob = {
  __typename?: 'ScheduledJob';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isPaused?: Maybe<Scalars['Boolean']['output']>;
  isSystemJob?: Maybe<Scalars['Boolean']['output']>;
  lastRanAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ScheduledJobRun = {
  __typename?: 'ScheduledJobRun';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  executionDetails?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  scheduledJobId?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  succeeded?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ScheduledJobRunsFilter = {
  onlyShowRunsWithActivity: Scalars['Boolean']['input'];
  onlyShowRunsWithFailure: Scalars['Boolean']['input'];
  scheduledJobId: Scalars['String']['input'];
};

export type ScheduledJobRunsQueryResults = {
  __typename?: 'ScheduledJobRunsQueryResults';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<ScheduledJobRun>>>;
};

export type ScheduledJobsFilter = {
  accountId: Scalars['String']['input'];
};

export type ScheduledJobsQueryResults = {
  __typename?: 'ScheduledJobsQueryResults';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<ScheduledJob>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type Team = {
  __typename?: 'Team';
  agentAssistData?: Maybe<Scalars['JSON']['output']>;
  assignedMembers?: Maybe<Array<Maybe<User>>>;
  assignedProjects?: Maybe<Array<Maybe<Project>>>;
  assignedReleaseChannelConfigs?: Maybe<Array<Maybe<TeamAccountReleaseChannelConfig>>>;
  assignedRoles?: Maybe<Array<Maybe<Role>>>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  defaultAccount?: Maybe<Account>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  memberCount?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissionsSummary?: Maybe<Array<Maybe<TeamPermissionSummaryItem>>>;
  roleCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};


export type TeamPermissionsSummaryArgs = {
  accountId: Scalars['String']['input'];
};

export type TeamAccountReleaseChannelConfig = {
  __typename?: 'TeamAccountReleaseChannelConfig';
  id: Scalars['String']['output'];
  projectReleaseChannelConfig?: Maybe<ProjectReleaseChannelConfig>;
  roles?: Maybe<Array<Maybe<Role>>>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  id?: Maybe<Scalars['ID']['output']>;
  isMember?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
};

export type TeamMembersFilter = {
  excludeOrgAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  excludeSuperAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  includeNonMembers: Scalars['Boolean']['input'];
  teamId: Scalars['String']['input'];
};

export type TeamPermissionSummaryItem = {
  __typename?: 'TeamPermissionSummaryItem';
  accountId?: Maybe<Scalars['ID']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  applicationName?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['ID']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  sectionName?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Array<Maybe<TeamPermissionSummaryItemSource>>>;
};

export type TeamPermissionSummaryItemSource = {
  __typename?: 'TeamPermissionSummaryItemSource';
  accountId?: Maybe<Scalars['String']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
  roleName?: Maybe<Scalars['String']['output']>;
};

export type TeamProductRoles = {
  __typename?: 'TeamProductRoles';
  accountId?: Maybe<Scalars['ID']['output']>;
  hasAgentAssistRoles?: Maybe<Scalars['Boolean']['output']>;
  hasAnalyticsRoles?: Maybe<Scalars['Boolean']['output']>;
  hasOrganizationRoles?: Maybe<Scalars['Boolean']['output']>;
  teamId?: Maybe<Scalars['ID']['output']>;
};

export type TeamProject = {
  __typename?: 'TeamProject';
  accountId?: Maybe<Scalars['ID']['output']>;
  isAssigned?: Maybe<Scalars['Boolean']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']['output']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  teamId?: Maybe<Scalars['ID']['output']>;
};

export type TeamProjectsFilter = {
  includeUnassigned: Scalars['Boolean']['input'];
  teamId: Scalars['String']['input'];
};

export type TeamRole = {
  __typename?: 'TeamRole';
  id?: Maybe<Scalars['ID']['output']>;
  isAssigned?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Role>;
};

export type TeamRolesFilter = {
  includeUnassigned: Scalars['Boolean']['input'];
  teamId: Scalars['String']['input'];
};

export type TeamsFilter = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  includeChildOrgTeams?: InputMaybe<Scalars['Boolean']['input']>;
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TimeZone = {
  __typename?: 'TimeZone';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
};

export type Trigger = {
  __typename?: 'Trigger';
  appletInstance?: Maybe<AppletInstance>;
  appletInstanceId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latestVersionWhileCreating?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  options?: Maybe<Scalars['JSON']['output']>;
  package?: Maybe<Package>;
  packageId?: Maybe<Scalars['String']['output']>;
  packageVersion?: Maybe<PackageVersion>;
  packageVersionId?: Maybe<Scalars['String']['output']>;
};

export type TriggerCreate = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  packageId: Scalars['String']['input'];
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  agentAssistData?: Maybe<Scalars['JSON']['output']>;
  agentAssistUserId?: Maybe<Scalars['Int']['output']>;
  assignedProjects?: Maybe<Array<Maybe<Project>>>;
  assignedReleaseChannelConfigs?: Maybe<Array<Maybe<UserAccountReleaseChannelConfig>>>;
  assignedRoles?: Maybe<Array<Maybe<Role>>>;
  assignedTeams?: Maybe<Array<Maybe<Team>>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  defaultAccount?: Maybe<Account>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kratosIdentity?: Maybe<Scalars['JSON']['output']>;
  lastActiveAt: Scalars['DateTime']['output'];
  lastLoggedInAt?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  localizationSettings?: Maybe<LocalizationSettings>;
  optionalConsentStatus?: Maybe<ConsentStatusEnum>;
  optionalConsentUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  passwordRecoveryStatus?: Maybe<PasswordRecoveryStatusEnum>;
  picture?: Maybe<Scalars['String']['output']>;
  pictureFile?: Maybe<File>;
  requiredConsentStatus?: Maybe<ConsentStatusEnum>;
  requiredConsentUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  sessions?: Maybe<Array<Maybe<UserSession>>>;
  settings?: Maybe<Scalars['JSON']['output']>;
  status: UserStatus;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type UserAccountReleaseChannelConfig = {
  __typename?: 'UserAccountReleaseChannelConfig';
  id: Scalars['String']['output'];
  projectReleaseChannelConfig?: Maybe<ProjectReleaseChannelConfig>;
  roles?: Maybe<Array<Maybe<Role>>>;
};

export type UserProductRoles = {
  __typename?: 'UserProductRoles';
  accountId?: Maybe<Scalars['ID']['output']>;
  agentAssistRolesSource?: Maybe<Array<Maybe<RoleSource>>>;
  analyticsRolesSource?: Maybe<Array<Maybe<RoleSource>>>;
  hasAgentAssistRoles?: Maybe<Scalars['Boolean']['output']>;
  hasAnalyticsRoles?: Maybe<Scalars['Boolean']['output']>;
  hasOrganizationRoles?: Maybe<Scalars['Boolean']['output']>;
  organizationRolesSource?: Maybe<Array<Maybe<RoleSource>>>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type UserProject = {
  __typename?: 'UserProject';
  accountId?: Maybe<Scalars['ID']['output']>;
  isAssigned?: Maybe<Scalars['Boolean']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']['output']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type UserProjectsFilter = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  includeUnassigned: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};

export type UserRole = {
  __typename?: 'UserRole';
  id?: Maybe<Scalars['ID']['output']>;
  isAssigned?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Role>;
};

export type UserRolesFilter = {
  includeUnassigned: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};

export type UserSession = {
  __typename?: 'UserSession';
  authenticatedAt?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  ipAddress?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  userAgent?: Maybe<Scalars['String']['output']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Invited = 'INVITED',
  Locked = 'LOCKED'
}

export type UserTeam = {
  __typename?: 'UserTeam';
  id?: Maybe<Scalars['ID']['output']>;
  isAssigned?: Maybe<Scalars['Boolean']['output']>;
  team?: Maybe<Team>;
};

export type UserTeamsFilter = {
  includeUnassigned: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};

export type UsersFilter = {
  accountId: Scalars['String']['input'];
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  teamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type ValidatedReleaseChannelDomainUrl = {
  __typename?: 'ValidatedReleaseChannelDomainUrl';
  intersections?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isValid: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
  validationResult?: Maybe<Scalars['String']['output']>;
};

export type ValidatedReleaseChannelDomainUrls = {
  __typename?: 'ValidatedReleaseChannelDomainUrls';
  urls?: Maybe<Array<Maybe<ValidatedReleaseChannelDomainUrl>>>;
};

export type Variable = {
  __typename?: 'Variable';
  account?: Maybe<Account>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  project?: Maybe<Project>;
  projectReleaseChannelConfigs?: Maybe<Array<Maybe<ProjectReleaseChannelConfig>>>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
  value: Scalars['String']['output'];
  variableAccessLevel: VariableAccessLevelEnum;
};

export enum VariableAccessLevelEnum {
  Organization = 'ORGANIZATION',
  Project = 'PROJECT'
}

/** Boolean expression to compare columns of type "VariableAccessLevel". All fields are combined with logical 'AND'. */
export type VariableAccessLevel_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['VariableAccessLevel']['input']>;
  _gt?: InputMaybe<Scalars['VariableAccessLevel']['input']>;
  _gte?: InputMaybe<Scalars['VariableAccessLevel']['input']>;
  _in?: InputMaybe<Array<Scalars['VariableAccessLevel']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['VariableAccessLevel']['input']>;
  _lte?: InputMaybe<Scalars['VariableAccessLevel']['input']>;
  _neq?: InputMaybe<Scalars['VariableAccessLevel']['input']>;
  _nin?: InputMaybe<Array<Scalars['VariableAccessLevel']['input']>>;
};

export type VariablesFilter = {
  projectId: Scalars['String']['input'];
  projectReleaseChannelConfigIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variableKeySearch?: InputMaybe<Scalars['String']['input']>;
};

export type Version = {
  __typename?: 'Version';
  data: Scalars['JSON']['output'];
  messages?: Maybe<Scalars['String']['output']>;
};

export type VersionBulkTestInput = {
  bulkTestFile: Scalars['Upload']['input'];
};

export type VersionConfigInput = {
  configFile: Scalars['Upload']['input'];
};

export type VersionDeployInput = {
  environment?: InputMaybe<Scalars['String']['input']>;
};

export type VersionGraphInput = {
  multiLanguageData?: InputMaybe<Scalars['String']['input']>;
  templateData?: InputMaybe<Scalars['String']['input']>;
  templateFeatures?: InputMaybe<Scalars['String']['input']>;
  templateType?: InputMaybe<Scalars['String']['input']>;
  templateVersion?: InputMaybe<Scalars['Int']['input']>;
};

export type VersionScriptsInput = {
  scriptFile: Array<InputMaybe<Scalars['Upload']['input']>>;
};

export type VersionSearchNluTrainInput = {
  stopwordsFile?: InputMaybe<Scalars['Upload']['input']>;
  synonymsFile?: InputMaybe<Scalars['Upload']['input']>;
  templateFile: Scalars['Upload']['input'];
};

export type VersionStopwordsInput = {
  stopwords?: InputMaybe<Scalars['String']['input']>;
};

export type VersionTemplatesInput = {
  templateFile: Scalars['Upload']['input'];
};

export type Admin_Mutation = {
  __typename?: 'admin_Mutation';
  _?: Maybe<Scalars['Boolean']['output']>;
  addUserToAccount?: Maybe<User>;
  createAccount?: Maybe<Account>;
  createAgentAssistProjectIntegration?: Maybe<Scalars['JSON']['output']>;
  createAgentAssistTeamIntegration?: Maybe<Scalars['JSON']['output']>;
  createAgentAssistUserIntegration?: Maybe<Scalars['JSON']['output']>;
  createApplet?: Maybe<Applet>;
  createAppletInstance?: Maybe<AppletInstance>;
  createAppletVersion?: Maybe<AppletVersion>;
  createPackage?: Maybe<Package>;
  createProject?: Maybe<Project>;
  createRole?: Maybe<Role>;
  createScheduledJob?: Maybe<ScheduledJob>;
  createTeam?: Maybe<Team>;
  createVariable?: Maybe<Variable>;
  deactivateAllUserSessions?: Maybe<Scalars['Int']['output']>;
  deactivateUserSession?: Maybe<Scalars['Int']['output']>;
  deleteAccount?: Maybe<Account>;
  deleteApplet?: Maybe<Applet>;
  deleteAppletInstance?: Maybe<AppletInstance>;
  deleteAppletVersion?: Maybe<AppletVersion>;
  deletePackage?: Maybe<Package>;
  deletePackageVersion?: Maybe<PackageVersion>;
  deleteProject?: Maybe<Scalars['Int']['output']>;
  deleteRoles?: Maybe<Array<Maybe<OperationResult>>>;
  deleteTeams?: Maybe<Array<Maybe<OperationResult>>>;
  deleteVariable?: Maybe<Variable>;
  falsifyUserActiveSessions?: Maybe<User>;
  finishUserRecoveryProcess?: Maybe<Scalars['Boolean']['output']>;
  lockAccount?: Maybe<Account>;
  makeDbSnapshot?: Maybe<Scalars['Boolean']['output']>;
  pauseScheduledJob?: Maybe<ScheduledJob>;
  removeUsersFromAccount?: Maybe<Array<Maybe<OperationResult>>>;
  resumeScheduledJob?: Maybe<ScheduledJob>;
  setRolesTeams?: Maybe<Scalars['Int']['output']>;
  setRolesUsers?: Maybe<Scalars['Int']['output']>;
  setTeamAccountProjectsRoles?: Maybe<Team>;
  setTeamAccountReleaseChannelsRoles?: Maybe<Team>;
  setTeamsMembers?: Maybe<Scalars['String']['output']>;
  setTeamsProjects?: Maybe<Scalars['Int']['output']>;
  setTeamsRoles?: Maybe<Scalars['String']['output']>;
  setUserAccountProjectsRoles?: Maybe<User>;
  setUserAccountReleaseChannelsRoles?: Maybe<User>;
  setUserLocalizationSettings?: Maybe<User>;
  setUserPasswordRecoveryStatus?: Maybe<User>;
  setUsersProjects?: Maybe<Scalars['Int']['output']>;
  setUsersRoles?: Maybe<Scalars['Int']['output']>;
  setUsersTeams?: Maybe<Scalars['Int']['output']>;
  startUserRecoveryProcess?: Maybe<Scalars['Boolean']['output']>;
  unlockAccount?: Maybe<Account>;
  updateAccount?: Maybe<Account>;
  updateApplet?: Maybe<Applet>;
  updateAppletInstance?: Maybe<AppletInstance>;
  updateAppletVersion?: Maybe<AppletVersion>;
  updateDomainsVerificationStatus?: Maybe<Array<Maybe<Domain>>>;
  updatePackage?: Maybe<Package>;
  updateProject?: Maybe<Project>;
  updateProjectLocalizationSettings?: Maybe<Project>;
  updateProjectReleaseChannelConfig?: Maybe<ProjectReleaseChannelConfig>;
  updateRole?: Maybe<Role>;
  updateRoleMembers?: Maybe<Role>;
  updateRoleTeams?: Maybe<Role>;
  updateTeam?: Maybe<Team>;
  updateTeamMembers?: Maybe<Team>;
  updateTeamProjects?: Maybe<Team>;
  updateTeamRoles?: Maybe<Team>;
  updateUser?: Maybe<User>;
  updateUserLocalizationSettings?: Maybe<User>;
  updateUserProjects?: Maybe<User>;
  updateUserRoles?: Maybe<User>;
  updateUserTeams?: Maybe<User>;
  updateVariable?: Maybe<Variable>;
  verifyDomain?: Maybe<Scalars['Boolean']['output']>;
};


export type Admin_MutationAddUserToAccountArgs = {
  accountId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  picture?: InputMaybe<Scalars['String']['input']>;
  projectIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projectIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  teamIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  teamIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIconId?: InputMaybe<Scalars['String']['input']>;
  userName: Scalars['String']['input'];
};


export type Admin_MutationCreateAccountArgs = {
  accountIconId?: InputMaybe<Scalars['String']['input']>;
  accountManagerId?: InputMaybe<Scalars['String']['input']>;
  accountName: Scalars['String']['input'];
  generalSettings?: InputMaybe<Scalars['JSON']['input']>;
  parentAccountId: Scalars['String']['input'];
  picture?: InputMaybe<Scalars['String']['input']>;
  resourceLimitSettings?: InputMaybe<Scalars['JSON']['input']>;
  securitySettings?: InputMaybe<Scalars['JSON']['input']>;
};


export type Admin_MutationCreateAgentAssistProjectIntegrationArgs = {
  projectId: Scalars['String']['input'];
};


export type Admin_MutationCreateAgentAssistTeamIntegrationArgs = {
  teamId: Scalars['String']['input'];
};


export type Admin_MutationCreateAgentAssistUserIntegrationArgs = {
  userId: Scalars['String']['input'];
};


export type Admin_MutationCreateAppletArgs = {
  appletCategory: AppletCategoryEnum;
  appletType: AppletTypeEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  iconId?: InputMaybe<Scalars['String']['input']>;
  pkgName: Scalars['String']['input'];
  schema: Scalars['JSON']['input'];
  version: Scalars['String']['input'];
  versionDescription?: InputMaybe<Scalars['String']['input']>;
};


export type Admin_MutationCreateAppletInstanceArgs = {
  actions?: InputMaybe<Array<ActionCreate>>;
  appletVersionId: Scalars['String']['input'];
  condition?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  options?: InputMaybe<Scalars['JSON']['input']>;
  projectReleaseChannelConfigId: Scalars['String']['input'];
  status?: InputMaybe<AppletStatusEnum>;
  triggers?: InputMaybe<Array<TriggerCreate>>;
};


export type Admin_MutationCreateAppletVersionArgs = {
  appletId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  schema: Scalars['JSON']['input'];
  version: Scalars['String']['input'];
};


export type Admin_MutationCreatePackageArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  packageJson: Scalars['JSON']['input'];
  packageTypes: Array<PackageTypeEnum>;
  pkgName: Scalars['String']['input'];
  schema: Scalars['JSON']['input'];
  version: Scalars['String']['input'];
};


export type Admin_MutationCreateProjectArgs = {
  accountId: Scalars['String']['input'];
  countryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  localeId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  productIds: Array<InputMaybe<Scalars['String']['input']>>;
  projectIconId?: InputMaybe<Scalars['String']['input']>;
  projectReleaseChannelConfigs?: InputMaybe<Array<InputMaybe<ProjectReleaseChannelConfigCreate>>>;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};


export type Admin_MutationCreateRoleArgs = {
  accountId: Scalars['String']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  productId: Scalars['String']['input'];
};


export type Admin_MutationCreateScheduledJobArgs = {
  name: Scalars['String']['input'];
};


export type Admin_MutationCreateTeamArgs = {
  accountId: Scalars['String']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projectIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Admin_MutationCreateVariableArgs = {
  key: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  projectReleaseChannelConfigIds?: InputMaybe<Array<Scalars['String']['input']>>;
  value: Scalars['String']['input'];
  variableAccessLevel?: InputMaybe<VariableAccessLevelEnum>;
};


export type Admin_MutationDeactivateAllUserSessionsArgs = {
  accountId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Admin_MutationDeactivateUserSessionArgs = {
  accountId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type Admin_MutationDeleteAccountArgs = {
  id: Scalars['ID']['input'];
};


export type Admin_MutationDeleteAppletArgs = {
  id: Scalars['String']['input'];
};


export type Admin_MutationDeleteAppletInstanceArgs = {
  id: Scalars['String']['input'];
};


export type Admin_MutationDeleteAppletVersionArgs = {
  id: Scalars['String']['input'];
};


export type Admin_MutationDeletePackageArgs = {
  id: Scalars['String']['input'];
};


export type Admin_MutationDeletePackageVersionArgs = {
  id: Scalars['String']['input'];
};


export type Admin_MutationDeleteProjectArgs = {
  id: Scalars['String']['input'];
};


export type Admin_MutationDeleteRolesArgs = {
  roleIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationDeleteTeamsArgs = {
  teamIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationDeleteVariableArgs = {
  id: Scalars['String']['input'];
};


export type Admin_MutationFinishUserRecoveryProcessArgs = {
  email: Scalars['String']['input'];
};


export type Admin_MutationLockAccountArgs = {
  id: Scalars['ID']['input'];
};


export type Admin_MutationPauseScheduledJobArgs = {
  id: Scalars['String']['input'];
};


export type Admin_MutationRemoveUsersFromAccountArgs = {
  accountId: Scalars['String']['input'];
  userIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationResumeScheduledJobArgs = {
  id: Scalars['String']['input'];
};


export type Admin_MutationSetRolesTeamsArgs = {
  accountId: Scalars['String']['input'];
  action: Set_Data_Action;
  roleIds: Array<InputMaybe<Scalars['String']['input']>>;
  teamIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationSetRolesUsersArgs = {
  accountId: Scalars['String']['input'];
  action: Set_Data_Action;
  roleIds: Array<InputMaybe<Scalars['String']['input']>>;
  userIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationSetTeamAccountProjectsRolesArgs = {
  projectRolesUpdate: Array<InputMaybe<ProjectRolesUpdate>>;
  teamId: Scalars['String']['input'];
};


export type Admin_MutationSetTeamAccountReleaseChannelsRolesArgs = {
  releaseChannelsRolesUpdate: Array<InputMaybe<ReleaseChannelRolesUpdate>>;
  teamId: Scalars['String']['input'];
};


export type Admin_MutationSetTeamsMembersArgs = {
  accountId: Scalars['String']['input'];
  action: Set_Data_Action;
  teamIds: Array<InputMaybe<Scalars['String']['input']>>;
  userIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationSetTeamsProjectsArgs = {
  accountId: Scalars['String']['input'];
  action: Set_Data_Action;
  projectIds: Array<InputMaybe<Scalars['String']['input']>>;
  teamIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationSetTeamsRolesArgs = {
  accountId: Scalars['String']['input'];
  action: Set_Data_Action;
  roleIds: Array<InputMaybe<Scalars['String']['input']>>;
  teamIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationSetUserAccountProjectsRolesArgs = {
  projectRolesUpdate: Array<InputMaybe<ProjectRolesUpdate>>;
  userId: Scalars['String']['input'];
};


export type Admin_MutationSetUserAccountReleaseChannelsRolesArgs = {
  releaseChannelsRolesUpdate: Array<InputMaybe<ReleaseChannelRolesUpdate>>;
  userId: Scalars['String']['input'];
};


export type Admin_MutationSetUserLocalizationSettingsArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  localeId?: InputMaybe<Scalars['String']['input']>;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type Admin_MutationSetUserPasswordRecoveryStatusArgs = {
  passwordRecoveryStatus: PasswordRecoveryStatusEnum;
  userId: Scalars['String']['input'];
};


export type Admin_MutationSetUsersProjectsArgs = {
  accountId: Scalars['String']['input'];
  action: Set_Data_Action;
  projectIds: Array<InputMaybe<Scalars['String']['input']>>;
  userIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationSetUsersRolesArgs = {
  accountId: Scalars['String']['input'];
  action: Set_Data_Action;
  roleIds: Array<InputMaybe<Scalars['String']['input']>>;
  userIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationSetUsersTeamsArgs = {
  accountId: Scalars['String']['input'];
  action: Set_Data_Action;
  teamIds: Array<InputMaybe<Scalars['String']['input']>>;
  userIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationStartUserRecoveryProcessArgs = {
  email: Scalars['String']['input'];
};


export type Admin_MutationUnlockAccountArgs = {
  id: Scalars['ID']['input'];
};


export type Admin_MutationUpdateAccountArgs = {
  accountIconId?: InputMaybe<Scalars['String']['input']>;
  accountManagerId?: InputMaybe<Scalars['String']['input']>;
  generalSettings?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  picture?: InputMaybe<Scalars['String']['input']>;
  resourceLimitSettings?: InputMaybe<Scalars['JSON']['input']>;
  securitySettings?: InputMaybe<Scalars['JSON']['input']>;
};


export type Admin_MutationUpdateAppletArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  iconId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  latestVersionId?: InputMaybe<Scalars['String']['input']>;
};


export type Admin_MutationUpdateAppletInstanceArgs = {
  actions?: InputMaybe<Array<InputMaybe<ActionCreate>>>;
  condition?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<AppletStatusEnum>;
  triggers?: InputMaybe<Array<InputMaybe<TriggerCreate>>>;
};


export type Admin_MutationUpdateAppletVersionArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type Admin_MutationUpdateDomainsVerificationStatusArgs = {
  domains?: InputMaybe<Array<DomainVerificationStatusInput>>;
};


export type Admin_MutationUpdatePackageArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  latestVersionId?: InputMaybe<Scalars['String']['input']>;
};


export type Admin_MutationUpdateProjectArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  localeId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projectIconId?: InputMaybe<Scalars['String']['input']>;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};


export type Admin_MutationUpdateProjectLocalizationSettingsArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  localeId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  settingsId: Scalars['String']['input'];
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};


export type Admin_MutationUpdateProjectReleaseChannelConfigArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<InputMaybe<DomainInput>>>;
  projectId: Scalars['String']['input'];
  projectReleaseChannelConfigId: Scalars['String']['input'];
  status?: InputMaybe<ReleaseChannelStatusEnum>;
};


export type Admin_MutationUpdateRoleArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Admin_MutationUpdateRoleMembersArgs = {
  roleId: Scalars['String']['input'];
  userIdsToAdd: Array<InputMaybe<Scalars['String']['input']>>;
  userIdsToRemove: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationUpdateRoleTeamsArgs = {
  roleId: Scalars['String']['input'];
  teamIdsToAdd: Array<InputMaybe<Scalars['String']['input']>>;
  teamIdsToRemove: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationUpdateTeamArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  projectIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projectIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Admin_MutationUpdateTeamMembersArgs = {
  accountId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  userIdsToAdd: Array<InputMaybe<Scalars['String']['input']>>;
  userIdsToRemove: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_MutationUpdateTeamProjectsArgs = {
  accountId: Scalars['String']['input'];
  projectIdsToAdd: Array<InputMaybe<Scalars['String']['input']>>;
  projectIdsToRemove: Array<InputMaybe<Scalars['String']['input']>>;
  teamId: Scalars['String']['input'];
};


export type Admin_MutationUpdateTeamRolesArgs = {
  accountId: Scalars['String']['input'];
  roleIdsToAdd: Array<InputMaybe<Scalars['String']['input']>>;
  roleIdsToRemove: Array<InputMaybe<Scalars['String']['input']>>;
  teamId: Scalars['String']['input'];
};


export type Admin_MutationUpdateUserArgs = {
  accountId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  optionalConsentStatus?: InputMaybe<ConsentStatusEnum>;
  optionalConsentUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  projectIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projectIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  requiredConsentStatus?: InputMaybe<ConsentStatusEnum>;
  requiredConsentUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  roleIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<UserStatus>;
  teamIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  teamIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIconId?: InputMaybe<Scalars['String']['input']>;
};


export type Admin_MutationUpdateUserLocalizationSettingsArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  localeId?: InputMaybe<Scalars['String']['input']>;
  settingsId: Scalars['String']['input'];
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type Admin_MutationUpdateUserProjectsArgs = {
  accountId: Scalars['String']['input'];
  projectIdsToAdd: Array<InputMaybe<Scalars['String']['input']>>;
  projectIdsToRemove: Array<InputMaybe<Scalars['String']['input']>>;
  userId: Scalars['String']['input'];
};


export type Admin_MutationUpdateUserRolesArgs = {
  accountId: Scalars['String']['input'];
  roleIdsToAdd: Array<InputMaybe<Scalars['String']['input']>>;
  roleIdsToRemove: Array<InputMaybe<Scalars['String']['input']>>;
  userId: Scalars['String']['input'];
};


export type Admin_MutationUpdateUserTeamsArgs = {
  accountId: Scalars['String']['input'];
  teamIdsToAdd: Array<InputMaybe<Scalars['String']['input']>>;
  teamIdsToRemove: Array<InputMaybe<Scalars['String']['input']>>;
  userId: Scalars['String']['input'];
};


export type Admin_MutationUpdateVariableArgs = {
  id: Scalars['String']['input'];
  projectReleaseChannelConfigIdsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projectReleaseChannelConfigIdsToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Scalars['String']['input']>;
};


export type Admin_MutationVerifyDomainArgs = {
  id: Scalars['String']['input'];
};

export type Admin_Query = {
  __typename?: 'admin_Query';
  HealthCheck?: Maybe<Scalars['Boolean']['output']>;
  _?: Maybe<Scalars['Boolean']['output']>;
  accountById?: Maybe<Account>;
  accountByShortId?: Maybe<Account>;
  accounts?: Maybe<PaginatedAccounts>;
  allProducts?: Maybe<Array<Maybe<Product>>>;
  allToolProducts?: Maybe<Array<Maybe<Product>>>;
  appletById?: Maybe<Applet>;
  appletInstanceById?: Maybe<AppletInstance>;
  appletInstanceByShortId?: Maybe<AppletInstance>;
  appletVersions?: Maybe<Array<Maybe<AppletVersion>>>;
  applets?: Maybe<Array<Maybe<Applet>>>;
  auditLogById?: Maybe<AuditLog>;
  auditLogs?: Maybe<PaginatedAuditLogs>;
  countries?: Maybe<Array<Maybe<Country2>>>;
  currentUser?: Maybe<User>;
  getAllDomains?: Maybe<Array<Maybe<Domain>>>;
  getLoginInfo?: Maybe<LoginInfo>;
  locales?: Maybe<Array<Maybe<Locale>>>;
  localizationSettingsConfig?: Maybe<LocalizationSettingsConfig>;
  organizationMetadata?: Maybe<OrganizationMetadata>;
  packageById?: Maybe<Package>;
  packages?: Maybe<Array<Maybe<Package>>>;
  projectById?: Maybe<Project>;
  projectByShortId?: Maybe<Project>;
  projectMetadata?: Maybe<ProjectMetadata>;
  projectReleaseChannelConfigByAppletInstanceId?: Maybe<ProjectReleaseChannelConfig>;
  projectReleaseChannelConfigByDomain?: Maybe<ProjectReleaseChannelConfig>;
  projectReleaseChannelConfigById?: Maybe<ProjectReleaseChannelConfig>;
  projectReleaseChannelConfigByShortId?: Maybe<ProjectReleaseChannelConfig>;
  projectTeams?: Maybe<PaginatedProjectTeams>;
  projectUsers?: Maybe<PaginatedProjectUsers>;
  projects?: Maybe<PaginatedProjects>;
  releaseChannelById?: Maybe<ReleaseChannel>;
  releaseChannels?: Maybe<Array<Maybe<ReleaseChannel>>>;
  roleById?: Maybe<Role>;
  roleMembers?: Maybe<PaginatedRoleMembers>;
  roleMetadata?: Maybe<RoleMetadata>;
  roles?: Maybe<PaginatedRoles>;
  rolesMetadata?: Maybe<RolesMetadata>;
  scheduledJobById?: Maybe<ScheduledJob>;
  scheduledJobRuns?: Maybe<ScheduledJobRunsQueryResults>;
  scheduledJobs?: Maybe<ScheduledJobsQueryResults>;
  teamById?: Maybe<Team>;
  teamMembers?: Maybe<PaginatedTeamMembers>;
  teamProductRoles?: Maybe<TeamProductRoles>;
  teamProjects?: Maybe<PaginatedTeamProjects>;
  teamRoles?: Maybe<PaginatedTeamRoles>;
  teams?: Maybe<PaginatedTeams>;
  timeZones?: Maybe<Array<Maybe<TimeZone>>>;
  userById?: Maybe<User>;
  userProductRoles?: Maybe<UserProductRoles>;
  userProjects?: Maybe<PaginatedUserProjects>;
  userRoles?: Maybe<PaginatedUserRoles>;
  userTeams?: Maybe<PaginatedUserTeams>;
  users?: Maybe<PaginatedUsers>;
  validateProjectReleaseChannelUrls?: Maybe<ValidatedReleaseChannelDomainUrls>;
  variableById?: Maybe<Variable>;
  variables?: Maybe<Array<Maybe<Variable>>>;
};


export type Admin_QueryAccountByIdArgs = {
  id: Scalars['String']['input'];
};


export type Admin_QueryAccountByShortIdArgs = {
  shortId: Scalars['String']['input'];
};


export type Admin_QueryAccountsArgs = {
  filterCriteria: AccountsFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryAppletByIdArgs = {
  appletId: Scalars['String']['input'];
};


export type Admin_QueryAppletInstanceByIdArgs = {
  appletInstanceId: Scalars['String']['input'];
};


export type Admin_QueryAppletInstanceByShortIdArgs = {
  shortId: Scalars['String']['input'];
};


export type Admin_QueryAppletVersionsArgs = {
  appletId: Scalars['String']['input'];
};


export type Admin_QueryAuditLogByIdArgs = {
  id: Scalars['ID']['input'];
};


export type Admin_QueryAuditLogsArgs = {
  filterCriteria: AuditLogsFilter;
  queryMetadata: QueryMetadata;
};


export type Admin_QueryGetLoginInfoArgs = {
  email: Scalars['String']['input'];
};


export type Admin_QueryOrganizationMetadataArgs = {
  accountId: Scalars['String']['input'];
};


export type Admin_QueryPackageByIdArgs = {
  packageId: Scalars['String']['input'];
};


export type Admin_QueryPackagesArgs = {
  packageTypes?: InputMaybe<Array<InputMaybe<PackageTypeEnum>>>;
};


export type Admin_QueryProjectByIdArgs = {
  id: Scalars['String']['input'];
};


export type Admin_QueryProjectByShortIdArgs = {
  shortId: Scalars['String']['input'];
};


export type Admin_QueryProjectMetadataArgs = {
  accountId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};


export type Admin_QueryProjectReleaseChannelConfigByAppletInstanceIdArgs = {
  appletInstanceId: Scalars['String']['input'];
};


export type Admin_QueryProjectReleaseChannelConfigByDomainArgs = {
  url: Scalars['String']['input'];
};


export type Admin_QueryProjectReleaseChannelConfigByIdArgs = {
  id: Scalars['String']['input'];
};


export type Admin_QueryProjectReleaseChannelConfigByShortIdArgs = {
  shortId: Scalars['String']['input'];
};


export type Admin_QueryProjectTeamsArgs = {
  accountId: Scalars['String']['input'];
  productId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryProjectUsersArgs = {
  accountId: Scalars['String']['input'];
  productId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryProjectsArgs = {
  filterCriteria: ProjectsFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryReleaseChannelByIdArgs = {
  id: Scalars['String']['input'];
};


export type Admin_QueryRoleByIdArgs = {
  id: Scalars['String']['input'];
};


export type Admin_QueryRoleMembersArgs = {
  filterCriteria: RoleMembersFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryRoleMetadataArgs = {
  roleId: Scalars['String']['input'];
};


export type Admin_QueryRolesArgs = {
  filterCriteria: RolesFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryRolesMetadataArgs = {
  accountId: Scalars['String']['input'];
};


export type Admin_QueryScheduledJobByIdArgs = {
  id: Scalars['ID']['input'];
};


export type Admin_QueryScheduledJobRunsArgs = {
  filterCriteria: ScheduledJobRunsFilter;
  queryMetadata: QueryMetadata;
};


export type Admin_QueryScheduledJobsArgs = {
  filterCriteria: ScheduledJobsFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryTeamByIdArgs = {
  id: Scalars['String']['input'];
};


export type Admin_QueryTeamMembersArgs = {
  filterCriteria: TeamMembersFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryTeamProductRolesArgs = {
  accountId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};


export type Admin_QueryTeamProjectsArgs = {
  filterCriteria: TeamProjectsFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryTeamRolesArgs = {
  filterCriteria: TeamRolesFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryTeamsArgs = {
  filterCriteria: TeamsFilter;
  queryMetadata: QueryMetadata;
};


export type Admin_QueryUserByIdArgs = {
  id: Scalars['String']['input'];
};


export type Admin_QueryUserProductRolesArgs = {
  accountId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type Admin_QueryUserProjectsArgs = {
  filterCriteria: UserProjectsFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryUserRolesArgs = {
  filterCriteria: UserRolesFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryUserTeamsArgs = {
  filterCriteria: UserTeamsFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryUsersArgs = {
  filterCriteria: UsersFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};


export type Admin_QueryValidateProjectReleaseChannelUrlsArgs = {
  urls: Array<InputMaybe<Scalars['String']['input']>>;
};


export type Admin_QueryVariableByIdArgs = {
  id: Scalars['String']['input'];
};


export type Admin_QueryVariablesArgs = {
  filterCriteria: VariablesFilter;
  queryMetadata?: InputMaybe<QueryMetadata>;
};

export type Admin_Subscription = {
  __typename?: 'admin_Subscription';
  _?: Maybe<Scalars['Boolean']['output']>;
};

/** Actual applications */
export type App_Loader_App = {
  __typename?: 'app_loader_app';
  /** An object relationship */
  customer?: Maybe<App_Loader_Customer>;
  customerId: Scalars['app_loader_uuid']['output'];
  /** An array relationship */
  environment: Array<App_Loader_Environment>;
  /** An aggregate relationship */
  environment_aggregate: App_Loader_Environment_Aggregate;
  id: Scalars['app_loader_uuid']['output'];
  name: Scalars['String']['output'];
};


/** Actual applications */
export type App_Loader_AppEnvironmentArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Environment_Order_By>>;
  where?: InputMaybe<App_Loader_Environment_Bool_Exp>;
};


/** Actual applications */
export type App_Loader_AppEnvironment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Environment_Order_By>>;
  where?: InputMaybe<App_Loader_Environment_Bool_Exp>;
};

/** aggregated selection of "app" */
export type App_Loader_App_Aggregate = {
  __typename?: 'app_loader_app_aggregate';
  aggregate?: Maybe<App_Loader_App_Aggregate_Fields>;
  nodes: Array<App_Loader_App>;
};

/** aggregate fields of "app" */
export type App_Loader_App_Aggregate_Fields = {
  __typename?: 'app_loader_app_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_App_Max_Fields>;
  min?: Maybe<App_Loader_App_Min_Fields>;
};


/** aggregate fields of "app" */
export type App_Loader_App_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_App_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "app". All fields are combined with a logical 'AND'. */
export type App_Loader_App_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_App_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_App_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_App_Bool_Exp>>;
  customer?: InputMaybe<App_Loader_Customer_Bool_Exp>;
  customerId?: InputMaybe<Uuid_Comparison_Exp>;
  environment?: InputMaybe<App_Loader_Environment_Bool_Exp>;
  environment_aggregate?: InputMaybe<App_Loader_Environment_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "app" */
export enum App_Loader_App_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppPkey = 'app_pkey'
}

/** input type for inserting data into table "app" */
export type App_Loader_App_Insert_Input = {
  customer?: InputMaybe<App_Loader_Customer_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  environment?: InputMaybe<App_Loader_Environment_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_App_Max_Fields = {
  __typename?: 'app_loader_app_max_fields';
  customerId?: Maybe<Scalars['app_loader_uuid']['output']>;
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_App_Min_Fields = {
  __typename?: 'app_loader_app_min_fields';
  customerId?: Maybe<Scalars['app_loader_uuid']['output']>;
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "app" */
export type App_Loader_App_Mutation_Response = {
  __typename?: 'app_loader_app_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_App>;
};

/** input type for inserting object relation for remote table "app" */
export type App_Loader_App_Obj_Rel_Insert_Input = {
  data: App_Loader_App_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_App_On_Conflict>;
};

/** on_conflict condition type for table "app" */
export type App_Loader_App_On_Conflict = {
  constraint: App_Loader_App_Constraint;
  update_columns?: Array<App_Loader_App_Update_Column>;
  where?: InputMaybe<App_Loader_App_Bool_Exp>;
};

/** Ordering options when selecting data from "app". */
export type App_Loader_App_Order_By = {
  customer?: InputMaybe<App_Loader_Customer_Order_By>;
  customerId?: InputMaybe<App_Loader_Order_By>;
  environment_aggregate?: InputMaybe<App_Loader_Environment_Aggregate_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: app */
export type App_Loader_App_Pk_Columns_Input = {
  id: Scalars['app_loader_uuid']['input'];
};

/** select columns of table "app" */
export enum App_Loader_App_Select_Column {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "app" */
export type App_Loader_App_Set_Input = {
  customerId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "app" */
export type App_Loader_App_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_App_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_App_Stream_Cursor_Value_Input = {
  customerId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "app" */
export enum App_Loader_App_Update_Column {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type App_Loader_App_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_App_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_App_Bool_Exp;
};

/** table for conditions */
export type App_Loader_Conditions = {
  __typename?: 'app_loader_conditions';
  id: Scalars['app_loader_uuid']['output'];
  name: Scalars['String']['output'];
  schema: Scalars['app_loader_jsonb']['output'];
  version: Scalars['String']['output'];
};


/** table for conditions */
export type App_Loader_ConditionsSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "conditions" */
export type App_Loader_Conditions_Aggregate = {
  __typename?: 'app_loader_conditions_aggregate';
  aggregate?: Maybe<App_Loader_Conditions_Aggregate_Fields>;
  nodes: Array<App_Loader_Conditions>;
};

/** aggregate fields of "conditions" */
export type App_Loader_Conditions_Aggregate_Fields = {
  __typename?: 'app_loader_conditions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Conditions_Max_Fields>;
  min?: Maybe<App_Loader_Conditions_Min_Fields>;
};


/** aggregate fields of "conditions" */
export type App_Loader_Conditions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Conditions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type App_Loader_Conditions_Append_Input = {
  schema?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "conditions". All fields are combined with a logical 'AND'. */
export type App_Loader_Conditions_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Conditions_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Conditions_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Conditions_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  schema?: InputMaybe<Jsonb_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "conditions" */
export enum App_Loader_Conditions_Constraint {
  /** unique or primary key constraint on columns "name" */
  ConditionsNameKey = 'conditions_name_key',
  /** unique or primary key constraint on columns "id" */
  ConditionsPkey = 'conditions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type App_Loader_Conditions_Delete_At_Path_Input = {
  schema?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type App_Loader_Conditions_Delete_Elem_Input = {
  schema?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type App_Loader_Conditions_Delete_Key_Input = {
  schema?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "conditions" */
export type App_Loader_Conditions_Insert_Input = {
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Conditions_Max_Fields = {
  __typename?: 'app_loader_conditions_max_fields';
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Conditions_Min_Fields = {
  __typename?: 'app_loader_conditions_min_fields';
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "conditions" */
export type App_Loader_Conditions_Mutation_Response = {
  __typename?: 'app_loader_conditions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Conditions>;
};

/** on_conflict condition type for table "conditions" */
export type App_Loader_Conditions_On_Conflict = {
  constraint: App_Loader_Conditions_Constraint;
  update_columns?: Array<App_Loader_Conditions_Update_Column>;
  where?: InputMaybe<App_Loader_Conditions_Bool_Exp>;
};

/** Ordering options when selecting data from "conditions". */
export type App_Loader_Conditions_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
  schema?: InputMaybe<App_Loader_Order_By>;
  version?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: conditions */
export type App_Loader_Conditions_Pk_Columns_Input = {
  id: Scalars['app_loader_uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type App_Loader_Conditions_Prepend_Input = {
  schema?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
};

/** select columns of table "conditions" */
export enum App_Loader_Conditions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Schema = 'schema',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "conditions" */
export type App_Loader_Conditions_Set_Input = {
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "conditions" */
export type App_Loader_Conditions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Conditions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Conditions_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "conditions" */
export enum App_Loader_Conditions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Schema = 'schema',
  /** column name */
  Version = 'version'
}

export type App_Loader_Conditions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<App_Loader_Conditions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<App_Loader_Conditions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<App_Loader_Conditions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<App_Loader_Conditions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<App_Loader_Conditions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Conditions_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Conditions_Bool_Exp;
};

/** ordering argument of a cursor */
export enum App_Loader_Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** table representing clients */
export type App_Loader_Customer = {
  __typename?: 'app_loader_customer';
  /** An object relationship */
  app?: Maybe<App_Loader_App>;
  id: Scalars['app_loader_uuid']['output'];
  /** An array relationship */
  n_applications: Array<App_Loader_N_Application>;
  /** An aggregate relationship */
  n_applications_aggregate: App_Loader_N_Application_Aggregate;
  name: Scalars['String']['output'];
  topLevelDomain: Scalars['String']['output'];
};


/** table representing clients */
export type App_Loader_CustomerN_ApplicationsArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Application_Order_By>>;
  where?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
};


/** table representing clients */
export type App_Loader_CustomerN_Applications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Application_Order_By>>;
  where?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
};

/** aggregated selection of "customer" */
export type App_Loader_Customer_Aggregate = {
  __typename?: 'app_loader_customer_aggregate';
  aggregate?: Maybe<App_Loader_Customer_Aggregate_Fields>;
  nodes: Array<App_Loader_Customer>;
};

/** aggregate fields of "customer" */
export type App_Loader_Customer_Aggregate_Fields = {
  __typename?: 'app_loader_customer_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Customer_Max_Fields>;
  min?: Maybe<App_Loader_Customer_Min_Fields>;
};


/** aggregate fields of "customer" */
export type App_Loader_Customer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Customer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "customer". All fields are combined with a logical 'AND'. */
export type App_Loader_Customer_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Customer_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Customer_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Customer_Bool_Exp>>;
  app?: InputMaybe<App_Loader_App_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  n_applications?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
  n_applications_aggregate?: InputMaybe<App_Loader_N_Application_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  topLevelDomain?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer" */
export enum App_Loader_Customer_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientPkey = 'client_pkey'
}

/** input type for inserting data into table "customer" */
export type App_Loader_Customer_Insert_Input = {
  app?: InputMaybe<App_Loader_App_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  n_applications?: InputMaybe<App_Loader_N_Application_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  topLevelDomain?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Customer_Max_Fields = {
  __typename?: 'app_loader_customer_max_fields';
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  topLevelDomain?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Customer_Min_Fields = {
  __typename?: 'app_loader_customer_min_fields';
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  topLevelDomain?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "customer" */
export type App_Loader_Customer_Mutation_Response = {
  __typename?: 'app_loader_customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Customer>;
};

/** input type for inserting object relation for remote table "customer" */
export type App_Loader_Customer_Obj_Rel_Insert_Input = {
  data: App_Loader_Customer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_Customer_On_Conflict>;
};

/** on_conflict condition type for table "customer" */
export type App_Loader_Customer_On_Conflict = {
  constraint: App_Loader_Customer_Constraint;
  update_columns?: Array<App_Loader_Customer_Update_Column>;
  where?: InputMaybe<App_Loader_Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "customer". */
export type App_Loader_Customer_Order_By = {
  app?: InputMaybe<App_Loader_App_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  n_applications_aggregate?: InputMaybe<App_Loader_N_Application_Aggregate_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
  topLevelDomain?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: customer */
export type App_Loader_Customer_Pk_Columns_Input = {
  id: Scalars['app_loader_uuid']['input'];
};

/** select columns of table "customer" */
export enum App_Loader_Customer_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TopLevelDomain = 'topLevelDomain'
}

/** input type for updating data in table "customer" */
export type App_Loader_Customer_Set_Input = {
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  topLevelDomain?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "customer" */
export type App_Loader_Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Customer_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  topLevelDomain?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "customer" */
export enum App_Loader_Customer_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TopLevelDomain = 'topLevelDomain'
}

export type App_Loader_Customer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Customer_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Customer_Bool_Exp;
};

/** columns and relationships of "app_loader.data_store" */
export type App_Loader_Data_Store = {
  __typename?: 'app_loader_data_store';
  data: Scalars['jsonb']['output'];
  session_id: Scalars['String']['output'];
};


/** columns and relationships of "app_loader.data_store" */
export type App_Loader_Data_StoreDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "app_loader.data_store" */
export type App_Loader_Data_Store_Aggregate = {
  __typename?: 'app_loader_data_store_aggregate';
  aggregate?: Maybe<App_Loader_Data_Store_Aggregate_Fields>;
  nodes: Array<App_Loader_Data_Store>;
};

/** aggregate fields of "app_loader.data_store" */
export type App_Loader_Data_Store_Aggregate_Fields = {
  __typename?: 'app_loader_data_store_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Data_Store_Max_Fields>;
  min?: Maybe<App_Loader_Data_Store_Min_Fields>;
};


/** aggregate fields of "app_loader.data_store" */
export type App_Loader_Data_Store_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Data_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type App_Loader_Data_Store_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "app_loader.data_store". All fields are combined with a logical 'AND'. */
export type App_Loader_Data_Store_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Data_Store_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Data_Store_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Data_Store_Bool_Exp>>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  session_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "app_loader.data_store" */
export enum App_Loader_Data_Store_Constraint {
  /** unique or primary key constraint on columns "session_id" */
  DataStorePkey = 'data_store_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type App_Loader_Data_Store_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type App_Loader_Data_Store_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type App_Loader_Data_Store_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "app_loader.data_store" */
export type App_Loader_Data_Store_Insert_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  session_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Data_Store_Max_Fields = {
  __typename?: 'app_loader_data_store_max_fields';
  session_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Data_Store_Min_Fields = {
  __typename?: 'app_loader_data_store_min_fields';
  session_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "app_loader.data_store" */
export type App_Loader_Data_Store_Mutation_Response = {
  __typename?: 'app_loader_data_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Data_Store>;
};

/** on_conflict condition type for table "app_loader.data_store" */
export type App_Loader_Data_Store_On_Conflict = {
  constraint: App_Loader_Data_Store_Constraint;
  update_columns?: Array<App_Loader_Data_Store_Update_Column>;
  where?: InputMaybe<App_Loader_Data_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "app_loader.data_store". */
export type App_Loader_Data_Store_Order_By = {
  data?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: app_loader.data_store */
export type App_Loader_Data_Store_Pk_Columns_Input = {
  session_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type App_Loader_Data_Store_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "app_loader.data_store" */
export enum App_Loader_Data_Store_Select_Column {
  /** column name */
  Data = 'data',
  /** column name */
  SessionId = 'session_id'
}

/** input type for updating data in table "app_loader.data_store" */
export type App_Loader_Data_Store_Set_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  session_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "app_loader_data_store" */
export type App_Loader_Data_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Data_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Data_Store_Stream_Cursor_Value_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  session_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "app_loader.data_store" */
export enum App_Loader_Data_Store_Update_Column {
  /** column name */
  Data = 'data',
  /** column name */
  SessionId = 'session_id'
}

export type App_Loader_Data_Store_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<App_Loader_Data_Store_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<App_Loader_Data_Store_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<App_Loader_Data_Store_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<App_Loader_Data_Store_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<App_Loader_Data_Store_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Data_Store_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Data_Store_Bool_Exp;
};

/** table for deployments */
export type App_Loader_Deployment = {
  __typename?: 'app_loader_deployment';
  appId: Scalars['app_loader_uuid']['output'];
  id: Scalars['app_loader_uuid']['output'];
};

/** aggregated selection of "deployment" */
export type App_Loader_Deployment_Aggregate = {
  __typename?: 'app_loader_deployment_aggregate';
  aggregate?: Maybe<App_Loader_Deployment_Aggregate_Fields>;
  nodes: Array<App_Loader_Deployment>;
};

/** aggregate fields of "deployment" */
export type App_Loader_Deployment_Aggregate_Fields = {
  __typename?: 'app_loader_deployment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Deployment_Max_Fields>;
  min?: Maybe<App_Loader_Deployment_Min_Fields>;
};


/** aggregate fields of "deployment" */
export type App_Loader_Deployment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Deployment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "deployment". All fields are combined with a logical 'AND'. */
export type App_Loader_Deployment_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Deployment_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Deployment_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Deployment_Bool_Exp>>;
  appId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deployment" */
export enum App_Loader_Deployment_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeploymentPkey = 'deployment_pkey'
}

/** input type for inserting data into table "deployment" */
export type App_Loader_Deployment_Insert_Input = {
  appId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Deployment_Max_Fields = {
  __typename?: 'app_loader_deployment_max_fields';
  appId?: Maybe<Scalars['app_loader_uuid']['output']>;
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Deployment_Min_Fields = {
  __typename?: 'app_loader_deployment_min_fields';
  appId?: Maybe<Scalars['app_loader_uuid']['output']>;
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** response of any mutation on the table "deployment" */
export type App_Loader_Deployment_Mutation_Response = {
  __typename?: 'app_loader_deployment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Deployment>;
};

/** on_conflict condition type for table "deployment" */
export type App_Loader_Deployment_On_Conflict = {
  constraint: App_Loader_Deployment_Constraint;
  update_columns?: Array<App_Loader_Deployment_Update_Column>;
  where?: InputMaybe<App_Loader_Deployment_Bool_Exp>;
};

/** Ordering options when selecting data from "deployment". */
export type App_Loader_Deployment_Order_By = {
  appId?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: deployment */
export type App_Loader_Deployment_Pk_Columns_Input = {
  id: Scalars['app_loader_uuid']['input'];
};

/** select columns of table "deployment" */
export enum App_Loader_Deployment_Select_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "deployment" */
export type App_Loader_Deployment_Set_Input = {
  appId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
};

/** Streaming cursor of the table "deployment" */
export type App_Loader_Deployment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Deployment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Deployment_Stream_Cursor_Value_Input = {
  appId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
};

/** update columns of table "deployment" */
export enum App_Loader_Deployment_Update_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  Id = 'id'
}

export type App_Loader_Deployment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Deployment_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Deployment_Bool_Exp;
};

/** environments for apps */
export type App_Loader_Environment = {
  __typename?: 'app_loader_environment';
  /** An object relationship */
  app?: Maybe<App_Loader_App>;
  appId?: Maybe<Scalars['app_loader_uuid']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['app_loader_uuid']['output'];
  name: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  /** An array relationship */
  target: Array<App_Loader_Target>;
  /** An aggregate relationship */
  target_aggregate: App_Loader_Target_Aggregate;
};


/** environments for apps */
export type App_Loader_EnvironmentTargetArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Target_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Target_Order_By>>;
  where?: InputMaybe<App_Loader_Target_Bool_Exp>;
};


/** environments for apps */
export type App_Loader_EnvironmentTarget_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Target_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Target_Order_By>>;
  where?: InputMaybe<App_Loader_Target_Bool_Exp>;
};

/** aggregated selection of "environment" */
export type App_Loader_Environment_Aggregate = {
  __typename?: 'app_loader_environment_aggregate';
  aggregate?: Maybe<App_Loader_Environment_Aggregate_Fields>;
  nodes: Array<App_Loader_Environment>;
};

export type App_Loader_Environment_Aggregate_Bool_Exp = {
  count?: InputMaybe<App_Loader_Environment_Aggregate_Bool_Exp_Count>;
};

export type App_Loader_Environment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<App_Loader_Environment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<App_Loader_Environment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "environment" */
export type App_Loader_Environment_Aggregate_Fields = {
  __typename?: 'app_loader_environment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Environment_Max_Fields>;
  min?: Maybe<App_Loader_Environment_Min_Fields>;
};


/** aggregate fields of "environment" */
export type App_Loader_Environment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Environment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "environment" */
export type App_Loader_Environment_Aggregate_Order_By = {
  count?: InputMaybe<App_Loader_Order_By>;
  max?: InputMaybe<App_Loader_Environment_Max_Order_By>;
  min?: InputMaybe<App_Loader_Environment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "environment" */
export type App_Loader_Environment_Arr_Rel_Insert_Input = {
  data: Array<App_Loader_Environment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_Environment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "environment". All fields are combined with a logical 'AND'. */
export type App_Loader_Environment_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Environment_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Environment_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Environment_Bool_Exp>>;
  app?: InputMaybe<App_Loader_App_Bool_Exp>;
  appId?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  path?: InputMaybe<String_Array_Comparison_Exp>;
  target?: InputMaybe<App_Loader_Target_Bool_Exp>;
  target_aggregate?: InputMaybe<App_Loader_Target_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "environment" */
export enum App_Loader_Environment_Constraint {
  /** unique or primary key constraint on columns "id" */
  EnvironmentIdKey = 'environment_id_key',
  /** unique or primary key constraint on columns "id" */
  EnvironmentPkey = 'environment_pkey'
}

/** input type for inserting data into table "environment" */
export type App_Loader_Environment_Insert_Input = {
  app?: InputMaybe<App_Loader_App_Obj_Rel_Insert_Input>;
  appId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  target?: InputMaybe<App_Loader_Target_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type App_Loader_Environment_Max_Fields = {
  __typename?: 'app_loader_environment_max_fields';
  appId?: Maybe<Scalars['app_loader_uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Array<Scalars['String']['output']>>;
};

/** order by max() on columns of table "environment" */
export type App_Loader_Environment_Max_Order_By = {
  appId?: InputMaybe<App_Loader_Order_By>;
  description?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
  path?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate min on columns */
export type App_Loader_Environment_Min_Fields = {
  __typename?: 'app_loader_environment_min_fields';
  appId?: Maybe<Scalars['app_loader_uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Array<Scalars['String']['output']>>;
};

/** order by min() on columns of table "environment" */
export type App_Loader_Environment_Min_Order_By = {
  appId?: InputMaybe<App_Loader_Order_By>;
  description?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
  path?: InputMaybe<App_Loader_Order_By>;
};

/** response of any mutation on the table "environment" */
export type App_Loader_Environment_Mutation_Response = {
  __typename?: 'app_loader_environment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Environment>;
};

/** input type for inserting object relation for remote table "environment" */
export type App_Loader_Environment_Obj_Rel_Insert_Input = {
  data: App_Loader_Environment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_Environment_On_Conflict>;
};

/** on_conflict condition type for table "environment" */
export type App_Loader_Environment_On_Conflict = {
  constraint: App_Loader_Environment_Constraint;
  update_columns?: Array<App_Loader_Environment_Update_Column>;
  where?: InputMaybe<App_Loader_Environment_Bool_Exp>;
};

/** Ordering options when selecting data from "environment". */
export type App_Loader_Environment_Order_By = {
  app?: InputMaybe<App_Loader_App_Order_By>;
  appId?: InputMaybe<App_Loader_Order_By>;
  description?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
  path?: InputMaybe<App_Loader_Order_By>;
  target_aggregate?: InputMaybe<App_Loader_Target_Aggregate_Order_By>;
};

/** primary key columns input for table: environment */
export type App_Loader_Environment_Pk_Columns_Input = {
  id: Scalars['app_loader_uuid']['input'];
};

/** select columns of table "environment" */
export enum App_Loader_Environment_Select_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path'
}

/** input type for updating data in table "environment" */
export type App_Loader_Environment_Set_Input = {
  appId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Streaming cursor of the table "environment" */
export type App_Loader_Environment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Environment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Environment_Stream_Cursor_Value_Input = {
  appId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** update columns of table "environment" */
export enum App_Loader_Environment_Update_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path'
}

export type App_Loader_Environment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Environment_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Environment_Bool_Exp;
};

/** table to handle anonymous_sessions for app-loader */
export type App_Loader_Latest_Schema_Anonymous_Session = {
  __typename?: 'app_loader_latest_schema_anonymous_session';
  data: Scalars['app_loader_jsonb']['output'];
  session_id: Scalars['app_loader_uuid']['output'];
};


/** table to handle anonymous_sessions for app-loader */
export type App_Loader_Latest_Schema_Anonymous_SessionDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "latest_schema.anonymous_session" */
export type App_Loader_Latest_Schema_Anonymous_Session_Aggregate = {
  __typename?: 'app_loader_latest_schema_anonymous_session_aggregate';
  aggregate?: Maybe<App_Loader_Latest_Schema_Anonymous_Session_Aggregate_Fields>;
  nodes: Array<App_Loader_Latest_Schema_Anonymous_Session>;
};

/** aggregate fields of "latest_schema.anonymous_session" */
export type App_Loader_Latest_Schema_Anonymous_Session_Aggregate_Fields = {
  __typename?: 'app_loader_latest_schema_anonymous_session_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Latest_Schema_Anonymous_Session_Max_Fields>;
  min?: Maybe<App_Loader_Latest_Schema_Anonymous_Session_Min_Fields>;
};


/** aggregate fields of "latest_schema.anonymous_session" */
export type App_Loader_Latest_Schema_Anonymous_Session_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Latest_Schema_Anonymous_Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type App_Loader_Latest_Schema_Anonymous_Session_Append_Input = {
  data?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "latest_schema.anonymous_session". All fields are combined with a logical 'AND'. */
export type App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp>>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  session_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "latest_schema.anonymous_session" */
export enum App_Loader_Latest_Schema_Anonymous_Session_Constraint {
  /** unique or primary key constraint on columns "session_id" */
  AnonymousSessionPkey = 'anonymous_session_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type App_Loader_Latest_Schema_Anonymous_Session_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type App_Loader_Latest_Schema_Anonymous_Session_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type App_Loader_Latest_Schema_Anonymous_Session_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "latest_schema.anonymous_session" */
export type App_Loader_Latest_Schema_Anonymous_Session_Insert_Input = {
  data?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
  session_id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Latest_Schema_Anonymous_Session_Max_Fields = {
  __typename?: 'app_loader_latest_schema_anonymous_session_max_fields';
  session_id?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Latest_Schema_Anonymous_Session_Min_Fields = {
  __typename?: 'app_loader_latest_schema_anonymous_session_min_fields';
  session_id?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** response of any mutation on the table "latest_schema.anonymous_session" */
export type App_Loader_Latest_Schema_Anonymous_Session_Mutation_Response = {
  __typename?: 'app_loader_latest_schema_anonymous_session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Latest_Schema_Anonymous_Session>;
};

/** on_conflict condition type for table "latest_schema.anonymous_session" */
export type App_Loader_Latest_Schema_Anonymous_Session_On_Conflict = {
  constraint: App_Loader_Latest_Schema_Anonymous_Session_Constraint;
  update_columns?: Array<App_Loader_Latest_Schema_Anonymous_Session_Update_Column>;
  where?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp>;
};

/** Ordering options when selecting data from "latest_schema.anonymous_session". */
export type App_Loader_Latest_Schema_Anonymous_Session_Order_By = {
  data?: InputMaybe<App_Loader_Order_By>;
  session_id?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: latest_schema.anonymous_session */
export type App_Loader_Latest_Schema_Anonymous_Session_Pk_Columns_Input = {
  session_id: Scalars['app_loader_uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type App_Loader_Latest_Schema_Anonymous_Session_Prepend_Input = {
  data?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
};

/** select columns of table "latest_schema.anonymous_session" */
export enum App_Loader_Latest_Schema_Anonymous_Session_Select_Column {
  /** column name */
  Data = 'data',
  /** column name */
  SessionId = 'session_id'
}

/** input type for updating data in table "latest_schema.anonymous_session" */
export type App_Loader_Latest_Schema_Anonymous_Session_Set_Input = {
  data?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
  session_id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
};

/** Streaming cursor of the table "latest_schema_anonymous_session" */
export type App_Loader_Latest_Schema_Anonymous_Session_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Latest_Schema_Anonymous_Session_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Latest_Schema_Anonymous_Session_Stream_Cursor_Value_Input = {
  data?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
  session_id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
};

/** update columns of table "latest_schema.anonymous_session" */
export enum App_Loader_Latest_Schema_Anonymous_Session_Update_Column {
  /** column name */
  Data = 'data',
  /** column name */
  SessionId = 'session_id'
}

export type App_Loader_Latest_Schema_Anonymous_Session_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp;
};

/** table to store all packages along with required metadata */
export type App_Loader_Latest_Schema_Packages = {
  __typename?: 'app_loader_latest_schema_packages';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  schema: Scalars['app_loader_jsonb']['output'];
  type: App_Loader_Latest_Schema_Packages_Type_Enum;
  version: Scalars['String']['output'];
};


/** table to store all packages along with required metadata */
export type App_Loader_Latest_Schema_PackagesSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "latest_schema.packages" */
export type App_Loader_Latest_Schema_Packages_Aggregate = {
  __typename?: 'app_loader_latest_schema_packages_aggregate';
  aggregate?: Maybe<App_Loader_Latest_Schema_Packages_Aggregate_Fields>;
  nodes: Array<App_Loader_Latest_Schema_Packages>;
};

/** aggregate fields of "latest_schema.packages" */
export type App_Loader_Latest_Schema_Packages_Aggregate_Fields = {
  __typename?: 'app_loader_latest_schema_packages_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Latest_Schema_Packages_Max_Fields>;
  min?: Maybe<App_Loader_Latest_Schema_Packages_Min_Fields>;
};


/** aggregate fields of "latest_schema.packages" */
export type App_Loader_Latest_Schema_Packages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type App_Loader_Latest_Schema_Packages_Append_Input = {
  schema?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "latest_schema.packages". All fields are combined with a logical 'AND'. */
export type App_Loader_Latest_Schema_Packages_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Latest_Schema_Packages_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  schema?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Enum_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "latest_schema.packages" */
export enum App_Loader_Latest_Schema_Packages_Constraint {
  /** unique or primary key constraint on columns "id" */
  PackagesPkey = 'packages_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type App_Loader_Latest_Schema_Packages_Delete_At_Path_Input = {
  schema?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type App_Loader_Latest_Schema_Packages_Delete_Elem_Input = {
  schema?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type App_Loader_Latest_Schema_Packages_Delete_Key_Input = {
  schema?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "latest_schema.packages" */
export type App_Loader_Latest_Schema_Packages_Insert_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
  type?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Enum>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Latest_Schema_Packages_Max_Fields = {
  __typename?: 'app_loader_latest_schema_packages_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Latest_Schema_Packages_Min_Fields = {
  __typename?: 'app_loader_latest_schema_packages_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "latest_schema.packages" */
export type App_Loader_Latest_Schema_Packages_Mutation_Response = {
  __typename?: 'app_loader_latest_schema_packages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Latest_Schema_Packages>;
};

/** on_conflict condition type for table "latest_schema.packages" */
export type App_Loader_Latest_Schema_Packages_On_Conflict = {
  constraint: App_Loader_Latest_Schema_Packages_Constraint;
  update_columns?: Array<App_Loader_Latest_Schema_Packages_Update_Column>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Bool_Exp>;
};

/** Ordering options when selecting data from "latest_schema.packages". */
export type App_Loader_Latest_Schema_Packages_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
  schema?: InputMaybe<App_Loader_Order_By>;
  type?: InputMaybe<App_Loader_Order_By>;
  version?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: latest_schema.packages */
export type App_Loader_Latest_Schema_Packages_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type App_Loader_Latest_Schema_Packages_Prepend_Input = {
  schema?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
};

/** select columns of table "latest_schema.packages" */
export enum App_Loader_Latest_Schema_Packages_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Schema = 'schema',
  /** column name */
  Type = 'type',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "latest_schema.packages" */
export type App_Loader_Latest_Schema_Packages_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
  type?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Enum>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "latest_schema_packages" */
export type App_Loader_Latest_Schema_Packages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Latest_Schema_Packages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Latest_Schema_Packages_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['app_loader_jsonb']['input']>;
  type?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Enum>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** table representing enums for types of package */
export type App_Loader_Latest_Schema_Packages_Type = {
  __typename?: 'app_loader_latest_schema_packages_type';
  type: Scalars['String']['output'];
};

/** aggregated selection of "latest_schema.packages_type" */
export type App_Loader_Latest_Schema_Packages_Type_Aggregate = {
  __typename?: 'app_loader_latest_schema_packages_type_aggregate';
  aggregate?: Maybe<App_Loader_Latest_Schema_Packages_Type_Aggregate_Fields>;
  nodes: Array<App_Loader_Latest_Schema_Packages_Type>;
};

/** aggregate fields of "latest_schema.packages_type" */
export type App_Loader_Latest_Schema_Packages_Type_Aggregate_Fields = {
  __typename?: 'app_loader_latest_schema_packages_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Latest_Schema_Packages_Type_Max_Fields>;
  min?: Maybe<App_Loader_Latest_Schema_Packages_Type_Min_Fields>;
};


/** aggregate fields of "latest_schema.packages_type" */
export type App_Loader_Latest_Schema_Packages_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "latest_schema.packages_type". All fields are combined with a logical 'AND'. */
export type App_Loader_Latest_Schema_Packages_Type_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "latest_schema.packages_type" */
export enum App_Loader_Latest_Schema_Packages_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  PackagesTypePkey = 'packages_type_pkey'
}

export enum App_Loader_Latest_Schema_Packages_Type_Enum {
  Condition = 'condition',
  Function = 'function',
  Trigger = 'trigger'
}

/** Boolean expression to compare columns of type "latest_schema_packages_type_enum". All fields are combined with logical 'AND'. */
export type App_Loader_Latest_Schema_Packages_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Enum>;
  _in?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Enum>;
  _nin?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Enum>>;
};

/** input type for inserting data into table "latest_schema.packages_type" */
export type App_Loader_Latest_Schema_Packages_Type_Insert_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Latest_Schema_Packages_Type_Max_Fields = {
  __typename?: 'app_loader_latest_schema_packages_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Latest_Schema_Packages_Type_Min_Fields = {
  __typename?: 'app_loader_latest_schema_packages_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "latest_schema.packages_type" */
export type App_Loader_Latest_Schema_Packages_Type_Mutation_Response = {
  __typename?: 'app_loader_latest_schema_packages_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Latest_Schema_Packages_Type>;
};

/** on_conflict condition type for table "latest_schema.packages_type" */
export type App_Loader_Latest_Schema_Packages_Type_On_Conflict = {
  constraint: App_Loader_Latest_Schema_Packages_Type_Constraint;
  update_columns?: Array<App_Loader_Latest_Schema_Packages_Type_Update_Column>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "latest_schema.packages_type". */
export type App_Loader_Latest_Schema_Packages_Type_Order_By = {
  type?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: latest_schema.packages_type */
export type App_Loader_Latest_Schema_Packages_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "latest_schema.packages_type" */
export enum App_Loader_Latest_Schema_Packages_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "latest_schema.packages_type" */
export type App_Loader_Latest_Schema_Packages_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "latest_schema_packages_type" */
export type App_Loader_Latest_Schema_Packages_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Latest_Schema_Packages_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Latest_Schema_Packages_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "latest_schema.packages_type" */
export enum App_Loader_Latest_Schema_Packages_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type App_Loader_Latest_Schema_Packages_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Latest_Schema_Packages_Type_Bool_Exp;
};

/** update columns of table "latest_schema.packages" */
export enum App_Loader_Latest_Schema_Packages_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Schema = 'schema',
  /** column name */
  Type = 'type',
  /** column name */
  Version = 'version'
}

export type App_Loader_Latest_Schema_Packages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<App_Loader_Latest_Schema_Packages_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<App_Loader_Latest_Schema_Packages_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<App_Loader_Latest_Schema_Packages_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<App_Loader_Latest_Schema_Packages_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<App_Loader_Latest_Schema_Packages_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Latest_Schema_Packages_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Latest_Schema_Packages_Bool_Exp;
};

/** table for representing analytics metadata */
export type App_Loader_Metadata_Analytics = {
  __typename?: 'app_loader_metadata_analytics';
  id: Scalars['app_loader_uuid']['output'];
  phApiHost: Scalars['String']['output'];
  phApiKey: Scalars['String']['output'];
  targetId: Scalars['app_loader_uuid']['output'];
};

/** aggregated selection of "metadata.analytics" */
export type App_Loader_Metadata_Analytics_Aggregate = {
  __typename?: 'app_loader_metadata_analytics_aggregate';
  aggregate?: Maybe<App_Loader_Metadata_Analytics_Aggregate_Fields>;
  nodes: Array<App_Loader_Metadata_Analytics>;
};

/** aggregate fields of "metadata.analytics" */
export type App_Loader_Metadata_Analytics_Aggregate_Fields = {
  __typename?: 'app_loader_metadata_analytics_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Metadata_Analytics_Max_Fields>;
  min?: Maybe<App_Loader_Metadata_Analytics_Min_Fields>;
};


/** aggregate fields of "metadata.analytics" */
export type App_Loader_Metadata_Analytics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Metadata_Analytics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "metadata.analytics". All fields are combined with a logical 'AND'. */
export type App_Loader_Metadata_Analytics_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Metadata_Analytics_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Metadata_Analytics_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Metadata_Analytics_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  phApiHost?: InputMaybe<String_Comparison_Exp>;
  phApiKey?: InputMaybe<String_Comparison_Exp>;
  targetId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "metadata.analytics" */
export enum App_Loader_Metadata_Analytics_Constraint {
  /** unique or primary key constraint on columns "id" */
  AnalyticsPkey = 'analytics_pkey'
}

/** input type for inserting data into table "metadata.analytics" */
export type App_Loader_Metadata_Analytics_Insert_Input = {
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  phApiHost?: InputMaybe<Scalars['String']['input']>;
  phApiKey?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Metadata_Analytics_Max_Fields = {
  __typename?: 'app_loader_metadata_analytics_max_fields';
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  phApiHost?: Maybe<Scalars['String']['output']>;
  phApiKey?: Maybe<Scalars['String']['output']>;
  targetId?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Metadata_Analytics_Min_Fields = {
  __typename?: 'app_loader_metadata_analytics_min_fields';
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  phApiHost?: Maybe<Scalars['String']['output']>;
  phApiKey?: Maybe<Scalars['String']['output']>;
  targetId?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** response of any mutation on the table "metadata.analytics" */
export type App_Loader_Metadata_Analytics_Mutation_Response = {
  __typename?: 'app_loader_metadata_analytics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Metadata_Analytics>;
};

/** input type for inserting object relation for remote table "metadata.analytics" */
export type App_Loader_Metadata_Analytics_Obj_Rel_Insert_Input = {
  data: App_Loader_Metadata_Analytics_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_Metadata_Analytics_On_Conflict>;
};

/** on_conflict condition type for table "metadata.analytics" */
export type App_Loader_Metadata_Analytics_On_Conflict = {
  constraint: App_Loader_Metadata_Analytics_Constraint;
  update_columns?: Array<App_Loader_Metadata_Analytics_Update_Column>;
  where?: InputMaybe<App_Loader_Metadata_Analytics_Bool_Exp>;
};

/** Ordering options when selecting data from "metadata.analytics". */
export type App_Loader_Metadata_Analytics_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
  phApiHost?: InputMaybe<App_Loader_Order_By>;
  phApiKey?: InputMaybe<App_Loader_Order_By>;
  targetId?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: metadata.analytics */
export type App_Loader_Metadata_Analytics_Pk_Columns_Input = {
  id: Scalars['app_loader_uuid']['input'];
};

/** select columns of table "metadata.analytics" */
export enum App_Loader_Metadata_Analytics_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PhApiHost = 'phApiHost',
  /** column name */
  PhApiKey = 'phApiKey',
  /** column name */
  TargetId = 'targetId'
}

/** input type for updating data in table "metadata.analytics" */
export type App_Loader_Metadata_Analytics_Set_Input = {
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  phApiHost?: InputMaybe<Scalars['String']['input']>;
  phApiKey?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
};

/** Streaming cursor of the table "metadata_analytics" */
export type App_Loader_Metadata_Analytics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Metadata_Analytics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Metadata_Analytics_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  phApiHost?: InputMaybe<Scalars['String']['input']>;
  phApiKey?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
};

/** update columns of table "metadata.analytics" */
export enum App_Loader_Metadata_Analytics_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PhApiHost = 'phApiHost',
  /** column name */
  PhApiKey = 'phApiKey',
  /** column name */
  TargetId = 'targetId'
}

export type App_Loader_Metadata_Analytics_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Metadata_Analytics_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Metadata_Analytics_Bool_Exp;
};

/** cc widget position options */
export type App_Loader_Metadata_Cc_Position_Option = {
  __typename?: 'app_loader_metadata_cc_position_option';
  position: Scalars['String']['output'];
};

/** aggregated selection of "metadata.cc_position_option" */
export type App_Loader_Metadata_Cc_Position_Option_Aggregate = {
  __typename?: 'app_loader_metadata_cc_position_option_aggregate';
  aggregate?: Maybe<App_Loader_Metadata_Cc_Position_Option_Aggregate_Fields>;
  nodes: Array<App_Loader_Metadata_Cc_Position_Option>;
};

/** aggregate fields of "metadata.cc_position_option" */
export type App_Loader_Metadata_Cc_Position_Option_Aggregate_Fields = {
  __typename?: 'app_loader_metadata_cc_position_option_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Metadata_Cc_Position_Option_Max_Fields>;
  min?: Maybe<App_Loader_Metadata_Cc_Position_Option_Min_Fields>;
};


/** aggregate fields of "metadata.cc_position_option" */
export type App_Loader_Metadata_Cc_Position_Option_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "metadata.cc_position_option". All fields are combined with a logical 'AND'. */
export type App_Loader_Metadata_Cc_Position_Option_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Bool_Exp>>;
  position?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "metadata.cc_position_option" */
export enum App_Loader_Metadata_Cc_Position_Option_Constraint {
  /** unique or primary key constraint on columns "position" */
  CcPositionOptionPkey = 'cc_position_option_pkey'
}

export enum App_Loader_Metadata_Cc_Position_Option_Enum {
  Left = 'left',
  Right = 'right'
}

/** Boolean expression to compare columns of type "metadata_cc_position_option_enum". All fields are combined with logical 'AND'. */
export type App_Loader_Metadata_Cc_Position_Option_Enum_Comparison_Exp = {
  _eq?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Enum>;
  _in?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Enum>;
  _nin?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Enum>>;
};

/** input type for inserting data into table "metadata.cc_position_option" */
export type App_Loader_Metadata_Cc_Position_Option_Insert_Input = {
  position?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Metadata_Cc_Position_Option_Max_Fields = {
  __typename?: 'app_loader_metadata_cc_position_option_max_fields';
  position?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Metadata_Cc_Position_Option_Min_Fields = {
  __typename?: 'app_loader_metadata_cc_position_option_min_fields';
  position?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "metadata.cc_position_option" */
export type App_Loader_Metadata_Cc_Position_Option_Mutation_Response = {
  __typename?: 'app_loader_metadata_cc_position_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Metadata_Cc_Position_Option>;
};

/** on_conflict condition type for table "metadata.cc_position_option" */
export type App_Loader_Metadata_Cc_Position_Option_On_Conflict = {
  constraint: App_Loader_Metadata_Cc_Position_Option_Constraint;
  update_columns?: Array<App_Loader_Metadata_Cc_Position_Option_Update_Column>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "metadata.cc_position_option". */
export type App_Loader_Metadata_Cc_Position_Option_Order_By = {
  position?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: metadata.cc_position_option */
export type App_Loader_Metadata_Cc_Position_Option_Pk_Columns_Input = {
  position: Scalars['String']['input'];
};

/** select columns of table "metadata.cc_position_option" */
export enum App_Loader_Metadata_Cc_Position_Option_Select_Column {
  /** column name */
  Position = 'position'
}

/** input type for updating data in table "metadata.cc_position_option" */
export type App_Loader_Metadata_Cc_Position_Option_Set_Input = {
  position?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "metadata_cc_position_option" */
export type App_Loader_Metadata_Cc_Position_Option_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Metadata_Cc_Position_Option_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Metadata_Cc_Position_Option_Stream_Cursor_Value_Input = {
  position?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "metadata.cc_position_option" */
export enum App_Loader_Metadata_Cc_Position_Option_Update_Column {
  /** column name */
  Position = 'position'
}

export type App_Loader_Metadata_Cc_Position_Option_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Metadata_Cc_Position_Option_Bool_Exp;
};

/** table representing metadata required for contact centre widget */
export type App_Loader_Metadata_Cc_Widget = {
  __typename?: 'app_loader_metadata_cc_widget';
  bubbleType?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Enum>;
  id: Scalars['app_loader_uuid']['output'];
  launcherTitle: Scalars['String']['output'];
  packageName: Scalars['String']['output'];
  phApiHost: Scalars['String']['output'];
  phApiKey: Scalars['String']['output'];
  position?: Maybe<App_Loader_Metadata_Cc_Position_Option_Enum>;
  targetId: Scalars['app_loader_uuid']['output'];
  websiteToken: Scalars['String']['output'];
};

/** aggregated selection of "metadata.cc_widget" */
export type App_Loader_Metadata_Cc_Widget_Aggregate = {
  __typename?: 'app_loader_metadata_cc_widget_aggregate';
  aggregate?: Maybe<App_Loader_Metadata_Cc_Widget_Aggregate_Fields>;
  nodes: Array<App_Loader_Metadata_Cc_Widget>;
};

/** aggregate fields of "metadata.cc_widget" */
export type App_Loader_Metadata_Cc_Widget_Aggregate_Fields = {
  __typename?: 'app_loader_metadata_cc_widget_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Metadata_Cc_Widget_Max_Fields>;
  min?: Maybe<App_Loader_Metadata_Cc_Widget_Min_Fields>;
};


/** aggregate fields of "metadata.cc_widget" */
export type App_Loader_Metadata_Cc_Widget_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "metadata.cc_widget". All fields are combined with a logical 'AND'. */
export type App_Loader_Metadata_Cc_Widget_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bool_Exp>>;
  bubbleType?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  launcherTitle?: InputMaybe<String_Comparison_Exp>;
  packageName?: InputMaybe<String_Comparison_Exp>;
  phApiHost?: InputMaybe<String_Comparison_Exp>;
  phApiKey?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Enum_Comparison_Exp>;
  targetId?: InputMaybe<Uuid_Comparison_Exp>;
  websiteToken?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "metadata.cc_widget_bubble_type" */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type = {
  __typename?: 'app_loader_metadata_cc_widget_bubble_type';
  type: Scalars['String']['output'];
};

/** aggregated selection of "metadata.cc_widget_bubble_type" */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Aggregate = {
  __typename?: 'app_loader_metadata_cc_widget_bubble_type_aggregate';
  aggregate?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Aggregate_Fields>;
  nodes: Array<App_Loader_Metadata_Cc_Widget_Bubble_Type>;
};

/** aggregate fields of "metadata.cc_widget_bubble_type" */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Aggregate_Fields = {
  __typename?: 'app_loader_metadata_cc_widget_bubble_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Max_Fields>;
  min?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Min_Fields>;
};


/** aggregate fields of "metadata.cc_widget_bubble_type" */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "metadata.cc_widget_bubble_type". All fields are combined with a logical 'AND'. */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "metadata.cc_widget_bubble_type" */
export enum App_Loader_Metadata_Cc_Widget_Bubble_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  CcWidgetBubbleTypePkey = 'cc_widget_bubble_type_pkey'
}

export enum App_Loader_Metadata_Cc_Widget_Bubble_Type_Enum {
  Expanded = 'expanded',
  Standard = 'standard'
}

/** Boolean expression to compare columns of type "metadata_cc_widget_bubble_type_enum". All fields are combined with logical 'AND'. */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Enum>;
  _in?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Enum>;
  _nin?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Enum>>;
};

/** input type for inserting data into table "metadata.cc_widget_bubble_type" */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Insert_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Max_Fields = {
  __typename?: 'app_loader_metadata_cc_widget_bubble_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Min_Fields = {
  __typename?: 'app_loader_metadata_cc_widget_bubble_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "metadata.cc_widget_bubble_type" */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Mutation_Response = {
  __typename?: 'app_loader_metadata_cc_widget_bubble_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Metadata_Cc_Widget_Bubble_Type>;
};

/** on_conflict condition type for table "metadata.cc_widget_bubble_type" */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_On_Conflict = {
  constraint: App_Loader_Metadata_Cc_Widget_Bubble_Type_Constraint;
  update_columns?: Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Update_Column>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "metadata.cc_widget_bubble_type". */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Order_By = {
  type?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: metadata.cc_widget_bubble_type */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "metadata.cc_widget_bubble_type" */
export enum App_Loader_Metadata_Cc_Widget_Bubble_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "metadata.cc_widget_bubble_type" */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "metadata_cc_widget_bubble_type" */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Metadata_Cc_Widget_Bubble_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "metadata.cc_widget_bubble_type" */
export enum App_Loader_Metadata_Cc_Widget_Bubble_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type App_Loader_Metadata_Cc_Widget_Bubble_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp;
};

/** unique or primary key constraints on table "metadata.cc_widget" */
export enum App_Loader_Metadata_Cc_Widget_Constraint {
  /** unique or primary key constraint on columns "id" */
  CcWidgetPkey = 'cc_widget_pkey'
}

/** input type for inserting data into table "metadata.cc_widget" */
export type App_Loader_Metadata_Cc_Widget_Insert_Input = {
  bubbleType?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Enum>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  launcherTitle?: InputMaybe<Scalars['String']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  phApiHost?: InputMaybe<Scalars['String']['input']>;
  phApiKey?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Enum>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  websiteToken?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Metadata_Cc_Widget_Max_Fields = {
  __typename?: 'app_loader_metadata_cc_widget_max_fields';
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  launcherTitle?: Maybe<Scalars['String']['output']>;
  packageName?: Maybe<Scalars['String']['output']>;
  phApiHost?: Maybe<Scalars['String']['output']>;
  phApiKey?: Maybe<Scalars['String']['output']>;
  targetId?: Maybe<Scalars['app_loader_uuid']['output']>;
  websiteToken?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Metadata_Cc_Widget_Min_Fields = {
  __typename?: 'app_loader_metadata_cc_widget_min_fields';
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  launcherTitle?: Maybe<Scalars['String']['output']>;
  packageName?: Maybe<Scalars['String']['output']>;
  phApiHost?: Maybe<Scalars['String']['output']>;
  phApiKey?: Maybe<Scalars['String']['output']>;
  targetId?: Maybe<Scalars['app_loader_uuid']['output']>;
  websiteToken?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "metadata.cc_widget" */
export type App_Loader_Metadata_Cc_Widget_Mutation_Response = {
  __typename?: 'app_loader_metadata_cc_widget_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Metadata_Cc_Widget>;
};

/** input type for inserting object relation for remote table "metadata.cc_widget" */
export type App_Loader_Metadata_Cc_Widget_Obj_Rel_Insert_Input = {
  data: App_Loader_Metadata_Cc_Widget_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_Metadata_Cc_Widget_On_Conflict>;
};

/** on_conflict condition type for table "metadata.cc_widget" */
export type App_Loader_Metadata_Cc_Widget_On_Conflict = {
  constraint: App_Loader_Metadata_Cc_Widget_Constraint;
  update_columns?: Array<App_Loader_Metadata_Cc_Widget_Update_Column>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bool_Exp>;
};

/** Ordering options when selecting data from "metadata.cc_widget". */
export type App_Loader_Metadata_Cc_Widget_Order_By = {
  bubbleType?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  launcherTitle?: InputMaybe<App_Loader_Order_By>;
  packageName?: InputMaybe<App_Loader_Order_By>;
  phApiHost?: InputMaybe<App_Loader_Order_By>;
  phApiKey?: InputMaybe<App_Loader_Order_By>;
  position?: InputMaybe<App_Loader_Order_By>;
  targetId?: InputMaybe<App_Loader_Order_By>;
  websiteToken?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: metadata.cc_widget */
export type App_Loader_Metadata_Cc_Widget_Pk_Columns_Input = {
  id: Scalars['app_loader_uuid']['input'];
};

/** select columns of table "metadata.cc_widget" */
export enum App_Loader_Metadata_Cc_Widget_Select_Column {
  /** column name */
  BubbleType = 'bubbleType',
  /** column name */
  Id = 'id',
  /** column name */
  LauncherTitle = 'launcherTitle',
  /** column name */
  PackageName = 'packageName',
  /** column name */
  PhApiHost = 'phApiHost',
  /** column name */
  PhApiKey = 'phApiKey',
  /** column name */
  Position = 'position',
  /** column name */
  TargetId = 'targetId',
  /** column name */
  WebsiteToken = 'websiteToken'
}

/** input type for updating data in table "metadata.cc_widget" */
export type App_Loader_Metadata_Cc_Widget_Set_Input = {
  bubbleType?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Enum>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  launcherTitle?: InputMaybe<Scalars['String']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  phApiHost?: InputMaybe<Scalars['String']['input']>;
  phApiKey?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Enum>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  websiteToken?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "metadata_cc_widget" */
export type App_Loader_Metadata_Cc_Widget_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Metadata_Cc_Widget_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Metadata_Cc_Widget_Stream_Cursor_Value_Input = {
  bubbleType?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Enum>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  launcherTitle?: InputMaybe<Scalars['String']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  phApiHost?: InputMaybe<Scalars['String']['input']>;
  phApiKey?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Enum>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  websiteToken?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "metadata.cc_widget" */
export enum App_Loader_Metadata_Cc_Widget_Update_Column {
  /** column name */
  BubbleType = 'bubbleType',
  /** column name */
  Id = 'id',
  /** column name */
  LauncherTitle = 'launcherTitle',
  /** column name */
  PackageName = 'packageName',
  /** column name */
  PhApiHost = 'phApiHost',
  /** column name */
  PhApiKey = 'phApiKey',
  /** column name */
  Position = 'position',
  /** column name */
  TargetId = 'targetId',
  /** column name */
  WebsiteToken = 'websiteToken'
}

export type App_Loader_Metadata_Cc_Widget_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Metadata_Cc_Widget_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Metadata_Cc_Widget_Bool_Exp;
};

/** table representing metadata for generic app/scripts */
export type App_Loader_Metadata_Generic = {
  __typename?: 'app_loader_metadata_generic';
  id: Scalars['app_loader_uuid']['output'];
  packageName: Scalars['String']['output'];
  targetId: Scalars['app_loader_uuid']['output'];
  type?: Maybe<App_Loader_Metadata_Generic_Module_Type_Enum>;
};

/** aggregated selection of "metadata.generic" */
export type App_Loader_Metadata_Generic_Aggregate = {
  __typename?: 'app_loader_metadata_generic_aggregate';
  aggregate?: Maybe<App_Loader_Metadata_Generic_Aggregate_Fields>;
  nodes: Array<App_Loader_Metadata_Generic>;
};

/** aggregate fields of "metadata.generic" */
export type App_Loader_Metadata_Generic_Aggregate_Fields = {
  __typename?: 'app_loader_metadata_generic_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Metadata_Generic_Max_Fields>;
  min?: Maybe<App_Loader_Metadata_Generic_Min_Fields>;
};


/** aggregate fields of "metadata.generic" */
export type App_Loader_Metadata_Generic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Metadata_Generic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "metadata.generic". All fields are combined with a logical 'AND'. */
export type App_Loader_Metadata_Generic_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Metadata_Generic_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Metadata_Generic_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Metadata_Generic_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  packageName?: InputMaybe<String_Comparison_Exp>;
  targetId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "metadata.generic" */
export enum App_Loader_Metadata_Generic_Constraint {
  /** unique or primary key constraint on columns "id" */
  GenericPkey = 'generic_pkey'
}

/** input type for inserting data into table "metadata.generic" */
export type App_Loader_Metadata_Generic_Insert_Input = {
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  type?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Enum>;
};

/** aggregate max on columns */
export type App_Loader_Metadata_Generic_Max_Fields = {
  __typename?: 'app_loader_metadata_generic_max_fields';
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  packageName?: Maybe<Scalars['String']['output']>;
  targetId?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Metadata_Generic_Min_Fields = {
  __typename?: 'app_loader_metadata_generic_min_fields';
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  packageName?: Maybe<Scalars['String']['output']>;
  targetId?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** module types for generic js resources */
export type App_Loader_Metadata_Generic_Module_Type = {
  __typename?: 'app_loader_metadata_generic_module_type';
  moduleType: Scalars['String']['output'];
};

/** aggregated selection of "metadata.generic_module_type" */
export type App_Loader_Metadata_Generic_Module_Type_Aggregate = {
  __typename?: 'app_loader_metadata_generic_module_type_aggregate';
  aggregate?: Maybe<App_Loader_Metadata_Generic_Module_Type_Aggregate_Fields>;
  nodes: Array<App_Loader_Metadata_Generic_Module_Type>;
};

/** aggregate fields of "metadata.generic_module_type" */
export type App_Loader_Metadata_Generic_Module_Type_Aggregate_Fields = {
  __typename?: 'app_loader_metadata_generic_module_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Metadata_Generic_Module_Type_Max_Fields>;
  min?: Maybe<App_Loader_Metadata_Generic_Module_Type_Min_Fields>;
};


/** aggregate fields of "metadata.generic_module_type" */
export type App_Loader_Metadata_Generic_Module_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "metadata.generic_module_type". All fields are combined with a logical 'AND'. */
export type App_Loader_Metadata_Generic_Module_Type_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Bool_Exp>>;
  moduleType?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "metadata.generic_module_type" */
export enum App_Loader_Metadata_Generic_Module_Type_Constraint {
  /** unique or primary key constraint on columns "moduleType" */
  GenericModuleTypePkey = 'generic_module_type_pkey'
}

export enum App_Loader_Metadata_Generic_Module_Type_Enum {
  Commonjs = 'commonjs',
  Module = 'module'
}

/** Boolean expression to compare columns of type "metadata_generic_module_type_enum". All fields are combined with logical 'AND'. */
export type App_Loader_Metadata_Generic_Module_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Enum>;
  _in?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Enum>;
  _nin?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Enum>>;
};

/** input type for inserting data into table "metadata.generic_module_type" */
export type App_Loader_Metadata_Generic_Module_Type_Insert_Input = {
  moduleType?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Metadata_Generic_Module_Type_Max_Fields = {
  __typename?: 'app_loader_metadata_generic_module_type_max_fields';
  moduleType?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Metadata_Generic_Module_Type_Min_Fields = {
  __typename?: 'app_loader_metadata_generic_module_type_min_fields';
  moduleType?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "metadata.generic_module_type" */
export type App_Loader_Metadata_Generic_Module_Type_Mutation_Response = {
  __typename?: 'app_loader_metadata_generic_module_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Metadata_Generic_Module_Type>;
};

/** on_conflict condition type for table "metadata.generic_module_type" */
export type App_Loader_Metadata_Generic_Module_Type_On_Conflict = {
  constraint: App_Loader_Metadata_Generic_Module_Type_Constraint;
  update_columns?: Array<App_Loader_Metadata_Generic_Module_Type_Update_Column>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "metadata.generic_module_type". */
export type App_Loader_Metadata_Generic_Module_Type_Order_By = {
  moduleType?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: metadata.generic_module_type */
export type App_Loader_Metadata_Generic_Module_Type_Pk_Columns_Input = {
  moduleType: Scalars['String']['input'];
};

/** select columns of table "metadata.generic_module_type" */
export enum App_Loader_Metadata_Generic_Module_Type_Select_Column {
  /** column name */
  ModuleType = 'moduleType'
}

/** input type for updating data in table "metadata.generic_module_type" */
export type App_Loader_Metadata_Generic_Module_Type_Set_Input = {
  moduleType?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "metadata_generic_module_type" */
export type App_Loader_Metadata_Generic_Module_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Metadata_Generic_Module_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Metadata_Generic_Module_Type_Stream_Cursor_Value_Input = {
  moduleType?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "metadata.generic_module_type" */
export enum App_Loader_Metadata_Generic_Module_Type_Update_Column {
  /** column name */
  ModuleType = 'moduleType'
}

export type App_Loader_Metadata_Generic_Module_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Metadata_Generic_Module_Type_Bool_Exp;
};

/** response of any mutation on the table "metadata.generic" */
export type App_Loader_Metadata_Generic_Mutation_Response = {
  __typename?: 'app_loader_metadata_generic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Metadata_Generic>;
};

/** input type for inserting object relation for remote table "metadata.generic" */
export type App_Loader_Metadata_Generic_Obj_Rel_Insert_Input = {
  data: App_Loader_Metadata_Generic_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_Metadata_Generic_On_Conflict>;
};

/** on_conflict condition type for table "metadata.generic" */
export type App_Loader_Metadata_Generic_On_Conflict = {
  constraint: App_Loader_Metadata_Generic_Constraint;
  update_columns?: Array<App_Loader_Metadata_Generic_Update_Column>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Bool_Exp>;
};

/** Ordering options when selecting data from "metadata.generic". */
export type App_Loader_Metadata_Generic_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
  packageName?: InputMaybe<App_Loader_Order_By>;
  targetId?: InputMaybe<App_Loader_Order_By>;
  type?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: metadata.generic */
export type App_Loader_Metadata_Generic_Pk_Columns_Input = {
  id: Scalars['app_loader_uuid']['input'];
};

/** select columns of table "metadata.generic" */
export enum App_Loader_Metadata_Generic_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PackageName = 'packageName',
  /** column name */
  TargetId = 'targetId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "metadata.generic" */
export type App_Loader_Metadata_Generic_Set_Input = {
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  type?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Enum>;
};

/** Streaming cursor of the table "metadata_generic" */
export type App_Loader_Metadata_Generic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Metadata_Generic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Metadata_Generic_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  type?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Enum>;
};

/** update columns of table "metadata.generic" */
export enum App_Loader_Metadata_Generic_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PackageName = 'packageName',
  /** column name */
  TargetId = 'targetId',
  /** column name */
  Type = 'type'
}

export type App_Loader_Metadata_Generic_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Metadata_Generic_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Metadata_Generic_Bool_Exp;
};

/** Table representing metadata required for Pype app */
export type App_Loader_Metadata_Pype = {
  __typename?: 'app_loader_metadata_pype';
  appId?: Maybe<Scalars['app_loader_uuid']['output']>;
  id: Scalars['app_loader_uuid']['output'];
  packageName: Scalars['String']['output'];
  phApiHost?: Maybe<Scalars['String']['output']>;
  phApiKey?: Maybe<Scalars['String']['output']>;
  targetId: Scalars['app_loader_uuid']['output'];
  type: App_Loader_Metadata_Pype_Type_Enum;
};

/** aggregated selection of "metadata.pype" */
export type App_Loader_Metadata_Pype_Aggregate = {
  __typename?: 'app_loader_metadata_pype_aggregate';
  aggregate?: Maybe<App_Loader_Metadata_Pype_Aggregate_Fields>;
  nodes: Array<App_Loader_Metadata_Pype>;
};

/** aggregate fields of "metadata.pype" */
export type App_Loader_Metadata_Pype_Aggregate_Fields = {
  __typename?: 'app_loader_metadata_pype_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Metadata_Pype_Max_Fields>;
  min?: Maybe<App_Loader_Metadata_Pype_Min_Fields>;
};


/** aggregate fields of "metadata.pype" */
export type App_Loader_Metadata_Pype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Metadata_Pype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "metadata.pype". All fields are combined with a logical 'AND'. */
export type App_Loader_Metadata_Pype_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Metadata_Pype_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Metadata_Pype_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Metadata_Pype_Bool_Exp>>;
  appId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  packageName?: InputMaybe<String_Comparison_Exp>;
  phApiHost?: InputMaybe<String_Comparison_Exp>;
  phApiKey?: InputMaybe<String_Comparison_Exp>;
  targetId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<App_Loader_Metadata_Pype_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "metadata.pype" */
export enum App_Loader_Metadata_Pype_Constraint {
  /** unique or primary key constraint on columns "id" */
  PypePkey = 'pype_pkey'
}

/** input type for inserting data into table "metadata.pype" */
export type App_Loader_Metadata_Pype_Insert_Input = {
  appId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  phApiHost?: InputMaybe<Scalars['String']['input']>;
  phApiKey?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  type?: InputMaybe<App_Loader_Metadata_Pype_Type_Enum>;
};

/** aggregate max on columns */
export type App_Loader_Metadata_Pype_Max_Fields = {
  __typename?: 'app_loader_metadata_pype_max_fields';
  appId?: Maybe<Scalars['app_loader_uuid']['output']>;
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  packageName?: Maybe<Scalars['String']['output']>;
  phApiHost?: Maybe<Scalars['String']['output']>;
  phApiKey?: Maybe<Scalars['String']['output']>;
  targetId?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Metadata_Pype_Min_Fields = {
  __typename?: 'app_loader_metadata_pype_min_fields';
  appId?: Maybe<Scalars['app_loader_uuid']['output']>;
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
  packageName?: Maybe<Scalars['String']['output']>;
  phApiHost?: Maybe<Scalars['String']['output']>;
  phApiKey?: Maybe<Scalars['String']['output']>;
  targetId?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** response of any mutation on the table "metadata.pype" */
export type App_Loader_Metadata_Pype_Mutation_Response = {
  __typename?: 'app_loader_metadata_pype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Metadata_Pype>;
};

/** input type for inserting object relation for remote table "metadata.pype" */
export type App_Loader_Metadata_Pype_Obj_Rel_Insert_Input = {
  data: App_Loader_Metadata_Pype_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_Metadata_Pype_On_Conflict>;
};

/** on_conflict condition type for table "metadata.pype" */
export type App_Loader_Metadata_Pype_On_Conflict = {
  constraint: App_Loader_Metadata_Pype_Constraint;
  update_columns?: Array<App_Loader_Metadata_Pype_Update_Column>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Bool_Exp>;
};

/** Ordering options when selecting data from "metadata.pype". */
export type App_Loader_Metadata_Pype_Order_By = {
  appId?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  packageName?: InputMaybe<App_Loader_Order_By>;
  phApiHost?: InputMaybe<App_Loader_Order_By>;
  phApiKey?: InputMaybe<App_Loader_Order_By>;
  targetId?: InputMaybe<App_Loader_Order_By>;
  type?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: metadata.pype */
export type App_Loader_Metadata_Pype_Pk_Columns_Input = {
  id: Scalars['app_loader_uuid']['input'];
};

/** select columns of table "metadata.pype" */
export enum App_Loader_Metadata_Pype_Select_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  Id = 'id',
  /** column name */
  PackageName = 'packageName',
  /** column name */
  PhApiHost = 'phApiHost',
  /** column name */
  PhApiKey = 'phApiKey',
  /** column name */
  TargetId = 'targetId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "metadata.pype" */
export type App_Loader_Metadata_Pype_Set_Input = {
  appId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  phApiHost?: InputMaybe<Scalars['String']['input']>;
  phApiKey?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  type?: InputMaybe<App_Loader_Metadata_Pype_Type_Enum>;
};

/** Streaming cursor of the table "metadata_pype" */
export type App_Loader_Metadata_Pype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Metadata_Pype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Metadata_Pype_Stream_Cursor_Value_Input = {
  appId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  phApiHost?: InputMaybe<Scalars['String']['input']>;
  phApiKey?: InputMaybe<Scalars['String']['input']>;
  targetId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  type?: InputMaybe<App_Loader_Metadata_Pype_Type_Enum>;
};

/** enum table representing types of Pype */
export type App_Loader_Metadata_Pype_Type = {
  __typename?: 'app_loader_metadata_pype_type';
  name: Scalars['String']['output'];
};

/** aggregated selection of "metadata.pype_type" */
export type App_Loader_Metadata_Pype_Type_Aggregate = {
  __typename?: 'app_loader_metadata_pype_type_aggregate';
  aggregate?: Maybe<App_Loader_Metadata_Pype_Type_Aggregate_Fields>;
  nodes: Array<App_Loader_Metadata_Pype_Type>;
};

/** aggregate fields of "metadata.pype_type" */
export type App_Loader_Metadata_Pype_Type_Aggregate_Fields = {
  __typename?: 'app_loader_metadata_pype_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Metadata_Pype_Type_Max_Fields>;
  min?: Maybe<App_Loader_Metadata_Pype_Type_Min_Fields>;
};


/** aggregate fields of "metadata.pype_type" */
export type App_Loader_Metadata_Pype_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "metadata.pype_type". All fields are combined with a logical 'AND'. */
export type App_Loader_Metadata_Pype_Type_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Metadata_Pype_Type_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "metadata.pype_type" */
export enum App_Loader_Metadata_Pype_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  PypeTypeEnumPkey = 'pype_type_enum_pkey'
}

export enum App_Loader_Metadata_Pype_Type_Enum {
  Default = 'default',
  JsMode = 'jsMode'
}

/** Boolean expression to compare columns of type "metadata_pype_type_enum". All fields are combined with logical 'AND'. */
export type App_Loader_Metadata_Pype_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<App_Loader_Metadata_Pype_Type_Enum>;
  _in?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<App_Loader_Metadata_Pype_Type_Enum>;
  _nin?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Enum>>;
};

/** input type for inserting data into table "metadata.pype_type" */
export type App_Loader_Metadata_Pype_Type_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Metadata_Pype_Type_Max_Fields = {
  __typename?: 'app_loader_metadata_pype_type_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Metadata_Pype_Type_Min_Fields = {
  __typename?: 'app_loader_metadata_pype_type_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "metadata.pype_type" */
export type App_Loader_Metadata_Pype_Type_Mutation_Response = {
  __typename?: 'app_loader_metadata_pype_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Metadata_Pype_Type>;
};

/** on_conflict condition type for table "metadata.pype_type" */
export type App_Loader_Metadata_Pype_Type_On_Conflict = {
  constraint: App_Loader_Metadata_Pype_Type_Constraint;
  update_columns?: Array<App_Loader_Metadata_Pype_Type_Update_Column>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "metadata.pype_type". */
export type App_Loader_Metadata_Pype_Type_Order_By = {
  name?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: metadata.pype_type */
export type App_Loader_Metadata_Pype_Type_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "metadata.pype_type" */
export enum App_Loader_Metadata_Pype_Type_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "metadata.pype_type" */
export type App_Loader_Metadata_Pype_Type_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "metadata_pype_type" */
export type App_Loader_Metadata_Pype_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Metadata_Pype_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Metadata_Pype_Type_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "metadata.pype_type" */
export enum App_Loader_Metadata_Pype_Type_Update_Column {
  /** column name */
  Name = 'name'
}

export type App_Loader_Metadata_Pype_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Metadata_Pype_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Metadata_Pype_Type_Bool_Exp;
};

/** update columns of table "metadata.pype" */
export enum App_Loader_Metadata_Pype_Update_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  Id = 'id',
  /** column name */
  PackageName = 'packageName',
  /** column name */
  PhApiHost = 'phApiHost',
  /** column name */
  PhApiKey = 'phApiKey',
  /** column name */
  TargetId = 'targetId',
  /** column name */
  Type = 'type'
}

export type App_Loader_Metadata_Pype_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Metadata_Pype_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Metadata_Pype_Bool_Exp;
};

export type App_Loader_Mutation_Root = {
  __typename?: 'app_loader_mutation_root';
  /** delete data from the table: "app" */
  delete_app?: Maybe<App_Loader_App_Mutation_Response>;
  /** delete single row from the table: "app" */
  delete_app_by_pk?: Maybe<App_Loader_App>;
  /** delete data from the table: "conditions" */
  delete_conditions?: Maybe<App_Loader_Conditions_Mutation_Response>;
  /** delete single row from the table: "conditions" */
  delete_conditions_by_pk?: Maybe<App_Loader_Conditions>;
  /** delete data from the table: "customer" */
  delete_customer?: Maybe<App_Loader_Customer_Mutation_Response>;
  /** delete single row from the table: "customer" */
  delete_customer_by_pk?: Maybe<App_Loader_Customer>;
  /** delete data from the table: "deployment" */
  delete_deployment?: Maybe<App_Loader_Deployment_Mutation_Response>;
  /** delete single row from the table: "deployment" */
  delete_deployment_by_pk?: Maybe<App_Loader_Deployment>;
  /** delete data from the table: "environment" */
  delete_environment?: Maybe<App_Loader_Environment_Mutation_Response>;
  /** delete single row from the table: "environment" */
  delete_environment_by_pk?: Maybe<App_Loader_Environment>;
  /** delete data from the table: "latest_schema.anonymous_session" */
  delete_latest_schema_anonymous_session?: Maybe<App_Loader_Latest_Schema_Anonymous_Session_Mutation_Response>;
  /** delete single row from the table: "latest_schema.anonymous_session" */
  delete_latest_schema_anonymous_session_by_pk?: Maybe<App_Loader_Latest_Schema_Anonymous_Session>;
  /** delete data from the table: "latest_schema.packages" */
  delete_latest_schema_packages?: Maybe<App_Loader_Latest_Schema_Packages_Mutation_Response>;
  /** delete single row from the table: "latest_schema.packages" */
  delete_latest_schema_packages_by_pk?: Maybe<App_Loader_Latest_Schema_Packages>;
  /** delete data from the table: "latest_schema.packages_type" */
  delete_latest_schema_packages_type?: Maybe<App_Loader_Latest_Schema_Packages_Type_Mutation_Response>;
  /** delete single row from the table: "latest_schema.packages_type" */
  delete_latest_schema_packages_type_by_pk?: Maybe<App_Loader_Latest_Schema_Packages_Type>;
  /** delete data from the table: "metadata.analytics" */
  delete_metadata_analytics?: Maybe<App_Loader_Metadata_Analytics_Mutation_Response>;
  /** delete single row from the table: "metadata.analytics" */
  delete_metadata_analytics_by_pk?: Maybe<App_Loader_Metadata_Analytics>;
  /** delete data from the table: "metadata.cc_position_option" */
  delete_metadata_cc_position_option?: Maybe<App_Loader_Metadata_Cc_Position_Option_Mutation_Response>;
  /** delete single row from the table: "metadata.cc_position_option" */
  delete_metadata_cc_position_option_by_pk?: Maybe<App_Loader_Metadata_Cc_Position_Option>;
  /** delete data from the table: "metadata.cc_widget" */
  delete_metadata_cc_widget?: Maybe<App_Loader_Metadata_Cc_Widget_Mutation_Response>;
  /** delete data from the table: "metadata.cc_widget_bubble_type" */
  delete_metadata_cc_widget_bubble_type?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Mutation_Response>;
  /** delete single row from the table: "metadata.cc_widget_bubble_type" */
  delete_metadata_cc_widget_bubble_type_by_pk?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type>;
  /** delete single row from the table: "metadata.cc_widget" */
  delete_metadata_cc_widget_by_pk?: Maybe<App_Loader_Metadata_Cc_Widget>;
  /** delete data from the table: "metadata.generic" */
  delete_metadata_generic?: Maybe<App_Loader_Metadata_Generic_Mutation_Response>;
  /** delete single row from the table: "metadata.generic" */
  delete_metadata_generic_by_pk?: Maybe<App_Loader_Metadata_Generic>;
  /** delete data from the table: "metadata.generic_module_type" */
  delete_metadata_generic_module_type?: Maybe<App_Loader_Metadata_Generic_Module_Type_Mutation_Response>;
  /** delete single row from the table: "metadata.generic_module_type" */
  delete_metadata_generic_module_type_by_pk?: Maybe<App_Loader_Metadata_Generic_Module_Type>;
  /** delete data from the table: "metadata.pype" */
  delete_metadata_pype?: Maybe<App_Loader_Metadata_Pype_Mutation_Response>;
  /** delete single row from the table: "metadata.pype" */
  delete_metadata_pype_by_pk?: Maybe<App_Loader_Metadata_Pype>;
  /** delete data from the table: "metadata.pype_type" */
  delete_metadata_pype_type?: Maybe<App_Loader_Metadata_Pype_Type_Mutation_Response>;
  /** delete single row from the table: "metadata.pype_type" */
  delete_metadata_pype_type_by_pk?: Maybe<App_Loader_Metadata_Pype_Type>;
  /** delete data from the table: "n_analytic_package_configuration" */
  delete_n_analytic_package_configuration?: Maybe<App_Loader_N_Analytic_Package_Configuration_Mutation_Response>;
  /** delete single row from the table: "n_analytic_package_configuration" */
  delete_n_analytic_package_configuration_by_pk?: Maybe<App_Loader_N_Analytic_Package_Configuration>;
  /** delete data from the table: "n_application" */
  delete_n_application?: Maybe<App_Loader_N_Application_Mutation_Response>;
  /** delete single row from the table: "n_application" */
  delete_n_application_by_pk?: Maybe<App_Loader_N_Application>;
  /** delete data from the table: "n_domain" */
  delete_n_domain?: Maybe<App_Loader_N_Domain_Mutation_Response>;
  /** delete single row from the table: "n_domain" */
  delete_n_domain_by_pk?: Maybe<App_Loader_N_Domain>;
  /** delete data from the table: "n_package" */
  delete_n_package?: Maybe<App_Loader_N_Package_Mutation_Response>;
  /** delete single row from the table: "n_package" */
  delete_n_package_by_pk?: Maybe<App_Loader_N_Package>;
  /** delete data from the table: "n_pype_package_configuration" */
  delete_n_pype_package_configuration?: Maybe<App_Loader_N_Pype_Package_Configuration_Mutation_Response>;
  /** delete single row from the table: "n_pype_package_configuration" */
  delete_n_pype_package_configuration_by_pk?: Maybe<App_Loader_N_Pype_Package_Configuration>;
  /** delete data from the table: "target" */
  delete_target?: Maybe<App_Loader_Target_Mutation_Response>;
  /** delete single row from the table: "target" */
  delete_target_by_pk?: Maybe<App_Loader_Target>;
  /** delete data from the table: "target_type" */
  delete_target_type?: Maybe<App_Loader_Target_Type_Mutation_Response>;
  /** delete single row from the table: "target_type" */
  delete_target_type_by_pk?: Maybe<App_Loader_Target_Type>;
  /** insert data into the table: "app" */
  insert_app?: Maybe<App_Loader_App_Mutation_Response>;
  /** insert a single row into the table: "app" */
  insert_app_one?: Maybe<App_Loader_App>;
  /** insert data into the table: "conditions" */
  insert_conditions?: Maybe<App_Loader_Conditions_Mutation_Response>;
  /** insert a single row into the table: "conditions" */
  insert_conditions_one?: Maybe<App_Loader_Conditions>;
  /** insert data into the table: "customer" */
  insert_customer?: Maybe<App_Loader_Customer_Mutation_Response>;
  /** insert a single row into the table: "customer" */
  insert_customer_one?: Maybe<App_Loader_Customer>;
  /** insert data into the table: "deployment" */
  insert_deployment?: Maybe<App_Loader_Deployment_Mutation_Response>;
  /** insert a single row into the table: "deployment" */
  insert_deployment_one?: Maybe<App_Loader_Deployment>;
  /** insert data into the table: "environment" */
  insert_environment?: Maybe<App_Loader_Environment_Mutation_Response>;
  /** insert a single row into the table: "environment" */
  insert_environment_one?: Maybe<App_Loader_Environment>;
  /** insert data into the table: "latest_schema.anonymous_session" */
  insert_latest_schema_anonymous_session?: Maybe<App_Loader_Latest_Schema_Anonymous_Session_Mutation_Response>;
  /** insert a single row into the table: "latest_schema.anonymous_session" */
  insert_latest_schema_anonymous_session_one?: Maybe<App_Loader_Latest_Schema_Anonymous_Session>;
  /** insert data into the table: "latest_schema.packages" */
  insert_latest_schema_packages?: Maybe<App_Loader_Latest_Schema_Packages_Mutation_Response>;
  /** insert a single row into the table: "latest_schema.packages" */
  insert_latest_schema_packages_one?: Maybe<App_Loader_Latest_Schema_Packages>;
  /** insert data into the table: "latest_schema.packages_type" */
  insert_latest_schema_packages_type?: Maybe<App_Loader_Latest_Schema_Packages_Type_Mutation_Response>;
  /** insert a single row into the table: "latest_schema.packages_type" */
  insert_latest_schema_packages_type_one?: Maybe<App_Loader_Latest_Schema_Packages_Type>;
  /** insert data into the table: "metadata.analytics" */
  insert_metadata_analytics?: Maybe<App_Loader_Metadata_Analytics_Mutation_Response>;
  /** insert a single row into the table: "metadata.analytics" */
  insert_metadata_analytics_one?: Maybe<App_Loader_Metadata_Analytics>;
  /** insert data into the table: "metadata.cc_position_option" */
  insert_metadata_cc_position_option?: Maybe<App_Loader_Metadata_Cc_Position_Option_Mutation_Response>;
  /** insert a single row into the table: "metadata.cc_position_option" */
  insert_metadata_cc_position_option_one?: Maybe<App_Loader_Metadata_Cc_Position_Option>;
  /** insert data into the table: "metadata.cc_widget" */
  insert_metadata_cc_widget?: Maybe<App_Loader_Metadata_Cc_Widget_Mutation_Response>;
  /** insert data into the table: "metadata.cc_widget_bubble_type" */
  insert_metadata_cc_widget_bubble_type?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Mutation_Response>;
  /** insert a single row into the table: "metadata.cc_widget_bubble_type" */
  insert_metadata_cc_widget_bubble_type_one?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type>;
  /** insert a single row into the table: "metadata.cc_widget" */
  insert_metadata_cc_widget_one?: Maybe<App_Loader_Metadata_Cc_Widget>;
  /** insert data into the table: "metadata.generic" */
  insert_metadata_generic?: Maybe<App_Loader_Metadata_Generic_Mutation_Response>;
  /** insert data into the table: "metadata.generic_module_type" */
  insert_metadata_generic_module_type?: Maybe<App_Loader_Metadata_Generic_Module_Type_Mutation_Response>;
  /** insert a single row into the table: "metadata.generic_module_type" */
  insert_metadata_generic_module_type_one?: Maybe<App_Loader_Metadata_Generic_Module_Type>;
  /** insert a single row into the table: "metadata.generic" */
  insert_metadata_generic_one?: Maybe<App_Loader_Metadata_Generic>;
  /** insert data into the table: "metadata.pype" */
  insert_metadata_pype?: Maybe<App_Loader_Metadata_Pype_Mutation_Response>;
  /** insert a single row into the table: "metadata.pype" */
  insert_metadata_pype_one?: Maybe<App_Loader_Metadata_Pype>;
  /** insert data into the table: "metadata.pype_type" */
  insert_metadata_pype_type?: Maybe<App_Loader_Metadata_Pype_Type_Mutation_Response>;
  /** insert a single row into the table: "metadata.pype_type" */
  insert_metadata_pype_type_one?: Maybe<App_Loader_Metadata_Pype_Type>;
  /** insert data into the table: "n_analytic_package_configuration" */
  insert_n_analytic_package_configuration?: Maybe<App_Loader_N_Analytic_Package_Configuration_Mutation_Response>;
  /** insert a single row into the table: "n_analytic_package_configuration" */
  insert_n_analytic_package_configuration_one?: Maybe<App_Loader_N_Analytic_Package_Configuration>;
  /** insert data into the table: "n_application" */
  insert_n_application?: Maybe<App_Loader_N_Application_Mutation_Response>;
  /** insert a single row into the table: "n_application" */
  insert_n_application_one?: Maybe<App_Loader_N_Application>;
  /** insert data into the table: "n_domain" */
  insert_n_domain?: Maybe<App_Loader_N_Domain_Mutation_Response>;
  /** insert a single row into the table: "n_domain" */
  insert_n_domain_one?: Maybe<App_Loader_N_Domain>;
  /** insert data into the table: "n_package" */
  insert_n_package?: Maybe<App_Loader_N_Package_Mutation_Response>;
  /** insert a single row into the table: "n_package" */
  insert_n_package_one?: Maybe<App_Loader_N_Package>;
  /** insert data into the table: "n_pype_package_configuration" */
  insert_n_pype_package_configuration?: Maybe<App_Loader_N_Pype_Package_Configuration_Mutation_Response>;
  /** insert a single row into the table: "n_pype_package_configuration" */
  insert_n_pype_package_configuration_one?: Maybe<App_Loader_N_Pype_Package_Configuration>;
  /** insert data into the table: "target" */
  insert_target?: Maybe<App_Loader_Target_Mutation_Response>;
  /** insert a single row into the table: "target" */
  insert_target_one?: Maybe<App_Loader_Target>;
  /** insert data into the table: "target_type" */
  insert_target_type?: Maybe<App_Loader_Target_Type_Mutation_Response>;
  /** insert a single row into the table: "target_type" */
  insert_target_type_one?: Maybe<App_Loader_Target_Type>;
  /** update data of the table: "app" */
  update_app?: Maybe<App_Loader_App_Mutation_Response>;
  /** update single row of the table: "app" */
  update_app_by_pk?: Maybe<App_Loader_App>;
  /** update multiples rows of table: "app" */
  update_app_many?: Maybe<Array<Maybe<App_Loader_App_Mutation_Response>>>;
  /** update data of the table: "conditions" */
  update_conditions?: Maybe<App_Loader_Conditions_Mutation_Response>;
  /** update single row of the table: "conditions" */
  update_conditions_by_pk?: Maybe<App_Loader_Conditions>;
  /** update multiples rows of table: "conditions" */
  update_conditions_many?: Maybe<Array<Maybe<App_Loader_Conditions_Mutation_Response>>>;
  /** update data of the table: "customer" */
  update_customer?: Maybe<App_Loader_Customer_Mutation_Response>;
  /** update single row of the table: "customer" */
  update_customer_by_pk?: Maybe<App_Loader_Customer>;
  /** update multiples rows of table: "customer" */
  update_customer_many?: Maybe<Array<Maybe<App_Loader_Customer_Mutation_Response>>>;
  /** update data of the table: "deployment" */
  update_deployment?: Maybe<App_Loader_Deployment_Mutation_Response>;
  /** update single row of the table: "deployment" */
  update_deployment_by_pk?: Maybe<App_Loader_Deployment>;
  /** update multiples rows of table: "deployment" */
  update_deployment_many?: Maybe<Array<Maybe<App_Loader_Deployment_Mutation_Response>>>;
  /** update data of the table: "environment" */
  update_environment?: Maybe<App_Loader_Environment_Mutation_Response>;
  /** update single row of the table: "environment" */
  update_environment_by_pk?: Maybe<App_Loader_Environment>;
  /** update multiples rows of table: "environment" */
  update_environment_many?: Maybe<Array<Maybe<App_Loader_Environment_Mutation_Response>>>;
  /** update data of the table: "latest_schema.anonymous_session" */
  update_latest_schema_anonymous_session?: Maybe<App_Loader_Latest_Schema_Anonymous_Session_Mutation_Response>;
  /** update single row of the table: "latest_schema.anonymous_session" */
  update_latest_schema_anonymous_session_by_pk?: Maybe<App_Loader_Latest_Schema_Anonymous_Session>;
  /** update multiples rows of table: "latest_schema.anonymous_session" */
  update_latest_schema_anonymous_session_many?: Maybe<Array<Maybe<App_Loader_Latest_Schema_Anonymous_Session_Mutation_Response>>>;
  /** update data of the table: "latest_schema.packages" */
  update_latest_schema_packages?: Maybe<App_Loader_Latest_Schema_Packages_Mutation_Response>;
  /** update single row of the table: "latest_schema.packages" */
  update_latest_schema_packages_by_pk?: Maybe<App_Loader_Latest_Schema_Packages>;
  /** update multiples rows of table: "latest_schema.packages" */
  update_latest_schema_packages_many?: Maybe<Array<Maybe<App_Loader_Latest_Schema_Packages_Mutation_Response>>>;
  /** update data of the table: "latest_schema.packages_type" */
  update_latest_schema_packages_type?: Maybe<App_Loader_Latest_Schema_Packages_Type_Mutation_Response>;
  /** update single row of the table: "latest_schema.packages_type" */
  update_latest_schema_packages_type_by_pk?: Maybe<App_Loader_Latest_Schema_Packages_Type>;
  /** update multiples rows of table: "latest_schema.packages_type" */
  update_latest_schema_packages_type_many?: Maybe<Array<Maybe<App_Loader_Latest_Schema_Packages_Type_Mutation_Response>>>;
  /** update data of the table: "metadata.analytics" */
  update_metadata_analytics?: Maybe<App_Loader_Metadata_Analytics_Mutation_Response>;
  /** update single row of the table: "metadata.analytics" */
  update_metadata_analytics_by_pk?: Maybe<App_Loader_Metadata_Analytics>;
  /** update multiples rows of table: "metadata.analytics" */
  update_metadata_analytics_many?: Maybe<Array<Maybe<App_Loader_Metadata_Analytics_Mutation_Response>>>;
  /** update data of the table: "metadata.cc_position_option" */
  update_metadata_cc_position_option?: Maybe<App_Loader_Metadata_Cc_Position_Option_Mutation_Response>;
  /** update single row of the table: "metadata.cc_position_option" */
  update_metadata_cc_position_option_by_pk?: Maybe<App_Loader_Metadata_Cc_Position_Option>;
  /** update multiples rows of table: "metadata.cc_position_option" */
  update_metadata_cc_position_option_many?: Maybe<Array<Maybe<App_Loader_Metadata_Cc_Position_Option_Mutation_Response>>>;
  /** update data of the table: "metadata.cc_widget" */
  update_metadata_cc_widget?: Maybe<App_Loader_Metadata_Cc_Widget_Mutation_Response>;
  /** update data of the table: "metadata.cc_widget_bubble_type" */
  update_metadata_cc_widget_bubble_type?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Mutation_Response>;
  /** update single row of the table: "metadata.cc_widget_bubble_type" */
  update_metadata_cc_widget_bubble_type_by_pk?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type>;
  /** update multiples rows of table: "metadata.cc_widget_bubble_type" */
  update_metadata_cc_widget_bubble_type_many?: Maybe<Array<Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Mutation_Response>>>;
  /** update single row of the table: "metadata.cc_widget" */
  update_metadata_cc_widget_by_pk?: Maybe<App_Loader_Metadata_Cc_Widget>;
  /** update multiples rows of table: "metadata.cc_widget" */
  update_metadata_cc_widget_many?: Maybe<Array<Maybe<App_Loader_Metadata_Cc_Widget_Mutation_Response>>>;
  /** update data of the table: "metadata.generic" */
  update_metadata_generic?: Maybe<App_Loader_Metadata_Generic_Mutation_Response>;
  /** update single row of the table: "metadata.generic" */
  update_metadata_generic_by_pk?: Maybe<App_Loader_Metadata_Generic>;
  /** update multiples rows of table: "metadata.generic" */
  update_metadata_generic_many?: Maybe<Array<Maybe<App_Loader_Metadata_Generic_Mutation_Response>>>;
  /** update data of the table: "metadata.generic_module_type" */
  update_metadata_generic_module_type?: Maybe<App_Loader_Metadata_Generic_Module_Type_Mutation_Response>;
  /** update single row of the table: "metadata.generic_module_type" */
  update_metadata_generic_module_type_by_pk?: Maybe<App_Loader_Metadata_Generic_Module_Type>;
  /** update multiples rows of table: "metadata.generic_module_type" */
  update_metadata_generic_module_type_many?: Maybe<Array<Maybe<App_Loader_Metadata_Generic_Module_Type_Mutation_Response>>>;
  /** update data of the table: "metadata.pype" */
  update_metadata_pype?: Maybe<App_Loader_Metadata_Pype_Mutation_Response>;
  /** update single row of the table: "metadata.pype" */
  update_metadata_pype_by_pk?: Maybe<App_Loader_Metadata_Pype>;
  /** update multiples rows of table: "metadata.pype" */
  update_metadata_pype_many?: Maybe<Array<Maybe<App_Loader_Metadata_Pype_Mutation_Response>>>;
  /** update data of the table: "metadata.pype_type" */
  update_metadata_pype_type?: Maybe<App_Loader_Metadata_Pype_Type_Mutation_Response>;
  /** update single row of the table: "metadata.pype_type" */
  update_metadata_pype_type_by_pk?: Maybe<App_Loader_Metadata_Pype_Type>;
  /** update multiples rows of table: "metadata.pype_type" */
  update_metadata_pype_type_many?: Maybe<Array<Maybe<App_Loader_Metadata_Pype_Type_Mutation_Response>>>;
  /** update data of the table: "n_analytic_package_configuration" */
  update_n_analytic_package_configuration?: Maybe<App_Loader_N_Analytic_Package_Configuration_Mutation_Response>;
  /** update single row of the table: "n_analytic_package_configuration" */
  update_n_analytic_package_configuration_by_pk?: Maybe<App_Loader_N_Analytic_Package_Configuration>;
  /** update multiples rows of table: "n_analytic_package_configuration" */
  update_n_analytic_package_configuration_many?: Maybe<Array<Maybe<App_Loader_N_Analytic_Package_Configuration_Mutation_Response>>>;
  /** update data of the table: "n_application" */
  update_n_application?: Maybe<App_Loader_N_Application_Mutation_Response>;
  /** update single row of the table: "n_application" */
  update_n_application_by_pk?: Maybe<App_Loader_N_Application>;
  /** update multiples rows of table: "n_application" */
  update_n_application_many?: Maybe<Array<Maybe<App_Loader_N_Application_Mutation_Response>>>;
  /** update data of the table: "n_domain" */
  update_n_domain?: Maybe<App_Loader_N_Domain_Mutation_Response>;
  /** update single row of the table: "n_domain" */
  update_n_domain_by_pk?: Maybe<App_Loader_N_Domain>;
  /** update multiples rows of table: "n_domain" */
  update_n_domain_many?: Maybe<Array<Maybe<App_Loader_N_Domain_Mutation_Response>>>;
  /** update data of the table: "n_package" */
  update_n_package?: Maybe<App_Loader_N_Package_Mutation_Response>;
  /** update single row of the table: "n_package" */
  update_n_package_by_pk?: Maybe<App_Loader_N_Package>;
  /** update multiples rows of table: "n_package" */
  update_n_package_many?: Maybe<Array<Maybe<App_Loader_N_Package_Mutation_Response>>>;
  /** update data of the table: "n_pype_package_configuration" */
  update_n_pype_package_configuration?: Maybe<App_Loader_N_Pype_Package_Configuration_Mutation_Response>;
  /** update single row of the table: "n_pype_package_configuration" */
  update_n_pype_package_configuration_by_pk?: Maybe<App_Loader_N_Pype_Package_Configuration>;
  /** update multiples rows of table: "n_pype_package_configuration" */
  update_n_pype_package_configuration_many?: Maybe<Array<Maybe<App_Loader_N_Pype_Package_Configuration_Mutation_Response>>>;
  /** update data of the table: "target" */
  update_target?: Maybe<App_Loader_Target_Mutation_Response>;
  /** update single row of the table: "target" */
  update_target_by_pk?: Maybe<App_Loader_Target>;
  /** update multiples rows of table: "target" */
  update_target_many?: Maybe<Array<Maybe<App_Loader_Target_Mutation_Response>>>;
  /** update data of the table: "target_type" */
  update_target_type?: Maybe<App_Loader_Target_Type_Mutation_Response>;
  /** update single row of the table: "target_type" */
  update_target_type_by_pk?: Maybe<App_Loader_Target_Type>;
  /** update multiples rows of table: "target_type" */
  update_target_type_many?: Maybe<Array<Maybe<App_Loader_Target_Type_Mutation_Response>>>;
};


export type App_Loader_Mutation_RootDelete_AppArgs = {
  where: App_Loader_App_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_App_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Mutation_RootDelete_ConditionsArgs = {
  where: App_Loader_Conditions_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Conditions_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Mutation_RootDelete_CustomerArgs = {
  where: App_Loader_Customer_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Customer_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Mutation_RootDelete_DeploymentArgs = {
  where: App_Loader_Deployment_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Deployment_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Mutation_RootDelete_EnvironmentArgs = {
  where: App_Loader_Environment_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Environment_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Mutation_RootDelete_Latest_Schema_Anonymous_SessionArgs = {
  where: App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Latest_Schema_Anonymous_Session_By_PkArgs = {
  session_id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Mutation_RootDelete_Latest_Schema_PackagesArgs = {
  where: App_Loader_Latest_Schema_Packages_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Latest_Schema_Packages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type App_Loader_Mutation_RootDelete_Latest_Schema_Packages_TypeArgs = {
  where: App_Loader_Latest_Schema_Packages_Type_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Latest_Schema_Packages_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type App_Loader_Mutation_RootDelete_Metadata_AnalyticsArgs = {
  where: App_Loader_Metadata_Analytics_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Metadata_Analytics_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Mutation_RootDelete_Metadata_Cc_Position_OptionArgs = {
  where: App_Loader_Metadata_Cc_Position_Option_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Metadata_Cc_Position_Option_By_PkArgs = {
  position: Scalars['String']['input'];
};


export type App_Loader_Mutation_RootDelete_Metadata_Cc_WidgetArgs = {
  where: App_Loader_Metadata_Cc_Widget_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Metadata_Cc_Widget_Bubble_TypeArgs = {
  where: App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Metadata_Cc_Widget_Bubble_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type App_Loader_Mutation_RootDelete_Metadata_Cc_Widget_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Mutation_RootDelete_Metadata_GenericArgs = {
  where: App_Loader_Metadata_Generic_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Metadata_Generic_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Mutation_RootDelete_Metadata_Generic_Module_TypeArgs = {
  where: App_Loader_Metadata_Generic_Module_Type_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Metadata_Generic_Module_Type_By_PkArgs = {
  moduleType: Scalars['String']['input'];
};


export type App_Loader_Mutation_RootDelete_Metadata_PypeArgs = {
  where: App_Loader_Metadata_Pype_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Metadata_Pype_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Mutation_RootDelete_Metadata_Pype_TypeArgs = {
  where: App_Loader_Metadata_Pype_Type_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Metadata_Pype_Type_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type App_Loader_Mutation_RootDelete_N_Analytic_Package_ConfigurationArgs = {
  where: App_Loader_N_Analytic_Package_Configuration_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_N_Analytic_Package_Configuration_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Mutation_RootDelete_N_ApplicationArgs = {
  where: App_Loader_N_Application_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_N_Application_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Mutation_RootDelete_N_DomainArgs = {
  where: App_Loader_N_Domain_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_N_Domain_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Mutation_RootDelete_N_PackageArgs = {
  where: App_Loader_N_Package_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_N_Package_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Mutation_RootDelete_N_Pype_Package_ConfigurationArgs = {
  where: App_Loader_N_Pype_Package_Configuration_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_N_Pype_Package_Configuration_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Mutation_RootDelete_TargetArgs = {
  where: App_Loader_Target_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Target_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Mutation_RootDelete_Target_TypeArgs = {
  where: App_Loader_Target_Type_Bool_Exp;
};


export type App_Loader_Mutation_RootDelete_Target_Type_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type App_Loader_Mutation_RootInsert_AppArgs = {
  objects: Array<App_Loader_App_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_App_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_App_OneArgs = {
  object: App_Loader_App_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_App_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_ConditionsArgs = {
  objects: Array<App_Loader_Conditions_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Conditions_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Conditions_OneArgs = {
  object: App_Loader_Conditions_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Conditions_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_CustomerArgs = {
  objects: Array<App_Loader_Customer_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Customer_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Customer_OneArgs = {
  object: App_Loader_Customer_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Customer_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_DeploymentArgs = {
  objects: Array<App_Loader_Deployment_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Deployment_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Deployment_OneArgs = {
  object: App_Loader_Deployment_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Deployment_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_EnvironmentArgs = {
  objects: Array<App_Loader_Environment_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Environment_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Environment_OneArgs = {
  object: App_Loader_Environment_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Environment_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Latest_Schema_Anonymous_SessionArgs = {
  objects: Array<App_Loader_Latest_Schema_Anonymous_Session_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Latest_Schema_Anonymous_Session_OneArgs = {
  object: App_Loader_Latest_Schema_Anonymous_Session_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Latest_Schema_PackagesArgs = {
  objects: Array<App_Loader_Latest_Schema_Packages_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Latest_Schema_Packages_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Latest_Schema_Packages_OneArgs = {
  object: App_Loader_Latest_Schema_Packages_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Latest_Schema_Packages_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Latest_Schema_Packages_TypeArgs = {
  objects: Array<App_Loader_Latest_Schema_Packages_Type_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Latest_Schema_Packages_Type_OneArgs = {
  object: App_Loader_Latest_Schema_Packages_Type_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_AnalyticsArgs = {
  objects: Array<App_Loader_Metadata_Analytics_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Metadata_Analytics_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Analytics_OneArgs = {
  object: App_Loader_Metadata_Analytics_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Metadata_Analytics_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Cc_Position_OptionArgs = {
  objects: Array<App_Loader_Metadata_Cc_Position_Option_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Cc_Position_Option_OneArgs = {
  object: App_Loader_Metadata_Cc_Position_Option_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Cc_WidgetArgs = {
  objects: Array<App_Loader_Metadata_Cc_Widget_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Metadata_Cc_Widget_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Cc_Widget_Bubble_TypeArgs = {
  objects: Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Cc_Widget_Bubble_Type_OneArgs = {
  object: App_Loader_Metadata_Cc_Widget_Bubble_Type_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Cc_Widget_OneArgs = {
  object: App_Loader_Metadata_Cc_Widget_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Metadata_Cc_Widget_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_GenericArgs = {
  objects: Array<App_Loader_Metadata_Generic_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Metadata_Generic_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Generic_Module_TypeArgs = {
  objects: Array<App_Loader_Metadata_Generic_Module_Type_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Generic_Module_Type_OneArgs = {
  object: App_Loader_Metadata_Generic_Module_Type_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Generic_OneArgs = {
  object: App_Loader_Metadata_Generic_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Metadata_Generic_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_PypeArgs = {
  objects: Array<App_Loader_Metadata_Pype_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Metadata_Pype_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Pype_OneArgs = {
  object: App_Loader_Metadata_Pype_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Metadata_Pype_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Pype_TypeArgs = {
  objects: Array<App_Loader_Metadata_Pype_Type_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Metadata_Pype_Type_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Metadata_Pype_Type_OneArgs = {
  object: App_Loader_Metadata_Pype_Type_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Metadata_Pype_Type_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_N_Analytic_Package_ConfigurationArgs = {
  objects: Array<App_Loader_N_Analytic_Package_Configuration_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_N_Analytic_Package_Configuration_OneArgs = {
  object: App_Loader_N_Analytic_Package_Configuration_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_N_ApplicationArgs = {
  objects: Array<App_Loader_N_Application_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_N_Application_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_N_Application_OneArgs = {
  object: App_Loader_N_Application_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_N_Application_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_N_DomainArgs = {
  objects: Array<App_Loader_N_Domain_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_N_Domain_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_N_Domain_OneArgs = {
  object: App_Loader_N_Domain_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_N_Domain_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_N_PackageArgs = {
  objects: Array<App_Loader_N_Package_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_N_Package_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_N_Package_OneArgs = {
  object: App_Loader_N_Package_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_N_Package_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_N_Pype_Package_ConfigurationArgs = {
  objects: Array<App_Loader_N_Pype_Package_Configuration_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_N_Pype_Package_Configuration_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_N_Pype_Package_Configuration_OneArgs = {
  object: App_Loader_N_Pype_Package_Configuration_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_N_Pype_Package_Configuration_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_TargetArgs = {
  objects: Array<App_Loader_Target_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Target_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Target_OneArgs = {
  object: App_Loader_Target_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Target_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Target_TypeArgs = {
  objects: Array<App_Loader_Target_Type_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Target_Type_On_Conflict>;
};


export type App_Loader_Mutation_RootInsert_Target_Type_OneArgs = {
  object: App_Loader_Target_Type_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Target_Type_On_Conflict>;
};


export type App_Loader_Mutation_RootUpdate_AppArgs = {
  _set?: InputMaybe<App_Loader_App_Set_Input>;
  where: App_Loader_App_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_App_By_PkArgs = {
  _set?: InputMaybe<App_Loader_App_Set_Input>;
  pk_columns: App_Loader_App_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_App_ManyArgs = {
  updates: Array<App_Loader_App_Updates>;
};


export type App_Loader_Mutation_RootUpdate_ConditionsArgs = {
  _append?: InputMaybe<App_Loader_Conditions_Append_Input>;
  _delete_at_path?: InputMaybe<App_Loader_Conditions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Loader_Conditions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Loader_Conditions_Delete_Key_Input>;
  _prepend?: InputMaybe<App_Loader_Conditions_Prepend_Input>;
  _set?: InputMaybe<App_Loader_Conditions_Set_Input>;
  where: App_Loader_Conditions_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Conditions_By_PkArgs = {
  _append?: InputMaybe<App_Loader_Conditions_Append_Input>;
  _delete_at_path?: InputMaybe<App_Loader_Conditions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Loader_Conditions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Loader_Conditions_Delete_Key_Input>;
  _prepend?: InputMaybe<App_Loader_Conditions_Prepend_Input>;
  _set?: InputMaybe<App_Loader_Conditions_Set_Input>;
  pk_columns: App_Loader_Conditions_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Conditions_ManyArgs = {
  updates: Array<App_Loader_Conditions_Updates>;
};


export type App_Loader_Mutation_RootUpdate_CustomerArgs = {
  _set?: InputMaybe<App_Loader_Customer_Set_Input>;
  where: App_Loader_Customer_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Customer_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Customer_Set_Input>;
  pk_columns: App_Loader_Customer_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Customer_ManyArgs = {
  updates: Array<App_Loader_Customer_Updates>;
};


export type App_Loader_Mutation_RootUpdate_DeploymentArgs = {
  _set?: InputMaybe<App_Loader_Deployment_Set_Input>;
  where: App_Loader_Deployment_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Deployment_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Deployment_Set_Input>;
  pk_columns: App_Loader_Deployment_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Deployment_ManyArgs = {
  updates: Array<App_Loader_Deployment_Updates>;
};


export type App_Loader_Mutation_RootUpdate_EnvironmentArgs = {
  _set?: InputMaybe<App_Loader_Environment_Set_Input>;
  where: App_Loader_Environment_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Environment_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Environment_Set_Input>;
  pk_columns: App_Loader_Environment_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Environment_ManyArgs = {
  updates: Array<App_Loader_Environment_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Latest_Schema_Anonymous_SessionArgs = {
  _append?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Append_Input>;
  _delete_at_path?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Delete_Key_Input>;
  _prepend?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Prepend_Input>;
  _set?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Set_Input>;
  where: App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Latest_Schema_Anonymous_Session_By_PkArgs = {
  _append?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Append_Input>;
  _delete_at_path?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Delete_Key_Input>;
  _prepend?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Prepend_Input>;
  _set?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Set_Input>;
  pk_columns: App_Loader_Latest_Schema_Anonymous_Session_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Latest_Schema_Anonymous_Session_ManyArgs = {
  updates: Array<App_Loader_Latest_Schema_Anonymous_Session_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Latest_Schema_PackagesArgs = {
  _append?: InputMaybe<App_Loader_Latest_Schema_Packages_Append_Input>;
  _delete_at_path?: InputMaybe<App_Loader_Latest_Schema_Packages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Loader_Latest_Schema_Packages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Loader_Latest_Schema_Packages_Delete_Key_Input>;
  _prepend?: InputMaybe<App_Loader_Latest_Schema_Packages_Prepend_Input>;
  _set?: InputMaybe<App_Loader_Latest_Schema_Packages_Set_Input>;
  where: App_Loader_Latest_Schema_Packages_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Latest_Schema_Packages_By_PkArgs = {
  _append?: InputMaybe<App_Loader_Latest_Schema_Packages_Append_Input>;
  _delete_at_path?: InputMaybe<App_Loader_Latest_Schema_Packages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Loader_Latest_Schema_Packages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Loader_Latest_Schema_Packages_Delete_Key_Input>;
  _prepend?: InputMaybe<App_Loader_Latest_Schema_Packages_Prepend_Input>;
  _set?: InputMaybe<App_Loader_Latest_Schema_Packages_Set_Input>;
  pk_columns: App_Loader_Latest_Schema_Packages_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Latest_Schema_Packages_ManyArgs = {
  updates: Array<App_Loader_Latest_Schema_Packages_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Latest_Schema_Packages_TypeArgs = {
  _set?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Set_Input>;
  where: App_Loader_Latest_Schema_Packages_Type_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Latest_Schema_Packages_Type_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Set_Input>;
  pk_columns: App_Loader_Latest_Schema_Packages_Type_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Latest_Schema_Packages_Type_ManyArgs = {
  updates: Array<App_Loader_Latest_Schema_Packages_Type_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Metadata_AnalyticsArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Analytics_Set_Input>;
  where: App_Loader_Metadata_Analytics_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Analytics_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Analytics_Set_Input>;
  pk_columns: App_Loader_Metadata_Analytics_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Analytics_ManyArgs = {
  updates: Array<App_Loader_Metadata_Analytics_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Cc_Position_OptionArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Set_Input>;
  where: App_Loader_Metadata_Cc_Position_Option_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Cc_Position_Option_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Set_Input>;
  pk_columns: App_Loader_Metadata_Cc_Position_Option_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Cc_Position_Option_ManyArgs = {
  updates: Array<App_Loader_Metadata_Cc_Position_Option_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Cc_WidgetArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Cc_Widget_Set_Input>;
  where: App_Loader_Metadata_Cc_Widget_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Cc_Widget_Bubble_TypeArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Set_Input>;
  where: App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Cc_Widget_Bubble_Type_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Set_Input>;
  pk_columns: App_Loader_Metadata_Cc_Widget_Bubble_Type_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Cc_Widget_Bubble_Type_ManyArgs = {
  updates: Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Cc_Widget_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Cc_Widget_Set_Input>;
  pk_columns: App_Loader_Metadata_Cc_Widget_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Cc_Widget_ManyArgs = {
  updates: Array<App_Loader_Metadata_Cc_Widget_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Metadata_GenericArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Generic_Set_Input>;
  where: App_Loader_Metadata_Generic_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Generic_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Generic_Set_Input>;
  pk_columns: App_Loader_Metadata_Generic_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Generic_ManyArgs = {
  updates: Array<App_Loader_Metadata_Generic_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Generic_Module_TypeArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Set_Input>;
  where: App_Loader_Metadata_Generic_Module_Type_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Generic_Module_Type_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Set_Input>;
  pk_columns: App_Loader_Metadata_Generic_Module_Type_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Generic_Module_Type_ManyArgs = {
  updates: Array<App_Loader_Metadata_Generic_Module_Type_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Metadata_PypeArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Pype_Set_Input>;
  where: App_Loader_Metadata_Pype_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Pype_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Pype_Set_Input>;
  pk_columns: App_Loader_Metadata_Pype_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Pype_ManyArgs = {
  updates: Array<App_Loader_Metadata_Pype_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Pype_TypeArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Pype_Type_Set_Input>;
  where: App_Loader_Metadata_Pype_Type_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Pype_Type_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Metadata_Pype_Type_Set_Input>;
  pk_columns: App_Loader_Metadata_Pype_Type_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Metadata_Pype_Type_ManyArgs = {
  updates: Array<App_Loader_Metadata_Pype_Type_Updates>;
};


export type App_Loader_Mutation_RootUpdate_N_Analytic_Package_ConfigurationArgs = {
  _inc?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Inc_Input>;
  _set?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Set_Input>;
  where: App_Loader_N_Analytic_Package_Configuration_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_N_Analytic_Package_Configuration_By_PkArgs = {
  _inc?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Inc_Input>;
  _set?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Set_Input>;
  pk_columns: App_Loader_N_Analytic_Package_Configuration_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_N_Analytic_Package_Configuration_ManyArgs = {
  updates: Array<App_Loader_N_Analytic_Package_Configuration_Updates>;
};


export type App_Loader_Mutation_RootUpdate_N_ApplicationArgs = {
  _inc?: InputMaybe<App_Loader_N_Application_Inc_Input>;
  _set?: InputMaybe<App_Loader_N_Application_Set_Input>;
  where: App_Loader_N_Application_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_N_Application_By_PkArgs = {
  _inc?: InputMaybe<App_Loader_N_Application_Inc_Input>;
  _set?: InputMaybe<App_Loader_N_Application_Set_Input>;
  pk_columns: App_Loader_N_Application_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_N_Application_ManyArgs = {
  updates: Array<App_Loader_N_Application_Updates>;
};


export type App_Loader_Mutation_RootUpdate_N_DomainArgs = {
  _inc?: InputMaybe<App_Loader_N_Domain_Inc_Input>;
  _set?: InputMaybe<App_Loader_N_Domain_Set_Input>;
  where: App_Loader_N_Domain_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_N_Domain_By_PkArgs = {
  _inc?: InputMaybe<App_Loader_N_Domain_Inc_Input>;
  _set?: InputMaybe<App_Loader_N_Domain_Set_Input>;
  pk_columns: App_Loader_N_Domain_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_N_Domain_ManyArgs = {
  updates: Array<App_Loader_N_Domain_Updates>;
};


export type App_Loader_Mutation_RootUpdate_N_PackageArgs = {
  _inc?: InputMaybe<App_Loader_N_Package_Inc_Input>;
  _set?: InputMaybe<App_Loader_N_Package_Set_Input>;
  where: App_Loader_N_Package_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_N_Package_By_PkArgs = {
  _inc?: InputMaybe<App_Loader_N_Package_Inc_Input>;
  _set?: InputMaybe<App_Loader_N_Package_Set_Input>;
  pk_columns: App_Loader_N_Package_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_N_Package_ManyArgs = {
  updates: Array<App_Loader_N_Package_Updates>;
};


export type App_Loader_Mutation_RootUpdate_N_Pype_Package_ConfigurationArgs = {
  _inc?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Inc_Input>;
  _set?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Set_Input>;
  where: App_Loader_N_Pype_Package_Configuration_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_N_Pype_Package_Configuration_By_PkArgs = {
  _inc?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Inc_Input>;
  _set?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Set_Input>;
  pk_columns: App_Loader_N_Pype_Package_Configuration_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_N_Pype_Package_Configuration_ManyArgs = {
  updates: Array<App_Loader_N_Pype_Package_Configuration_Updates>;
};


export type App_Loader_Mutation_RootUpdate_TargetArgs = {
  _set?: InputMaybe<App_Loader_Target_Set_Input>;
  where: App_Loader_Target_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Target_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Target_Set_Input>;
  pk_columns: App_Loader_Target_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Target_ManyArgs = {
  updates: Array<App_Loader_Target_Updates>;
};


export type App_Loader_Mutation_RootUpdate_Target_TypeArgs = {
  _set?: InputMaybe<App_Loader_Target_Type_Set_Input>;
  where: App_Loader_Target_Type_Bool_Exp;
};


export type App_Loader_Mutation_RootUpdate_Target_Type_By_PkArgs = {
  _set?: InputMaybe<App_Loader_Target_Type_Set_Input>;
  pk_columns: App_Loader_Target_Type_Pk_Columns_Input;
};


export type App_Loader_Mutation_RootUpdate_Target_Type_ManyArgs = {
  updates: Array<App_Loader_Target_Type_Updates>;
};

/** columns and relationships of "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_Configuration = {
  __typename?: 'app_loader_n_analytic_package_configuration';
  id: Scalars['Int']['output'];
  /** An array relationship */
  n_packages: Array<App_Loader_N_Package>;
  /** An aggregate relationship */
  n_packages_aggregate: App_Loader_N_Package_Aggregate;
  ph_instance_address: Scalars['String']['output'];
  ph_project_api_key: Scalars['String']['output'];
};


/** columns and relationships of "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_ConfigurationN_PackagesArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Package_Order_By>>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};


/** columns and relationships of "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_ConfigurationN_Packages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Package_Order_By>>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};

/** aggregated selection of "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_Configuration_Aggregate = {
  __typename?: 'app_loader_n_analytic_package_configuration_aggregate';
  aggregate?: Maybe<App_Loader_N_Analytic_Package_Configuration_Aggregate_Fields>;
  nodes: Array<App_Loader_N_Analytic_Package_Configuration>;
};

/** aggregate fields of "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_Configuration_Aggregate_Fields = {
  __typename?: 'app_loader_n_analytic_package_configuration_aggregate_fields';
  avg?: Maybe<App_Loader_N_Analytic_Package_Configuration_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_N_Analytic_Package_Configuration_Max_Fields>;
  min?: Maybe<App_Loader_N_Analytic_Package_Configuration_Min_Fields>;
  stddev?: Maybe<App_Loader_N_Analytic_Package_Configuration_Stddev_Fields>;
  stddev_pop?: Maybe<App_Loader_N_Analytic_Package_Configuration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Loader_N_Analytic_Package_Configuration_Stddev_Samp_Fields>;
  sum?: Maybe<App_Loader_N_Analytic_Package_Configuration_Sum_Fields>;
  var_pop?: Maybe<App_Loader_N_Analytic_Package_Configuration_Var_Pop_Fields>;
  var_samp?: Maybe<App_Loader_N_Analytic_Package_Configuration_Var_Samp_Fields>;
  variance?: Maybe<App_Loader_N_Analytic_Package_Configuration_Variance_Fields>;
};


/** aggregate fields of "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_Configuration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_N_Analytic_Package_Configuration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type App_Loader_N_Analytic_Package_Configuration_Avg_Fields = {
  __typename?: 'app_loader_n_analytic_package_configuration_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "n_analytic_package_configuration". All fields are combined with a logical 'AND'. */
export type App_Loader_N_Analytic_Package_Configuration_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_N_Analytic_Package_Configuration_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_N_Analytic_Package_Configuration_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  n_packages?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
  n_packages_aggregate?: InputMaybe<App_Loader_N_Package_Aggregate_Bool_Exp>;
  ph_instance_address?: InputMaybe<String_Comparison_Exp>;
  ph_project_api_key?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "n_analytic_package_configuration" */
export enum App_Loader_N_Analytic_Package_Configuration_Constraint {
  /** unique or primary key constraint on columns "id" */
  NAnalyticApplicationConfigurationPkey = 'n_analytic_application_configuration_pkey'
}

/** input type for incrementing numeric columns in table "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_Configuration_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_Configuration_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  n_packages?: InputMaybe<App_Loader_N_Package_Arr_Rel_Insert_Input>;
  ph_instance_address?: InputMaybe<Scalars['String']['input']>;
  ph_project_api_key?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_N_Analytic_Package_Configuration_Max_Fields = {
  __typename?: 'app_loader_n_analytic_package_configuration_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  ph_instance_address?: Maybe<Scalars['String']['output']>;
  ph_project_api_key?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_N_Analytic_Package_Configuration_Min_Fields = {
  __typename?: 'app_loader_n_analytic_package_configuration_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  ph_instance_address?: Maybe<Scalars['String']['output']>;
  ph_project_api_key?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_Configuration_Mutation_Response = {
  __typename?: 'app_loader_n_analytic_package_configuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_N_Analytic_Package_Configuration>;
};

/** input type for inserting object relation for remote table "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_Configuration_Obj_Rel_Insert_Input = {
  data: App_Loader_N_Analytic_Package_Configuration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_On_Conflict>;
};

/** on_conflict condition type for table "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_Configuration_On_Conflict = {
  constraint: App_Loader_N_Analytic_Package_Configuration_Constraint;
  update_columns?: Array<App_Loader_N_Analytic_Package_Configuration_Update_Column>;
  where?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Bool_Exp>;
};

/** Ordering options when selecting data from "n_analytic_package_configuration". */
export type App_Loader_N_Analytic_Package_Configuration_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
  n_packages_aggregate?: InputMaybe<App_Loader_N_Package_Aggregate_Order_By>;
  ph_instance_address?: InputMaybe<App_Loader_Order_By>;
  ph_project_api_key?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: n_analytic_package_configuration */
export type App_Loader_N_Analytic_Package_Configuration_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "n_analytic_package_configuration" */
export enum App_Loader_N_Analytic_Package_Configuration_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PhInstanceAddress = 'ph_instance_address',
  /** column name */
  PhProjectApiKey = 'ph_project_api_key'
}

/** input type for updating data in table "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_Configuration_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  ph_instance_address?: InputMaybe<Scalars['String']['input']>;
  ph_project_api_key?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type App_Loader_N_Analytic_Package_Configuration_Stddev_Fields = {
  __typename?: 'app_loader_n_analytic_package_configuration_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type App_Loader_N_Analytic_Package_Configuration_Stddev_Pop_Fields = {
  __typename?: 'app_loader_n_analytic_package_configuration_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type App_Loader_N_Analytic_Package_Configuration_Stddev_Samp_Fields = {
  __typename?: 'app_loader_n_analytic_package_configuration_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "n_analytic_package_configuration" */
export type App_Loader_N_Analytic_Package_Configuration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_N_Analytic_Package_Configuration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_N_Analytic_Package_Configuration_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  ph_instance_address?: InputMaybe<Scalars['String']['input']>;
  ph_project_api_key?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type App_Loader_N_Analytic_Package_Configuration_Sum_Fields = {
  __typename?: 'app_loader_n_analytic_package_configuration_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "n_analytic_package_configuration" */
export enum App_Loader_N_Analytic_Package_Configuration_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PhInstanceAddress = 'ph_instance_address',
  /** column name */
  PhProjectApiKey = 'ph_project_api_key'
}

export type App_Loader_N_Analytic_Package_Configuration_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_N_Analytic_Package_Configuration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type App_Loader_N_Analytic_Package_Configuration_Var_Pop_Fields = {
  __typename?: 'app_loader_n_analytic_package_configuration_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type App_Loader_N_Analytic_Package_Configuration_Var_Samp_Fields = {
  __typename?: 'app_loader_n_analytic_package_configuration_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type App_Loader_N_Analytic_Package_Configuration_Variance_Fields = {
  __typename?: 'app_loader_n_analytic_package_configuration_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "n_application" */
export type App_Loader_N_Application = {
  __typename?: 'app_loader_n_application';
  /** An object relationship */
  customer: App_Loader_Customer;
  customer_id: Scalars['app_loader_uuid']['output'];
  id: Scalars['Int']['output'];
  /** An array relationship */
  n_domains: Array<App_Loader_N_Domain>;
  /** An aggregate relationship */
  n_domains_aggregate: App_Loader_N_Domain_Aggregate;
  /** An array relationship */
  n_packages: Array<App_Loader_N_Package>;
  /** An aggregate relationship */
  n_packages_aggregate: App_Loader_N_Package_Aggregate;
  name: Scalars['String']['output'];
};


/** columns and relationships of "n_application" */
export type App_Loader_N_ApplicationN_DomainsArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Domain_Order_By>>;
  where?: InputMaybe<App_Loader_N_Domain_Bool_Exp>;
};


/** columns and relationships of "n_application" */
export type App_Loader_N_ApplicationN_Domains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Domain_Order_By>>;
  where?: InputMaybe<App_Loader_N_Domain_Bool_Exp>;
};


/** columns and relationships of "n_application" */
export type App_Loader_N_ApplicationN_PackagesArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Package_Order_By>>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};


/** columns and relationships of "n_application" */
export type App_Loader_N_ApplicationN_Packages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Package_Order_By>>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};

/** aggregated selection of "n_application" */
export type App_Loader_N_Application_Aggregate = {
  __typename?: 'app_loader_n_application_aggregate';
  aggregate?: Maybe<App_Loader_N_Application_Aggregate_Fields>;
  nodes: Array<App_Loader_N_Application>;
};

export type App_Loader_N_Application_Aggregate_Bool_Exp = {
  count?: InputMaybe<App_Loader_N_Application_Aggregate_Bool_Exp_Count>;
};

export type App_Loader_N_Application_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<App_Loader_N_Application_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "n_application" */
export type App_Loader_N_Application_Aggregate_Fields = {
  __typename?: 'app_loader_n_application_aggregate_fields';
  avg?: Maybe<App_Loader_N_Application_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_N_Application_Max_Fields>;
  min?: Maybe<App_Loader_N_Application_Min_Fields>;
  stddev?: Maybe<App_Loader_N_Application_Stddev_Fields>;
  stddev_pop?: Maybe<App_Loader_N_Application_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Loader_N_Application_Stddev_Samp_Fields>;
  sum?: Maybe<App_Loader_N_Application_Sum_Fields>;
  var_pop?: Maybe<App_Loader_N_Application_Var_Pop_Fields>;
  var_samp?: Maybe<App_Loader_N_Application_Var_Samp_Fields>;
  variance?: Maybe<App_Loader_N_Application_Variance_Fields>;
};


/** aggregate fields of "n_application" */
export type App_Loader_N_Application_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_N_Application_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "n_application" */
export type App_Loader_N_Application_Aggregate_Order_By = {
  avg?: InputMaybe<App_Loader_N_Application_Avg_Order_By>;
  count?: InputMaybe<App_Loader_Order_By>;
  max?: InputMaybe<App_Loader_N_Application_Max_Order_By>;
  min?: InputMaybe<App_Loader_N_Application_Min_Order_By>;
  stddev?: InputMaybe<App_Loader_N_Application_Stddev_Order_By>;
  stddev_pop?: InputMaybe<App_Loader_N_Application_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<App_Loader_N_Application_Stddev_Samp_Order_By>;
  sum?: InputMaybe<App_Loader_N_Application_Sum_Order_By>;
  var_pop?: InputMaybe<App_Loader_N_Application_Var_Pop_Order_By>;
  var_samp?: InputMaybe<App_Loader_N_Application_Var_Samp_Order_By>;
  variance?: InputMaybe<App_Loader_N_Application_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "n_application" */
export type App_Loader_N_Application_Arr_Rel_Insert_Input = {
  data: Array<App_Loader_N_Application_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_N_Application_On_Conflict>;
};

/** aggregate avg on columns */
export type App_Loader_N_Application_Avg_Fields = {
  __typename?: 'app_loader_n_application_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "n_application" */
export type App_Loader_N_Application_Avg_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
};

/** Boolean expression to filter rows from the table "n_application". All fields are combined with a logical 'AND'. */
export type App_Loader_N_Application_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_N_Application_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_N_Application_Bool_Exp>>;
  customer?: InputMaybe<App_Loader_Customer_Bool_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  n_domains?: InputMaybe<App_Loader_N_Domain_Bool_Exp>;
  n_domains_aggregate?: InputMaybe<App_Loader_N_Domain_Aggregate_Bool_Exp>;
  n_packages?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
  n_packages_aggregate?: InputMaybe<App_Loader_N_Package_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "n_application" */
export enum App_Loader_N_Application_Constraint {
  /** unique or primary key constraint on columns "id" */
  NApplicationPkey = 'n_application_pkey'
}

/** input type for incrementing numeric columns in table "n_application" */
export type App_Loader_N_Application_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "n_application" */
export type App_Loader_N_Application_Insert_Input = {
  customer?: InputMaybe<App_Loader_Customer_Obj_Rel_Insert_Input>;
  customer_id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  n_domains?: InputMaybe<App_Loader_N_Domain_Arr_Rel_Insert_Input>;
  n_packages?: InputMaybe<App_Loader_N_Package_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_N_Application_Max_Fields = {
  __typename?: 'app_loader_n_application_max_fields';
  customer_id?: Maybe<Scalars['app_loader_uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "n_application" */
export type App_Loader_N_Application_Max_Order_By = {
  customer_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate min on columns */
export type App_Loader_N_Application_Min_Fields = {
  __typename?: 'app_loader_n_application_min_fields';
  customer_id?: Maybe<Scalars['app_loader_uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "n_application" */
export type App_Loader_N_Application_Min_Order_By = {
  customer_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
};

/** response of any mutation on the table "n_application" */
export type App_Loader_N_Application_Mutation_Response = {
  __typename?: 'app_loader_n_application_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_N_Application>;
};

/** input type for inserting object relation for remote table "n_application" */
export type App_Loader_N_Application_Obj_Rel_Insert_Input = {
  data: App_Loader_N_Application_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_N_Application_On_Conflict>;
};

/** on_conflict condition type for table "n_application" */
export type App_Loader_N_Application_On_Conflict = {
  constraint: App_Loader_N_Application_Constraint;
  update_columns?: Array<App_Loader_N_Application_Update_Column>;
  where?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
};

/** Ordering options when selecting data from "n_application". */
export type App_Loader_N_Application_Order_By = {
  customer?: InputMaybe<App_Loader_Customer_Order_By>;
  customer_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  n_domains_aggregate?: InputMaybe<App_Loader_N_Domain_Aggregate_Order_By>;
  n_packages_aggregate?: InputMaybe<App_Loader_N_Package_Aggregate_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: n_application */
export type App_Loader_N_Application_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "n_application" */
export enum App_Loader_N_Application_Select_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "n_application" */
export type App_Loader_N_Application_Set_Input = {
  customer_id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type App_Loader_N_Application_Stddev_Fields = {
  __typename?: 'app_loader_n_application_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "n_application" */
export type App_Loader_N_Application_Stddev_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate stddev_pop on columns */
export type App_Loader_N_Application_Stddev_Pop_Fields = {
  __typename?: 'app_loader_n_application_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "n_application" */
export type App_Loader_N_Application_Stddev_Pop_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate stddev_samp on columns */
export type App_Loader_N_Application_Stddev_Samp_Fields = {
  __typename?: 'app_loader_n_application_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "n_application" */
export type App_Loader_N_Application_Stddev_Samp_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
};

/** Streaming cursor of the table "n_application" */
export type App_Loader_N_Application_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_N_Application_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_N_Application_Stream_Cursor_Value_Input = {
  customer_id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type App_Loader_N_Application_Sum_Fields = {
  __typename?: 'app_loader_n_application_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "n_application" */
export type App_Loader_N_Application_Sum_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
};

/** update columns of table "n_application" */
export enum App_Loader_N_Application_Update_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type App_Loader_N_Application_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<App_Loader_N_Application_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_N_Application_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_N_Application_Bool_Exp;
};

/** aggregate var_pop on columns */
export type App_Loader_N_Application_Var_Pop_Fields = {
  __typename?: 'app_loader_n_application_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "n_application" */
export type App_Loader_N_Application_Var_Pop_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate var_samp on columns */
export type App_Loader_N_Application_Var_Samp_Fields = {
  __typename?: 'app_loader_n_application_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "n_application" */
export type App_Loader_N_Application_Var_Samp_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate variance on columns */
export type App_Loader_N_Application_Variance_Fields = {
  __typename?: 'app_loader_n_application_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "n_application" */
export type App_Loader_N_Application_Variance_Order_By = {
  id?: InputMaybe<App_Loader_Order_By>;
};

/** columns and relationships of "n_domain" */
export type App_Loader_N_Domain = {
  __typename?: 'app_loader_n_domain';
  application_id: Scalars['Int']['output'];
  fqdn: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  n_application: App_Loader_N_Application;
};

/** aggregated selection of "n_domain" */
export type App_Loader_N_Domain_Aggregate = {
  __typename?: 'app_loader_n_domain_aggregate';
  aggregate?: Maybe<App_Loader_N_Domain_Aggregate_Fields>;
  nodes: Array<App_Loader_N_Domain>;
};

export type App_Loader_N_Domain_Aggregate_Bool_Exp = {
  count?: InputMaybe<App_Loader_N_Domain_Aggregate_Bool_Exp_Count>;
};

export type App_Loader_N_Domain_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<App_Loader_N_Domain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<App_Loader_N_Domain_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "n_domain" */
export type App_Loader_N_Domain_Aggregate_Fields = {
  __typename?: 'app_loader_n_domain_aggregate_fields';
  avg?: Maybe<App_Loader_N_Domain_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_N_Domain_Max_Fields>;
  min?: Maybe<App_Loader_N_Domain_Min_Fields>;
  stddev?: Maybe<App_Loader_N_Domain_Stddev_Fields>;
  stddev_pop?: Maybe<App_Loader_N_Domain_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Loader_N_Domain_Stddev_Samp_Fields>;
  sum?: Maybe<App_Loader_N_Domain_Sum_Fields>;
  var_pop?: Maybe<App_Loader_N_Domain_Var_Pop_Fields>;
  var_samp?: Maybe<App_Loader_N_Domain_Var_Samp_Fields>;
  variance?: Maybe<App_Loader_N_Domain_Variance_Fields>;
};


/** aggregate fields of "n_domain" */
export type App_Loader_N_Domain_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_N_Domain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "n_domain" */
export type App_Loader_N_Domain_Aggregate_Order_By = {
  avg?: InputMaybe<App_Loader_N_Domain_Avg_Order_By>;
  count?: InputMaybe<App_Loader_Order_By>;
  max?: InputMaybe<App_Loader_N_Domain_Max_Order_By>;
  min?: InputMaybe<App_Loader_N_Domain_Min_Order_By>;
  stddev?: InputMaybe<App_Loader_N_Domain_Stddev_Order_By>;
  stddev_pop?: InputMaybe<App_Loader_N_Domain_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<App_Loader_N_Domain_Stddev_Samp_Order_By>;
  sum?: InputMaybe<App_Loader_N_Domain_Sum_Order_By>;
  var_pop?: InputMaybe<App_Loader_N_Domain_Var_Pop_Order_By>;
  var_samp?: InputMaybe<App_Loader_N_Domain_Var_Samp_Order_By>;
  variance?: InputMaybe<App_Loader_N_Domain_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "n_domain" */
export type App_Loader_N_Domain_Arr_Rel_Insert_Input = {
  data: Array<App_Loader_N_Domain_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_N_Domain_On_Conflict>;
};

/** aggregate avg on columns */
export type App_Loader_N_Domain_Avg_Fields = {
  __typename?: 'app_loader_n_domain_avg_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "n_domain" */
export type App_Loader_N_Domain_Avg_Order_By = {
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** Boolean expression to filter rows from the table "n_domain". All fields are combined with a logical 'AND'. */
export type App_Loader_N_Domain_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_N_Domain_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_N_Domain_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_N_Domain_Bool_Exp>>;
  application_id?: InputMaybe<Int_Comparison_Exp>;
  fqdn?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  n_application?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
};

/** unique or primary key constraints on table "n_domain" */
export enum App_Loader_N_Domain_Constraint {
  /** unique or primary key constraint on columns "id" */
  NDomainPkey = 'n_domain_pkey'
}

/** input type for incrementing numeric columns in table "n_domain" */
export type App_Loader_N_Domain_Inc_Input = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "n_domain" */
export type App_Loader_N_Domain_Insert_Input = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  fqdn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  n_application?: InputMaybe<App_Loader_N_Application_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type App_Loader_N_Domain_Max_Fields = {
  __typename?: 'app_loader_n_domain_max_fields';
  application_id?: Maybe<Scalars['Int']['output']>;
  fqdn?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "n_domain" */
export type App_Loader_N_Domain_Max_Order_By = {
  application_id?: InputMaybe<App_Loader_Order_By>;
  fqdn?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate min on columns */
export type App_Loader_N_Domain_Min_Fields = {
  __typename?: 'app_loader_n_domain_min_fields';
  application_id?: Maybe<Scalars['Int']['output']>;
  fqdn?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "n_domain" */
export type App_Loader_N_Domain_Min_Order_By = {
  application_id?: InputMaybe<App_Loader_Order_By>;
  fqdn?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** response of any mutation on the table "n_domain" */
export type App_Loader_N_Domain_Mutation_Response = {
  __typename?: 'app_loader_n_domain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_N_Domain>;
};

/** on_conflict condition type for table "n_domain" */
export type App_Loader_N_Domain_On_Conflict = {
  constraint: App_Loader_N_Domain_Constraint;
  update_columns?: Array<App_Loader_N_Domain_Update_Column>;
  where?: InputMaybe<App_Loader_N_Domain_Bool_Exp>;
};

/** Ordering options when selecting data from "n_domain". */
export type App_Loader_N_Domain_Order_By = {
  application_id?: InputMaybe<App_Loader_Order_By>;
  fqdn?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  n_application?: InputMaybe<App_Loader_N_Application_Order_By>;
};

/** primary key columns input for table: n_domain */
export type App_Loader_N_Domain_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "n_domain" */
export enum App_Loader_N_Domain_Select_Column {
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "n_domain" */
export type App_Loader_N_Domain_Set_Input = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  fqdn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type App_Loader_N_Domain_Stddev_Fields = {
  __typename?: 'app_loader_n_domain_stddev_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "n_domain" */
export type App_Loader_N_Domain_Stddev_Order_By = {
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate stddev_pop on columns */
export type App_Loader_N_Domain_Stddev_Pop_Fields = {
  __typename?: 'app_loader_n_domain_stddev_pop_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "n_domain" */
export type App_Loader_N_Domain_Stddev_Pop_Order_By = {
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate stddev_samp on columns */
export type App_Loader_N_Domain_Stddev_Samp_Fields = {
  __typename?: 'app_loader_n_domain_stddev_samp_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "n_domain" */
export type App_Loader_N_Domain_Stddev_Samp_Order_By = {
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** Streaming cursor of the table "n_domain" */
export type App_Loader_N_Domain_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_N_Domain_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_N_Domain_Stream_Cursor_Value_Input = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  fqdn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type App_Loader_N_Domain_Sum_Fields = {
  __typename?: 'app_loader_n_domain_sum_fields';
  application_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "n_domain" */
export type App_Loader_N_Domain_Sum_Order_By = {
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** update columns of table "n_domain" */
export enum App_Loader_N_Domain_Update_Column {
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id'
}

export type App_Loader_N_Domain_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<App_Loader_N_Domain_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_N_Domain_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_N_Domain_Bool_Exp;
};

/** aggregate var_pop on columns */
export type App_Loader_N_Domain_Var_Pop_Fields = {
  __typename?: 'app_loader_n_domain_var_pop_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "n_domain" */
export type App_Loader_N_Domain_Var_Pop_Order_By = {
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate var_samp on columns */
export type App_Loader_N_Domain_Var_Samp_Fields = {
  __typename?: 'app_loader_n_domain_var_samp_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "n_domain" */
export type App_Loader_N_Domain_Var_Samp_Order_By = {
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate variance on columns */
export type App_Loader_N_Domain_Variance_Fields = {
  __typename?: 'app_loader_n_domain_variance_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "n_domain" */
export type App_Loader_N_Domain_Variance_Order_By = {
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** columns and relationships of "n_package" */
export type App_Loader_N_Package = {
  __typename?: 'app_loader_n_package';
  analytic_configuration_id?: Maybe<Scalars['Int']['output']>;
  application_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  n_analytic_package_configuration?: Maybe<App_Loader_N_Analytic_Package_Configuration>;
  /** An object relationship */
  n_application: App_Loader_N_Application;
  /** An object relationship */
  n_pype_package_configuration?: Maybe<App_Loader_N_Pype_Package_Configuration>;
  name: Scalars['String']['output'];
  pype_configuration_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "n_package" */
export type App_Loader_N_Package_Aggregate = {
  __typename?: 'app_loader_n_package_aggregate';
  aggregate?: Maybe<App_Loader_N_Package_Aggregate_Fields>;
  nodes: Array<App_Loader_N_Package>;
};

export type App_Loader_N_Package_Aggregate_Bool_Exp = {
  count?: InputMaybe<App_Loader_N_Package_Aggregate_Bool_Exp_Count>;
};

export type App_Loader_N_Package_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "n_package" */
export type App_Loader_N_Package_Aggregate_Fields = {
  __typename?: 'app_loader_n_package_aggregate_fields';
  avg?: Maybe<App_Loader_N_Package_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_N_Package_Max_Fields>;
  min?: Maybe<App_Loader_N_Package_Min_Fields>;
  stddev?: Maybe<App_Loader_N_Package_Stddev_Fields>;
  stddev_pop?: Maybe<App_Loader_N_Package_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Loader_N_Package_Stddev_Samp_Fields>;
  sum?: Maybe<App_Loader_N_Package_Sum_Fields>;
  var_pop?: Maybe<App_Loader_N_Package_Var_Pop_Fields>;
  var_samp?: Maybe<App_Loader_N_Package_Var_Samp_Fields>;
  variance?: Maybe<App_Loader_N_Package_Variance_Fields>;
};


/** aggregate fields of "n_package" */
export type App_Loader_N_Package_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "n_package" */
export type App_Loader_N_Package_Aggregate_Order_By = {
  avg?: InputMaybe<App_Loader_N_Package_Avg_Order_By>;
  count?: InputMaybe<App_Loader_Order_By>;
  max?: InputMaybe<App_Loader_N_Package_Max_Order_By>;
  min?: InputMaybe<App_Loader_N_Package_Min_Order_By>;
  stddev?: InputMaybe<App_Loader_N_Package_Stddev_Order_By>;
  stddev_pop?: InputMaybe<App_Loader_N_Package_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<App_Loader_N_Package_Stddev_Samp_Order_By>;
  sum?: InputMaybe<App_Loader_N_Package_Sum_Order_By>;
  var_pop?: InputMaybe<App_Loader_N_Package_Var_Pop_Order_By>;
  var_samp?: InputMaybe<App_Loader_N_Package_Var_Samp_Order_By>;
  variance?: InputMaybe<App_Loader_N_Package_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "n_package" */
export type App_Loader_N_Package_Arr_Rel_Insert_Input = {
  data: Array<App_Loader_N_Package_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_N_Package_On_Conflict>;
};

/** aggregate avg on columns */
export type App_Loader_N_Package_Avg_Fields = {
  __typename?: 'app_loader_n_package_avg_fields';
  analytic_configuration_id?: Maybe<Scalars['Float']['output']>;
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pype_configuration_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "n_package" */
export type App_Loader_N_Package_Avg_Order_By = {
  analytic_configuration_id?: InputMaybe<App_Loader_Order_By>;
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  pype_configuration_id?: InputMaybe<App_Loader_Order_By>;
};

/** Boolean expression to filter rows from the table "n_package". All fields are combined with a logical 'AND'. */
export type App_Loader_N_Package_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_N_Package_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_N_Package_Bool_Exp>>;
  analytic_configuration_id?: InputMaybe<Int_Comparison_Exp>;
  application_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  n_analytic_package_configuration?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Bool_Exp>;
  n_application?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
  n_pype_package_configuration?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pype_configuration_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "n_package" */
export enum App_Loader_N_Package_Constraint {
  /** unique or primary key constraint on columns "id" */
  NPackagePkey = 'n_package_pkey'
}

/** input type for incrementing numeric columns in table "n_package" */
export type App_Loader_N_Package_Inc_Input = {
  analytic_configuration_id?: InputMaybe<Scalars['Int']['input']>;
  application_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  pype_configuration_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "n_package" */
export type App_Loader_N_Package_Insert_Input = {
  analytic_configuration_id?: InputMaybe<Scalars['Int']['input']>;
  application_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  n_analytic_package_configuration?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Obj_Rel_Insert_Input>;
  n_application?: InputMaybe<App_Loader_N_Application_Obj_Rel_Insert_Input>;
  n_pype_package_configuration?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  pype_configuration_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type App_Loader_N_Package_Max_Fields = {
  __typename?: 'app_loader_n_package_max_fields';
  analytic_configuration_id?: Maybe<Scalars['Int']['output']>;
  application_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pype_configuration_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "n_package" */
export type App_Loader_N_Package_Max_Order_By = {
  analytic_configuration_id?: InputMaybe<App_Loader_Order_By>;
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
  pype_configuration_id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate min on columns */
export type App_Loader_N_Package_Min_Fields = {
  __typename?: 'app_loader_n_package_min_fields';
  analytic_configuration_id?: Maybe<Scalars['Int']['output']>;
  application_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pype_configuration_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "n_package" */
export type App_Loader_N_Package_Min_Order_By = {
  analytic_configuration_id?: InputMaybe<App_Loader_Order_By>;
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
  pype_configuration_id?: InputMaybe<App_Loader_Order_By>;
};

/** response of any mutation on the table "n_package" */
export type App_Loader_N_Package_Mutation_Response = {
  __typename?: 'app_loader_n_package_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_N_Package>;
};

/** on_conflict condition type for table "n_package" */
export type App_Loader_N_Package_On_Conflict = {
  constraint: App_Loader_N_Package_Constraint;
  update_columns?: Array<App_Loader_N_Package_Update_Column>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};

/** Ordering options when selecting data from "n_package". */
export type App_Loader_N_Package_Order_By = {
  analytic_configuration_id?: InputMaybe<App_Loader_Order_By>;
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  n_analytic_package_configuration?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Order_By>;
  n_application?: InputMaybe<App_Loader_N_Application_Order_By>;
  n_pype_package_configuration?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Order_By>;
  name?: InputMaybe<App_Loader_Order_By>;
  pype_configuration_id?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: n_package */
export type App_Loader_N_Package_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "n_package" */
export enum App_Loader_N_Package_Select_Column {
  /** column name */
  AnalyticConfigurationId = 'analytic_configuration_id',
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PypeConfigurationId = 'pype_configuration_id'
}

/** input type for updating data in table "n_package" */
export type App_Loader_N_Package_Set_Input = {
  analytic_configuration_id?: InputMaybe<Scalars['Int']['input']>;
  application_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pype_configuration_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type App_Loader_N_Package_Stddev_Fields = {
  __typename?: 'app_loader_n_package_stddev_fields';
  analytic_configuration_id?: Maybe<Scalars['Float']['output']>;
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pype_configuration_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "n_package" */
export type App_Loader_N_Package_Stddev_Order_By = {
  analytic_configuration_id?: InputMaybe<App_Loader_Order_By>;
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  pype_configuration_id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate stddev_pop on columns */
export type App_Loader_N_Package_Stddev_Pop_Fields = {
  __typename?: 'app_loader_n_package_stddev_pop_fields';
  analytic_configuration_id?: Maybe<Scalars['Float']['output']>;
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pype_configuration_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "n_package" */
export type App_Loader_N_Package_Stddev_Pop_Order_By = {
  analytic_configuration_id?: InputMaybe<App_Loader_Order_By>;
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  pype_configuration_id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate stddev_samp on columns */
export type App_Loader_N_Package_Stddev_Samp_Fields = {
  __typename?: 'app_loader_n_package_stddev_samp_fields';
  analytic_configuration_id?: Maybe<Scalars['Float']['output']>;
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pype_configuration_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "n_package" */
export type App_Loader_N_Package_Stddev_Samp_Order_By = {
  analytic_configuration_id?: InputMaybe<App_Loader_Order_By>;
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  pype_configuration_id?: InputMaybe<App_Loader_Order_By>;
};

/** Streaming cursor of the table "n_package" */
export type App_Loader_N_Package_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_N_Package_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_N_Package_Stream_Cursor_Value_Input = {
  analytic_configuration_id?: InputMaybe<Scalars['Int']['input']>;
  application_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pype_configuration_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type App_Loader_N_Package_Sum_Fields = {
  __typename?: 'app_loader_n_package_sum_fields';
  analytic_configuration_id?: Maybe<Scalars['Int']['output']>;
  application_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  pype_configuration_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "n_package" */
export type App_Loader_N_Package_Sum_Order_By = {
  analytic_configuration_id?: InputMaybe<App_Loader_Order_By>;
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  pype_configuration_id?: InputMaybe<App_Loader_Order_By>;
};

/** update columns of table "n_package" */
export enum App_Loader_N_Package_Update_Column {
  /** column name */
  AnalyticConfigurationId = 'analytic_configuration_id',
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PypeConfigurationId = 'pype_configuration_id'
}

export type App_Loader_N_Package_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<App_Loader_N_Package_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_N_Package_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_N_Package_Bool_Exp;
};

/** aggregate var_pop on columns */
export type App_Loader_N_Package_Var_Pop_Fields = {
  __typename?: 'app_loader_n_package_var_pop_fields';
  analytic_configuration_id?: Maybe<Scalars['Float']['output']>;
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pype_configuration_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "n_package" */
export type App_Loader_N_Package_Var_Pop_Order_By = {
  analytic_configuration_id?: InputMaybe<App_Loader_Order_By>;
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  pype_configuration_id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate var_samp on columns */
export type App_Loader_N_Package_Var_Samp_Fields = {
  __typename?: 'app_loader_n_package_var_samp_fields';
  analytic_configuration_id?: Maybe<Scalars['Float']['output']>;
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pype_configuration_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "n_package" */
export type App_Loader_N_Package_Var_Samp_Order_By = {
  analytic_configuration_id?: InputMaybe<App_Loader_Order_By>;
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  pype_configuration_id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate variance on columns */
export type App_Loader_N_Package_Variance_Fields = {
  __typename?: 'app_loader_n_package_variance_fields';
  analytic_configuration_id?: Maybe<Scalars['Float']['output']>;
  application_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pype_configuration_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "n_package" */
export type App_Loader_N_Package_Variance_Order_By = {
  analytic_configuration_id?: InputMaybe<App_Loader_Order_By>;
  application_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  pype_configuration_id?: InputMaybe<App_Loader_Order_By>;
};

/** columns and relationships of "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_Configuration = {
  __typename?: 'app_loader_n_pype_package_configuration';
  analytics_host: Scalars['String']['output'];
  analytics_key: Scalars['String']['output'];
  app_id: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** An array relationship */
  n_packages: Array<App_Loader_N_Package>;
  /** An aggregate relationship */
  n_packages_aggregate: App_Loader_N_Package_Aggregate;
};


/** columns and relationships of "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_ConfigurationN_PackagesArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Package_Order_By>>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};


/** columns and relationships of "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_ConfigurationN_Packages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Package_Order_By>>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};

/** aggregated selection of "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_Configuration_Aggregate = {
  __typename?: 'app_loader_n_pype_package_configuration_aggregate';
  aggregate?: Maybe<App_Loader_N_Pype_Package_Configuration_Aggregate_Fields>;
  nodes: Array<App_Loader_N_Pype_Package_Configuration>;
};

/** aggregate fields of "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_Configuration_Aggregate_Fields = {
  __typename?: 'app_loader_n_pype_package_configuration_aggregate_fields';
  avg?: Maybe<App_Loader_N_Pype_Package_Configuration_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_N_Pype_Package_Configuration_Max_Fields>;
  min?: Maybe<App_Loader_N_Pype_Package_Configuration_Min_Fields>;
  stddev?: Maybe<App_Loader_N_Pype_Package_Configuration_Stddev_Fields>;
  stddev_pop?: Maybe<App_Loader_N_Pype_Package_Configuration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Loader_N_Pype_Package_Configuration_Stddev_Samp_Fields>;
  sum?: Maybe<App_Loader_N_Pype_Package_Configuration_Sum_Fields>;
  var_pop?: Maybe<App_Loader_N_Pype_Package_Configuration_Var_Pop_Fields>;
  var_samp?: Maybe<App_Loader_N_Pype_Package_Configuration_Var_Samp_Fields>;
  variance?: Maybe<App_Loader_N_Pype_Package_Configuration_Variance_Fields>;
};


/** aggregate fields of "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_Configuration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_N_Pype_Package_Configuration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type App_Loader_N_Pype_Package_Configuration_Avg_Fields = {
  __typename?: 'app_loader_n_pype_package_configuration_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "n_pype_package_configuration". All fields are combined with a logical 'AND'. */
export type App_Loader_N_Pype_Package_Configuration_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_N_Pype_Package_Configuration_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_N_Pype_Package_Configuration_Bool_Exp>>;
  analytics_host?: InputMaybe<String_Comparison_Exp>;
  analytics_key?: InputMaybe<String_Comparison_Exp>;
  app_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  n_packages?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
  n_packages_aggregate?: InputMaybe<App_Loader_N_Package_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "n_pype_package_configuration" */
export enum App_Loader_N_Pype_Package_Configuration_Constraint {
  /** unique or primary key constraint on columns "id" */
  NPypePackageConfigurationPkey = 'n_pype_package_configuration_pkey'
}

/** input type for incrementing numeric columns in table "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_Configuration_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_Configuration_Insert_Input = {
  analytics_host?: InputMaybe<Scalars['String']['input']>;
  analytics_key?: InputMaybe<Scalars['String']['input']>;
  app_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  n_packages?: InputMaybe<App_Loader_N_Package_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type App_Loader_N_Pype_Package_Configuration_Max_Fields = {
  __typename?: 'app_loader_n_pype_package_configuration_max_fields';
  analytics_host?: Maybe<Scalars['String']['output']>;
  analytics_key?: Maybe<Scalars['String']['output']>;
  app_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type App_Loader_N_Pype_Package_Configuration_Min_Fields = {
  __typename?: 'app_loader_n_pype_package_configuration_min_fields';
  analytics_host?: Maybe<Scalars['String']['output']>;
  analytics_key?: Maybe<Scalars['String']['output']>;
  app_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_Configuration_Mutation_Response = {
  __typename?: 'app_loader_n_pype_package_configuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_N_Pype_Package_Configuration>;
};

/** input type for inserting object relation for remote table "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_Configuration_Obj_Rel_Insert_Input = {
  data: App_Loader_N_Pype_Package_Configuration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_N_Pype_Package_Configuration_On_Conflict>;
};

/** on_conflict condition type for table "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_Configuration_On_Conflict = {
  constraint: App_Loader_N_Pype_Package_Configuration_Constraint;
  update_columns?: Array<App_Loader_N_Pype_Package_Configuration_Update_Column>;
  where?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Bool_Exp>;
};

/** Ordering options when selecting data from "n_pype_package_configuration". */
export type App_Loader_N_Pype_Package_Configuration_Order_By = {
  analytics_host?: InputMaybe<App_Loader_Order_By>;
  analytics_key?: InputMaybe<App_Loader_Order_By>;
  app_id?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  n_packages_aggregate?: InputMaybe<App_Loader_N_Package_Aggregate_Order_By>;
};

/** primary key columns input for table: n_pype_package_configuration */
export type App_Loader_N_Pype_Package_Configuration_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "n_pype_package_configuration" */
export enum App_Loader_N_Pype_Package_Configuration_Select_Column {
  /** column name */
  AnalyticsHost = 'analytics_host',
  /** column name */
  AnalyticsKey = 'analytics_key',
  /** column name */
  AppId = 'app_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_Configuration_Set_Input = {
  analytics_host?: InputMaybe<Scalars['String']['input']>;
  analytics_key?: InputMaybe<Scalars['String']['input']>;
  app_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type App_Loader_N_Pype_Package_Configuration_Stddev_Fields = {
  __typename?: 'app_loader_n_pype_package_configuration_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type App_Loader_N_Pype_Package_Configuration_Stddev_Pop_Fields = {
  __typename?: 'app_loader_n_pype_package_configuration_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type App_Loader_N_Pype_Package_Configuration_Stddev_Samp_Fields = {
  __typename?: 'app_loader_n_pype_package_configuration_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "n_pype_package_configuration" */
export type App_Loader_N_Pype_Package_Configuration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_N_Pype_Package_Configuration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_N_Pype_Package_Configuration_Stream_Cursor_Value_Input = {
  analytics_host?: InputMaybe<Scalars['String']['input']>;
  analytics_key?: InputMaybe<Scalars['String']['input']>;
  app_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type App_Loader_N_Pype_Package_Configuration_Sum_Fields = {
  __typename?: 'app_loader_n_pype_package_configuration_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "n_pype_package_configuration" */
export enum App_Loader_N_Pype_Package_Configuration_Update_Column {
  /** column name */
  AnalyticsHost = 'analytics_host',
  /** column name */
  AnalyticsKey = 'analytics_key',
  /** column name */
  AppId = 'app_id',
  /** column name */
  Id = 'id'
}

export type App_Loader_N_Pype_Package_Configuration_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_N_Pype_Package_Configuration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type App_Loader_N_Pype_Package_Configuration_Var_Pop_Fields = {
  __typename?: 'app_loader_n_pype_package_configuration_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type App_Loader_N_Pype_Package_Configuration_Var_Samp_Fields = {
  __typename?: 'app_loader_n_pype_package_configuration_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type App_Loader_N_Pype_Package_Configuration_Variance_Fields = {
  __typename?: 'app_loader_n_pype_package_configuration_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** column ordering options */
export enum App_Loader_Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type App_Loader_Query_Root = {
  __typename?: 'app_loader_query_root';
  /** fetch data from the table: "app" */
  app: Array<App_Loader_App>;
  /** fetch aggregated fields from the table: "app" */
  app_aggregate: App_Loader_App_Aggregate;
  /** fetch data from the table: "app" using primary key columns */
  app_by_pk?: Maybe<App_Loader_App>;
  /** fetch data from the table: "conditions" */
  conditions: Array<App_Loader_Conditions>;
  /** fetch aggregated fields from the table: "conditions" */
  conditions_aggregate: App_Loader_Conditions_Aggregate;
  /** fetch data from the table: "conditions" using primary key columns */
  conditions_by_pk?: Maybe<App_Loader_Conditions>;
  /** fetch data from the table: "customer" */
  customer: Array<App_Loader_Customer>;
  /** fetch aggregated fields from the table: "customer" */
  customer_aggregate: App_Loader_Customer_Aggregate;
  /** fetch data from the table: "customer" using primary key columns */
  customer_by_pk?: Maybe<App_Loader_Customer>;
  /** fetch data from the table: "deployment" */
  deployment: Array<App_Loader_Deployment>;
  /** fetch aggregated fields from the table: "deployment" */
  deployment_aggregate: App_Loader_Deployment_Aggregate;
  /** fetch data from the table: "deployment" using primary key columns */
  deployment_by_pk?: Maybe<App_Loader_Deployment>;
  /** An array relationship */
  environment: Array<App_Loader_Environment>;
  /** An aggregate relationship */
  environment_aggregate: App_Loader_Environment_Aggregate;
  /** fetch data from the table: "environment" using primary key columns */
  environment_by_pk?: Maybe<App_Loader_Environment>;
  /** fetch data from the table: "latest_schema.anonymous_session" */
  latest_schema_anonymous_session: Array<App_Loader_Latest_Schema_Anonymous_Session>;
  /** fetch aggregated fields from the table: "latest_schema.anonymous_session" */
  latest_schema_anonymous_session_aggregate: App_Loader_Latest_Schema_Anonymous_Session_Aggregate;
  /** fetch data from the table: "latest_schema.anonymous_session" using primary key columns */
  latest_schema_anonymous_session_by_pk?: Maybe<App_Loader_Latest_Schema_Anonymous_Session>;
  /** fetch data from the table: "latest_schema.packages" */
  latest_schema_packages: Array<App_Loader_Latest_Schema_Packages>;
  /** fetch aggregated fields from the table: "latest_schema.packages" */
  latest_schema_packages_aggregate: App_Loader_Latest_Schema_Packages_Aggregate;
  /** fetch data from the table: "latest_schema.packages" using primary key columns */
  latest_schema_packages_by_pk?: Maybe<App_Loader_Latest_Schema_Packages>;
  /** fetch data from the table: "latest_schema.packages_type" */
  latest_schema_packages_type: Array<App_Loader_Latest_Schema_Packages_Type>;
  /** fetch aggregated fields from the table: "latest_schema.packages_type" */
  latest_schema_packages_type_aggregate: App_Loader_Latest_Schema_Packages_Type_Aggregate;
  /** fetch data from the table: "latest_schema.packages_type" using primary key columns */
  latest_schema_packages_type_by_pk?: Maybe<App_Loader_Latest_Schema_Packages_Type>;
  /** fetch data from the table: "metadata.analytics" */
  metadata_analytics: Array<App_Loader_Metadata_Analytics>;
  /** fetch aggregated fields from the table: "metadata.analytics" */
  metadata_analytics_aggregate: App_Loader_Metadata_Analytics_Aggregate;
  /** fetch data from the table: "metadata.analytics" using primary key columns */
  metadata_analytics_by_pk?: Maybe<App_Loader_Metadata_Analytics>;
  /** fetch data from the table: "metadata.cc_position_option" */
  metadata_cc_position_option: Array<App_Loader_Metadata_Cc_Position_Option>;
  /** fetch aggregated fields from the table: "metadata.cc_position_option" */
  metadata_cc_position_option_aggregate: App_Loader_Metadata_Cc_Position_Option_Aggregate;
  /** fetch data from the table: "metadata.cc_position_option" using primary key columns */
  metadata_cc_position_option_by_pk?: Maybe<App_Loader_Metadata_Cc_Position_Option>;
  /** fetch data from the table: "metadata.cc_widget" */
  metadata_cc_widget: Array<App_Loader_Metadata_Cc_Widget>;
  /** fetch aggregated fields from the table: "metadata.cc_widget" */
  metadata_cc_widget_aggregate: App_Loader_Metadata_Cc_Widget_Aggregate;
  /** fetch data from the table: "metadata.cc_widget_bubble_type" */
  metadata_cc_widget_bubble_type: Array<App_Loader_Metadata_Cc_Widget_Bubble_Type>;
  /** fetch aggregated fields from the table: "metadata.cc_widget_bubble_type" */
  metadata_cc_widget_bubble_type_aggregate: App_Loader_Metadata_Cc_Widget_Bubble_Type_Aggregate;
  /** fetch data from the table: "metadata.cc_widget_bubble_type" using primary key columns */
  metadata_cc_widget_bubble_type_by_pk?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type>;
  /** fetch data from the table: "metadata.cc_widget" using primary key columns */
  metadata_cc_widget_by_pk?: Maybe<App_Loader_Metadata_Cc_Widget>;
  /** fetch data from the table: "metadata.generic" */
  metadata_generic: Array<App_Loader_Metadata_Generic>;
  /** fetch aggregated fields from the table: "metadata.generic" */
  metadata_generic_aggregate: App_Loader_Metadata_Generic_Aggregate;
  /** fetch data from the table: "metadata.generic" using primary key columns */
  metadata_generic_by_pk?: Maybe<App_Loader_Metadata_Generic>;
  /** fetch data from the table: "metadata.generic_module_type" */
  metadata_generic_module_type: Array<App_Loader_Metadata_Generic_Module_Type>;
  /** fetch aggregated fields from the table: "metadata.generic_module_type" */
  metadata_generic_module_type_aggregate: App_Loader_Metadata_Generic_Module_Type_Aggregate;
  /** fetch data from the table: "metadata.generic_module_type" using primary key columns */
  metadata_generic_module_type_by_pk?: Maybe<App_Loader_Metadata_Generic_Module_Type>;
  /** fetch data from the table: "metadata.pype" */
  metadata_pype: Array<App_Loader_Metadata_Pype>;
  /** fetch aggregated fields from the table: "metadata.pype" */
  metadata_pype_aggregate: App_Loader_Metadata_Pype_Aggregate;
  /** fetch data from the table: "metadata.pype" using primary key columns */
  metadata_pype_by_pk?: Maybe<App_Loader_Metadata_Pype>;
  /** fetch data from the table: "metadata.pype_type" */
  metadata_pype_type: Array<App_Loader_Metadata_Pype_Type>;
  /** fetch aggregated fields from the table: "metadata.pype_type" */
  metadata_pype_type_aggregate: App_Loader_Metadata_Pype_Type_Aggregate;
  /** fetch data from the table: "metadata.pype_type" using primary key columns */
  metadata_pype_type_by_pk?: Maybe<App_Loader_Metadata_Pype_Type>;
  /** fetch data from the table: "n_analytic_package_configuration" */
  n_analytic_package_configuration: Array<App_Loader_N_Analytic_Package_Configuration>;
  /** fetch aggregated fields from the table: "n_analytic_package_configuration" */
  n_analytic_package_configuration_aggregate: App_Loader_N_Analytic_Package_Configuration_Aggregate;
  /** fetch data from the table: "n_analytic_package_configuration" using primary key columns */
  n_analytic_package_configuration_by_pk?: Maybe<App_Loader_N_Analytic_Package_Configuration>;
  /** fetch data from the table: "n_application" */
  n_application: Array<App_Loader_N_Application>;
  /** fetch aggregated fields from the table: "n_application" */
  n_application_aggregate: App_Loader_N_Application_Aggregate;
  /** fetch data from the table: "n_application" using primary key columns */
  n_application_by_pk?: Maybe<App_Loader_N_Application>;
  /** fetch data from the table: "n_domain" */
  n_domain: Array<App_Loader_N_Domain>;
  /** fetch aggregated fields from the table: "n_domain" */
  n_domain_aggregate: App_Loader_N_Domain_Aggregate;
  /** fetch data from the table: "n_domain" using primary key columns */
  n_domain_by_pk?: Maybe<App_Loader_N_Domain>;
  /** fetch data from the table: "n_package" */
  n_package: Array<App_Loader_N_Package>;
  /** fetch aggregated fields from the table: "n_package" */
  n_package_aggregate: App_Loader_N_Package_Aggregate;
  /** fetch data from the table: "n_package" using primary key columns */
  n_package_by_pk?: Maybe<App_Loader_N_Package>;
  /** fetch data from the table: "n_pype_package_configuration" */
  n_pype_package_configuration: Array<App_Loader_N_Pype_Package_Configuration>;
  /** fetch aggregated fields from the table: "n_pype_package_configuration" */
  n_pype_package_configuration_aggregate: App_Loader_N_Pype_Package_Configuration_Aggregate;
  /** fetch data from the table: "n_pype_package_configuration" using primary key columns */
  n_pype_package_configuration_by_pk?: Maybe<App_Loader_N_Pype_Package_Configuration>;
  /** An array relationship */
  target: Array<App_Loader_Target>;
  /** An aggregate relationship */
  target_aggregate: App_Loader_Target_Aggregate;
  /** fetch data from the table: "target" using primary key columns */
  target_by_pk?: Maybe<App_Loader_Target>;
  /** fetch data from the table: "target_type" */
  target_type: Array<App_Loader_Target_Type>;
  /** fetch aggregated fields from the table: "target_type" */
  target_type_aggregate: App_Loader_Target_Type_Aggregate;
  /** fetch data from the table: "target_type" using primary key columns */
  target_type_by_pk?: Maybe<App_Loader_Target_Type>;
};


export type App_Loader_Query_RootAppArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_App_Order_By>>;
  where?: InputMaybe<App_Loader_App_Bool_Exp>;
};


export type App_Loader_Query_RootApp_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_App_Order_By>>;
  where?: InputMaybe<App_Loader_App_Bool_Exp>;
};


export type App_Loader_Query_RootApp_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Query_RootConditionsArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Conditions_Order_By>>;
  where?: InputMaybe<App_Loader_Conditions_Bool_Exp>;
};


export type App_Loader_Query_RootConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Conditions_Order_By>>;
  where?: InputMaybe<App_Loader_Conditions_Bool_Exp>;
};


export type App_Loader_Query_RootConditions_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Query_RootCustomerArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Customer_Order_By>>;
  where?: InputMaybe<App_Loader_Customer_Bool_Exp>;
};


export type App_Loader_Query_RootCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Customer_Order_By>>;
  where?: InputMaybe<App_Loader_Customer_Bool_Exp>;
};


export type App_Loader_Query_RootCustomer_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Query_RootDeploymentArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Deployment_Order_By>>;
  where?: InputMaybe<App_Loader_Deployment_Bool_Exp>;
};


export type App_Loader_Query_RootDeployment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Deployment_Order_By>>;
  where?: InputMaybe<App_Loader_Deployment_Bool_Exp>;
};


export type App_Loader_Query_RootDeployment_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Query_RootEnvironmentArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Environment_Order_By>>;
  where?: InputMaybe<App_Loader_Environment_Bool_Exp>;
};


export type App_Loader_Query_RootEnvironment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Environment_Order_By>>;
  where?: InputMaybe<App_Loader_Environment_Bool_Exp>;
};


export type App_Loader_Query_RootEnvironment_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Query_RootLatest_Schema_Anonymous_SessionArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Anonymous_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Anonymous_Session_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp>;
};


export type App_Loader_Query_RootLatest_Schema_Anonymous_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Anonymous_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Anonymous_Session_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp>;
};


export type App_Loader_Query_RootLatest_Schema_Anonymous_Session_By_PkArgs = {
  session_id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Query_RootLatest_Schema_PackagesArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Bool_Exp>;
};


export type App_Loader_Query_RootLatest_Schema_Packages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Bool_Exp>;
};


export type App_Loader_Query_RootLatest_Schema_Packages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type App_Loader_Query_RootLatest_Schema_Packages_TypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Bool_Exp>;
};


export type App_Loader_Query_RootLatest_Schema_Packages_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Bool_Exp>;
};


export type App_Loader_Query_RootLatest_Schema_Packages_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type App_Loader_Query_RootMetadata_AnalyticsArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Analytics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Analytics_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Analytics_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Analytics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Analytics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Analytics_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Analytics_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Analytics_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Query_RootMetadata_Cc_Position_OptionArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Cc_Position_Option_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Cc_Position_Option_By_PkArgs = {
  position: Scalars['String']['input'];
};


export type App_Loader_Query_RootMetadata_Cc_WidgetArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Cc_Widget_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Cc_Widget_Bubble_TypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Cc_Widget_Bubble_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Cc_Widget_Bubble_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type App_Loader_Query_RootMetadata_Cc_Widget_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Query_RootMetadata_GenericArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Generic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Generic_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Generic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Generic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Generic_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Generic_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Query_RootMetadata_Generic_Module_TypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Generic_Module_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Generic_Module_Type_By_PkArgs = {
  moduleType: Scalars['String']['input'];
};


export type App_Loader_Query_RootMetadata_PypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Pype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Pype_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Pype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Pype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Pype_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Pype_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Query_RootMetadata_Pype_TypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Type_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Pype_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Type_Bool_Exp>;
};


export type App_Loader_Query_RootMetadata_Pype_Type_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type App_Loader_Query_RootN_Analytic_Package_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Analytic_Package_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Analytic_Package_Configuration_Order_By>>;
  where?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Bool_Exp>;
};


export type App_Loader_Query_RootN_Analytic_Package_Configuration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Analytic_Package_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Analytic_Package_Configuration_Order_By>>;
  where?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Bool_Exp>;
};


export type App_Loader_Query_RootN_Analytic_Package_Configuration_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Query_RootN_ApplicationArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Application_Order_By>>;
  where?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
};


export type App_Loader_Query_RootN_Application_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Application_Order_By>>;
  where?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
};


export type App_Loader_Query_RootN_Application_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Query_RootN_DomainArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Domain_Order_By>>;
  where?: InputMaybe<App_Loader_N_Domain_Bool_Exp>;
};


export type App_Loader_Query_RootN_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Domain_Order_By>>;
  where?: InputMaybe<App_Loader_N_Domain_Bool_Exp>;
};


export type App_Loader_Query_RootN_Domain_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Query_RootN_PackageArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Package_Order_By>>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};


export type App_Loader_Query_RootN_Package_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Package_Order_By>>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};


export type App_Loader_Query_RootN_Package_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Query_RootN_Pype_Package_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Pype_Package_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Pype_Package_Configuration_Order_By>>;
  where?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Bool_Exp>;
};


export type App_Loader_Query_RootN_Pype_Package_Configuration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Pype_Package_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Pype_Package_Configuration_Order_By>>;
  where?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Bool_Exp>;
};


export type App_Loader_Query_RootN_Pype_Package_Configuration_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Query_RootTargetArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Target_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Target_Order_By>>;
  where?: InputMaybe<App_Loader_Target_Bool_Exp>;
};


export type App_Loader_Query_RootTarget_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Target_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Target_Order_By>>;
  where?: InputMaybe<App_Loader_Target_Bool_Exp>;
};


export type App_Loader_Query_RootTarget_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Query_RootTarget_TypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Target_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Target_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Target_Type_Bool_Exp>;
};


export type App_Loader_Query_RootTarget_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Target_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Target_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Target_Type_Bool_Exp>;
};


export type App_Loader_Query_RootTarget_Type_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type App_Loader_Subscription_Root = {
  __typename?: 'app_loader_subscription_root';
  /** fetch data from the table: "app" */
  app: Array<App_Loader_App>;
  /** fetch aggregated fields from the table: "app" */
  app_aggregate: App_Loader_App_Aggregate;
  /** fetch data from the table: "app" using primary key columns */
  app_by_pk?: Maybe<App_Loader_App>;
  /** fetch data from the table in a streaming manner: "app" */
  app_stream: Array<App_Loader_App>;
  /** fetch data from the table: "conditions" */
  conditions: Array<App_Loader_Conditions>;
  /** fetch aggregated fields from the table: "conditions" */
  conditions_aggregate: App_Loader_Conditions_Aggregate;
  /** fetch data from the table: "conditions" using primary key columns */
  conditions_by_pk?: Maybe<App_Loader_Conditions>;
  /** fetch data from the table in a streaming manner: "conditions" */
  conditions_stream: Array<App_Loader_Conditions>;
  /** fetch data from the table: "customer" */
  customer: Array<App_Loader_Customer>;
  /** fetch aggregated fields from the table: "customer" */
  customer_aggregate: App_Loader_Customer_Aggregate;
  /** fetch data from the table: "customer" using primary key columns */
  customer_by_pk?: Maybe<App_Loader_Customer>;
  /** fetch data from the table in a streaming manner: "customer" */
  customer_stream: Array<App_Loader_Customer>;
  /** fetch data from the table: "deployment" */
  deployment: Array<App_Loader_Deployment>;
  /** fetch aggregated fields from the table: "deployment" */
  deployment_aggregate: App_Loader_Deployment_Aggregate;
  /** fetch data from the table: "deployment" using primary key columns */
  deployment_by_pk?: Maybe<App_Loader_Deployment>;
  /** fetch data from the table in a streaming manner: "deployment" */
  deployment_stream: Array<App_Loader_Deployment>;
  /** An array relationship */
  environment: Array<App_Loader_Environment>;
  /** An aggregate relationship */
  environment_aggregate: App_Loader_Environment_Aggregate;
  /** fetch data from the table: "environment" using primary key columns */
  environment_by_pk?: Maybe<App_Loader_Environment>;
  /** fetch data from the table in a streaming manner: "environment" */
  environment_stream: Array<App_Loader_Environment>;
  /** fetch data from the table: "latest_schema.anonymous_session" */
  latest_schema_anonymous_session: Array<App_Loader_Latest_Schema_Anonymous_Session>;
  /** fetch aggregated fields from the table: "latest_schema.anonymous_session" */
  latest_schema_anonymous_session_aggregate: App_Loader_Latest_Schema_Anonymous_Session_Aggregate;
  /** fetch data from the table: "latest_schema.anonymous_session" using primary key columns */
  latest_schema_anonymous_session_by_pk?: Maybe<App_Loader_Latest_Schema_Anonymous_Session>;
  /** fetch data from the table in a streaming manner: "latest_schema.anonymous_session" */
  latest_schema_anonymous_session_stream: Array<App_Loader_Latest_Schema_Anonymous_Session>;
  /** fetch data from the table: "latest_schema.packages" */
  latest_schema_packages: Array<App_Loader_Latest_Schema_Packages>;
  /** fetch aggregated fields from the table: "latest_schema.packages" */
  latest_schema_packages_aggregate: App_Loader_Latest_Schema_Packages_Aggregate;
  /** fetch data from the table: "latest_schema.packages" using primary key columns */
  latest_schema_packages_by_pk?: Maybe<App_Loader_Latest_Schema_Packages>;
  /** fetch data from the table in a streaming manner: "latest_schema.packages" */
  latest_schema_packages_stream: Array<App_Loader_Latest_Schema_Packages>;
  /** fetch data from the table: "latest_schema.packages_type" */
  latest_schema_packages_type: Array<App_Loader_Latest_Schema_Packages_Type>;
  /** fetch aggregated fields from the table: "latest_schema.packages_type" */
  latest_schema_packages_type_aggregate: App_Loader_Latest_Schema_Packages_Type_Aggregate;
  /** fetch data from the table: "latest_schema.packages_type" using primary key columns */
  latest_schema_packages_type_by_pk?: Maybe<App_Loader_Latest_Schema_Packages_Type>;
  /** fetch data from the table in a streaming manner: "latest_schema.packages_type" */
  latest_schema_packages_type_stream: Array<App_Loader_Latest_Schema_Packages_Type>;
  /** fetch data from the table: "metadata.analytics" */
  metadata_analytics: Array<App_Loader_Metadata_Analytics>;
  /** fetch aggregated fields from the table: "metadata.analytics" */
  metadata_analytics_aggregate: App_Loader_Metadata_Analytics_Aggregate;
  /** fetch data from the table: "metadata.analytics" using primary key columns */
  metadata_analytics_by_pk?: Maybe<App_Loader_Metadata_Analytics>;
  /** fetch data from the table in a streaming manner: "metadata.analytics" */
  metadata_analytics_stream: Array<App_Loader_Metadata_Analytics>;
  /** fetch data from the table: "metadata.cc_position_option" */
  metadata_cc_position_option: Array<App_Loader_Metadata_Cc_Position_Option>;
  /** fetch aggregated fields from the table: "metadata.cc_position_option" */
  metadata_cc_position_option_aggregate: App_Loader_Metadata_Cc_Position_Option_Aggregate;
  /** fetch data from the table: "metadata.cc_position_option" using primary key columns */
  metadata_cc_position_option_by_pk?: Maybe<App_Loader_Metadata_Cc_Position_Option>;
  /** fetch data from the table in a streaming manner: "metadata.cc_position_option" */
  metadata_cc_position_option_stream: Array<App_Loader_Metadata_Cc_Position_Option>;
  /** fetch data from the table: "metadata.cc_widget" */
  metadata_cc_widget: Array<App_Loader_Metadata_Cc_Widget>;
  /** fetch aggregated fields from the table: "metadata.cc_widget" */
  metadata_cc_widget_aggregate: App_Loader_Metadata_Cc_Widget_Aggregate;
  /** fetch data from the table: "metadata.cc_widget_bubble_type" */
  metadata_cc_widget_bubble_type: Array<App_Loader_Metadata_Cc_Widget_Bubble_Type>;
  /** fetch aggregated fields from the table: "metadata.cc_widget_bubble_type" */
  metadata_cc_widget_bubble_type_aggregate: App_Loader_Metadata_Cc_Widget_Bubble_Type_Aggregate;
  /** fetch data from the table: "metadata.cc_widget_bubble_type" using primary key columns */
  metadata_cc_widget_bubble_type_by_pk?: Maybe<App_Loader_Metadata_Cc_Widget_Bubble_Type>;
  /** fetch data from the table in a streaming manner: "metadata.cc_widget_bubble_type" */
  metadata_cc_widget_bubble_type_stream: Array<App_Loader_Metadata_Cc_Widget_Bubble_Type>;
  /** fetch data from the table: "metadata.cc_widget" using primary key columns */
  metadata_cc_widget_by_pk?: Maybe<App_Loader_Metadata_Cc_Widget>;
  /** fetch data from the table in a streaming manner: "metadata.cc_widget" */
  metadata_cc_widget_stream: Array<App_Loader_Metadata_Cc_Widget>;
  /** fetch data from the table: "metadata.generic" */
  metadata_generic: Array<App_Loader_Metadata_Generic>;
  /** fetch aggregated fields from the table: "metadata.generic" */
  metadata_generic_aggregate: App_Loader_Metadata_Generic_Aggregate;
  /** fetch data from the table: "metadata.generic" using primary key columns */
  metadata_generic_by_pk?: Maybe<App_Loader_Metadata_Generic>;
  /** fetch data from the table: "metadata.generic_module_type" */
  metadata_generic_module_type: Array<App_Loader_Metadata_Generic_Module_Type>;
  /** fetch aggregated fields from the table: "metadata.generic_module_type" */
  metadata_generic_module_type_aggregate: App_Loader_Metadata_Generic_Module_Type_Aggregate;
  /** fetch data from the table: "metadata.generic_module_type" using primary key columns */
  metadata_generic_module_type_by_pk?: Maybe<App_Loader_Metadata_Generic_Module_Type>;
  /** fetch data from the table in a streaming manner: "metadata.generic_module_type" */
  metadata_generic_module_type_stream: Array<App_Loader_Metadata_Generic_Module_Type>;
  /** fetch data from the table in a streaming manner: "metadata.generic" */
  metadata_generic_stream: Array<App_Loader_Metadata_Generic>;
  /** fetch data from the table: "metadata.pype" */
  metadata_pype: Array<App_Loader_Metadata_Pype>;
  /** fetch aggregated fields from the table: "metadata.pype" */
  metadata_pype_aggregate: App_Loader_Metadata_Pype_Aggregate;
  /** fetch data from the table: "metadata.pype" using primary key columns */
  metadata_pype_by_pk?: Maybe<App_Loader_Metadata_Pype>;
  /** fetch data from the table in a streaming manner: "metadata.pype" */
  metadata_pype_stream: Array<App_Loader_Metadata_Pype>;
  /** fetch data from the table: "metadata.pype_type" */
  metadata_pype_type: Array<App_Loader_Metadata_Pype_Type>;
  /** fetch aggregated fields from the table: "metadata.pype_type" */
  metadata_pype_type_aggregate: App_Loader_Metadata_Pype_Type_Aggregate;
  /** fetch data from the table: "metadata.pype_type" using primary key columns */
  metadata_pype_type_by_pk?: Maybe<App_Loader_Metadata_Pype_Type>;
  /** fetch data from the table in a streaming manner: "metadata.pype_type" */
  metadata_pype_type_stream: Array<App_Loader_Metadata_Pype_Type>;
  /** fetch data from the table: "n_analytic_package_configuration" */
  n_analytic_package_configuration: Array<App_Loader_N_Analytic_Package_Configuration>;
  /** fetch aggregated fields from the table: "n_analytic_package_configuration" */
  n_analytic_package_configuration_aggregate: App_Loader_N_Analytic_Package_Configuration_Aggregate;
  /** fetch data from the table: "n_analytic_package_configuration" using primary key columns */
  n_analytic_package_configuration_by_pk?: Maybe<App_Loader_N_Analytic_Package_Configuration>;
  /** fetch data from the table in a streaming manner: "n_analytic_package_configuration" */
  n_analytic_package_configuration_stream: Array<App_Loader_N_Analytic_Package_Configuration>;
  /** fetch data from the table: "n_application" */
  n_application: Array<App_Loader_N_Application>;
  /** fetch aggregated fields from the table: "n_application" */
  n_application_aggregate: App_Loader_N_Application_Aggregate;
  /** fetch data from the table: "n_application" using primary key columns */
  n_application_by_pk?: Maybe<App_Loader_N_Application>;
  /** fetch data from the table in a streaming manner: "n_application" */
  n_application_stream: Array<App_Loader_N_Application>;
  /** fetch data from the table: "n_domain" */
  n_domain: Array<App_Loader_N_Domain>;
  /** fetch aggregated fields from the table: "n_domain" */
  n_domain_aggregate: App_Loader_N_Domain_Aggregate;
  /** fetch data from the table: "n_domain" using primary key columns */
  n_domain_by_pk?: Maybe<App_Loader_N_Domain>;
  /** fetch data from the table in a streaming manner: "n_domain" */
  n_domain_stream: Array<App_Loader_N_Domain>;
  /** fetch data from the table: "n_package" */
  n_package: Array<App_Loader_N_Package>;
  /** fetch aggregated fields from the table: "n_package" */
  n_package_aggregate: App_Loader_N_Package_Aggregate;
  /** fetch data from the table: "n_package" using primary key columns */
  n_package_by_pk?: Maybe<App_Loader_N_Package>;
  /** fetch data from the table in a streaming manner: "n_package" */
  n_package_stream: Array<App_Loader_N_Package>;
  /** fetch data from the table: "n_pype_package_configuration" */
  n_pype_package_configuration: Array<App_Loader_N_Pype_Package_Configuration>;
  /** fetch aggregated fields from the table: "n_pype_package_configuration" */
  n_pype_package_configuration_aggregate: App_Loader_N_Pype_Package_Configuration_Aggregate;
  /** fetch data from the table: "n_pype_package_configuration" using primary key columns */
  n_pype_package_configuration_by_pk?: Maybe<App_Loader_N_Pype_Package_Configuration>;
  /** fetch data from the table in a streaming manner: "n_pype_package_configuration" */
  n_pype_package_configuration_stream: Array<App_Loader_N_Pype_Package_Configuration>;
  /** An array relationship */
  target: Array<App_Loader_Target>;
  /** An aggregate relationship */
  target_aggregate: App_Loader_Target_Aggregate;
  /** fetch data from the table: "target" using primary key columns */
  target_by_pk?: Maybe<App_Loader_Target>;
  /** fetch data from the table in a streaming manner: "target" */
  target_stream: Array<App_Loader_Target>;
  /** fetch data from the table: "target_type" */
  target_type: Array<App_Loader_Target_Type>;
  /** fetch aggregated fields from the table: "target_type" */
  target_type_aggregate: App_Loader_Target_Type_Aggregate;
  /** fetch data from the table: "target_type" using primary key columns */
  target_type_by_pk?: Maybe<App_Loader_Target_Type>;
  /** fetch data from the table in a streaming manner: "target_type" */
  target_type_stream: Array<App_Loader_Target_Type>;
};


export type App_Loader_Subscription_RootAppArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_App_Order_By>>;
  where?: InputMaybe<App_Loader_App_Bool_Exp>;
};


export type App_Loader_Subscription_RootApp_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_App_Order_By>>;
  where?: InputMaybe<App_Loader_App_Bool_Exp>;
};


export type App_Loader_Subscription_RootApp_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Subscription_RootApp_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_App_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_App_Bool_Exp>;
};


export type App_Loader_Subscription_RootConditionsArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Conditions_Order_By>>;
  where?: InputMaybe<App_Loader_Conditions_Bool_Exp>;
};


export type App_Loader_Subscription_RootConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Conditions_Order_By>>;
  where?: InputMaybe<App_Loader_Conditions_Bool_Exp>;
};


export type App_Loader_Subscription_RootConditions_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Subscription_RootConditions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Conditions_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Conditions_Bool_Exp>;
};


export type App_Loader_Subscription_RootCustomerArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Customer_Order_By>>;
  where?: InputMaybe<App_Loader_Customer_Bool_Exp>;
};


export type App_Loader_Subscription_RootCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Customer_Order_By>>;
  where?: InputMaybe<App_Loader_Customer_Bool_Exp>;
};


export type App_Loader_Subscription_RootCustomer_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Subscription_RootCustomer_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Customer_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Customer_Bool_Exp>;
};


export type App_Loader_Subscription_RootDeploymentArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Deployment_Order_By>>;
  where?: InputMaybe<App_Loader_Deployment_Bool_Exp>;
};


export type App_Loader_Subscription_RootDeployment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Deployment_Order_By>>;
  where?: InputMaybe<App_Loader_Deployment_Bool_Exp>;
};


export type App_Loader_Subscription_RootDeployment_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Subscription_RootDeployment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Deployment_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Deployment_Bool_Exp>;
};


export type App_Loader_Subscription_RootEnvironmentArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Environment_Order_By>>;
  where?: InputMaybe<App_Loader_Environment_Bool_Exp>;
};


export type App_Loader_Subscription_RootEnvironment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Environment_Order_By>>;
  where?: InputMaybe<App_Loader_Environment_Bool_Exp>;
};


export type App_Loader_Subscription_RootEnvironment_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Subscription_RootEnvironment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Environment_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Environment_Bool_Exp>;
};


export type App_Loader_Subscription_RootLatest_Schema_Anonymous_SessionArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Anonymous_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Anonymous_Session_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp>;
};


export type App_Loader_Subscription_RootLatest_Schema_Anonymous_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Anonymous_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Anonymous_Session_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp>;
};


export type App_Loader_Subscription_RootLatest_Schema_Anonymous_Session_By_PkArgs = {
  session_id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Subscription_RootLatest_Schema_Anonymous_Session_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Anonymous_Session_Bool_Exp>;
};


export type App_Loader_Subscription_RootLatest_Schema_PackagesArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Bool_Exp>;
};


export type App_Loader_Subscription_RootLatest_Schema_Packages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Bool_Exp>;
};


export type App_Loader_Subscription_RootLatest_Schema_Packages_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type App_Loader_Subscription_RootLatest_Schema_Packages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Latest_Schema_Packages_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Bool_Exp>;
};


export type App_Loader_Subscription_RootLatest_Schema_Packages_TypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootLatest_Schema_Packages_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Latest_Schema_Packages_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootLatest_Schema_Packages_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type App_Loader_Subscription_RootLatest_Schema_Packages_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Latest_Schema_Packages_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Latest_Schema_Packages_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_AnalyticsArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Analytics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Analytics_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Analytics_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Analytics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Analytics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Analytics_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Analytics_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Analytics_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Subscription_RootMetadata_Analytics_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Metadata_Analytics_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Metadata_Analytics_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Cc_Position_OptionArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Cc_Position_Option_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Position_Option_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Cc_Position_Option_By_PkArgs = {
  position: Scalars['String']['input'];
};


export type App_Loader_Subscription_RootMetadata_Cc_Position_Option_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Metadata_Cc_Position_Option_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Position_Option_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Cc_WidgetArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Cc_Widget_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Cc_Widget_Bubble_TypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Cc_Widget_Bubble_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Cc_Widget_Bubble_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Cc_Widget_Bubble_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};


export type App_Loader_Subscription_RootMetadata_Cc_Widget_Bubble_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bubble_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Cc_Widget_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Subscription_RootMetadata_Cc_Widget_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Metadata_Cc_Widget_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_GenericArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Generic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Generic_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Generic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Generic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Generic_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Generic_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Subscription_RootMetadata_Generic_Module_TypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Generic_Module_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Generic_Module_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Generic_Module_Type_By_PkArgs = {
  moduleType: Scalars['String']['input'];
};


export type App_Loader_Subscription_RootMetadata_Generic_Module_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Metadata_Generic_Module_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Module_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Generic_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Metadata_Generic_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Metadata_Generic_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_PypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Pype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Pype_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Pype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Pype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Pype_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Pype_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Subscription_RootMetadata_Pype_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Metadata_Pype_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Pype_TypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Pype_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Metadata_Pype_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootMetadata_Pype_Type_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type App_Loader_Subscription_RootMetadata_Pype_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Metadata_Pype_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Metadata_Pype_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Analytic_Package_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Analytic_Package_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Analytic_Package_Configuration_Order_By>>;
  where?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Analytic_Package_Configuration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Analytic_Package_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Analytic_Package_Configuration_Order_By>>;
  where?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Analytic_Package_Configuration_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Subscription_RootN_Analytic_Package_Configuration_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_N_Analytic_Package_Configuration_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_N_Analytic_Package_Configuration_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_ApplicationArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Application_Order_By>>;
  where?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Application_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Application_Order_By>>;
  where?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Application_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Subscription_RootN_Application_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_N_Application_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_N_Application_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_DomainArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Domain_Order_By>>;
  where?: InputMaybe<App_Loader_N_Domain_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Domain_Order_By>>;
  where?: InputMaybe<App_Loader_N_Domain_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Domain_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Subscription_RootN_Domain_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_N_Domain_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_N_Domain_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_PackageArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Package_Order_By>>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Package_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Package_Order_By>>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Package_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Subscription_RootN_Package_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_N_Package_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_N_Package_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Pype_Package_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Pype_Package_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Pype_Package_Configuration_Order_By>>;
  where?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Pype_Package_Configuration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_N_Pype_Package_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_N_Pype_Package_Configuration_Order_By>>;
  where?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Bool_Exp>;
};


export type App_Loader_Subscription_RootN_Pype_Package_Configuration_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type App_Loader_Subscription_RootN_Pype_Package_Configuration_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_N_Pype_Package_Configuration_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_N_Pype_Package_Configuration_Bool_Exp>;
};


export type App_Loader_Subscription_RootTargetArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Target_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Target_Order_By>>;
  where?: InputMaybe<App_Loader_Target_Bool_Exp>;
};


export type App_Loader_Subscription_RootTarget_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Target_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Target_Order_By>>;
  where?: InputMaybe<App_Loader_Target_Bool_Exp>;
};


export type App_Loader_Subscription_RootTarget_By_PkArgs = {
  id: Scalars['app_loader_uuid']['input'];
};


export type App_Loader_Subscription_RootTarget_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Target_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Target_Bool_Exp>;
};


export type App_Loader_Subscription_RootTarget_TypeArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Target_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Target_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Target_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootTarget_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Target_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Target_Type_Order_By>>;
  where?: InputMaybe<App_Loader_Target_Type_Bool_Exp>;
};


export type App_Loader_Subscription_RootTarget_Type_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type App_Loader_Subscription_RootTarget_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Target_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Target_Type_Bool_Exp>;
};

/** columns and relationships of "target" */
export type App_Loader_Target = {
  __typename?: 'app_loader_target';
  /** An object relationship */
  analytics?: Maybe<App_Loader_Metadata_Analytics>;
  /** An object relationship */
  ccWidget?: Maybe<App_Loader_Metadata_Cc_Widget>;
  envId: Scalars['app_loader_uuid']['output'];
  /** An object relationship */
  environment?: Maybe<App_Loader_Environment>;
  /** An object relationship */
  generic?: Maybe<App_Loader_Metadata_Generic>;
  id: Scalars['app_loader_uuid']['output'];
  /** An object relationship */
  pype?: Maybe<App_Loader_Metadata_Pype>;
  type: App_Loader_Target_Type_Enum;
};

/** aggregated selection of "target" */
export type App_Loader_Target_Aggregate = {
  __typename?: 'app_loader_target_aggregate';
  aggregate?: Maybe<App_Loader_Target_Aggregate_Fields>;
  nodes: Array<App_Loader_Target>;
};

export type App_Loader_Target_Aggregate_Bool_Exp = {
  count?: InputMaybe<App_Loader_Target_Aggregate_Bool_Exp_Count>;
};

export type App_Loader_Target_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<App_Loader_Target_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<App_Loader_Target_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "target" */
export type App_Loader_Target_Aggregate_Fields = {
  __typename?: 'app_loader_target_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Target_Max_Fields>;
  min?: Maybe<App_Loader_Target_Min_Fields>;
};


/** aggregate fields of "target" */
export type App_Loader_Target_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Target_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "target" */
export type App_Loader_Target_Aggregate_Order_By = {
  count?: InputMaybe<App_Loader_Order_By>;
  max?: InputMaybe<App_Loader_Target_Max_Order_By>;
  min?: InputMaybe<App_Loader_Target_Min_Order_By>;
};

/** input type for inserting array relation for remote table "target" */
export type App_Loader_Target_Arr_Rel_Insert_Input = {
  data: Array<App_Loader_Target_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<App_Loader_Target_On_Conflict>;
};

/** Boolean expression to filter rows from the table "target". All fields are combined with a logical 'AND'. */
export type App_Loader_Target_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Target_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Target_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Target_Bool_Exp>>;
  analytics?: InputMaybe<App_Loader_Metadata_Analytics_Bool_Exp>;
  ccWidget?: InputMaybe<App_Loader_Metadata_Cc_Widget_Bool_Exp>;
  envId?: InputMaybe<Uuid_Comparison_Exp>;
  environment?: InputMaybe<App_Loader_Environment_Bool_Exp>;
  generic?: InputMaybe<App_Loader_Metadata_Generic_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  pype?: InputMaybe<App_Loader_Metadata_Pype_Bool_Exp>;
  type?: InputMaybe<App_Loader_Target_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "target" */
export enum App_Loader_Target_Constraint {
  /** unique or primary key constraint on columns "id" */
  TargetPkey = 'target_pkey'
}

/** input type for inserting data into table "target" */
export type App_Loader_Target_Insert_Input = {
  analytics?: InputMaybe<App_Loader_Metadata_Analytics_Obj_Rel_Insert_Input>;
  ccWidget?: InputMaybe<App_Loader_Metadata_Cc_Widget_Obj_Rel_Insert_Input>;
  envId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  environment?: InputMaybe<App_Loader_Environment_Obj_Rel_Insert_Input>;
  generic?: InputMaybe<App_Loader_Metadata_Generic_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  pype?: InputMaybe<App_Loader_Metadata_Pype_Obj_Rel_Insert_Input>;
  type?: InputMaybe<App_Loader_Target_Type_Enum>;
};

/** aggregate max on columns */
export type App_Loader_Target_Max_Fields = {
  __typename?: 'app_loader_target_max_fields';
  envId?: Maybe<Scalars['app_loader_uuid']['output']>;
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** order by max() on columns of table "target" */
export type App_Loader_Target_Max_Order_By = {
  envId?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** aggregate min on columns */
export type App_Loader_Target_Min_Fields = {
  __typename?: 'app_loader_target_min_fields';
  envId?: Maybe<Scalars['app_loader_uuid']['output']>;
  id?: Maybe<Scalars['app_loader_uuid']['output']>;
};

/** order by min() on columns of table "target" */
export type App_Loader_Target_Min_Order_By = {
  envId?: InputMaybe<App_Loader_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
};

/** response of any mutation on the table "target" */
export type App_Loader_Target_Mutation_Response = {
  __typename?: 'app_loader_target_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Target>;
};

/** on_conflict condition type for table "target" */
export type App_Loader_Target_On_Conflict = {
  constraint: App_Loader_Target_Constraint;
  update_columns?: Array<App_Loader_Target_Update_Column>;
  where?: InputMaybe<App_Loader_Target_Bool_Exp>;
};

/** Ordering options when selecting data from "target". */
export type App_Loader_Target_Order_By = {
  analytics?: InputMaybe<App_Loader_Metadata_Analytics_Order_By>;
  ccWidget?: InputMaybe<App_Loader_Metadata_Cc_Widget_Order_By>;
  envId?: InputMaybe<App_Loader_Order_By>;
  environment?: InputMaybe<App_Loader_Environment_Order_By>;
  generic?: InputMaybe<App_Loader_Metadata_Generic_Order_By>;
  id?: InputMaybe<App_Loader_Order_By>;
  pype?: InputMaybe<App_Loader_Metadata_Pype_Order_By>;
  type?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: target */
export type App_Loader_Target_Pk_Columns_Input = {
  id: Scalars['app_loader_uuid']['input'];
};

/** select columns of table "target" */
export enum App_Loader_Target_Select_Column {
  /** column name */
  EnvId = 'envId',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "target" */
export type App_Loader_Target_Set_Input = {
  envId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  type?: InputMaybe<App_Loader_Target_Type_Enum>;
};

/** Streaming cursor of the table "target" */
export type App_Loader_Target_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Target_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Target_Stream_Cursor_Value_Input = {
  envId?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  id?: InputMaybe<Scalars['app_loader_uuid']['input']>;
  type?: InputMaybe<App_Loader_Target_Type_Enum>;
};

/** columns and relationships of "target_type" */
export type App_Loader_Target_Type = {
  __typename?: 'app_loader_target_type';
  name: Scalars['String']['output'];
};

/** aggregated selection of "target_type" */
export type App_Loader_Target_Type_Aggregate = {
  __typename?: 'app_loader_target_type_aggregate';
  aggregate?: Maybe<App_Loader_Target_Type_Aggregate_Fields>;
  nodes: Array<App_Loader_Target_Type>;
};

/** aggregate fields of "target_type" */
export type App_Loader_Target_Type_Aggregate_Fields = {
  __typename?: 'app_loader_target_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<App_Loader_Target_Type_Max_Fields>;
  min?: Maybe<App_Loader_Target_Type_Min_Fields>;
};


/** aggregate fields of "target_type" */
export type App_Loader_Target_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Loader_Target_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "target_type". All fields are combined with a logical 'AND'. */
export type App_Loader_Target_Type_Bool_Exp = {
  _and?: InputMaybe<Array<App_Loader_Target_Type_Bool_Exp>>;
  _not?: InputMaybe<App_Loader_Target_Type_Bool_Exp>;
  _or?: InputMaybe<Array<App_Loader_Target_Type_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "target_type" */
export enum App_Loader_Target_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  PlatformEnumPkey = 'platform_enum_pkey'
}

export enum App_Loader_Target_Type_Enum {
  Analytics = 'Analytics',
  CcWidget = 'CCWidget',
  Generic = 'Generic',
  Pype = 'Pype'
}

/** Boolean expression to compare columns of type "target_type_enum". All fields are combined with logical 'AND'. */
export type App_Loader_Target_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<App_Loader_Target_Type_Enum>;
  _in?: InputMaybe<Array<App_Loader_Target_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<App_Loader_Target_Type_Enum>;
  _nin?: InputMaybe<Array<App_Loader_Target_Type_Enum>>;
};

/** input type for inserting data into table "target_type" */
export type App_Loader_Target_Type_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type App_Loader_Target_Type_Max_Fields = {
  __typename?: 'app_loader_target_type_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type App_Loader_Target_Type_Min_Fields = {
  __typename?: 'app_loader_target_type_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "target_type" */
export type App_Loader_Target_Type_Mutation_Response = {
  __typename?: 'app_loader_target_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Loader_Target_Type>;
};

/** on_conflict condition type for table "target_type" */
export type App_Loader_Target_Type_On_Conflict = {
  constraint: App_Loader_Target_Type_Constraint;
  update_columns?: Array<App_Loader_Target_Type_Update_Column>;
  where?: InputMaybe<App_Loader_Target_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "target_type". */
export type App_Loader_Target_Type_Order_By = {
  name?: InputMaybe<App_Loader_Order_By>;
};

/** primary key columns input for table: target_type */
export type App_Loader_Target_Type_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "target_type" */
export enum App_Loader_Target_Type_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "target_type" */
export type App_Loader_Target_Type_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "target_type" */
export type App_Loader_Target_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Loader_Target_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<App_Loader_Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Loader_Target_Type_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "target_type" */
export enum App_Loader_Target_Type_Update_Column {
  /** column name */
  Name = 'name'
}

export type App_Loader_Target_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Target_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Target_Type_Bool_Exp;
};

/** update columns of table "target" */
export enum App_Loader_Target_Update_Column {
  /** column name */
  EnvId = 'envId',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

export type App_Loader_Target_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<App_Loader_Target_Set_Input>;
  /** filter the rows which have to be updated */
  where: App_Loader_Target_Bool_Exp;
};

/** columns and relationships of "apps" */
export type Apps = {
  __typename?: 'apps';
  app_id: Scalars['String']['output'];
  /** An array relationship */
  available_environments: Array<Environments>;
  /** An aggregate relationship */
  available_environments_aggregate: Environments_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  created_by: Scalars['uuid']['output'];
  default_workflow_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  deployment_history: Array<Deployments>;
  /** An aggregate relationship */
  deployment_history_aggregate: Deployments_Aggregate;
  /** An object relationship */
  graph?: Maybe<Graph>;
  graph_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  version_history: Array<Versions>;
  /** An aggregate relationship */
  version_history_aggregate: Versions_Aggregate;
};


/** columns and relationships of "apps" */
export type AppsAvailable_EnvironmentsArgs = {
  distinct_on?: InputMaybe<Array<Environments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Environments_Order_By>>;
  where?: InputMaybe<Environments_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsAvailable_Environments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Environments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Environments_Order_By>>;
  where?: InputMaybe<Environments_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsDeployment_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deployments_Order_By>>;
  where?: InputMaybe<Deployments_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsDeployment_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deployments_Order_By>>;
  where?: InputMaybe<Deployments_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsVersion_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Versions_Order_By>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};


/** columns and relationships of "apps" */
export type AppsVersion_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Versions_Order_By>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};

/** aggregated selection of "apps" */
export type Apps_Aggregate = {
  __typename?: 'apps_aggregate';
  aggregate?: Maybe<Apps_Aggregate_Fields>;
  nodes: Array<Apps>;
};

/** aggregate fields of "apps" */
export type Apps_Aggregate_Fields = {
  __typename?: 'apps_aggregate_fields';
  avg?: Maybe<Apps_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Max_Fields>;
  min?: Maybe<Apps_Min_Fields>;
  stddev?: Maybe<Apps_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Sum_Fields>;
  var_pop?: Maybe<Apps_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Var_Samp_Fields>;
  variance?: Maybe<Apps_Variance_Fields>;
};


/** aggregate fields of "apps" */
export type Apps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "apps.app" */
export type Apps_App = {
  __typename?: 'apps_app';
  /** An object relationship */
  app_config?: Maybe<Apps_App_Config>;
  app_config_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  app_files: Array<Apps_App_File>;
  /** An aggregate relationship */
  app_files_aggregate: Apps_App_File_Aggregate;
  app_id: Scalars['Int']['output'];
  app_name: Scalars['String']['output'];
  config?: Maybe<Scalars['jsonb']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  customer?: Maybe<Apps_Customer>;
  customer_id?: Maybe<Scalars['Int']['output']>;
  full_name: Scalars['String']['output'];
  gui_metadata?: Maybe<Scalars['jsonb']['output']>;
  last_updated?: Maybe<Scalars['timestamptz']['output']>;
  nlu_config?: Maybe<Scalars['jsonb']['output']>;
  translate_input?: Maybe<Scalars['Boolean']['output']>;
  translate_output?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  versions: Array<Apps_Version>;
  /** An aggregate relationship */
  versions_aggregate: Apps_Version_Aggregate;
};


/** columns and relationships of "apps.app" */
export type Apps_AppApp_FilesArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_File_Order_By>>;
  where?: InputMaybe<Apps_App_File_Bool_Exp>;
};


/** columns and relationships of "apps.app" */
export type Apps_AppApp_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_File_Order_By>>;
  where?: InputMaybe<Apps_App_File_Bool_Exp>;
};


/** columns and relationships of "apps.app" */
export type Apps_AppConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.app" */
export type Apps_AppGui_MetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.app" */
export type Apps_AppNlu_ConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.app" */
export type Apps_AppVersionsArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};


/** columns and relationships of "apps.app" */
export type Apps_AppVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};

/** aggregated selection of "apps.app" */
export type Apps_App_Aggregate = {
  __typename?: 'apps_app_aggregate';
  aggregate?: Maybe<Apps_App_Aggregate_Fields>;
  nodes: Array<Apps_App>;
};

export type Apps_App_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Apps_App_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Apps_App_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Apps_App_Aggregate_Bool_Exp_Count>;
};

export type Apps_App_Aggregate_Bool_Exp_Bool_And = {
  arguments: Apps_App_Select_Column_Apps_App_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_App_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Apps_App_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Apps_App_Select_Column_Apps_App_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_App_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Apps_App_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_App_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_App_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps.app" */
export type Apps_App_Aggregate_Fields = {
  __typename?: 'apps_app_aggregate_fields';
  avg?: Maybe<Apps_App_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_App_Max_Fields>;
  min?: Maybe<Apps_App_Min_Fields>;
  stddev?: Maybe<Apps_App_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_App_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_App_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_App_Sum_Fields>;
  var_pop?: Maybe<Apps_App_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_App_Var_Samp_Fields>;
  variance?: Maybe<Apps_App_Variance_Fields>;
};


/** aggregate fields of "apps.app" */
export type Apps_App_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_App_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps.app" */
export type Apps_App_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_App_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_App_Max_Order_By>;
  min?: InputMaybe<Apps_App_Min_Order_By>;
  stddev?: InputMaybe<Apps_App_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_App_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_App_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_App_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_App_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_App_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_App_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_App_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  gui_metadata?: InputMaybe<Scalars['jsonb']['input']>;
  nlu_config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "apps.app" */
export type Apps_App_Arr_Rel_Insert_Input = {
  data: Array<Apps_App_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_App_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_App_Avg_Fields = {
  __typename?: 'apps_app_avg_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps.app" */
export type Apps_App_Avg_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps.app". All fields are combined with a logical 'AND'. */
export type Apps_App_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_App_Bool_Exp>>;
  _not?: InputMaybe<Apps_App_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_App_Bool_Exp>>;
  app_config?: InputMaybe<Apps_App_Config_Bool_Exp>;
  app_config_id?: InputMaybe<Int_Comparison_Exp>;
  app_files?: InputMaybe<Apps_App_File_Bool_Exp>;
  app_files_aggregate?: InputMaybe<Apps_App_File_Aggregate_Bool_Exp>;
  app_id?: InputMaybe<Int_Comparison_Exp>;
  app_name?: InputMaybe<String_Comparison_Exp>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Apps_Customer_Bool_Exp>;
  customer_id?: InputMaybe<Int_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  gui_metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  last_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  nlu_config?: InputMaybe<Jsonb_Comparison_Exp>;
  translate_input?: InputMaybe<Boolean_Comparison_Exp>;
  translate_output?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  versions?: InputMaybe<Apps_Version_Bool_Exp>;
  versions_aggregate?: InputMaybe<Apps_Version_Aggregate_Bool_Exp>;
};

/** columns and relationships of "apps.app_config" */
export type Apps_App_Config = {
  __typename?: 'apps_app_config';
  app_config_id: Scalars['Int']['output'];
  /** An array relationship */
  apps: Array<Apps_App>;
  /** An aggregate relationship */
  apps_aggregate: Apps_App_Aggregate;
  checksum?: Maybe<Scalars['uuid']['output']>;
  config?: Maybe<Scalars['jsonb']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  versions: Array<Apps_Version>;
  /** An aggregate relationship */
  versions_aggregate: Apps_Version_Aggregate;
};


/** columns and relationships of "apps.app_config" */
export type Apps_App_ConfigAppsArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Order_By>>;
  where?: InputMaybe<Apps_App_Bool_Exp>;
};


/** columns and relationships of "apps.app_config" */
export type Apps_App_ConfigApps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Order_By>>;
  where?: InputMaybe<Apps_App_Bool_Exp>;
};


/** columns and relationships of "apps.app_config" */
export type Apps_App_ConfigConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.app_config" */
export type Apps_App_ConfigVersionsArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};


/** columns and relationships of "apps.app_config" */
export type Apps_App_ConfigVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};

/** aggregated selection of "apps.app_config" */
export type Apps_App_Config_Aggregate = {
  __typename?: 'apps_app_config_aggregate';
  aggregate?: Maybe<Apps_App_Config_Aggregate_Fields>;
  nodes: Array<Apps_App_Config>;
};

/** aggregate fields of "apps.app_config" */
export type Apps_App_Config_Aggregate_Fields = {
  __typename?: 'apps_app_config_aggregate_fields';
  avg?: Maybe<Apps_App_Config_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_App_Config_Max_Fields>;
  min?: Maybe<Apps_App_Config_Min_Fields>;
  stddev?: Maybe<Apps_App_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_App_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_App_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_App_Config_Sum_Fields>;
  var_pop?: Maybe<Apps_App_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_App_Config_Var_Samp_Fields>;
  variance?: Maybe<Apps_App_Config_Variance_Fields>;
};


/** aggregate fields of "apps.app_config" */
export type Apps_App_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_App_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_App_Config_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Apps_App_Config_Avg_Fields = {
  __typename?: 'apps_app_config_avg_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.app_config". All fields are combined with a logical 'AND'. */
export type Apps_App_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_App_Config_Bool_Exp>>;
  _not?: InputMaybe<Apps_App_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_App_Config_Bool_Exp>>;
  app_config_id?: InputMaybe<Int_Comparison_Exp>;
  apps?: InputMaybe<Apps_App_Bool_Exp>;
  apps_aggregate?: InputMaybe<Apps_App_Aggregate_Bool_Exp>;
  checksum?: InputMaybe<Uuid_Comparison_Exp>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  versions?: InputMaybe<Apps_Version_Bool_Exp>;
  versions_aggregate?: InputMaybe<Apps_Version_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "apps.app_config" */
export enum Apps_App_Config_Constraint {
  /** unique or primary key constraint on columns "app_config_id" */
  PkAppConfig = 'pk_app_config'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_App_Config_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_App_Config_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_App_Config_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "apps.app_config" */
export type Apps_App_Config_Insert_Input = {
  apps?: InputMaybe<Apps_App_Arr_Rel_Insert_Input>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  versions?: InputMaybe<Apps_Version_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Apps_App_Config_Max_Fields = {
  __typename?: 'apps_app_config_max_fields';
  app_config_id?: Maybe<Scalars['Int']['output']>;
  checksum?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Apps_App_Config_Min_Fields = {
  __typename?: 'apps_app_config_min_fields';
  app_config_id?: Maybe<Scalars['Int']['output']>;
  checksum?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "apps.app_config" */
export type Apps_App_Config_Mutation_Response = {
  __typename?: 'apps_app_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_App_Config>;
};

/** input type for inserting object relation for remote table "apps.app_config" */
export type Apps_App_Config_Obj_Rel_Insert_Input = {
  data: Apps_App_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_App_Config_On_Conflict>;
};

/** on_conflict condition type for table "apps.app_config" */
export type Apps_App_Config_On_Conflict = {
  constraint: Apps_App_Config_Constraint;
  update_columns?: Array<Apps_App_Config_Update_Column>;
  where?: InputMaybe<Apps_App_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.app_config". */
export type Apps_App_Config_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  apps_aggregate?: InputMaybe<Apps_App_Aggregate_Order_By>;
  checksum?: InputMaybe<Order_By>;
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  versions_aggregate?: InputMaybe<Apps_Version_Aggregate_Order_By>;
};

/** primary key columns input for table: apps.app_config */
export type Apps_App_Config_Pk_Columns_Input = {
  app_config_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_App_Config_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.app_config" */
export enum Apps_App_Config_Select_Column {
  /** column name */
  AppConfigId = 'app_config_id',
  /** column name */
  Checksum = 'checksum',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at'
}

/** input type for updating data in table "apps.app_config" */
export type Apps_App_Config_Set_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Apps_App_Config_Stddev_Fields = {
  __typename?: 'apps_app_config_stddev_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_App_Config_Stddev_Pop_Fields = {
  __typename?: 'apps_app_config_stddev_pop_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_App_Config_Stddev_Samp_Fields = {
  __typename?: 'apps_app_config_stddev_samp_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_app_config" */
export type Apps_App_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_App_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_App_Config_Stream_Cursor_Value_Input = {
  app_config_id?: InputMaybe<Scalars['Int']['input']>;
  checksum?: InputMaybe<Scalars['uuid']['input']>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Apps_App_Config_Sum_Fields = {
  __typename?: 'apps_app_config_sum_fields';
  app_config_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "apps.app_config" */
export enum Apps_App_Config_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at'
}

export type Apps_App_Config_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_App_Config_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_App_Config_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_App_Config_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_App_Config_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_App_Config_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_App_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_App_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_App_Config_Var_Pop_Fields = {
  __typename?: 'apps_app_config_var_pop_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_App_Config_Var_Samp_Fields = {
  __typename?: 'apps_app_config_var_samp_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_App_Config_Variance_Fields = {
  __typename?: 'apps_app_config_variance_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
};

/** unique or primary key constraints on table "apps.app" */
export enum Apps_App_Constraint {
  /** unique or primary key constraint on columns "app_id" */
  PkApp = 'pk_app',
  /** unique or primary key constraint on columns "full_name" */
  UqAppFullName = 'uq_app_full_name'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_App_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']['input']>>;
  gui_metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  nlu_config?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_App_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']['input']>;
  gui_metadata?: InputMaybe<Scalars['Int']['input']>;
  nlu_config?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_App_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']['input']>;
  gui_metadata?: InputMaybe<Scalars['String']['input']>;
  nlu_config?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "apps.app_file" */
export type Apps_App_File = {
  __typename?: 'apps_app_file';
  /** An object relationship */
  app: Apps_App;
  app_id: Scalars['bigint']['output'];
  /** An object relationship */
  file: Apps_File;
  file_id: Scalars['bigint']['output'];
  filename: Scalars['String']['output'];
  mime_type?: Maybe<Scalars['String']['output']>;
  parent_path: Scalars['String']['output'];
};

/** aggregated selection of "apps.app_file" */
export type Apps_App_File_Aggregate = {
  __typename?: 'apps_app_file_aggregate';
  aggregate?: Maybe<Apps_App_File_Aggregate_Fields>;
  nodes: Array<Apps_App_File>;
};

export type Apps_App_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apps_App_File_Aggregate_Bool_Exp_Count>;
};

export type Apps_App_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_App_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_App_File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps.app_file" */
export type Apps_App_File_Aggregate_Fields = {
  __typename?: 'apps_app_file_aggregate_fields';
  avg?: Maybe<Apps_App_File_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_App_File_Max_Fields>;
  min?: Maybe<Apps_App_File_Min_Fields>;
  stddev?: Maybe<Apps_App_File_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_App_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_App_File_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_App_File_Sum_Fields>;
  var_pop?: Maybe<Apps_App_File_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_App_File_Var_Samp_Fields>;
  variance?: Maybe<Apps_App_File_Variance_Fields>;
};


/** aggregate fields of "apps.app_file" */
export type Apps_App_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_App_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps.app_file" */
export type Apps_App_File_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_App_File_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_App_File_Max_Order_By>;
  min?: InputMaybe<Apps_App_File_Min_Order_By>;
  stddev?: InputMaybe<Apps_App_File_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_App_File_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_App_File_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_App_File_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_App_File_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_App_File_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_App_File_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "apps.app_file" */
export type Apps_App_File_Arr_Rel_Insert_Input = {
  data: Array<Apps_App_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_App_File_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_App_File_Avg_Fields = {
  __typename?: 'apps_app_file_avg_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps.app_file" */
export type Apps_App_File_Avg_Order_By = {
  app_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps.app_file". All fields are combined with a logical 'AND'. */
export type Apps_App_File_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_App_File_Bool_Exp>>;
  _not?: InputMaybe<Apps_App_File_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_App_File_Bool_Exp>>;
  app?: InputMaybe<Apps_App_Bool_Exp>;
  app_id?: InputMaybe<Bigint_Comparison_Exp>;
  file?: InputMaybe<Apps_File_Bool_Exp>;
  file_id?: InputMaybe<Bigint_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  mime_type?: InputMaybe<String_Comparison_Exp>;
  parent_path?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.app_file" */
export enum Apps_App_File_Constraint {
  /** unique or primary key constraint on columns "filename", "parent_path", "app_id" */
  PkAppFile = 'pk_app_file'
}

/** input type for incrementing numeric columns in table "apps.app_file" */
export type Apps_App_File_Inc_Input = {
  app_id?: InputMaybe<Scalars['bigint']['input']>;
  file_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.app_file" */
export type Apps_App_File_Insert_Input = {
  app?: InputMaybe<Apps_App_Obj_Rel_Insert_Input>;
  app_id?: InputMaybe<Scalars['bigint']['input']>;
  file?: InputMaybe<Apps_File_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['bigint']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  mime_type?: InputMaybe<Scalars['String']['input']>;
  parent_path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Apps_App_File_Max_Fields = {
  __typename?: 'apps_app_file_max_fields';
  app_id?: Maybe<Scalars['bigint']['output']>;
  file_id?: Maybe<Scalars['bigint']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  mime_type?: Maybe<Scalars['String']['output']>;
  parent_path?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "apps.app_file" */
export type Apps_App_File_Max_Order_By = {
  app_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  mime_type?: InputMaybe<Order_By>;
  parent_path?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_App_File_Min_Fields = {
  __typename?: 'apps_app_file_min_fields';
  app_id?: Maybe<Scalars['bigint']['output']>;
  file_id?: Maybe<Scalars['bigint']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  mime_type?: Maybe<Scalars['String']['output']>;
  parent_path?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "apps.app_file" */
export type Apps_App_File_Min_Order_By = {
  app_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  mime_type?: InputMaybe<Order_By>;
  parent_path?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps.app_file" */
export type Apps_App_File_Mutation_Response = {
  __typename?: 'apps_app_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_App_File>;
};

/** on_conflict condition type for table "apps.app_file" */
export type Apps_App_File_On_Conflict = {
  constraint: Apps_App_File_Constraint;
  update_columns?: Array<Apps_App_File_Update_Column>;
  where?: InputMaybe<Apps_App_File_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.app_file". */
export type Apps_App_File_Order_By = {
  app?: InputMaybe<Apps_App_Order_By>;
  app_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Apps_File_Order_By>;
  file_id?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  mime_type?: InputMaybe<Order_By>;
  parent_path?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.app_file */
export type Apps_App_File_Pk_Columns_Input = {
  app_id: Scalars['bigint']['input'];
  filename: Scalars['String']['input'];
  parent_path: Scalars['String']['input'];
};

/** select columns of table "apps.app_file" */
export enum Apps_App_File_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Filename = 'filename',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  ParentPath = 'parent_path'
}

/** input type for updating data in table "apps.app_file" */
export type Apps_App_File_Set_Input = {
  app_id?: InputMaybe<Scalars['bigint']['input']>;
  file_id?: InputMaybe<Scalars['bigint']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  mime_type?: InputMaybe<Scalars['String']['input']>;
  parent_path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Apps_App_File_Stddev_Fields = {
  __typename?: 'apps_app_file_stddev_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps.app_file" */
export type Apps_App_File_Stddev_Order_By = {
  app_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_App_File_Stddev_Pop_Fields = {
  __typename?: 'apps_app_file_stddev_pop_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps.app_file" */
export type Apps_App_File_Stddev_Pop_Order_By = {
  app_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_App_File_Stddev_Samp_Fields = {
  __typename?: 'apps_app_file_stddev_samp_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps.app_file" */
export type Apps_App_File_Stddev_Samp_Order_By = {
  app_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_app_file" */
export type Apps_App_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_App_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_App_File_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['bigint']['input']>;
  file_id?: InputMaybe<Scalars['bigint']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  mime_type?: InputMaybe<Scalars['String']['input']>;
  parent_path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Apps_App_File_Sum_Fields = {
  __typename?: 'apps_app_file_sum_fields';
  app_id?: Maybe<Scalars['bigint']['output']>;
  file_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "apps.app_file" */
export type Apps_App_File_Sum_Order_By = {
  app_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** update columns of table "apps.app_file" */
export enum Apps_App_File_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Filename = 'filename',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  ParentPath = 'parent_path'
}

export type Apps_App_File_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_App_File_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_App_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_App_File_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_App_File_Var_Pop_Fields = {
  __typename?: 'apps_app_file_var_pop_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps.app_file" */
export type Apps_App_File_Var_Pop_Order_By = {
  app_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_App_File_Var_Samp_Fields = {
  __typename?: 'apps_app_file_var_samp_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps.app_file" */
export type Apps_App_File_Var_Samp_Order_By = {
  app_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_App_File_Variance_Fields = {
  __typename?: 'apps_app_file_variance_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps.app_file" */
export type Apps_App_File_Variance_Order_By = {
  app_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "apps.app" */
export type Apps_App_Inc_Input = {
  app_config_id?: InputMaybe<Scalars['Int']['input']>;
  customer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "apps.app" */
export type Apps_App_Insert_Input = {
  app_config?: InputMaybe<Apps_App_Config_Obj_Rel_Insert_Input>;
  app_config_id?: InputMaybe<Scalars['Int']['input']>;
  app_files?: InputMaybe<Apps_App_File_Arr_Rel_Insert_Input>;
  app_name?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer?: InputMaybe<Apps_Customer_Obj_Rel_Insert_Input>;
  customer_id?: InputMaybe<Scalars['Int']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  gui_metadata?: InputMaybe<Scalars['jsonb']['input']>;
  last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  nlu_config?: InputMaybe<Scalars['jsonb']['input']>;
  translate_input?: InputMaybe<Scalars['Boolean']['input']>;
  translate_output?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  versions?: InputMaybe<Apps_Version_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Apps_App_Max_Fields = {
  __typename?: 'apps_app_max_fields';
  app_config_id?: Maybe<Scalars['Int']['output']>;
  app_id?: Maybe<Scalars['Int']['output']>;
  app_name?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_id?: Maybe<Scalars['Int']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  last_updated?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "apps.app" */
export type Apps_App_Max_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  app_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  last_updated?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_App_Min_Fields = {
  __typename?: 'apps_app_min_fields';
  app_config_id?: Maybe<Scalars['Int']['output']>;
  app_id?: Maybe<Scalars['Int']['output']>;
  app_name?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_id?: Maybe<Scalars['Int']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  last_updated?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "apps.app" */
export type Apps_App_Min_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  app_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  last_updated?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps.app" */
export type Apps_App_Mutation_Response = {
  __typename?: 'apps_app_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_App>;
};

/** input type for inserting object relation for remote table "apps.app" */
export type Apps_App_Obj_Rel_Insert_Input = {
  data: Apps_App_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_App_On_Conflict>;
};

/** on_conflict condition type for table "apps.app" */
export type Apps_App_On_Conflict = {
  constraint: Apps_App_Constraint;
  update_columns?: Array<Apps_App_Update_Column>;
  where?: InputMaybe<Apps_App_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.app". */
export type Apps_App_Order_By = {
  app_config?: InputMaybe<Apps_App_Config_Order_By>;
  app_config_id?: InputMaybe<Order_By>;
  app_files_aggregate?: InputMaybe<Apps_App_File_Aggregate_Order_By>;
  app_id?: InputMaybe<Order_By>;
  app_name?: InputMaybe<Order_By>;
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer?: InputMaybe<Apps_Customer_Order_By>;
  customer_id?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  gui_metadata?: InputMaybe<Order_By>;
  last_updated?: InputMaybe<Order_By>;
  nlu_config?: InputMaybe<Order_By>;
  translate_input?: InputMaybe<Order_By>;
  translate_output?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  versions_aggregate?: InputMaybe<Apps_Version_Aggregate_Order_By>;
};

/** primary key columns input for table: apps.app */
export type Apps_App_Pk_Columns_Input = {
  app_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_App_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  gui_metadata?: InputMaybe<Scalars['jsonb']['input']>;
  nlu_config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.app" */
export enum Apps_App_Select_Column {
  /** column name */
  AppConfigId = 'app_config_id',
  /** column name */
  AppId = 'app_id',
  /** column name */
  AppName = 'app_name',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  FullName = 'full_name',
  /** column name */
  GuiMetadata = 'gui_metadata',
  /** column name */
  LastUpdated = 'last_updated',
  /** column name */
  NluConfig = 'nlu_config',
  /** column name */
  TranslateInput = 'translate_input',
  /** column name */
  TranslateOutput = 'translate_output',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "apps_app_aggregate_bool_exp_bool_and_arguments_columns" columns of table "apps.app" */
export enum Apps_App_Select_Column_Apps_App_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  TranslateInput = 'translate_input',
  /** column name */
  TranslateOutput = 'translate_output'
}

/** select "apps_app_aggregate_bool_exp_bool_or_arguments_columns" columns of table "apps.app" */
export enum Apps_App_Select_Column_Apps_App_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  TranslateInput = 'translate_input',
  /** column name */
  TranslateOutput = 'translate_output'
}

/** input type for updating data in table "apps.app" */
export type Apps_App_Set_Input = {
  app_config_id?: InputMaybe<Scalars['Int']['input']>;
  app_name?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_id?: InputMaybe<Scalars['Int']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  gui_metadata?: InputMaybe<Scalars['jsonb']['input']>;
  last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  nlu_config?: InputMaybe<Scalars['jsonb']['input']>;
  translate_input?: InputMaybe<Scalars['Boolean']['input']>;
  translate_output?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Apps_App_Stddev_Fields = {
  __typename?: 'apps_app_stddev_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps.app" */
export type Apps_App_Stddev_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_App_Stddev_Pop_Fields = {
  __typename?: 'apps_app_stddev_pop_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps.app" */
export type Apps_App_Stddev_Pop_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_App_Stddev_Samp_Fields = {
  __typename?: 'apps_app_stddev_samp_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps.app" */
export type Apps_App_Stddev_Samp_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_app" */
export type Apps_App_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_App_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_App_Stream_Cursor_Value_Input = {
  app_config_id?: InputMaybe<Scalars['Int']['input']>;
  app_id?: InputMaybe<Scalars['Int']['input']>;
  app_name?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_id?: InputMaybe<Scalars['Int']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  gui_metadata?: InputMaybe<Scalars['jsonb']['input']>;
  last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  nlu_config?: InputMaybe<Scalars['jsonb']['input']>;
  translate_input?: InputMaybe<Scalars['Boolean']['input']>;
  translate_output?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Apps_App_Sum_Fields = {
  __typename?: 'apps_app_sum_fields';
  app_config_id?: Maybe<Scalars['Int']['output']>;
  app_id?: Maybe<Scalars['Int']['output']>;
  customer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "apps.app" */
export type Apps_App_Sum_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** update columns of table "apps.app" */
export enum Apps_App_Update_Column {
  /** column name */
  AppConfigId = 'app_config_id',
  /** column name */
  AppName = 'app_name',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  FullName = 'full_name',
  /** column name */
  GuiMetadata = 'gui_metadata',
  /** column name */
  LastUpdated = 'last_updated',
  /** column name */
  NluConfig = 'nlu_config',
  /** column name */
  TranslateInput = 'translate_input',
  /** column name */
  TranslateOutput = 'translate_output',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Apps_App_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_App_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_App_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_App_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_App_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_App_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_App_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_App_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_App_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_App_Var_Pop_Fields = {
  __typename?: 'apps_app_var_pop_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps.app" */
export type Apps_App_Var_Pop_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_App_Var_Samp_Fields = {
  __typename?: 'apps_app_var_samp_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps.app" */
export type Apps_App_Var_Samp_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_App_Variance_Fields = {
  __typename?: 'apps_app_variance_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps.app" */
export type Apps_App_Variance_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** aggregate avg on columns */
export type Apps_Avg_Fields = {
  __typename?: 'apps_avg_fields';
  default_workflow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps". All fields are combined with a logical 'AND'. */
export type Apps_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Bool_Exp>>;
  _not?: InputMaybe<Apps_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Bool_Exp>>;
  app_id?: InputMaybe<String_Comparison_Exp>;
  available_environments?: InputMaybe<Environments_Bool_Exp>;
  available_environments_aggregate?: InputMaybe<Environments_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  default_workflow_id?: InputMaybe<Int_Comparison_Exp>;
  deployment_history?: InputMaybe<Deployments_Bool_Exp>;
  deployment_history_aggregate?: InputMaybe<Deployments_Aggregate_Bool_Exp>;
  graph?: InputMaybe<Graph_Bool_Exp>;
  graph_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  version_history?: InputMaybe<Versions_Bool_Exp>;
  version_history_aggregate?: InputMaybe<Versions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "apps" */
export enum Apps_Constraint {
  /** unique or primary key constraint on columns "app_id" */
  MicroAppAppIdKey = 'microApp_app_id_key',
  /** unique or primary key constraint on columns "id" */
  MicroAppPkey = 'microApp_pkey'
}

/** columns and relationships of "apps.convert_roundtrip" */
export type Apps_Convert_Roundtrip = {
  __typename?: 'apps_convert_roundtrip';
  error_message?: Maybe<Scalars['String']['output']>;
  input_csv: Scalars['String']['output'];
  input_csv_checksum?: Maybe<Scalars['uuid']['output']>;
  output_csv?: Maybe<Scalars['String']['output']>;
  output_csv_checksum?: Maybe<Scalars['uuid']['output']>;
  output_json?: Maybe<Scalars['jsonb']['output']>;
  output_json_checksum?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  version: Apps_Version;
  version_id: Scalars['bigint']['output'];
};


/** columns and relationships of "apps.convert_roundtrip" */
export type Apps_Convert_RoundtripOutput_JsonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "apps.convert_roundtrip" */
export type Apps_Convert_Roundtrip_Aggregate = {
  __typename?: 'apps_convert_roundtrip_aggregate';
  aggregate?: Maybe<Apps_Convert_Roundtrip_Aggregate_Fields>;
  nodes: Array<Apps_Convert_Roundtrip>;
};

/** aggregate fields of "apps.convert_roundtrip" */
export type Apps_Convert_Roundtrip_Aggregate_Fields = {
  __typename?: 'apps_convert_roundtrip_aggregate_fields';
  avg?: Maybe<Apps_Convert_Roundtrip_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Convert_Roundtrip_Max_Fields>;
  min?: Maybe<Apps_Convert_Roundtrip_Min_Fields>;
  stddev?: Maybe<Apps_Convert_Roundtrip_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Convert_Roundtrip_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Convert_Roundtrip_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Convert_Roundtrip_Sum_Fields>;
  var_pop?: Maybe<Apps_Convert_Roundtrip_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Convert_Roundtrip_Var_Samp_Fields>;
  variance?: Maybe<Apps_Convert_Roundtrip_Variance_Fields>;
};


/** aggregate fields of "apps.convert_roundtrip" */
export type Apps_Convert_Roundtrip_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Convert_Roundtrip_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_Convert_Roundtrip_Append_Input = {
  output_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Apps_Convert_Roundtrip_Avg_Fields = {
  __typename?: 'apps_convert_roundtrip_avg_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.convert_roundtrip". All fields are combined with a logical 'AND'. */
export type Apps_Convert_Roundtrip_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Convert_Roundtrip_Bool_Exp>>;
  _not?: InputMaybe<Apps_Convert_Roundtrip_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Convert_Roundtrip_Bool_Exp>>;
  error_message?: InputMaybe<String_Comparison_Exp>;
  input_csv?: InputMaybe<String_Comparison_Exp>;
  input_csv_checksum?: InputMaybe<Uuid_Comparison_Exp>;
  output_csv?: InputMaybe<String_Comparison_Exp>;
  output_csv_checksum?: InputMaybe<Uuid_Comparison_Exp>;
  output_json?: InputMaybe<Jsonb_Comparison_Exp>;
  output_json_checksum?: InputMaybe<Uuid_Comparison_Exp>;
  version?: InputMaybe<Apps_Version_Bool_Exp>;
  version_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.convert_roundtrip" */
export enum Apps_Convert_Roundtrip_Constraint {
  /** unique or primary key constraint on columns "version_id" */
  PkConvertRoundtrip = 'pk_convert_roundtrip'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_Convert_Roundtrip_Delete_At_Path_Input = {
  output_json?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_Convert_Roundtrip_Delete_Elem_Input = {
  output_json?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_Convert_Roundtrip_Delete_Key_Input = {
  output_json?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "apps.convert_roundtrip" */
export type Apps_Convert_Roundtrip_Inc_Input = {
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.convert_roundtrip" */
export type Apps_Convert_Roundtrip_Insert_Input = {
  error_message?: InputMaybe<Scalars['String']['input']>;
  input_csv?: InputMaybe<Scalars['String']['input']>;
  output_csv?: InputMaybe<Scalars['String']['input']>;
  output_json?: InputMaybe<Scalars['jsonb']['input']>;
  version?: InputMaybe<Apps_Version_Obj_Rel_Insert_Input>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Apps_Convert_Roundtrip_Max_Fields = {
  __typename?: 'apps_convert_roundtrip_max_fields';
  error_message?: Maybe<Scalars['String']['output']>;
  input_csv?: Maybe<Scalars['String']['output']>;
  input_csv_checksum?: Maybe<Scalars['uuid']['output']>;
  output_csv?: Maybe<Scalars['String']['output']>;
  output_csv_checksum?: Maybe<Scalars['uuid']['output']>;
  output_json_checksum?: Maybe<Scalars['uuid']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Apps_Convert_Roundtrip_Min_Fields = {
  __typename?: 'apps_convert_roundtrip_min_fields';
  error_message?: Maybe<Scalars['String']['output']>;
  input_csv?: Maybe<Scalars['String']['output']>;
  input_csv_checksum?: Maybe<Scalars['uuid']['output']>;
  output_csv?: Maybe<Scalars['String']['output']>;
  output_csv_checksum?: Maybe<Scalars['uuid']['output']>;
  output_json_checksum?: Maybe<Scalars['uuid']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "apps.convert_roundtrip" */
export type Apps_Convert_Roundtrip_Mutation_Response = {
  __typename?: 'apps_convert_roundtrip_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Convert_Roundtrip>;
};

/** input type for inserting object relation for remote table "apps.convert_roundtrip" */
export type Apps_Convert_Roundtrip_Obj_Rel_Insert_Input = {
  data: Apps_Convert_Roundtrip_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Convert_Roundtrip_On_Conflict>;
};

/** on_conflict condition type for table "apps.convert_roundtrip" */
export type Apps_Convert_Roundtrip_On_Conflict = {
  constraint: Apps_Convert_Roundtrip_Constraint;
  update_columns?: Array<Apps_Convert_Roundtrip_Update_Column>;
  where?: InputMaybe<Apps_Convert_Roundtrip_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.convert_roundtrip". */
export type Apps_Convert_Roundtrip_Order_By = {
  error_message?: InputMaybe<Order_By>;
  input_csv?: InputMaybe<Order_By>;
  input_csv_checksum?: InputMaybe<Order_By>;
  output_csv?: InputMaybe<Order_By>;
  output_csv_checksum?: InputMaybe<Order_By>;
  output_json?: InputMaybe<Order_By>;
  output_json_checksum?: InputMaybe<Order_By>;
  version?: InputMaybe<Apps_Version_Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.convert_roundtrip */
export type Apps_Convert_Roundtrip_Pk_Columns_Input = {
  version_id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_Convert_Roundtrip_Prepend_Input = {
  output_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.convert_roundtrip" */
export enum Apps_Convert_Roundtrip_Select_Column {
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  InputCsv = 'input_csv',
  /** column name */
  InputCsvChecksum = 'input_csv_checksum',
  /** column name */
  OutputCsv = 'output_csv',
  /** column name */
  OutputCsvChecksum = 'output_csv_checksum',
  /** column name */
  OutputJson = 'output_json',
  /** column name */
  OutputJsonChecksum = 'output_json_checksum',
  /** column name */
  VersionId = 'version_id'
}

/** input type for updating data in table "apps.convert_roundtrip" */
export type Apps_Convert_Roundtrip_Set_Input = {
  error_message?: InputMaybe<Scalars['String']['input']>;
  input_csv?: InputMaybe<Scalars['String']['input']>;
  output_csv?: InputMaybe<Scalars['String']['input']>;
  output_json?: InputMaybe<Scalars['jsonb']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Convert_Roundtrip_Stddev_Fields = {
  __typename?: 'apps_convert_roundtrip_stddev_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_Convert_Roundtrip_Stddev_Pop_Fields = {
  __typename?: 'apps_convert_roundtrip_stddev_pop_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_Convert_Roundtrip_Stddev_Samp_Fields = {
  __typename?: 'apps_convert_roundtrip_stddev_samp_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_convert_roundtrip" */
export type Apps_Convert_Roundtrip_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Convert_Roundtrip_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Convert_Roundtrip_Stream_Cursor_Value_Input = {
  error_message?: InputMaybe<Scalars['String']['input']>;
  input_csv?: InputMaybe<Scalars['String']['input']>;
  input_csv_checksum?: InputMaybe<Scalars['uuid']['input']>;
  output_csv?: InputMaybe<Scalars['String']['input']>;
  output_csv_checksum?: InputMaybe<Scalars['uuid']['input']>;
  output_json?: InputMaybe<Scalars['jsonb']['input']>;
  output_json_checksum?: InputMaybe<Scalars['uuid']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Apps_Convert_Roundtrip_Sum_Fields = {
  __typename?: 'apps_convert_roundtrip_sum_fields';
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "apps.convert_roundtrip" */
export enum Apps_Convert_Roundtrip_Update_Column {
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  InputCsv = 'input_csv',
  /** column name */
  OutputCsv = 'output_csv',
  /** column name */
  OutputJson = 'output_json',
  /** column name */
  VersionId = 'version_id'
}

export type Apps_Convert_Roundtrip_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_Convert_Roundtrip_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_Convert_Roundtrip_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_Convert_Roundtrip_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_Convert_Roundtrip_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Convert_Roundtrip_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_Convert_Roundtrip_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Convert_Roundtrip_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Convert_Roundtrip_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Convert_Roundtrip_Var_Pop_Fields = {
  __typename?: 'apps_convert_roundtrip_var_pop_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_Convert_Roundtrip_Var_Samp_Fields = {
  __typename?: 'apps_convert_roundtrip_var_samp_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_Convert_Roundtrip_Variance_Fields = {
  __typename?: 'apps_convert_roundtrip_variance_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "apps.csv_file" */
export type Apps_Csv_File = {
  __typename?: 'apps_csv_file';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  csv_checksum?: Maybe<Scalars['uuid']['output']>;
  csv_data: Scalars['String']['output'];
  csv_file_id: Scalars['Int']['output'];
  /** An array relationship */
  nluFilesByIntentCsvId: Array<Apps_Nlu_Files>;
  /** An aggregate relationship */
  nluFilesByIntentCsvId_aggregate: Apps_Nlu_Files_Aggregate;
  /** An array relationship */
  nlu_files: Array<Apps_Nlu_Files>;
  /** An aggregate relationship */
  nlu_files_aggregate: Apps_Nlu_Files_Aggregate;
};


/** columns and relationships of "apps.csv_file" */
export type Apps_Csv_FileNluFilesByIntentCsvIdArgs = {
  distinct_on?: InputMaybe<Array<Apps_Nlu_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Nlu_Files_Order_By>>;
  where?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
};


/** columns and relationships of "apps.csv_file" */
export type Apps_Csv_FileNluFilesByIntentCsvId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Nlu_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Nlu_Files_Order_By>>;
  where?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
};


/** columns and relationships of "apps.csv_file" */
export type Apps_Csv_FileNlu_FilesArgs = {
  distinct_on?: InputMaybe<Array<Apps_Nlu_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Nlu_Files_Order_By>>;
  where?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
};


/** columns and relationships of "apps.csv_file" */
export type Apps_Csv_FileNlu_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Nlu_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Nlu_Files_Order_By>>;
  where?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
};

/** aggregated selection of "apps.csv_file" */
export type Apps_Csv_File_Aggregate = {
  __typename?: 'apps_csv_file_aggregate';
  aggregate?: Maybe<Apps_Csv_File_Aggregate_Fields>;
  nodes: Array<Apps_Csv_File>;
};

/** aggregate fields of "apps.csv_file" */
export type Apps_Csv_File_Aggregate_Fields = {
  __typename?: 'apps_csv_file_aggregate_fields';
  avg?: Maybe<Apps_Csv_File_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Csv_File_Max_Fields>;
  min?: Maybe<Apps_Csv_File_Min_Fields>;
  stddev?: Maybe<Apps_Csv_File_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Csv_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Csv_File_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Csv_File_Sum_Fields>;
  var_pop?: Maybe<Apps_Csv_File_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Csv_File_Var_Samp_Fields>;
  variance?: Maybe<Apps_Csv_File_Variance_Fields>;
};


/** aggregate fields of "apps.csv_file" */
export type Apps_Csv_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Csv_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Apps_Csv_File_Avg_Fields = {
  __typename?: 'apps_csv_file_avg_fields';
  csv_file_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.csv_file". All fields are combined with a logical 'AND'. */
export type Apps_Csv_File_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Csv_File_Bool_Exp>>;
  _not?: InputMaybe<Apps_Csv_File_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Csv_File_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  csv_checksum?: InputMaybe<Uuid_Comparison_Exp>;
  csv_data?: InputMaybe<String_Comparison_Exp>;
  csv_file_id?: InputMaybe<Int_Comparison_Exp>;
  nluFilesByIntentCsvId?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
  nluFilesByIntentCsvId_aggregate?: InputMaybe<Apps_Nlu_Files_Aggregate_Bool_Exp>;
  nlu_files?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
  nlu_files_aggregate?: InputMaybe<Apps_Nlu_Files_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "apps.csv_file" */
export enum Apps_Csv_File_Constraint {
  /** unique or primary key constraint on columns "csv_file_id" */
  PkCsvFile = 'pk_csv_file'
}

/** input type for inserting data into table "apps.csv_file" */
export type Apps_Csv_File_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  csv_data?: InputMaybe<Scalars['String']['input']>;
  nluFilesByIntentCsvId?: InputMaybe<Apps_Nlu_Files_Arr_Rel_Insert_Input>;
  nlu_files?: InputMaybe<Apps_Nlu_Files_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Apps_Csv_File_Max_Fields = {
  __typename?: 'apps_csv_file_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  csv_checksum?: Maybe<Scalars['uuid']['output']>;
  csv_data?: Maybe<Scalars['String']['output']>;
  csv_file_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Apps_Csv_File_Min_Fields = {
  __typename?: 'apps_csv_file_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  csv_checksum?: Maybe<Scalars['uuid']['output']>;
  csv_data?: Maybe<Scalars['String']['output']>;
  csv_file_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "apps.csv_file" */
export type Apps_Csv_File_Mutation_Response = {
  __typename?: 'apps_csv_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Csv_File>;
};

/** input type for inserting object relation for remote table "apps.csv_file" */
export type Apps_Csv_File_Obj_Rel_Insert_Input = {
  data: Apps_Csv_File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Csv_File_On_Conflict>;
};

/** on_conflict condition type for table "apps.csv_file" */
export type Apps_Csv_File_On_Conflict = {
  constraint: Apps_Csv_File_Constraint;
  update_columns?: Array<Apps_Csv_File_Update_Column>;
  where?: InputMaybe<Apps_Csv_File_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.csv_file". */
export type Apps_Csv_File_Order_By = {
  created_at?: InputMaybe<Order_By>;
  csv_checksum?: InputMaybe<Order_By>;
  csv_data?: InputMaybe<Order_By>;
  csv_file_id?: InputMaybe<Order_By>;
  nluFilesByIntentCsvId_aggregate?: InputMaybe<Apps_Nlu_Files_Aggregate_Order_By>;
  nlu_files_aggregate?: InputMaybe<Apps_Nlu_Files_Aggregate_Order_By>;
};

/** primary key columns input for table: apps.csv_file */
export type Apps_Csv_File_Pk_Columns_Input = {
  csv_file_id: Scalars['Int']['input'];
};

/** select columns of table "apps.csv_file" */
export enum Apps_Csv_File_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CsvChecksum = 'csv_checksum',
  /** column name */
  CsvData = 'csv_data',
  /** column name */
  CsvFileId = 'csv_file_id'
}

/** input type for updating data in table "apps.csv_file" */
export type Apps_Csv_File_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  csv_data?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Csv_File_Stddev_Fields = {
  __typename?: 'apps_csv_file_stddev_fields';
  csv_file_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_Csv_File_Stddev_Pop_Fields = {
  __typename?: 'apps_csv_file_stddev_pop_fields';
  csv_file_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_Csv_File_Stddev_Samp_Fields = {
  __typename?: 'apps_csv_file_stddev_samp_fields';
  csv_file_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_csv_file" */
export type Apps_Csv_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Csv_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Csv_File_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  csv_checksum?: InputMaybe<Scalars['uuid']['input']>;
  csv_data?: InputMaybe<Scalars['String']['input']>;
  csv_file_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Apps_Csv_File_Sum_Fields = {
  __typename?: 'apps_csv_file_sum_fields';
  csv_file_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "apps.csv_file" */
export enum Apps_Csv_File_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CsvData = 'csv_data'
}

export type Apps_Csv_File_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Csv_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Csv_File_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Csv_File_Var_Pop_Fields = {
  __typename?: 'apps_csv_file_var_pop_fields';
  csv_file_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_Csv_File_Var_Samp_Fields = {
  __typename?: 'apps_csv_file_var_samp_fields';
  csv_file_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_Csv_File_Variance_Fields = {
  __typename?: 'apps_csv_file_variance_fields';
  csv_file_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "apps.customer" */
export type Apps_Customer = {
  __typename?: 'apps_customer';
  /** An array relationship */
  apps: Array<Apps_App>;
  /** An aggregate relationship */
  apps_aggregate: Apps_App_Aggregate;
  config?: Maybe<Scalars['jsonb']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  customer_config?: Maybe<Apps_Customer_Config>;
  customer_config_id?: Maybe<Scalars['Int']['output']>;
  customer_id: Scalars['Int']['output'];
  customer_name: Scalars['String']['output'];
  customer_uuid?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  environments: Array<Apps_Environment>;
  /** An aggregate relationship */
  environments_aggregate: Apps_Environment_Aggregate;
};


/** columns and relationships of "apps.customer" */
export type Apps_CustomerAppsArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Order_By>>;
  where?: InputMaybe<Apps_App_Bool_Exp>;
};


/** columns and relationships of "apps.customer" */
export type Apps_CustomerApps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Order_By>>;
  where?: InputMaybe<Apps_App_Bool_Exp>;
};


/** columns and relationships of "apps.customer" */
export type Apps_CustomerConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.customer" */
export type Apps_CustomerEnvironmentsArgs = {
  distinct_on?: InputMaybe<Array<Apps_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Environment_Order_By>>;
  where?: InputMaybe<Apps_Environment_Bool_Exp>;
};


/** columns and relationships of "apps.customer" */
export type Apps_CustomerEnvironments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Environment_Order_By>>;
  where?: InputMaybe<Apps_Environment_Bool_Exp>;
};

/** aggregated selection of "apps.customer" */
export type Apps_Customer_Aggregate = {
  __typename?: 'apps_customer_aggregate';
  aggregate?: Maybe<Apps_Customer_Aggregate_Fields>;
  nodes: Array<Apps_Customer>;
};

export type Apps_Customer_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apps_Customer_Aggregate_Bool_Exp_Count>;
};

export type Apps_Customer_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_Customer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Customer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps.customer" */
export type Apps_Customer_Aggregate_Fields = {
  __typename?: 'apps_customer_aggregate_fields';
  avg?: Maybe<Apps_Customer_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Customer_Max_Fields>;
  min?: Maybe<Apps_Customer_Min_Fields>;
  stddev?: Maybe<Apps_Customer_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Customer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Customer_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Customer_Sum_Fields>;
  var_pop?: Maybe<Apps_Customer_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Customer_Var_Samp_Fields>;
  variance?: Maybe<Apps_Customer_Variance_Fields>;
};


/** aggregate fields of "apps.customer" */
export type Apps_Customer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Customer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps.customer" */
export type Apps_Customer_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_Customer_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_Customer_Max_Order_By>;
  min?: InputMaybe<Apps_Customer_Min_Order_By>;
  stddev?: InputMaybe<Apps_Customer_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_Customer_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_Customer_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_Customer_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_Customer_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_Customer_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_Customer_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_Customer_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "apps.customer" */
export type Apps_Customer_Arr_Rel_Insert_Input = {
  data: Array<Apps_Customer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Customer_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_Customer_Avg_Fields = {
  __typename?: 'apps_customer_avg_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps.customer" */
export type Apps_Customer_Avg_Order_By = {
  customer_config_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps.customer". All fields are combined with a logical 'AND'. */
export type Apps_Customer_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Customer_Bool_Exp>>;
  _not?: InputMaybe<Apps_Customer_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Customer_Bool_Exp>>;
  apps?: InputMaybe<Apps_App_Bool_Exp>;
  apps_aggregate?: InputMaybe<Apps_App_Aggregate_Bool_Exp>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_config?: InputMaybe<Apps_Customer_Config_Bool_Exp>;
  customer_config_id?: InputMaybe<Int_Comparison_Exp>;
  customer_id?: InputMaybe<Int_Comparison_Exp>;
  customer_name?: InputMaybe<String_Comparison_Exp>;
  customer_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environments?: InputMaybe<Apps_Environment_Bool_Exp>;
  environments_aggregate?: InputMaybe<Apps_Environment_Aggregate_Bool_Exp>;
};

/** columns and relationships of "apps.customer_config" */
export type Apps_Customer_Config = {
  __typename?: 'apps_customer_config';
  checksum?: Maybe<Scalars['uuid']['output']>;
  config?: Maybe<Scalars['jsonb']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_config_id: Scalars['Int']['output'];
  /** An array relationship */
  customers: Array<Apps_Customer>;
  /** An aggregate relationship */
  customers_aggregate: Apps_Customer_Aggregate;
  /** An array relationship */
  versions: Array<Apps_Version>;
  /** An aggregate relationship */
  versions_aggregate: Apps_Version_Aggregate;
};


/** columns and relationships of "apps.customer_config" */
export type Apps_Customer_ConfigConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.customer_config" */
export type Apps_Customer_ConfigCustomersArgs = {
  distinct_on?: InputMaybe<Array<Apps_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Customer_Order_By>>;
  where?: InputMaybe<Apps_Customer_Bool_Exp>;
};


/** columns and relationships of "apps.customer_config" */
export type Apps_Customer_ConfigCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Customer_Order_By>>;
  where?: InputMaybe<Apps_Customer_Bool_Exp>;
};


/** columns and relationships of "apps.customer_config" */
export type Apps_Customer_ConfigVersionsArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};


/** columns and relationships of "apps.customer_config" */
export type Apps_Customer_ConfigVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};

/** aggregated selection of "apps.customer_config" */
export type Apps_Customer_Config_Aggregate = {
  __typename?: 'apps_customer_config_aggregate';
  aggregate?: Maybe<Apps_Customer_Config_Aggregate_Fields>;
  nodes: Array<Apps_Customer_Config>;
};

/** aggregate fields of "apps.customer_config" */
export type Apps_Customer_Config_Aggregate_Fields = {
  __typename?: 'apps_customer_config_aggregate_fields';
  avg?: Maybe<Apps_Customer_Config_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Customer_Config_Max_Fields>;
  min?: Maybe<Apps_Customer_Config_Min_Fields>;
  stddev?: Maybe<Apps_Customer_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Customer_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Customer_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Customer_Config_Sum_Fields>;
  var_pop?: Maybe<Apps_Customer_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Customer_Config_Var_Samp_Fields>;
  variance?: Maybe<Apps_Customer_Config_Variance_Fields>;
};


/** aggregate fields of "apps.customer_config" */
export type Apps_Customer_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Customer_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_Customer_Config_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Apps_Customer_Config_Avg_Fields = {
  __typename?: 'apps_customer_config_avg_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.customer_config". All fields are combined with a logical 'AND'. */
export type Apps_Customer_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Customer_Config_Bool_Exp>>;
  _not?: InputMaybe<Apps_Customer_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Customer_Config_Bool_Exp>>;
  checksum?: InputMaybe<Uuid_Comparison_Exp>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_config_id?: InputMaybe<Int_Comparison_Exp>;
  customers?: InputMaybe<Apps_Customer_Bool_Exp>;
  customers_aggregate?: InputMaybe<Apps_Customer_Aggregate_Bool_Exp>;
  versions?: InputMaybe<Apps_Version_Bool_Exp>;
  versions_aggregate?: InputMaybe<Apps_Version_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "apps.customer_config" */
export enum Apps_Customer_Config_Constraint {
  /** unique or primary key constraint on columns "customer_config_id" */
  PkCustomerConfig = 'pk_customer_config'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_Customer_Config_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_Customer_Config_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_Customer_Config_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "apps.customer_config" */
export type Apps_Customer_Config_Insert_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customers?: InputMaybe<Apps_Customer_Arr_Rel_Insert_Input>;
  versions?: InputMaybe<Apps_Version_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Apps_Customer_Config_Max_Fields = {
  __typename?: 'apps_customer_config_max_fields';
  checksum?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_config_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Apps_Customer_Config_Min_Fields = {
  __typename?: 'apps_customer_config_min_fields';
  checksum?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_config_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "apps.customer_config" */
export type Apps_Customer_Config_Mutation_Response = {
  __typename?: 'apps_customer_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Customer_Config>;
};

/** input type for inserting object relation for remote table "apps.customer_config" */
export type Apps_Customer_Config_Obj_Rel_Insert_Input = {
  data: Apps_Customer_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Customer_Config_On_Conflict>;
};

/** on_conflict condition type for table "apps.customer_config" */
export type Apps_Customer_Config_On_Conflict = {
  constraint: Apps_Customer_Config_Constraint;
  update_columns?: Array<Apps_Customer_Config_Update_Column>;
  where?: InputMaybe<Apps_Customer_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.customer_config". */
export type Apps_Customer_Config_Order_By = {
  checksum?: InputMaybe<Order_By>;
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  customers_aggregate?: InputMaybe<Apps_Customer_Aggregate_Order_By>;
  versions_aggregate?: InputMaybe<Apps_Version_Aggregate_Order_By>;
};

/** primary key columns input for table: apps.customer_config */
export type Apps_Customer_Config_Pk_Columns_Input = {
  customer_config_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_Customer_Config_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.customer_config" */
export enum Apps_Customer_Config_Select_Column {
  /** column name */
  Checksum = 'checksum',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerConfigId = 'customer_config_id'
}

/** input type for updating data in table "apps.customer_config" */
export type Apps_Customer_Config_Set_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Customer_Config_Stddev_Fields = {
  __typename?: 'apps_customer_config_stddev_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_Customer_Config_Stddev_Pop_Fields = {
  __typename?: 'apps_customer_config_stddev_pop_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_Customer_Config_Stddev_Samp_Fields = {
  __typename?: 'apps_customer_config_stddev_samp_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_customer_config" */
export type Apps_Customer_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Customer_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Customer_Config_Stream_Cursor_Value_Input = {
  checksum?: InputMaybe<Scalars['uuid']['input']>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_config_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Apps_Customer_Config_Sum_Fields = {
  __typename?: 'apps_customer_config_sum_fields';
  customer_config_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "apps.customer_config" */
export enum Apps_Customer_Config_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at'
}

export type Apps_Customer_Config_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_Customer_Config_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_Customer_Config_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_Customer_Config_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_Customer_Config_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_Customer_Config_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Customer_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Customer_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Customer_Config_Var_Pop_Fields = {
  __typename?: 'apps_customer_config_var_pop_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_Customer_Config_Var_Samp_Fields = {
  __typename?: 'apps_customer_config_var_samp_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_Customer_Config_Variance_Fields = {
  __typename?: 'apps_customer_config_variance_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
};

/** unique or primary key constraints on table "apps.customer" */
export enum Apps_Customer_Constraint {
  /** unique or primary key constraint on columns "customer_id" */
  PkCustomer = 'pk_customer',
  /** unique or primary key constraint on columns "customer_name" */
  UqCustomerChecksum = 'uq_customer_checksum'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_Customer_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_Customer_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_Customer_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "apps.customer" */
export type Apps_Customer_Inc_Input = {
  customer_config_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "apps.customer" */
export type Apps_Customer_Insert_Input = {
  apps?: InputMaybe<Apps_App_Arr_Rel_Insert_Input>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_config?: InputMaybe<Apps_Customer_Config_Obj_Rel_Insert_Input>;
  customer_config_id?: InputMaybe<Scalars['Int']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  customer_uuid?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  environments?: InputMaybe<Apps_Environment_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Apps_Customer_Max_Fields = {
  __typename?: 'apps_customer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_config_id?: Maybe<Scalars['Int']['output']>;
  customer_id?: Maybe<Scalars['Int']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  customer_uuid?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "apps.customer" */
export type Apps_Customer_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  customer_name?: InputMaybe<Order_By>;
  customer_uuid?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_Customer_Min_Fields = {
  __typename?: 'apps_customer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_config_id?: Maybe<Scalars['Int']['output']>;
  customer_id?: Maybe<Scalars['Int']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  customer_uuid?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "apps.customer" */
export type Apps_Customer_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  customer_name?: InputMaybe<Order_By>;
  customer_uuid?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps.customer" */
export type Apps_Customer_Mutation_Response = {
  __typename?: 'apps_customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Customer>;
};

/** input type for inserting object relation for remote table "apps.customer" */
export type Apps_Customer_Obj_Rel_Insert_Input = {
  data: Apps_Customer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Customer_On_Conflict>;
};

/** on_conflict condition type for table "apps.customer" */
export type Apps_Customer_On_Conflict = {
  constraint: Apps_Customer_Constraint;
  update_columns?: Array<Apps_Customer_Update_Column>;
  where?: InputMaybe<Apps_Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.customer". */
export type Apps_Customer_Order_By = {
  apps_aggregate?: InputMaybe<Apps_App_Aggregate_Order_By>;
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_config?: InputMaybe<Apps_Customer_Config_Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  customer_name?: InputMaybe<Order_By>;
  customer_uuid?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  environments_aggregate?: InputMaybe<Apps_Environment_Aggregate_Order_By>;
};

/** primary key columns input for table: apps.customer */
export type Apps_Customer_Pk_Columns_Input = {
  customer_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_Customer_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.customer" */
export enum Apps_Customer_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerConfigId = 'customer_config_id',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  CustomerUuid = 'customer_uuid',
  /** column name */
  DeletedAt = 'deleted_at'
}

/** input type for updating data in table "apps.customer" */
export type Apps_Customer_Set_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_config_id?: InputMaybe<Scalars['Int']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  customer_uuid?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Customer_Stddev_Fields = {
  __typename?: 'apps_customer_stddev_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps.customer" */
export type Apps_Customer_Stddev_Order_By = {
  customer_config_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_Customer_Stddev_Pop_Fields = {
  __typename?: 'apps_customer_stddev_pop_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps.customer" */
export type Apps_Customer_Stddev_Pop_Order_By = {
  customer_config_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_Customer_Stddev_Samp_Fields = {
  __typename?: 'apps_customer_stddev_samp_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps.customer" */
export type Apps_Customer_Stddev_Samp_Order_By = {
  customer_config_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_customer" */
export type Apps_Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Customer_Stream_Cursor_Value_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_config_id?: InputMaybe<Scalars['Int']['input']>;
  customer_id?: InputMaybe<Scalars['Int']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  customer_uuid?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Apps_Customer_Sum_Fields = {
  __typename?: 'apps_customer_sum_fields';
  customer_config_id?: Maybe<Scalars['Int']['output']>;
  customer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "apps.customer" */
export type Apps_Customer_Sum_Order_By = {
  customer_config_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** update columns of table "apps.customer" */
export enum Apps_Customer_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerConfigId = 'customer_config_id',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  CustomerUuid = 'customer_uuid',
  /** column name */
  DeletedAt = 'deleted_at'
}

export type Apps_Customer_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_Customer_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_Customer_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_Customer_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_Customer_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Customer_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_Customer_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Customer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Customer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Customer_Var_Pop_Fields = {
  __typename?: 'apps_customer_var_pop_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps.customer" */
export type Apps_Customer_Var_Pop_Order_By = {
  customer_config_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_Customer_Var_Samp_Fields = {
  __typename?: 'apps_customer_var_samp_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps.customer" */
export type Apps_Customer_Var_Samp_Order_By = {
  customer_config_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_Customer_Variance_Fields = {
  __typename?: 'apps_customer_variance_fields';
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  customer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps.customer" */
export type Apps_Customer_Variance_Order_By = {
  customer_config_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "apps.deployment" */
export type Apps_Deployment = {
  __typename?: 'apps_deployment';
  created_at: Scalars['timestamptz']['output'];
  deployed_at?: Maybe<Scalars['timestamptz']['output']>;
  deployment_id: Scalars['Int']['output'];
  /** An object relationship */
  environment?: Maybe<Apps_Environment>;
  environment_id?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  version?: Maybe<Apps_Version>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "apps.deployment" */
export type Apps_Deployment_Aggregate = {
  __typename?: 'apps_deployment_aggregate';
  aggregate?: Maybe<Apps_Deployment_Aggregate_Fields>;
  nodes: Array<Apps_Deployment>;
};

export type Apps_Deployment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apps_Deployment_Aggregate_Bool_Exp_Count>;
};

export type Apps_Deployment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_Deployment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Deployment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps.deployment" */
export type Apps_Deployment_Aggregate_Fields = {
  __typename?: 'apps_deployment_aggregate_fields';
  avg?: Maybe<Apps_Deployment_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Deployment_Max_Fields>;
  min?: Maybe<Apps_Deployment_Min_Fields>;
  stddev?: Maybe<Apps_Deployment_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Deployment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Deployment_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Deployment_Sum_Fields>;
  var_pop?: Maybe<Apps_Deployment_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Deployment_Var_Samp_Fields>;
  variance?: Maybe<Apps_Deployment_Variance_Fields>;
};


/** aggregate fields of "apps.deployment" */
export type Apps_Deployment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Deployment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps.deployment" */
export type Apps_Deployment_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_Deployment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_Deployment_Max_Order_By>;
  min?: InputMaybe<Apps_Deployment_Min_Order_By>;
  stddev?: InputMaybe<Apps_Deployment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_Deployment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_Deployment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_Deployment_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_Deployment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_Deployment_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_Deployment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "apps.deployment" */
export type Apps_Deployment_Arr_Rel_Insert_Input = {
  data: Array<Apps_Deployment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Deployment_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_Deployment_Avg_Fields = {
  __typename?: 'apps_deployment_avg_fields';
  deployment_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps.deployment" */
export type Apps_Deployment_Avg_Order_By = {
  deployment_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps.deployment". All fields are combined with a logical 'AND'. */
export type Apps_Deployment_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Deployment_Bool_Exp>>;
  _not?: InputMaybe<Apps_Deployment_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Deployment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deployed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deployment_id?: InputMaybe<Int_Comparison_Exp>;
  environment?: InputMaybe<Apps_Environment_Bool_Exp>;
  environment_id?: InputMaybe<Int_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Apps_Version_Bool_Exp>;
  version_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.deployment" */
export enum Apps_Deployment_Constraint {
  /** unique or primary key constraint on columns "deployment_id" */
  PkDeployment = 'pk_deployment'
}

/** input type for incrementing numeric columns in table "apps.deployment" */
export type Apps_Deployment_Inc_Input = {
  environment_id?: InputMaybe<Scalars['Int']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "apps.deployment" */
export type Apps_Deployment_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deployed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  environment?: InputMaybe<Apps_Environment_Obj_Rel_Insert_Input>;
  environment_id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version?: InputMaybe<Apps_Version_Obj_Rel_Insert_Input>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Apps_Deployment_Max_Fields = {
  __typename?: 'apps_deployment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deployed_at?: Maybe<Scalars['timestamptz']['output']>;
  deployment_id?: Maybe<Scalars['Int']['output']>;
  environment_id?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "apps.deployment" */
export type Apps_Deployment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deployed_at?: InputMaybe<Order_By>;
  deployment_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_Deployment_Min_Fields = {
  __typename?: 'apps_deployment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deployed_at?: Maybe<Scalars['timestamptz']['output']>;
  deployment_id?: Maybe<Scalars['Int']['output']>;
  environment_id?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "apps.deployment" */
export type Apps_Deployment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deployed_at?: InputMaybe<Order_By>;
  deployment_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps.deployment" */
export type Apps_Deployment_Mutation_Response = {
  __typename?: 'apps_deployment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Deployment>;
};

/** on_conflict condition type for table "apps.deployment" */
export type Apps_Deployment_On_Conflict = {
  constraint: Apps_Deployment_Constraint;
  update_columns?: Array<Apps_Deployment_Update_Column>;
  where?: InputMaybe<Apps_Deployment_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.deployment". */
export type Apps_Deployment_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deployed_at?: InputMaybe<Order_By>;
  deployment_id?: InputMaybe<Order_By>;
  environment?: InputMaybe<Apps_Environment_Order_By>;
  environment_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Apps_Version_Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.deployment */
export type Apps_Deployment_Pk_Columns_Input = {
  deployment_id: Scalars['Int']['input'];
};

/** select columns of table "apps.deployment" */
export enum Apps_Deployment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeployedAt = 'deployed_at',
  /** column name */
  DeploymentId = 'deployment_id',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionId = 'version_id'
}

/** input type for updating data in table "apps.deployment" */
export type Apps_Deployment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deployed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  environment_id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Deployment_Stddev_Fields = {
  __typename?: 'apps_deployment_stddev_fields';
  deployment_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps.deployment" */
export type Apps_Deployment_Stddev_Order_By = {
  deployment_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_Deployment_Stddev_Pop_Fields = {
  __typename?: 'apps_deployment_stddev_pop_fields';
  deployment_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps.deployment" */
export type Apps_Deployment_Stddev_Pop_Order_By = {
  deployment_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_Deployment_Stddev_Samp_Fields = {
  __typename?: 'apps_deployment_stddev_samp_fields';
  deployment_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps.deployment" */
export type Apps_Deployment_Stddev_Samp_Order_By = {
  deployment_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_deployment" */
export type Apps_Deployment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Deployment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Deployment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deployed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deployment_id?: InputMaybe<Scalars['Int']['input']>;
  environment_id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Apps_Deployment_Sum_Fields = {
  __typename?: 'apps_deployment_sum_fields';
  deployment_id?: Maybe<Scalars['Int']['output']>;
  environment_id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "apps.deployment" */
export type Apps_Deployment_Sum_Order_By = {
  deployment_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** update columns of table "apps.deployment" */
export enum Apps_Deployment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeployedAt = 'deployed_at',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionId = 'version_id'
}

export type Apps_Deployment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Deployment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Deployment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Deployment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Deployment_Var_Pop_Fields = {
  __typename?: 'apps_deployment_var_pop_fields';
  deployment_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps.deployment" */
export type Apps_Deployment_Var_Pop_Order_By = {
  deployment_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_Deployment_Var_Samp_Fields = {
  __typename?: 'apps_deployment_var_samp_fields';
  deployment_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps.deployment" */
export type Apps_Deployment_Var_Samp_Order_By = {
  deployment_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_Deployment_Variance_Fields = {
  __typename?: 'apps_deployment_variance_fields';
  deployment_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps.deployment" */
export type Apps_Deployment_Variance_Order_By = {
  deployment_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "apps.environment" */
export type Apps_Environment = {
  __typename?: 'apps_environment';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  customer: Apps_Customer;
  customer_id: Scalars['Int']['output'];
  /** An array relationship */
  deployments: Array<Apps_Deployment>;
  /** An aggregate relationship */
  deployments_aggregate: Apps_Deployment_Aggregate;
  environment_id: Scalars['Int']['output'];
  environment_name: Scalars['String']['output'];
  is_production: Scalars['Boolean']['output'];
  latest_version_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  version?: Maybe<Apps_Version>;
};


/** columns and relationships of "apps.environment" */
export type Apps_EnvironmentDeploymentsArgs = {
  distinct_on?: InputMaybe<Array<Apps_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Deployment_Order_By>>;
  where?: InputMaybe<Apps_Deployment_Bool_Exp>;
};


/** columns and relationships of "apps.environment" */
export type Apps_EnvironmentDeployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Deployment_Order_By>>;
  where?: InputMaybe<Apps_Deployment_Bool_Exp>;
};

/** aggregated selection of "apps.environment" */
export type Apps_Environment_Aggregate = {
  __typename?: 'apps_environment_aggregate';
  aggregate?: Maybe<Apps_Environment_Aggregate_Fields>;
  nodes: Array<Apps_Environment>;
};

export type Apps_Environment_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Apps_Environment_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Apps_Environment_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Apps_Environment_Aggregate_Bool_Exp_Count>;
};

export type Apps_Environment_Aggregate_Bool_Exp_Bool_And = {
  arguments: Apps_Environment_Select_Column_Apps_Environment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Environment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Apps_Environment_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Apps_Environment_Select_Column_Apps_Environment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Environment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Apps_Environment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_Environment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Environment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps.environment" */
export type Apps_Environment_Aggregate_Fields = {
  __typename?: 'apps_environment_aggregate_fields';
  avg?: Maybe<Apps_Environment_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Environment_Max_Fields>;
  min?: Maybe<Apps_Environment_Min_Fields>;
  stddev?: Maybe<Apps_Environment_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Environment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Environment_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Environment_Sum_Fields>;
  var_pop?: Maybe<Apps_Environment_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Environment_Var_Samp_Fields>;
  variance?: Maybe<Apps_Environment_Variance_Fields>;
};


/** aggregate fields of "apps.environment" */
export type Apps_Environment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Environment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps.environment" */
export type Apps_Environment_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_Environment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_Environment_Max_Order_By>;
  min?: InputMaybe<Apps_Environment_Min_Order_By>;
  stddev?: InputMaybe<Apps_Environment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_Environment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_Environment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_Environment_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_Environment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_Environment_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_Environment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "apps.environment" */
export type Apps_Environment_Arr_Rel_Insert_Input = {
  data: Array<Apps_Environment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Environment_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_Environment_Avg_Fields = {
  __typename?: 'apps_environment_avg_fields';
  customer_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  latest_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps.environment" */
export type Apps_Environment_Avg_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  latest_version_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps.environment". All fields are combined with a logical 'AND'. */
export type Apps_Environment_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Environment_Bool_Exp>>;
  _not?: InputMaybe<Apps_Environment_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Environment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Apps_Customer_Bool_Exp>;
  customer_id?: InputMaybe<Int_Comparison_Exp>;
  deployments?: InputMaybe<Apps_Deployment_Bool_Exp>;
  deployments_aggregate?: InputMaybe<Apps_Deployment_Aggregate_Bool_Exp>;
  environment_id?: InputMaybe<Int_Comparison_Exp>;
  environment_name?: InputMaybe<String_Comparison_Exp>;
  is_production?: InputMaybe<Boolean_Comparison_Exp>;
  latest_version_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Apps_Version_Bool_Exp>;
};

/** unique or primary key constraints on table "apps.environment" */
export enum Apps_Environment_Constraint {
  /** unique or primary key constraint on columns "environment_id" */
  PkEnvironment = 'pk_environment',
  /** unique or primary key constraint on columns "environment_name", "customer_id" */
  UqEnvironmentCustomerIdEnvironmentName = 'uq_environment_customer_id_environment_name',
  /** unique or primary key constraint on columns "is_production", "customer_id" */
  UqEnvironmentCustomerIdIsProduction = 'uq_environment_customer_id_is_production'
}

/** input type for incrementing numeric columns in table "apps.environment" */
export type Apps_Environment_Inc_Input = {
  customer_id?: InputMaybe<Scalars['Int']['input']>;
  latest_version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.environment" */
export type Apps_Environment_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer?: InputMaybe<Apps_Customer_Obj_Rel_Insert_Input>;
  customer_id?: InputMaybe<Scalars['Int']['input']>;
  deployments?: InputMaybe<Apps_Deployment_Arr_Rel_Insert_Input>;
  environment_name?: InputMaybe<Scalars['String']['input']>;
  is_production?: InputMaybe<Scalars['Boolean']['input']>;
  latest_version_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version?: InputMaybe<Apps_Version_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Apps_Environment_Max_Fields = {
  __typename?: 'apps_environment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_id?: Maybe<Scalars['Int']['output']>;
  environment_id?: Maybe<Scalars['Int']['output']>;
  environment_name?: Maybe<Scalars['String']['output']>;
  latest_version_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "apps.environment" */
export type Apps_Environment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  environment_name?: InputMaybe<Order_By>;
  latest_version_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_Environment_Min_Fields = {
  __typename?: 'apps_environment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_id?: Maybe<Scalars['Int']['output']>;
  environment_id?: Maybe<Scalars['Int']['output']>;
  environment_name?: Maybe<Scalars['String']['output']>;
  latest_version_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "apps.environment" */
export type Apps_Environment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  environment_name?: InputMaybe<Order_By>;
  latest_version_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps.environment" */
export type Apps_Environment_Mutation_Response = {
  __typename?: 'apps_environment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Environment>;
};

/** input type for inserting object relation for remote table "apps.environment" */
export type Apps_Environment_Obj_Rel_Insert_Input = {
  data: Apps_Environment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Environment_On_Conflict>;
};

/** on_conflict condition type for table "apps.environment" */
export type Apps_Environment_On_Conflict = {
  constraint: Apps_Environment_Constraint;
  update_columns?: Array<Apps_Environment_Update_Column>;
  where?: InputMaybe<Apps_Environment_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.environment". */
export type Apps_Environment_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer?: InputMaybe<Apps_Customer_Order_By>;
  customer_id?: InputMaybe<Order_By>;
  deployments_aggregate?: InputMaybe<Apps_Deployment_Aggregate_Order_By>;
  environment_id?: InputMaybe<Order_By>;
  environment_name?: InputMaybe<Order_By>;
  is_production?: InputMaybe<Order_By>;
  latest_version_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Apps_Version_Order_By>;
};

/** primary key columns input for table: apps.environment */
export type Apps_Environment_Pk_Columns_Input = {
  environment_id: Scalars['Int']['input'];
};

/** select columns of table "apps.environment" */
export enum Apps_Environment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  EnvironmentName = 'environment_name',
  /** column name */
  IsProduction = 'is_production',
  /** column name */
  LatestVersionId = 'latest_version_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "apps_environment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "apps.environment" */
export enum Apps_Environment_Select_Column_Apps_Environment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsProduction = 'is_production'
}

/** select "apps_environment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "apps.environment" */
export enum Apps_Environment_Select_Column_Apps_Environment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsProduction = 'is_production'
}

/** input type for updating data in table "apps.environment" */
export type Apps_Environment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_id?: InputMaybe<Scalars['Int']['input']>;
  environment_name?: InputMaybe<Scalars['String']['input']>;
  is_production?: InputMaybe<Scalars['Boolean']['input']>;
  latest_version_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Environment_Stddev_Fields = {
  __typename?: 'apps_environment_stddev_fields';
  customer_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  latest_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps.environment" */
export type Apps_Environment_Stddev_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  latest_version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_Environment_Stddev_Pop_Fields = {
  __typename?: 'apps_environment_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  latest_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps.environment" */
export type Apps_Environment_Stddev_Pop_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  latest_version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_Environment_Stddev_Samp_Fields = {
  __typename?: 'apps_environment_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  latest_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps.environment" */
export type Apps_Environment_Stddev_Samp_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  latest_version_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_environment" */
export type Apps_Environment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Environment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Environment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_id?: InputMaybe<Scalars['Int']['input']>;
  environment_id?: InputMaybe<Scalars['Int']['input']>;
  environment_name?: InputMaybe<Scalars['String']['input']>;
  is_production?: InputMaybe<Scalars['Boolean']['input']>;
  latest_version_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Apps_Environment_Sum_Fields = {
  __typename?: 'apps_environment_sum_fields';
  customer_id?: Maybe<Scalars['Int']['output']>;
  environment_id?: Maybe<Scalars['Int']['output']>;
  latest_version_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "apps.environment" */
export type Apps_Environment_Sum_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  latest_version_id?: InputMaybe<Order_By>;
};

/** update columns of table "apps.environment" */
export enum Apps_Environment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EnvironmentName = 'environment_name',
  /** column name */
  IsProduction = 'is_production',
  /** column name */
  LatestVersionId = 'latest_version_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Apps_Environment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Environment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Environment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Environment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Environment_Var_Pop_Fields = {
  __typename?: 'apps_environment_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  latest_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps.environment" */
export type Apps_Environment_Var_Pop_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  latest_version_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_Environment_Var_Samp_Fields = {
  __typename?: 'apps_environment_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  latest_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps.environment" */
export type Apps_Environment_Var_Samp_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  latest_version_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_Environment_Variance_Fields = {
  __typename?: 'apps_environment_variance_fields';
  customer_id?: Maybe<Scalars['Float']['output']>;
  environment_id?: Maybe<Scalars['Float']['output']>;
  latest_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps.environment" */
export type Apps_Environment_Variance_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  latest_version_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "apps.file" */
export type Apps_File = {
  __typename?: 'apps_file';
  /** An array relationship */
  app_files: Array<Apps_App_File>;
  /** An aggregate relationship */
  app_files_aggregate: Apps_App_File_Aggregate;
  byte_count: Scalars['bigint']['output'];
  created_at: Scalars['timestamptz']['output'];
  file_id: Scalars['bigint']['output'];
  file_md5: Scalars['uuid']['output'];
  mime_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  version_files: Array<Apps_Version_File>;
  /** An aggregate relationship */
  version_files_aggregate: Apps_Version_File_Aggregate;
};


/** columns and relationships of "apps.file" */
export type Apps_FileApp_FilesArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_File_Order_By>>;
  where?: InputMaybe<Apps_App_File_Bool_Exp>;
};


/** columns and relationships of "apps.file" */
export type Apps_FileApp_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_File_Order_By>>;
  where?: InputMaybe<Apps_App_File_Bool_Exp>;
};


/** columns and relationships of "apps.file" */
export type Apps_FileVersion_FilesArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_File_Order_By>>;
  where?: InputMaybe<Apps_Version_File_Bool_Exp>;
};


/** columns and relationships of "apps.file" */
export type Apps_FileVersion_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_File_Order_By>>;
  where?: InputMaybe<Apps_Version_File_Bool_Exp>;
};

/** aggregated selection of "apps.file" */
export type Apps_File_Aggregate = {
  __typename?: 'apps_file_aggregate';
  aggregate?: Maybe<Apps_File_Aggregate_Fields>;
  nodes: Array<Apps_File>;
};

/** aggregate fields of "apps.file" */
export type Apps_File_Aggregate_Fields = {
  __typename?: 'apps_file_aggregate_fields';
  avg?: Maybe<Apps_File_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_File_Max_Fields>;
  min?: Maybe<Apps_File_Min_Fields>;
  stddev?: Maybe<Apps_File_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_File_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_File_Sum_Fields>;
  var_pop?: Maybe<Apps_File_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_File_Var_Samp_Fields>;
  variance?: Maybe<Apps_File_Variance_Fields>;
};


/** aggregate fields of "apps.file" */
export type Apps_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Apps_File_Avg_Fields = {
  __typename?: 'apps_file_avg_fields';
  byte_count?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.file". All fields are combined with a logical 'AND'. */
export type Apps_File_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_File_Bool_Exp>>;
  _not?: InputMaybe<Apps_File_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_File_Bool_Exp>>;
  app_files?: InputMaybe<Apps_App_File_Bool_Exp>;
  app_files_aggregate?: InputMaybe<Apps_App_File_Aggregate_Bool_Exp>;
  byte_count?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Bigint_Comparison_Exp>;
  file_md5?: InputMaybe<Uuid_Comparison_Exp>;
  mime_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version_files?: InputMaybe<Apps_Version_File_Bool_Exp>;
  version_files_aggregate?: InputMaybe<Apps_Version_File_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "apps.file" */
export enum Apps_File_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  PkFile = 'pk_file',
  /** unique or primary key constraint on columns "file_md5" */
  UqFileFileMd5 = 'uq_file_file_md5'
}

/** input type for incrementing numeric columns in table "apps.file" */
export type Apps_File_Inc_Input = {
  byte_count?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.file" */
export type Apps_File_Insert_Input = {
  app_files?: InputMaybe<Apps_App_File_Arr_Rel_Insert_Input>;
  byte_count?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_md5?: InputMaybe<Scalars['uuid']['input']>;
  mime_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_files?: InputMaybe<Apps_Version_File_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Apps_File_Max_Fields = {
  __typename?: 'apps_file_max_fields';
  byte_count?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['bigint']['output']>;
  file_md5?: Maybe<Scalars['uuid']['output']>;
  mime_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Apps_File_Min_Fields = {
  __typename?: 'apps_file_min_fields';
  byte_count?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['bigint']['output']>;
  file_md5?: Maybe<Scalars['uuid']['output']>;
  mime_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "apps.file" */
export type Apps_File_Mutation_Response = {
  __typename?: 'apps_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_File>;
};

/** input type for inserting object relation for remote table "apps.file" */
export type Apps_File_Obj_Rel_Insert_Input = {
  data: Apps_File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_File_On_Conflict>;
};

/** on_conflict condition type for table "apps.file" */
export type Apps_File_On_Conflict = {
  constraint: Apps_File_Constraint;
  update_columns?: Array<Apps_File_Update_Column>;
  where?: InputMaybe<Apps_File_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.file". */
export type Apps_File_Order_By = {
  app_files_aggregate?: InputMaybe<Apps_App_File_Aggregate_Order_By>;
  byte_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  file_md5?: InputMaybe<Order_By>;
  mime_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version_files_aggregate?: InputMaybe<Apps_Version_File_Aggregate_Order_By>;
};

/** primary key columns input for table: apps.file */
export type Apps_File_Pk_Columns_Input = {
  file_id: Scalars['bigint']['input'];
};

/** select columns of table "apps.file" */
export enum Apps_File_Select_Column {
  /** column name */
  ByteCount = 'byte_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FileMd5 = 'file_md5',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "apps.file" */
export type Apps_File_Set_Input = {
  byte_count?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_md5?: InputMaybe<Scalars['uuid']['input']>;
  mime_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Apps_File_Stddev_Fields = {
  __typename?: 'apps_file_stddev_fields';
  byte_count?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_File_Stddev_Pop_Fields = {
  __typename?: 'apps_file_stddev_pop_fields';
  byte_count?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_File_Stddev_Samp_Fields = {
  __typename?: 'apps_file_stddev_samp_fields';
  byte_count?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_file" */
export type Apps_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_File_Stream_Cursor_Value_Input = {
  byte_count?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['bigint']['input']>;
  file_md5?: InputMaybe<Scalars['uuid']['input']>;
  mime_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Apps_File_Sum_Fields = {
  __typename?: 'apps_file_sum_fields';
  byte_count?: Maybe<Scalars['bigint']['output']>;
  file_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "apps.file" */
export enum Apps_File_Update_Column {
  /** column name */
  ByteCount = 'byte_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileMd5 = 'file_md5',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Apps_File_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_File_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_File_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_File_Var_Pop_Fields = {
  __typename?: 'apps_file_var_pop_fields';
  byte_count?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_File_Var_Samp_Fields = {
  __typename?: 'apps_file_var_samp_fields';
  byte_count?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_File_Variance_Fields = {
  __typename?: 'apps_file_variance_fields';
  byte_count?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "apps.import_fail" */
export type Apps_Import_Fail = {
  __typename?: 'apps_import_fail';
  bot_name?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  customer_name?: Maybe<Scalars['String']['output']>;
  error_message: Scalars['String']['output'];
  import_fail_id: Scalars['Int']['output'];
  /** An array relationship */
  import_fail_nodes: Array<Apps_Import_Fail_Node>;
  /** An aggregate relationship */
  import_fail_nodes_aggregate: Apps_Import_Fail_Node_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  version_name?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "apps.import_fail" */
export type Apps_Import_FailImport_Fail_NodesArgs = {
  distinct_on?: InputMaybe<Array<Apps_Import_Fail_Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Import_Fail_Node_Order_By>>;
  where?: InputMaybe<Apps_Import_Fail_Node_Bool_Exp>;
};


/** columns and relationships of "apps.import_fail" */
export type Apps_Import_FailImport_Fail_Nodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Import_Fail_Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Import_Fail_Node_Order_By>>;
  where?: InputMaybe<Apps_Import_Fail_Node_Bool_Exp>;
};

/** aggregated selection of "apps.import_fail" */
export type Apps_Import_Fail_Aggregate = {
  __typename?: 'apps_import_fail_aggregate';
  aggregate?: Maybe<Apps_Import_Fail_Aggregate_Fields>;
  nodes: Array<Apps_Import_Fail>;
};

/** aggregate fields of "apps.import_fail" */
export type Apps_Import_Fail_Aggregate_Fields = {
  __typename?: 'apps_import_fail_aggregate_fields';
  avg?: Maybe<Apps_Import_Fail_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Import_Fail_Max_Fields>;
  min?: Maybe<Apps_Import_Fail_Min_Fields>;
  stddev?: Maybe<Apps_Import_Fail_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Import_Fail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Import_Fail_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Import_Fail_Sum_Fields>;
  var_pop?: Maybe<Apps_Import_Fail_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Import_Fail_Var_Samp_Fields>;
  variance?: Maybe<Apps_Import_Fail_Variance_Fields>;
};


/** aggregate fields of "apps.import_fail" */
export type Apps_Import_Fail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Import_Fail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Apps_Import_Fail_Avg_Fields = {
  __typename?: 'apps_import_fail_avg_fields';
  import_fail_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.import_fail". All fields are combined with a logical 'AND'. */
export type Apps_Import_Fail_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Import_Fail_Bool_Exp>>;
  _not?: InputMaybe<Apps_Import_Fail_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Import_Fail_Bool_Exp>>;
  bot_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_name?: InputMaybe<String_Comparison_Exp>;
  error_message?: InputMaybe<String_Comparison_Exp>;
  import_fail_id?: InputMaybe<Int_Comparison_Exp>;
  import_fail_nodes?: InputMaybe<Apps_Import_Fail_Node_Bool_Exp>;
  import_fail_nodes_aggregate?: InputMaybe<Apps_Import_Fail_Node_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.import_fail" */
export enum Apps_Import_Fail_Constraint {
  /** unique or primary key constraint on columns "bot_name", "customer_name", "version_name" */
  ImportFailCustomerNameBotNameVersionNameKey = 'import_fail_customer_name_bot_name_version_name_key',
  /** unique or primary key constraint on columns "import_fail_id" */
  PkImportFail = 'pk_import_fail'
}

/** input type for inserting data into table "apps.import_fail" */
export type Apps_Import_Fail_Insert_Input = {
  bot_name?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  error_message?: InputMaybe<Scalars['String']['input']>;
  import_fail_nodes?: InputMaybe<Apps_Import_Fail_Node_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Apps_Import_Fail_Max_Fields = {
  __typename?: 'apps_import_fail_max_fields';
  bot_name?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  error_message?: Maybe<Scalars['String']['output']>;
  import_fail_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  version_name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Apps_Import_Fail_Min_Fields = {
  __typename?: 'apps_import_fail_min_fields';
  bot_name?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  error_message?: Maybe<Scalars['String']['output']>;
  import_fail_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  version_name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "apps.import_fail" */
export type Apps_Import_Fail_Mutation_Response = {
  __typename?: 'apps_import_fail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Import_Fail>;
};

/** columns and relationships of "apps.import_fail_node" */
export type Apps_Import_Fail_Node = {
  __typename?: 'apps_import_fail_node';
  array_position: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  import_fail: Apps_Import_Fail;
  import_fail_id: Scalars['Int']['output'];
  import_fail_node_id: Scalars['bigint']['output'];
  node_json: Scalars['jsonb']['output'];
};


/** columns and relationships of "apps.import_fail_node" */
export type Apps_Import_Fail_NodeNode_JsonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Aggregate = {
  __typename?: 'apps_import_fail_node_aggregate';
  aggregate?: Maybe<Apps_Import_Fail_Node_Aggregate_Fields>;
  nodes: Array<Apps_Import_Fail_Node>;
};

export type Apps_Import_Fail_Node_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apps_Import_Fail_Node_Aggregate_Bool_Exp_Count>;
};

export type Apps_Import_Fail_Node_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_Import_Fail_Node_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Import_Fail_Node_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Aggregate_Fields = {
  __typename?: 'apps_import_fail_node_aggregate_fields';
  avg?: Maybe<Apps_Import_Fail_Node_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Import_Fail_Node_Max_Fields>;
  min?: Maybe<Apps_Import_Fail_Node_Min_Fields>;
  stddev?: Maybe<Apps_Import_Fail_Node_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Import_Fail_Node_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Import_Fail_Node_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Import_Fail_Node_Sum_Fields>;
  var_pop?: Maybe<Apps_Import_Fail_Node_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Import_Fail_Node_Var_Samp_Fields>;
  variance?: Maybe<Apps_Import_Fail_Node_Variance_Fields>;
};


/** aggregate fields of "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Import_Fail_Node_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_Import_Fail_Node_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_Import_Fail_Node_Max_Order_By>;
  min?: InputMaybe<Apps_Import_Fail_Node_Min_Order_By>;
  stddev?: InputMaybe<Apps_Import_Fail_Node_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_Import_Fail_Node_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_Import_Fail_Node_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_Import_Fail_Node_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_Import_Fail_Node_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_Import_Fail_Node_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_Import_Fail_Node_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_Import_Fail_Node_Append_Input = {
  node_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Arr_Rel_Insert_Input = {
  data: Array<Apps_Import_Fail_Node_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Import_Fail_Node_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_Import_Fail_Node_Avg_Fields = {
  __typename?: 'apps_import_fail_node_avg_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  import_fail_id?: Maybe<Scalars['Float']['output']>;
  import_fail_node_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Avg_Order_By = {
  array_position?: InputMaybe<Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_node_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps.import_fail_node". All fields are combined with a logical 'AND'. */
export type Apps_Import_Fail_Node_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Import_Fail_Node_Bool_Exp>>;
  _not?: InputMaybe<Apps_Import_Fail_Node_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Import_Fail_Node_Bool_Exp>>;
  array_position?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  import_fail?: InputMaybe<Apps_Import_Fail_Bool_Exp>;
  import_fail_id?: InputMaybe<Int_Comparison_Exp>;
  import_fail_node_id?: InputMaybe<Bigint_Comparison_Exp>;
  node_json?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.import_fail_node" */
export enum Apps_Import_Fail_Node_Constraint {
  /** unique or primary key constraint on columns "import_fail_node_id" */
  PkImportFailNode = 'pk_import_fail_node',
  /** unique or primary key constraint on columns "import_fail_id", "array_position" */
  UqImportFailNodeImportFailId = 'uq_import_fail_node_import_fail_id'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_Import_Fail_Node_Delete_At_Path_Input = {
  node_json?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_Import_Fail_Node_Delete_Elem_Input = {
  node_json?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_Import_Fail_Node_Delete_Key_Input = {
  node_json?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Inc_Input = {
  array_position?: InputMaybe<Scalars['Int']['input']>;
  import_fail_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Insert_Input = {
  array_position?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  import_fail?: InputMaybe<Apps_Import_Fail_Obj_Rel_Insert_Input>;
  import_fail_id?: InputMaybe<Scalars['Int']['input']>;
  node_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Apps_Import_Fail_Node_Max_Fields = {
  __typename?: 'apps_import_fail_node_max_fields';
  array_position?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  import_fail_id?: Maybe<Scalars['Int']['output']>;
  import_fail_node_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Max_Order_By = {
  array_position?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_node_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_Import_Fail_Node_Min_Fields = {
  __typename?: 'apps_import_fail_node_min_fields';
  array_position?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  import_fail_id?: Maybe<Scalars['Int']['output']>;
  import_fail_node_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Min_Order_By = {
  array_position?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_node_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Mutation_Response = {
  __typename?: 'apps_import_fail_node_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Import_Fail_Node>;
};

/** on_conflict condition type for table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_On_Conflict = {
  constraint: Apps_Import_Fail_Node_Constraint;
  update_columns?: Array<Apps_Import_Fail_Node_Update_Column>;
  where?: InputMaybe<Apps_Import_Fail_Node_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.import_fail_node". */
export type Apps_Import_Fail_Node_Order_By = {
  array_position?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  import_fail?: InputMaybe<Apps_Import_Fail_Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_node_id?: InputMaybe<Order_By>;
  node_json?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.import_fail_node */
export type Apps_Import_Fail_Node_Pk_Columns_Input = {
  import_fail_node_id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_Import_Fail_Node_Prepend_Input = {
  node_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.import_fail_node" */
export enum Apps_Import_Fail_Node_Select_Column {
  /** column name */
  ArrayPosition = 'array_position',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ImportFailId = 'import_fail_id',
  /** column name */
  ImportFailNodeId = 'import_fail_node_id',
  /** column name */
  NodeJson = 'node_json'
}

/** input type for updating data in table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Set_Input = {
  array_position?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  import_fail_id?: InputMaybe<Scalars['Int']['input']>;
  node_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Import_Fail_Node_Stddev_Fields = {
  __typename?: 'apps_import_fail_node_stddev_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  import_fail_id?: Maybe<Scalars['Float']['output']>;
  import_fail_node_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Stddev_Order_By = {
  array_position?: InputMaybe<Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_node_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_Import_Fail_Node_Stddev_Pop_Fields = {
  __typename?: 'apps_import_fail_node_stddev_pop_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  import_fail_id?: Maybe<Scalars['Float']['output']>;
  import_fail_node_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Stddev_Pop_Order_By = {
  array_position?: InputMaybe<Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_node_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_Import_Fail_Node_Stddev_Samp_Fields = {
  __typename?: 'apps_import_fail_node_stddev_samp_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  import_fail_id?: Maybe<Scalars['Float']['output']>;
  import_fail_node_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Stddev_Samp_Order_By = {
  array_position?: InputMaybe<Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_node_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_import_fail_node" */
export type Apps_Import_Fail_Node_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Import_Fail_Node_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Import_Fail_Node_Stream_Cursor_Value_Input = {
  array_position?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  import_fail_id?: InputMaybe<Scalars['Int']['input']>;
  import_fail_node_id?: InputMaybe<Scalars['bigint']['input']>;
  node_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type Apps_Import_Fail_Node_Sum_Fields = {
  __typename?: 'apps_import_fail_node_sum_fields';
  array_position?: Maybe<Scalars['Int']['output']>;
  import_fail_id?: Maybe<Scalars['Int']['output']>;
  import_fail_node_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Sum_Order_By = {
  array_position?: InputMaybe<Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_node_id?: InputMaybe<Order_By>;
};

/** update columns of table "apps.import_fail_node" */
export enum Apps_Import_Fail_Node_Update_Column {
  /** column name */
  ArrayPosition = 'array_position',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ImportFailId = 'import_fail_id',
  /** column name */
  NodeJson = 'node_json'
}

export type Apps_Import_Fail_Node_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_Import_Fail_Node_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_Import_Fail_Node_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_Import_Fail_Node_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_Import_Fail_Node_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Import_Fail_Node_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_Import_Fail_Node_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Import_Fail_Node_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Import_Fail_Node_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Import_Fail_Node_Var_Pop_Fields = {
  __typename?: 'apps_import_fail_node_var_pop_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  import_fail_id?: Maybe<Scalars['Float']['output']>;
  import_fail_node_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Var_Pop_Order_By = {
  array_position?: InputMaybe<Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_node_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_Import_Fail_Node_Var_Samp_Fields = {
  __typename?: 'apps_import_fail_node_var_samp_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  import_fail_id?: Maybe<Scalars['Float']['output']>;
  import_fail_node_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Var_Samp_Order_By = {
  array_position?: InputMaybe<Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_node_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_Import_Fail_Node_Variance_Fields = {
  __typename?: 'apps_import_fail_node_variance_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  import_fail_id?: Maybe<Scalars['Float']['output']>;
  import_fail_node_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps.import_fail_node" */
export type Apps_Import_Fail_Node_Variance_Order_By = {
  array_position?: InputMaybe<Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_node_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "apps.import_fail" */
export type Apps_Import_Fail_Obj_Rel_Insert_Input = {
  data: Apps_Import_Fail_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Import_Fail_On_Conflict>;
};

/** on_conflict condition type for table "apps.import_fail" */
export type Apps_Import_Fail_On_Conflict = {
  constraint: Apps_Import_Fail_Constraint;
  update_columns?: Array<Apps_Import_Fail_Update_Column>;
  where?: InputMaybe<Apps_Import_Fail_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.import_fail". */
export type Apps_Import_Fail_Order_By = {
  bot_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_name?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  import_fail_id?: InputMaybe<Order_By>;
  import_fail_nodes_aggregate?: InputMaybe<Apps_Import_Fail_Node_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.import_fail */
export type Apps_Import_Fail_Pk_Columns_Input = {
  import_fail_id: Scalars['Int']['input'];
};

/** select columns of table "apps.import_fail" */
export enum Apps_Import_Fail_Select_Column {
  /** column name */
  BotName = 'bot_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  ImportFailId = 'import_fail_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionName = 'version_name'
}

/** input type for updating data in table "apps.import_fail" */
export type Apps_Import_Fail_Set_Input = {
  bot_name?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  error_message?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Import_Fail_Stddev_Fields = {
  __typename?: 'apps_import_fail_stddev_fields';
  import_fail_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_Import_Fail_Stddev_Pop_Fields = {
  __typename?: 'apps_import_fail_stddev_pop_fields';
  import_fail_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_Import_Fail_Stddev_Samp_Fields = {
  __typename?: 'apps_import_fail_stddev_samp_fields';
  import_fail_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_import_fail" */
export type Apps_Import_Fail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Import_Fail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Import_Fail_Stream_Cursor_Value_Input = {
  bot_name?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  error_message?: InputMaybe<Scalars['String']['input']>;
  import_fail_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Apps_Import_Fail_Sum_Fields = {
  __typename?: 'apps_import_fail_sum_fields';
  import_fail_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "apps.import_fail" */
export enum Apps_Import_Fail_Update_Column {
  /** column name */
  BotName = 'bot_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionName = 'version_name'
}

export type Apps_Import_Fail_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Import_Fail_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Import_Fail_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Import_Fail_Var_Pop_Fields = {
  __typename?: 'apps_import_fail_var_pop_fields';
  import_fail_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_Import_Fail_Var_Samp_Fields = {
  __typename?: 'apps_import_fail_var_samp_fields';
  import_fail_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_Import_Fail_Variance_Fields = {
  __typename?: 'apps_import_fail_variance_fields';
  import_fail_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "apps" */
export type Apps_Inc_Input = {
  default_workflow_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "apps" */
export type Apps_Insert_Input = {
  app_id?: InputMaybe<Scalars['String']['input']>;
  available_environments?: InputMaybe<Environments_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  default_workflow_id?: InputMaybe<Scalars['Int']['input']>;
  deployment_history?: InputMaybe<Deployments_Arr_Rel_Insert_Input>;
  graph?: InputMaybe<Graph_Obj_Rel_Insert_Input>;
  graph_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  version_history?: InputMaybe<Versions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Apps_Max_Fields = {
  __typename?: 'apps_max_fields';
  app_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  default_workflow_id?: Maybe<Scalars['Int']['output']>;
  graph_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Apps_Min_Fields = {
  __typename?: 'apps_min_fields';
  app_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  default_workflow_id?: Maybe<Scalars['Int']['output']>;
  graph_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "apps" */
export type Apps_Mutation_Response = {
  __typename?: 'apps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps>;
};

/** columns and relationships of "apps.nlu_files" */
export type Apps_Nlu_Files = {
  __typename?: 'apps_nlu_files';
  /** An object relationship */
  csvFileByIntentCsvId?: Maybe<Apps_Csv_File>;
  /** An object relationship */
  csv_file?: Maybe<Apps_Csv_File>;
  entity_csv_id?: Maybe<Scalars['Int']['output']>;
  intent_csv_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  version: Apps_Version;
  version_id: Scalars['bigint']['output'];
};

/** aggregated selection of "apps.nlu_files" */
export type Apps_Nlu_Files_Aggregate = {
  __typename?: 'apps_nlu_files_aggregate';
  aggregate?: Maybe<Apps_Nlu_Files_Aggregate_Fields>;
  nodes: Array<Apps_Nlu_Files>;
};

export type Apps_Nlu_Files_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apps_Nlu_Files_Aggregate_Bool_Exp_Count>;
};

export type Apps_Nlu_Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_Nlu_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps.nlu_files" */
export type Apps_Nlu_Files_Aggregate_Fields = {
  __typename?: 'apps_nlu_files_aggregate_fields';
  avg?: Maybe<Apps_Nlu_Files_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Nlu_Files_Max_Fields>;
  min?: Maybe<Apps_Nlu_Files_Min_Fields>;
  stddev?: Maybe<Apps_Nlu_Files_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Nlu_Files_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Nlu_Files_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Nlu_Files_Sum_Fields>;
  var_pop?: Maybe<Apps_Nlu_Files_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Nlu_Files_Var_Samp_Fields>;
  variance?: Maybe<Apps_Nlu_Files_Variance_Fields>;
};


/** aggregate fields of "apps.nlu_files" */
export type Apps_Nlu_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Nlu_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps.nlu_files" */
export type Apps_Nlu_Files_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_Nlu_Files_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_Nlu_Files_Max_Order_By>;
  min?: InputMaybe<Apps_Nlu_Files_Min_Order_By>;
  stddev?: InputMaybe<Apps_Nlu_Files_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_Nlu_Files_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_Nlu_Files_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_Nlu_Files_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_Nlu_Files_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_Nlu_Files_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_Nlu_Files_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "apps.nlu_files" */
export type Apps_Nlu_Files_Arr_Rel_Insert_Input = {
  data: Array<Apps_Nlu_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Nlu_Files_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_Nlu_Files_Avg_Fields = {
  __typename?: 'apps_nlu_files_avg_fields';
  entity_csv_id?: Maybe<Scalars['Float']['output']>;
  intent_csv_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps.nlu_files" */
export type Apps_Nlu_Files_Avg_Order_By = {
  entity_csv_id?: InputMaybe<Order_By>;
  intent_csv_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps.nlu_files". All fields are combined with a logical 'AND'. */
export type Apps_Nlu_Files_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Nlu_Files_Bool_Exp>>;
  _not?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Nlu_Files_Bool_Exp>>;
  csvFileByIntentCsvId?: InputMaybe<Apps_Csv_File_Bool_Exp>;
  csv_file?: InputMaybe<Apps_Csv_File_Bool_Exp>;
  entity_csv_id?: InputMaybe<Int_Comparison_Exp>;
  intent_csv_id?: InputMaybe<Int_Comparison_Exp>;
  version?: InputMaybe<Apps_Version_Bool_Exp>;
  version_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.nlu_files" */
export enum Apps_Nlu_Files_Constraint {
  /** unique or primary key constraint on columns "version_id" */
  PkNluFiles = 'pk_nlu_files'
}

/** input type for incrementing numeric columns in table "apps.nlu_files" */
export type Apps_Nlu_Files_Inc_Input = {
  entity_csv_id?: InputMaybe<Scalars['Int']['input']>;
  intent_csv_id?: InputMaybe<Scalars['Int']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.nlu_files" */
export type Apps_Nlu_Files_Insert_Input = {
  csvFileByIntentCsvId?: InputMaybe<Apps_Csv_File_Obj_Rel_Insert_Input>;
  csv_file?: InputMaybe<Apps_Csv_File_Obj_Rel_Insert_Input>;
  entity_csv_id?: InputMaybe<Scalars['Int']['input']>;
  intent_csv_id?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Apps_Version_Obj_Rel_Insert_Input>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Apps_Nlu_Files_Max_Fields = {
  __typename?: 'apps_nlu_files_max_fields';
  entity_csv_id?: Maybe<Scalars['Int']['output']>;
  intent_csv_id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "apps.nlu_files" */
export type Apps_Nlu_Files_Max_Order_By = {
  entity_csv_id?: InputMaybe<Order_By>;
  intent_csv_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_Nlu_Files_Min_Fields = {
  __typename?: 'apps_nlu_files_min_fields';
  entity_csv_id?: Maybe<Scalars['Int']['output']>;
  intent_csv_id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "apps.nlu_files" */
export type Apps_Nlu_Files_Min_Order_By = {
  entity_csv_id?: InputMaybe<Order_By>;
  intent_csv_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps.nlu_files" */
export type Apps_Nlu_Files_Mutation_Response = {
  __typename?: 'apps_nlu_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Nlu_Files>;
};

/** input type for inserting object relation for remote table "apps.nlu_files" */
export type Apps_Nlu_Files_Obj_Rel_Insert_Input = {
  data: Apps_Nlu_Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Nlu_Files_On_Conflict>;
};

/** on_conflict condition type for table "apps.nlu_files" */
export type Apps_Nlu_Files_On_Conflict = {
  constraint: Apps_Nlu_Files_Constraint;
  update_columns?: Array<Apps_Nlu_Files_Update_Column>;
  where?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.nlu_files". */
export type Apps_Nlu_Files_Order_By = {
  csvFileByIntentCsvId?: InputMaybe<Apps_Csv_File_Order_By>;
  csv_file?: InputMaybe<Apps_Csv_File_Order_By>;
  entity_csv_id?: InputMaybe<Order_By>;
  intent_csv_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Apps_Version_Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.nlu_files */
export type Apps_Nlu_Files_Pk_Columns_Input = {
  version_id: Scalars['bigint']['input'];
};

/** select columns of table "apps.nlu_files" */
export enum Apps_Nlu_Files_Select_Column {
  /** column name */
  EntityCsvId = 'entity_csv_id',
  /** column name */
  IntentCsvId = 'intent_csv_id',
  /** column name */
  VersionId = 'version_id'
}

/** input type for updating data in table "apps.nlu_files" */
export type Apps_Nlu_Files_Set_Input = {
  entity_csv_id?: InputMaybe<Scalars['Int']['input']>;
  intent_csv_id?: InputMaybe<Scalars['Int']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Nlu_Files_Stddev_Fields = {
  __typename?: 'apps_nlu_files_stddev_fields';
  entity_csv_id?: Maybe<Scalars['Float']['output']>;
  intent_csv_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps.nlu_files" */
export type Apps_Nlu_Files_Stddev_Order_By = {
  entity_csv_id?: InputMaybe<Order_By>;
  intent_csv_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_Nlu_Files_Stddev_Pop_Fields = {
  __typename?: 'apps_nlu_files_stddev_pop_fields';
  entity_csv_id?: Maybe<Scalars['Float']['output']>;
  intent_csv_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps.nlu_files" */
export type Apps_Nlu_Files_Stddev_Pop_Order_By = {
  entity_csv_id?: InputMaybe<Order_By>;
  intent_csv_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_Nlu_Files_Stddev_Samp_Fields = {
  __typename?: 'apps_nlu_files_stddev_samp_fields';
  entity_csv_id?: Maybe<Scalars['Float']['output']>;
  intent_csv_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps.nlu_files" */
export type Apps_Nlu_Files_Stddev_Samp_Order_By = {
  entity_csv_id?: InputMaybe<Order_By>;
  intent_csv_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_nlu_files" */
export type Apps_Nlu_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Nlu_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Nlu_Files_Stream_Cursor_Value_Input = {
  entity_csv_id?: InputMaybe<Scalars['Int']['input']>;
  intent_csv_id?: InputMaybe<Scalars['Int']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Apps_Nlu_Files_Sum_Fields = {
  __typename?: 'apps_nlu_files_sum_fields';
  entity_csv_id?: Maybe<Scalars['Int']['output']>;
  intent_csv_id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "apps.nlu_files" */
export type Apps_Nlu_Files_Sum_Order_By = {
  entity_csv_id?: InputMaybe<Order_By>;
  intent_csv_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** update columns of table "apps.nlu_files" */
export enum Apps_Nlu_Files_Update_Column {
  /** column name */
  EntityCsvId = 'entity_csv_id',
  /** column name */
  IntentCsvId = 'intent_csv_id',
  /** column name */
  VersionId = 'version_id'
}

export type Apps_Nlu_Files_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Nlu_Files_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Nlu_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Nlu_Files_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Nlu_Files_Var_Pop_Fields = {
  __typename?: 'apps_nlu_files_var_pop_fields';
  entity_csv_id?: Maybe<Scalars['Float']['output']>;
  intent_csv_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps.nlu_files" */
export type Apps_Nlu_Files_Var_Pop_Order_By = {
  entity_csv_id?: InputMaybe<Order_By>;
  intent_csv_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_Nlu_Files_Var_Samp_Fields = {
  __typename?: 'apps_nlu_files_var_samp_fields';
  entity_csv_id?: Maybe<Scalars['Float']['output']>;
  intent_csv_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps.nlu_files" */
export type Apps_Nlu_Files_Var_Samp_Order_By = {
  entity_csv_id?: InputMaybe<Order_By>;
  intent_csv_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_Nlu_Files_Variance_Fields = {
  __typename?: 'apps_nlu_files_variance_fields';
  entity_csv_id?: Maybe<Scalars['Float']['output']>;
  intent_csv_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps.nlu_files" */
export type Apps_Nlu_Files_Variance_Order_By = {
  entity_csv_id?: InputMaybe<Order_By>;
  intent_csv_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "apps.node" */
export type Apps_Node = {
  __typename?: 'apps_node';
  checksum?: Maybe<Scalars['bigint']['output']>;
  elements?: Maybe<Scalars['jsonb']['output']>;
  messages?: Maybe<Scalars['jsonb']['output']>;
  nlu_routing?: Maybe<Scalars['jsonb']['output']>;
  node_content_other?: Maybe<Scalars['jsonb']['output']>;
  node_data_other?: Maybe<Scalars['jsonb']['output']>;
  node_id: Scalars['bigint']['output'];
  /** An array relationship */
  node_instances: Array<Apps_Node_Instance>;
  /** An aggregate relationship */
  node_instances_aggregate: Apps_Node_Instance_Aggregate;
  node_name?: Maybe<Scalars['String']['output']>;
  node_type: Scalars['String']['output'];
};


/** columns and relationships of "apps.node" */
export type Apps_NodeElementsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node" */
export type Apps_NodeMessagesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node" */
export type Apps_NodeNlu_RoutingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node" */
export type Apps_NodeNode_Content_OtherArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node" */
export type Apps_NodeNode_Data_OtherArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node" */
export type Apps_NodeNode_InstancesArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
};


/** columns and relationships of "apps.node" */
export type Apps_NodeNode_Instances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
};

/** aggregated selection of "apps.node" */
export type Apps_Node_Aggregate = {
  __typename?: 'apps_node_aggregate';
  aggregate?: Maybe<Apps_Node_Aggregate_Fields>;
  nodes: Array<Apps_Node>;
};

/** aggregate fields of "apps.node" */
export type Apps_Node_Aggregate_Fields = {
  __typename?: 'apps_node_aggregate_fields';
  avg?: Maybe<Apps_Node_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Node_Max_Fields>;
  min?: Maybe<Apps_Node_Min_Fields>;
  stddev?: Maybe<Apps_Node_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Node_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Node_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Node_Sum_Fields>;
  var_pop?: Maybe<Apps_Node_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Node_Var_Samp_Fields>;
  variance?: Maybe<Apps_Node_Variance_Fields>;
};


/** aggregate fields of "apps.node" */
export type Apps_Node_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Node_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_Node_Append_Input = {
  elements?: InputMaybe<Scalars['jsonb']['input']>;
  messages?: InputMaybe<Scalars['jsonb']['input']>;
  nlu_routing?: InputMaybe<Scalars['jsonb']['input']>;
  node_content_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_data_other?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Apps_Node_Avg_Fields = {
  __typename?: 'apps_node_avg_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.node". All fields are combined with a logical 'AND'. */
export type Apps_Node_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Node_Bool_Exp>>;
  _not?: InputMaybe<Apps_Node_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Node_Bool_Exp>>;
  checksum?: InputMaybe<Bigint_Comparison_Exp>;
  elements?: InputMaybe<Jsonb_Comparison_Exp>;
  messages?: InputMaybe<Jsonb_Comparison_Exp>;
  nlu_routing?: InputMaybe<Jsonb_Comparison_Exp>;
  node_content_other?: InputMaybe<Jsonb_Comparison_Exp>;
  node_data_other?: InputMaybe<Jsonb_Comparison_Exp>;
  node_id?: InputMaybe<Bigint_Comparison_Exp>;
  node_instances?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
  node_instances_aggregate?: InputMaybe<Apps_Node_Instance_Aggregate_Bool_Exp>;
  node_name?: InputMaybe<String_Comparison_Exp>;
  node_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.node" */
export enum Apps_Node_Constraint {
  /** unique or primary key constraint on columns "node_id" */
  PkNode = 'pk_node'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_Node_Delete_At_Path_Input = {
  elements?: InputMaybe<Array<Scalars['String']['input']>>;
  messages?: InputMaybe<Array<Scalars['String']['input']>>;
  nlu_routing?: InputMaybe<Array<Scalars['String']['input']>>;
  node_content_other?: InputMaybe<Array<Scalars['String']['input']>>;
  node_data_other?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_Node_Delete_Elem_Input = {
  elements?: InputMaybe<Scalars['Int']['input']>;
  messages?: InputMaybe<Scalars['Int']['input']>;
  nlu_routing?: InputMaybe<Scalars['Int']['input']>;
  node_content_other?: InputMaybe<Scalars['Int']['input']>;
  node_data_other?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_Node_Delete_Key_Input = {
  elements?: InputMaybe<Scalars['String']['input']>;
  messages?: InputMaybe<Scalars['String']['input']>;
  nlu_routing?: InputMaybe<Scalars['String']['input']>;
  node_content_other?: InputMaybe<Scalars['String']['input']>;
  node_data_other?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "apps.node_import_flat" */
export type Apps_Node_Import_Flat = {
  __typename?: 'apps_node_import_flat';
  content_orig?: Maybe<Scalars['jsonb']['output']>;
  content_other?: Maybe<Scalars['jsonb']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  is_dynamic?: Maybe<Scalars['Boolean']['output']>;
  messages?: Maybe<Scalars['jsonb']['output']>;
  next_node?: Maybe<Scalars['bigint']['output']>;
  ni_checksum?: Maybe<Scalars['bigint']['output']>;
  nlu_routing?: Maybe<Scalars['jsonb']['output']>;
  node_array_position: Scalars['Int']['output'];
  node_checksum?: Maybe<Scalars['bigint']['output']>;
  node_content_other?: Maybe<Scalars['jsonb']['output']>;
  node_data_other?: Maybe<Scalars['jsonb']['output']>;
  node_id?: Maybe<Scalars['bigint']['output']>;
  node_import_flat_id: Scalars['Int']['output'];
  node_instance_id?: Maybe<Scalars['bigint']['output']>;
  node_name?: Maybe<Scalars['String']['output']>;
  node_number?: Maybe<Scalars['bigint']['output']>;
  node_type: Scalars['String']['output'];
  options_json?: Maybe<Scalars['jsonb']['output']>;
  output_val_to_dest_lookup?: Maybe<Scalars['jsonb']['output']>;
  ra_checksum?: Maybe<Scalars['bigint']['output']>;
  rich_asset_content?: Maybe<Scalars['jsonb']['output']>;
  rich_asset_id?: Maybe<Scalars['bigint']['output']>;
  rich_asset_type?: Maybe<Scalars['rich_asset_type_enum']['output']>;
  source_node?: Maybe<Scalars['bigint']['output']>;
  source_var?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "apps.node_import_flat" */
export type Apps_Node_Import_FlatContent_OrigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node_import_flat" */
export type Apps_Node_Import_FlatContent_OtherArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node_import_flat" */
export type Apps_Node_Import_FlatMessagesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node_import_flat" */
export type Apps_Node_Import_FlatNlu_RoutingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node_import_flat" */
export type Apps_Node_Import_FlatNode_Content_OtherArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node_import_flat" */
export type Apps_Node_Import_FlatNode_Data_OtherArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node_import_flat" */
export type Apps_Node_Import_FlatOptions_JsonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node_import_flat" */
export type Apps_Node_Import_FlatOutput_Val_To_Dest_LookupArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node_import_flat" */
export type Apps_Node_Import_FlatRich_Asset_ContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "apps.node_import_flat" */
export type Apps_Node_Import_Flat_Aggregate = {
  __typename?: 'apps_node_import_flat_aggregate';
  aggregate?: Maybe<Apps_Node_Import_Flat_Aggregate_Fields>;
  nodes: Array<Apps_Node_Import_Flat>;
};

/** aggregate fields of "apps.node_import_flat" */
export type Apps_Node_Import_Flat_Aggregate_Fields = {
  __typename?: 'apps_node_import_flat_aggregate_fields';
  avg?: Maybe<Apps_Node_Import_Flat_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Node_Import_Flat_Max_Fields>;
  min?: Maybe<Apps_Node_Import_Flat_Min_Fields>;
  stddev?: Maybe<Apps_Node_Import_Flat_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Node_Import_Flat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Node_Import_Flat_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Node_Import_Flat_Sum_Fields>;
  var_pop?: Maybe<Apps_Node_Import_Flat_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Node_Import_Flat_Var_Samp_Fields>;
  variance?: Maybe<Apps_Node_Import_Flat_Variance_Fields>;
};


/** aggregate fields of "apps.node_import_flat" */
export type Apps_Node_Import_Flat_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Node_Import_Flat_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_Node_Import_Flat_Append_Input = {
  content_orig?: InputMaybe<Scalars['jsonb']['input']>;
  content_other?: InputMaybe<Scalars['jsonb']['input']>;
  messages?: InputMaybe<Scalars['jsonb']['input']>;
  nlu_routing?: InputMaybe<Scalars['jsonb']['input']>;
  node_content_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_data_other?: InputMaybe<Scalars['jsonb']['input']>;
  options_json?: InputMaybe<Scalars['jsonb']['input']>;
  output_val_to_dest_lookup?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Apps_Node_Import_Flat_Avg_Fields = {
  __typename?: 'apps_node_import_flat_avg_fields';
  next_node?: Maybe<Scalars['Float']['output']>;
  ni_checksum?: Maybe<Scalars['Float']['output']>;
  node_array_position?: Maybe<Scalars['Float']['output']>;
  node_checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_import_flat_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  ra_checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.node_import_flat". All fields are combined with a logical 'AND'. */
export type Apps_Node_Import_Flat_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Node_Import_Flat_Bool_Exp>>;
  _not?: InputMaybe<Apps_Node_Import_Flat_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Node_Import_Flat_Bool_Exp>>;
  content_orig?: InputMaybe<Jsonb_Comparison_Exp>;
  content_other?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  is_dynamic?: InputMaybe<Boolean_Comparison_Exp>;
  messages?: InputMaybe<Jsonb_Comparison_Exp>;
  next_node?: InputMaybe<Bigint_Comparison_Exp>;
  ni_checksum?: InputMaybe<Bigint_Comparison_Exp>;
  nlu_routing?: InputMaybe<Jsonb_Comparison_Exp>;
  node_array_position?: InputMaybe<Int_Comparison_Exp>;
  node_checksum?: InputMaybe<Bigint_Comparison_Exp>;
  node_content_other?: InputMaybe<Jsonb_Comparison_Exp>;
  node_data_other?: InputMaybe<Jsonb_Comparison_Exp>;
  node_id?: InputMaybe<Bigint_Comparison_Exp>;
  node_import_flat_id?: InputMaybe<Int_Comparison_Exp>;
  node_instance_id?: InputMaybe<Bigint_Comparison_Exp>;
  node_name?: InputMaybe<String_Comparison_Exp>;
  node_number?: InputMaybe<Bigint_Comparison_Exp>;
  node_type?: InputMaybe<String_Comparison_Exp>;
  options_json?: InputMaybe<Jsonb_Comparison_Exp>;
  output_val_to_dest_lookup?: InputMaybe<Jsonb_Comparison_Exp>;
  ra_checksum?: InputMaybe<Bigint_Comparison_Exp>;
  rich_asset_content?: InputMaybe<Jsonb_Comparison_Exp>;
  rich_asset_id?: InputMaybe<Bigint_Comparison_Exp>;
  rich_asset_type?: InputMaybe<Rich_Asset_Type_Enum_Comparison_Exp>;
  source_node?: InputMaybe<Bigint_Comparison_Exp>;
  source_var?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.node_import_flat" */
export enum Apps_Node_Import_Flat_Constraint {
  /** unique or primary key constraint on columns "node_import_flat_id" */
  PkNodeImportFlat = 'pk_node_import_flat',
  /** unique or primary key constraint on columns "version_id", "node_number" */
  UqNodeImportFlatVersionIdNodeNumber = 'uq_node_import_flat_version_id_node_number'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_Node_Import_Flat_Delete_At_Path_Input = {
  content_orig?: InputMaybe<Array<Scalars['String']['input']>>;
  content_other?: InputMaybe<Array<Scalars['String']['input']>>;
  messages?: InputMaybe<Array<Scalars['String']['input']>>;
  nlu_routing?: InputMaybe<Array<Scalars['String']['input']>>;
  node_content_other?: InputMaybe<Array<Scalars['String']['input']>>;
  node_data_other?: InputMaybe<Array<Scalars['String']['input']>>;
  options_json?: InputMaybe<Array<Scalars['String']['input']>>;
  output_val_to_dest_lookup?: InputMaybe<Array<Scalars['String']['input']>>;
  rich_asset_content?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_Node_Import_Flat_Delete_Elem_Input = {
  content_orig?: InputMaybe<Scalars['Int']['input']>;
  content_other?: InputMaybe<Scalars['Int']['input']>;
  messages?: InputMaybe<Scalars['Int']['input']>;
  nlu_routing?: InputMaybe<Scalars['Int']['input']>;
  node_content_other?: InputMaybe<Scalars['Int']['input']>;
  node_data_other?: InputMaybe<Scalars['Int']['input']>;
  options_json?: InputMaybe<Scalars['Int']['input']>;
  output_val_to_dest_lookup?: InputMaybe<Scalars['Int']['input']>;
  rich_asset_content?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_Node_Import_Flat_Delete_Key_Input = {
  content_orig?: InputMaybe<Scalars['String']['input']>;
  content_other?: InputMaybe<Scalars['String']['input']>;
  messages?: InputMaybe<Scalars['String']['input']>;
  nlu_routing?: InputMaybe<Scalars['String']['input']>;
  node_content_other?: InputMaybe<Scalars['String']['input']>;
  node_data_other?: InputMaybe<Scalars['String']['input']>;
  options_json?: InputMaybe<Scalars['String']['input']>;
  output_val_to_dest_lookup?: InputMaybe<Scalars['String']['input']>;
  rich_asset_content?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "apps.node_import_flat" */
export type Apps_Node_Import_Flat_Inc_Input = {
  next_node?: InputMaybe<Scalars['bigint']['input']>;
  node_array_position?: InputMaybe<Scalars['Int']['input']>;
  node_id?: InputMaybe<Scalars['bigint']['input']>;
  node_instance_id?: InputMaybe<Scalars['bigint']['input']>;
  node_number?: InputMaybe<Scalars['bigint']['input']>;
  rich_asset_id?: InputMaybe<Scalars['bigint']['input']>;
  source_node?: InputMaybe<Scalars['bigint']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.node_import_flat" */
export type Apps_Node_Import_Flat_Insert_Input = {
  content_orig?: InputMaybe<Scalars['jsonb']['input']>;
  content_other?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  is_dynamic?: InputMaybe<Scalars['Boolean']['input']>;
  messages?: InputMaybe<Scalars['jsonb']['input']>;
  next_node?: InputMaybe<Scalars['bigint']['input']>;
  nlu_routing?: InputMaybe<Scalars['jsonb']['input']>;
  node_array_position?: InputMaybe<Scalars['Int']['input']>;
  node_content_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_data_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_id?: InputMaybe<Scalars['bigint']['input']>;
  node_instance_id?: InputMaybe<Scalars['bigint']['input']>;
  node_name?: InputMaybe<Scalars['String']['input']>;
  node_number?: InputMaybe<Scalars['bigint']['input']>;
  node_type?: InputMaybe<Scalars['String']['input']>;
  options_json?: InputMaybe<Scalars['jsonb']['input']>;
  output_val_to_dest_lookup?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_content?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_id?: InputMaybe<Scalars['bigint']['input']>;
  rich_asset_type?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  source_node?: InputMaybe<Scalars['bigint']['input']>;
  source_var?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Apps_Node_Import_Flat_Max_Fields = {
  __typename?: 'apps_node_import_flat_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  next_node?: Maybe<Scalars['bigint']['output']>;
  ni_checksum?: Maybe<Scalars['bigint']['output']>;
  node_array_position?: Maybe<Scalars['Int']['output']>;
  node_checksum?: Maybe<Scalars['bigint']['output']>;
  node_id?: Maybe<Scalars['bigint']['output']>;
  node_import_flat_id?: Maybe<Scalars['Int']['output']>;
  node_instance_id?: Maybe<Scalars['bigint']['output']>;
  node_name?: Maybe<Scalars['String']['output']>;
  node_number?: Maybe<Scalars['bigint']['output']>;
  node_type?: Maybe<Scalars['String']['output']>;
  ra_checksum?: Maybe<Scalars['bigint']['output']>;
  rich_asset_id?: Maybe<Scalars['bigint']['output']>;
  rich_asset_type?: Maybe<Scalars['rich_asset_type_enum']['output']>;
  source_node?: Maybe<Scalars['bigint']['output']>;
  source_var?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Apps_Node_Import_Flat_Min_Fields = {
  __typename?: 'apps_node_import_flat_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  next_node?: Maybe<Scalars['bigint']['output']>;
  ni_checksum?: Maybe<Scalars['bigint']['output']>;
  node_array_position?: Maybe<Scalars['Int']['output']>;
  node_checksum?: Maybe<Scalars['bigint']['output']>;
  node_id?: Maybe<Scalars['bigint']['output']>;
  node_import_flat_id?: Maybe<Scalars['Int']['output']>;
  node_instance_id?: Maybe<Scalars['bigint']['output']>;
  node_name?: Maybe<Scalars['String']['output']>;
  node_number?: Maybe<Scalars['bigint']['output']>;
  node_type?: Maybe<Scalars['String']['output']>;
  ra_checksum?: Maybe<Scalars['bigint']['output']>;
  rich_asset_id?: Maybe<Scalars['bigint']['output']>;
  rich_asset_type?: Maybe<Scalars['rich_asset_type_enum']['output']>;
  source_node?: Maybe<Scalars['bigint']['output']>;
  source_var?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "apps.node_import_flat" */
export type Apps_Node_Import_Flat_Mutation_Response = {
  __typename?: 'apps_node_import_flat_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Node_Import_Flat>;
};

/** on_conflict condition type for table "apps.node_import_flat" */
export type Apps_Node_Import_Flat_On_Conflict = {
  constraint: Apps_Node_Import_Flat_Constraint;
  update_columns?: Array<Apps_Node_Import_Flat_Update_Column>;
  where?: InputMaybe<Apps_Node_Import_Flat_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.node_import_flat". */
export type Apps_Node_Import_Flat_Order_By = {
  content_orig?: InputMaybe<Order_By>;
  content_other?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  is_dynamic?: InputMaybe<Order_By>;
  messages?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  ni_checksum?: InputMaybe<Order_By>;
  nlu_routing?: InputMaybe<Order_By>;
  node_array_position?: InputMaybe<Order_By>;
  node_checksum?: InputMaybe<Order_By>;
  node_content_other?: InputMaybe<Order_By>;
  node_data_other?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_import_flat_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_name?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  node_type?: InputMaybe<Order_By>;
  options_json?: InputMaybe<Order_By>;
  output_val_to_dest_lookup?: InputMaybe<Order_By>;
  ra_checksum?: InputMaybe<Order_By>;
  rich_asset_content?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
  rich_asset_type?: InputMaybe<Order_By>;
  source_node?: InputMaybe<Order_By>;
  source_var?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.node_import_flat */
export type Apps_Node_Import_Flat_Pk_Columns_Input = {
  node_import_flat_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_Node_Import_Flat_Prepend_Input = {
  content_orig?: InputMaybe<Scalars['jsonb']['input']>;
  content_other?: InputMaybe<Scalars['jsonb']['input']>;
  messages?: InputMaybe<Scalars['jsonb']['input']>;
  nlu_routing?: InputMaybe<Scalars['jsonb']['input']>;
  node_content_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_data_other?: InputMaybe<Scalars['jsonb']['input']>;
  options_json?: InputMaybe<Scalars['jsonb']['input']>;
  output_val_to_dest_lookup?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.node_import_flat" */
export enum Apps_Node_Import_Flat_Select_Column {
  /** column name */
  ContentOrig = 'content_orig',
  /** column name */
  ContentOther = 'content_other',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsDynamic = 'is_dynamic',
  /** column name */
  Messages = 'messages',
  /** column name */
  NextNode = 'next_node',
  /** column name */
  NiChecksum = 'ni_checksum',
  /** column name */
  NluRouting = 'nlu_routing',
  /** column name */
  NodeArrayPosition = 'node_array_position',
  /** column name */
  NodeChecksum = 'node_checksum',
  /** column name */
  NodeContentOther = 'node_content_other',
  /** column name */
  NodeDataOther = 'node_data_other',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  NodeImportFlatId = 'node_import_flat_id',
  /** column name */
  NodeInstanceId = 'node_instance_id',
  /** column name */
  NodeName = 'node_name',
  /** column name */
  NodeNumber = 'node_number',
  /** column name */
  NodeType = 'node_type',
  /** column name */
  OptionsJson = 'options_json',
  /** column name */
  OutputValToDestLookup = 'output_val_to_dest_lookup',
  /** column name */
  RaChecksum = 'ra_checksum',
  /** column name */
  RichAssetContent = 'rich_asset_content',
  /** column name */
  RichAssetId = 'rich_asset_id',
  /** column name */
  RichAssetType = 'rich_asset_type',
  /** column name */
  SourceNode = 'source_node',
  /** column name */
  SourceVar = 'source_var',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionId = 'version_id'
}

/** input type for updating data in table "apps.node_import_flat" */
export type Apps_Node_Import_Flat_Set_Input = {
  content_orig?: InputMaybe<Scalars['jsonb']['input']>;
  content_other?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  is_dynamic?: InputMaybe<Scalars['Boolean']['input']>;
  messages?: InputMaybe<Scalars['jsonb']['input']>;
  next_node?: InputMaybe<Scalars['bigint']['input']>;
  nlu_routing?: InputMaybe<Scalars['jsonb']['input']>;
  node_array_position?: InputMaybe<Scalars['Int']['input']>;
  node_content_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_data_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_id?: InputMaybe<Scalars['bigint']['input']>;
  node_instance_id?: InputMaybe<Scalars['bigint']['input']>;
  node_name?: InputMaybe<Scalars['String']['input']>;
  node_number?: InputMaybe<Scalars['bigint']['input']>;
  node_type?: InputMaybe<Scalars['String']['input']>;
  options_json?: InputMaybe<Scalars['jsonb']['input']>;
  output_val_to_dest_lookup?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_content?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_id?: InputMaybe<Scalars['bigint']['input']>;
  rich_asset_type?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  source_node?: InputMaybe<Scalars['bigint']['input']>;
  source_var?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Node_Import_Flat_Stddev_Fields = {
  __typename?: 'apps_node_import_flat_stddev_fields';
  next_node?: Maybe<Scalars['Float']['output']>;
  ni_checksum?: Maybe<Scalars['Float']['output']>;
  node_array_position?: Maybe<Scalars['Float']['output']>;
  node_checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_import_flat_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  ra_checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_Node_Import_Flat_Stddev_Pop_Fields = {
  __typename?: 'apps_node_import_flat_stddev_pop_fields';
  next_node?: Maybe<Scalars['Float']['output']>;
  ni_checksum?: Maybe<Scalars['Float']['output']>;
  node_array_position?: Maybe<Scalars['Float']['output']>;
  node_checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_import_flat_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  ra_checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_Node_Import_Flat_Stddev_Samp_Fields = {
  __typename?: 'apps_node_import_flat_stddev_samp_fields';
  next_node?: Maybe<Scalars['Float']['output']>;
  ni_checksum?: Maybe<Scalars['Float']['output']>;
  node_array_position?: Maybe<Scalars['Float']['output']>;
  node_checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_import_flat_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  ra_checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_node_import_flat" */
export type Apps_Node_Import_Flat_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Node_Import_Flat_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Node_Import_Flat_Stream_Cursor_Value_Input = {
  content_orig?: InputMaybe<Scalars['jsonb']['input']>;
  content_other?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  is_dynamic?: InputMaybe<Scalars['Boolean']['input']>;
  messages?: InputMaybe<Scalars['jsonb']['input']>;
  next_node?: InputMaybe<Scalars['bigint']['input']>;
  ni_checksum?: InputMaybe<Scalars['bigint']['input']>;
  nlu_routing?: InputMaybe<Scalars['jsonb']['input']>;
  node_array_position?: InputMaybe<Scalars['Int']['input']>;
  node_checksum?: InputMaybe<Scalars['bigint']['input']>;
  node_content_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_data_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_id?: InputMaybe<Scalars['bigint']['input']>;
  node_import_flat_id?: InputMaybe<Scalars['Int']['input']>;
  node_instance_id?: InputMaybe<Scalars['bigint']['input']>;
  node_name?: InputMaybe<Scalars['String']['input']>;
  node_number?: InputMaybe<Scalars['bigint']['input']>;
  node_type?: InputMaybe<Scalars['String']['input']>;
  options_json?: InputMaybe<Scalars['jsonb']['input']>;
  output_val_to_dest_lookup?: InputMaybe<Scalars['jsonb']['input']>;
  ra_checksum?: InputMaybe<Scalars['bigint']['input']>;
  rich_asset_content?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_id?: InputMaybe<Scalars['bigint']['input']>;
  rich_asset_type?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  source_node?: InputMaybe<Scalars['bigint']['input']>;
  source_var?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Apps_Node_Import_Flat_Sum_Fields = {
  __typename?: 'apps_node_import_flat_sum_fields';
  next_node?: Maybe<Scalars['bigint']['output']>;
  ni_checksum?: Maybe<Scalars['bigint']['output']>;
  node_array_position?: Maybe<Scalars['Int']['output']>;
  node_checksum?: Maybe<Scalars['bigint']['output']>;
  node_id?: Maybe<Scalars['bigint']['output']>;
  node_import_flat_id?: Maybe<Scalars['Int']['output']>;
  node_instance_id?: Maybe<Scalars['bigint']['output']>;
  node_number?: Maybe<Scalars['bigint']['output']>;
  ra_checksum?: Maybe<Scalars['bigint']['output']>;
  rich_asset_id?: Maybe<Scalars['bigint']['output']>;
  source_node?: Maybe<Scalars['bigint']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "apps.node_import_flat" */
export enum Apps_Node_Import_Flat_Update_Column {
  /** column name */
  ContentOrig = 'content_orig',
  /** column name */
  ContentOther = 'content_other',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsDynamic = 'is_dynamic',
  /** column name */
  Messages = 'messages',
  /** column name */
  NextNode = 'next_node',
  /** column name */
  NluRouting = 'nlu_routing',
  /** column name */
  NodeArrayPosition = 'node_array_position',
  /** column name */
  NodeContentOther = 'node_content_other',
  /** column name */
  NodeDataOther = 'node_data_other',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  NodeInstanceId = 'node_instance_id',
  /** column name */
  NodeName = 'node_name',
  /** column name */
  NodeNumber = 'node_number',
  /** column name */
  NodeType = 'node_type',
  /** column name */
  OptionsJson = 'options_json',
  /** column name */
  OutputValToDestLookup = 'output_val_to_dest_lookup',
  /** column name */
  RichAssetContent = 'rich_asset_content',
  /** column name */
  RichAssetId = 'rich_asset_id',
  /** column name */
  RichAssetType = 'rich_asset_type',
  /** column name */
  SourceNode = 'source_node',
  /** column name */
  SourceVar = 'source_var',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionId = 'version_id'
}

export type Apps_Node_Import_Flat_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_Node_Import_Flat_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_Node_Import_Flat_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_Node_Import_Flat_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_Node_Import_Flat_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Node_Import_Flat_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_Node_Import_Flat_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Node_Import_Flat_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Node_Import_Flat_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Node_Import_Flat_Var_Pop_Fields = {
  __typename?: 'apps_node_import_flat_var_pop_fields';
  next_node?: Maybe<Scalars['Float']['output']>;
  ni_checksum?: Maybe<Scalars['Float']['output']>;
  node_array_position?: Maybe<Scalars['Float']['output']>;
  node_checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_import_flat_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  ra_checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_Node_Import_Flat_Var_Samp_Fields = {
  __typename?: 'apps_node_import_flat_var_samp_fields';
  next_node?: Maybe<Scalars['Float']['output']>;
  ni_checksum?: Maybe<Scalars['Float']['output']>;
  node_array_position?: Maybe<Scalars['Float']['output']>;
  node_checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_import_flat_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  ra_checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_Node_Import_Flat_Variance_Fields = {
  __typename?: 'apps_node_import_flat_variance_fields';
  next_node?: Maybe<Scalars['Float']['output']>;
  ni_checksum?: Maybe<Scalars['Float']['output']>;
  node_array_position?: Maybe<Scalars['Float']['output']>;
  node_checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_import_flat_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  ra_checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "apps.node_import_json" */
export type Apps_Node_Import_Json = {
  __typename?: 'apps_node_import_json';
  array_position: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  node_import_json_id: Scalars['Int']['output'];
  node_json: Scalars['jsonb']['output'];
  version_id: Scalars['bigint']['output'];
};


/** columns and relationships of "apps.node_import_json" */
export type Apps_Node_Import_JsonNode_JsonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "apps.node_import_json" */
export type Apps_Node_Import_Json_Aggregate = {
  __typename?: 'apps_node_import_json_aggregate';
  aggregate?: Maybe<Apps_Node_Import_Json_Aggregate_Fields>;
  nodes: Array<Apps_Node_Import_Json>;
};

/** aggregate fields of "apps.node_import_json" */
export type Apps_Node_Import_Json_Aggregate_Fields = {
  __typename?: 'apps_node_import_json_aggregate_fields';
  avg?: Maybe<Apps_Node_Import_Json_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Node_Import_Json_Max_Fields>;
  min?: Maybe<Apps_Node_Import_Json_Min_Fields>;
  stddev?: Maybe<Apps_Node_Import_Json_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Node_Import_Json_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Node_Import_Json_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Node_Import_Json_Sum_Fields>;
  var_pop?: Maybe<Apps_Node_Import_Json_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Node_Import_Json_Var_Samp_Fields>;
  variance?: Maybe<Apps_Node_Import_Json_Variance_Fields>;
};


/** aggregate fields of "apps.node_import_json" */
export type Apps_Node_Import_Json_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Node_Import_Json_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_Node_Import_Json_Append_Input = {
  node_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Apps_Node_Import_Json_Avg_Fields = {
  __typename?: 'apps_node_import_json_avg_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  node_import_json_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.node_import_json". All fields are combined with a logical 'AND'. */
export type Apps_Node_Import_Json_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Node_Import_Json_Bool_Exp>>;
  _not?: InputMaybe<Apps_Node_Import_Json_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Node_Import_Json_Bool_Exp>>;
  array_position?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  node_import_json_id?: InputMaybe<Int_Comparison_Exp>;
  node_json?: InputMaybe<Jsonb_Comparison_Exp>;
  version_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.node_import_json" */
export enum Apps_Node_Import_Json_Constraint {
  /** unique or primary key constraint on columns "version_id", "array_position" */
  NodeImportJsonVersionIdArrayPositionKey = 'node_import_json_version_id_array_position_key',
  /** unique or primary key constraint on columns "node_import_json_id" */
  PkNodeImportJson = 'pk_node_import_json'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_Node_Import_Json_Delete_At_Path_Input = {
  node_json?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_Node_Import_Json_Delete_Elem_Input = {
  node_json?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_Node_Import_Json_Delete_Key_Input = {
  node_json?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "apps.node_import_json" */
export type Apps_Node_Import_Json_Inc_Input = {
  array_position?: InputMaybe<Scalars['Int']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.node_import_json" */
export type Apps_Node_Import_Json_Insert_Input = {
  array_position?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  node_json?: InputMaybe<Scalars['jsonb']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Apps_Node_Import_Json_Max_Fields = {
  __typename?: 'apps_node_import_json_max_fields';
  array_position?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  node_import_json_id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Apps_Node_Import_Json_Min_Fields = {
  __typename?: 'apps_node_import_json_min_fields';
  array_position?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  node_import_json_id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "apps.node_import_json" */
export type Apps_Node_Import_Json_Mutation_Response = {
  __typename?: 'apps_node_import_json_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Node_Import_Json>;
};

/** on_conflict condition type for table "apps.node_import_json" */
export type Apps_Node_Import_Json_On_Conflict = {
  constraint: Apps_Node_Import_Json_Constraint;
  update_columns?: Array<Apps_Node_Import_Json_Update_Column>;
  where?: InputMaybe<Apps_Node_Import_Json_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.node_import_json". */
export type Apps_Node_Import_Json_Order_By = {
  array_position?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  node_import_json_id?: InputMaybe<Order_By>;
  node_json?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.node_import_json */
export type Apps_Node_Import_Json_Pk_Columns_Input = {
  node_import_json_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_Node_Import_Json_Prepend_Input = {
  node_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.node_import_json" */
export enum Apps_Node_Import_Json_Select_Column {
  /** column name */
  ArrayPosition = 'array_position',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NodeImportJsonId = 'node_import_json_id',
  /** column name */
  NodeJson = 'node_json',
  /** column name */
  VersionId = 'version_id'
}

/** input type for updating data in table "apps.node_import_json" */
export type Apps_Node_Import_Json_Set_Input = {
  array_position?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  node_json?: InputMaybe<Scalars['jsonb']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Node_Import_Json_Stddev_Fields = {
  __typename?: 'apps_node_import_json_stddev_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  node_import_json_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_Node_Import_Json_Stddev_Pop_Fields = {
  __typename?: 'apps_node_import_json_stddev_pop_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  node_import_json_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_Node_Import_Json_Stddev_Samp_Fields = {
  __typename?: 'apps_node_import_json_stddev_samp_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  node_import_json_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_node_import_json" */
export type Apps_Node_Import_Json_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Node_Import_Json_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Node_Import_Json_Stream_Cursor_Value_Input = {
  array_position?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  node_import_json_id?: InputMaybe<Scalars['Int']['input']>;
  node_json?: InputMaybe<Scalars['jsonb']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Apps_Node_Import_Json_Sum_Fields = {
  __typename?: 'apps_node_import_json_sum_fields';
  array_position?: Maybe<Scalars['Int']['output']>;
  node_import_json_id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "apps.node_import_json" */
export enum Apps_Node_Import_Json_Update_Column {
  /** column name */
  ArrayPosition = 'array_position',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NodeJson = 'node_json',
  /** column name */
  VersionId = 'version_id'
}

export type Apps_Node_Import_Json_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_Node_Import_Json_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_Node_Import_Json_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_Node_Import_Json_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_Node_Import_Json_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Node_Import_Json_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_Node_Import_Json_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Node_Import_Json_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Node_Import_Json_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Node_Import_Json_Var_Pop_Fields = {
  __typename?: 'apps_node_import_json_var_pop_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  node_import_json_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_Node_Import_Json_Var_Samp_Fields = {
  __typename?: 'apps_node_import_json_var_samp_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  node_import_json_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_Node_Import_Json_Variance_Fields = {
  __typename?: 'apps_node_import_json_variance_fields';
  array_position?: Maybe<Scalars['Float']['output']>;
  node_import_json_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for inserting data into table "apps.node" */
export type Apps_Node_Insert_Input = {
  elements?: InputMaybe<Scalars['jsonb']['input']>;
  messages?: InputMaybe<Scalars['jsonb']['input']>;
  nlu_routing?: InputMaybe<Scalars['jsonb']['input']>;
  node_content_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_data_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_instances?: InputMaybe<Apps_Node_Instance_Arr_Rel_Insert_Input>;
  node_name?: InputMaybe<Scalars['String']['input']>;
  node_type?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "apps.node_instance" */
export type Apps_Node_Instance = {
  __typename?: 'apps_node_instance';
  checksum?: Maybe<Scalars['bigint']['output']>;
  next_node?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  node: Apps_Node;
  node_id: Scalars['bigint']['output'];
  node_instance_id: Scalars['bigint']['output'];
  output_map_dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  output_value_to_dest_map?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  rich_asset?: Maybe<Apps_Rich_Asset>;
  rich_asset_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  version_node_instances: Array<Apps_Version_Node_Instance>;
  /** An aggregate relationship */
  version_node_instances_aggregate: Apps_Version_Node_Instance_Aggregate;
};


/** columns and relationships of "apps.node_instance" */
export type Apps_Node_InstanceOutput_Value_To_Dest_MapArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.node_instance" */
export type Apps_Node_InstanceVersion_Node_InstancesArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
};


/** columns and relationships of "apps.node_instance" */
export type Apps_Node_InstanceVersion_Node_Instances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
};

/** aggregated selection of "apps.node_instance" */
export type Apps_Node_Instance_Aggregate = {
  __typename?: 'apps_node_instance_aggregate';
  aggregate?: Maybe<Apps_Node_Instance_Aggregate_Fields>;
  nodes: Array<Apps_Node_Instance>;
};

export type Apps_Node_Instance_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apps_Node_Instance_Aggregate_Bool_Exp_Count>;
};

export type Apps_Node_Instance_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_Node_Instance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps.node_instance" */
export type Apps_Node_Instance_Aggregate_Fields = {
  __typename?: 'apps_node_instance_aggregate_fields';
  avg?: Maybe<Apps_Node_Instance_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Node_Instance_Max_Fields>;
  min?: Maybe<Apps_Node_Instance_Min_Fields>;
  stddev?: Maybe<Apps_Node_Instance_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Node_Instance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Node_Instance_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Node_Instance_Sum_Fields>;
  var_pop?: Maybe<Apps_Node_Instance_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Node_Instance_Var_Samp_Fields>;
  variance?: Maybe<Apps_Node_Instance_Variance_Fields>;
};


/** aggregate fields of "apps.node_instance" */
export type Apps_Node_Instance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Node_Instance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps.node_instance" */
export type Apps_Node_Instance_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_Node_Instance_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_Node_Instance_Max_Order_By>;
  min?: InputMaybe<Apps_Node_Instance_Min_Order_By>;
  stddev?: InputMaybe<Apps_Node_Instance_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_Node_Instance_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_Node_Instance_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_Node_Instance_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_Node_Instance_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_Node_Instance_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_Node_Instance_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_Node_Instance_Append_Input = {
  output_value_to_dest_map?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "apps.node_instance" */
export type Apps_Node_Instance_Arr_Rel_Insert_Input = {
  data: Array<Apps_Node_Instance_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Node_Instance_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_Node_Instance_Avg_Fields = {
  __typename?: 'apps_node_instance_avg_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps.node_instance" */
export type Apps_Node_Instance_Avg_Order_By = {
  checksum?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps.node_instance". All fields are combined with a logical 'AND'. */
export type Apps_Node_Instance_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Node_Instance_Bool_Exp>>;
  _not?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Node_Instance_Bool_Exp>>;
  checksum?: InputMaybe<Bigint_Comparison_Exp>;
  next_node?: InputMaybe<Bigint_Comparison_Exp>;
  node?: InputMaybe<Apps_Node_Bool_Exp>;
  node_id?: InputMaybe<Bigint_Comparison_Exp>;
  node_instance_id?: InputMaybe<Bigint_Comparison_Exp>;
  output_map_dest_nodes?: InputMaybe<Bigint_Array_Comparison_Exp>;
  output_value_to_dest_map?: InputMaybe<Jsonb_Comparison_Exp>;
  rich_asset?: InputMaybe<Apps_Rich_Asset_Bool_Exp>;
  rich_asset_id?: InputMaybe<Bigint_Comparison_Exp>;
  version_node_instances?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
  version_node_instances_aggregate?: InputMaybe<Apps_Version_Node_Instance_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "apps.node_instance" */
export enum Apps_Node_Instance_Constraint {
  /** unique or primary key constraint on columns "node_instance_id" */
  PkNodeInstance = 'pk_node_instance'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_Node_Instance_Delete_At_Path_Input = {
  output_value_to_dest_map?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_Node_Instance_Delete_Elem_Input = {
  output_value_to_dest_map?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_Node_Instance_Delete_Key_Input = {
  output_value_to_dest_map?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "apps.node_instance" */
export type Apps_Node_Instance_Inc_Input = {
  next_node?: InputMaybe<Scalars['bigint']['input']>;
  node_id?: InputMaybe<Scalars['bigint']['input']>;
  rich_asset_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.node_instance" */
export type Apps_Node_Instance_Insert_Input = {
  next_node?: InputMaybe<Scalars['bigint']['input']>;
  node?: InputMaybe<Apps_Node_Obj_Rel_Insert_Input>;
  node_id?: InputMaybe<Scalars['bigint']['input']>;
  output_value_to_dest_map?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset?: InputMaybe<Apps_Rich_Asset_Obj_Rel_Insert_Input>;
  rich_asset_id?: InputMaybe<Scalars['bigint']['input']>;
  version_node_instances?: InputMaybe<Apps_Version_Node_Instance_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Apps_Node_Instance_Max_Fields = {
  __typename?: 'apps_node_instance_max_fields';
  checksum?: Maybe<Scalars['bigint']['output']>;
  next_node?: Maybe<Scalars['bigint']['output']>;
  node_id?: Maybe<Scalars['bigint']['output']>;
  node_instance_id?: Maybe<Scalars['bigint']['output']>;
  output_map_dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  rich_asset_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "apps.node_instance" */
export type Apps_Node_Instance_Max_Order_By = {
  checksum?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  output_map_dest_nodes?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_Node_Instance_Min_Fields = {
  __typename?: 'apps_node_instance_min_fields';
  checksum?: Maybe<Scalars['bigint']['output']>;
  next_node?: Maybe<Scalars['bigint']['output']>;
  node_id?: Maybe<Scalars['bigint']['output']>;
  node_instance_id?: Maybe<Scalars['bigint']['output']>;
  output_map_dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  rich_asset_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "apps.node_instance" */
export type Apps_Node_Instance_Min_Order_By = {
  checksum?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  output_map_dest_nodes?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps.node_instance" */
export type Apps_Node_Instance_Mutation_Response = {
  __typename?: 'apps_node_instance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Node_Instance>;
};

/** input type for inserting object relation for remote table "apps.node_instance" */
export type Apps_Node_Instance_Obj_Rel_Insert_Input = {
  data: Apps_Node_Instance_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Node_Instance_On_Conflict>;
};

/** on_conflict condition type for table "apps.node_instance" */
export type Apps_Node_Instance_On_Conflict = {
  constraint: Apps_Node_Instance_Constraint;
  update_columns?: Array<Apps_Node_Instance_Update_Column>;
  where?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.node_instance". */
export type Apps_Node_Instance_Order_By = {
  checksum?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  node?: InputMaybe<Apps_Node_Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  output_map_dest_nodes?: InputMaybe<Order_By>;
  output_value_to_dest_map?: InputMaybe<Order_By>;
  rich_asset?: InputMaybe<Apps_Rich_Asset_Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
  version_node_instances_aggregate?: InputMaybe<Apps_Version_Node_Instance_Aggregate_Order_By>;
};

/** primary key columns input for table: apps.node_instance */
export type Apps_Node_Instance_Pk_Columns_Input = {
  node_instance_id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_Node_Instance_Prepend_Input = {
  output_value_to_dest_map?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.node_instance" */
export enum Apps_Node_Instance_Select_Column {
  /** column name */
  Checksum = 'checksum',
  /** column name */
  NextNode = 'next_node',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  NodeInstanceId = 'node_instance_id',
  /** column name */
  OutputMapDestNodes = 'output_map_dest_nodes',
  /** column name */
  OutputValueToDestMap = 'output_value_to_dest_map',
  /** column name */
  RichAssetId = 'rich_asset_id'
}

/** input type for updating data in table "apps.node_instance" */
export type Apps_Node_Instance_Set_Input = {
  next_node?: InputMaybe<Scalars['bigint']['input']>;
  node_id?: InputMaybe<Scalars['bigint']['input']>;
  output_value_to_dest_map?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Node_Instance_Stddev_Fields = {
  __typename?: 'apps_node_instance_stddev_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps.node_instance" */
export type Apps_Node_Instance_Stddev_Order_By = {
  checksum?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_Node_Instance_Stddev_Pop_Fields = {
  __typename?: 'apps_node_instance_stddev_pop_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps.node_instance" */
export type Apps_Node_Instance_Stddev_Pop_Order_By = {
  checksum?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_Node_Instance_Stddev_Samp_Fields = {
  __typename?: 'apps_node_instance_stddev_samp_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps.node_instance" */
export type Apps_Node_Instance_Stddev_Samp_Order_By = {
  checksum?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_node_instance" */
export type Apps_Node_Instance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Node_Instance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Node_Instance_Stream_Cursor_Value_Input = {
  checksum?: InputMaybe<Scalars['bigint']['input']>;
  next_node?: InputMaybe<Scalars['bigint']['input']>;
  node_id?: InputMaybe<Scalars['bigint']['input']>;
  node_instance_id?: InputMaybe<Scalars['bigint']['input']>;
  output_map_dest_nodes?: InputMaybe<Array<Scalars['bigint']['input']>>;
  output_value_to_dest_map?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Apps_Node_Instance_Sum_Fields = {
  __typename?: 'apps_node_instance_sum_fields';
  checksum?: Maybe<Scalars['bigint']['output']>;
  next_node?: Maybe<Scalars['bigint']['output']>;
  node_id?: Maybe<Scalars['bigint']['output']>;
  node_instance_id?: Maybe<Scalars['bigint']['output']>;
  rich_asset_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "apps.node_instance" */
export type Apps_Node_Instance_Sum_Order_By = {
  checksum?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
};

/** update columns of table "apps.node_instance" */
export enum Apps_Node_Instance_Update_Column {
  /** column name */
  NextNode = 'next_node',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  OutputValueToDestMap = 'output_value_to_dest_map',
  /** column name */
  RichAssetId = 'rich_asset_id'
}

export type Apps_Node_Instance_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_Node_Instance_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_Node_Instance_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_Node_Instance_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_Node_Instance_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Node_Instance_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_Node_Instance_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Node_Instance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Node_Instance_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Node_Instance_Var_Pop_Fields = {
  __typename?: 'apps_node_instance_var_pop_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps.node_instance" */
export type Apps_Node_Instance_Var_Pop_Order_By = {
  checksum?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_Node_Instance_Var_Samp_Fields = {
  __typename?: 'apps_node_instance_var_samp_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps.node_instance" */
export type Apps_Node_Instance_Var_Samp_Order_By = {
  checksum?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_Node_Instance_Variance_Fields = {
  __typename?: 'apps_node_instance_variance_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps.node_instance" */
export type Apps_Node_Instance_Variance_Order_By = {
  checksum?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Apps_Node_Max_Fields = {
  __typename?: 'apps_node_max_fields';
  checksum?: Maybe<Scalars['bigint']['output']>;
  node_id?: Maybe<Scalars['bigint']['output']>;
  node_name?: Maybe<Scalars['String']['output']>;
  node_type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Apps_Node_Min_Fields = {
  __typename?: 'apps_node_min_fields';
  checksum?: Maybe<Scalars['bigint']['output']>;
  node_id?: Maybe<Scalars['bigint']['output']>;
  node_name?: Maybe<Scalars['String']['output']>;
  node_type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "apps.node" */
export type Apps_Node_Mutation_Response = {
  __typename?: 'apps_node_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Node>;
};

/** input type for inserting object relation for remote table "apps.node" */
export type Apps_Node_Obj_Rel_Insert_Input = {
  data: Apps_Node_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Node_On_Conflict>;
};

/** on_conflict condition type for table "apps.node" */
export type Apps_Node_On_Conflict = {
  constraint: Apps_Node_Constraint;
  update_columns?: Array<Apps_Node_Update_Column>;
  where?: InputMaybe<Apps_Node_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.node". */
export type Apps_Node_Order_By = {
  checksum?: InputMaybe<Order_By>;
  elements?: InputMaybe<Order_By>;
  messages?: InputMaybe<Order_By>;
  nlu_routing?: InputMaybe<Order_By>;
  node_content_other?: InputMaybe<Order_By>;
  node_data_other?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  node_instances_aggregate?: InputMaybe<Apps_Node_Instance_Aggregate_Order_By>;
  node_name?: InputMaybe<Order_By>;
  node_type?: InputMaybe<Order_By>;
};

/** columns and relationships of "apps.node_outgoing_edge_view" */
export type Apps_Node_Outgoing_Edge_View = {
  __typename?: 'apps_node_outgoing_edge_view';
  from_next_node?: Maybe<Scalars['bigint']['output']>;
  from_node_instance_id?: Maybe<Scalars['bigint']['output']>;
  from_node_number?: Maybe<Scalars['bigint']['output']>;
  from_output_map_dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  from_rich_asset_dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  next_node?: Maybe<Scalars['bigint']['output']>;
  to_node_numbers?: Maybe<Array<Scalars['bigint']['output']>>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "apps.node_outgoing_edge_view" */
export type Apps_Node_Outgoing_Edge_View_Aggregate = {
  __typename?: 'apps_node_outgoing_edge_view_aggregate';
  aggregate?: Maybe<Apps_Node_Outgoing_Edge_View_Aggregate_Fields>;
  nodes: Array<Apps_Node_Outgoing_Edge_View>;
};

/** aggregate fields of "apps.node_outgoing_edge_view" */
export type Apps_Node_Outgoing_Edge_View_Aggregate_Fields = {
  __typename?: 'apps_node_outgoing_edge_view_aggregate_fields';
  avg?: Maybe<Apps_Node_Outgoing_Edge_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Node_Outgoing_Edge_View_Max_Fields>;
  min?: Maybe<Apps_Node_Outgoing_Edge_View_Min_Fields>;
  stddev?: Maybe<Apps_Node_Outgoing_Edge_View_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Node_Outgoing_Edge_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Node_Outgoing_Edge_View_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Node_Outgoing_Edge_View_Sum_Fields>;
  var_pop?: Maybe<Apps_Node_Outgoing_Edge_View_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Node_Outgoing_Edge_View_Var_Samp_Fields>;
  variance?: Maybe<Apps_Node_Outgoing_Edge_View_Variance_Fields>;
};


/** aggregate fields of "apps.node_outgoing_edge_view" */
export type Apps_Node_Outgoing_Edge_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Node_Outgoing_Edge_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Apps_Node_Outgoing_Edge_View_Avg_Fields = {
  __typename?: 'apps_node_outgoing_edge_view_avg_fields';
  from_next_node?: Maybe<Scalars['Float']['output']>;
  from_node_instance_id?: Maybe<Scalars['Float']['output']>;
  from_node_number?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.node_outgoing_edge_view". All fields are combined with a logical 'AND'. */
export type Apps_Node_Outgoing_Edge_View_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Node_Outgoing_Edge_View_Bool_Exp>>;
  _not?: InputMaybe<Apps_Node_Outgoing_Edge_View_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Node_Outgoing_Edge_View_Bool_Exp>>;
  from_next_node?: InputMaybe<Bigint_Comparison_Exp>;
  from_node_instance_id?: InputMaybe<Bigint_Comparison_Exp>;
  from_node_number?: InputMaybe<Bigint_Comparison_Exp>;
  from_output_map_dest_nodes?: InputMaybe<Bigint_Array_Comparison_Exp>;
  from_rich_asset_dest_nodes?: InputMaybe<Bigint_Array_Comparison_Exp>;
  next_node?: InputMaybe<Bigint_Comparison_Exp>;
  to_node_numbers?: InputMaybe<Bigint_Array_Comparison_Exp>;
  version_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "apps.node_outgoing_edge_view" */
export type Apps_Node_Outgoing_Edge_View_Insert_Input = {
  from_next_node?: InputMaybe<Scalars['bigint']['input']>;
  from_node_instance_id?: InputMaybe<Scalars['bigint']['input']>;
  from_node_number?: InputMaybe<Scalars['bigint']['input']>;
  from_output_map_dest_nodes?: InputMaybe<Array<Scalars['bigint']['input']>>;
  from_rich_asset_dest_nodes?: InputMaybe<Array<Scalars['bigint']['input']>>;
  next_node?: InputMaybe<Scalars['bigint']['input']>;
  to_node_numbers?: InputMaybe<Array<Scalars['bigint']['input']>>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Apps_Node_Outgoing_Edge_View_Max_Fields = {
  __typename?: 'apps_node_outgoing_edge_view_max_fields';
  from_next_node?: Maybe<Scalars['bigint']['output']>;
  from_node_instance_id?: Maybe<Scalars['bigint']['output']>;
  from_node_number?: Maybe<Scalars['bigint']['output']>;
  from_output_map_dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  from_rich_asset_dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  next_node?: Maybe<Scalars['bigint']['output']>;
  to_node_numbers?: Maybe<Array<Scalars['bigint']['output']>>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Apps_Node_Outgoing_Edge_View_Min_Fields = {
  __typename?: 'apps_node_outgoing_edge_view_min_fields';
  from_next_node?: Maybe<Scalars['bigint']['output']>;
  from_node_instance_id?: Maybe<Scalars['bigint']['output']>;
  from_node_number?: Maybe<Scalars['bigint']['output']>;
  from_output_map_dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  from_rich_asset_dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  next_node?: Maybe<Scalars['bigint']['output']>;
  to_node_numbers?: Maybe<Array<Scalars['bigint']['output']>>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** input type for inserting object relation for remote table "apps.node_outgoing_edge_view" */
export type Apps_Node_Outgoing_Edge_View_Obj_Rel_Insert_Input = {
  data: Apps_Node_Outgoing_Edge_View_Insert_Input;
};

/** Ordering options when selecting data from "apps.node_outgoing_edge_view". */
export type Apps_Node_Outgoing_Edge_View_Order_By = {
  from_next_node?: InputMaybe<Order_By>;
  from_node_instance_id?: InputMaybe<Order_By>;
  from_node_number?: InputMaybe<Order_By>;
  from_output_map_dest_nodes?: InputMaybe<Order_By>;
  from_rich_asset_dest_nodes?: InputMaybe<Order_By>;
  next_node?: InputMaybe<Order_By>;
  to_node_numbers?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** select columns of table "apps.node_outgoing_edge_view" */
export enum Apps_Node_Outgoing_Edge_View_Select_Column {
  /** column name */
  FromNextNode = 'from_next_node',
  /** column name */
  FromNodeInstanceId = 'from_node_instance_id',
  /** column name */
  FromNodeNumber = 'from_node_number',
  /** column name */
  FromOutputMapDestNodes = 'from_output_map_dest_nodes',
  /** column name */
  FromRichAssetDestNodes = 'from_rich_asset_dest_nodes',
  /** column name */
  NextNode = 'next_node',
  /** column name */
  ToNodeNumbers = 'to_node_numbers',
  /** column name */
  VersionId = 'version_id'
}

/** aggregate stddev on columns */
export type Apps_Node_Outgoing_Edge_View_Stddev_Fields = {
  __typename?: 'apps_node_outgoing_edge_view_stddev_fields';
  from_next_node?: Maybe<Scalars['Float']['output']>;
  from_node_instance_id?: Maybe<Scalars['Float']['output']>;
  from_node_number?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_Node_Outgoing_Edge_View_Stddev_Pop_Fields = {
  __typename?: 'apps_node_outgoing_edge_view_stddev_pop_fields';
  from_next_node?: Maybe<Scalars['Float']['output']>;
  from_node_instance_id?: Maybe<Scalars['Float']['output']>;
  from_node_number?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_Node_Outgoing_Edge_View_Stddev_Samp_Fields = {
  __typename?: 'apps_node_outgoing_edge_view_stddev_samp_fields';
  from_next_node?: Maybe<Scalars['Float']['output']>;
  from_node_instance_id?: Maybe<Scalars['Float']['output']>;
  from_node_number?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_node_outgoing_edge_view" */
export type Apps_Node_Outgoing_Edge_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Node_Outgoing_Edge_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Node_Outgoing_Edge_View_Stream_Cursor_Value_Input = {
  from_next_node?: InputMaybe<Scalars['bigint']['input']>;
  from_node_instance_id?: InputMaybe<Scalars['bigint']['input']>;
  from_node_number?: InputMaybe<Scalars['bigint']['input']>;
  from_output_map_dest_nodes?: InputMaybe<Array<Scalars['bigint']['input']>>;
  from_rich_asset_dest_nodes?: InputMaybe<Array<Scalars['bigint']['input']>>;
  next_node?: InputMaybe<Scalars['bigint']['input']>;
  to_node_numbers?: InputMaybe<Array<Scalars['bigint']['input']>>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Apps_Node_Outgoing_Edge_View_Sum_Fields = {
  __typename?: 'apps_node_outgoing_edge_view_sum_fields';
  from_next_node?: Maybe<Scalars['bigint']['output']>;
  from_node_instance_id?: Maybe<Scalars['bigint']['output']>;
  from_node_number?: Maybe<Scalars['bigint']['output']>;
  next_node?: Maybe<Scalars['bigint']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type Apps_Node_Outgoing_Edge_View_Var_Pop_Fields = {
  __typename?: 'apps_node_outgoing_edge_view_var_pop_fields';
  from_next_node?: Maybe<Scalars['Float']['output']>;
  from_node_instance_id?: Maybe<Scalars['Float']['output']>;
  from_node_number?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_Node_Outgoing_Edge_View_Var_Samp_Fields = {
  __typename?: 'apps_node_outgoing_edge_view_var_samp_fields';
  from_next_node?: Maybe<Scalars['Float']['output']>;
  from_node_instance_id?: Maybe<Scalars['Float']['output']>;
  from_node_number?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_Node_Outgoing_Edge_View_Variance_Fields = {
  __typename?: 'apps_node_outgoing_edge_view_variance_fields';
  from_next_node?: Maybe<Scalars['Float']['output']>;
  from_node_instance_id?: Maybe<Scalars['Float']['output']>;
  from_node_number?: Maybe<Scalars['Float']['output']>;
  next_node?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** primary key columns input for table: apps.node */
export type Apps_Node_Pk_Columns_Input = {
  node_id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_Node_Prepend_Input = {
  elements?: InputMaybe<Scalars['jsonb']['input']>;
  messages?: InputMaybe<Scalars['jsonb']['input']>;
  nlu_routing?: InputMaybe<Scalars['jsonb']['input']>;
  node_content_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_data_other?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.node" */
export enum Apps_Node_Select_Column {
  /** column name */
  Checksum = 'checksum',
  /** column name */
  Elements = 'elements',
  /** column name */
  Messages = 'messages',
  /** column name */
  NluRouting = 'nlu_routing',
  /** column name */
  NodeContentOther = 'node_content_other',
  /** column name */
  NodeDataOther = 'node_data_other',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  NodeName = 'node_name',
  /** column name */
  NodeType = 'node_type'
}

/** input type for updating data in table "apps.node" */
export type Apps_Node_Set_Input = {
  elements?: InputMaybe<Scalars['jsonb']['input']>;
  messages?: InputMaybe<Scalars['jsonb']['input']>;
  nlu_routing?: InputMaybe<Scalars['jsonb']['input']>;
  node_content_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_data_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_name?: InputMaybe<Scalars['String']['input']>;
  node_type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Node_Stddev_Fields = {
  __typename?: 'apps_node_stddev_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_Node_Stddev_Pop_Fields = {
  __typename?: 'apps_node_stddev_pop_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_Node_Stddev_Samp_Fields = {
  __typename?: 'apps_node_stddev_samp_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_node" */
export type Apps_Node_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Node_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Node_Stream_Cursor_Value_Input = {
  checksum?: InputMaybe<Scalars['bigint']['input']>;
  elements?: InputMaybe<Scalars['jsonb']['input']>;
  messages?: InputMaybe<Scalars['jsonb']['input']>;
  nlu_routing?: InputMaybe<Scalars['jsonb']['input']>;
  node_content_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_data_other?: InputMaybe<Scalars['jsonb']['input']>;
  node_id?: InputMaybe<Scalars['bigint']['input']>;
  node_name?: InputMaybe<Scalars['String']['input']>;
  node_type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Apps_Node_Sum_Fields = {
  __typename?: 'apps_node_sum_fields';
  checksum?: Maybe<Scalars['bigint']['output']>;
  node_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "apps.node" */
export enum Apps_Node_Update_Column {
  /** column name */
  Elements = 'elements',
  /** column name */
  Messages = 'messages',
  /** column name */
  NluRouting = 'nlu_routing',
  /** column name */
  NodeContentOther = 'node_content_other',
  /** column name */
  NodeDataOther = 'node_data_other',
  /** column name */
  NodeName = 'node_name',
  /** column name */
  NodeType = 'node_type'
}

export type Apps_Node_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_Node_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_Node_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_Node_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_Node_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_Node_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Node_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Node_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Node_Var_Pop_Fields = {
  __typename?: 'apps_node_var_pop_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_Node_Var_Samp_Fields = {
  __typename?: 'apps_node_var_samp_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_Node_Variance_Fields = {
  __typename?: 'apps_node_variance_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  node_id?: Maybe<Scalars['Float']['output']>;
};

/** on_conflict condition type for table "apps" */
export type Apps_On_Conflict = {
  constraint: Apps_Constraint;
  update_columns?: Array<Apps_Update_Column>;
  where?: InputMaybe<Apps_Bool_Exp>;
};

/** Ordering options when selecting data from "apps". */
export type Apps_Order_By = {
  app_id?: InputMaybe<Order_By>;
  available_environments_aggregate?: InputMaybe<Environments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_workflow_id?: InputMaybe<Order_By>;
  deployment_history_aggregate?: InputMaybe<Deployments_Aggregate_Order_By>;
  graph?: InputMaybe<Graph_Order_By>;
  graph_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  version_history_aggregate?: InputMaybe<Versions_Aggregate_Order_By>;
};

/** primary key columns input for table: apps */
export type Apps_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "apps.rich_asset" */
export type Apps_Rich_Asset = {
  __typename?: 'apps_rich_asset';
  checksum?: Maybe<Scalars['bigint']['output']>;
  content_other?: Maybe<Scalars['jsonb']['output']>;
  dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  is_dynamic?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  node_instances: Array<Apps_Node_Instance>;
  /** An aggregate relationship */
  node_instances_aggregate: Apps_Node_Instance_Aggregate;
  options_json?: Maybe<Scalars['jsonb']['output']>;
  rich_asset_id: Scalars['bigint']['output'];
  rich_asset_type: Scalars['rich_asset_type_enum']['output'];
  source_node?: Maybe<Scalars['bigint']['output']>;
  source_var?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "apps.rich_asset" */
export type Apps_Rich_AssetContent_OtherArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.rich_asset" */
export type Apps_Rich_AssetNode_InstancesArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
};


/** columns and relationships of "apps.rich_asset" */
export type Apps_Rich_AssetNode_Instances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
};


/** columns and relationships of "apps.rich_asset" */
export type Apps_Rich_AssetOptions_JsonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "apps.rich_asset" */
export type Apps_Rich_Asset_Aggregate = {
  __typename?: 'apps_rich_asset_aggregate';
  aggregate?: Maybe<Apps_Rich_Asset_Aggregate_Fields>;
  nodes: Array<Apps_Rich_Asset>;
};

/** aggregate fields of "apps.rich_asset" */
export type Apps_Rich_Asset_Aggregate_Fields = {
  __typename?: 'apps_rich_asset_aggregate_fields';
  avg?: Maybe<Apps_Rich_Asset_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Rich_Asset_Max_Fields>;
  min?: Maybe<Apps_Rich_Asset_Min_Fields>;
  stddev?: Maybe<Apps_Rich_Asset_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Rich_Asset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Rich_Asset_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Rich_Asset_Sum_Fields>;
  var_pop?: Maybe<Apps_Rich_Asset_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Rich_Asset_Var_Samp_Fields>;
  variance?: Maybe<Apps_Rich_Asset_Variance_Fields>;
};


/** aggregate fields of "apps.rich_asset" */
export type Apps_Rich_Asset_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Rich_Asset_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_Rich_Asset_Append_Input = {
  content_other?: InputMaybe<Scalars['jsonb']['input']>;
  options_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Apps_Rich_Asset_Avg_Fields = {
  __typename?: 'apps_rich_asset_avg_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.rich_asset". All fields are combined with a logical 'AND'. */
export type Apps_Rich_Asset_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Rich_Asset_Bool_Exp>>;
  _not?: InputMaybe<Apps_Rich_Asset_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Rich_Asset_Bool_Exp>>;
  checksum?: InputMaybe<Bigint_Comparison_Exp>;
  content_other?: InputMaybe<Jsonb_Comparison_Exp>;
  dest_nodes?: InputMaybe<Bigint_Array_Comparison_Exp>;
  is_dynamic?: InputMaybe<Boolean_Comparison_Exp>;
  node_instances?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
  node_instances_aggregate?: InputMaybe<Apps_Node_Instance_Aggregate_Bool_Exp>;
  options_json?: InputMaybe<Jsonb_Comparison_Exp>;
  rich_asset_id?: InputMaybe<Bigint_Comparison_Exp>;
  rich_asset_type?: InputMaybe<Rich_Asset_Type_Enum_Comparison_Exp>;
  source_node?: InputMaybe<Bigint_Comparison_Exp>;
  source_var?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.rich_asset" */
export enum Apps_Rich_Asset_Constraint {
  /** unique or primary key constraint on columns "rich_asset_id" */
  PkRichAsset = 'pk_rich_asset'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_Rich_Asset_Delete_At_Path_Input = {
  content_other?: InputMaybe<Array<Scalars['String']['input']>>;
  options_json?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_Rich_Asset_Delete_Elem_Input = {
  content_other?: InputMaybe<Scalars['Int']['input']>;
  options_json?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_Rich_Asset_Delete_Key_Input = {
  content_other?: InputMaybe<Scalars['String']['input']>;
  options_json?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "apps.rich_asset" */
export type Apps_Rich_Asset_Inc_Input = {
  source_node?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.rich_asset" */
export type Apps_Rich_Asset_Insert_Input = {
  content_other?: InputMaybe<Scalars['jsonb']['input']>;
  is_dynamic?: InputMaybe<Scalars['Boolean']['input']>;
  node_instances?: InputMaybe<Apps_Node_Instance_Arr_Rel_Insert_Input>;
  options_json?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_type?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  source_node?: InputMaybe<Scalars['bigint']['input']>;
  source_var?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Apps_Rich_Asset_Max_Fields = {
  __typename?: 'apps_rich_asset_max_fields';
  checksum?: Maybe<Scalars['bigint']['output']>;
  dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  rich_asset_id?: Maybe<Scalars['bigint']['output']>;
  rich_asset_type?: Maybe<Scalars['rich_asset_type_enum']['output']>;
  source_node?: Maybe<Scalars['bigint']['output']>;
  source_var?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Apps_Rich_Asset_Min_Fields = {
  __typename?: 'apps_rich_asset_min_fields';
  checksum?: Maybe<Scalars['bigint']['output']>;
  dest_nodes?: Maybe<Array<Scalars['bigint']['output']>>;
  rich_asset_id?: Maybe<Scalars['bigint']['output']>;
  rich_asset_type?: Maybe<Scalars['rich_asset_type_enum']['output']>;
  source_node?: Maybe<Scalars['bigint']['output']>;
  source_var?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "apps.rich_asset" */
export type Apps_Rich_Asset_Mutation_Response = {
  __typename?: 'apps_rich_asset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Rich_Asset>;
};

/** input type for inserting object relation for remote table "apps.rich_asset" */
export type Apps_Rich_Asset_Obj_Rel_Insert_Input = {
  data: Apps_Rich_Asset_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Rich_Asset_On_Conflict>;
};

/** on_conflict condition type for table "apps.rich_asset" */
export type Apps_Rich_Asset_On_Conflict = {
  constraint: Apps_Rich_Asset_Constraint;
  update_columns?: Array<Apps_Rich_Asset_Update_Column>;
  where?: InputMaybe<Apps_Rich_Asset_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.rich_asset". */
export type Apps_Rich_Asset_Order_By = {
  checksum?: InputMaybe<Order_By>;
  content_other?: InputMaybe<Order_By>;
  dest_nodes?: InputMaybe<Order_By>;
  is_dynamic?: InputMaybe<Order_By>;
  node_instances_aggregate?: InputMaybe<Apps_Node_Instance_Aggregate_Order_By>;
  options_json?: InputMaybe<Order_By>;
  rich_asset_id?: InputMaybe<Order_By>;
  rich_asset_type?: InputMaybe<Order_By>;
  source_node?: InputMaybe<Order_By>;
  source_var?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.rich_asset */
export type Apps_Rich_Asset_Pk_Columns_Input = {
  rich_asset_id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_Rich_Asset_Prepend_Input = {
  content_other?: InputMaybe<Scalars['jsonb']['input']>;
  options_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.rich_asset" */
export enum Apps_Rich_Asset_Select_Column {
  /** column name */
  Checksum = 'checksum',
  /** column name */
  ContentOther = 'content_other',
  /** column name */
  DestNodes = 'dest_nodes',
  /** column name */
  IsDynamic = 'is_dynamic',
  /** column name */
  OptionsJson = 'options_json',
  /** column name */
  RichAssetId = 'rich_asset_id',
  /** column name */
  RichAssetType = 'rich_asset_type',
  /** column name */
  SourceNode = 'source_node',
  /** column name */
  SourceVar = 'source_var'
}

/** input type for updating data in table "apps.rich_asset" */
export type Apps_Rich_Asset_Set_Input = {
  content_other?: InputMaybe<Scalars['jsonb']['input']>;
  is_dynamic?: InputMaybe<Scalars['Boolean']['input']>;
  options_json?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_type?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  source_node?: InputMaybe<Scalars['bigint']['input']>;
  source_var?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Rich_Asset_Stddev_Fields = {
  __typename?: 'apps_rich_asset_stddev_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_Rich_Asset_Stddev_Pop_Fields = {
  __typename?: 'apps_rich_asset_stddev_pop_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_Rich_Asset_Stddev_Samp_Fields = {
  __typename?: 'apps_rich_asset_stddev_samp_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_rich_asset" */
export type Apps_Rich_Asset_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Rich_Asset_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Rich_Asset_Stream_Cursor_Value_Input = {
  checksum?: InputMaybe<Scalars['bigint']['input']>;
  content_other?: InputMaybe<Scalars['jsonb']['input']>;
  dest_nodes?: InputMaybe<Array<Scalars['bigint']['input']>>;
  is_dynamic?: InputMaybe<Scalars['Boolean']['input']>;
  options_json?: InputMaybe<Scalars['jsonb']['input']>;
  rich_asset_id?: InputMaybe<Scalars['bigint']['input']>;
  rich_asset_type?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  source_node?: InputMaybe<Scalars['bigint']['input']>;
  source_var?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Apps_Rich_Asset_Sum_Fields = {
  __typename?: 'apps_rich_asset_sum_fields';
  checksum?: Maybe<Scalars['bigint']['output']>;
  rich_asset_id?: Maybe<Scalars['bigint']['output']>;
  source_node?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "apps.rich_asset" */
export enum Apps_Rich_Asset_Update_Column {
  /** column name */
  ContentOther = 'content_other',
  /** column name */
  IsDynamic = 'is_dynamic',
  /** column name */
  OptionsJson = 'options_json',
  /** column name */
  RichAssetType = 'rich_asset_type',
  /** column name */
  SourceNode = 'source_node',
  /** column name */
  SourceVar = 'source_var'
}

export type Apps_Rich_Asset_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_Rich_Asset_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_Rich_Asset_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_Rich_Asset_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_Rich_Asset_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Rich_Asset_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_Rich_Asset_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Rich_Asset_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Rich_Asset_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Rich_Asset_Var_Pop_Fields = {
  __typename?: 'apps_rich_asset_var_pop_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_Rich_Asset_Var_Samp_Fields = {
  __typename?: 'apps_rich_asset_var_samp_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_Rich_Asset_Variance_Fields = {
  __typename?: 'apps_rich_asset_variance_fields';
  checksum?: Maybe<Scalars['Float']['output']>;
  rich_asset_id?: Maybe<Scalars['Float']['output']>;
  source_node?: Maybe<Scalars['Float']['output']>;
};

/** select columns of table "apps" */
export enum Apps_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DefaultWorkflowId = 'default_workflow_id',
  /** column name */
  GraphId = 'graph_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "apps" */
export type Apps_Set_Input = {
  app_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  default_workflow_id?: InputMaybe<Scalars['Int']['input']>;
  graph_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Stddev_Fields = {
  __typename?: 'apps_stddev_fields';
  default_workflow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_Stddev_Pop_Fields = {
  __typename?: 'apps_stddev_pop_fields';
  default_workflow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_Stddev_Samp_Fields = {
  __typename?: 'apps_stddev_samp_fields';
  default_workflow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps" */
export type Apps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  default_workflow_id?: InputMaybe<Scalars['Int']['input']>;
  graph_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Apps_Sum_Fields = {
  __typename?: 'apps_sum_fields';
  default_workflow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "apps" */
export enum Apps_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DefaultWorkflowId = 'default_workflow_id',
  /** column name */
  GraphId = 'graph_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Apps_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Bool_Exp;
};

/** columns and relationships of "apps_v2" */
export type Apps_V2 = {
  __typename?: 'apps_v2';
  default_workflow: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "apps_v2" */
export type Apps_V2_Aggregate = {
  __typename?: 'apps_v2_aggregate';
  aggregate?: Maybe<Apps_V2_Aggregate_Fields>;
  nodes: Array<Apps_V2>;
};

export type Apps_V2_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apps_V2_Aggregate_Bool_Exp_Count>;
};

export type Apps_V2_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_V2_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_V2_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps_v2" */
export type Apps_V2_Aggregate_Fields = {
  __typename?: 'apps_v2_aggregate_fields';
  avg?: Maybe<Apps_V2_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_V2_Max_Fields>;
  min?: Maybe<Apps_V2_Min_Fields>;
  stddev?: Maybe<Apps_V2_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_V2_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_V2_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_V2_Sum_Fields>;
  var_pop?: Maybe<Apps_V2_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_V2_Var_Samp_Fields>;
  variance?: Maybe<Apps_V2_Variance_Fields>;
};


/** aggregate fields of "apps_v2" */
export type Apps_V2_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_V2_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps_v2" */
export type Apps_V2_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_V2_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_V2_Max_Order_By>;
  min?: InputMaybe<Apps_V2_Min_Order_By>;
  stddev?: InputMaybe<Apps_V2_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_V2_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_V2_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_V2_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_V2_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_V2_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_V2_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "apps_v2" */
export type Apps_V2_Arr_Rel_Insert_Input = {
  data: Array<Apps_V2_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_V2_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_V2_Avg_Fields = {
  __typename?: 'apps_v2_avg_fields';
  default_workflow?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps_v2" */
export type Apps_V2_Avg_Order_By = {
  default_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps_v2". All fields are combined with a logical 'AND'. */
export type Apps_V2_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_V2_Bool_Exp>>;
  _not?: InputMaybe<Apps_V2_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_V2_Bool_Exp>>;
  default_workflow?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps_v2" */
export enum Apps_V2_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppsV2IdKey = 'apps_v2_id_key',
  /** unique or primary key constraint on columns "id" */
  AppsV2Pkey = 'apps_v2_pkey'
}

/** input type for incrementing numeric columns in table "apps_v2" */
export type Apps_V2_Inc_Input = {
  default_workflow?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "apps_v2" */
export type Apps_V2_Insert_Input = {
  default_workflow?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Apps_V2_Max_Fields = {
  __typename?: 'apps_v2_max_fields';
  default_workflow?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "apps_v2" */
export type Apps_V2_Max_Order_By = {
  default_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_V2_Min_Fields = {
  __typename?: 'apps_v2_min_fields';
  default_workflow?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "apps_v2" */
export type Apps_V2_Min_Order_By = {
  default_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps_v2" */
export type Apps_V2_Mutation_Response = {
  __typename?: 'apps_v2_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_V2>;
};

/** on_conflict condition type for table "apps_v2" */
export type Apps_V2_On_Conflict = {
  constraint: Apps_V2_Constraint;
  update_columns?: Array<Apps_V2_Update_Column>;
  where?: InputMaybe<Apps_V2_Bool_Exp>;
};

/** Ordering options when selecting data from "apps_v2". */
export type Apps_V2_Order_By = {
  default_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps_v2 */
export type Apps_V2_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "apps_v2" */
export enum Apps_V2_Select_Column {
  /** column name */
  DefaultWorkflow = 'default_workflow',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "apps_v2" */
export type Apps_V2_Set_Input = {
  default_workflow?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Apps_V2_Stddev_Fields = {
  __typename?: 'apps_v2_stddev_fields';
  default_workflow?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps_v2" */
export type Apps_V2_Stddev_Order_By = {
  default_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_V2_Stddev_Pop_Fields = {
  __typename?: 'apps_v2_stddev_pop_fields';
  default_workflow?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps_v2" */
export type Apps_V2_Stddev_Pop_Order_By = {
  default_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_V2_Stddev_Samp_Fields = {
  __typename?: 'apps_v2_stddev_samp_fields';
  default_workflow?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps_v2" */
export type Apps_V2_Stddev_Samp_Order_By = {
  default_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_v2" */
export type Apps_V2_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_V2_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_V2_Stream_Cursor_Value_Input = {
  default_workflow?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Apps_V2_Sum_Fields = {
  __typename?: 'apps_v2_sum_fields';
  default_workflow?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "apps_v2" */
export type Apps_V2_Sum_Order_By = {
  default_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "apps_v2" */
export enum Apps_V2_Update_Column {
  /** column name */
  DefaultWorkflow = 'default_workflow',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Apps_V2_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_V2_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_V2_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_V2_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_V2_Var_Pop_Fields = {
  __typename?: 'apps_v2_var_pop_fields';
  default_workflow?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps_v2" */
export type Apps_V2_Var_Pop_Order_By = {
  default_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_V2_Var_Samp_Fields = {
  __typename?: 'apps_v2_var_samp_fields';
  default_workflow?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps_v2" */
export type Apps_V2_Var_Samp_Order_By = {
  default_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_V2_Variance_Fields = {
  __typename?: 'apps_v2_variance_fields';
  default_workflow?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps_v2" */
export type Apps_V2_Variance_Order_By = {
  default_workflow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Apps_Var_Pop_Fields = {
  __typename?: 'apps_var_pop_fields';
  default_workflow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_Var_Samp_Fields = {
  __typename?: 'apps_var_samp_fields';
  default_workflow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_Variance_Fields = {
  __typename?: 'apps_variance_fields';
  default_workflow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "apps.version" */
export type Apps_Version = {
  __typename?: 'apps_version';
  /** An object relationship */
  app?: Maybe<Apps_App>;
  /** An object relationship */
  app_config?: Maybe<Apps_App_Config>;
  app_config_id?: Maybe<Scalars['Int']['output']>;
  app_id?: Maybe<Scalars['Int']['output']>;
  config?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  convert_roundtrip?: Maybe<Apps_Convert_Roundtrip>;
  /** An object relationship */
  customer_config?: Maybe<Apps_Customer_Config>;
  customer_config_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  deployments: Array<Apps_Deployment>;
  /** An aggregate relationship */
  deployments_aggregate: Apps_Deployment_Aggregate;
  /** An array relationship */
  environments: Array<Apps_Environment>;
  /** An aggregate relationship */
  environments_aggregate: Apps_Environment_Aggregate;
  /** An object relationship */
  nlu_file?: Maybe<Apps_Nlu_Files>;
  readonly_at?: Maybe<Scalars['timestamptz']['output']>;
  requirements?: Maybe<Scalars['jsonb']['output']>;
  search_endpoints?: Maybe<Array<Scalars['String']['output']>>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  version_display_datum?: Maybe<Apps_Version_Display_Data>;
  /** An array relationship */
  version_files: Array<Apps_Version_File>;
  /** An aggregate relationship */
  version_files_aggregate: Apps_Version_File_Aggregate;
  version_id: Scalars['bigint']['output'];
  version_name: Scalars['String']['output'];
  /** An array relationship */
  version_node_instances: Array<Apps_Version_Node_Instance>;
  /** An aggregate relationship */
  version_node_instances_aggregate: Apps_Version_Node_Instance_Aggregate;
};


/** columns and relationships of "apps.version" */
export type Apps_VersionConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.version" */
export type Apps_VersionDeploymentsArgs = {
  distinct_on?: InputMaybe<Array<Apps_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Deployment_Order_By>>;
  where?: InputMaybe<Apps_Deployment_Bool_Exp>;
};


/** columns and relationships of "apps.version" */
export type Apps_VersionDeployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Deployment_Order_By>>;
  where?: InputMaybe<Apps_Deployment_Bool_Exp>;
};


/** columns and relationships of "apps.version" */
export type Apps_VersionEnvironmentsArgs = {
  distinct_on?: InputMaybe<Array<Apps_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Environment_Order_By>>;
  where?: InputMaybe<Apps_Environment_Bool_Exp>;
};


/** columns and relationships of "apps.version" */
export type Apps_VersionEnvironments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Environment_Order_By>>;
  where?: InputMaybe<Apps_Environment_Bool_Exp>;
};


/** columns and relationships of "apps.version" */
export type Apps_VersionRequirementsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "apps.version" */
export type Apps_VersionVersion_FilesArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_File_Order_By>>;
  where?: InputMaybe<Apps_Version_File_Bool_Exp>;
};


/** columns and relationships of "apps.version" */
export type Apps_VersionVersion_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_File_Order_By>>;
  where?: InputMaybe<Apps_Version_File_Bool_Exp>;
};


/** columns and relationships of "apps.version" */
export type Apps_VersionVersion_Node_InstancesArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
};


/** columns and relationships of "apps.version" */
export type Apps_VersionVersion_Node_Instances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
};

/** aggregated selection of "apps.version" */
export type Apps_Version_Aggregate = {
  __typename?: 'apps_version_aggregate';
  aggregate?: Maybe<Apps_Version_Aggregate_Fields>;
  nodes: Array<Apps_Version>;
};

export type Apps_Version_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apps_Version_Aggregate_Bool_Exp_Count>;
};

export type Apps_Version_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_Version_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Version_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps.version" */
export type Apps_Version_Aggregate_Fields = {
  __typename?: 'apps_version_aggregate_fields';
  avg?: Maybe<Apps_Version_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Version_Max_Fields>;
  min?: Maybe<Apps_Version_Min_Fields>;
  stddev?: Maybe<Apps_Version_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Version_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Version_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Version_Sum_Fields>;
  var_pop?: Maybe<Apps_Version_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Version_Var_Samp_Fields>;
  variance?: Maybe<Apps_Version_Variance_Fields>;
};


/** aggregate fields of "apps.version" */
export type Apps_Version_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Version_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps.version" */
export type Apps_Version_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_Version_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_Version_Max_Order_By>;
  min?: InputMaybe<Apps_Version_Min_Order_By>;
  stddev?: InputMaybe<Apps_Version_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_Version_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_Version_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_Version_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_Version_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_Version_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_Version_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_Version_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  requirements?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "apps.version" */
export type Apps_Version_Arr_Rel_Insert_Input = {
  data: Array<Apps_Version_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Version_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_Version_Avg_Fields = {
  __typename?: 'apps_version_avg_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps.version" */
export type Apps_Version_Avg_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps.version". All fields are combined with a logical 'AND'. */
export type Apps_Version_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Version_Bool_Exp>>;
  _not?: InputMaybe<Apps_Version_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Version_Bool_Exp>>;
  app?: InputMaybe<Apps_App_Bool_Exp>;
  app_config?: InputMaybe<Apps_App_Config_Bool_Exp>;
  app_config_id?: InputMaybe<Int_Comparison_Exp>;
  app_id?: InputMaybe<Int_Comparison_Exp>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  convert_roundtrip?: InputMaybe<Apps_Convert_Roundtrip_Bool_Exp>;
  customer_config?: InputMaybe<Apps_Customer_Config_Bool_Exp>;
  customer_config_id?: InputMaybe<Int_Comparison_Exp>;
  deployments?: InputMaybe<Apps_Deployment_Bool_Exp>;
  deployments_aggregate?: InputMaybe<Apps_Deployment_Aggregate_Bool_Exp>;
  environments?: InputMaybe<Apps_Environment_Bool_Exp>;
  environments_aggregate?: InputMaybe<Apps_Environment_Aggregate_Bool_Exp>;
  nlu_file?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
  readonly_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  requirements?: InputMaybe<Jsonb_Comparison_Exp>;
  search_endpoints?: InputMaybe<String_Array_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version_display_datum?: InputMaybe<Apps_Version_Display_Data_Bool_Exp>;
  version_files?: InputMaybe<Apps_Version_File_Bool_Exp>;
  version_files_aggregate?: InputMaybe<Apps_Version_File_Aggregate_Bool_Exp>;
  version_id?: InputMaybe<Bigint_Comparison_Exp>;
  version_name?: InputMaybe<String_Comparison_Exp>;
  version_node_instances?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
  version_node_instances_aggregate?: InputMaybe<Apps_Version_Node_Instance_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "apps.version" */
export enum Apps_Version_Constraint {
  /** unique or primary key constraint on columns "version_id" */
  PkVersion = 'pk_version',
  /** unique or primary key constraint on columns "app_id", "version_name" */
  UqCustomerCustomerConfigId = 'uq_customer_customer_config_id'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_Version_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']['input']>>;
  requirements?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_Version_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']['input']>;
  requirements?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_Version_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']['input']>;
  requirements?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "apps.version_display_data" */
export type Apps_Version_Display_Data = {
  __typename?: 'apps_version_display_data';
  display_data: Scalars['jsonb']['output'];
  /** An object relationship */
  version: Apps_Version;
  version_id: Scalars['bigint']['output'];
};


/** columns and relationships of "apps.version_display_data" */
export type Apps_Version_Display_DataDisplay_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "apps.version_display_data" */
export type Apps_Version_Display_Data_Aggregate = {
  __typename?: 'apps_version_display_data_aggregate';
  aggregate?: Maybe<Apps_Version_Display_Data_Aggregate_Fields>;
  nodes: Array<Apps_Version_Display_Data>;
};

/** aggregate fields of "apps.version_display_data" */
export type Apps_Version_Display_Data_Aggregate_Fields = {
  __typename?: 'apps_version_display_data_aggregate_fields';
  avg?: Maybe<Apps_Version_Display_Data_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Version_Display_Data_Max_Fields>;
  min?: Maybe<Apps_Version_Display_Data_Min_Fields>;
  stddev?: Maybe<Apps_Version_Display_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Version_Display_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Version_Display_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Version_Display_Data_Sum_Fields>;
  var_pop?: Maybe<Apps_Version_Display_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Version_Display_Data_Var_Samp_Fields>;
  variance?: Maybe<Apps_Version_Display_Data_Variance_Fields>;
};


/** aggregate fields of "apps.version_display_data" */
export type Apps_Version_Display_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Version_Display_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apps_Version_Display_Data_Append_Input = {
  display_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Apps_Version_Display_Data_Avg_Fields = {
  __typename?: 'apps_version_display_data_avg_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "apps.version_display_data". All fields are combined with a logical 'AND'. */
export type Apps_Version_Display_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Version_Display_Data_Bool_Exp>>;
  _not?: InputMaybe<Apps_Version_Display_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Version_Display_Data_Bool_Exp>>;
  display_data?: InputMaybe<Jsonb_Comparison_Exp>;
  version?: InputMaybe<Apps_Version_Bool_Exp>;
  version_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.version_display_data" */
export enum Apps_Version_Display_Data_Constraint {
  /** unique or primary key constraint on columns "version_id" */
  PkVersionDisplayData = 'pk_version_display_data'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apps_Version_Display_Data_Delete_At_Path_Input = {
  display_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apps_Version_Display_Data_Delete_Elem_Input = {
  display_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apps_Version_Display_Data_Delete_Key_Input = {
  display_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "apps.version_display_data" */
export type Apps_Version_Display_Data_Inc_Input = {
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.version_display_data" */
export type Apps_Version_Display_Data_Insert_Input = {
  display_data?: InputMaybe<Scalars['jsonb']['input']>;
  version?: InputMaybe<Apps_Version_Obj_Rel_Insert_Input>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Apps_Version_Display_Data_Max_Fields = {
  __typename?: 'apps_version_display_data_max_fields';
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Apps_Version_Display_Data_Min_Fields = {
  __typename?: 'apps_version_display_data_min_fields';
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "apps.version_display_data" */
export type Apps_Version_Display_Data_Mutation_Response = {
  __typename?: 'apps_version_display_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Version_Display_Data>;
};

/** input type for inserting object relation for remote table "apps.version_display_data" */
export type Apps_Version_Display_Data_Obj_Rel_Insert_Input = {
  data: Apps_Version_Display_Data_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Version_Display_Data_On_Conflict>;
};

/** on_conflict condition type for table "apps.version_display_data" */
export type Apps_Version_Display_Data_On_Conflict = {
  constraint: Apps_Version_Display_Data_Constraint;
  update_columns?: Array<Apps_Version_Display_Data_Update_Column>;
  where?: InputMaybe<Apps_Version_Display_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.version_display_data". */
export type Apps_Version_Display_Data_Order_By = {
  display_data?: InputMaybe<Order_By>;
  version?: InputMaybe<Apps_Version_Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.version_display_data */
export type Apps_Version_Display_Data_Pk_Columns_Input = {
  version_id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_Version_Display_Data_Prepend_Input = {
  display_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.version_display_data" */
export enum Apps_Version_Display_Data_Select_Column {
  /** column name */
  DisplayData = 'display_data',
  /** column name */
  VersionId = 'version_id'
}

/** input type for updating data in table "apps.version_display_data" */
export type Apps_Version_Display_Data_Set_Input = {
  display_data?: InputMaybe<Scalars['jsonb']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Version_Display_Data_Stddev_Fields = {
  __typename?: 'apps_version_display_data_stddev_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Apps_Version_Display_Data_Stddev_Pop_Fields = {
  __typename?: 'apps_version_display_data_stddev_pop_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Apps_Version_Display_Data_Stddev_Samp_Fields = {
  __typename?: 'apps_version_display_data_stddev_samp_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "apps_version_display_data" */
export type Apps_Version_Display_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Version_Display_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Version_Display_Data_Stream_Cursor_Value_Input = {
  display_data?: InputMaybe<Scalars['jsonb']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Apps_Version_Display_Data_Sum_Fields = {
  __typename?: 'apps_version_display_data_sum_fields';
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "apps.version_display_data" */
export enum Apps_Version_Display_Data_Update_Column {
  /** column name */
  DisplayData = 'display_data',
  /** column name */
  VersionId = 'version_id'
}

export type Apps_Version_Display_Data_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_Version_Display_Data_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_Version_Display_Data_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_Version_Display_Data_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_Version_Display_Data_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Version_Display_Data_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_Version_Display_Data_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Version_Display_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Version_Display_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Version_Display_Data_Var_Pop_Fields = {
  __typename?: 'apps_version_display_data_var_pop_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Apps_Version_Display_Data_Var_Samp_Fields = {
  __typename?: 'apps_version_display_data_var_samp_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Apps_Version_Display_Data_Variance_Fields = {
  __typename?: 'apps_version_display_data_variance_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "apps.version_file" */
export type Apps_Version_File = {
  __typename?: 'apps_version_file';
  action_node_runtime?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  file: Apps_File;
  file_id: Scalars['bigint']['output'];
  filename: Scalars['String']['output'];
  parent_path: Scalars['String']['output'];
  /** An object relationship */
  version: Apps_Version;
  version_id: Scalars['bigint']['output'];
};

/** aggregated selection of "apps.version_file" */
export type Apps_Version_File_Aggregate = {
  __typename?: 'apps_version_file_aggregate';
  aggregate?: Maybe<Apps_Version_File_Aggregate_Fields>;
  nodes: Array<Apps_Version_File>;
};

export type Apps_Version_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apps_Version_File_Aggregate_Bool_Exp_Count>;
};

export type Apps_Version_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_Version_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Version_File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps.version_file" */
export type Apps_Version_File_Aggregate_Fields = {
  __typename?: 'apps_version_file_aggregate_fields';
  avg?: Maybe<Apps_Version_File_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Version_File_Max_Fields>;
  min?: Maybe<Apps_Version_File_Min_Fields>;
  stddev?: Maybe<Apps_Version_File_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Version_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Version_File_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Version_File_Sum_Fields>;
  var_pop?: Maybe<Apps_Version_File_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Version_File_Var_Samp_Fields>;
  variance?: Maybe<Apps_Version_File_Variance_Fields>;
};


/** aggregate fields of "apps.version_file" */
export type Apps_Version_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Version_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps.version_file" */
export type Apps_Version_File_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_Version_File_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_Version_File_Max_Order_By>;
  min?: InputMaybe<Apps_Version_File_Min_Order_By>;
  stddev?: InputMaybe<Apps_Version_File_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_Version_File_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_Version_File_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_Version_File_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_Version_File_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_Version_File_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_Version_File_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "apps.version_file" */
export type Apps_Version_File_Arr_Rel_Insert_Input = {
  data: Array<Apps_Version_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Version_File_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_Version_File_Avg_Fields = {
  __typename?: 'apps_version_file_avg_fields';
  file_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps.version_file" */
export type Apps_Version_File_Avg_Order_By = {
  file_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps.version_file". All fields are combined with a logical 'AND'. */
export type Apps_Version_File_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Version_File_Bool_Exp>>;
  _not?: InputMaybe<Apps_Version_File_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Version_File_Bool_Exp>>;
  action_node_runtime?: InputMaybe<String_Comparison_Exp>;
  file?: InputMaybe<Apps_File_Bool_Exp>;
  file_id?: InputMaybe<Bigint_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  parent_path?: InputMaybe<String_Comparison_Exp>;
  version?: InputMaybe<Apps_Version_Bool_Exp>;
  version_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.version_file" */
export enum Apps_Version_File_Constraint {
  /** unique or primary key constraint on columns "version_id", "filename", "parent_path" */
  PkVersionFile = 'pk_version_file'
}

/** input type for incrementing numeric columns in table "apps.version_file" */
export type Apps_Version_File_Inc_Input = {
  file_id?: InputMaybe<Scalars['bigint']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.version_file" */
export type Apps_Version_File_Insert_Input = {
  action_node_runtime?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Apps_File_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['bigint']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  parent_path?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Apps_Version_Obj_Rel_Insert_Input>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Apps_Version_File_Max_Fields = {
  __typename?: 'apps_version_file_max_fields';
  action_node_runtime?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['bigint']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  parent_path?: Maybe<Scalars['String']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "apps.version_file" */
export type Apps_Version_File_Max_Order_By = {
  action_node_runtime?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  parent_path?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_Version_File_Min_Fields = {
  __typename?: 'apps_version_file_min_fields';
  action_node_runtime?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['bigint']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  parent_path?: Maybe<Scalars['String']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "apps.version_file" */
export type Apps_Version_File_Min_Order_By = {
  action_node_runtime?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  parent_path?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps.version_file" */
export type Apps_Version_File_Mutation_Response = {
  __typename?: 'apps_version_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Version_File>;
};

/** on_conflict condition type for table "apps.version_file" */
export type Apps_Version_File_On_Conflict = {
  constraint: Apps_Version_File_Constraint;
  update_columns?: Array<Apps_Version_File_Update_Column>;
  where?: InputMaybe<Apps_Version_File_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.version_file". */
export type Apps_Version_File_Order_By = {
  action_node_runtime?: InputMaybe<Order_By>;
  file?: InputMaybe<Apps_File_Order_By>;
  file_id?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  parent_path?: InputMaybe<Order_By>;
  version?: InputMaybe<Apps_Version_Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.version_file */
export type Apps_Version_File_Pk_Columns_Input = {
  filename: Scalars['String']['input'];
  parent_path: Scalars['String']['input'];
  version_id: Scalars['bigint']['input'];
};

/** select columns of table "apps.version_file" */
export enum Apps_Version_File_Select_Column {
  /** column name */
  ActionNodeRuntime = 'action_node_runtime',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Filename = 'filename',
  /** column name */
  ParentPath = 'parent_path',
  /** column name */
  VersionId = 'version_id'
}

/** input type for updating data in table "apps.version_file" */
export type Apps_Version_File_Set_Input = {
  action_node_runtime?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['bigint']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  parent_path?: InputMaybe<Scalars['String']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Version_File_Stddev_Fields = {
  __typename?: 'apps_version_file_stddev_fields';
  file_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps.version_file" */
export type Apps_Version_File_Stddev_Order_By = {
  file_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_Version_File_Stddev_Pop_Fields = {
  __typename?: 'apps_version_file_stddev_pop_fields';
  file_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps.version_file" */
export type Apps_Version_File_Stddev_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_Version_File_Stddev_Samp_Fields = {
  __typename?: 'apps_version_file_stddev_samp_fields';
  file_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps.version_file" */
export type Apps_Version_File_Stddev_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_version_file" */
export type Apps_Version_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Version_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Version_File_Stream_Cursor_Value_Input = {
  action_node_runtime?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['bigint']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  parent_path?: InputMaybe<Scalars['String']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Apps_Version_File_Sum_Fields = {
  __typename?: 'apps_version_file_sum_fields';
  file_id?: Maybe<Scalars['bigint']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "apps.version_file" */
export type Apps_Version_File_Sum_Order_By = {
  file_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** update columns of table "apps.version_file" */
export enum Apps_Version_File_Update_Column {
  /** column name */
  ActionNodeRuntime = 'action_node_runtime',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Filename = 'filename',
  /** column name */
  ParentPath = 'parent_path',
  /** column name */
  VersionId = 'version_id'
}

export type Apps_Version_File_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Version_File_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Version_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Version_File_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Version_File_Var_Pop_Fields = {
  __typename?: 'apps_version_file_var_pop_fields';
  file_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps.version_file" */
export type Apps_Version_File_Var_Pop_Order_By = {
  file_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_Version_File_Var_Samp_Fields = {
  __typename?: 'apps_version_file_var_samp_fields';
  file_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps.version_file" */
export type Apps_Version_File_Var_Samp_Order_By = {
  file_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_Version_File_Variance_Fields = {
  __typename?: 'apps_version_file_variance_fields';
  file_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps.version_file" */
export type Apps_Version_File_Variance_Order_By = {
  file_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "apps.version" */
export type Apps_Version_Inc_Input = {
  app_config_id?: InputMaybe<Scalars['Int']['input']>;
  app_id?: InputMaybe<Scalars['Int']['input']>;
  customer_config_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "apps.version" */
export type Apps_Version_Insert_Input = {
  app?: InputMaybe<Apps_App_Obj_Rel_Insert_Input>;
  app_config?: InputMaybe<Apps_App_Config_Obj_Rel_Insert_Input>;
  app_config_id?: InputMaybe<Scalars['Int']['input']>;
  app_id?: InputMaybe<Scalars['Int']['input']>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  convert_roundtrip?: InputMaybe<Apps_Convert_Roundtrip_Obj_Rel_Insert_Input>;
  customer_config?: InputMaybe<Apps_Customer_Config_Obj_Rel_Insert_Input>;
  customer_config_id?: InputMaybe<Scalars['Int']['input']>;
  deployments?: InputMaybe<Apps_Deployment_Arr_Rel_Insert_Input>;
  environments?: InputMaybe<Apps_Environment_Arr_Rel_Insert_Input>;
  nlu_file?: InputMaybe<Apps_Nlu_Files_Obj_Rel_Insert_Input>;
  readonly_at?: InputMaybe<Scalars['timestamptz']['input']>;
  requirements?: InputMaybe<Scalars['jsonb']['input']>;
  search_endpoints?: InputMaybe<Array<Scalars['String']['input']>>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_display_datum?: InputMaybe<Apps_Version_Display_Data_Obj_Rel_Insert_Input>;
  version_files?: InputMaybe<Apps_Version_File_Arr_Rel_Insert_Input>;
  version_name?: InputMaybe<Scalars['String']['input']>;
  version_node_instances?: InputMaybe<Apps_Version_Node_Instance_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Apps_Version_Max_Fields = {
  __typename?: 'apps_version_max_fields';
  app_config_id?: Maybe<Scalars['Int']['output']>;
  app_id?: Maybe<Scalars['Int']['output']>;
  customer_config_id?: Maybe<Scalars['Int']['output']>;
  readonly_at?: Maybe<Scalars['timestamptz']['output']>;
  search_endpoints?: Maybe<Array<Scalars['String']['output']>>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
  version_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "apps.version" */
export type Apps_Version_Max_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  readonly_at?: InputMaybe<Order_By>;
  search_endpoints?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
  version_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_Version_Min_Fields = {
  __typename?: 'apps_version_min_fields';
  app_config_id?: Maybe<Scalars['Int']['output']>;
  app_id?: Maybe<Scalars['Int']['output']>;
  customer_config_id?: Maybe<Scalars['Int']['output']>;
  readonly_at?: Maybe<Scalars['timestamptz']['output']>;
  search_endpoints?: Maybe<Array<Scalars['String']['output']>>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
  version_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "apps.version" */
export type Apps_Version_Min_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  readonly_at?: InputMaybe<Order_By>;
  search_endpoints?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
  version_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps.version" */
export type Apps_Version_Mutation_Response = {
  __typename?: 'apps_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Version>;
};

/** columns and relationships of "apps.version_node_instance" */
export type Apps_Version_Node_Instance = {
  __typename?: 'apps_version_node_instance';
  group_name?: Maybe<Scalars['String']['output']>;
  group_order?: Maybe<Scalars['Int']['output']>;
  is_workflow_start: Scalars['Boolean']['output'];
  /** An object relationship */
  node_instance: Apps_Node_Instance;
  node_instance_id: Scalars['bigint']['output'];
  node_number: Scalars['bigint']['output'];
  /** An object relationship */
  outgoing_nodes?: Maybe<Apps_Node_Outgoing_Edge_View>;
  /** An object relationship */
  version: Apps_Version;
  version_id: Scalars['bigint']['output'];
  workflow_name: Scalars['String']['output'];
};

/** aggregated selection of "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Aggregate = {
  __typename?: 'apps_version_node_instance_aggregate';
  aggregate?: Maybe<Apps_Version_Node_Instance_Aggregate_Fields>;
  nodes: Array<Apps_Version_Node_Instance>;
};

export type Apps_Version_Node_Instance_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Apps_Version_Node_Instance_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Apps_Version_Node_Instance_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Apps_Version_Node_Instance_Aggregate_Bool_Exp_Count>;
};

export type Apps_Version_Node_Instance_Aggregate_Bool_Exp_Bool_And = {
  arguments: Apps_Version_Node_Instance_Select_Column_Apps_Version_Node_Instance_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Apps_Version_Node_Instance_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Apps_Version_Node_Instance_Select_Column_Apps_Version_Node_Instance_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Apps_Version_Node_Instance_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apps_Version_Node_Instance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Aggregate_Fields = {
  __typename?: 'apps_version_node_instance_aggregate_fields';
  avg?: Maybe<Apps_Version_Node_Instance_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Apps_Version_Node_Instance_Max_Fields>;
  min?: Maybe<Apps_Version_Node_Instance_Min_Fields>;
  stddev?: Maybe<Apps_Version_Node_Instance_Stddev_Fields>;
  stddev_pop?: Maybe<Apps_Version_Node_Instance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apps_Version_Node_Instance_Stddev_Samp_Fields>;
  sum?: Maybe<Apps_Version_Node_Instance_Sum_Fields>;
  var_pop?: Maybe<Apps_Version_Node_Instance_Var_Pop_Fields>;
  var_samp?: Maybe<Apps_Version_Node_Instance_Var_Samp_Fields>;
  variance?: Maybe<Apps_Version_Node_Instance_Variance_Fields>;
};


/** aggregate fields of "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Version_Node_Instance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Aggregate_Order_By = {
  avg?: InputMaybe<Apps_Version_Node_Instance_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Apps_Version_Node_Instance_Max_Order_By>;
  min?: InputMaybe<Apps_Version_Node_Instance_Min_Order_By>;
  stddev?: InputMaybe<Apps_Version_Node_Instance_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Apps_Version_Node_Instance_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Apps_Version_Node_Instance_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Apps_Version_Node_Instance_Sum_Order_By>;
  var_pop?: InputMaybe<Apps_Version_Node_Instance_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Apps_Version_Node_Instance_Var_Samp_Order_By>;
  variance?: InputMaybe<Apps_Version_Node_Instance_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Arr_Rel_Insert_Input = {
  data: Array<Apps_Version_Node_Instance_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Version_Node_Instance_On_Conflict>;
};

/** aggregate avg on columns */
export type Apps_Version_Node_Instance_Avg_Fields = {
  __typename?: 'apps_version_node_instance_avg_fields';
  group_order?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Avg_Order_By = {
  group_order?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apps.version_node_instance". All fields are combined with a logical 'AND'. */
export type Apps_Version_Node_Instance_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Version_Node_Instance_Bool_Exp>>;
  _not?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Version_Node_Instance_Bool_Exp>>;
  group_name?: InputMaybe<String_Comparison_Exp>;
  group_order?: InputMaybe<Int_Comparison_Exp>;
  is_workflow_start?: InputMaybe<Boolean_Comparison_Exp>;
  node_instance?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
  node_instance_id?: InputMaybe<Bigint_Comparison_Exp>;
  node_number?: InputMaybe<Bigint_Comparison_Exp>;
  outgoing_nodes?: InputMaybe<Apps_Node_Outgoing_Edge_View_Bool_Exp>;
  version?: InputMaybe<Apps_Version_Bool_Exp>;
  version_id?: InputMaybe<Bigint_Comparison_Exp>;
  workflow_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps.version_node_instance" */
export enum Apps_Version_Node_Instance_Constraint {
  /** unique or primary key constraint on columns "version_id", "node_number" */
  PkVersionNodeInstance = 'pk_version_node_instance',
  /** unique or primary key constraint on columns "version_id", "group_order", "group_name" */
  UqVersionNodeInstanceVersionIdGroupNameGroupOrder = 'uq_version_node_instance_version_id_group_name_group_order',
  /** unique or primary key constraint on columns "version_id", "node_number" */
  UqVersionNodeInstanceVersionIdNodeNumber = 'uq_version_node_instance_version_id_node_number',
  /** unique or primary key constraint on columns "version_id", "workflow_name" */
  UqVersionNodeInstanceVersionIdWorkflowStart = 'uq_version_node_instance_version_id_workflow_start'
}

/** input type for incrementing numeric columns in table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Inc_Input = {
  group_order?: InputMaybe<Scalars['Int']['input']>;
  node_instance_id?: InputMaybe<Scalars['bigint']['input']>;
  node_number?: InputMaybe<Scalars['bigint']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Insert_Input = {
  group_name?: InputMaybe<Scalars['String']['input']>;
  group_order?: InputMaybe<Scalars['Int']['input']>;
  is_workflow_start?: InputMaybe<Scalars['Boolean']['input']>;
  node_instance?: InputMaybe<Apps_Node_Instance_Obj_Rel_Insert_Input>;
  node_instance_id?: InputMaybe<Scalars['bigint']['input']>;
  node_number?: InputMaybe<Scalars['bigint']['input']>;
  outgoing_nodes?: InputMaybe<Apps_Node_Outgoing_Edge_View_Obj_Rel_Insert_Input>;
  version?: InputMaybe<Apps_Version_Obj_Rel_Insert_Input>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
  workflow_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Apps_Version_Node_Instance_Max_Fields = {
  __typename?: 'apps_version_node_instance_max_fields';
  group_name?: Maybe<Scalars['String']['output']>;
  group_order?: Maybe<Scalars['Int']['output']>;
  node_instance_id?: Maybe<Scalars['bigint']['output']>;
  node_number?: Maybe<Scalars['bigint']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
  workflow_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Max_Order_By = {
  group_name?: InputMaybe<Order_By>;
  group_order?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
  workflow_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Apps_Version_Node_Instance_Min_Fields = {
  __typename?: 'apps_version_node_instance_min_fields';
  group_name?: Maybe<Scalars['String']['output']>;
  group_order?: Maybe<Scalars['Int']['output']>;
  node_instance_id?: Maybe<Scalars['bigint']['output']>;
  node_number?: Maybe<Scalars['bigint']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
  workflow_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Min_Order_By = {
  group_name?: InputMaybe<Order_By>;
  group_order?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
  workflow_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Mutation_Response = {
  __typename?: 'apps_version_node_instance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Apps_Version_Node_Instance>;
};

/** on_conflict condition type for table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_On_Conflict = {
  constraint: Apps_Version_Node_Instance_Constraint;
  update_columns?: Array<Apps_Version_Node_Instance_Update_Column>;
  where?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.version_node_instance". */
export type Apps_Version_Node_Instance_Order_By = {
  group_name?: InputMaybe<Order_By>;
  group_order?: InputMaybe<Order_By>;
  is_workflow_start?: InputMaybe<Order_By>;
  node_instance?: InputMaybe<Apps_Node_Instance_Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  outgoing_nodes?: InputMaybe<Apps_Node_Outgoing_Edge_View_Order_By>;
  version?: InputMaybe<Apps_Version_Order_By>;
  version_id?: InputMaybe<Order_By>;
  workflow_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apps.version_node_instance */
export type Apps_Version_Node_Instance_Pk_Columns_Input = {
  node_number: Scalars['bigint']['input'];
  version_id: Scalars['bigint']['input'];
};

/** select columns of table "apps.version_node_instance" */
export enum Apps_Version_Node_Instance_Select_Column {
  /** column name */
  GroupName = 'group_name',
  /** column name */
  GroupOrder = 'group_order',
  /** column name */
  IsWorkflowStart = 'is_workflow_start',
  /** column name */
  NodeInstanceId = 'node_instance_id',
  /** column name */
  NodeNumber = 'node_number',
  /** column name */
  VersionId = 'version_id',
  /** column name */
  WorkflowName = 'workflow_name'
}

/** select "apps_version_node_instance_aggregate_bool_exp_bool_and_arguments_columns" columns of table "apps.version_node_instance" */
export enum Apps_Version_Node_Instance_Select_Column_Apps_Version_Node_Instance_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsWorkflowStart = 'is_workflow_start'
}

/** select "apps_version_node_instance_aggregate_bool_exp_bool_or_arguments_columns" columns of table "apps.version_node_instance" */
export enum Apps_Version_Node_Instance_Select_Column_Apps_Version_Node_Instance_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsWorkflowStart = 'is_workflow_start'
}

/** input type for updating data in table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Set_Input = {
  group_name?: InputMaybe<Scalars['String']['input']>;
  group_order?: InputMaybe<Scalars['Int']['input']>;
  is_workflow_start?: InputMaybe<Scalars['Boolean']['input']>;
  node_instance_id?: InputMaybe<Scalars['bigint']['input']>;
  node_number?: InputMaybe<Scalars['bigint']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
  workflow_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Version_Node_Instance_Stddev_Fields = {
  __typename?: 'apps_version_node_instance_stddev_fields';
  group_order?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Stddev_Order_By = {
  group_order?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_Version_Node_Instance_Stddev_Pop_Fields = {
  __typename?: 'apps_version_node_instance_stddev_pop_fields';
  group_order?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Stddev_Pop_Order_By = {
  group_order?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_Version_Node_Instance_Stddev_Samp_Fields = {
  __typename?: 'apps_version_node_instance_stddev_samp_fields';
  group_order?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Stddev_Samp_Order_By = {
  group_order?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_version_node_instance" */
export type Apps_Version_Node_Instance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Version_Node_Instance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Version_Node_Instance_Stream_Cursor_Value_Input = {
  group_name?: InputMaybe<Scalars['String']['input']>;
  group_order?: InputMaybe<Scalars['Int']['input']>;
  is_workflow_start?: InputMaybe<Scalars['Boolean']['input']>;
  node_instance_id?: InputMaybe<Scalars['bigint']['input']>;
  node_number?: InputMaybe<Scalars['bigint']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
  workflow_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Apps_Version_Node_Instance_Sum_Fields = {
  __typename?: 'apps_version_node_instance_sum_fields';
  group_order?: Maybe<Scalars['Int']['output']>;
  node_instance_id?: Maybe<Scalars['bigint']['output']>;
  node_number?: Maybe<Scalars['bigint']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Sum_Order_By = {
  group_order?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** update columns of table "apps.version_node_instance" */
export enum Apps_Version_Node_Instance_Update_Column {
  /** column name */
  GroupName = 'group_name',
  /** column name */
  GroupOrder = 'group_order',
  /** column name */
  IsWorkflowStart = 'is_workflow_start',
  /** column name */
  NodeInstanceId = 'node_instance_id',
  /** column name */
  NodeNumber = 'node_number',
  /** column name */
  VersionId = 'version_id',
  /** column name */
  WorkflowName = 'workflow_name'
}

export type Apps_Version_Node_Instance_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Version_Node_Instance_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Version_Node_Instance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Version_Node_Instance_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Version_Node_Instance_Var_Pop_Fields = {
  __typename?: 'apps_version_node_instance_var_pop_fields';
  group_order?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Var_Pop_Order_By = {
  group_order?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_Version_Node_Instance_Var_Samp_Fields = {
  __typename?: 'apps_version_node_instance_var_samp_fields';
  group_order?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Var_Samp_Order_By = {
  group_order?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_Version_Node_Instance_Variance_Fields = {
  __typename?: 'apps_version_node_instance_variance_fields';
  group_order?: Maybe<Scalars['Float']['output']>;
  node_instance_id?: Maybe<Scalars['Float']['output']>;
  node_number?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps.version_node_instance" */
export type Apps_Version_Node_Instance_Variance_Order_By = {
  group_order?: InputMaybe<Order_By>;
  node_instance_id?: InputMaybe<Order_By>;
  node_number?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "apps.version" */
export type Apps_Version_Obj_Rel_Insert_Input = {
  data: Apps_Version_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_Version_On_Conflict>;
};

/** on_conflict condition type for table "apps.version" */
export type Apps_Version_On_Conflict = {
  constraint: Apps_Version_Constraint;
  update_columns?: Array<Apps_Version_Update_Column>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};

/** Ordering options when selecting data from "apps.version". */
export type Apps_Version_Order_By = {
  app?: InputMaybe<Apps_App_Order_By>;
  app_config?: InputMaybe<Apps_App_Config_Order_By>;
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  config?: InputMaybe<Order_By>;
  convert_roundtrip?: InputMaybe<Apps_Convert_Roundtrip_Order_By>;
  customer_config?: InputMaybe<Apps_Customer_Config_Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  deployments_aggregate?: InputMaybe<Apps_Deployment_Aggregate_Order_By>;
  environments_aggregate?: InputMaybe<Apps_Environment_Aggregate_Order_By>;
  nlu_file?: InputMaybe<Apps_Nlu_Files_Order_By>;
  readonly_at?: InputMaybe<Order_By>;
  requirements?: InputMaybe<Order_By>;
  search_endpoints?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version_display_datum?: InputMaybe<Apps_Version_Display_Data_Order_By>;
  version_files_aggregate?: InputMaybe<Apps_Version_File_Aggregate_Order_By>;
  version_id?: InputMaybe<Order_By>;
  version_name?: InputMaybe<Order_By>;
  version_node_instances_aggregate?: InputMaybe<Apps_Version_Node_Instance_Aggregate_Order_By>;
};

/** primary key columns input for table: apps.version */
export type Apps_Version_Pk_Columns_Input = {
  version_id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apps_Version_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  requirements?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "apps.version" */
export enum Apps_Version_Select_Column {
  /** column name */
  AppConfigId = 'app_config_id',
  /** column name */
  AppId = 'app_id',
  /** column name */
  Config = 'config',
  /** column name */
  CustomerConfigId = 'customer_config_id',
  /** column name */
  ReadonlyAt = 'readonly_at',
  /** column name */
  Requirements = 'requirements',
  /** column name */
  SearchEndpoints = 'search_endpoints',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionId = 'version_id',
  /** column name */
  VersionName = 'version_name'
}

/** input type for updating data in table "apps.version" */
export type Apps_Version_Set_Input = {
  app_config_id?: InputMaybe<Scalars['Int']['input']>;
  app_id?: InputMaybe<Scalars['Int']['input']>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  customer_config_id?: InputMaybe<Scalars['Int']['input']>;
  readonly_at?: InputMaybe<Scalars['timestamptz']['input']>;
  requirements?: InputMaybe<Scalars['jsonb']['input']>;
  search_endpoints?: InputMaybe<Array<Scalars['String']['input']>>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Apps_Version_Stddev_Fields = {
  __typename?: 'apps_version_stddev_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "apps.version" */
export type Apps_Version_Stddev_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apps_Version_Stddev_Pop_Fields = {
  __typename?: 'apps_version_stddev_pop_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "apps.version" */
export type Apps_Version_Stddev_Pop_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apps_Version_Stddev_Samp_Fields = {
  __typename?: 'apps_version_stddev_samp_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "apps.version" */
export type Apps_Version_Stddev_Samp_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "apps_version" */
export type Apps_Version_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Version_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Version_Stream_Cursor_Value_Input = {
  app_config_id?: InputMaybe<Scalars['Int']['input']>;
  app_id?: InputMaybe<Scalars['Int']['input']>;
  config?: InputMaybe<Scalars['jsonb']['input']>;
  customer_config_id?: InputMaybe<Scalars['Int']['input']>;
  readonly_at?: InputMaybe<Scalars['timestamptz']['input']>;
  requirements?: InputMaybe<Scalars['jsonb']['input']>;
  search_endpoints?: InputMaybe<Array<Scalars['String']['input']>>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  version_id?: InputMaybe<Scalars['bigint']['input']>;
  version_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Apps_Version_Sum_Fields = {
  __typename?: 'apps_version_sum_fields';
  app_config_id?: Maybe<Scalars['Int']['output']>;
  app_id?: Maybe<Scalars['Int']['output']>;
  customer_config_id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "apps.version" */
export type Apps_Version_Sum_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** update columns of table "apps.version" */
export enum Apps_Version_Update_Column {
  /** column name */
  AppConfigId = 'app_config_id',
  /** column name */
  AppId = 'app_id',
  /** column name */
  Config = 'config',
  /** column name */
  CustomerConfigId = 'customer_config_id',
  /** column name */
  ReadonlyAt = 'readonly_at',
  /** column name */
  Requirements = 'requirements',
  /** column name */
  SearchEndpoints = 'search_endpoints',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VersionName = 'version_name'
}

export type Apps_Version_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apps_Version_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apps_Version_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apps_Version_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apps_Version_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apps_Version_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apps_Version_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Version_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apps_Version_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Apps_Version_Var_Pop_Fields = {
  __typename?: 'apps_version_var_pop_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "apps.version" */
export type Apps_Version_Var_Pop_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apps_Version_Var_Samp_Fields = {
  __typename?: 'apps_version_var_samp_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "apps.version" */
export type Apps_Version_Var_Samp_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Apps_Version_Variance_Fields = {
  __typename?: 'apps_version_variance_fields';
  app_config_id?: Maybe<Scalars['Float']['output']>;
  app_id?: Maybe<Scalars['Float']['output']>;
  customer_config_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "apps.version" */
export type Apps_Version_Variance_Order_By = {
  app_config_id?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  customer_config_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "auth_providers" */
export type Auth_Providers = {
  __typename?: 'auth_providers';
  value: Scalars['String']['output'];
};

/** aggregated selection of "auth_providers" */
export type Auth_Providers_Aggregate = {
  __typename?: 'auth_providers_aggregate';
  aggregate?: Maybe<Auth_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Providers>;
};

/** aggregate fields of "auth_providers" */
export type Auth_Providers_Aggregate_Fields = {
  __typename?: 'auth_providers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Providers_Max_Fields>;
  min?: Maybe<Auth_Providers_Min_Fields>;
};


/** aggregate fields of "auth_providers" */
export type Auth_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth_providers". All fields are combined with a logical 'AND'. */
export type Auth_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Providers_Bool_Exp>>;
  _not?: InputMaybe<Auth_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Providers_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth_providers" */
export enum Auth_Providers_Constraint {
  /** unique or primary key constraint on columns "value" */
  AuthOptionsPkey = 'auth_options_pkey'
}

export enum Auth_Providers_Enum {
  Google = 'google',
  Pypestream = 'pypestream'
}

/** Boolean expression to compare columns of type "auth_providers_enum". All fields are combined with logical 'AND'. */
export type Auth_Providers_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Auth_Providers_Enum>;
  _in?: InputMaybe<Array<Auth_Providers_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Auth_Providers_Enum>;
  _nin?: InputMaybe<Array<Auth_Providers_Enum>>;
};

/** input type for inserting data into table "auth_providers" */
export type Auth_Providers_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Auth_Providers_Max_Fields = {
  __typename?: 'auth_providers_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Auth_Providers_Min_Fields = {
  __typename?: 'auth_providers_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth_providers" */
export type Auth_Providers_Mutation_Response = {
  __typename?: 'auth_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Providers>;
};

/** input type for inserting object relation for remote table "auth_providers" */
export type Auth_Providers_Obj_Rel_Insert_Input = {
  data: Auth_Providers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Providers_On_Conflict>;
};

/** on_conflict condition type for table "auth_providers" */
export type Auth_Providers_On_Conflict = {
  constraint: Auth_Providers_Constraint;
  update_columns?: Array<Auth_Providers_Update_Column>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "auth_providers". */
export type Auth_Providers_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth_providers */
export type Auth_Providers_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "auth_providers" */
export enum Auth_Providers_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "auth_providers" */
export type Auth_Providers_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_providers" */
export type Auth_Providers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Providers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Providers_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth_providers" */
export enum Auth_Providers_Update_Column {
  /** column name */
  Value = 'value'
}

export type Auth_Providers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Providers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Providers_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _eq?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _gt?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _gte?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['bigint']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _lte?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _neq?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['bigint']['input']>>>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "children_result" */
export type Children_Result = {
  __typename?: 'children_result';
  result?: Maybe<Array<Scalars['Int']['output']>>;
};

/** aggregated selection of "children_result" */
export type Children_Result_Aggregate = {
  __typename?: 'children_result_aggregate';
  aggregate?: Maybe<Children_Result_Aggregate_Fields>;
  nodes: Array<Children_Result>;
};

/** aggregate fields of "children_result" */
export type Children_Result_Aggregate_Fields = {
  __typename?: 'children_result_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Children_Result_Max_Fields>;
  min?: Maybe<Children_Result_Min_Fields>;
};


/** aggregate fields of "children_result" */
export type Children_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Children_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "children_result". All fields are combined with a logical 'AND'. */
export type Children_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Children_Result_Bool_Exp>>;
  _not?: InputMaybe<Children_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Children_Result_Bool_Exp>>;
  result?: InputMaybe<Int_Array_Comparison_Exp>;
};

/** input type for inserting data into table "children_result" */
export type Children_Result_Insert_Input = {
  result?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** aggregate max on columns */
export type Children_Result_Max_Fields = {
  __typename?: 'children_result_max_fields';
  result?: Maybe<Array<Scalars['Int']['output']>>;
};

/** aggregate min on columns */
export type Children_Result_Min_Fields = {
  __typename?: 'children_result_min_fields';
  result?: Maybe<Array<Scalars['Int']['output']>>;
};

/** response of any mutation on the table "children_result" */
export type Children_Result_Mutation_Response = {
  __typename?: 'children_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Children_Result>;
};

/** Ordering options when selecting data from "children_result". */
export type Children_Result_Order_By = {
  result?: InputMaybe<Order_By>;
};

/** select columns of table "children_result" */
export enum Children_Result_Select_Column {
  /** column name */
  Result = 'result'
}

/** input type for updating data in table "children_result" */
export type Children_Result_Set_Input = {
  result?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Streaming cursor of the table "children_result" */
export type Children_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Children_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Children_Result_Stream_Cursor_Value_Input = {
  result?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Children_Result_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Children_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Children_Result_Bool_Exp;
};

/** columns and relationships of "content_types" */
export type Content_Types = {
  __typename?: 'content_types';
  /** An array relationship */
  content_typesss: Array<Contents>;
  /** An aggregate relationship */
  content_typesss_aggregate: Contents_Aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "content_types" */
export type Content_TypesContent_TypesssArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


/** columns and relationships of "content_types" */
export type Content_TypesContent_Typesss_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};

/** aggregated selection of "content_types" */
export type Content_Types_Aggregate = {
  __typename?: 'content_types_aggregate';
  aggregate?: Maybe<Content_Types_Aggregate_Fields>;
  nodes: Array<Content_Types>;
};

/** aggregate fields of "content_types" */
export type Content_Types_Aggregate_Fields = {
  __typename?: 'content_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Content_Types_Max_Fields>;
  min?: Maybe<Content_Types_Min_Fields>;
};


/** aggregate fields of "content_types" */
export type Content_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "content_types". All fields are combined with a logical 'AND'. */
export type Content_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Types_Bool_Exp>>;
  _not?: InputMaybe<Content_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Types_Bool_Exp>>;
  content_typesss?: InputMaybe<Contents_Bool_Exp>;
  content_typesss_aggregate?: InputMaybe<Contents_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "content_types" */
export enum Content_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  ContentTypesPkey = 'content_types_pkey'
}

/** input type for inserting data into table "content_types" */
export type Content_Types_Insert_Input = {
  content_typesss?: InputMaybe<Contents_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Content_Types_Max_Fields = {
  __typename?: 'content_types_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Content_Types_Min_Fields = {
  __typename?: 'content_types_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "content_types" */
export type Content_Types_Mutation_Response = {
  __typename?: 'content_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Types>;
};

/** input type for inserting object relation for remote table "content_types" */
export type Content_Types_Obj_Rel_Insert_Input = {
  data: Content_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Content_Types_On_Conflict>;
};

/** on_conflict condition type for table "content_types" */
export type Content_Types_On_Conflict = {
  constraint: Content_Types_Constraint;
  update_columns?: Array<Content_Types_Update_Column>;
  where?: InputMaybe<Content_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "content_types". */
export type Content_Types_Order_By = {
  content_typesss_aggregate?: InputMaybe<Contents_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: content_types */
export type Content_Types_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "content_types" */
export enum Content_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "content_types" */
export type Content_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "content_types" */
export type Content_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "content_types" */
export enum Content_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Content_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Types_Bool_Exp;
};

/** columns and relationships of "contents" */
export type Contents = {
  __typename?: 'contents';
  alt_text?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  content_type: Content_Types;
  id: Scalars['uuid']['output'];
  message?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  parent_node_id: Scalars['uuid']['output'];
  type: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "contents" */
export type Contents_Aggregate = {
  __typename?: 'contents_aggregate';
  aggregate?: Maybe<Contents_Aggregate_Fields>;
  nodes: Array<Contents>;
};

export type Contents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contents_Aggregate_Bool_Exp_Count>;
};

export type Contents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Contents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contents" */
export type Contents_Aggregate_Fields = {
  __typename?: 'contents_aggregate_fields';
  avg?: Maybe<Contents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Contents_Max_Fields>;
  min?: Maybe<Contents_Min_Fields>;
  stddev?: Maybe<Contents_Stddev_Fields>;
  stddev_pop?: Maybe<Contents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contents_Stddev_Samp_Fields>;
  sum?: Maybe<Contents_Sum_Fields>;
  var_pop?: Maybe<Contents_Var_Pop_Fields>;
  var_samp?: Maybe<Contents_Var_Samp_Fields>;
  variance?: Maybe<Contents_Variance_Fields>;
};


/** aggregate fields of "contents" */
export type Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contents" */
export type Contents_Aggregate_Order_By = {
  avg?: InputMaybe<Contents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contents_Max_Order_By>;
  min?: InputMaybe<Contents_Min_Order_By>;
  stddev?: InputMaybe<Contents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contents_Sum_Order_By>;
  var_pop?: InputMaybe<Contents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contents_Var_Samp_Order_By>;
  variance?: InputMaybe<Contents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contents" */
export type Contents_Arr_Rel_Insert_Input = {
  data: Array<Contents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};

/** aggregate avg on columns */
export type Contents_Avg_Fields = {
  __typename?: 'contents_avg_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "contents" */
export type Contents_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contents". All fields are combined with a logical 'AND'. */
export type Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Contents_Bool_Exp>>;
  _not?: InputMaybe<Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Contents_Bool_Exp>>;
  alt_text?: InputMaybe<String_Comparison_Exp>;
  content_type?: InputMaybe<Content_Types_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  parent_node_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contents" */
export enum Contents_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentsNewPkey = 'contents_new_pkey'
}

/** input type for incrementing numeric columns in table "contents" */
export type Contents_Inc_Input = {
  order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "contents" */
export type Contents_Insert_Input = {
  alt_text?: InputMaybe<Scalars['String']['input']>;
  content_type?: InputMaybe<Content_Types_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  parent_node_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Contents_Max_Fields = {
  __typename?: 'contents_max_fields';
  alt_text?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  parent_node_id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "contents" */
export type Contents_Max_Order_By = {
  alt_text?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parent_node_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contents_Min_Fields = {
  __typename?: 'contents_min_fields';
  alt_text?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  parent_node_id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "contents" */
export type Contents_Min_Order_By = {
  alt_text?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parent_node_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contents" */
export type Contents_Mutation_Response = {
  __typename?: 'contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contents>;
};

/** on_conflict condition type for table "contents" */
export type Contents_On_Conflict = {
  constraint: Contents_Constraint;
  update_columns?: Array<Contents_Update_Column>;
  where?: InputMaybe<Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "contents". */
export type Contents_Order_By = {
  alt_text?: InputMaybe<Order_By>;
  content_type?: InputMaybe<Content_Types_Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parent_node_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contents */
export type Contents_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "contents" */
export enum Contents_Select_Column {
  /** column name */
  AltText = 'alt_text',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Order = 'order',
  /** column name */
  ParentNodeId = 'parent_node_id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "contents" */
export type Contents_Set_Input = {
  alt_text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  parent_node_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Contents_Stddev_Fields = {
  __typename?: 'contents_stddev_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "contents" */
export type Contents_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contents_Stddev_Pop_Fields = {
  __typename?: 'contents_stddev_pop_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "contents" */
export type Contents_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contents_Stddev_Samp_Fields = {
  __typename?: 'contents_stddev_samp_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "contents" */
export type Contents_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "contents" */
export type Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contents_Stream_Cursor_Value_Input = {
  alt_text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  parent_node_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Contents_Sum_Fields = {
  __typename?: 'contents_sum_fields';
  order?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "contents" */
export type Contents_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "contents" */
export enum Contents_Update_Column {
  /** column name */
  AltText = 'alt_text',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Order = 'order',
  /** column name */
  ParentNodeId = 'parent_node_id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

export type Contents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contents_Var_Pop_Fields = {
  __typename?: 'contents_var_pop_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "contents" */
export type Contents_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contents_Var_Samp_Fields = {
  __typename?: 'contents_var_samp_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "contents" */
export type Contents_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Contents_Variance_Fields = {
  __typename?: 'contents_variance_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "contents" */
export type Contents_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "deployments" */
export type Deployments = {
  __typename?: 'deployments';
  app_id: Scalars['uuid']['output'];
  env_id: Scalars['uuid']['output'];
  /** An object relationship */
  environment?: Maybe<Environments>;
  id: Scalars['uuid']['output'];
  is_latest?: Maybe<Scalars['Boolean']['output']>;
  is_live?: Maybe<Scalars['Boolean']['output']>;
  time: Scalars['date']['output'];
  user_id: Scalars['uuid']['output'];
  /** An object relationship */
  version?: Maybe<Versions>;
  version_id: Scalars['uuid']['output'];
};

/** aggregated selection of "deployments" */
export type Deployments_Aggregate = {
  __typename?: 'deployments_aggregate';
  aggregate?: Maybe<Deployments_Aggregate_Fields>;
  nodes: Array<Deployments>;
};

export type Deployments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Deployments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Deployments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Deployments_Aggregate_Bool_Exp_Count>;
};

export type Deployments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Deployments_Select_Column_Deployments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Deployments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deployments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Deployments_Select_Column_Deployments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Deployments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deployments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Deployments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Deployments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "deployments" */
export type Deployments_Aggregate_Fields = {
  __typename?: 'deployments_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Deployments_Max_Fields>;
  min?: Maybe<Deployments_Min_Fields>;
};


/** aggregate fields of "deployments" */
export type Deployments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deployments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "deployments" */
export type Deployments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Deployments_Max_Order_By>;
  min?: InputMaybe<Deployments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deployments" */
export type Deployments_Arr_Rel_Insert_Input = {
  data: Array<Deployments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deployments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deployments". All fields are combined with a logical 'AND'. */
export type Deployments_Bool_Exp = {
  _and?: InputMaybe<Array<Deployments_Bool_Exp>>;
  _not?: InputMaybe<Deployments_Bool_Exp>;
  _or?: InputMaybe<Array<Deployments_Bool_Exp>>;
  app_id?: InputMaybe<Uuid_Comparison_Exp>;
  env_id?: InputMaybe<Uuid_Comparison_Exp>;
  environment?: InputMaybe<Environments_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_latest?: InputMaybe<Boolean_Comparison_Exp>;
  is_live?: InputMaybe<Boolean_Comparison_Exp>;
  time?: InputMaybe<Date_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  version?: InputMaybe<Versions_Bool_Exp>;
  version_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deployments" */
export enum Deployments_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeploymentsPkey = 'deployments_pkey'
}

/** input type for inserting data into table "deployments" */
export type Deployments_Insert_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  env_id?: InputMaybe<Scalars['uuid']['input']>;
  environment?: InputMaybe<Environments_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_latest?: InputMaybe<Scalars['Boolean']['input']>;
  is_live?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['date']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  version?: InputMaybe<Versions_Obj_Rel_Insert_Input>;
  version_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Deployments_Max_Fields = {
  __typename?: 'deployments_max_fields';
  app_id?: Maybe<Scalars['uuid']['output']>;
  env_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  time?: Maybe<Scalars['date']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  version_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "deployments" */
export type Deployments_Max_Order_By = {
  app_id?: InputMaybe<Order_By>;
  env_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Deployments_Min_Fields = {
  __typename?: 'deployments_min_fields';
  app_id?: Maybe<Scalars['uuid']['output']>;
  env_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  time?: Maybe<Scalars['date']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  version_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "deployments" */
export type Deployments_Min_Order_By = {
  app_id?: InputMaybe<Order_By>;
  env_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "deployments" */
export type Deployments_Mutation_Response = {
  __typename?: 'deployments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Deployments>;
};

/** on_conflict condition type for table "deployments" */
export type Deployments_On_Conflict = {
  constraint: Deployments_Constraint;
  update_columns?: Array<Deployments_Update_Column>;
  where?: InputMaybe<Deployments_Bool_Exp>;
};

/** Ordering options when selecting data from "deployments". */
export type Deployments_Order_By = {
  app_id?: InputMaybe<Order_By>;
  env_id?: InputMaybe<Order_By>;
  environment?: InputMaybe<Environments_Order_By>;
  id?: InputMaybe<Order_By>;
  is_latest?: InputMaybe<Order_By>;
  is_live?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  version?: InputMaybe<Versions_Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deployments */
export type Deployments_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "deployments" */
export enum Deployments_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  EnvId = 'env_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsLatest = 'is_latest',
  /** column name */
  IsLive = 'is_live',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VersionId = 'version_id'
}

/** select "deployments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "deployments" */
export enum Deployments_Select_Column_Deployments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsLatest = 'is_latest',
  /** column name */
  IsLive = 'is_live'
}

/** select "deployments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "deployments" */
export enum Deployments_Select_Column_Deployments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsLatest = 'is_latest',
  /** column name */
  IsLive = 'is_live'
}

/** input type for updating data in table "deployments" */
export type Deployments_Set_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  env_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_latest?: InputMaybe<Scalars['Boolean']['input']>;
  is_live?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['date']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  version_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "deployments" */
export type Deployments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deployments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deployments_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  env_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_latest?: InputMaybe<Scalars['Boolean']['input']>;
  is_live?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['date']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  version_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "deployments" */
export enum Deployments_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  EnvId = 'env_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsLatest = 'is_latest',
  /** column name */
  IsLive = 'is_live',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VersionId = 'version_id'
}

export type Deployments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deployments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deployments_Bool_Exp;
};

/** columns and relationships of "drafts" */
export type Drafts = {
  __typename?: 'drafts';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  time: Scalars['date']['output'];
  user_id: Scalars['uuid']['output'];
  version_id: Scalars['uuid']['output'];
};

/** aggregated selection of "drafts" */
export type Drafts_Aggregate = {
  __typename?: 'drafts_aggregate';
  aggregate?: Maybe<Drafts_Aggregate_Fields>;
  nodes: Array<Drafts>;
};

/** aggregate fields of "drafts" */
export type Drafts_Aggregate_Fields = {
  __typename?: 'drafts_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Drafts_Max_Fields>;
  min?: Maybe<Drafts_Min_Fields>;
};


/** aggregate fields of "drafts" */
export type Drafts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Drafts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "drafts". All fields are combined with a logical 'AND'. */
export type Drafts_Bool_Exp = {
  _and?: InputMaybe<Array<Drafts_Bool_Exp>>;
  _not?: InputMaybe<Drafts_Bool_Exp>;
  _or?: InputMaybe<Array<Drafts_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  time?: InputMaybe<Date_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  version_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "drafts" */
export enum Drafts_Constraint {
  /** unique or primary key constraint on columns "id" */
  DraftPkey = 'draft_pkey'
}

/** input type for inserting data into table "drafts" */
export type Drafts_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  time?: InputMaybe<Scalars['date']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  version_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Drafts_Max_Fields = {
  __typename?: 'drafts_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  time?: Maybe<Scalars['date']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  version_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Drafts_Min_Fields = {
  __typename?: 'drafts_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  time?: Maybe<Scalars['date']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  version_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "drafts" */
export type Drafts_Mutation_Response = {
  __typename?: 'drafts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Drafts>;
};

/** input type for inserting object relation for remote table "drafts" */
export type Drafts_Obj_Rel_Insert_Input = {
  data: Drafts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Drafts_On_Conflict>;
};

/** on_conflict condition type for table "drafts" */
export type Drafts_On_Conflict = {
  constraint: Drafts_Constraint;
  update_columns?: Array<Drafts_Update_Column>;
  where?: InputMaybe<Drafts_Bool_Exp>;
};

/** Ordering options when selecting data from "drafts". */
export type Drafts_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: drafts */
export type Drafts_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "drafts" */
export enum Drafts_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VersionId = 'version_id'
}

/** input type for updating data in table "drafts" */
export type Drafts_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  time?: InputMaybe<Scalars['date']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  version_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "drafts" */
export type Drafts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Drafts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Drafts_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  time?: InputMaybe<Scalars['date']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  version_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "drafts" */
export enum Drafts_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VersionId = 'version_id'
}

export type Drafts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Drafts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Drafts_Bool_Exp;
};

/** columns and relationships of "environments" */
export type Environments = {
  __typename?: 'environments';
  app_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  is_default: Scalars['Boolean']['output'];
  is_prod: Scalars['Boolean']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

/** aggregated selection of "environments" */
export type Environments_Aggregate = {
  __typename?: 'environments_aggregate';
  aggregate?: Maybe<Environments_Aggregate_Fields>;
  nodes: Array<Environments>;
};

export type Environments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Environments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Environments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Environments_Aggregate_Bool_Exp_Count>;
};

export type Environments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Environments_Select_Column_Environments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Environments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Environments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Environments_Select_Column_Environments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Environments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Environments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Environments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Environments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "environments" */
export type Environments_Aggregate_Fields = {
  __typename?: 'environments_aggregate_fields';
  avg?: Maybe<Environments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Environments_Max_Fields>;
  min?: Maybe<Environments_Min_Fields>;
  stddev?: Maybe<Environments_Stddev_Fields>;
  stddev_pop?: Maybe<Environments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Environments_Stddev_Samp_Fields>;
  sum?: Maybe<Environments_Sum_Fields>;
  var_pop?: Maybe<Environments_Var_Pop_Fields>;
  var_samp?: Maybe<Environments_Var_Samp_Fields>;
  variance?: Maybe<Environments_Variance_Fields>;
};


/** aggregate fields of "environments" */
export type Environments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Environments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "environments" */
export type Environments_Aggregate_Order_By = {
  avg?: InputMaybe<Environments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Environments_Max_Order_By>;
  min?: InputMaybe<Environments_Min_Order_By>;
  stddev?: InputMaybe<Environments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Environments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Environments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Environments_Sum_Order_By>;
  var_pop?: InputMaybe<Environments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Environments_Var_Samp_Order_By>;
  variance?: InputMaybe<Environments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "environments" */
export type Environments_Arr_Rel_Insert_Input = {
  data: Array<Environments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Environments_On_Conflict>;
};

/** aggregate avg on columns */
export type Environments_Avg_Fields = {
  __typename?: 'environments_avg_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "environments" */
export type Environments_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "environments". All fields are combined with a logical 'AND'. */
export type Environments_Bool_Exp = {
  _and?: InputMaybe<Array<Environments_Bool_Exp>>;
  _not?: InputMaybe<Environments_Bool_Exp>;
  _or?: InputMaybe<Array<Environments_Bool_Exp>>;
  app_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_default?: InputMaybe<Boolean_Comparison_Exp>;
  is_prod?: InputMaybe<Boolean_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "environments" */
export enum Environments_Constraint {
  /** unique or primary key constraint on columns "id" */
  EnvironmentsPkey = 'environments_pkey'
}

/** input type for incrementing numeric columns in table "environments" */
export type Environments_Inc_Input = {
  order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "environments" */
export type Environments_Insert_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  is_prod?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Environments_Max_Fields = {
  __typename?: 'environments_max_fields';
  app_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "environments" */
export type Environments_Max_Order_By = {
  app_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Environments_Min_Fields = {
  __typename?: 'environments_min_fields';
  app_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "environments" */
export type Environments_Min_Order_By = {
  app_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "environments" */
export type Environments_Mutation_Response = {
  __typename?: 'environments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Environments>;
};

/** input type for inserting object relation for remote table "environments" */
export type Environments_Obj_Rel_Insert_Input = {
  data: Environments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Environments_On_Conflict>;
};

/** on_conflict condition type for table "environments" */
export type Environments_On_Conflict = {
  constraint: Environments_Constraint;
  update_columns?: Array<Environments_Update_Column>;
  where?: InputMaybe<Environments_Bool_Exp>;
};

/** Ordering options when selecting data from "environments". */
export type Environments_Order_By = {
  app_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_default?: InputMaybe<Order_By>;
  is_prod?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: environments */
export type Environments_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "environments" */
export enum Environments_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  IsProd = 'is_prod',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title'
}

/** select "environments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "environments" */
export enum Environments_Select_Column_Environments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  IsProd = 'is_prod'
}

/** select "environments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "environments" */
export enum Environments_Select_Column_Environments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  IsProd = 'is_prod'
}

/** input type for updating data in table "environments" */
export type Environments_Set_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  is_prod?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Environments_Stddev_Fields = {
  __typename?: 'environments_stddev_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "environments" */
export type Environments_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Environments_Stddev_Pop_Fields = {
  __typename?: 'environments_stddev_pop_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "environments" */
export type Environments_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Environments_Stddev_Samp_Fields = {
  __typename?: 'environments_stddev_samp_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "environments" */
export type Environments_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "environments" */
export type Environments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Environments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Environments_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  is_prod?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Environments_Sum_Fields = {
  __typename?: 'environments_sum_fields';
  order?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "environments" */
export type Environments_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "environments" */
export enum Environments_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  IsProd = 'is_prod',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title'
}

export type Environments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Environments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Environments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Environments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Environments_Var_Pop_Fields = {
  __typename?: 'environments_var_pop_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "environments" */
export type Environments_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Environments_Var_Samp_Fields = {
  __typename?: 'environments_var_samp_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "environments" */
export type Environments_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Environments_Variance_Fields = {
  __typename?: 'environments_variance_fields';
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "environments" */
export type Environments_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

export type Fe_Proto_Mutation_Root = {
  __typename?: 'fe_proto_mutation_root';
  /** delete data from the table: "apps" */
  delete_apps?: Maybe<Apps_Mutation_Response>;
  /** delete single row from the table: "apps" */
  delete_apps_by_pk?: Maybe<Apps>;
  /** delete data from the table: "apps_v2" */
  delete_apps_v2?: Maybe<Apps_V2_Mutation_Response>;
  /** delete single row from the table: "apps_v2" */
  delete_apps_v2_by_pk?: Maybe<Apps_V2>;
  /** delete data from the table: "auth_providers" */
  delete_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** delete single row from the table: "auth_providers" */
  delete_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** delete data from the table: "children_result" */
  delete_children_result?: Maybe<Children_Result_Mutation_Response>;
  /** delete data from the table: "content_types" */
  delete_content_types?: Maybe<Content_Types_Mutation_Response>;
  /** delete single row from the table: "content_types" */
  delete_content_types_by_pk?: Maybe<Content_Types>;
  /** delete data from the table: "contents" */
  delete_contents?: Maybe<Contents_Mutation_Response>;
  /** delete single row from the table: "contents" */
  delete_contents_by_pk?: Maybe<Contents>;
  /** delete data from the table: "deployments" */
  delete_deployments?: Maybe<Deployments_Mutation_Response>;
  /** delete single row from the table: "deployments" */
  delete_deployments_by_pk?: Maybe<Deployments>;
  /** delete data from the table: "drafts" */
  delete_drafts?: Maybe<Drafts_Mutation_Response>;
  /** delete single row from the table: "drafts" */
  delete_drafts_by_pk?: Maybe<Drafts>;
  /** delete data from the table: "environments" */
  delete_environments?: Maybe<Environments_Mutation_Response>;
  /** delete single row from the table: "environments" */
  delete_environments_by_pk?: Maybe<Environments>;
  /** delete data from the table: "foobar" */
  delete_foobar?: Maybe<Foobar_Mutation_Response>;
  /** delete data from the table: "graph" */
  delete_graph?: Maybe<Graph_Mutation_Response>;
  /** delete single row from the table: "graph" */
  delete_graph_by_pk?: Maybe<Graph>;
  /** delete data from the table: "image_contents" */
  delete_image_contents?: Maybe<Image_Contents_Mutation_Response>;
  /** delete single row from the table: "image_contents" */
  delete_image_contents_by_pk?: Maybe<Image_Contents>;
  /** delete data from the table: "node_jsonb" */
  delete_node_jsonb?: Maybe<Node_Jsonb_Mutation_Response>;
  /** delete single row from the table: "node_jsonb" */
  delete_node_jsonb_by_pk?: Maybe<Node_Jsonb>;
  /** delete data from the table: "nodes" */
  delete_nodes?: Maybe<Nodes_Mutation_Response>;
  /** delete single row from the table: "nodes" */
  delete_nodes_by_pk?: Maybe<Nodes>;
  /** delete data from the table: "parent_child_nodes" */
  delete_parent_child_nodes?: Maybe<Parent_Child_Nodes_Mutation_Response>;
  /** delete data from the table: "sso_domains" */
  delete_sso_domains?: Maybe<Sso_Domains_Mutation_Response>;
  /** delete single row from the table: "sso_domains" */
  delete_sso_domains_by_pk?: Maybe<Sso_Domains>;
  /** delete data from the table: "text_contents" */
  delete_text_contents?: Maybe<Text_Contents_Mutation_Response>;
  /** delete single row from the table: "text_contents" */
  delete_text_contents_by_pk?: Maybe<Text_Contents>;
  /** delete data from the table: "text_result" */
  delete_text_result?: Maybe<Text_Result_Mutation_Response>;
  /** delete data from the table: "user_test" */
  delete_user_test?: Maybe<User_Test_Mutation_Response>;
  /** delete single row from the table: "user_test" */
  delete_user_test_by_pk?: Maybe<User_Test>;
  /** delete data from the table: "versions" */
  delete_versions?: Maybe<Versions_Mutation_Response>;
  /** delete single row from the table: "versions" */
  delete_versions_by_pk?: Maybe<Versions>;
  /** delete data from the table: "workflow" */
  delete_workflow?: Maybe<Workflow_Mutation_Response>;
  /** delete single row from the table: "workflow" */
  delete_workflow_by_pk?: Maybe<Workflow>;
  /** delete data from the table: "workflows" */
  delete_workflows?: Maybe<Workflows_Mutation_Response>;
  /** delete single row from the table: "workflows" */
  delete_workflows_by_pk?: Maybe<Workflows>;
  /** insert data into the table: "apps" */
  insert_apps?: Maybe<Apps_Mutation_Response>;
  /** insert a single row into the table: "apps" */
  insert_apps_one?: Maybe<Apps>;
  /** insert data into the table: "apps_v2" */
  insert_apps_v2?: Maybe<Apps_V2_Mutation_Response>;
  /** insert a single row into the table: "apps_v2" */
  insert_apps_v2_one?: Maybe<Apps_V2>;
  /** insert data into the table: "auth_providers" */
  insert_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth_providers" */
  insert_auth_providers_one?: Maybe<Auth_Providers>;
  /** insert data into the table: "children_result" */
  insert_children_result?: Maybe<Children_Result_Mutation_Response>;
  /** insert a single row into the table: "children_result" */
  insert_children_result_one?: Maybe<Children_Result>;
  /** insert data into the table: "content_types" */
  insert_content_types?: Maybe<Content_Types_Mutation_Response>;
  /** insert a single row into the table: "content_types" */
  insert_content_types_one?: Maybe<Content_Types>;
  /** insert data into the table: "contents" */
  insert_contents?: Maybe<Contents_Mutation_Response>;
  /** insert a single row into the table: "contents" */
  insert_contents_one?: Maybe<Contents>;
  /** insert data into the table: "deployments" */
  insert_deployments?: Maybe<Deployments_Mutation_Response>;
  /** insert a single row into the table: "deployments" */
  insert_deployments_one?: Maybe<Deployments>;
  /** insert data into the table: "drafts" */
  insert_drafts?: Maybe<Drafts_Mutation_Response>;
  /** insert a single row into the table: "drafts" */
  insert_drafts_one?: Maybe<Drafts>;
  /** insert data into the table: "environments" */
  insert_environments?: Maybe<Environments_Mutation_Response>;
  /** insert a single row into the table: "environments" */
  insert_environments_one?: Maybe<Environments>;
  /** insert data into the table: "foobar" */
  insert_foobar?: Maybe<Foobar_Mutation_Response>;
  /** insert a single row into the table: "foobar" */
  insert_foobar_one?: Maybe<Foobar>;
  /** insert data into the table: "graph" */
  insert_graph?: Maybe<Graph_Mutation_Response>;
  /** insert a single row into the table: "graph" */
  insert_graph_one?: Maybe<Graph>;
  /** insert data into the table: "image_contents" */
  insert_image_contents?: Maybe<Image_Contents_Mutation_Response>;
  /** insert a single row into the table: "image_contents" */
  insert_image_contents_one?: Maybe<Image_Contents>;
  /** insert data into the table: "node_jsonb" */
  insert_node_jsonb?: Maybe<Node_Jsonb_Mutation_Response>;
  /** insert a single row into the table: "node_jsonb" */
  insert_node_jsonb_one?: Maybe<Node_Jsonb>;
  /** insert data into the table: "nodes" */
  insert_nodes?: Maybe<Nodes_Mutation_Response>;
  /** insert a single row into the table: "nodes" */
  insert_nodes_one?: Maybe<Nodes>;
  /** insert data into the table: "parent_child_nodes" */
  insert_parent_child_nodes?: Maybe<Parent_Child_Nodes_Mutation_Response>;
  /** insert a single row into the table: "parent_child_nodes" */
  insert_parent_child_nodes_one?: Maybe<Parent_Child_Nodes>;
  /** insert data into the table: "sso_domains" */
  insert_sso_domains?: Maybe<Sso_Domains_Mutation_Response>;
  /** insert a single row into the table: "sso_domains" */
  insert_sso_domains_one?: Maybe<Sso_Domains>;
  /** insert data into the table: "text_contents" */
  insert_text_contents?: Maybe<Text_Contents_Mutation_Response>;
  /** insert a single row into the table: "text_contents" */
  insert_text_contents_one?: Maybe<Text_Contents>;
  /** insert data into the table: "text_result" */
  insert_text_result?: Maybe<Text_Result_Mutation_Response>;
  /** insert a single row into the table: "text_result" */
  insert_text_result_one?: Maybe<Text_Result>;
  /** insert data into the table: "user_test" */
  insert_user_test?: Maybe<User_Test_Mutation_Response>;
  /** insert a single row into the table: "user_test" */
  insert_user_test_one?: Maybe<User_Test>;
  /** insert data into the table: "versions" */
  insert_versions?: Maybe<Versions_Mutation_Response>;
  /** insert a single row into the table: "versions" */
  insert_versions_one?: Maybe<Versions>;
  /** insert data into the table: "workflow" */
  insert_workflow?: Maybe<Workflow_Mutation_Response>;
  /** insert a single row into the table: "workflow" */
  insert_workflow_one?: Maybe<Workflow>;
  /** insert data into the table: "workflows" */
  insert_workflows?: Maybe<Workflows_Mutation_Response>;
  /** insert a single row into the table: "workflows" */
  insert_workflows_one?: Maybe<Workflows>;
  /** update data of the table: "apps" */
  update_apps?: Maybe<Apps_Mutation_Response>;
  /** update single row of the table: "apps" */
  update_apps_by_pk?: Maybe<Apps>;
  /** update multiples rows of table: "apps" */
  update_apps_many?: Maybe<Array<Maybe<Apps_Mutation_Response>>>;
  /** update data of the table: "apps_v2" */
  update_apps_v2?: Maybe<Apps_V2_Mutation_Response>;
  /** update single row of the table: "apps_v2" */
  update_apps_v2_by_pk?: Maybe<Apps_V2>;
  /** update multiples rows of table: "apps_v2" */
  update_apps_v2_many?: Maybe<Array<Maybe<Apps_V2_Mutation_Response>>>;
  /** update data of the table: "auth_providers" */
  update_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** update single row of the table: "auth_providers" */
  update_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** update multiples rows of table: "auth_providers" */
  update_auth_providers_many?: Maybe<Array<Maybe<Auth_Providers_Mutation_Response>>>;
  /** update data of the table: "children_result" */
  update_children_result?: Maybe<Children_Result_Mutation_Response>;
  /** update multiples rows of table: "children_result" */
  update_children_result_many?: Maybe<Array<Maybe<Children_Result_Mutation_Response>>>;
  /** update data of the table: "content_types" */
  update_content_types?: Maybe<Content_Types_Mutation_Response>;
  /** update single row of the table: "content_types" */
  update_content_types_by_pk?: Maybe<Content_Types>;
  /** update multiples rows of table: "content_types" */
  update_content_types_many?: Maybe<Array<Maybe<Content_Types_Mutation_Response>>>;
  /** update data of the table: "contents" */
  update_contents?: Maybe<Contents_Mutation_Response>;
  /** update single row of the table: "contents" */
  update_contents_by_pk?: Maybe<Contents>;
  /** update multiples rows of table: "contents" */
  update_contents_many?: Maybe<Array<Maybe<Contents_Mutation_Response>>>;
  /** update data of the table: "deployments" */
  update_deployments?: Maybe<Deployments_Mutation_Response>;
  /** update single row of the table: "deployments" */
  update_deployments_by_pk?: Maybe<Deployments>;
  /** update multiples rows of table: "deployments" */
  update_deployments_many?: Maybe<Array<Maybe<Deployments_Mutation_Response>>>;
  /** update data of the table: "drafts" */
  update_drafts?: Maybe<Drafts_Mutation_Response>;
  /** update single row of the table: "drafts" */
  update_drafts_by_pk?: Maybe<Drafts>;
  /** update multiples rows of table: "drafts" */
  update_drafts_many?: Maybe<Array<Maybe<Drafts_Mutation_Response>>>;
  /** update data of the table: "environments" */
  update_environments?: Maybe<Environments_Mutation_Response>;
  /** update single row of the table: "environments" */
  update_environments_by_pk?: Maybe<Environments>;
  /** update multiples rows of table: "environments" */
  update_environments_many?: Maybe<Array<Maybe<Environments_Mutation_Response>>>;
  /** update data of the table: "foobar" */
  update_foobar?: Maybe<Foobar_Mutation_Response>;
  /** update multiples rows of table: "foobar" */
  update_foobar_many?: Maybe<Array<Maybe<Foobar_Mutation_Response>>>;
  /** update data of the table: "graph" */
  update_graph?: Maybe<Graph_Mutation_Response>;
  /** update single row of the table: "graph" */
  update_graph_by_pk?: Maybe<Graph>;
  /** update multiples rows of table: "graph" */
  update_graph_many?: Maybe<Array<Maybe<Graph_Mutation_Response>>>;
  /** update data of the table: "image_contents" */
  update_image_contents?: Maybe<Image_Contents_Mutation_Response>;
  /** update single row of the table: "image_contents" */
  update_image_contents_by_pk?: Maybe<Image_Contents>;
  /** update multiples rows of table: "image_contents" */
  update_image_contents_many?: Maybe<Array<Maybe<Image_Contents_Mutation_Response>>>;
  /** update data of the table: "node_jsonb" */
  update_node_jsonb?: Maybe<Node_Jsonb_Mutation_Response>;
  /** update single row of the table: "node_jsonb" */
  update_node_jsonb_by_pk?: Maybe<Node_Jsonb>;
  /** update multiples rows of table: "node_jsonb" */
  update_node_jsonb_many?: Maybe<Array<Maybe<Node_Jsonb_Mutation_Response>>>;
  /** update data of the table: "nodes" */
  update_nodes?: Maybe<Nodes_Mutation_Response>;
  /** update single row of the table: "nodes" */
  update_nodes_by_pk?: Maybe<Nodes>;
  /** update multiples rows of table: "nodes" */
  update_nodes_many?: Maybe<Array<Maybe<Nodes_Mutation_Response>>>;
  /** update data of the table: "parent_child_nodes" */
  update_parent_child_nodes?: Maybe<Parent_Child_Nodes_Mutation_Response>;
  /** update multiples rows of table: "parent_child_nodes" */
  update_parent_child_nodes_many?: Maybe<Array<Maybe<Parent_Child_Nodes_Mutation_Response>>>;
  /** update data of the table: "sso_domains" */
  update_sso_domains?: Maybe<Sso_Domains_Mutation_Response>;
  /** update single row of the table: "sso_domains" */
  update_sso_domains_by_pk?: Maybe<Sso_Domains>;
  /** update multiples rows of table: "sso_domains" */
  update_sso_domains_many?: Maybe<Array<Maybe<Sso_Domains_Mutation_Response>>>;
  /** update data of the table: "text_contents" */
  update_text_contents?: Maybe<Text_Contents_Mutation_Response>;
  /** update single row of the table: "text_contents" */
  update_text_contents_by_pk?: Maybe<Text_Contents>;
  /** update multiples rows of table: "text_contents" */
  update_text_contents_many?: Maybe<Array<Maybe<Text_Contents_Mutation_Response>>>;
  /** update data of the table: "text_result" */
  update_text_result?: Maybe<Text_Result_Mutation_Response>;
  /** update multiples rows of table: "text_result" */
  update_text_result_many?: Maybe<Array<Maybe<Text_Result_Mutation_Response>>>;
  /** update data of the table: "user_test" */
  update_user_test?: Maybe<User_Test_Mutation_Response>;
  /** update single row of the table: "user_test" */
  update_user_test_by_pk?: Maybe<User_Test>;
  /** update multiples rows of table: "user_test" */
  update_user_test_many?: Maybe<Array<Maybe<User_Test_Mutation_Response>>>;
  /** update data of the table: "versions" */
  update_versions?: Maybe<Versions_Mutation_Response>;
  /** update single row of the table: "versions" */
  update_versions_by_pk?: Maybe<Versions>;
  /** update multiples rows of table: "versions" */
  update_versions_many?: Maybe<Array<Maybe<Versions_Mutation_Response>>>;
  /** update data of the table: "workflow" */
  update_workflow?: Maybe<Workflow_Mutation_Response>;
  /** update single row of the table: "workflow" */
  update_workflow_by_pk?: Maybe<Workflow>;
  /** update multiples rows of table: "workflow" */
  update_workflow_many?: Maybe<Array<Maybe<Workflow_Mutation_Response>>>;
  /** update data of the table: "workflows" */
  update_workflows?: Maybe<Workflows_Mutation_Response>;
  /** update single row of the table: "workflows" */
  update_workflows_by_pk?: Maybe<Workflows>;
  /** update multiples rows of table: "workflows" */
  update_workflows_many?: Maybe<Array<Maybe<Workflows_Mutation_Response>>>;
};


export type Fe_Proto_Mutation_RootDelete_AppsArgs = {
  where: Apps_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Apps_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Mutation_RootDelete_Apps_V2Args = {
  where: Apps_V2_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Apps_V2_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Mutation_RootDelete_Auth_ProvidersArgs = {
  where: Auth_Providers_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Auth_Providers_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Fe_Proto_Mutation_RootDelete_Children_ResultArgs = {
  where: Children_Result_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Content_TypesArgs = {
  where: Content_Types_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Content_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Fe_Proto_Mutation_RootDelete_ContentsArgs = {
  where: Contents_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Contents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Mutation_RootDelete_DeploymentsArgs = {
  where: Deployments_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Deployments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Mutation_RootDelete_DraftsArgs = {
  where: Drafts_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Drafts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Mutation_RootDelete_EnvironmentsArgs = {
  where: Environments_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Environments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Mutation_RootDelete_FoobarArgs = {
  where: Foobar_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_GraphArgs = {
  where: Graph_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Graph_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Mutation_RootDelete_Image_ContentsArgs = {
  where: Image_Contents_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Image_Contents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Mutation_RootDelete_Node_JsonbArgs = {
  where: Node_Jsonb_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Node_Jsonb_By_PkArgs = {
  number: Scalars['Int']['input'];
};


export type Fe_Proto_Mutation_RootDelete_NodesArgs = {
  where: Nodes_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Nodes_By_PkArgs = {
  id: Scalars['uuid']['input'];
  number: Scalars['Int']['input'];
};


export type Fe_Proto_Mutation_RootDelete_Parent_Child_NodesArgs = {
  where: Parent_Child_Nodes_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Sso_DomainsArgs = {
  where: Sso_Domains_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Sso_Domains_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Mutation_RootDelete_Text_ContentsArgs = {
  where: Text_Contents_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Text_Contents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Mutation_RootDelete_Text_ResultArgs = {
  where: Text_Result_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_User_TestArgs = {
  where: User_Test_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_User_Test_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Mutation_RootDelete_VersionsArgs = {
  where: Versions_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Versions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Mutation_RootDelete_WorkflowArgs = {
  where: Workflow_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Workflow_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Mutation_RootDelete_WorkflowsArgs = {
  where: Workflows_Bool_Exp;
};


export type Fe_Proto_Mutation_RootDelete_Workflows_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Mutation_RootInsert_AppsArgs = {
  objects: Array<Apps_Insert_Input>;
  on_conflict?: InputMaybe<Apps_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Apps_OneArgs = {
  object: Apps_Insert_Input;
  on_conflict?: InputMaybe<Apps_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Apps_V2Args = {
  objects: Array<Apps_V2_Insert_Input>;
  on_conflict?: InputMaybe<Apps_V2_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Apps_V2_OneArgs = {
  object: Apps_V2_Insert_Input;
  on_conflict?: InputMaybe<Apps_V2_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Auth_ProvidersArgs = {
  objects: Array<Auth_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Providers_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Auth_Providers_OneArgs = {
  object: Auth_Providers_Insert_Input;
  on_conflict?: InputMaybe<Auth_Providers_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Children_ResultArgs = {
  objects: Array<Children_Result_Insert_Input>;
};


export type Fe_Proto_Mutation_RootInsert_Children_Result_OneArgs = {
  object: Children_Result_Insert_Input;
};


export type Fe_Proto_Mutation_RootInsert_Content_TypesArgs = {
  objects: Array<Content_Types_Insert_Input>;
  on_conflict?: InputMaybe<Content_Types_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Content_Types_OneArgs = {
  object: Content_Types_Insert_Input;
  on_conflict?: InputMaybe<Content_Types_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_ContentsArgs = {
  objects: Array<Contents_Insert_Input>;
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Contents_OneArgs = {
  object: Contents_Insert_Input;
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_DeploymentsArgs = {
  objects: Array<Deployments_Insert_Input>;
  on_conflict?: InputMaybe<Deployments_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Deployments_OneArgs = {
  object: Deployments_Insert_Input;
  on_conflict?: InputMaybe<Deployments_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_DraftsArgs = {
  objects: Array<Drafts_Insert_Input>;
  on_conflict?: InputMaybe<Drafts_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Drafts_OneArgs = {
  object: Drafts_Insert_Input;
  on_conflict?: InputMaybe<Drafts_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_EnvironmentsArgs = {
  objects: Array<Environments_Insert_Input>;
  on_conflict?: InputMaybe<Environments_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Environments_OneArgs = {
  object: Environments_Insert_Input;
  on_conflict?: InputMaybe<Environments_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_FoobarArgs = {
  objects: Array<Foobar_Insert_Input>;
};


export type Fe_Proto_Mutation_RootInsert_Foobar_OneArgs = {
  object: Foobar_Insert_Input;
};


export type Fe_Proto_Mutation_RootInsert_GraphArgs = {
  objects: Array<Graph_Insert_Input>;
  on_conflict?: InputMaybe<Graph_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Graph_OneArgs = {
  object: Graph_Insert_Input;
  on_conflict?: InputMaybe<Graph_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Image_ContentsArgs = {
  objects: Array<Image_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Image_Contents_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Image_Contents_OneArgs = {
  object: Image_Contents_Insert_Input;
  on_conflict?: InputMaybe<Image_Contents_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Node_JsonbArgs = {
  objects: Array<Node_Jsonb_Insert_Input>;
  on_conflict?: InputMaybe<Node_Jsonb_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Node_Jsonb_OneArgs = {
  object: Node_Jsonb_Insert_Input;
  on_conflict?: InputMaybe<Node_Jsonb_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_NodesArgs = {
  objects: Array<Nodes_Insert_Input>;
  on_conflict?: InputMaybe<Nodes_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Nodes_OneArgs = {
  object: Nodes_Insert_Input;
  on_conflict?: InputMaybe<Nodes_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Parent_Child_NodesArgs = {
  objects: Array<Parent_Child_Nodes_Insert_Input>;
};


export type Fe_Proto_Mutation_RootInsert_Parent_Child_Nodes_OneArgs = {
  object: Parent_Child_Nodes_Insert_Input;
};


export type Fe_Proto_Mutation_RootInsert_Sso_DomainsArgs = {
  objects: Array<Sso_Domains_Insert_Input>;
  on_conflict?: InputMaybe<Sso_Domains_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Sso_Domains_OneArgs = {
  object: Sso_Domains_Insert_Input;
  on_conflict?: InputMaybe<Sso_Domains_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Text_ContentsArgs = {
  objects: Array<Text_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Text_Contents_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Text_Contents_OneArgs = {
  object: Text_Contents_Insert_Input;
  on_conflict?: InputMaybe<Text_Contents_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Text_ResultArgs = {
  objects: Array<Text_Result_Insert_Input>;
};


export type Fe_Proto_Mutation_RootInsert_Text_Result_OneArgs = {
  object: Text_Result_Insert_Input;
};


export type Fe_Proto_Mutation_RootInsert_User_TestArgs = {
  objects: Array<User_Test_Insert_Input>;
  on_conflict?: InputMaybe<User_Test_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_User_Test_OneArgs = {
  object: User_Test_Insert_Input;
  on_conflict?: InputMaybe<User_Test_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_VersionsArgs = {
  objects: Array<Versions_Insert_Input>;
  on_conflict?: InputMaybe<Versions_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Versions_OneArgs = {
  object: Versions_Insert_Input;
  on_conflict?: InputMaybe<Versions_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_WorkflowArgs = {
  objects: Array<Workflow_Insert_Input>;
  on_conflict?: InputMaybe<Workflow_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Workflow_OneArgs = {
  object: Workflow_Insert_Input;
  on_conflict?: InputMaybe<Workflow_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_WorkflowsArgs = {
  objects: Array<Workflows_Insert_Input>;
  on_conflict?: InputMaybe<Workflows_On_Conflict>;
};


export type Fe_Proto_Mutation_RootInsert_Workflows_OneArgs = {
  object: Workflows_Insert_Input;
  on_conflict?: InputMaybe<Workflows_On_Conflict>;
};


export type Fe_Proto_Mutation_RootUpdate_AppsArgs = {
  _inc?: InputMaybe<Apps_Inc_Input>;
  _set?: InputMaybe<Apps_Set_Input>;
  where: Apps_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Apps_By_PkArgs = {
  _inc?: InputMaybe<Apps_Inc_Input>;
  _set?: InputMaybe<Apps_Set_Input>;
  pk_columns: Apps_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Apps_ManyArgs = {
  updates: Array<Apps_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_Apps_V2Args = {
  _inc?: InputMaybe<Apps_V2_Inc_Input>;
  _set?: InputMaybe<Apps_V2_Set_Input>;
  where: Apps_V2_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Apps_V2_By_PkArgs = {
  _inc?: InputMaybe<Apps_V2_Inc_Input>;
  _set?: InputMaybe<Apps_V2_Set_Input>;
  pk_columns: Apps_V2_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Apps_V2_ManyArgs = {
  updates: Array<Apps_V2_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_Auth_ProvidersArgs = {
  _set?: InputMaybe<Auth_Providers_Set_Input>;
  where: Auth_Providers_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Auth_Providers_By_PkArgs = {
  _set?: InputMaybe<Auth_Providers_Set_Input>;
  pk_columns: Auth_Providers_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Auth_Providers_ManyArgs = {
  updates: Array<Auth_Providers_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_Children_ResultArgs = {
  _set?: InputMaybe<Children_Result_Set_Input>;
  where: Children_Result_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Children_Result_ManyArgs = {
  updates: Array<Children_Result_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_Content_TypesArgs = {
  _set?: InputMaybe<Content_Types_Set_Input>;
  where: Content_Types_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Content_Types_By_PkArgs = {
  _set?: InputMaybe<Content_Types_Set_Input>;
  pk_columns: Content_Types_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Content_Types_ManyArgs = {
  updates: Array<Content_Types_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_ContentsArgs = {
  _inc?: InputMaybe<Contents_Inc_Input>;
  _set?: InputMaybe<Contents_Set_Input>;
  where: Contents_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Contents_By_PkArgs = {
  _inc?: InputMaybe<Contents_Inc_Input>;
  _set?: InputMaybe<Contents_Set_Input>;
  pk_columns: Contents_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Contents_ManyArgs = {
  updates: Array<Contents_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_DeploymentsArgs = {
  _set?: InputMaybe<Deployments_Set_Input>;
  where: Deployments_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Deployments_By_PkArgs = {
  _set?: InputMaybe<Deployments_Set_Input>;
  pk_columns: Deployments_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Deployments_ManyArgs = {
  updates: Array<Deployments_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_DraftsArgs = {
  _set?: InputMaybe<Drafts_Set_Input>;
  where: Drafts_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Drafts_By_PkArgs = {
  _set?: InputMaybe<Drafts_Set_Input>;
  pk_columns: Drafts_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Drafts_ManyArgs = {
  updates: Array<Drafts_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_EnvironmentsArgs = {
  _inc?: InputMaybe<Environments_Inc_Input>;
  _set?: InputMaybe<Environments_Set_Input>;
  where: Environments_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Environments_By_PkArgs = {
  _inc?: InputMaybe<Environments_Inc_Input>;
  _set?: InputMaybe<Environments_Set_Input>;
  pk_columns: Environments_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Environments_ManyArgs = {
  updates: Array<Environments_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_FoobarArgs = {
  _inc?: InputMaybe<Foobar_Inc_Input>;
  _set?: InputMaybe<Foobar_Set_Input>;
  where: Foobar_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Foobar_ManyArgs = {
  updates: Array<Foobar_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_GraphArgs = {
  _set?: InputMaybe<Graph_Set_Input>;
  where: Graph_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Graph_By_PkArgs = {
  _set?: InputMaybe<Graph_Set_Input>;
  pk_columns: Graph_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Graph_ManyArgs = {
  updates: Array<Graph_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_Image_ContentsArgs = {
  _set?: InputMaybe<Image_Contents_Set_Input>;
  where: Image_Contents_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Image_Contents_By_PkArgs = {
  _set?: InputMaybe<Image_Contents_Set_Input>;
  pk_columns: Image_Contents_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Image_Contents_ManyArgs = {
  updates: Array<Image_Contents_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_Node_JsonbArgs = {
  _append?: InputMaybe<Node_Jsonb_Append_Input>;
  _delete_at_path?: InputMaybe<Node_Jsonb_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Node_Jsonb_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Node_Jsonb_Delete_Key_Input>;
  _inc?: InputMaybe<Node_Jsonb_Inc_Input>;
  _prepend?: InputMaybe<Node_Jsonb_Prepend_Input>;
  _set?: InputMaybe<Node_Jsonb_Set_Input>;
  where: Node_Jsonb_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Node_Jsonb_By_PkArgs = {
  _append?: InputMaybe<Node_Jsonb_Append_Input>;
  _delete_at_path?: InputMaybe<Node_Jsonb_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Node_Jsonb_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Node_Jsonb_Delete_Key_Input>;
  _inc?: InputMaybe<Node_Jsonb_Inc_Input>;
  _prepend?: InputMaybe<Node_Jsonb_Prepend_Input>;
  _set?: InputMaybe<Node_Jsonb_Set_Input>;
  pk_columns: Node_Jsonb_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Node_Jsonb_ManyArgs = {
  updates: Array<Node_Jsonb_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_NodesArgs = {
  _inc?: InputMaybe<Nodes_Inc_Input>;
  _set?: InputMaybe<Nodes_Set_Input>;
  where: Nodes_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Nodes_By_PkArgs = {
  _inc?: InputMaybe<Nodes_Inc_Input>;
  _set?: InputMaybe<Nodes_Set_Input>;
  pk_columns: Nodes_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Nodes_ManyArgs = {
  updates: Array<Nodes_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_Parent_Child_NodesArgs = {
  _inc?: InputMaybe<Parent_Child_Nodes_Inc_Input>;
  _set?: InputMaybe<Parent_Child_Nodes_Set_Input>;
  where: Parent_Child_Nodes_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Parent_Child_Nodes_ManyArgs = {
  updates: Array<Parent_Child_Nodes_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_Sso_DomainsArgs = {
  _inc?: InputMaybe<Sso_Domains_Inc_Input>;
  _set?: InputMaybe<Sso_Domains_Set_Input>;
  where: Sso_Domains_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Sso_Domains_By_PkArgs = {
  _inc?: InputMaybe<Sso_Domains_Inc_Input>;
  _set?: InputMaybe<Sso_Domains_Set_Input>;
  pk_columns: Sso_Domains_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Sso_Domains_ManyArgs = {
  updates: Array<Sso_Domains_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_Text_ContentsArgs = {
  _set?: InputMaybe<Text_Contents_Set_Input>;
  where: Text_Contents_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Text_Contents_By_PkArgs = {
  _set?: InputMaybe<Text_Contents_Set_Input>;
  pk_columns: Text_Contents_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Text_Contents_ManyArgs = {
  updates: Array<Text_Contents_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_Text_ResultArgs = {
  _inc?: InputMaybe<Text_Result_Inc_Input>;
  _set?: InputMaybe<Text_Result_Set_Input>;
  where: Text_Result_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Text_Result_ManyArgs = {
  updates: Array<Text_Result_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_User_TestArgs = {
  _inc?: InputMaybe<User_Test_Inc_Input>;
  _set?: InputMaybe<User_Test_Set_Input>;
  where: User_Test_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_User_Test_By_PkArgs = {
  _inc?: InputMaybe<User_Test_Inc_Input>;
  _set?: InputMaybe<User_Test_Set_Input>;
  pk_columns: User_Test_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_User_Test_ManyArgs = {
  updates: Array<User_Test_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_VersionsArgs = {
  _set?: InputMaybe<Versions_Set_Input>;
  where: Versions_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Versions_By_PkArgs = {
  _set?: InputMaybe<Versions_Set_Input>;
  pk_columns: Versions_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Versions_ManyArgs = {
  updates: Array<Versions_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_WorkflowArgs = {
  _inc?: InputMaybe<Workflow_Inc_Input>;
  _set?: InputMaybe<Workflow_Set_Input>;
  where: Workflow_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Workflow_By_PkArgs = {
  _inc?: InputMaybe<Workflow_Inc_Input>;
  _set?: InputMaybe<Workflow_Set_Input>;
  pk_columns: Workflow_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Workflow_ManyArgs = {
  updates: Array<Workflow_Updates>;
};


export type Fe_Proto_Mutation_RootUpdate_WorkflowsArgs = {
  _inc?: InputMaybe<Workflows_Inc_Input>;
  _set?: InputMaybe<Workflows_Set_Input>;
  where: Workflows_Bool_Exp;
};


export type Fe_Proto_Mutation_RootUpdate_Workflows_By_PkArgs = {
  _inc?: InputMaybe<Workflows_Inc_Input>;
  _set?: InputMaybe<Workflows_Set_Input>;
  pk_columns: Workflows_Pk_Columns_Input;
};


export type Fe_Proto_Mutation_RootUpdate_Workflows_ManyArgs = {
  updates: Array<Workflows_Updates>;
};

export type Fe_Proto_Query_Root = {
  __typename?: 'fe_proto_query_root';
  /** fetch data from the table: "apps" */
  apps: Array<Apps>;
  /** fetch aggregated fields from the table: "apps" */
  apps_aggregate: Apps_Aggregate;
  /** fetch data from the table: "apps" using primary key columns */
  apps_by_pk?: Maybe<Apps>;
  /** fetch data from the table: "apps_v2" */
  apps_v2: Array<Apps_V2>;
  /** fetch aggregated fields from the table: "apps_v2" */
  apps_v2_aggregate: Apps_V2_Aggregate;
  /** fetch data from the table: "apps_v2" using primary key columns */
  apps_v2_by_pk?: Maybe<Apps_V2>;
  /** fetch data from the table: "auth_providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth_providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth_providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table: "children_result" */
  children_result: Array<Children_Result>;
  /** fetch aggregated fields from the table: "children_result" */
  children_result_aggregate: Children_Result_Aggregate;
  /** fetch data from the table: "content_types" */
  content_types: Array<Content_Types>;
  /** fetch aggregated fields from the table: "content_types" */
  content_types_aggregate: Content_Types_Aggregate;
  /** fetch data from the table: "content_types" using primary key columns */
  content_types_by_pk?: Maybe<Content_Types>;
  /** An array relationship */
  contents: Array<Contents>;
  /** An aggregate relationship */
  contents_aggregate: Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  contents_by_pk?: Maybe<Contents>;
  /** fetch data from the table: "deployments" */
  deployments: Array<Deployments>;
  /** fetch aggregated fields from the table: "deployments" */
  deployments_aggregate: Deployments_Aggregate;
  /** fetch data from the table: "deployments" using primary key columns */
  deployments_by_pk?: Maybe<Deployments>;
  /** fetch data from the table: "drafts" */
  drafts: Array<Drafts>;
  /** fetch aggregated fields from the table: "drafts" */
  drafts_aggregate: Drafts_Aggregate;
  /** fetch data from the table: "drafts" using primary key columns */
  drafts_by_pk?: Maybe<Drafts>;
  /** fetch data from the table: "environments" */
  environments: Array<Environments>;
  /** fetch aggregated fields from the table: "environments" */
  environments_aggregate: Environments_Aggregate;
  /** fetch data from the table: "environments" using primary key columns */
  environments_by_pk?: Maybe<Environments>;
  /** fetch data from the table: "foobar" */
  foobar: Array<Foobar>;
  /** fetch aggregated fields from the table: "foobar" */
  foobar_aggregate: Foobar_Aggregate;
  /** execute function "getauthprovider" which returns "auth_providers" */
  getauthprovider?: Maybe<Auth_Providers>;
  /** execute function "getauthprovider" and query aggregates on result of table type "auth_providers" */
  getauthprovider_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "graph" */
  graph: Array<Graph>;
  /** fetch aggregated fields from the table: "graph" */
  graph_aggregate: Graph_Aggregate;
  /** fetch data from the table: "graph" using primary key columns */
  graph_by_pk?: Maybe<Graph>;
  /** fetch data from the table: "image_contents" */
  image_contents: Array<Image_Contents>;
  /** fetch aggregated fields from the table: "image_contents" */
  image_contents_aggregate: Image_Contents_Aggregate;
  /** fetch data from the table: "image_contents" using primary key columns */
  image_contents_by_pk?: Maybe<Image_Contents>;
  /** execute function "node_counts" which returns "node_jsonb" */
  node_counts: Array<Node_Jsonb>;
  /** execute function "node_counts" and query aggregates on result of table type "node_jsonb" */
  node_counts_aggregate: Node_Jsonb_Aggregate;
  /** fetch data from the table: "node_jsonb" */
  node_jsonb: Array<Node_Jsonb>;
  /** fetch aggregated fields from the table: "node_jsonb" */
  node_jsonb_aggregate: Node_Jsonb_Aggregate;
  /** fetch data from the table: "node_jsonb" using primary key columns */
  node_jsonb_by_pk?: Maybe<Node_Jsonb>;
  /** An array relationship */
  nodes: Array<Nodes>;
  /** An aggregate relationship */
  nodes_aggregate: Nodes_Aggregate;
  /** fetch data from the table: "nodes" using primary key columns */
  nodes_by_pk?: Maybe<Nodes>;
  /** fetch data from the table: "parent_child_nodes" */
  parent_child_nodes: Array<Parent_Child_Nodes>;
  /** fetch aggregated fields from the table: "parent_child_nodes" */
  parent_child_nodes_aggregate: Parent_Child_Nodes_Aggregate;
  /** fetch data from the table: "sso_domains" */
  sso_domains: Array<Sso_Domains>;
  /** fetch aggregated fields from the table: "sso_domains" */
  sso_domains_aggregate: Sso_Domains_Aggregate;
  /** fetch data from the table: "sso_domains" using primary key columns */
  sso_domains_by_pk?: Maybe<Sso_Domains>;
  /** fetch data from the table: "text_contents" */
  text_contents: Array<Text_Contents>;
  /** fetch aggregated fields from the table: "text_contents" */
  text_contents_aggregate: Text_Contents_Aggregate;
  /** fetch data from the table: "text_contents" using primary key columns */
  text_contents_by_pk?: Maybe<Text_Contents>;
  /** fetch data from the table: "text_result" */
  text_result: Array<Text_Result>;
  /** fetch aggregated fields from the table: "text_result" */
  text_result_aggregate: Text_Result_Aggregate;
  /** fetch data from the table: "user_test" */
  user_test: Array<User_Test>;
  /** fetch aggregated fields from the table: "user_test" */
  user_test_aggregate: User_Test_Aggregate;
  /** fetch data from the table: "user_test" using primary key columns */
  user_test_by_pk?: Maybe<User_Test>;
  /** fetch data from the table: "versions" */
  versions: Array<Versions>;
  /** fetch aggregated fields from the table: "versions" */
  versions_aggregate: Versions_Aggregate;
  /** fetch data from the table: "versions" using primary key columns */
  versions_by_pk?: Maybe<Versions>;
  /** fetch data from the table: "workflow" */
  workflow: Array<Workflow>;
  /** fetch aggregated fields from the table: "workflow" */
  workflow_aggregate: Workflow_Aggregate;
  /** fetch data from the table: "workflow" using primary key columns */
  workflow_by_pk?: Maybe<Workflow>;
  /** fetch data from the table: "workflows" */
  workflows: Array<Workflows>;
  /** fetch aggregated fields from the table: "workflows" */
  workflows_aggregate: Workflows_Aggregate;
  /** fetch data from the table: "workflows" using primary key columns */
  workflows_by_pk?: Maybe<Workflows>;
};


export type Fe_Proto_Query_RootAppsArgs = {
  distinct_on?: InputMaybe<Array<Apps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Order_By>>;
  where?: InputMaybe<Apps_Bool_Exp>;
};


export type Fe_Proto_Query_RootApps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Order_By>>;
  where?: InputMaybe<Apps_Bool_Exp>;
};


export type Fe_Proto_Query_RootApps_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Query_RootApps_V2Args = {
  distinct_on?: InputMaybe<Array<Apps_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_V2_Order_By>>;
  where?: InputMaybe<Apps_V2_Bool_Exp>;
};


export type Fe_Proto_Query_RootApps_V2_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_V2_Order_By>>;
  where?: InputMaybe<Apps_V2_Bool_Exp>;
};


export type Fe_Proto_Query_RootApps_V2_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Query_RootAuth_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


export type Fe_Proto_Query_RootAuth_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


export type Fe_Proto_Query_RootAuth_Providers_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Fe_Proto_Query_RootChildren_ResultArgs = {
  distinct_on?: InputMaybe<Array<Children_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Children_Result_Order_By>>;
  where?: InputMaybe<Children_Result_Bool_Exp>;
};


export type Fe_Proto_Query_RootChildren_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Children_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Children_Result_Order_By>>;
  where?: InputMaybe<Children_Result_Bool_Exp>;
};


export type Fe_Proto_Query_RootContent_TypesArgs = {
  distinct_on?: InputMaybe<Array<Content_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Types_Order_By>>;
  where?: InputMaybe<Content_Types_Bool_Exp>;
};


export type Fe_Proto_Query_RootContent_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Types_Order_By>>;
  where?: InputMaybe<Content_Types_Bool_Exp>;
};


export type Fe_Proto_Query_RootContent_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Fe_Proto_Query_RootContentsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Fe_Proto_Query_RootContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Fe_Proto_Query_RootContents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Query_RootDeploymentsArgs = {
  distinct_on?: InputMaybe<Array<Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deployments_Order_By>>;
  where?: InputMaybe<Deployments_Bool_Exp>;
};


export type Fe_Proto_Query_RootDeployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deployments_Order_By>>;
  where?: InputMaybe<Deployments_Bool_Exp>;
};


export type Fe_Proto_Query_RootDeployments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Query_RootDraftsArgs = {
  distinct_on?: InputMaybe<Array<Drafts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Drafts_Order_By>>;
  where?: InputMaybe<Drafts_Bool_Exp>;
};


export type Fe_Proto_Query_RootDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Drafts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Drafts_Order_By>>;
  where?: InputMaybe<Drafts_Bool_Exp>;
};


export type Fe_Proto_Query_RootDrafts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Query_RootEnvironmentsArgs = {
  distinct_on?: InputMaybe<Array<Environments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Environments_Order_By>>;
  where?: InputMaybe<Environments_Bool_Exp>;
};


export type Fe_Proto_Query_RootEnvironments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Environments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Environments_Order_By>>;
  where?: InputMaybe<Environments_Bool_Exp>;
};


export type Fe_Proto_Query_RootEnvironments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Query_RootFoobarArgs = {
  distinct_on?: InputMaybe<Array<Foobar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Foobar_Order_By>>;
  where?: InputMaybe<Foobar_Bool_Exp>;
};


export type Fe_Proto_Query_RootFoobar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Foobar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Foobar_Order_By>>;
  where?: InputMaybe<Foobar_Bool_Exp>;
};


export type Fe_Proto_Query_RootGetauthproviderArgs = {
  args: Getauthprovider_Args;
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


export type Fe_Proto_Query_RootGetauthprovider_AggregateArgs = {
  args: Getauthprovider_Args;
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


export type Fe_Proto_Query_RootGraphArgs = {
  distinct_on?: InputMaybe<Array<Graph_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Graph_Order_By>>;
  where?: InputMaybe<Graph_Bool_Exp>;
};


export type Fe_Proto_Query_RootGraph_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Graph_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Graph_Order_By>>;
  where?: InputMaybe<Graph_Bool_Exp>;
};


export type Fe_Proto_Query_RootGraph_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Query_RootImage_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Image_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Contents_Order_By>>;
  where?: InputMaybe<Image_Contents_Bool_Exp>;
};


export type Fe_Proto_Query_RootImage_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Image_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Contents_Order_By>>;
  where?: InputMaybe<Image_Contents_Bool_Exp>;
};


export type Fe_Proto_Query_RootImage_Contents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Query_RootNode_CountsArgs = {
  args: Node_Counts_Args;
  distinct_on?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Jsonb_Order_By>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};


export type Fe_Proto_Query_RootNode_Counts_AggregateArgs = {
  args: Node_Counts_Args;
  distinct_on?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Jsonb_Order_By>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};


export type Fe_Proto_Query_RootNode_JsonbArgs = {
  distinct_on?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Jsonb_Order_By>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};


export type Fe_Proto_Query_RootNode_Jsonb_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Jsonb_Order_By>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};


export type Fe_Proto_Query_RootNode_Jsonb_By_PkArgs = {
  number: Scalars['Int']['input'];
};


export type Fe_Proto_Query_RootNodesArgs = {
  distinct_on?: InputMaybe<Array<Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nodes_Order_By>>;
  where?: InputMaybe<Nodes_Bool_Exp>;
};


export type Fe_Proto_Query_RootNodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nodes_Order_By>>;
  where?: InputMaybe<Nodes_Bool_Exp>;
};


export type Fe_Proto_Query_RootNodes_By_PkArgs = {
  id: Scalars['uuid']['input'];
  number: Scalars['Int']['input'];
};


export type Fe_Proto_Query_RootParent_Child_NodesArgs = {
  distinct_on?: InputMaybe<Array<Parent_Child_Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_Child_Nodes_Order_By>>;
  where?: InputMaybe<Parent_Child_Nodes_Bool_Exp>;
};


export type Fe_Proto_Query_RootParent_Child_Nodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parent_Child_Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_Child_Nodes_Order_By>>;
  where?: InputMaybe<Parent_Child_Nodes_Bool_Exp>;
};


export type Fe_Proto_Query_RootSso_DomainsArgs = {
  distinct_on?: InputMaybe<Array<Sso_Domains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sso_Domains_Order_By>>;
  where?: InputMaybe<Sso_Domains_Bool_Exp>;
};


export type Fe_Proto_Query_RootSso_Domains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sso_Domains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sso_Domains_Order_By>>;
  where?: InputMaybe<Sso_Domains_Bool_Exp>;
};


export type Fe_Proto_Query_RootSso_Domains_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Query_RootText_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Text_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Contents_Order_By>>;
  where?: InputMaybe<Text_Contents_Bool_Exp>;
};


export type Fe_Proto_Query_RootText_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Contents_Order_By>>;
  where?: InputMaybe<Text_Contents_Bool_Exp>;
};


export type Fe_Proto_Query_RootText_Contents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Query_RootText_ResultArgs = {
  distinct_on?: InputMaybe<Array<Text_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Result_Order_By>>;
  where?: InputMaybe<Text_Result_Bool_Exp>;
};


export type Fe_Proto_Query_RootText_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Result_Order_By>>;
  where?: InputMaybe<Text_Result_Bool_Exp>;
};


export type Fe_Proto_Query_RootUser_TestArgs = {
  distinct_on?: InputMaybe<Array<User_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Test_Order_By>>;
  where?: InputMaybe<User_Test_Bool_Exp>;
};


export type Fe_Proto_Query_RootUser_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Test_Order_By>>;
  where?: InputMaybe<User_Test_Bool_Exp>;
};


export type Fe_Proto_Query_RootUser_Test_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Query_RootVersionsArgs = {
  distinct_on?: InputMaybe<Array<Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Versions_Order_By>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};


export type Fe_Proto_Query_RootVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Versions_Order_By>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};


export type Fe_Proto_Query_RootVersions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Query_RootWorkflowArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workflow_Order_By>>;
  where?: InputMaybe<Workflow_Bool_Exp>;
};


export type Fe_Proto_Query_RootWorkflow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workflow_Order_By>>;
  where?: InputMaybe<Workflow_Bool_Exp>;
};


export type Fe_Proto_Query_RootWorkflow_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Query_RootWorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workflows_Order_By>>;
  where?: InputMaybe<Workflows_Bool_Exp>;
};


export type Fe_Proto_Query_RootWorkflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workflows_Order_By>>;
  where?: InputMaybe<Workflows_Bool_Exp>;
};


export type Fe_Proto_Query_RootWorkflows_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Fe_Proto_Subscription_Root = {
  __typename?: 'fe_proto_subscription_root';
  /** fetch data from the table: "apps" */
  apps: Array<Apps>;
  /** fetch aggregated fields from the table: "apps" */
  apps_aggregate: Apps_Aggregate;
  /** fetch data from the table: "apps" using primary key columns */
  apps_by_pk?: Maybe<Apps>;
  /** fetch data from the table in a streaming manner: "apps" */
  apps_stream: Array<Apps>;
  /** fetch data from the table: "apps_v2" */
  apps_v2: Array<Apps_V2>;
  /** fetch aggregated fields from the table: "apps_v2" */
  apps_v2_aggregate: Apps_V2_Aggregate;
  /** fetch data from the table: "apps_v2" using primary key columns */
  apps_v2_by_pk?: Maybe<Apps_V2>;
  /** fetch data from the table in a streaming manner: "apps_v2" */
  apps_v2_stream: Array<Apps_V2>;
  /** fetch data from the table: "auth_providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth_providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth_providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table in a streaming manner: "auth_providers" */
  auth_providers_stream: Array<Auth_Providers>;
  /** fetch data from the table: "children_result" */
  children_result: Array<Children_Result>;
  /** fetch aggregated fields from the table: "children_result" */
  children_result_aggregate: Children_Result_Aggregate;
  /** fetch data from the table in a streaming manner: "children_result" */
  children_result_stream: Array<Children_Result>;
  /** fetch data from the table: "content_types" */
  content_types: Array<Content_Types>;
  /** fetch aggregated fields from the table: "content_types" */
  content_types_aggregate: Content_Types_Aggregate;
  /** fetch data from the table: "content_types" using primary key columns */
  content_types_by_pk?: Maybe<Content_Types>;
  /** fetch data from the table in a streaming manner: "content_types" */
  content_types_stream: Array<Content_Types>;
  /** An array relationship */
  contents: Array<Contents>;
  /** An aggregate relationship */
  contents_aggregate: Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  contents_by_pk?: Maybe<Contents>;
  /** fetch data from the table in a streaming manner: "contents" */
  contents_stream: Array<Contents>;
  /** fetch data from the table: "deployments" */
  deployments: Array<Deployments>;
  /** fetch aggregated fields from the table: "deployments" */
  deployments_aggregate: Deployments_Aggregate;
  /** fetch data from the table: "deployments" using primary key columns */
  deployments_by_pk?: Maybe<Deployments>;
  /** fetch data from the table in a streaming manner: "deployments" */
  deployments_stream: Array<Deployments>;
  /** fetch data from the table: "drafts" */
  drafts: Array<Drafts>;
  /** fetch aggregated fields from the table: "drafts" */
  drafts_aggregate: Drafts_Aggregate;
  /** fetch data from the table: "drafts" using primary key columns */
  drafts_by_pk?: Maybe<Drafts>;
  /** fetch data from the table in a streaming manner: "drafts" */
  drafts_stream: Array<Drafts>;
  /** fetch data from the table: "environments" */
  environments: Array<Environments>;
  /** fetch aggregated fields from the table: "environments" */
  environments_aggregate: Environments_Aggregate;
  /** fetch data from the table: "environments" using primary key columns */
  environments_by_pk?: Maybe<Environments>;
  /** fetch data from the table in a streaming manner: "environments" */
  environments_stream: Array<Environments>;
  /** fetch data from the table: "foobar" */
  foobar: Array<Foobar>;
  /** fetch aggregated fields from the table: "foobar" */
  foobar_aggregate: Foobar_Aggregate;
  /** fetch data from the table in a streaming manner: "foobar" */
  foobar_stream: Array<Foobar>;
  /** execute function "getauthprovider" which returns "auth_providers" */
  getauthprovider?: Maybe<Auth_Providers>;
  /** execute function "getauthprovider" and query aggregates on result of table type "auth_providers" */
  getauthprovider_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "graph" */
  graph: Array<Graph>;
  /** fetch aggregated fields from the table: "graph" */
  graph_aggregate: Graph_Aggregate;
  /** fetch data from the table: "graph" using primary key columns */
  graph_by_pk?: Maybe<Graph>;
  /** fetch data from the table in a streaming manner: "graph" */
  graph_stream: Array<Graph>;
  /** fetch data from the table: "image_contents" */
  image_contents: Array<Image_Contents>;
  /** fetch aggregated fields from the table: "image_contents" */
  image_contents_aggregate: Image_Contents_Aggregate;
  /** fetch data from the table: "image_contents" using primary key columns */
  image_contents_by_pk?: Maybe<Image_Contents>;
  /** fetch data from the table in a streaming manner: "image_contents" */
  image_contents_stream: Array<Image_Contents>;
  /** execute function "node_counts" which returns "node_jsonb" */
  node_counts: Array<Node_Jsonb>;
  /** execute function "node_counts" and query aggregates on result of table type "node_jsonb" */
  node_counts_aggregate: Node_Jsonb_Aggregate;
  /** fetch data from the table: "node_jsonb" */
  node_jsonb: Array<Node_Jsonb>;
  /** fetch aggregated fields from the table: "node_jsonb" */
  node_jsonb_aggregate: Node_Jsonb_Aggregate;
  /** fetch data from the table: "node_jsonb" using primary key columns */
  node_jsonb_by_pk?: Maybe<Node_Jsonb>;
  /** fetch data from the table in a streaming manner: "node_jsonb" */
  node_jsonb_stream: Array<Node_Jsonb>;
  /** An array relationship */
  nodes: Array<Nodes>;
  /** An aggregate relationship */
  nodes_aggregate: Nodes_Aggregate;
  /** fetch data from the table: "nodes" using primary key columns */
  nodes_by_pk?: Maybe<Nodes>;
  /** fetch data from the table in a streaming manner: "nodes" */
  nodes_stream: Array<Nodes>;
  /** fetch data from the table: "parent_child_nodes" */
  parent_child_nodes: Array<Parent_Child_Nodes>;
  /** fetch aggregated fields from the table: "parent_child_nodes" */
  parent_child_nodes_aggregate: Parent_Child_Nodes_Aggregate;
  /** fetch data from the table in a streaming manner: "parent_child_nodes" */
  parent_child_nodes_stream: Array<Parent_Child_Nodes>;
  /** fetch data from the table: "sso_domains" */
  sso_domains: Array<Sso_Domains>;
  /** fetch aggregated fields from the table: "sso_domains" */
  sso_domains_aggregate: Sso_Domains_Aggregate;
  /** fetch data from the table: "sso_domains" using primary key columns */
  sso_domains_by_pk?: Maybe<Sso_Domains>;
  /** fetch data from the table in a streaming manner: "sso_domains" */
  sso_domains_stream: Array<Sso_Domains>;
  /** fetch data from the table: "text_contents" */
  text_contents: Array<Text_Contents>;
  /** fetch aggregated fields from the table: "text_contents" */
  text_contents_aggregate: Text_Contents_Aggregate;
  /** fetch data from the table: "text_contents" using primary key columns */
  text_contents_by_pk?: Maybe<Text_Contents>;
  /** fetch data from the table in a streaming manner: "text_contents" */
  text_contents_stream: Array<Text_Contents>;
  /** fetch data from the table: "text_result" */
  text_result: Array<Text_Result>;
  /** fetch aggregated fields from the table: "text_result" */
  text_result_aggregate: Text_Result_Aggregate;
  /** fetch data from the table in a streaming manner: "text_result" */
  text_result_stream: Array<Text_Result>;
  /** fetch data from the table: "user_test" */
  user_test: Array<User_Test>;
  /** fetch aggregated fields from the table: "user_test" */
  user_test_aggregate: User_Test_Aggregate;
  /** fetch data from the table: "user_test" using primary key columns */
  user_test_by_pk?: Maybe<User_Test>;
  /** fetch data from the table in a streaming manner: "user_test" */
  user_test_stream: Array<User_Test>;
  /** fetch data from the table: "versions" */
  versions: Array<Versions>;
  /** fetch aggregated fields from the table: "versions" */
  versions_aggregate: Versions_Aggregate;
  /** fetch data from the table: "versions" using primary key columns */
  versions_by_pk?: Maybe<Versions>;
  /** fetch data from the table in a streaming manner: "versions" */
  versions_stream: Array<Versions>;
  /** fetch data from the table: "workflow" */
  workflow: Array<Workflow>;
  /** fetch aggregated fields from the table: "workflow" */
  workflow_aggregate: Workflow_Aggregate;
  /** fetch data from the table: "workflow" using primary key columns */
  workflow_by_pk?: Maybe<Workflow>;
  /** fetch data from the table in a streaming manner: "workflow" */
  workflow_stream: Array<Workflow>;
  /** fetch data from the table: "workflows" */
  workflows: Array<Workflows>;
  /** fetch aggregated fields from the table: "workflows" */
  workflows_aggregate: Workflows_Aggregate;
  /** fetch data from the table: "workflows" using primary key columns */
  workflows_by_pk?: Maybe<Workflows>;
  /** fetch data from the table in a streaming manner: "workflows" */
  workflows_stream: Array<Workflows>;
};


export type Fe_Proto_Subscription_RootAppsArgs = {
  distinct_on?: InputMaybe<Array<Apps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Order_By>>;
  where?: InputMaybe<Apps_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootApps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Order_By>>;
  where?: InputMaybe<Apps_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootApps_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Subscription_RootApps_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootApps_V2Args = {
  distinct_on?: InputMaybe<Array<Apps_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_V2_Order_By>>;
  where?: InputMaybe<Apps_V2_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootApps_V2_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_V2_Order_By>>;
  where?: InputMaybe<Apps_V2_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootApps_V2_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Subscription_RootApps_V2_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_V2_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_V2_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootAuth_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootAuth_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootAuth_Providers_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Fe_Proto_Subscription_RootAuth_Providers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Providers_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootChildren_ResultArgs = {
  distinct_on?: InputMaybe<Array<Children_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Children_Result_Order_By>>;
  where?: InputMaybe<Children_Result_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootChildren_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Children_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Children_Result_Order_By>>;
  where?: InputMaybe<Children_Result_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootChildren_Result_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Children_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Children_Result_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootContent_TypesArgs = {
  distinct_on?: InputMaybe<Array<Content_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Types_Order_By>>;
  where?: InputMaybe<Content_Types_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootContent_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Content_Types_Order_By>>;
  where?: InputMaybe<Content_Types_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootContent_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Fe_Proto_Subscription_RootContent_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Content_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Types_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootContentsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootContents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Subscription_RootContents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootDeploymentsArgs = {
  distinct_on?: InputMaybe<Array<Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deployments_Order_By>>;
  where?: InputMaybe<Deployments_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootDeployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deployments_Order_By>>;
  where?: InputMaybe<Deployments_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootDeployments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Subscription_RootDeployments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Deployments_Stream_Cursor_Input>>;
  where?: InputMaybe<Deployments_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootDraftsArgs = {
  distinct_on?: InputMaybe<Array<Drafts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Drafts_Order_By>>;
  where?: InputMaybe<Drafts_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootDrafts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Drafts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Drafts_Order_By>>;
  where?: InputMaybe<Drafts_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootDrafts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Subscription_RootDrafts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Drafts_Stream_Cursor_Input>>;
  where?: InputMaybe<Drafts_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootEnvironmentsArgs = {
  distinct_on?: InputMaybe<Array<Environments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Environments_Order_By>>;
  where?: InputMaybe<Environments_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootEnvironments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Environments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Environments_Order_By>>;
  where?: InputMaybe<Environments_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootEnvironments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Subscription_RootEnvironments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Environments_Stream_Cursor_Input>>;
  where?: InputMaybe<Environments_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootFoobarArgs = {
  distinct_on?: InputMaybe<Array<Foobar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Foobar_Order_By>>;
  where?: InputMaybe<Foobar_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootFoobar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Foobar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Foobar_Order_By>>;
  where?: InputMaybe<Foobar_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootFoobar_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Foobar_Stream_Cursor_Input>>;
  where?: InputMaybe<Foobar_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootGetauthproviderArgs = {
  args: Getauthprovider_Args;
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootGetauthprovider_AggregateArgs = {
  args: Getauthprovider_Args;
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootGraphArgs = {
  distinct_on?: InputMaybe<Array<Graph_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Graph_Order_By>>;
  where?: InputMaybe<Graph_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootGraph_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Graph_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Graph_Order_By>>;
  where?: InputMaybe<Graph_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootGraph_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Subscription_RootGraph_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Graph_Stream_Cursor_Input>>;
  where?: InputMaybe<Graph_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootImage_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Image_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Contents_Order_By>>;
  where?: InputMaybe<Image_Contents_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootImage_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Image_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Image_Contents_Order_By>>;
  where?: InputMaybe<Image_Contents_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootImage_Contents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Subscription_RootImage_Contents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Image_Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Image_Contents_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootNode_CountsArgs = {
  args: Node_Counts_Args;
  distinct_on?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Jsonb_Order_By>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootNode_Counts_AggregateArgs = {
  args: Node_Counts_Args;
  distinct_on?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Jsonb_Order_By>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootNode_JsonbArgs = {
  distinct_on?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Jsonb_Order_By>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootNode_Jsonb_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Jsonb_Order_By>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootNode_Jsonb_By_PkArgs = {
  number: Scalars['Int']['input'];
};


export type Fe_Proto_Subscription_RootNode_Jsonb_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Node_Jsonb_Stream_Cursor_Input>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootNodesArgs = {
  distinct_on?: InputMaybe<Array<Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nodes_Order_By>>;
  where?: InputMaybe<Nodes_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootNodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nodes_Order_By>>;
  where?: InputMaybe<Nodes_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootNodes_By_PkArgs = {
  id: Scalars['uuid']['input'];
  number: Scalars['Int']['input'];
};


export type Fe_Proto_Subscription_RootNodes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Nodes_Stream_Cursor_Input>>;
  where?: InputMaybe<Nodes_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootParent_Child_NodesArgs = {
  distinct_on?: InputMaybe<Array<Parent_Child_Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_Child_Nodes_Order_By>>;
  where?: InputMaybe<Parent_Child_Nodes_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootParent_Child_Nodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parent_Child_Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_Child_Nodes_Order_By>>;
  where?: InputMaybe<Parent_Child_Nodes_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootParent_Child_Nodes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Parent_Child_Nodes_Stream_Cursor_Input>>;
  where?: InputMaybe<Parent_Child_Nodes_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootSso_DomainsArgs = {
  distinct_on?: InputMaybe<Array<Sso_Domains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sso_Domains_Order_By>>;
  where?: InputMaybe<Sso_Domains_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootSso_Domains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sso_Domains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sso_Domains_Order_By>>;
  where?: InputMaybe<Sso_Domains_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootSso_Domains_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Subscription_RootSso_Domains_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sso_Domains_Stream_Cursor_Input>>;
  where?: InputMaybe<Sso_Domains_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootText_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Text_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Contents_Order_By>>;
  where?: InputMaybe<Text_Contents_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootText_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Contents_Order_By>>;
  where?: InputMaybe<Text_Contents_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootText_Contents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Subscription_RootText_Contents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Text_Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Text_Contents_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootText_ResultArgs = {
  distinct_on?: InputMaybe<Array<Text_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Result_Order_By>>;
  where?: InputMaybe<Text_Result_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootText_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Result_Order_By>>;
  where?: InputMaybe<Text_Result_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootText_Result_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Text_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Text_Result_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootUser_TestArgs = {
  distinct_on?: InputMaybe<Array<User_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Test_Order_By>>;
  where?: InputMaybe<User_Test_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootUser_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Test_Order_By>>;
  where?: InputMaybe<User_Test_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootUser_Test_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Subscription_RootUser_Test_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Test_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Test_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootVersionsArgs = {
  distinct_on?: InputMaybe<Array<Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Versions_Order_By>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Versions_Order_By>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootVersions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Fe_Proto_Subscription_RootVersions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Versions_Stream_Cursor_Input>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootWorkflowArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workflow_Order_By>>;
  where?: InputMaybe<Workflow_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootWorkflow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workflow_Order_By>>;
  where?: InputMaybe<Workflow_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootWorkflow_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Subscription_RootWorkflow_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Workflow_Stream_Cursor_Input>>;
  where?: InputMaybe<Workflow_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootWorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workflows_Order_By>>;
  where?: InputMaybe<Workflows_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootWorkflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workflows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Workflows_Order_By>>;
  where?: InputMaybe<Workflows_Bool_Exp>;
};


export type Fe_Proto_Subscription_RootWorkflows_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Fe_Proto_Subscription_RootWorkflows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Workflows_Stream_Cursor_Input>>;
  where?: InputMaybe<Workflows_Bool_Exp>;
};

/** columns and relationships of "foobar" */
export type Foobar = {
  __typename?: 'foobar';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "foobar" */
export type Foobar_Aggregate = {
  __typename?: 'foobar_aggregate';
  aggregate?: Maybe<Foobar_Aggregate_Fields>;
  nodes: Array<Foobar>;
};

/** aggregate fields of "foobar" */
export type Foobar_Aggregate_Fields = {
  __typename?: 'foobar_aggregate_fields';
  avg?: Maybe<Foobar_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Foobar_Max_Fields>;
  min?: Maybe<Foobar_Min_Fields>;
  stddev?: Maybe<Foobar_Stddev_Fields>;
  stddev_pop?: Maybe<Foobar_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Foobar_Stddev_Samp_Fields>;
  sum?: Maybe<Foobar_Sum_Fields>;
  var_pop?: Maybe<Foobar_Var_Pop_Fields>;
  var_samp?: Maybe<Foobar_Var_Samp_Fields>;
  variance?: Maybe<Foobar_Variance_Fields>;
};


/** aggregate fields of "foobar" */
export type Foobar_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Foobar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Foobar_Avg_Fields = {
  __typename?: 'foobar_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "foobar". All fields are combined with a logical 'AND'. */
export type Foobar_Bool_Exp = {
  _and?: InputMaybe<Array<Foobar_Bool_Exp>>;
  _not?: InputMaybe<Foobar_Bool_Exp>;
  _or?: InputMaybe<Array<Foobar_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "foobar" */
export type Foobar_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "foobar" */
export type Foobar_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Foobar_Max_Fields = {
  __typename?: 'foobar_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Foobar_Min_Fields = {
  __typename?: 'foobar_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "foobar" */
export type Foobar_Mutation_Response = {
  __typename?: 'foobar_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Foobar>;
};

/** Ordering options when selecting data from "foobar". */
export type Foobar_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** select columns of table "foobar" */
export enum Foobar_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "foobar" */
export type Foobar_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Foobar_Stddev_Fields = {
  __typename?: 'foobar_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Foobar_Stddev_Pop_Fields = {
  __typename?: 'foobar_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Foobar_Stddev_Samp_Fields = {
  __typename?: 'foobar_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "foobar" */
export type Foobar_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Foobar_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Foobar_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Foobar_Sum_Fields = {
  __typename?: 'foobar_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

export type Foobar_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Foobar_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Foobar_Set_Input>;
  /** filter the rows which have to be updated */
  where: Foobar_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Foobar_Var_Pop_Fields = {
  __typename?: 'foobar_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Foobar_Var_Samp_Fields = {
  __typename?: 'foobar_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Foobar_Variance_Fields = {
  __typename?: 'foobar_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type Getauthprovider_Args = {
  email_id?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "graph" */
export type Graph = {
  __typename?: 'graph';
  app_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  nodes: Array<Nodes>;
  /** An aggregate relationship */
  nodes_aggregate: Nodes_Aggregate;
  /** An array relationship */
  nodes_jsonb: Array<Node_Jsonb>;
  /** An aggregate relationship */
  nodes_jsonb_aggregate: Node_Jsonb_Aggregate;
};


/** columns and relationships of "graph" */
export type GraphNodesArgs = {
  distinct_on?: InputMaybe<Array<Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nodes_Order_By>>;
  where?: InputMaybe<Nodes_Bool_Exp>;
};


/** columns and relationships of "graph" */
export type GraphNodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nodes_Order_By>>;
  where?: InputMaybe<Nodes_Bool_Exp>;
};


/** columns and relationships of "graph" */
export type GraphNodes_JsonbArgs = {
  distinct_on?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Jsonb_Order_By>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};


/** columns and relationships of "graph" */
export type GraphNodes_Jsonb_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Jsonb_Order_By>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};

/** aggregated selection of "graph" */
export type Graph_Aggregate = {
  __typename?: 'graph_aggregate';
  aggregate?: Maybe<Graph_Aggregate_Fields>;
  nodes: Array<Graph>;
};

/** aggregate fields of "graph" */
export type Graph_Aggregate_Fields = {
  __typename?: 'graph_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Graph_Max_Fields>;
  min?: Maybe<Graph_Min_Fields>;
};


/** aggregate fields of "graph" */
export type Graph_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Graph_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "graph". All fields are combined with a logical 'AND'. */
export type Graph_Bool_Exp = {
  _and?: InputMaybe<Array<Graph_Bool_Exp>>;
  _not?: InputMaybe<Graph_Bool_Exp>;
  _or?: InputMaybe<Array<Graph_Bool_Exp>>;
  app_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nodes?: InputMaybe<Nodes_Bool_Exp>;
  nodes_aggregate?: InputMaybe<Nodes_Aggregate_Bool_Exp>;
  nodes_jsonb?: InputMaybe<Node_Jsonb_Bool_Exp>;
  nodes_jsonb_aggregate?: InputMaybe<Node_Jsonb_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "graph" */
export enum Graph_Constraint {
  /** unique or primary key constraint on columns "id" */
  GraphPkey = 'graph_pkey'
}

/** input type for inserting data into table "graph" */
export type Graph_Insert_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nodes?: InputMaybe<Nodes_Arr_Rel_Insert_Input>;
  nodes_jsonb?: InputMaybe<Node_Jsonb_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Graph_Max_Fields = {
  __typename?: 'graph_max_fields';
  app_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Graph_Min_Fields = {
  __typename?: 'graph_min_fields';
  app_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "graph" */
export type Graph_Mutation_Response = {
  __typename?: 'graph_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Graph>;
};

/** input type for inserting object relation for remote table "graph" */
export type Graph_Obj_Rel_Insert_Input = {
  data: Graph_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Graph_On_Conflict>;
};

/** on_conflict condition type for table "graph" */
export type Graph_On_Conflict = {
  constraint: Graph_Constraint;
  update_columns?: Array<Graph_Update_Column>;
  where?: InputMaybe<Graph_Bool_Exp>;
};

/** Ordering options when selecting data from "graph". */
export type Graph_Order_By = {
  app_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nodes_aggregate?: InputMaybe<Nodes_Aggregate_Order_By>;
  nodes_jsonb_aggregate?: InputMaybe<Node_Jsonb_Aggregate_Order_By>;
};

/** primary key columns input for table: graph */
export type Graph_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "graph" */
export enum Graph_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "graph" */
export type Graph_Set_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "graph" */
export type Graph_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Graph_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Graph_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "graph" */
export enum Graph_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Id = 'id'
}

export type Graph_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Graph_Set_Input>;
  /** filter the rows which have to be updated */
  where: Graph_Bool_Exp;
};

/** columns and relationships of "image_contents" */
export type Image_Contents = {
  __typename?: 'image_contents';
  alt_text: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  url: Scalars['String']['output'];
};

/** aggregated selection of "image_contents" */
export type Image_Contents_Aggregate = {
  __typename?: 'image_contents_aggregate';
  aggregate?: Maybe<Image_Contents_Aggregate_Fields>;
  nodes: Array<Image_Contents>;
};

/** aggregate fields of "image_contents" */
export type Image_Contents_Aggregate_Fields = {
  __typename?: 'image_contents_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Image_Contents_Max_Fields>;
  min?: Maybe<Image_Contents_Min_Fields>;
};


/** aggregate fields of "image_contents" */
export type Image_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Image_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "image_contents". All fields are combined with a logical 'AND'. */
export type Image_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Image_Contents_Bool_Exp>>;
  _not?: InputMaybe<Image_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Image_Contents_Bool_Exp>>;
  alt_text?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "image_contents" */
export enum Image_Contents_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImageContentsPkey = 'image_contents_pkey'
}

/** input type for inserting data into table "image_contents" */
export type Image_Contents_Insert_Input = {
  alt_text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Image_Contents_Max_Fields = {
  __typename?: 'image_contents_max_fields';
  alt_text?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Image_Contents_Min_Fields = {
  __typename?: 'image_contents_min_fields';
  alt_text?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "image_contents" */
export type Image_Contents_Mutation_Response = {
  __typename?: 'image_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Image_Contents>;
};

/** on_conflict condition type for table "image_contents" */
export type Image_Contents_On_Conflict = {
  constraint: Image_Contents_Constraint;
  update_columns?: Array<Image_Contents_Update_Column>;
  where?: InputMaybe<Image_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "image_contents". */
export type Image_Contents_Order_By = {
  alt_text?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: image_contents */
export type Image_Contents_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "image_contents" */
export enum Image_Contents_Select_Column {
  /** column name */
  AltText = 'alt_text',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "image_contents" */
export type Image_Contents_Set_Input = {
  alt_text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "image_contents" */
export type Image_Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Image_Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Image_Contents_Stream_Cursor_Value_Input = {
  alt_text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "image_contents" */
export enum Image_Contents_Update_Column {
  /** column name */
  AltText = 'alt_text',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

export type Image_Contents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Image_Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Image_Contents_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  admin_?: Maybe<Admin_Mutation>;
  app_loader_?: Maybe<App_Loader_Mutation_Root>;
  deleteBotAssetById?: Maybe<Asset>;
  deleteBotById?: Maybe<Bot>;
  deleteCustomerById: Scalars['String']['output'];
  /** customer. */
  deleteCustomerPackages?: Maybe<Customer>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  deleteOneIntent?: Maybe<Version>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  deleteOneSynonym?: Maybe<Version>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  deleteOneTypeEntity?: Maybe<Version>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  deleteOneUtterance?: Maybe<Version>;
  deleteVersionScriptById?: Maybe<Version>;
  /** delete data from the table: "app_loader.data_store" */
  delete_app_loader_data_store?: Maybe<App_Loader_Data_Store_Mutation_Response>;
  /** delete single row from the table: "app_loader.data_store" */
  delete_app_loader_data_store_by_pk?: Maybe<App_Loader_Data_Store>;
  /** delete data from the table: "apps.app" */
  delete_apps_app?: Maybe<Apps_App_Mutation_Response>;
  /** delete single row from the table: "apps.app" */
  delete_apps_app_by_pk?: Maybe<Apps_App>;
  /** delete data from the table: "apps.app_config" */
  delete_apps_app_config?: Maybe<Apps_App_Config_Mutation_Response>;
  /** delete single row from the table: "apps.app_config" */
  delete_apps_app_config_by_pk?: Maybe<Apps_App_Config>;
  /** delete data from the table: "apps.app_file" */
  delete_apps_app_file?: Maybe<Apps_App_File_Mutation_Response>;
  /** delete single row from the table: "apps.app_file" */
  delete_apps_app_file_by_pk?: Maybe<Apps_App_File>;
  /** delete data from the table: "apps.convert_roundtrip" */
  delete_apps_convert_roundtrip?: Maybe<Apps_Convert_Roundtrip_Mutation_Response>;
  /** delete single row from the table: "apps.convert_roundtrip" */
  delete_apps_convert_roundtrip_by_pk?: Maybe<Apps_Convert_Roundtrip>;
  /** delete data from the table: "apps.csv_file" */
  delete_apps_csv_file?: Maybe<Apps_Csv_File_Mutation_Response>;
  /** delete single row from the table: "apps.csv_file" */
  delete_apps_csv_file_by_pk?: Maybe<Apps_Csv_File>;
  /** delete data from the table: "apps.customer" */
  delete_apps_customer?: Maybe<Apps_Customer_Mutation_Response>;
  /** delete single row from the table: "apps.customer" */
  delete_apps_customer_by_pk?: Maybe<Apps_Customer>;
  /** delete data from the table: "apps.customer_config" */
  delete_apps_customer_config?: Maybe<Apps_Customer_Config_Mutation_Response>;
  /** delete single row from the table: "apps.customer_config" */
  delete_apps_customer_config_by_pk?: Maybe<Apps_Customer_Config>;
  /** delete data from the table: "apps.deployment" */
  delete_apps_deployment?: Maybe<Apps_Deployment_Mutation_Response>;
  /** delete single row from the table: "apps.deployment" */
  delete_apps_deployment_by_pk?: Maybe<Apps_Deployment>;
  /** delete data from the table: "apps.environment" */
  delete_apps_environment?: Maybe<Apps_Environment_Mutation_Response>;
  /** delete single row from the table: "apps.environment" */
  delete_apps_environment_by_pk?: Maybe<Apps_Environment>;
  /** delete data from the table: "apps.file" */
  delete_apps_file?: Maybe<Apps_File_Mutation_Response>;
  /** delete single row from the table: "apps.file" */
  delete_apps_file_by_pk?: Maybe<Apps_File>;
  /** delete data from the table: "apps.import_fail" */
  delete_apps_import_fail?: Maybe<Apps_Import_Fail_Mutation_Response>;
  /** delete single row from the table: "apps.import_fail" */
  delete_apps_import_fail_by_pk?: Maybe<Apps_Import_Fail>;
  /** delete data from the table: "apps.import_fail_node" */
  delete_apps_import_fail_node?: Maybe<Apps_Import_Fail_Node_Mutation_Response>;
  /** delete single row from the table: "apps.import_fail_node" */
  delete_apps_import_fail_node_by_pk?: Maybe<Apps_Import_Fail_Node>;
  /** delete data from the table: "apps.nlu_files" */
  delete_apps_nlu_files?: Maybe<Apps_Nlu_Files_Mutation_Response>;
  /** delete single row from the table: "apps.nlu_files" */
  delete_apps_nlu_files_by_pk?: Maybe<Apps_Nlu_Files>;
  /** delete data from the table: "apps.node" */
  delete_apps_node?: Maybe<Apps_Node_Mutation_Response>;
  /** delete single row from the table: "apps.node" */
  delete_apps_node_by_pk?: Maybe<Apps_Node>;
  /** delete data from the table: "apps.node_import_flat" */
  delete_apps_node_import_flat?: Maybe<Apps_Node_Import_Flat_Mutation_Response>;
  /** delete single row from the table: "apps.node_import_flat" */
  delete_apps_node_import_flat_by_pk?: Maybe<Apps_Node_Import_Flat>;
  /** delete data from the table: "apps.node_import_json" */
  delete_apps_node_import_json?: Maybe<Apps_Node_Import_Json_Mutation_Response>;
  /** delete single row from the table: "apps.node_import_json" */
  delete_apps_node_import_json_by_pk?: Maybe<Apps_Node_Import_Json>;
  /** delete data from the table: "apps.node_instance" */
  delete_apps_node_instance?: Maybe<Apps_Node_Instance_Mutation_Response>;
  /** delete single row from the table: "apps.node_instance" */
  delete_apps_node_instance_by_pk?: Maybe<Apps_Node_Instance>;
  /** delete data from the table: "apps.rich_asset" */
  delete_apps_rich_asset?: Maybe<Apps_Rich_Asset_Mutation_Response>;
  /** delete single row from the table: "apps.rich_asset" */
  delete_apps_rich_asset_by_pk?: Maybe<Apps_Rich_Asset>;
  /** delete data from the table: "apps.version" */
  delete_apps_version?: Maybe<Apps_Version_Mutation_Response>;
  /** delete single row from the table: "apps.version" */
  delete_apps_version_by_pk?: Maybe<Apps_Version>;
  /** delete data from the table: "apps.version_display_data" */
  delete_apps_version_display_data?: Maybe<Apps_Version_Display_Data_Mutation_Response>;
  /** delete single row from the table: "apps.version_display_data" */
  delete_apps_version_display_data_by_pk?: Maybe<Apps_Version_Display_Data>;
  /** delete data from the table: "apps.version_file" */
  delete_apps_version_file?: Maybe<Apps_Version_File_Mutation_Response>;
  /** delete single row from the table: "apps.version_file" */
  delete_apps_version_file_by_pk?: Maybe<Apps_Version_File>;
  /** delete data from the table: "apps.version_node_instance" */
  delete_apps_version_node_instance?: Maybe<Apps_Version_Node_Instance_Mutation_Response>;
  /** delete single row from the table: "apps.version_node_instance" */
  delete_apps_version_node_instance_by_pk?: Maybe<Apps_Version_Node_Instance>;
  /** delete data from the table: "tenancy._ProjectReleaseChannelConfigToVariable" */
  delete_tenancy__ProjectReleaseChannelConfigToVariable?: Maybe<Tenancy__ProjectReleaseChannelConfigToVariable_Mutation_Response>;
  /** delete data from the table: "tenancy._prisma_migrations" */
  delete_tenancy__prisma_migrations?: Maybe<Tenancy__Prisma_Migrations_Mutation_Response>;
  /** delete single row from the table: "tenancy._prisma_migrations" */
  delete_tenancy__prisma_migrations_by_pk?: Maybe<Tenancy__Prisma_Migrations>;
  /** delete data from the table: "tenancy._role_projects" */
  delete_tenancy__role_projects?: Maybe<Tenancy__Role_Projects_Mutation_Response>;
  /** delete data from the table: "tenancy._team_account_project_roles" */
  delete_tenancy__team_account_project_roles?: Maybe<Tenancy__Team_Account_Project_Roles_Mutation_Response>;
  /** delete data from the table: "tenancy._team_account_release_channel_config_roles" */
  delete_tenancy__team_account_release_channel_config_roles?: Maybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Mutation_Response>;
  /** delete data from the table: "tenancy._team_account_roles" */
  delete_tenancy__team_account_roles?: Maybe<Tenancy__Team_Account_Roles_Mutation_Response>;
  /** delete data from the table: "tenancy._user_account_project_roles" */
  delete_tenancy__user_account_project_roles?: Maybe<Tenancy__User_Account_Project_Roles_Mutation_Response>;
  /** delete data from the table: "tenancy._user_account_release_channel_config_roles" */
  delete_tenancy__user_account_release_channel_config_roles?: Maybe<Tenancy__User_Account_Release_Channel_Config_Roles_Mutation_Response>;
  /** delete data from the table: "tenancy._user_account_roles" */
  delete_tenancy__user_account_roles?: Maybe<Tenancy__User_Account_Roles_Mutation_Response>;
  /** delete data from the table: "tenancy._user_teams" */
  delete_tenancy__user_teams?: Maybe<Tenancy__User_Teams_Mutation_Response>;
  /** delete data from the table: "tenancy.account_scheduled_job_runs" */
  delete_tenancy_account_scheduled_job_runs?: Maybe<Tenancy_Account_Scheduled_Job_Runs_Mutation_Response>;
  /** delete single row from the table: "tenancy.account_scheduled_job_runs" */
  delete_tenancy_account_scheduled_job_runs_by_pk?: Maybe<Tenancy_Account_Scheduled_Job_Runs>;
  /** delete data from the table: "tenancy.account_scheduled_jobs" */
  delete_tenancy_account_scheduled_jobs?: Maybe<Tenancy_Account_Scheduled_Jobs_Mutation_Response>;
  /** delete single row from the table: "tenancy.account_scheduled_jobs" */
  delete_tenancy_account_scheduled_jobs_by_pk?: Maybe<Tenancy_Account_Scheduled_Jobs>;
  /** delete data from the table: "tenancy.accounts" */
  delete_tenancy_accounts?: Maybe<Tenancy_Accounts_Mutation_Response>;
  /** delete single row from the table: "tenancy.accounts" */
  delete_tenancy_accounts_by_pk?: Maybe<Tenancy_Accounts>;
  /** delete data from the table: "tenancy.action" */
  delete_tenancy_action?: Maybe<Tenancy_Action_Mutation_Response>;
  /** delete single row from the table: "tenancy.action" */
  delete_tenancy_action_by_pk?: Maybe<Tenancy_Action>;
  /** delete data from the table: "tenancy.app_config" */
  delete_tenancy_app_config?: Maybe<Tenancy_App_Config_Mutation_Response>;
  /** delete single row from the table: "tenancy.app_config" */
  delete_tenancy_app_config_by_pk?: Maybe<Tenancy_App_Config>;
  /** delete data from the table: "tenancy.applet" */
  delete_tenancy_applet?: Maybe<Tenancy_Applet_Mutation_Response>;
  /** delete single row from the table: "tenancy.applet" */
  delete_tenancy_applet_by_pk?: Maybe<Tenancy_Applet>;
  /** delete data from the table: "tenancy.applet_instance" */
  delete_tenancy_applet_instance?: Maybe<Tenancy_Applet_Instance_Mutation_Response>;
  /** delete single row from the table: "tenancy.applet_instance" */
  delete_tenancy_applet_instance_by_pk?: Maybe<Tenancy_Applet_Instance>;
  /** delete data from the table: "tenancy.applet_version" */
  delete_tenancy_applet_version?: Maybe<Tenancy_Applet_Version_Mutation_Response>;
  /** delete single row from the table: "tenancy.applet_version" */
  delete_tenancy_applet_version_by_pk?: Maybe<Tenancy_Applet_Version>;
  /** delete data from the table: "tenancy.audit_logs" */
  delete_tenancy_audit_logs?: Maybe<Tenancy_Audit_Logs_Mutation_Response>;
  /** delete single row from the table: "tenancy.audit_logs" */
  delete_tenancy_audit_logs_by_pk?: Maybe<Tenancy_Audit_Logs>;
  /** delete data from the table: "tenancy.condition" */
  delete_tenancy_condition?: Maybe<Tenancy_Condition_Mutation_Response>;
  /** delete single row from the table: "tenancy.condition" */
  delete_tenancy_condition_by_pk?: Maybe<Tenancy_Condition>;
  /** delete data from the table: "tenancy.country" */
  delete_tenancy_country?: Maybe<Tenancy_Country_Mutation_Response>;
  /** delete single row from the table: "tenancy.country" */
  delete_tenancy_country_by_pk?: Maybe<Tenancy_Country>;
  /** delete data from the table: "tenancy.domain" */
  delete_tenancy_domain?: Maybe<Tenancy_Domain_Mutation_Response>;
  /** delete single row from the table: "tenancy.domain" */
  delete_tenancy_domain_by_pk?: Maybe<Tenancy_Domain>;
  /** delete data from the table: "tenancy.failed_kafka_events" */
  delete_tenancy_failed_kafka_events?: Maybe<Tenancy_Failed_Kafka_Events_Mutation_Response>;
  /** delete single row from the table: "tenancy.failed_kafka_events" */
  delete_tenancy_failed_kafka_events_by_pk?: Maybe<Tenancy_Failed_Kafka_Events>;
  /** delete data from the table: "tenancy.file" */
  delete_tenancy_file?: Maybe<Tenancy_File_Mutation_Response>;
  /** delete single row from the table: "tenancy.file" */
  delete_tenancy_file_by_pk?: Maybe<Tenancy_File>;
  /** delete data from the table: "tenancy.locale" */
  delete_tenancy_locale?: Maybe<Tenancy_Locale_Mutation_Response>;
  /** delete single row from the table: "tenancy.locale" */
  delete_tenancy_locale_by_pk?: Maybe<Tenancy_Locale>;
  /** delete data from the table: "tenancy.localization_settings" */
  delete_tenancy_localization_settings?: Maybe<Tenancy_Localization_Settings_Mutation_Response>;
  /** delete single row from the table: "tenancy.localization_settings" */
  delete_tenancy_localization_settings_by_pk?: Maybe<Tenancy_Localization_Settings>;
  /** delete data from the table: "tenancy.package" */
  delete_tenancy_package?: Maybe<Tenancy_Package_Mutation_Response>;
  /** delete single row from the table: "tenancy.package" */
  delete_tenancy_package_by_pk?: Maybe<Tenancy_Package>;
  /** delete data from the table: "tenancy.package_version" */
  delete_tenancy_package_version?: Maybe<Tenancy_Package_Version_Mutation_Response>;
  /** delete single row from the table: "tenancy.package_version" */
  delete_tenancy_package_version_by_pk?: Maybe<Tenancy_Package_Version>;
  /** delete data from the table: "tenancy.products" */
  delete_tenancy_products?: Maybe<Tenancy_Products_Mutation_Response>;
  /** delete single row from the table: "tenancy.products" */
  delete_tenancy_products_by_pk?: Maybe<Tenancy_Products>;
  /** delete data from the table: "tenancy.project_product_settings" */
  delete_tenancy_project_product_settings?: Maybe<Tenancy_Project_Product_Settings_Mutation_Response>;
  /** delete single row from the table: "tenancy.project_product_settings" */
  delete_tenancy_project_product_settings_by_pk?: Maybe<Tenancy_Project_Product_Settings>;
  /** delete data from the table: "tenancy.project_release_channel_config" */
  delete_tenancy_project_release_channel_config?: Maybe<Tenancy_Project_Release_Channel_Config_Mutation_Response>;
  /** delete single row from the table: "tenancy.project_release_channel_config" */
  delete_tenancy_project_release_channel_config_by_pk?: Maybe<Tenancy_Project_Release_Channel_Config>;
  /** delete data from the table: "tenancy.projects" */
  delete_tenancy_projects?: Maybe<Tenancy_Projects_Mutation_Response>;
  /** delete single row from the table: "tenancy.projects" */
  delete_tenancy_projects_by_pk?: Maybe<Tenancy_Projects>;
  /** delete data from the table: "tenancy.release_channel" */
  delete_tenancy_release_channel?: Maybe<Tenancy_Release_Channel_Mutation_Response>;
  /** delete single row from the table: "tenancy.release_channel" */
  delete_tenancy_release_channel_by_pk?: Maybe<Tenancy_Release_Channel>;
  /** delete data from the table: "tenancy.roles" */
  delete_tenancy_roles?: Maybe<Tenancy_Roles_Mutation_Response>;
  /** delete single row from the table: "tenancy.roles" */
  delete_tenancy_roles_by_pk?: Maybe<Tenancy_Roles>;
  /** delete data from the table: "tenancy.scheduled_jobs" */
  delete_tenancy_scheduled_jobs?: Maybe<Tenancy_Scheduled_Jobs_Mutation_Response>;
  /** delete single row from the table: "tenancy.scheduled_jobs" */
  delete_tenancy_scheduled_jobs_by_pk?: Maybe<Tenancy_Scheduled_Jobs>;
  /** delete data from the table: "tenancy.team_account_projects" */
  delete_tenancy_team_account_projects?: Maybe<Tenancy_Team_Account_Projects_Mutation_Response>;
  /** delete single row from the table: "tenancy.team_account_projects" */
  delete_tenancy_team_account_projects_by_pk?: Maybe<Tenancy_Team_Account_Projects>;
  /** delete data from the table: "tenancy.team_account_release_channel_config" */
  delete_tenancy_team_account_release_channel_config?: Maybe<Tenancy_Team_Account_Release_Channel_Config_Mutation_Response>;
  /** delete single row from the table: "tenancy.team_account_release_channel_config" */
  delete_tenancy_team_account_release_channel_config_by_pk?: Maybe<Tenancy_Team_Account_Release_Channel_Config>;
  /** delete data from the table: "tenancy.team_accounts" */
  delete_tenancy_team_accounts?: Maybe<Tenancy_Team_Accounts_Mutation_Response>;
  /** delete single row from the table: "tenancy.team_accounts" */
  delete_tenancy_team_accounts_by_pk?: Maybe<Tenancy_Team_Accounts>;
  /** delete data from the table: "tenancy.teams" */
  delete_tenancy_teams?: Maybe<Tenancy_Teams_Mutation_Response>;
  /** delete single row from the table: "tenancy.teams" */
  delete_tenancy_teams_by_pk?: Maybe<Tenancy_Teams>;
  /** delete data from the table: "tenancy.time_zone" */
  delete_tenancy_time_zone?: Maybe<Tenancy_Time_Zone_Mutation_Response>;
  /** delete single row from the table: "tenancy.time_zone" */
  delete_tenancy_time_zone_by_pk?: Maybe<Tenancy_Time_Zone>;
  /** delete data from the table: "tenancy.trigger" */
  delete_tenancy_trigger?: Maybe<Tenancy_Trigger_Mutation_Response>;
  /** delete single row from the table: "tenancy.trigger" */
  delete_tenancy_trigger_by_pk?: Maybe<Tenancy_Trigger>;
  /** delete data from the table: "tenancy.user_account_projects" */
  delete_tenancy_user_account_projects?: Maybe<Tenancy_User_Account_Projects_Mutation_Response>;
  /** delete single row from the table: "tenancy.user_account_projects" */
  delete_tenancy_user_account_projects_by_pk?: Maybe<Tenancy_User_Account_Projects>;
  /** delete data from the table: "tenancy.user_account_release_channel_config" */
  delete_tenancy_user_account_release_channel_config?: Maybe<Tenancy_User_Account_Release_Channel_Config_Mutation_Response>;
  /** delete single row from the table: "tenancy.user_account_release_channel_config" */
  delete_tenancy_user_account_release_channel_config_by_pk?: Maybe<Tenancy_User_Account_Release_Channel_Config>;
  /** delete data from the table: "tenancy.user_accounts" */
  delete_tenancy_user_accounts?: Maybe<Tenancy_User_Accounts_Mutation_Response>;
  /** delete single row from the table: "tenancy.user_accounts" */
  delete_tenancy_user_accounts_by_pk?: Maybe<Tenancy_User_Accounts>;
  /** delete data from the table: "tenancy.users" */
  delete_tenancy_users?: Maybe<Tenancy_Users_Mutation_Response>;
  /** delete single row from the table: "tenancy.users" */
  delete_tenancy_users_by_pk?: Maybe<Tenancy_Users>;
  /** delete data from the table: "tenancy.variable" */
  delete_tenancy_variable?: Maybe<Tenancy_Variable_Mutation_Response>;
  /** delete single row from the table: "tenancy.variable" */
  delete_tenancy_variable_by_pk?: Maybe<Tenancy_Variable>;
  fe_proto_?: Maybe<Fe_Proto_Mutation_Root>;
  /** insert data into the table: "app_loader.data_store" */
  insert_app_loader_data_store?: Maybe<App_Loader_Data_Store_Mutation_Response>;
  /** insert a single row into the table: "app_loader.data_store" */
  insert_app_loader_data_store_one?: Maybe<App_Loader_Data_Store>;
  /** insert data into the table: "apps.app" */
  insert_apps_app?: Maybe<Apps_App_Mutation_Response>;
  /** insert data into the table: "apps.app_config" */
  insert_apps_app_config?: Maybe<Apps_App_Config_Mutation_Response>;
  /** insert a single row into the table: "apps.app_config" */
  insert_apps_app_config_one?: Maybe<Apps_App_Config>;
  /** insert data into the table: "apps.app_file" */
  insert_apps_app_file?: Maybe<Apps_App_File_Mutation_Response>;
  /** insert a single row into the table: "apps.app_file" */
  insert_apps_app_file_one?: Maybe<Apps_App_File>;
  /** insert a single row into the table: "apps.app" */
  insert_apps_app_one?: Maybe<Apps_App>;
  /** insert data into the table: "apps.convert_roundtrip" */
  insert_apps_convert_roundtrip?: Maybe<Apps_Convert_Roundtrip_Mutation_Response>;
  /** insert a single row into the table: "apps.convert_roundtrip" */
  insert_apps_convert_roundtrip_one?: Maybe<Apps_Convert_Roundtrip>;
  /** insert data into the table: "apps.csv_file" */
  insert_apps_csv_file?: Maybe<Apps_Csv_File_Mutation_Response>;
  /** insert a single row into the table: "apps.csv_file" */
  insert_apps_csv_file_one?: Maybe<Apps_Csv_File>;
  /** insert data into the table: "apps.customer" */
  insert_apps_customer?: Maybe<Apps_Customer_Mutation_Response>;
  /** insert data into the table: "apps.customer_config" */
  insert_apps_customer_config?: Maybe<Apps_Customer_Config_Mutation_Response>;
  /** insert a single row into the table: "apps.customer_config" */
  insert_apps_customer_config_one?: Maybe<Apps_Customer_Config>;
  /** insert a single row into the table: "apps.customer" */
  insert_apps_customer_one?: Maybe<Apps_Customer>;
  /** insert data into the table: "apps.deployment" */
  insert_apps_deployment?: Maybe<Apps_Deployment_Mutation_Response>;
  /** insert a single row into the table: "apps.deployment" */
  insert_apps_deployment_one?: Maybe<Apps_Deployment>;
  /** insert data into the table: "apps.environment" */
  insert_apps_environment?: Maybe<Apps_Environment_Mutation_Response>;
  /** insert a single row into the table: "apps.environment" */
  insert_apps_environment_one?: Maybe<Apps_Environment>;
  /** insert data into the table: "apps.file" */
  insert_apps_file?: Maybe<Apps_File_Mutation_Response>;
  /** insert a single row into the table: "apps.file" */
  insert_apps_file_one?: Maybe<Apps_File>;
  /** insert data into the table: "apps.import_fail" */
  insert_apps_import_fail?: Maybe<Apps_Import_Fail_Mutation_Response>;
  /** insert data into the table: "apps.import_fail_node" */
  insert_apps_import_fail_node?: Maybe<Apps_Import_Fail_Node_Mutation_Response>;
  /** insert a single row into the table: "apps.import_fail_node" */
  insert_apps_import_fail_node_one?: Maybe<Apps_Import_Fail_Node>;
  /** insert a single row into the table: "apps.import_fail" */
  insert_apps_import_fail_one?: Maybe<Apps_Import_Fail>;
  /** insert data into the table: "apps.nlu_files" */
  insert_apps_nlu_files?: Maybe<Apps_Nlu_Files_Mutation_Response>;
  /** insert a single row into the table: "apps.nlu_files" */
  insert_apps_nlu_files_one?: Maybe<Apps_Nlu_Files>;
  /** insert data into the table: "apps.node" */
  insert_apps_node?: Maybe<Apps_Node_Mutation_Response>;
  /** insert data into the table: "apps.node_import_flat" */
  insert_apps_node_import_flat?: Maybe<Apps_Node_Import_Flat_Mutation_Response>;
  /** insert a single row into the table: "apps.node_import_flat" */
  insert_apps_node_import_flat_one?: Maybe<Apps_Node_Import_Flat>;
  /** insert data into the table: "apps.node_import_json" */
  insert_apps_node_import_json?: Maybe<Apps_Node_Import_Json_Mutation_Response>;
  /** insert a single row into the table: "apps.node_import_json" */
  insert_apps_node_import_json_one?: Maybe<Apps_Node_Import_Json>;
  /** insert data into the table: "apps.node_instance" */
  insert_apps_node_instance?: Maybe<Apps_Node_Instance_Mutation_Response>;
  /** insert a single row into the table: "apps.node_instance" */
  insert_apps_node_instance_one?: Maybe<Apps_Node_Instance>;
  /** insert a single row into the table: "apps.node" */
  insert_apps_node_one?: Maybe<Apps_Node>;
  /** insert data into the table: "apps.rich_asset" */
  insert_apps_rich_asset?: Maybe<Apps_Rich_Asset_Mutation_Response>;
  /** insert a single row into the table: "apps.rich_asset" */
  insert_apps_rich_asset_one?: Maybe<Apps_Rich_Asset>;
  /** insert data into the table: "apps.version" */
  insert_apps_version?: Maybe<Apps_Version_Mutation_Response>;
  /** insert data into the table: "apps.version_display_data" */
  insert_apps_version_display_data?: Maybe<Apps_Version_Display_Data_Mutation_Response>;
  /** insert a single row into the table: "apps.version_display_data" */
  insert_apps_version_display_data_one?: Maybe<Apps_Version_Display_Data>;
  /** insert data into the table: "apps.version_file" */
  insert_apps_version_file?: Maybe<Apps_Version_File_Mutation_Response>;
  /** insert a single row into the table: "apps.version_file" */
  insert_apps_version_file_one?: Maybe<Apps_Version_File>;
  /** insert data into the table: "apps.version_node_instance" */
  insert_apps_version_node_instance?: Maybe<Apps_Version_Node_Instance_Mutation_Response>;
  /** insert a single row into the table: "apps.version_node_instance" */
  insert_apps_version_node_instance_one?: Maybe<Apps_Version_Node_Instance>;
  /** insert a single row into the table: "apps.version" */
  insert_apps_version_one?: Maybe<Apps_Version>;
  /** insert data into the table: "tenancy._ProjectReleaseChannelConfigToVariable" */
  insert_tenancy__ProjectReleaseChannelConfigToVariable?: Maybe<Tenancy__ProjectReleaseChannelConfigToVariable_Mutation_Response>;
  /** insert a single row into the table: "tenancy._ProjectReleaseChannelConfigToVariable" */
  insert_tenancy__ProjectReleaseChannelConfigToVariable_one?: Maybe<Tenancy__ProjectReleaseChannelConfigToVariable>;
  /** insert data into the table: "tenancy._prisma_migrations" */
  insert_tenancy__prisma_migrations?: Maybe<Tenancy__Prisma_Migrations_Mutation_Response>;
  /** insert a single row into the table: "tenancy._prisma_migrations" */
  insert_tenancy__prisma_migrations_one?: Maybe<Tenancy__Prisma_Migrations>;
  /** insert data into the table: "tenancy._role_projects" */
  insert_tenancy__role_projects?: Maybe<Tenancy__Role_Projects_Mutation_Response>;
  /** insert a single row into the table: "tenancy._role_projects" */
  insert_tenancy__role_projects_one?: Maybe<Tenancy__Role_Projects>;
  /** insert data into the table: "tenancy._team_account_project_roles" */
  insert_tenancy__team_account_project_roles?: Maybe<Tenancy__Team_Account_Project_Roles_Mutation_Response>;
  /** insert a single row into the table: "tenancy._team_account_project_roles" */
  insert_tenancy__team_account_project_roles_one?: Maybe<Tenancy__Team_Account_Project_Roles>;
  /** insert data into the table: "tenancy._team_account_release_channel_config_roles" */
  insert_tenancy__team_account_release_channel_config_roles?: Maybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Mutation_Response>;
  /** insert a single row into the table: "tenancy._team_account_release_channel_config_roles" */
  insert_tenancy__team_account_release_channel_config_roles_one?: Maybe<Tenancy__Team_Account_Release_Channel_Config_Roles>;
  /** insert data into the table: "tenancy._team_account_roles" */
  insert_tenancy__team_account_roles?: Maybe<Tenancy__Team_Account_Roles_Mutation_Response>;
  /** insert a single row into the table: "tenancy._team_account_roles" */
  insert_tenancy__team_account_roles_one?: Maybe<Tenancy__Team_Account_Roles>;
  /** insert data into the table: "tenancy._user_account_project_roles" */
  insert_tenancy__user_account_project_roles?: Maybe<Tenancy__User_Account_Project_Roles_Mutation_Response>;
  /** insert a single row into the table: "tenancy._user_account_project_roles" */
  insert_tenancy__user_account_project_roles_one?: Maybe<Tenancy__User_Account_Project_Roles>;
  /** insert data into the table: "tenancy._user_account_release_channel_config_roles" */
  insert_tenancy__user_account_release_channel_config_roles?: Maybe<Tenancy__User_Account_Release_Channel_Config_Roles_Mutation_Response>;
  /** insert a single row into the table: "tenancy._user_account_release_channel_config_roles" */
  insert_tenancy__user_account_release_channel_config_roles_one?: Maybe<Tenancy__User_Account_Release_Channel_Config_Roles>;
  /** insert data into the table: "tenancy._user_account_roles" */
  insert_tenancy__user_account_roles?: Maybe<Tenancy__User_Account_Roles_Mutation_Response>;
  /** insert a single row into the table: "tenancy._user_account_roles" */
  insert_tenancy__user_account_roles_one?: Maybe<Tenancy__User_Account_Roles>;
  /** insert data into the table: "tenancy._user_teams" */
  insert_tenancy__user_teams?: Maybe<Tenancy__User_Teams_Mutation_Response>;
  /** insert a single row into the table: "tenancy._user_teams" */
  insert_tenancy__user_teams_one?: Maybe<Tenancy__User_Teams>;
  /** insert data into the table: "tenancy.account_scheduled_job_runs" */
  insert_tenancy_account_scheduled_job_runs?: Maybe<Tenancy_Account_Scheduled_Job_Runs_Mutation_Response>;
  /** insert a single row into the table: "tenancy.account_scheduled_job_runs" */
  insert_tenancy_account_scheduled_job_runs_one?: Maybe<Tenancy_Account_Scheduled_Job_Runs>;
  /** insert data into the table: "tenancy.account_scheduled_jobs" */
  insert_tenancy_account_scheduled_jobs?: Maybe<Tenancy_Account_Scheduled_Jobs_Mutation_Response>;
  /** insert a single row into the table: "tenancy.account_scheduled_jobs" */
  insert_tenancy_account_scheduled_jobs_one?: Maybe<Tenancy_Account_Scheduled_Jobs>;
  /** insert data into the table: "tenancy.accounts" */
  insert_tenancy_accounts?: Maybe<Tenancy_Accounts_Mutation_Response>;
  /** insert a single row into the table: "tenancy.accounts" */
  insert_tenancy_accounts_one?: Maybe<Tenancy_Accounts>;
  /** insert data into the table: "tenancy.action" */
  insert_tenancy_action?: Maybe<Tenancy_Action_Mutation_Response>;
  /** insert a single row into the table: "tenancy.action" */
  insert_tenancy_action_one?: Maybe<Tenancy_Action>;
  /** insert data into the table: "tenancy.app_config" */
  insert_tenancy_app_config?: Maybe<Tenancy_App_Config_Mutation_Response>;
  /** insert a single row into the table: "tenancy.app_config" */
  insert_tenancy_app_config_one?: Maybe<Tenancy_App_Config>;
  /** insert data into the table: "tenancy.applet" */
  insert_tenancy_applet?: Maybe<Tenancy_Applet_Mutation_Response>;
  /** insert data into the table: "tenancy.applet_instance" */
  insert_tenancy_applet_instance?: Maybe<Tenancy_Applet_Instance_Mutation_Response>;
  /** insert a single row into the table: "tenancy.applet_instance" */
  insert_tenancy_applet_instance_one?: Maybe<Tenancy_Applet_Instance>;
  /** insert a single row into the table: "tenancy.applet" */
  insert_tenancy_applet_one?: Maybe<Tenancy_Applet>;
  /** insert data into the table: "tenancy.applet_version" */
  insert_tenancy_applet_version?: Maybe<Tenancy_Applet_Version_Mutation_Response>;
  /** insert a single row into the table: "tenancy.applet_version" */
  insert_tenancy_applet_version_one?: Maybe<Tenancy_Applet_Version>;
  /** insert data into the table: "tenancy.audit_logs" */
  insert_tenancy_audit_logs?: Maybe<Tenancy_Audit_Logs_Mutation_Response>;
  /** insert a single row into the table: "tenancy.audit_logs" */
  insert_tenancy_audit_logs_one?: Maybe<Tenancy_Audit_Logs>;
  /** insert data into the table: "tenancy.condition" */
  insert_tenancy_condition?: Maybe<Tenancy_Condition_Mutation_Response>;
  /** insert a single row into the table: "tenancy.condition" */
  insert_tenancy_condition_one?: Maybe<Tenancy_Condition>;
  /** insert data into the table: "tenancy.country" */
  insert_tenancy_country?: Maybe<Tenancy_Country_Mutation_Response>;
  /** insert a single row into the table: "tenancy.country" */
  insert_tenancy_country_one?: Maybe<Tenancy_Country>;
  /** insert data into the table: "tenancy.domain" */
  insert_tenancy_domain?: Maybe<Tenancy_Domain_Mutation_Response>;
  /** insert a single row into the table: "tenancy.domain" */
  insert_tenancy_domain_one?: Maybe<Tenancy_Domain>;
  /** insert data into the table: "tenancy.failed_kafka_events" */
  insert_tenancy_failed_kafka_events?: Maybe<Tenancy_Failed_Kafka_Events_Mutation_Response>;
  /** insert a single row into the table: "tenancy.failed_kafka_events" */
  insert_tenancy_failed_kafka_events_one?: Maybe<Tenancy_Failed_Kafka_Events>;
  /** insert data into the table: "tenancy.file" */
  insert_tenancy_file?: Maybe<Tenancy_File_Mutation_Response>;
  /** insert a single row into the table: "tenancy.file" */
  insert_tenancy_file_one?: Maybe<Tenancy_File>;
  /** insert data into the table: "tenancy.locale" */
  insert_tenancy_locale?: Maybe<Tenancy_Locale_Mutation_Response>;
  /** insert a single row into the table: "tenancy.locale" */
  insert_tenancy_locale_one?: Maybe<Tenancy_Locale>;
  /** insert data into the table: "tenancy.localization_settings" */
  insert_tenancy_localization_settings?: Maybe<Tenancy_Localization_Settings_Mutation_Response>;
  /** insert a single row into the table: "tenancy.localization_settings" */
  insert_tenancy_localization_settings_one?: Maybe<Tenancy_Localization_Settings>;
  /** insert data into the table: "tenancy.package" */
  insert_tenancy_package?: Maybe<Tenancy_Package_Mutation_Response>;
  /** insert a single row into the table: "tenancy.package" */
  insert_tenancy_package_one?: Maybe<Tenancy_Package>;
  /** insert data into the table: "tenancy.package_version" */
  insert_tenancy_package_version?: Maybe<Tenancy_Package_Version_Mutation_Response>;
  /** insert a single row into the table: "tenancy.package_version" */
  insert_tenancy_package_version_one?: Maybe<Tenancy_Package_Version>;
  /** insert data into the table: "tenancy.products" */
  insert_tenancy_products?: Maybe<Tenancy_Products_Mutation_Response>;
  /** insert a single row into the table: "tenancy.products" */
  insert_tenancy_products_one?: Maybe<Tenancy_Products>;
  /** insert data into the table: "tenancy.project_product_settings" */
  insert_tenancy_project_product_settings?: Maybe<Tenancy_Project_Product_Settings_Mutation_Response>;
  /** insert a single row into the table: "tenancy.project_product_settings" */
  insert_tenancy_project_product_settings_one?: Maybe<Tenancy_Project_Product_Settings>;
  /** insert data into the table: "tenancy.project_release_channel_config" */
  insert_tenancy_project_release_channel_config?: Maybe<Tenancy_Project_Release_Channel_Config_Mutation_Response>;
  /** insert a single row into the table: "tenancy.project_release_channel_config" */
  insert_tenancy_project_release_channel_config_one?: Maybe<Tenancy_Project_Release_Channel_Config>;
  /** insert data into the table: "tenancy.projects" */
  insert_tenancy_projects?: Maybe<Tenancy_Projects_Mutation_Response>;
  /** insert a single row into the table: "tenancy.projects" */
  insert_tenancy_projects_one?: Maybe<Tenancy_Projects>;
  /** insert data into the table: "tenancy.release_channel" */
  insert_tenancy_release_channel?: Maybe<Tenancy_Release_Channel_Mutation_Response>;
  /** insert a single row into the table: "tenancy.release_channel" */
  insert_tenancy_release_channel_one?: Maybe<Tenancy_Release_Channel>;
  /** insert data into the table: "tenancy.roles" */
  insert_tenancy_roles?: Maybe<Tenancy_Roles_Mutation_Response>;
  /** insert a single row into the table: "tenancy.roles" */
  insert_tenancy_roles_one?: Maybe<Tenancy_Roles>;
  /** insert data into the table: "tenancy.scheduled_jobs" */
  insert_tenancy_scheduled_jobs?: Maybe<Tenancy_Scheduled_Jobs_Mutation_Response>;
  /** insert a single row into the table: "tenancy.scheduled_jobs" */
  insert_tenancy_scheduled_jobs_one?: Maybe<Tenancy_Scheduled_Jobs>;
  /** insert data into the table: "tenancy.team_account_projects" */
  insert_tenancy_team_account_projects?: Maybe<Tenancy_Team_Account_Projects_Mutation_Response>;
  /** insert a single row into the table: "tenancy.team_account_projects" */
  insert_tenancy_team_account_projects_one?: Maybe<Tenancy_Team_Account_Projects>;
  /** insert data into the table: "tenancy.team_account_release_channel_config" */
  insert_tenancy_team_account_release_channel_config?: Maybe<Tenancy_Team_Account_Release_Channel_Config_Mutation_Response>;
  /** insert a single row into the table: "tenancy.team_account_release_channel_config" */
  insert_tenancy_team_account_release_channel_config_one?: Maybe<Tenancy_Team_Account_Release_Channel_Config>;
  /** insert data into the table: "tenancy.team_accounts" */
  insert_tenancy_team_accounts?: Maybe<Tenancy_Team_Accounts_Mutation_Response>;
  /** insert a single row into the table: "tenancy.team_accounts" */
  insert_tenancy_team_accounts_one?: Maybe<Tenancy_Team_Accounts>;
  /** insert data into the table: "tenancy.teams" */
  insert_tenancy_teams?: Maybe<Tenancy_Teams_Mutation_Response>;
  /** insert a single row into the table: "tenancy.teams" */
  insert_tenancy_teams_one?: Maybe<Tenancy_Teams>;
  /** insert data into the table: "tenancy.time_zone" */
  insert_tenancy_time_zone?: Maybe<Tenancy_Time_Zone_Mutation_Response>;
  /** insert a single row into the table: "tenancy.time_zone" */
  insert_tenancy_time_zone_one?: Maybe<Tenancy_Time_Zone>;
  /** insert data into the table: "tenancy.trigger" */
  insert_tenancy_trigger?: Maybe<Tenancy_Trigger_Mutation_Response>;
  /** insert a single row into the table: "tenancy.trigger" */
  insert_tenancy_trigger_one?: Maybe<Tenancy_Trigger>;
  /** insert data into the table: "tenancy.user_account_projects" */
  insert_tenancy_user_account_projects?: Maybe<Tenancy_User_Account_Projects_Mutation_Response>;
  /** insert a single row into the table: "tenancy.user_account_projects" */
  insert_tenancy_user_account_projects_one?: Maybe<Tenancy_User_Account_Projects>;
  /** insert data into the table: "tenancy.user_account_release_channel_config" */
  insert_tenancy_user_account_release_channel_config?: Maybe<Tenancy_User_Account_Release_Channel_Config_Mutation_Response>;
  /** insert a single row into the table: "tenancy.user_account_release_channel_config" */
  insert_tenancy_user_account_release_channel_config_one?: Maybe<Tenancy_User_Account_Release_Channel_Config>;
  /** insert data into the table: "tenancy.user_accounts" */
  insert_tenancy_user_accounts?: Maybe<Tenancy_User_Accounts_Mutation_Response>;
  /** insert a single row into the table: "tenancy.user_accounts" */
  insert_tenancy_user_accounts_one?: Maybe<Tenancy_User_Accounts>;
  /** insert data into the table: "tenancy.users" */
  insert_tenancy_users?: Maybe<Tenancy_Users_Mutation_Response>;
  /** insert a single row into the table: "tenancy.users" */
  insert_tenancy_users_one?: Maybe<Tenancy_Users>;
  /** insert data into the table: "tenancy.variable" */
  insert_tenancy_variable?: Maybe<Tenancy_Variable_Mutation_Response>;
  /** insert a single row into the table: "tenancy.variable" */
  insert_tenancy_variable_one?: Maybe<Tenancy_Variable>;
  /**
   * This endpoint allows you to update any parameter provided
   * when a bot is created, such as botType.
   */
  patchBotById?: Maybe<Bot>;
  /**
   * This endpoint adds packages to a customer, in addition to the
   * ones already configured.  If a package with the same name is
   * added, it overwrites the version currently specified.  To replace
   * packages entirely, use the post endpoint.
   */
  patchCustomerPackages?: Maybe<Customer>;
  patchCustomerResources?: Maybe<Customer>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  patchOneIntent?: Maybe<Version>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  patchOneSynonym?: Maybe<Version>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  patchOneTypeEntity?: Maybe<Version>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  patchOneUtterance?: Maybe<Version>;
  postBotVersions?: Maybe<Version>;
  postCustomerBots?: Maybe<Customer>;
  /**
   * This endpoint overwrites and replaces the existing packages. To
   * add packages to the existing packages, use the patch endpoint.
   */
  postCustomerPackages?: Maybe<Customer>;
  postCustomers?: Maybe<Customer>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  postEntities?: Maybe<Version>;
  /**
   * Note: the request body JSON is only expected to have this format
   * {
   *   "env": <string>,          #environment, e.g. Claybox or QA1
   *   "uuid": <string>,         # Unique User ID, e.g. af560c9470e04d69be064f64963a1bcc
   *   "email": <string>,            # user email
   *   "customer": <string>,     # name of customer, e.g. MyCompanyABC
   *   "bot": <string>,          # name of solution, e.g. MyTestSolution
   *   "version": <string>,      # version of bot/solution, e.g. v1
   *   "max_num_entries": <integer>  # number most recent results to return
   * }
   * Where at least one of the fields is required (disregarding max_num_entries)
   */
  postHistorySearch?: Maybe<Version>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  postIntents?: Maybe<Version>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  postOneIntent?: Maybe<Version>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  postOneSynonym?: Maybe<Version>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  postOneTypeEntity?: Maybe<Version>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  postOneUtterance?: Maybe<Version>;
  postVersionBulkTest: Scalars['String']['output'];
  postVersionConfig?: Maybe<Version>;
  postVersionNluTest: Scalars['String']['output'];
  postVersionScript?: Maybe<Version>;
  /**
   * Format of JSON:
   * {"stopwords": ["a", "at", "the", ...]}
   */
  postVersionStopwords: Scalars['String']['output'];
  postVersionTemplate?: Maybe<Version>;
  putBotAssets?: Maybe<Asset>;
  /** any error messages. */
  putCompile: Scalars['String']['output'];
  putVersionDeploy?: Maybe<Version>;
  putVersionGraph?: Maybe<Version>;
  putVersionPreview?: Maybe<Version>;
  putVersionSearchNluTrain?: Maybe<Version>;
  putVersionTrain?: Maybe<Version>;
  /** update data of the table: "app_loader.data_store" */
  update_app_loader_data_store?: Maybe<App_Loader_Data_Store_Mutation_Response>;
  /** update single row of the table: "app_loader.data_store" */
  update_app_loader_data_store_by_pk?: Maybe<App_Loader_Data_Store>;
  /** update multiples rows of table: "app_loader.data_store" */
  update_app_loader_data_store_many?: Maybe<Array<Maybe<App_Loader_Data_Store_Mutation_Response>>>;
  /** update data of the table: "apps.app" */
  update_apps_app?: Maybe<Apps_App_Mutation_Response>;
  /** update single row of the table: "apps.app" */
  update_apps_app_by_pk?: Maybe<Apps_App>;
  /** update data of the table: "apps.app_config" */
  update_apps_app_config?: Maybe<Apps_App_Config_Mutation_Response>;
  /** update single row of the table: "apps.app_config" */
  update_apps_app_config_by_pk?: Maybe<Apps_App_Config>;
  /** update multiples rows of table: "apps.app_config" */
  update_apps_app_config_many?: Maybe<Array<Maybe<Apps_App_Config_Mutation_Response>>>;
  /** update data of the table: "apps.app_file" */
  update_apps_app_file?: Maybe<Apps_App_File_Mutation_Response>;
  /** update single row of the table: "apps.app_file" */
  update_apps_app_file_by_pk?: Maybe<Apps_App_File>;
  /** update multiples rows of table: "apps.app_file" */
  update_apps_app_file_many?: Maybe<Array<Maybe<Apps_App_File_Mutation_Response>>>;
  /** update multiples rows of table: "apps.app" */
  update_apps_app_many?: Maybe<Array<Maybe<Apps_App_Mutation_Response>>>;
  /** update data of the table: "apps.convert_roundtrip" */
  update_apps_convert_roundtrip?: Maybe<Apps_Convert_Roundtrip_Mutation_Response>;
  /** update single row of the table: "apps.convert_roundtrip" */
  update_apps_convert_roundtrip_by_pk?: Maybe<Apps_Convert_Roundtrip>;
  /** update multiples rows of table: "apps.convert_roundtrip" */
  update_apps_convert_roundtrip_many?: Maybe<Array<Maybe<Apps_Convert_Roundtrip_Mutation_Response>>>;
  /** update data of the table: "apps.csv_file" */
  update_apps_csv_file?: Maybe<Apps_Csv_File_Mutation_Response>;
  /** update single row of the table: "apps.csv_file" */
  update_apps_csv_file_by_pk?: Maybe<Apps_Csv_File>;
  /** update multiples rows of table: "apps.csv_file" */
  update_apps_csv_file_many?: Maybe<Array<Maybe<Apps_Csv_File_Mutation_Response>>>;
  /** update data of the table: "apps.customer" */
  update_apps_customer?: Maybe<Apps_Customer_Mutation_Response>;
  /** update single row of the table: "apps.customer" */
  update_apps_customer_by_pk?: Maybe<Apps_Customer>;
  /** update data of the table: "apps.customer_config" */
  update_apps_customer_config?: Maybe<Apps_Customer_Config_Mutation_Response>;
  /** update single row of the table: "apps.customer_config" */
  update_apps_customer_config_by_pk?: Maybe<Apps_Customer_Config>;
  /** update multiples rows of table: "apps.customer_config" */
  update_apps_customer_config_many?: Maybe<Array<Maybe<Apps_Customer_Config_Mutation_Response>>>;
  /** update multiples rows of table: "apps.customer" */
  update_apps_customer_many?: Maybe<Array<Maybe<Apps_Customer_Mutation_Response>>>;
  /** update data of the table: "apps.deployment" */
  update_apps_deployment?: Maybe<Apps_Deployment_Mutation_Response>;
  /** update single row of the table: "apps.deployment" */
  update_apps_deployment_by_pk?: Maybe<Apps_Deployment>;
  /** update multiples rows of table: "apps.deployment" */
  update_apps_deployment_many?: Maybe<Array<Maybe<Apps_Deployment_Mutation_Response>>>;
  /** update data of the table: "apps.environment" */
  update_apps_environment?: Maybe<Apps_Environment_Mutation_Response>;
  /** update single row of the table: "apps.environment" */
  update_apps_environment_by_pk?: Maybe<Apps_Environment>;
  /** update multiples rows of table: "apps.environment" */
  update_apps_environment_many?: Maybe<Array<Maybe<Apps_Environment_Mutation_Response>>>;
  /** update data of the table: "apps.file" */
  update_apps_file?: Maybe<Apps_File_Mutation_Response>;
  /** update single row of the table: "apps.file" */
  update_apps_file_by_pk?: Maybe<Apps_File>;
  /** update multiples rows of table: "apps.file" */
  update_apps_file_many?: Maybe<Array<Maybe<Apps_File_Mutation_Response>>>;
  /** update data of the table: "apps.import_fail" */
  update_apps_import_fail?: Maybe<Apps_Import_Fail_Mutation_Response>;
  /** update single row of the table: "apps.import_fail" */
  update_apps_import_fail_by_pk?: Maybe<Apps_Import_Fail>;
  /** update multiples rows of table: "apps.import_fail" */
  update_apps_import_fail_many?: Maybe<Array<Maybe<Apps_Import_Fail_Mutation_Response>>>;
  /** update data of the table: "apps.import_fail_node" */
  update_apps_import_fail_node?: Maybe<Apps_Import_Fail_Node_Mutation_Response>;
  /** update single row of the table: "apps.import_fail_node" */
  update_apps_import_fail_node_by_pk?: Maybe<Apps_Import_Fail_Node>;
  /** update multiples rows of table: "apps.import_fail_node" */
  update_apps_import_fail_node_many?: Maybe<Array<Maybe<Apps_Import_Fail_Node_Mutation_Response>>>;
  /** update data of the table: "apps.nlu_files" */
  update_apps_nlu_files?: Maybe<Apps_Nlu_Files_Mutation_Response>;
  /** update single row of the table: "apps.nlu_files" */
  update_apps_nlu_files_by_pk?: Maybe<Apps_Nlu_Files>;
  /** update multiples rows of table: "apps.nlu_files" */
  update_apps_nlu_files_many?: Maybe<Array<Maybe<Apps_Nlu_Files_Mutation_Response>>>;
  /** update data of the table: "apps.node" */
  update_apps_node?: Maybe<Apps_Node_Mutation_Response>;
  /** update single row of the table: "apps.node" */
  update_apps_node_by_pk?: Maybe<Apps_Node>;
  /** update data of the table: "apps.node_import_flat" */
  update_apps_node_import_flat?: Maybe<Apps_Node_Import_Flat_Mutation_Response>;
  /** update single row of the table: "apps.node_import_flat" */
  update_apps_node_import_flat_by_pk?: Maybe<Apps_Node_Import_Flat>;
  /** update multiples rows of table: "apps.node_import_flat" */
  update_apps_node_import_flat_many?: Maybe<Array<Maybe<Apps_Node_Import_Flat_Mutation_Response>>>;
  /** update data of the table: "apps.node_import_json" */
  update_apps_node_import_json?: Maybe<Apps_Node_Import_Json_Mutation_Response>;
  /** update single row of the table: "apps.node_import_json" */
  update_apps_node_import_json_by_pk?: Maybe<Apps_Node_Import_Json>;
  /** update multiples rows of table: "apps.node_import_json" */
  update_apps_node_import_json_many?: Maybe<Array<Maybe<Apps_Node_Import_Json_Mutation_Response>>>;
  /** update data of the table: "apps.node_instance" */
  update_apps_node_instance?: Maybe<Apps_Node_Instance_Mutation_Response>;
  /** update single row of the table: "apps.node_instance" */
  update_apps_node_instance_by_pk?: Maybe<Apps_Node_Instance>;
  /** update multiples rows of table: "apps.node_instance" */
  update_apps_node_instance_many?: Maybe<Array<Maybe<Apps_Node_Instance_Mutation_Response>>>;
  /** update multiples rows of table: "apps.node" */
  update_apps_node_many?: Maybe<Array<Maybe<Apps_Node_Mutation_Response>>>;
  /** update data of the table: "apps.rich_asset" */
  update_apps_rich_asset?: Maybe<Apps_Rich_Asset_Mutation_Response>;
  /** update single row of the table: "apps.rich_asset" */
  update_apps_rich_asset_by_pk?: Maybe<Apps_Rich_Asset>;
  /** update multiples rows of table: "apps.rich_asset" */
  update_apps_rich_asset_many?: Maybe<Array<Maybe<Apps_Rich_Asset_Mutation_Response>>>;
  /** update data of the table: "apps.version" */
  update_apps_version?: Maybe<Apps_Version_Mutation_Response>;
  /** update single row of the table: "apps.version" */
  update_apps_version_by_pk?: Maybe<Apps_Version>;
  /** update data of the table: "apps.version_display_data" */
  update_apps_version_display_data?: Maybe<Apps_Version_Display_Data_Mutation_Response>;
  /** update single row of the table: "apps.version_display_data" */
  update_apps_version_display_data_by_pk?: Maybe<Apps_Version_Display_Data>;
  /** update multiples rows of table: "apps.version_display_data" */
  update_apps_version_display_data_many?: Maybe<Array<Maybe<Apps_Version_Display_Data_Mutation_Response>>>;
  /** update data of the table: "apps.version_file" */
  update_apps_version_file?: Maybe<Apps_Version_File_Mutation_Response>;
  /** update single row of the table: "apps.version_file" */
  update_apps_version_file_by_pk?: Maybe<Apps_Version_File>;
  /** update multiples rows of table: "apps.version_file" */
  update_apps_version_file_many?: Maybe<Array<Maybe<Apps_Version_File_Mutation_Response>>>;
  /** update multiples rows of table: "apps.version" */
  update_apps_version_many?: Maybe<Array<Maybe<Apps_Version_Mutation_Response>>>;
  /** update data of the table: "apps.version_node_instance" */
  update_apps_version_node_instance?: Maybe<Apps_Version_Node_Instance_Mutation_Response>;
  /** update single row of the table: "apps.version_node_instance" */
  update_apps_version_node_instance_by_pk?: Maybe<Apps_Version_Node_Instance>;
  /** update multiples rows of table: "apps.version_node_instance" */
  update_apps_version_node_instance_many?: Maybe<Array<Maybe<Apps_Version_Node_Instance_Mutation_Response>>>;
  /** update data of the table: "tenancy._ProjectReleaseChannelConfigToVariable" */
  update_tenancy__ProjectReleaseChannelConfigToVariable?: Maybe<Tenancy__ProjectReleaseChannelConfigToVariable_Mutation_Response>;
  /** update multiples rows of table: "tenancy._ProjectReleaseChannelConfigToVariable" */
  update_tenancy__ProjectReleaseChannelConfigToVariable_many?: Maybe<Array<Maybe<Tenancy__ProjectReleaseChannelConfigToVariable_Mutation_Response>>>;
  /** update data of the table: "tenancy._prisma_migrations" */
  update_tenancy__prisma_migrations?: Maybe<Tenancy__Prisma_Migrations_Mutation_Response>;
  /** update single row of the table: "tenancy._prisma_migrations" */
  update_tenancy__prisma_migrations_by_pk?: Maybe<Tenancy__Prisma_Migrations>;
  /** update multiples rows of table: "tenancy._prisma_migrations" */
  update_tenancy__prisma_migrations_many?: Maybe<Array<Maybe<Tenancy__Prisma_Migrations_Mutation_Response>>>;
  /** update data of the table: "tenancy._role_projects" */
  update_tenancy__role_projects?: Maybe<Tenancy__Role_Projects_Mutation_Response>;
  /** update multiples rows of table: "tenancy._role_projects" */
  update_tenancy__role_projects_many?: Maybe<Array<Maybe<Tenancy__Role_Projects_Mutation_Response>>>;
  /** update data of the table: "tenancy._team_account_project_roles" */
  update_tenancy__team_account_project_roles?: Maybe<Tenancy__Team_Account_Project_Roles_Mutation_Response>;
  /** update multiples rows of table: "tenancy._team_account_project_roles" */
  update_tenancy__team_account_project_roles_many?: Maybe<Array<Maybe<Tenancy__Team_Account_Project_Roles_Mutation_Response>>>;
  /** update data of the table: "tenancy._team_account_release_channel_config_roles" */
  update_tenancy__team_account_release_channel_config_roles?: Maybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Mutation_Response>;
  /** update multiples rows of table: "tenancy._team_account_release_channel_config_roles" */
  update_tenancy__team_account_release_channel_config_roles_many?: Maybe<Array<Maybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Mutation_Response>>>;
  /** update data of the table: "tenancy._team_account_roles" */
  update_tenancy__team_account_roles?: Maybe<Tenancy__Team_Account_Roles_Mutation_Response>;
  /** update multiples rows of table: "tenancy._team_account_roles" */
  update_tenancy__team_account_roles_many?: Maybe<Array<Maybe<Tenancy__Team_Account_Roles_Mutation_Response>>>;
  /** update data of the table: "tenancy._user_account_project_roles" */
  update_tenancy__user_account_project_roles?: Maybe<Tenancy__User_Account_Project_Roles_Mutation_Response>;
  /** update multiples rows of table: "tenancy._user_account_project_roles" */
  update_tenancy__user_account_project_roles_many?: Maybe<Array<Maybe<Tenancy__User_Account_Project_Roles_Mutation_Response>>>;
  /** update data of the table: "tenancy._user_account_release_channel_config_roles" */
  update_tenancy__user_account_release_channel_config_roles?: Maybe<Tenancy__User_Account_Release_Channel_Config_Roles_Mutation_Response>;
  /** update multiples rows of table: "tenancy._user_account_release_channel_config_roles" */
  update_tenancy__user_account_release_channel_config_roles_many?: Maybe<Array<Maybe<Tenancy__User_Account_Release_Channel_Config_Roles_Mutation_Response>>>;
  /** update data of the table: "tenancy._user_account_roles" */
  update_tenancy__user_account_roles?: Maybe<Tenancy__User_Account_Roles_Mutation_Response>;
  /** update multiples rows of table: "tenancy._user_account_roles" */
  update_tenancy__user_account_roles_many?: Maybe<Array<Maybe<Tenancy__User_Account_Roles_Mutation_Response>>>;
  /** update data of the table: "tenancy._user_teams" */
  update_tenancy__user_teams?: Maybe<Tenancy__User_Teams_Mutation_Response>;
  /** update multiples rows of table: "tenancy._user_teams" */
  update_tenancy__user_teams_many?: Maybe<Array<Maybe<Tenancy__User_Teams_Mutation_Response>>>;
  /** update data of the table: "tenancy.account_scheduled_job_runs" */
  update_tenancy_account_scheduled_job_runs?: Maybe<Tenancy_Account_Scheduled_Job_Runs_Mutation_Response>;
  /** update single row of the table: "tenancy.account_scheduled_job_runs" */
  update_tenancy_account_scheduled_job_runs_by_pk?: Maybe<Tenancy_Account_Scheduled_Job_Runs>;
  /** update multiples rows of table: "tenancy.account_scheduled_job_runs" */
  update_tenancy_account_scheduled_job_runs_many?: Maybe<Array<Maybe<Tenancy_Account_Scheduled_Job_Runs_Mutation_Response>>>;
  /** update data of the table: "tenancy.account_scheduled_jobs" */
  update_tenancy_account_scheduled_jobs?: Maybe<Tenancy_Account_Scheduled_Jobs_Mutation_Response>;
  /** update single row of the table: "tenancy.account_scheduled_jobs" */
  update_tenancy_account_scheduled_jobs_by_pk?: Maybe<Tenancy_Account_Scheduled_Jobs>;
  /** update multiples rows of table: "tenancy.account_scheduled_jobs" */
  update_tenancy_account_scheduled_jobs_many?: Maybe<Array<Maybe<Tenancy_Account_Scheduled_Jobs_Mutation_Response>>>;
  /** update data of the table: "tenancy.accounts" */
  update_tenancy_accounts?: Maybe<Tenancy_Accounts_Mutation_Response>;
  /** update single row of the table: "tenancy.accounts" */
  update_tenancy_accounts_by_pk?: Maybe<Tenancy_Accounts>;
  /** update multiples rows of table: "tenancy.accounts" */
  update_tenancy_accounts_many?: Maybe<Array<Maybe<Tenancy_Accounts_Mutation_Response>>>;
  /** update data of the table: "tenancy.action" */
  update_tenancy_action?: Maybe<Tenancy_Action_Mutation_Response>;
  /** update single row of the table: "tenancy.action" */
  update_tenancy_action_by_pk?: Maybe<Tenancy_Action>;
  /** update multiples rows of table: "tenancy.action" */
  update_tenancy_action_many?: Maybe<Array<Maybe<Tenancy_Action_Mutation_Response>>>;
  /** update data of the table: "tenancy.app_config" */
  update_tenancy_app_config?: Maybe<Tenancy_App_Config_Mutation_Response>;
  /** update single row of the table: "tenancy.app_config" */
  update_tenancy_app_config_by_pk?: Maybe<Tenancy_App_Config>;
  /** update multiples rows of table: "tenancy.app_config" */
  update_tenancy_app_config_many?: Maybe<Array<Maybe<Tenancy_App_Config_Mutation_Response>>>;
  /** update data of the table: "tenancy.applet" */
  update_tenancy_applet?: Maybe<Tenancy_Applet_Mutation_Response>;
  /** update single row of the table: "tenancy.applet" */
  update_tenancy_applet_by_pk?: Maybe<Tenancy_Applet>;
  /** update data of the table: "tenancy.applet_instance" */
  update_tenancy_applet_instance?: Maybe<Tenancy_Applet_Instance_Mutation_Response>;
  /** update single row of the table: "tenancy.applet_instance" */
  update_tenancy_applet_instance_by_pk?: Maybe<Tenancy_Applet_Instance>;
  /** update multiples rows of table: "tenancy.applet_instance" */
  update_tenancy_applet_instance_many?: Maybe<Array<Maybe<Tenancy_Applet_Instance_Mutation_Response>>>;
  /** update multiples rows of table: "tenancy.applet" */
  update_tenancy_applet_many?: Maybe<Array<Maybe<Tenancy_Applet_Mutation_Response>>>;
  /** update data of the table: "tenancy.applet_version" */
  update_tenancy_applet_version?: Maybe<Tenancy_Applet_Version_Mutation_Response>;
  /** update single row of the table: "tenancy.applet_version" */
  update_tenancy_applet_version_by_pk?: Maybe<Tenancy_Applet_Version>;
  /** update multiples rows of table: "tenancy.applet_version" */
  update_tenancy_applet_version_many?: Maybe<Array<Maybe<Tenancy_Applet_Version_Mutation_Response>>>;
  /** update data of the table: "tenancy.audit_logs" */
  update_tenancy_audit_logs?: Maybe<Tenancy_Audit_Logs_Mutation_Response>;
  /** update single row of the table: "tenancy.audit_logs" */
  update_tenancy_audit_logs_by_pk?: Maybe<Tenancy_Audit_Logs>;
  /** update multiples rows of table: "tenancy.audit_logs" */
  update_tenancy_audit_logs_many?: Maybe<Array<Maybe<Tenancy_Audit_Logs_Mutation_Response>>>;
  /** update data of the table: "tenancy.condition" */
  update_tenancy_condition?: Maybe<Tenancy_Condition_Mutation_Response>;
  /** update single row of the table: "tenancy.condition" */
  update_tenancy_condition_by_pk?: Maybe<Tenancy_Condition>;
  /** update multiples rows of table: "tenancy.condition" */
  update_tenancy_condition_many?: Maybe<Array<Maybe<Tenancy_Condition_Mutation_Response>>>;
  /** update data of the table: "tenancy.country" */
  update_tenancy_country?: Maybe<Tenancy_Country_Mutation_Response>;
  /** update single row of the table: "tenancy.country" */
  update_tenancy_country_by_pk?: Maybe<Tenancy_Country>;
  /** update multiples rows of table: "tenancy.country" */
  update_tenancy_country_many?: Maybe<Array<Maybe<Tenancy_Country_Mutation_Response>>>;
  /** update data of the table: "tenancy.domain" */
  update_tenancy_domain?: Maybe<Tenancy_Domain_Mutation_Response>;
  /** update single row of the table: "tenancy.domain" */
  update_tenancy_domain_by_pk?: Maybe<Tenancy_Domain>;
  /** update multiples rows of table: "tenancy.domain" */
  update_tenancy_domain_many?: Maybe<Array<Maybe<Tenancy_Domain_Mutation_Response>>>;
  /** update data of the table: "tenancy.failed_kafka_events" */
  update_tenancy_failed_kafka_events?: Maybe<Tenancy_Failed_Kafka_Events_Mutation_Response>;
  /** update single row of the table: "tenancy.failed_kafka_events" */
  update_tenancy_failed_kafka_events_by_pk?: Maybe<Tenancy_Failed_Kafka_Events>;
  /** update multiples rows of table: "tenancy.failed_kafka_events" */
  update_tenancy_failed_kafka_events_many?: Maybe<Array<Maybe<Tenancy_Failed_Kafka_Events_Mutation_Response>>>;
  /** update data of the table: "tenancy.file" */
  update_tenancy_file?: Maybe<Tenancy_File_Mutation_Response>;
  /** update single row of the table: "tenancy.file" */
  update_tenancy_file_by_pk?: Maybe<Tenancy_File>;
  /** update multiples rows of table: "tenancy.file" */
  update_tenancy_file_many?: Maybe<Array<Maybe<Tenancy_File_Mutation_Response>>>;
  /** update data of the table: "tenancy.locale" */
  update_tenancy_locale?: Maybe<Tenancy_Locale_Mutation_Response>;
  /** update single row of the table: "tenancy.locale" */
  update_tenancy_locale_by_pk?: Maybe<Tenancy_Locale>;
  /** update multiples rows of table: "tenancy.locale" */
  update_tenancy_locale_many?: Maybe<Array<Maybe<Tenancy_Locale_Mutation_Response>>>;
  /** update data of the table: "tenancy.localization_settings" */
  update_tenancy_localization_settings?: Maybe<Tenancy_Localization_Settings_Mutation_Response>;
  /** update single row of the table: "tenancy.localization_settings" */
  update_tenancy_localization_settings_by_pk?: Maybe<Tenancy_Localization_Settings>;
  /** update multiples rows of table: "tenancy.localization_settings" */
  update_tenancy_localization_settings_many?: Maybe<Array<Maybe<Tenancy_Localization_Settings_Mutation_Response>>>;
  /** update data of the table: "tenancy.package" */
  update_tenancy_package?: Maybe<Tenancy_Package_Mutation_Response>;
  /** update single row of the table: "tenancy.package" */
  update_tenancy_package_by_pk?: Maybe<Tenancy_Package>;
  /** update multiples rows of table: "tenancy.package" */
  update_tenancy_package_many?: Maybe<Array<Maybe<Tenancy_Package_Mutation_Response>>>;
  /** update data of the table: "tenancy.package_version" */
  update_tenancy_package_version?: Maybe<Tenancy_Package_Version_Mutation_Response>;
  /** update single row of the table: "tenancy.package_version" */
  update_tenancy_package_version_by_pk?: Maybe<Tenancy_Package_Version>;
  /** update multiples rows of table: "tenancy.package_version" */
  update_tenancy_package_version_many?: Maybe<Array<Maybe<Tenancy_Package_Version_Mutation_Response>>>;
  /** update data of the table: "tenancy.products" */
  update_tenancy_products?: Maybe<Tenancy_Products_Mutation_Response>;
  /** update single row of the table: "tenancy.products" */
  update_tenancy_products_by_pk?: Maybe<Tenancy_Products>;
  /** update multiples rows of table: "tenancy.products" */
  update_tenancy_products_many?: Maybe<Array<Maybe<Tenancy_Products_Mutation_Response>>>;
  /** update data of the table: "tenancy.project_product_settings" */
  update_tenancy_project_product_settings?: Maybe<Tenancy_Project_Product_Settings_Mutation_Response>;
  /** update single row of the table: "tenancy.project_product_settings" */
  update_tenancy_project_product_settings_by_pk?: Maybe<Tenancy_Project_Product_Settings>;
  /** update multiples rows of table: "tenancy.project_product_settings" */
  update_tenancy_project_product_settings_many?: Maybe<Array<Maybe<Tenancy_Project_Product_Settings_Mutation_Response>>>;
  /** update data of the table: "tenancy.project_release_channel_config" */
  update_tenancy_project_release_channel_config?: Maybe<Tenancy_Project_Release_Channel_Config_Mutation_Response>;
  /** update single row of the table: "tenancy.project_release_channel_config" */
  update_tenancy_project_release_channel_config_by_pk?: Maybe<Tenancy_Project_Release_Channel_Config>;
  /** update multiples rows of table: "tenancy.project_release_channel_config" */
  update_tenancy_project_release_channel_config_many?: Maybe<Array<Maybe<Tenancy_Project_Release_Channel_Config_Mutation_Response>>>;
  /** update data of the table: "tenancy.projects" */
  update_tenancy_projects?: Maybe<Tenancy_Projects_Mutation_Response>;
  /** update single row of the table: "tenancy.projects" */
  update_tenancy_projects_by_pk?: Maybe<Tenancy_Projects>;
  /** update multiples rows of table: "tenancy.projects" */
  update_tenancy_projects_many?: Maybe<Array<Maybe<Tenancy_Projects_Mutation_Response>>>;
  /** update data of the table: "tenancy.release_channel" */
  update_tenancy_release_channel?: Maybe<Tenancy_Release_Channel_Mutation_Response>;
  /** update single row of the table: "tenancy.release_channel" */
  update_tenancy_release_channel_by_pk?: Maybe<Tenancy_Release_Channel>;
  /** update multiples rows of table: "tenancy.release_channel" */
  update_tenancy_release_channel_many?: Maybe<Array<Maybe<Tenancy_Release_Channel_Mutation_Response>>>;
  /** update data of the table: "tenancy.roles" */
  update_tenancy_roles?: Maybe<Tenancy_Roles_Mutation_Response>;
  /** update single row of the table: "tenancy.roles" */
  update_tenancy_roles_by_pk?: Maybe<Tenancy_Roles>;
  /** update multiples rows of table: "tenancy.roles" */
  update_tenancy_roles_many?: Maybe<Array<Maybe<Tenancy_Roles_Mutation_Response>>>;
  /** update data of the table: "tenancy.scheduled_jobs" */
  update_tenancy_scheduled_jobs?: Maybe<Tenancy_Scheduled_Jobs_Mutation_Response>;
  /** update single row of the table: "tenancy.scheduled_jobs" */
  update_tenancy_scheduled_jobs_by_pk?: Maybe<Tenancy_Scheduled_Jobs>;
  /** update multiples rows of table: "tenancy.scheduled_jobs" */
  update_tenancy_scheduled_jobs_many?: Maybe<Array<Maybe<Tenancy_Scheduled_Jobs_Mutation_Response>>>;
  /** update data of the table: "tenancy.team_account_projects" */
  update_tenancy_team_account_projects?: Maybe<Tenancy_Team_Account_Projects_Mutation_Response>;
  /** update single row of the table: "tenancy.team_account_projects" */
  update_tenancy_team_account_projects_by_pk?: Maybe<Tenancy_Team_Account_Projects>;
  /** update multiples rows of table: "tenancy.team_account_projects" */
  update_tenancy_team_account_projects_many?: Maybe<Array<Maybe<Tenancy_Team_Account_Projects_Mutation_Response>>>;
  /** update data of the table: "tenancy.team_account_release_channel_config" */
  update_tenancy_team_account_release_channel_config?: Maybe<Tenancy_Team_Account_Release_Channel_Config_Mutation_Response>;
  /** update single row of the table: "tenancy.team_account_release_channel_config" */
  update_tenancy_team_account_release_channel_config_by_pk?: Maybe<Tenancy_Team_Account_Release_Channel_Config>;
  /** update multiples rows of table: "tenancy.team_account_release_channel_config" */
  update_tenancy_team_account_release_channel_config_many?: Maybe<Array<Maybe<Tenancy_Team_Account_Release_Channel_Config_Mutation_Response>>>;
  /** update data of the table: "tenancy.team_accounts" */
  update_tenancy_team_accounts?: Maybe<Tenancy_Team_Accounts_Mutation_Response>;
  /** update single row of the table: "tenancy.team_accounts" */
  update_tenancy_team_accounts_by_pk?: Maybe<Tenancy_Team_Accounts>;
  /** update multiples rows of table: "tenancy.team_accounts" */
  update_tenancy_team_accounts_many?: Maybe<Array<Maybe<Tenancy_Team_Accounts_Mutation_Response>>>;
  /** update data of the table: "tenancy.teams" */
  update_tenancy_teams?: Maybe<Tenancy_Teams_Mutation_Response>;
  /** update single row of the table: "tenancy.teams" */
  update_tenancy_teams_by_pk?: Maybe<Tenancy_Teams>;
  /** update multiples rows of table: "tenancy.teams" */
  update_tenancy_teams_many?: Maybe<Array<Maybe<Tenancy_Teams_Mutation_Response>>>;
  /** update data of the table: "tenancy.time_zone" */
  update_tenancy_time_zone?: Maybe<Tenancy_Time_Zone_Mutation_Response>;
  /** update single row of the table: "tenancy.time_zone" */
  update_tenancy_time_zone_by_pk?: Maybe<Tenancy_Time_Zone>;
  /** update multiples rows of table: "tenancy.time_zone" */
  update_tenancy_time_zone_many?: Maybe<Array<Maybe<Tenancy_Time_Zone_Mutation_Response>>>;
  /** update data of the table: "tenancy.trigger" */
  update_tenancy_trigger?: Maybe<Tenancy_Trigger_Mutation_Response>;
  /** update single row of the table: "tenancy.trigger" */
  update_tenancy_trigger_by_pk?: Maybe<Tenancy_Trigger>;
  /** update multiples rows of table: "tenancy.trigger" */
  update_tenancy_trigger_many?: Maybe<Array<Maybe<Tenancy_Trigger_Mutation_Response>>>;
  /** update data of the table: "tenancy.user_account_projects" */
  update_tenancy_user_account_projects?: Maybe<Tenancy_User_Account_Projects_Mutation_Response>;
  /** update single row of the table: "tenancy.user_account_projects" */
  update_tenancy_user_account_projects_by_pk?: Maybe<Tenancy_User_Account_Projects>;
  /** update multiples rows of table: "tenancy.user_account_projects" */
  update_tenancy_user_account_projects_many?: Maybe<Array<Maybe<Tenancy_User_Account_Projects_Mutation_Response>>>;
  /** update data of the table: "tenancy.user_account_release_channel_config" */
  update_tenancy_user_account_release_channel_config?: Maybe<Tenancy_User_Account_Release_Channel_Config_Mutation_Response>;
  /** update single row of the table: "tenancy.user_account_release_channel_config" */
  update_tenancy_user_account_release_channel_config_by_pk?: Maybe<Tenancy_User_Account_Release_Channel_Config>;
  /** update multiples rows of table: "tenancy.user_account_release_channel_config" */
  update_tenancy_user_account_release_channel_config_many?: Maybe<Array<Maybe<Tenancy_User_Account_Release_Channel_Config_Mutation_Response>>>;
  /** update data of the table: "tenancy.user_accounts" */
  update_tenancy_user_accounts?: Maybe<Tenancy_User_Accounts_Mutation_Response>;
  /** update single row of the table: "tenancy.user_accounts" */
  update_tenancy_user_accounts_by_pk?: Maybe<Tenancy_User_Accounts>;
  /** update multiples rows of table: "tenancy.user_accounts" */
  update_tenancy_user_accounts_many?: Maybe<Array<Maybe<Tenancy_User_Accounts_Mutation_Response>>>;
  /** update data of the table: "tenancy.users" */
  update_tenancy_users?: Maybe<Tenancy_Users_Mutation_Response>;
  /** update single row of the table: "tenancy.users" */
  update_tenancy_users_by_pk?: Maybe<Tenancy_Users>;
  /** update multiples rows of table: "tenancy.users" */
  update_tenancy_users_many?: Maybe<Array<Maybe<Tenancy_Users_Mutation_Response>>>;
  /** update data of the table: "tenancy.variable" */
  update_tenancy_variable?: Maybe<Tenancy_Variable_Mutation_Response>;
  /** update single row of the table: "tenancy.variable" */
  update_tenancy_variable_by_pk?: Maybe<Tenancy_Variable>;
  /** update multiples rows of table: "tenancy.variable" */
  update_tenancy_variable_many?: Maybe<Array<Maybe<Tenancy_Variable_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDeleteBotAssetByIdArgs = {
  assetId: Scalars['String']['input'];
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteBotByIdArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerByIdArgs = {
  customerId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteCustomerPackagesArgs = {
  customerId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteOneIntentArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteOneSynonymArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteOneTypeEntityArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteOneUtteranceArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteVersionScriptByIdArgs = {
  scriptId: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_App_Loader_Data_StoreArgs = {
  where: App_Loader_Data_Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_Loader_Data_Store_By_PkArgs = {
  session_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_AppArgs = {
  where: Apps_App_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_App_By_PkArgs = {
  app_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_App_ConfigArgs = {
  where: Apps_App_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_App_Config_By_PkArgs = {
  app_config_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_App_FileArgs = {
  where: Apps_App_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_App_File_By_PkArgs = {
  app_id: Scalars['bigint']['input'];
  filename: Scalars['String']['input'];
  parent_path: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Convert_RoundtripArgs = {
  where: Apps_Convert_Roundtrip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Convert_Roundtrip_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Csv_FileArgs = {
  where: Apps_Csv_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Csv_File_By_PkArgs = {
  csv_file_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_CustomerArgs = {
  where: Apps_Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Customer_By_PkArgs = {
  customer_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Customer_ConfigArgs = {
  where: Apps_Customer_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Customer_Config_By_PkArgs = {
  customer_config_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_DeploymentArgs = {
  where: Apps_Deployment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Deployment_By_PkArgs = {
  deployment_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_EnvironmentArgs = {
  where: Apps_Environment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Environment_By_PkArgs = {
  environment_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_FileArgs = {
  where: Apps_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_File_By_PkArgs = {
  file_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Import_FailArgs = {
  where: Apps_Import_Fail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Import_Fail_By_PkArgs = {
  import_fail_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Import_Fail_NodeArgs = {
  where: Apps_Import_Fail_Node_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Import_Fail_Node_By_PkArgs = {
  import_fail_node_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Nlu_FilesArgs = {
  where: Apps_Nlu_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Nlu_Files_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_NodeArgs = {
  where: Apps_Node_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Node_By_PkArgs = {
  node_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Node_Import_FlatArgs = {
  where: Apps_Node_Import_Flat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Node_Import_Flat_By_PkArgs = {
  node_import_flat_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Node_Import_JsonArgs = {
  where: Apps_Node_Import_Json_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Node_Import_Json_By_PkArgs = {
  node_import_json_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Node_InstanceArgs = {
  where: Apps_Node_Instance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Node_Instance_By_PkArgs = {
  node_instance_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Rich_AssetArgs = {
  where: Apps_Rich_Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Rich_Asset_By_PkArgs = {
  rich_asset_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_VersionArgs = {
  where: Apps_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Version_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Version_Display_DataArgs = {
  where: Apps_Version_Display_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Version_Display_Data_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Version_FileArgs = {
  where: Apps_Version_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Version_File_By_PkArgs = {
  filename: Scalars['String']['input'];
  parent_path: Scalars['String']['input'];
  version_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Apps_Version_Node_InstanceArgs = {
  where: Apps_Version_Node_Instance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apps_Version_Node_Instance_By_PkArgs = {
  node_number: Scalars['bigint']['input'];
  version_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy__ProjectReleaseChannelConfigToVariableArgs = {
  where: Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy__Prisma_MigrationsArgs = {
  where: Tenancy__Prisma_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy__Prisma_Migrations_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy__Role_ProjectsArgs = {
  where: Tenancy__Role_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy__Team_Account_Project_RolesArgs = {
  where: Tenancy__Team_Account_Project_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy__Team_Account_Release_Channel_Config_RolesArgs = {
  where: Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy__Team_Account_RolesArgs = {
  where: Tenancy__Team_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy__User_Account_Project_RolesArgs = {
  where: Tenancy__User_Account_Project_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy__User_Account_Release_Channel_Config_RolesArgs = {
  where: Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy__User_Account_RolesArgs = {
  where: Tenancy__User_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy__User_TeamsArgs = {
  where: Tenancy__User_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Account_Scheduled_Job_RunsArgs = {
  where: Tenancy_Account_Scheduled_Job_Runs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Account_Scheduled_Job_Runs_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Account_Scheduled_JobsArgs = {
  where: Tenancy_Account_Scheduled_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Account_Scheduled_Jobs_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_AccountsArgs = {
  where: Tenancy_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Accounts_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_ActionArgs = {
  where: Tenancy_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Action_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_App_ConfigArgs = {
  where: Tenancy_App_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_App_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_AppletArgs = {
  where: Tenancy_Applet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Applet_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Applet_InstanceArgs = {
  where: Tenancy_Applet_Instance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Applet_Instance_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Applet_VersionArgs = {
  where: Tenancy_Applet_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Applet_Version_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Audit_LogsArgs = {
  where: Tenancy_Audit_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Audit_Logs_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_ConditionArgs = {
  where: Tenancy_Condition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Condition_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_CountryArgs = {
  where: Tenancy_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Country_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_DomainArgs = {
  where: Tenancy_Domain_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Domain_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Failed_Kafka_EventsArgs = {
  where: Tenancy_Failed_Kafka_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Failed_Kafka_Events_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_FileArgs = {
  where: Tenancy_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_File_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_LocaleArgs = {
  where: Tenancy_Locale_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Locale_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Localization_SettingsArgs = {
  where: Tenancy_Localization_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Localization_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_PackageArgs = {
  where: Tenancy_Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Package_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Package_VersionArgs = {
  where: Tenancy_Package_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Package_Version_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_ProductsArgs = {
  where: Tenancy_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Products_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Project_Product_SettingsArgs = {
  where: Tenancy_Project_Product_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Project_Product_Settings_By_PkArgs = {
  productId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Project_Release_Channel_ConfigArgs = {
  where: Tenancy_Project_Release_Channel_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Project_Release_Channel_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_ProjectsArgs = {
  where: Tenancy_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Projects_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Release_ChannelArgs = {
  where: Tenancy_Release_Channel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Release_Channel_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_RolesArgs = {
  where: Tenancy_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Roles_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Scheduled_JobsArgs = {
  where: Tenancy_Scheduled_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Scheduled_Jobs_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Team_Account_ProjectsArgs = {
  where: Tenancy_Team_Account_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Team_Account_Projects_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Team_Account_Release_Channel_ConfigArgs = {
  where: Tenancy_Team_Account_Release_Channel_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Team_Account_Release_Channel_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Team_AccountsArgs = {
  where: Tenancy_Team_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Team_Accounts_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_TeamsArgs = {
  where: Tenancy_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Teams_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Time_ZoneArgs = {
  where: Tenancy_Time_Zone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Time_Zone_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_TriggerArgs = {
  where: Tenancy_Trigger_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Trigger_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_User_Account_ProjectsArgs = {
  where: Tenancy_User_Account_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_User_Account_Projects_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_User_Account_Release_Channel_ConfigArgs = {
  where: Tenancy_User_Account_Release_Channel_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_User_Account_Release_Channel_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_User_AccountsArgs = {
  where: Tenancy_User_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_User_Accounts_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_UsersArgs = {
  where: Tenancy_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Users_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_VariableArgs = {
  where: Tenancy_Variable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenancy_Variable_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsert_App_Loader_Data_StoreArgs = {
  objects: Array<App_Loader_Data_Store_Insert_Input>;
  on_conflict?: InputMaybe<App_Loader_Data_Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_Loader_Data_Store_OneArgs = {
  object: App_Loader_Data_Store_Insert_Input;
  on_conflict?: InputMaybe<App_Loader_Data_Store_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_AppArgs = {
  objects: Array<Apps_App_Insert_Input>;
  on_conflict?: InputMaybe<Apps_App_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_App_ConfigArgs = {
  objects: Array<Apps_App_Config_Insert_Input>;
  on_conflict?: InputMaybe<Apps_App_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_App_Config_OneArgs = {
  object: Apps_App_Config_Insert_Input;
  on_conflict?: InputMaybe<Apps_App_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_App_FileArgs = {
  objects: Array<Apps_App_File_Insert_Input>;
  on_conflict?: InputMaybe<Apps_App_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_App_File_OneArgs = {
  object: Apps_App_File_Insert_Input;
  on_conflict?: InputMaybe<Apps_App_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_App_OneArgs = {
  object: Apps_App_Insert_Input;
  on_conflict?: InputMaybe<Apps_App_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Convert_RoundtripArgs = {
  objects: Array<Apps_Convert_Roundtrip_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Convert_Roundtrip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Convert_Roundtrip_OneArgs = {
  object: Apps_Convert_Roundtrip_Insert_Input;
  on_conflict?: InputMaybe<Apps_Convert_Roundtrip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Csv_FileArgs = {
  objects: Array<Apps_Csv_File_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Csv_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Csv_File_OneArgs = {
  object: Apps_Csv_File_Insert_Input;
  on_conflict?: InputMaybe<Apps_Csv_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_CustomerArgs = {
  objects: Array<Apps_Customer_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Customer_ConfigArgs = {
  objects: Array<Apps_Customer_Config_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Customer_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Customer_Config_OneArgs = {
  object: Apps_Customer_Config_Insert_Input;
  on_conflict?: InputMaybe<Apps_Customer_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Customer_OneArgs = {
  object: Apps_Customer_Insert_Input;
  on_conflict?: InputMaybe<Apps_Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_DeploymentArgs = {
  objects: Array<Apps_Deployment_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Deployment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Deployment_OneArgs = {
  object: Apps_Deployment_Insert_Input;
  on_conflict?: InputMaybe<Apps_Deployment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_EnvironmentArgs = {
  objects: Array<Apps_Environment_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Environment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Environment_OneArgs = {
  object: Apps_Environment_Insert_Input;
  on_conflict?: InputMaybe<Apps_Environment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_FileArgs = {
  objects: Array<Apps_File_Insert_Input>;
  on_conflict?: InputMaybe<Apps_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_File_OneArgs = {
  object: Apps_File_Insert_Input;
  on_conflict?: InputMaybe<Apps_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Import_FailArgs = {
  objects: Array<Apps_Import_Fail_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Import_Fail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Import_Fail_NodeArgs = {
  objects: Array<Apps_Import_Fail_Node_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Import_Fail_Node_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Import_Fail_Node_OneArgs = {
  object: Apps_Import_Fail_Node_Insert_Input;
  on_conflict?: InputMaybe<Apps_Import_Fail_Node_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Import_Fail_OneArgs = {
  object: Apps_Import_Fail_Insert_Input;
  on_conflict?: InputMaybe<Apps_Import_Fail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Nlu_FilesArgs = {
  objects: Array<Apps_Nlu_Files_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Nlu_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Nlu_Files_OneArgs = {
  object: Apps_Nlu_Files_Insert_Input;
  on_conflict?: InputMaybe<Apps_Nlu_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_NodeArgs = {
  objects: Array<Apps_Node_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Node_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Node_Import_FlatArgs = {
  objects: Array<Apps_Node_Import_Flat_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Node_Import_Flat_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Node_Import_Flat_OneArgs = {
  object: Apps_Node_Import_Flat_Insert_Input;
  on_conflict?: InputMaybe<Apps_Node_Import_Flat_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Node_Import_JsonArgs = {
  objects: Array<Apps_Node_Import_Json_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Node_Import_Json_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Node_Import_Json_OneArgs = {
  object: Apps_Node_Import_Json_Insert_Input;
  on_conflict?: InputMaybe<Apps_Node_Import_Json_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Node_InstanceArgs = {
  objects: Array<Apps_Node_Instance_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Node_Instance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Node_Instance_OneArgs = {
  object: Apps_Node_Instance_Insert_Input;
  on_conflict?: InputMaybe<Apps_Node_Instance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Node_OneArgs = {
  object: Apps_Node_Insert_Input;
  on_conflict?: InputMaybe<Apps_Node_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Rich_AssetArgs = {
  objects: Array<Apps_Rich_Asset_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Rich_Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Rich_Asset_OneArgs = {
  object: Apps_Rich_Asset_Insert_Input;
  on_conflict?: InputMaybe<Apps_Rich_Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_VersionArgs = {
  objects: Array<Apps_Version_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Version_Display_DataArgs = {
  objects: Array<Apps_Version_Display_Data_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Version_Display_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Version_Display_Data_OneArgs = {
  object: Apps_Version_Display_Data_Insert_Input;
  on_conflict?: InputMaybe<Apps_Version_Display_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Version_FileArgs = {
  objects: Array<Apps_Version_File_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Version_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Version_File_OneArgs = {
  object: Apps_Version_File_Insert_Input;
  on_conflict?: InputMaybe<Apps_Version_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Version_Node_InstanceArgs = {
  objects: Array<Apps_Version_Node_Instance_Insert_Input>;
  on_conflict?: InputMaybe<Apps_Version_Node_Instance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Version_Node_Instance_OneArgs = {
  object: Apps_Version_Node_Instance_Insert_Input;
  on_conflict?: InputMaybe<Apps_Version_Node_Instance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apps_Version_OneArgs = {
  object: Apps_Version_Insert_Input;
  on_conflict?: InputMaybe<Apps_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__ProjectReleaseChannelConfigToVariableArgs = {
  objects: Array<Tenancy__ProjectReleaseChannelConfigToVariable_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__ProjectReleaseChannelConfigToVariable_OneArgs = {
  object: Tenancy__ProjectReleaseChannelConfigToVariable_Insert_Input;
  on_conflict?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__Prisma_MigrationsArgs = {
  objects: Array<Tenancy__Prisma_Migrations_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy__Prisma_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__Prisma_Migrations_OneArgs = {
  object: Tenancy__Prisma_Migrations_Insert_Input;
  on_conflict?: InputMaybe<Tenancy__Prisma_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__Role_ProjectsArgs = {
  objects: Array<Tenancy__Role_Projects_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy__Role_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__Role_Projects_OneArgs = {
  object: Tenancy__Role_Projects_Insert_Input;
  on_conflict?: InputMaybe<Tenancy__Role_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__Team_Account_Project_RolesArgs = {
  objects: Array<Tenancy__Team_Account_Project_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy__Team_Account_Project_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__Team_Account_Project_Roles_OneArgs = {
  object: Tenancy__Team_Account_Project_Roles_Insert_Input;
  on_conflict?: InputMaybe<Tenancy__Team_Account_Project_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__Team_Account_Release_Channel_Config_RolesArgs = {
  objects: Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__Team_Account_Release_Channel_Config_Roles_OneArgs = {
  object: Tenancy__Team_Account_Release_Channel_Config_Roles_Insert_Input;
  on_conflict?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__Team_Account_RolesArgs = {
  objects: Array<Tenancy__Team_Account_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy__Team_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__Team_Account_Roles_OneArgs = {
  object: Tenancy__Team_Account_Roles_Insert_Input;
  on_conflict?: InputMaybe<Tenancy__Team_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__User_Account_Project_RolesArgs = {
  objects: Array<Tenancy__User_Account_Project_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy__User_Account_Project_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__User_Account_Project_Roles_OneArgs = {
  object: Tenancy__User_Account_Project_Roles_Insert_Input;
  on_conflict?: InputMaybe<Tenancy__User_Account_Project_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__User_Account_Release_Channel_Config_RolesArgs = {
  objects: Array<Tenancy__User_Account_Release_Channel_Config_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__User_Account_Release_Channel_Config_Roles_OneArgs = {
  object: Tenancy__User_Account_Release_Channel_Config_Roles_Insert_Input;
  on_conflict?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__User_Account_RolesArgs = {
  objects: Array<Tenancy__User_Account_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy__User_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__User_Account_Roles_OneArgs = {
  object: Tenancy__User_Account_Roles_Insert_Input;
  on_conflict?: InputMaybe<Tenancy__User_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__User_TeamsArgs = {
  objects: Array<Tenancy__User_Teams_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy__User_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy__User_Teams_OneArgs = {
  object: Tenancy__User_Teams_Insert_Input;
  on_conflict?: InputMaybe<Tenancy__User_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Account_Scheduled_Job_RunsArgs = {
  objects: Array<Tenancy_Account_Scheduled_Job_Runs_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Account_Scheduled_Job_Runs_OneArgs = {
  object: Tenancy_Account_Scheduled_Job_Runs_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Account_Scheduled_JobsArgs = {
  objects: Array<Tenancy_Account_Scheduled_Jobs_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Account_Scheduled_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Account_Scheduled_Jobs_OneArgs = {
  object: Tenancy_Account_Scheduled_Jobs_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Account_Scheduled_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_AccountsArgs = {
  objects: Array<Tenancy_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Accounts_OneArgs = {
  object: Tenancy_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_ActionArgs = {
  objects: Array<Tenancy_Action_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Action_OneArgs = {
  object: Tenancy_Action_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_App_ConfigArgs = {
  objects: Array<Tenancy_App_Config_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_App_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_App_Config_OneArgs = {
  object: Tenancy_App_Config_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_App_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_AppletArgs = {
  objects: Array<Tenancy_Applet_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Applet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Applet_InstanceArgs = {
  objects: Array<Tenancy_Applet_Instance_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Applet_Instance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Applet_Instance_OneArgs = {
  object: Tenancy_Applet_Instance_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Applet_Instance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Applet_OneArgs = {
  object: Tenancy_Applet_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Applet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Applet_VersionArgs = {
  objects: Array<Tenancy_Applet_Version_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Applet_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Applet_Version_OneArgs = {
  object: Tenancy_Applet_Version_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Applet_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Audit_LogsArgs = {
  objects: Array<Tenancy_Audit_Logs_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Audit_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Audit_Logs_OneArgs = {
  object: Tenancy_Audit_Logs_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Audit_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_ConditionArgs = {
  objects: Array<Tenancy_Condition_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Condition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Condition_OneArgs = {
  object: Tenancy_Condition_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Condition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_CountryArgs = {
  objects: Array<Tenancy_Country_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Country_OneArgs = {
  object: Tenancy_Country_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_DomainArgs = {
  objects: Array<Tenancy_Domain_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Domain_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Domain_OneArgs = {
  object: Tenancy_Domain_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Domain_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Failed_Kafka_EventsArgs = {
  objects: Array<Tenancy_Failed_Kafka_Events_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Failed_Kafka_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Failed_Kafka_Events_OneArgs = {
  object: Tenancy_Failed_Kafka_Events_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Failed_Kafka_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_FileArgs = {
  objects: Array<Tenancy_File_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_File_OneArgs = {
  object: Tenancy_File_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_LocaleArgs = {
  objects: Array<Tenancy_Locale_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Locale_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Locale_OneArgs = {
  object: Tenancy_Locale_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Locale_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Localization_SettingsArgs = {
  objects: Array<Tenancy_Localization_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Localization_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Localization_Settings_OneArgs = {
  object: Tenancy_Localization_Settings_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Localization_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_PackageArgs = {
  objects: Array<Tenancy_Package_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Package_OneArgs = {
  object: Tenancy_Package_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Package_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Package_VersionArgs = {
  objects: Array<Tenancy_Package_Version_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Package_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Package_Version_OneArgs = {
  object: Tenancy_Package_Version_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Package_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_ProductsArgs = {
  objects: Array<Tenancy_Products_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Products_OneArgs = {
  object: Tenancy_Products_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Project_Product_SettingsArgs = {
  objects: Array<Tenancy_Project_Product_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Project_Product_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Project_Product_Settings_OneArgs = {
  object: Tenancy_Project_Product_Settings_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Project_Product_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Project_Release_Channel_ConfigArgs = {
  objects: Array<Tenancy_Project_Release_Channel_Config_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Project_Release_Channel_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Project_Release_Channel_Config_OneArgs = {
  object: Tenancy_Project_Release_Channel_Config_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Project_Release_Channel_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_ProjectsArgs = {
  objects: Array<Tenancy_Projects_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Projects_OneArgs = {
  object: Tenancy_Projects_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Release_ChannelArgs = {
  objects: Array<Tenancy_Release_Channel_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Release_Channel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Release_Channel_OneArgs = {
  object: Tenancy_Release_Channel_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Release_Channel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_RolesArgs = {
  objects: Array<Tenancy_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Roles_OneArgs = {
  object: Tenancy_Roles_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Scheduled_JobsArgs = {
  objects: Array<Tenancy_Scheduled_Jobs_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Scheduled_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Scheduled_Jobs_OneArgs = {
  object: Tenancy_Scheduled_Jobs_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Scheduled_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Team_Account_ProjectsArgs = {
  objects: Array<Tenancy_Team_Account_Projects_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Team_Account_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Team_Account_Projects_OneArgs = {
  object: Tenancy_Team_Account_Projects_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Team_Account_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Team_Account_Release_Channel_ConfigArgs = {
  objects: Array<Tenancy_Team_Account_Release_Channel_Config_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Team_Account_Release_Channel_Config_OneArgs = {
  object: Tenancy_Team_Account_Release_Channel_Config_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Team_AccountsArgs = {
  objects: Array<Tenancy_Team_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Team_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Team_Accounts_OneArgs = {
  object: Tenancy_Team_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Team_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_TeamsArgs = {
  objects: Array<Tenancy_Teams_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Teams_OneArgs = {
  object: Tenancy_Teams_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Time_ZoneArgs = {
  objects: Array<Tenancy_Time_Zone_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Time_Zone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Time_Zone_OneArgs = {
  object: Tenancy_Time_Zone_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Time_Zone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_TriggerArgs = {
  objects: Array<Tenancy_Trigger_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Trigger_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Trigger_OneArgs = {
  object: Tenancy_Trigger_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Trigger_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_User_Account_ProjectsArgs = {
  objects: Array<Tenancy_User_Account_Projects_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_User_Account_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_User_Account_Projects_OneArgs = {
  object: Tenancy_User_Account_Projects_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_User_Account_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_User_Account_Release_Channel_ConfigArgs = {
  objects: Array<Tenancy_User_Account_Release_Channel_Config_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_User_Account_Release_Channel_Config_OneArgs = {
  object: Tenancy_User_Account_Release_Channel_Config_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_User_AccountsArgs = {
  objects: Array<Tenancy_User_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_User_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_User_Accounts_OneArgs = {
  object: Tenancy_User_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_User_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_UsersArgs = {
  objects: Array<Tenancy_Users_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Users_OneArgs = {
  object: Tenancy_Users_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_VariableArgs = {
  objects: Array<Tenancy_Variable_Insert_Input>;
  on_conflict?: InputMaybe<Tenancy_Variable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenancy_Variable_OneArgs = {
  object: Tenancy_Variable_Insert_Input;
  on_conflict?: InputMaybe<Tenancy_Variable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootPatchBotByIdArgs = {
  bot2Input: Bot2Input;
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPatchCustomerPackagesArgs = {
  customerId: Scalars['String']['input'];
  customerPackagesInput: CustomerPackagesInput;
};


/** mutation root */
export type Mutation_RootPatchCustomerResourcesArgs = {
  customerId: Scalars['String']['input'];
  customerResourcesInput: CustomerResourcesInput;
};


/** mutation root */
export type Mutation_RootPatchOneIntentArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPatchOneSynonymArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPatchOneTypeEntityArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPatchOneUtteranceArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostBotVersionsArgs = {
  botId: Scalars['String']['input'];
  botVersionsInput: BotVersionsInput;
};


/** mutation root */
export type Mutation_RootPostCustomerBotsArgs = {
  customerBotsInput: CustomerBotsInput;
  customerId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostCustomerPackagesArgs = {
  customerId: Scalars['String']['input'];
  customerPackagesInput: CustomerPackagesInput;
};


/** mutation root */
export type Mutation_RootPostCustomersArgs = {
  customersInput: CustomersInput;
};


/** mutation root */
export type Mutation_RootPostEntitiesArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostHistorySearchArgs = {
  customerId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostIntentsArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostOneIntentArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostOneSynonymArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostOneTypeEntityArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostOneUtteranceArgs = {
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostVersionBulkTestArgs = {
  versionBulkTestInput: VersionBulkTestInput;
  versionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostVersionConfigArgs = {
  versionConfigInput: VersionConfigInput;
  versionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostVersionNluTestArgs = {
  utterance: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPostVersionScriptArgs = {
  versionId: Scalars['String']['input'];
  versionScriptsInput: VersionScriptsInput;
};


/** mutation root */
export type Mutation_RootPostVersionStopwordsArgs = {
  versionId: Scalars['String']['input'];
  versionStopwordsInput: VersionStopwordsInput;
};


/** mutation root */
export type Mutation_RootPostVersionTemplateArgs = {
  botVersion?: InputMaybe<BotVersion>;
  versionId: Scalars['String']['input'];
  versionTemplatesInput: VersionTemplatesInput;
};


/** mutation root */
export type Mutation_RootPutBotAssetsArgs = {
  botAssetsInput: BotAssetsInput;
  botId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPutCompileArgs = {
  botVersion?: InputMaybe<BotVersion>;
  compileInput: CompileInput;
};


/** mutation root */
export type Mutation_RootPutVersionDeployArgs = {
  versionDeployInput: VersionDeployInput;
  versionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPutVersionGraphArgs = {
  versionGraphInput: VersionGraphInput;
  versionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPutVersionPreviewArgs = {
  versionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPutVersionSearchNluTrainArgs = {
  searchNLUEndpointName: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
  versionSearchNluTrainInput: VersionSearchNluTrainInput;
};


/** mutation root */
export type Mutation_RootPutVersionTrainArgs = {
  versionId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdate_App_Loader_Data_StoreArgs = {
  _append?: InputMaybe<App_Loader_Data_Store_Append_Input>;
  _delete_at_path?: InputMaybe<App_Loader_Data_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Loader_Data_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Loader_Data_Store_Delete_Key_Input>;
  _prepend?: InputMaybe<App_Loader_Data_Store_Prepend_Input>;
  _set?: InputMaybe<App_Loader_Data_Store_Set_Input>;
  where: App_Loader_Data_Store_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_Loader_Data_Store_By_PkArgs = {
  _append?: InputMaybe<App_Loader_Data_Store_Append_Input>;
  _delete_at_path?: InputMaybe<App_Loader_Data_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Loader_Data_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Loader_Data_Store_Delete_Key_Input>;
  _prepend?: InputMaybe<App_Loader_Data_Store_Prepend_Input>;
  _set?: InputMaybe<App_Loader_Data_Store_Set_Input>;
  pk_columns: App_Loader_Data_Store_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_Loader_Data_Store_ManyArgs = {
  updates: Array<App_Loader_Data_Store_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_AppArgs = {
  _append?: InputMaybe<Apps_App_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_App_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_App_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_App_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_App_Inc_Input>;
  _prepend?: InputMaybe<Apps_App_Prepend_Input>;
  _set?: InputMaybe<Apps_App_Set_Input>;
  where: Apps_App_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_App_By_PkArgs = {
  _append?: InputMaybe<Apps_App_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_App_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_App_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_App_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_App_Inc_Input>;
  _prepend?: InputMaybe<Apps_App_Prepend_Input>;
  _set?: InputMaybe<Apps_App_Set_Input>;
  pk_columns: Apps_App_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_App_ConfigArgs = {
  _append?: InputMaybe<Apps_App_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_App_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_App_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_App_Config_Delete_Key_Input>;
  _prepend?: InputMaybe<Apps_App_Config_Prepend_Input>;
  _set?: InputMaybe<Apps_App_Config_Set_Input>;
  where: Apps_App_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_App_Config_By_PkArgs = {
  _append?: InputMaybe<Apps_App_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_App_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_App_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_App_Config_Delete_Key_Input>;
  _prepend?: InputMaybe<Apps_App_Config_Prepend_Input>;
  _set?: InputMaybe<Apps_App_Config_Set_Input>;
  pk_columns: Apps_App_Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_App_Config_ManyArgs = {
  updates: Array<Apps_App_Config_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_App_FileArgs = {
  _inc?: InputMaybe<Apps_App_File_Inc_Input>;
  _set?: InputMaybe<Apps_App_File_Set_Input>;
  where: Apps_App_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_App_File_By_PkArgs = {
  _inc?: InputMaybe<Apps_App_File_Inc_Input>;
  _set?: InputMaybe<Apps_App_File_Set_Input>;
  pk_columns: Apps_App_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_App_File_ManyArgs = {
  updates: Array<Apps_App_File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_App_ManyArgs = {
  updates: Array<Apps_App_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Convert_RoundtripArgs = {
  _append?: InputMaybe<Apps_Convert_Roundtrip_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Convert_Roundtrip_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Convert_Roundtrip_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Convert_Roundtrip_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Convert_Roundtrip_Inc_Input>;
  _prepend?: InputMaybe<Apps_Convert_Roundtrip_Prepend_Input>;
  _set?: InputMaybe<Apps_Convert_Roundtrip_Set_Input>;
  where: Apps_Convert_Roundtrip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Convert_Roundtrip_By_PkArgs = {
  _append?: InputMaybe<Apps_Convert_Roundtrip_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Convert_Roundtrip_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Convert_Roundtrip_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Convert_Roundtrip_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Convert_Roundtrip_Inc_Input>;
  _prepend?: InputMaybe<Apps_Convert_Roundtrip_Prepend_Input>;
  _set?: InputMaybe<Apps_Convert_Roundtrip_Set_Input>;
  pk_columns: Apps_Convert_Roundtrip_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Convert_Roundtrip_ManyArgs = {
  updates: Array<Apps_Convert_Roundtrip_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Csv_FileArgs = {
  _set?: InputMaybe<Apps_Csv_File_Set_Input>;
  where: Apps_Csv_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Csv_File_By_PkArgs = {
  _set?: InputMaybe<Apps_Csv_File_Set_Input>;
  pk_columns: Apps_Csv_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Csv_File_ManyArgs = {
  updates: Array<Apps_Csv_File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_CustomerArgs = {
  _append?: InputMaybe<Apps_Customer_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Customer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Customer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Customer_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Customer_Inc_Input>;
  _prepend?: InputMaybe<Apps_Customer_Prepend_Input>;
  _set?: InputMaybe<Apps_Customer_Set_Input>;
  where: Apps_Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Customer_By_PkArgs = {
  _append?: InputMaybe<Apps_Customer_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Customer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Customer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Customer_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Customer_Inc_Input>;
  _prepend?: InputMaybe<Apps_Customer_Prepend_Input>;
  _set?: InputMaybe<Apps_Customer_Set_Input>;
  pk_columns: Apps_Customer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Customer_ConfigArgs = {
  _append?: InputMaybe<Apps_Customer_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Customer_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Customer_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Customer_Config_Delete_Key_Input>;
  _prepend?: InputMaybe<Apps_Customer_Config_Prepend_Input>;
  _set?: InputMaybe<Apps_Customer_Config_Set_Input>;
  where: Apps_Customer_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Customer_Config_By_PkArgs = {
  _append?: InputMaybe<Apps_Customer_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Customer_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Customer_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Customer_Config_Delete_Key_Input>;
  _prepend?: InputMaybe<Apps_Customer_Config_Prepend_Input>;
  _set?: InputMaybe<Apps_Customer_Config_Set_Input>;
  pk_columns: Apps_Customer_Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Customer_Config_ManyArgs = {
  updates: Array<Apps_Customer_Config_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Customer_ManyArgs = {
  updates: Array<Apps_Customer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_DeploymentArgs = {
  _inc?: InputMaybe<Apps_Deployment_Inc_Input>;
  _set?: InputMaybe<Apps_Deployment_Set_Input>;
  where: Apps_Deployment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Deployment_By_PkArgs = {
  _inc?: InputMaybe<Apps_Deployment_Inc_Input>;
  _set?: InputMaybe<Apps_Deployment_Set_Input>;
  pk_columns: Apps_Deployment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Deployment_ManyArgs = {
  updates: Array<Apps_Deployment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_EnvironmentArgs = {
  _inc?: InputMaybe<Apps_Environment_Inc_Input>;
  _set?: InputMaybe<Apps_Environment_Set_Input>;
  where: Apps_Environment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Environment_By_PkArgs = {
  _inc?: InputMaybe<Apps_Environment_Inc_Input>;
  _set?: InputMaybe<Apps_Environment_Set_Input>;
  pk_columns: Apps_Environment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Environment_ManyArgs = {
  updates: Array<Apps_Environment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_FileArgs = {
  _inc?: InputMaybe<Apps_File_Inc_Input>;
  _set?: InputMaybe<Apps_File_Set_Input>;
  where: Apps_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_File_By_PkArgs = {
  _inc?: InputMaybe<Apps_File_Inc_Input>;
  _set?: InputMaybe<Apps_File_Set_Input>;
  pk_columns: Apps_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_File_ManyArgs = {
  updates: Array<Apps_File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Import_FailArgs = {
  _set?: InputMaybe<Apps_Import_Fail_Set_Input>;
  where: Apps_Import_Fail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Import_Fail_By_PkArgs = {
  _set?: InputMaybe<Apps_Import_Fail_Set_Input>;
  pk_columns: Apps_Import_Fail_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Import_Fail_ManyArgs = {
  updates: Array<Apps_Import_Fail_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Import_Fail_NodeArgs = {
  _append?: InputMaybe<Apps_Import_Fail_Node_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Import_Fail_Node_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Import_Fail_Node_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Import_Fail_Node_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Import_Fail_Node_Inc_Input>;
  _prepend?: InputMaybe<Apps_Import_Fail_Node_Prepend_Input>;
  _set?: InputMaybe<Apps_Import_Fail_Node_Set_Input>;
  where: Apps_Import_Fail_Node_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Import_Fail_Node_By_PkArgs = {
  _append?: InputMaybe<Apps_Import_Fail_Node_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Import_Fail_Node_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Import_Fail_Node_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Import_Fail_Node_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Import_Fail_Node_Inc_Input>;
  _prepend?: InputMaybe<Apps_Import_Fail_Node_Prepend_Input>;
  _set?: InputMaybe<Apps_Import_Fail_Node_Set_Input>;
  pk_columns: Apps_Import_Fail_Node_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Import_Fail_Node_ManyArgs = {
  updates: Array<Apps_Import_Fail_Node_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Nlu_FilesArgs = {
  _inc?: InputMaybe<Apps_Nlu_Files_Inc_Input>;
  _set?: InputMaybe<Apps_Nlu_Files_Set_Input>;
  where: Apps_Nlu_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Nlu_Files_By_PkArgs = {
  _inc?: InputMaybe<Apps_Nlu_Files_Inc_Input>;
  _set?: InputMaybe<Apps_Nlu_Files_Set_Input>;
  pk_columns: Apps_Nlu_Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Nlu_Files_ManyArgs = {
  updates: Array<Apps_Nlu_Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_NodeArgs = {
  _append?: InputMaybe<Apps_Node_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Node_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Node_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Node_Delete_Key_Input>;
  _prepend?: InputMaybe<Apps_Node_Prepend_Input>;
  _set?: InputMaybe<Apps_Node_Set_Input>;
  where: Apps_Node_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Node_By_PkArgs = {
  _append?: InputMaybe<Apps_Node_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Node_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Node_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Node_Delete_Key_Input>;
  _prepend?: InputMaybe<Apps_Node_Prepend_Input>;
  _set?: InputMaybe<Apps_Node_Set_Input>;
  pk_columns: Apps_Node_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Node_Import_FlatArgs = {
  _append?: InputMaybe<Apps_Node_Import_Flat_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Node_Import_Flat_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Node_Import_Flat_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Node_Import_Flat_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Node_Import_Flat_Inc_Input>;
  _prepend?: InputMaybe<Apps_Node_Import_Flat_Prepend_Input>;
  _set?: InputMaybe<Apps_Node_Import_Flat_Set_Input>;
  where: Apps_Node_Import_Flat_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Node_Import_Flat_By_PkArgs = {
  _append?: InputMaybe<Apps_Node_Import_Flat_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Node_Import_Flat_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Node_Import_Flat_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Node_Import_Flat_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Node_Import_Flat_Inc_Input>;
  _prepend?: InputMaybe<Apps_Node_Import_Flat_Prepend_Input>;
  _set?: InputMaybe<Apps_Node_Import_Flat_Set_Input>;
  pk_columns: Apps_Node_Import_Flat_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Node_Import_Flat_ManyArgs = {
  updates: Array<Apps_Node_Import_Flat_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Node_Import_JsonArgs = {
  _append?: InputMaybe<Apps_Node_Import_Json_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Node_Import_Json_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Node_Import_Json_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Node_Import_Json_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Node_Import_Json_Inc_Input>;
  _prepend?: InputMaybe<Apps_Node_Import_Json_Prepend_Input>;
  _set?: InputMaybe<Apps_Node_Import_Json_Set_Input>;
  where: Apps_Node_Import_Json_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Node_Import_Json_By_PkArgs = {
  _append?: InputMaybe<Apps_Node_Import_Json_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Node_Import_Json_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Node_Import_Json_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Node_Import_Json_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Node_Import_Json_Inc_Input>;
  _prepend?: InputMaybe<Apps_Node_Import_Json_Prepend_Input>;
  _set?: InputMaybe<Apps_Node_Import_Json_Set_Input>;
  pk_columns: Apps_Node_Import_Json_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Node_Import_Json_ManyArgs = {
  updates: Array<Apps_Node_Import_Json_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Node_InstanceArgs = {
  _append?: InputMaybe<Apps_Node_Instance_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Node_Instance_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Node_Instance_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Node_Instance_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Node_Instance_Inc_Input>;
  _prepend?: InputMaybe<Apps_Node_Instance_Prepend_Input>;
  _set?: InputMaybe<Apps_Node_Instance_Set_Input>;
  where: Apps_Node_Instance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Node_Instance_By_PkArgs = {
  _append?: InputMaybe<Apps_Node_Instance_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Node_Instance_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Node_Instance_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Node_Instance_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Node_Instance_Inc_Input>;
  _prepend?: InputMaybe<Apps_Node_Instance_Prepend_Input>;
  _set?: InputMaybe<Apps_Node_Instance_Set_Input>;
  pk_columns: Apps_Node_Instance_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Node_Instance_ManyArgs = {
  updates: Array<Apps_Node_Instance_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Node_ManyArgs = {
  updates: Array<Apps_Node_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Rich_AssetArgs = {
  _append?: InputMaybe<Apps_Rich_Asset_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Rich_Asset_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Rich_Asset_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Rich_Asset_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Rich_Asset_Inc_Input>;
  _prepend?: InputMaybe<Apps_Rich_Asset_Prepend_Input>;
  _set?: InputMaybe<Apps_Rich_Asset_Set_Input>;
  where: Apps_Rich_Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Rich_Asset_By_PkArgs = {
  _append?: InputMaybe<Apps_Rich_Asset_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Rich_Asset_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Rich_Asset_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Rich_Asset_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Rich_Asset_Inc_Input>;
  _prepend?: InputMaybe<Apps_Rich_Asset_Prepend_Input>;
  _set?: InputMaybe<Apps_Rich_Asset_Set_Input>;
  pk_columns: Apps_Rich_Asset_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Rich_Asset_ManyArgs = {
  updates: Array<Apps_Rich_Asset_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_VersionArgs = {
  _append?: InputMaybe<Apps_Version_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Version_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Version_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Version_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Version_Inc_Input>;
  _prepend?: InputMaybe<Apps_Version_Prepend_Input>;
  _set?: InputMaybe<Apps_Version_Set_Input>;
  where: Apps_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Version_By_PkArgs = {
  _append?: InputMaybe<Apps_Version_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Version_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Version_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Version_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Version_Inc_Input>;
  _prepend?: InputMaybe<Apps_Version_Prepend_Input>;
  _set?: InputMaybe<Apps_Version_Set_Input>;
  pk_columns: Apps_Version_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Version_Display_DataArgs = {
  _append?: InputMaybe<Apps_Version_Display_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Version_Display_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Version_Display_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Version_Display_Data_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Version_Display_Data_Inc_Input>;
  _prepend?: InputMaybe<Apps_Version_Display_Data_Prepend_Input>;
  _set?: InputMaybe<Apps_Version_Display_Data_Set_Input>;
  where: Apps_Version_Display_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Version_Display_Data_By_PkArgs = {
  _append?: InputMaybe<Apps_Version_Display_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Apps_Version_Display_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apps_Version_Display_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apps_Version_Display_Data_Delete_Key_Input>;
  _inc?: InputMaybe<Apps_Version_Display_Data_Inc_Input>;
  _prepend?: InputMaybe<Apps_Version_Display_Data_Prepend_Input>;
  _set?: InputMaybe<Apps_Version_Display_Data_Set_Input>;
  pk_columns: Apps_Version_Display_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Version_Display_Data_ManyArgs = {
  updates: Array<Apps_Version_Display_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Version_FileArgs = {
  _inc?: InputMaybe<Apps_Version_File_Inc_Input>;
  _set?: InputMaybe<Apps_Version_File_Set_Input>;
  where: Apps_Version_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Version_File_By_PkArgs = {
  _inc?: InputMaybe<Apps_Version_File_Inc_Input>;
  _set?: InputMaybe<Apps_Version_File_Set_Input>;
  pk_columns: Apps_Version_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Version_File_ManyArgs = {
  updates: Array<Apps_Version_File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Version_ManyArgs = {
  updates: Array<Apps_Version_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Version_Node_InstanceArgs = {
  _inc?: InputMaybe<Apps_Version_Node_Instance_Inc_Input>;
  _set?: InputMaybe<Apps_Version_Node_Instance_Set_Input>;
  where: Apps_Version_Node_Instance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Version_Node_Instance_By_PkArgs = {
  _inc?: InputMaybe<Apps_Version_Node_Instance_Inc_Input>;
  _set?: InputMaybe<Apps_Version_Node_Instance_Set_Input>;
  pk_columns: Apps_Version_Node_Instance_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Apps_Version_Node_Instance_ManyArgs = {
  updates: Array<Apps_Version_Node_Instance_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__ProjectReleaseChannelConfigToVariableArgs = {
  _set?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Set_Input>;
  where: Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__ProjectReleaseChannelConfigToVariable_ManyArgs = {
  updates: Array<Tenancy__ProjectReleaseChannelConfigToVariable_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__Prisma_MigrationsArgs = {
  _inc?: InputMaybe<Tenancy__Prisma_Migrations_Inc_Input>;
  _set?: InputMaybe<Tenancy__Prisma_Migrations_Set_Input>;
  where: Tenancy__Prisma_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__Prisma_Migrations_By_PkArgs = {
  _inc?: InputMaybe<Tenancy__Prisma_Migrations_Inc_Input>;
  _set?: InputMaybe<Tenancy__Prisma_Migrations_Set_Input>;
  pk_columns: Tenancy__Prisma_Migrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__Prisma_Migrations_ManyArgs = {
  updates: Array<Tenancy__Prisma_Migrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__Role_ProjectsArgs = {
  _set?: InputMaybe<Tenancy__Role_Projects_Set_Input>;
  where: Tenancy__Role_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__Role_Projects_ManyArgs = {
  updates: Array<Tenancy__Role_Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__Team_Account_Project_RolesArgs = {
  _set?: InputMaybe<Tenancy__Team_Account_Project_Roles_Set_Input>;
  where: Tenancy__Team_Account_Project_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__Team_Account_Project_Roles_ManyArgs = {
  updates: Array<Tenancy__Team_Account_Project_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__Team_Account_Release_Channel_Config_RolesArgs = {
  _set?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Set_Input>;
  where: Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__Team_Account_Release_Channel_Config_Roles_ManyArgs = {
  updates: Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__Team_Account_RolesArgs = {
  _set?: InputMaybe<Tenancy__Team_Account_Roles_Set_Input>;
  where: Tenancy__Team_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__Team_Account_Roles_ManyArgs = {
  updates: Array<Tenancy__Team_Account_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__User_Account_Project_RolesArgs = {
  _set?: InputMaybe<Tenancy__User_Account_Project_Roles_Set_Input>;
  where: Tenancy__User_Account_Project_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__User_Account_Project_Roles_ManyArgs = {
  updates: Array<Tenancy__User_Account_Project_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__User_Account_Release_Channel_Config_RolesArgs = {
  _set?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Set_Input>;
  where: Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__User_Account_Release_Channel_Config_Roles_ManyArgs = {
  updates: Array<Tenancy__User_Account_Release_Channel_Config_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__User_Account_RolesArgs = {
  _set?: InputMaybe<Tenancy__User_Account_Roles_Set_Input>;
  where: Tenancy__User_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__User_Account_Roles_ManyArgs = {
  updates: Array<Tenancy__User_Account_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__User_TeamsArgs = {
  _set?: InputMaybe<Tenancy__User_Teams_Set_Input>;
  where: Tenancy__User_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy__User_Teams_ManyArgs = {
  updates: Array<Tenancy__User_Teams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Account_Scheduled_Job_RunsArgs = {
  _append?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Set_Input>;
  where: Tenancy_Account_Scheduled_Job_Runs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Account_Scheduled_Job_Runs_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Set_Input>;
  pk_columns: Tenancy_Account_Scheduled_Job_Runs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Account_Scheduled_Job_Runs_ManyArgs = {
  updates: Array<Tenancy_Account_Scheduled_Job_Runs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Account_Scheduled_JobsArgs = {
  _set?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Set_Input>;
  where: Tenancy_Account_Scheduled_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Account_Scheduled_Jobs_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Set_Input>;
  pk_columns: Tenancy_Account_Scheduled_Jobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Account_Scheduled_Jobs_ManyArgs = {
  updates: Array<Tenancy_Account_Scheduled_Jobs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_AccountsArgs = {
  _append?: InputMaybe<Tenancy_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Accounts_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Accounts_Set_Input>;
  where: Tenancy_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Accounts_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Accounts_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Accounts_Set_Input>;
  pk_columns: Tenancy_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Accounts_ManyArgs = {
  updates: Array<Tenancy_Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_ActionArgs = {
  _append?: InputMaybe<Tenancy_Action_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Action_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Action_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Action_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Action_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Action_Set_Input>;
  where: Tenancy_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Action_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Action_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Action_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Action_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Action_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Action_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Action_Set_Input>;
  pk_columns: Tenancy_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Action_ManyArgs = {
  updates: Array<Tenancy_Action_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_App_ConfigArgs = {
  _set?: InputMaybe<Tenancy_App_Config_Set_Input>;
  where: Tenancy_App_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_App_Config_By_PkArgs = {
  _set?: InputMaybe<Tenancy_App_Config_Set_Input>;
  pk_columns: Tenancy_App_Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_App_Config_ManyArgs = {
  updates: Array<Tenancy_App_Config_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_AppletArgs = {
  _append?: InputMaybe<Tenancy_Applet_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Applet_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Applet_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Applet_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Applet_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Applet_Set_Input>;
  where: Tenancy_Applet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Applet_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Applet_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Applet_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Applet_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Applet_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Applet_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Applet_Set_Input>;
  pk_columns: Tenancy_Applet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Applet_InstanceArgs = {
  _append?: InputMaybe<Tenancy_Applet_Instance_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Applet_Instance_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Applet_Instance_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Applet_Instance_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Applet_Instance_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Applet_Instance_Set_Input>;
  where: Tenancy_Applet_Instance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Applet_Instance_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Applet_Instance_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Applet_Instance_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Applet_Instance_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Applet_Instance_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Applet_Instance_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Applet_Instance_Set_Input>;
  pk_columns: Tenancy_Applet_Instance_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Applet_Instance_ManyArgs = {
  updates: Array<Tenancy_Applet_Instance_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Applet_ManyArgs = {
  updates: Array<Tenancy_Applet_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Applet_VersionArgs = {
  _append?: InputMaybe<Tenancy_Applet_Version_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Applet_Version_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Applet_Version_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Applet_Version_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Applet_Version_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Applet_Version_Set_Input>;
  where: Tenancy_Applet_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Applet_Version_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Applet_Version_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Applet_Version_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Applet_Version_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Applet_Version_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Applet_Version_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Applet_Version_Set_Input>;
  pk_columns: Tenancy_Applet_Version_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Applet_Version_ManyArgs = {
  updates: Array<Tenancy_Applet_Version_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Audit_LogsArgs = {
  _append?: InputMaybe<Tenancy_Audit_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Audit_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Audit_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Audit_Logs_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Audit_Logs_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Audit_Logs_Set_Input>;
  where: Tenancy_Audit_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Audit_Logs_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Audit_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Audit_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Audit_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Audit_Logs_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Audit_Logs_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Audit_Logs_Set_Input>;
  pk_columns: Tenancy_Audit_Logs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Audit_Logs_ManyArgs = {
  updates: Array<Tenancy_Audit_Logs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_ConditionArgs = {
  _append?: InputMaybe<Tenancy_Condition_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Condition_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Condition_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Condition_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Condition_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Condition_Set_Input>;
  where: Tenancy_Condition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Condition_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Condition_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Condition_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Condition_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Condition_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Condition_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Condition_Set_Input>;
  pk_columns: Tenancy_Condition_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Condition_ManyArgs = {
  updates: Array<Tenancy_Condition_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_CountryArgs = {
  _set?: InputMaybe<Tenancy_Country_Set_Input>;
  where: Tenancy_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Country_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Country_Set_Input>;
  pk_columns: Tenancy_Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Country_ManyArgs = {
  updates: Array<Tenancy_Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_DomainArgs = {
  _set?: InputMaybe<Tenancy_Domain_Set_Input>;
  where: Tenancy_Domain_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Domain_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Domain_Set_Input>;
  pk_columns: Tenancy_Domain_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Domain_ManyArgs = {
  updates: Array<Tenancy_Domain_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Failed_Kafka_EventsArgs = {
  _set?: InputMaybe<Tenancy_Failed_Kafka_Events_Set_Input>;
  where: Tenancy_Failed_Kafka_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Failed_Kafka_Events_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Failed_Kafka_Events_Set_Input>;
  pk_columns: Tenancy_Failed_Kafka_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Failed_Kafka_Events_ManyArgs = {
  updates: Array<Tenancy_Failed_Kafka_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_FileArgs = {
  _append?: InputMaybe<Tenancy_File_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_File_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_File_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_File_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_File_Prepend_Input>;
  _set?: InputMaybe<Tenancy_File_Set_Input>;
  where: Tenancy_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_File_By_PkArgs = {
  _append?: InputMaybe<Tenancy_File_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_File_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_File_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_File_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_File_Prepend_Input>;
  _set?: InputMaybe<Tenancy_File_Set_Input>;
  pk_columns: Tenancy_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_File_ManyArgs = {
  updates: Array<Tenancy_File_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_LocaleArgs = {
  _set?: InputMaybe<Tenancy_Locale_Set_Input>;
  where: Tenancy_Locale_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Locale_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Locale_Set_Input>;
  pk_columns: Tenancy_Locale_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Locale_ManyArgs = {
  updates: Array<Tenancy_Locale_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Localization_SettingsArgs = {
  _set?: InputMaybe<Tenancy_Localization_Settings_Set_Input>;
  where: Tenancy_Localization_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Localization_Settings_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Localization_Settings_Set_Input>;
  pk_columns: Tenancy_Localization_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Localization_Settings_ManyArgs = {
  updates: Array<Tenancy_Localization_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_PackageArgs = {
  _set?: InputMaybe<Tenancy_Package_Set_Input>;
  where: Tenancy_Package_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Package_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Package_Set_Input>;
  pk_columns: Tenancy_Package_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Package_ManyArgs = {
  updates: Array<Tenancy_Package_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Package_VersionArgs = {
  _append?: InputMaybe<Tenancy_Package_Version_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Package_Version_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Package_Version_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Package_Version_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Package_Version_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Package_Version_Set_Input>;
  where: Tenancy_Package_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Package_Version_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Package_Version_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Package_Version_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Package_Version_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Package_Version_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Package_Version_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Package_Version_Set_Input>;
  pk_columns: Tenancy_Package_Version_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Package_Version_ManyArgs = {
  updates: Array<Tenancy_Package_Version_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_ProductsArgs = {
  _append?: InputMaybe<Tenancy_Products_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Products_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Products_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Products_Set_Input>;
  where: Tenancy_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Products_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Products_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Products_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Products_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Products_Set_Input>;
  pk_columns: Tenancy_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Products_ManyArgs = {
  updates: Array<Tenancy_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Project_Product_SettingsArgs = {
  _append?: InputMaybe<Tenancy_Project_Product_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Project_Product_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Project_Product_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Project_Product_Settings_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Project_Product_Settings_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Project_Product_Settings_Set_Input>;
  where: Tenancy_Project_Product_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Project_Product_Settings_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Project_Product_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Project_Product_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Project_Product_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Project_Product_Settings_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Project_Product_Settings_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Project_Product_Settings_Set_Input>;
  pk_columns: Tenancy_Project_Product_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Project_Product_Settings_ManyArgs = {
  updates: Array<Tenancy_Project_Product_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Project_Release_Channel_ConfigArgs = {
  _set?: InputMaybe<Tenancy_Project_Release_Channel_Config_Set_Input>;
  where: Tenancy_Project_Release_Channel_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Project_Release_Channel_Config_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Project_Release_Channel_Config_Set_Input>;
  pk_columns: Tenancy_Project_Release_Channel_Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Project_Release_Channel_Config_ManyArgs = {
  updates: Array<Tenancy_Project_Release_Channel_Config_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_ProjectsArgs = {
  _append?: InputMaybe<Tenancy_Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Projects_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Projects_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Projects_Set_Input>;
  where: Tenancy_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Projects_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Projects_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Projects_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Projects_Set_Input>;
  pk_columns: Tenancy_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Projects_ManyArgs = {
  updates: Array<Tenancy_Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Release_ChannelArgs = {
  _set?: InputMaybe<Tenancy_Release_Channel_Set_Input>;
  where: Tenancy_Release_Channel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Release_Channel_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Release_Channel_Set_Input>;
  pk_columns: Tenancy_Release_Channel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Release_Channel_ManyArgs = {
  updates: Array<Tenancy_Release_Channel_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_RolesArgs = {
  _append?: InputMaybe<Tenancy_Roles_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Roles_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Roles_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Roles_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Roles_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Roles_Set_Input>;
  where: Tenancy_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Roles_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Roles_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Roles_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Roles_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Roles_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Roles_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Roles_Set_Input>;
  pk_columns: Tenancy_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Roles_ManyArgs = {
  updates: Array<Tenancy_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Scheduled_JobsArgs = {
  _set?: InputMaybe<Tenancy_Scheduled_Jobs_Set_Input>;
  where: Tenancy_Scheduled_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Scheduled_Jobs_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Scheduled_Jobs_Set_Input>;
  pk_columns: Tenancy_Scheduled_Jobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Scheduled_Jobs_ManyArgs = {
  updates: Array<Tenancy_Scheduled_Jobs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Team_Account_ProjectsArgs = {
  _append?: InputMaybe<Tenancy_Team_Account_Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Team_Account_Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Team_Account_Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Team_Account_Projects_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Team_Account_Projects_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Team_Account_Projects_Set_Input>;
  where: Tenancy_Team_Account_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Team_Account_Projects_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Team_Account_Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Team_Account_Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Team_Account_Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Team_Account_Projects_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Team_Account_Projects_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Team_Account_Projects_Set_Input>;
  pk_columns: Tenancy_Team_Account_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Team_Account_Projects_ManyArgs = {
  updates: Array<Tenancy_Team_Account_Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Team_Account_Release_Channel_ConfigArgs = {
  _set?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Set_Input>;
  where: Tenancy_Team_Account_Release_Channel_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Team_Account_Release_Channel_Config_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Set_Input>;
  pk_columns: Tenancy_Team_Account_Release_Channel_Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Team_Account_Release_Channel_Config_ManyArgs = {
  updates: Array<Tenancy_Team_Account_Release_Channel_Config_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Team_AccountsArgs = {
  _append?: InputMaybe<Tenancy_Team_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Team_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Team_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Team_Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Team_Accounts_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Team_Accounts_Set_Input>;
  where: Tenancy_Team_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Team_Accounts_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Team_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Team_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Team_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Team_Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Team_Accounts_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Team_Accounts_Set_Input>;
  pk_columns: Tenancy_Team_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Team_Accounts_ManyArgs = {
  updates: Array<Tenancy_Team_Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_TeamsArgs = {
  _append?: InputMaybe<Tenancy_Teams_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Teams_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Teams_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Teams_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Teams_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Teams_Set_Input>;
  where: Tenancy_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Teams_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Teams_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Teams_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Teams_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Teams_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Teams_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Teams_Set_Input>;
  pk_columns: Tenancy_Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Teams_ManyArgs = {
  updates: Array<Tenancy_Teams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Time_ZoneArgs = {
  _set?: InputMaybe<Tenancy_Time_Zone_Set_Input>;
  where: Tenancy_Time_Zone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Time_Zone_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Time_Zone_Set_Input>;
  pk_columns: Tenancy_Time_Zone_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Time_Zone_ManyArgs = {
  updates: Array<Tenancy_Time_Zone_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_TriggerArgs = {
  _append?: InputMaybe<Tenancy_Trigger_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Trigger_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Trigger_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Trigger_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Trigger_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Trigger_Set_Input>;
  where: Tenancy_Trigger_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Trigger_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Trigger_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Trigger_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Trigger_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Trigger_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Trigger_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Trigger_Set_Input>;
  pk_columns: Tenancy_Trigger_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Trigger_ManyArgs = {
  updates: Array<Tenancy_Trigger_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_User_Account_ProjectsArgs = {
  _append?: InputMaybe<Tenancy_User_Account_Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_User_Account_Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_User_Account_Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_User_Account_Projects_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_User_Account_Projects_Prepend_Input>;
  _set?: InputMaybe<Tenancy_User_Account_Projects_Set_Input>;
  where: Tenancy_User_Account_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_User_Account_Projects_By_PkArgs = {
  _append?: InputMaybe<Tenancy_User_Account_Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_User_Account_Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_User_Account_Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_User_Account_Projects_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_User_Account_Projects_Prepend_Input>;
  _set?: InputMaybe<Tenancy_User_Account_Projects_Set_Input>;
  pk_columns: Tenancy_User_Account_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_User_Account_Projects_ManyArgs = {
  updates: Array<Tenancy_User_Account_Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_User_Account_Release_Channel_ConfigArgs = {
  _set?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Set_Input>;
  where: Tenancy_User_Account_Release_Channel_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_User_Account_Release_Channel_Config_By_PkArgs = {
  _set?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Set_Input>;
  pk_columns: Tenancy_User_Account_Release_Channel_Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_User_Account_Release_Channel_Config_ManyArgs = {
  updates: Array<Tenancy_User_Account_Release_Channel_Config_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_User_AccountsArgs = {
  _append?: InputMaybe<Tenancy_User_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_User_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_User_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_User_Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_User_Accounts_Prepend_Input>;
  _set?: InputMaybe<Tenancy_User_Accounts_Set_Input>;
  where: Tenancy_User_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_User_Accounts_By_PkArgs = {
  _append?: InputMaybe<Tenancy_User_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_User_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_User_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_User_Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_User_Accounts_Prepend_Input>;
  _set?: InputMaybe<Tenancy_User_Accounts_Set_Input>;
  pk_columns: Tenancy_User_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_User_Accounts_ManyArgs = {
  updates: Array<Tenancy_User_Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_UsersArgs = {
  _append?: InputMaybe<Tenancy_Users_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Users_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Users_Set_Input>;
  where: Tenancy_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Users_By_PkArgs = {
  _append?: InputMaybe<Tenancy_Users_Append_Input>;
  _delete_at_path?: InputMaybe<Tenancy_Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tenancy_Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tenancy_Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Tenancy_Users_Prepend_Input>;
  _set?: InputMaybe<Tenancy_Users_Set_Input>;
  pk_columns: Tenancy_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Users_ManyArgs = {
  updates: Array<Tenancy_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_VariableArgs = {
  _set?: InputMaybe<Tenancy_Variable_Set_Input>;
  where: Tenancy_Variable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Variable_By_PkArgs = {
  _set?: InputMaybe<Tenancy_Variable_Set_Input>;
  pk_columns: Tenancy_Variable_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenancy_Variable_ManyArgs = {
  updates: Array<Tenancy_Variable_Updates>;
};

export type Node_Counts_Args = {
  node?: InputMaybe<Scalars['node_jsonb_scalar']['input']>;
};

/** columns and relationships of "node_jsonb" */
export type Node_Jsonb = {
  __typename?: 'node_jsonb';
  children?: Maybe<Array<Scalars['String']['output']>>;
  collapsed: Scalars['Boolean']['output'];
  contents?: Maybe<Scalars['jsonb']['output']>;
  graph_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  label: Scalars['String']['output'];
  nextNode?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "node_counts" */
  node30?: Maybe<Array<Node_Jsonb>>;
  number: Scalars['Int']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};


/** columns and relationships of "node_jsonb" */
export type Node_JsonbContentsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "node_jsonb" */
export type Node_JsonbNode30Args = {
  distinct_on?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Jsonb_Order_By>>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};

/** aggregated selection of "node_jsonb" */
export type Node_Jsonb_Aggregate = {
  __typename?: 'node_jsonb_aggregate';
  aggregate?: Maybe<Node_Jsonb_Aggregate_Fields>;
  nodes: Array<Node_Jsonb>;
};

export type Node_Jsonb_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Node_Jsonb_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Node_Jsonb_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Node_Jsonb_Aggregate_Bool_Exp_Count>;
};

export type Node_Jsonb_Aggregate_Bool_Exp_Bool_And = {
  arguments: Node_Jsonb_Select_Column_Node_Jsonb_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Node_Jsonb_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Node_Jsonb_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Node_Jsonb_Select_Column_Node_Jsonb_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Node_Jsonb_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Node_Jsonb_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Node_Jsonb_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "node_jsonb" */
export type Node_Jsonb_Aggregate_Fields = {
  __typename?: 'node_jsonb_aggregate_fields';
  avg?: Maybe<Node_Jsonb_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Node_Jsonb_Max_Fields>;
  min?: Maybe<Node_Jsonb_Min_Fields>;
  stddev?: Maybe<Node_Jsonb_Stddev_Fields>;
  stddev_pop?: Maybe<Node_Jsonb_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Node_Jsonb_Stddev_Samp_Fields>;
  sum?: Maybe<Node_Jsonb_Sum_Fields>;
  var_pop?: Maybe<Node_Jsonb_Var_Pop_Fields>;
  var_samp?: Maybe<Node_Jsonb_Var_Samp_Fields>;
  variance?: Maybe<Node_Jsonb_Variance_Fields>;
};


/** aggregate fields of "node_jsonb" */
export type Node_Jsonb_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Node_Jsonb_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "node_jsonb" */
export type Node_Jsonb_Aggregate_Order_By = {
  avg?: InputMaybe<Node_Jsonb_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Node_Jsonb_Max_Order_By>;
  min?: InputMaybe<Node_Jsonb_Min_Order_By>;
  stddev?: InputMaybe<Node_Jsonb_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Node_Jsonb_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Node_Jsonb_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Node_Jsonb_Sum_Order_By>;
  var_pop?: InputMaybe<Node_Jsonb_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Node_Jsonb_Var_Samp_Order_By>;
  variance?: InputMaybe<Node_Jsonb_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Node_Jsonb_Append_Input = {
  contents?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "node_jsonb" */
export type Node_Jsonb_Arr_Rel_Insert_Input = {
  data: Array<Node_Jsonb_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Node_Jsonb_On_Conflict>;
};

/** aggregate avg on columns */
export type Node_Jsonb_Avg_Fields = {
  __typename?: 'node_jsonb_avg_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "node_jsonb" */
export type Node_Jsonb_Avg_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "node_jsonb". All fields are combined with a logical 'AND'. */
export type Node_Jsonb_Bool_Exp = {
  _and?: InputMaybe<Array<Node_Jsonb_Bool_Exp>>;
  _not?: InputMaybe<Node_Jsonb_Bool_Exp>;
  _or?: InputMaybe<Array<Node_Jsonb_Bool_Exp>>;
  children?: InputMaybe<String_Array_Comparison_Exp>;
  collapsed?: InputMaybe<Boolean_Comparison_Exp>;
  contents?: InputMaybe<Jsonb_Comparison_Exp>;
  graph_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  nextNode?: InputMaybe<Int_Comparison_Exp>;
  node30?: InputMaybe<Node_Jsonb_Bool_Exp>;
  number?: InputMaybe<Int_Comparison_Exp>;
  parentId?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "node_jsonb" */
export enum Node_Jsonb_Constraint {
  /** unique or primary key constraint on columns "number" */
  NodeJsonbPkey = 'node_jsonb_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Node_Jsonb_Delete_At_Path_Input = {
  contents?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Node_Jsonb_Delete_Elem_Input = {
  contents?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Node_Jsonb_Delete_Key_Input = {
  contents?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "node_jsonb" */
export type Node_Jsonb_Inc_Input = {
  nextNode?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "node_jsonb" */
export type Node_Jsonb_Insert_Input = {
  children?: InputMaybe<Array<Scalars['String']['input']>>;
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  contents?: InputMaybe<Scalars['jsonb']['input']>;
  graph_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  nextNode?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Node_Jsonb_Max_Fields = {
  __typename?: 'node_jsonb_max_fields';
  children?: Maybe<Array<Scalars['String']['output']>>;
  graph_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  nextNode?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "node_jsonb" */
export type Node_Jsonb_Max_Order_By = {
  children?: InputMaybe<Order_By>;
  graph_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Node_Jsonb_Min_Fields = {
  __typename?: 'node_jsonb_min_fields';
  children?: Maybe<Array<Scalars['String']['output']>>;
  graph_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  nextNode?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "node_jsonb" */
export type Node_Jsonb_Min_Order_By = {
  children?: InputMaybe<Order_By>;
  graph_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "node_jsonb" */
export type Node_Jsonb_Mutation_Response = {
  __typename?: 'node_jsonb_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Node_Jsonb>;
};

/** on_conflict condition type for table "node_jsonb" */
export type Node_Jsonb_On_Conflict = {
  constraint: Node_Jsonb_Constraint;
  update_columns?: Array<Node_Jsonb_Update_Column>;
  where?: InputMaybe<Node_Jsonb_Bool_Exp>;
};

/** Ordering options when selecting data from "node_jsonb". */
export type Node_Jsonb_Order_By = {
  children?: InputMaybe<Order_By>;
  collapsed?: InputMaybe<Order_By>;
  contents?: InputMaybe<Order_By>;
  graph_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  nextNode?: InputMaybe<Order_By>;
  node30_aggregate?: InputMaybe<Node_Jsonb_Aggregate_Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: node_jsonb */
export type Node_Jsonb_Pk_Columns_Input = {
  number: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Node_Jsonb_Prepend_Input = {
  contents?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "node_jsonb" */
export enum Node_Jsonb_Select_Column {
  /** column name */
  Children = 'children',
  /** column name */
  Collapsed = 'collapsed',
  /** column name */
  Contents = 'contents',
  /** column name */
  GraphId = 'graph_id',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  NextNode = 'nextNode',
  /** column name */
  Number = 'number',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Type = 'type'
}

/** select "node_jsonb_aggregate_bool_exp_bool_and_arguments_columns" columns of table "node_jsonb" */
export enum Node_Jsonb_Select_Column_Node_Jsonb_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Collapsed = 'collapsed'
}

/** select "node_jsonb_aggregate_bool_exp_bool_or_arguments_columns" columns of table "node_jsonb" */
export enum Node_Jsonb_Select_Column_Node_Jsonb_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Collapsed = 'collapsed'
}

/** input type for updating data in table "node_jsonb" */
export type Node_Jsonb_Set_Input = {
  children?: InputMaybe<Array<Scalars['String']['input']>>;
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  contents?: InputMaybe<Scalars['jsonb']['input']>;
  graph_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  nextNode?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Node_Jsonb_Stddev_Fields = {
  __typename?: 'node_jsonb_stddev_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "node_jsonb" */
export type Node_Jsonb_Stddev_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Node_Jsonb_Stddev_Pop_Fields = {
  __typename?: 'node_jsonb_stddev_pop_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "node_jsonb" */
export type Node_Jsonb_Stddev_Pop_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Node_Jsonb_Stddev_Samp_Fields = {
  __typename?: 'node_jsonb_stddev_samp_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "node_jsonb" */
export type Node_Jsonb_Stddev_Samp_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "node_jsonb" */
export type Node_Jsonb_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Node_Jsonb_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Node_Jsonb_Stream_Cursor_Value_Input = {
  children?: InputMaybe<Array<Scalars['String']['input']>>;
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  contents?: InputMaybe<Scalars['jsonb']['input']>;
  graph_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  nextNode?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Node_Jsonb_Sum_Fields = {
  __typename?: 'node_jsonb_sum_fields';
  nextNode?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "node_jsonb" */
export type Node_Jsonb_Sum_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** update columns of table "node_jsonb" */
export enum Node_Jsonb_Update_Column {
  /** column name */
  Children = 'children',
  /** column name */
  Collapsed = 'collapsed',
  /** column name */
  Contents = 'contents',
  /** column name */
  GraphId = 'graph_id',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  NextNode = 'nextNode',
  /** column name */
  Number = 'number',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Type = 'type'
}

export type Node_Jsonb_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Node_Jsonb_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Node_Jsonb_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Node_Jsonb_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Node_Jsonb_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Node_Jsonb_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Node_Jsonb_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Node_Jsonb_Set_Input>;
  /** filter the rows which have to be updated */
  where: Node_Jsonb_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Node_Jsonb_Var_Pop_Fields = {
  __typename?: 'node_jsonb_var_pop_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "node_jsonb" */
export type Node_Jsonb_Var_Pop_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Node_Jsonb_Var_Samp_Fields = {
  __typename?: 'node_jsonb_var_samp_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "node_jsonb" */
export type Node_Jsonb_Var_Samp_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Node_Jsonb_Variance_Fields = {
  __typename?: 'node_jsonb_variance_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "node_jsonb" */
export type Node_Jsonb_Variance_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** columns and relationships of "nodes" */
export type Nodes = {
  __typename?: 'nodes';
  children?: Maybe<Array<Scalars['Int']['output']>>;
  collapsed: Scalars['Boolean']['output'];
  /** An array relationship */
  contents: Array<Contents>;
  /** An aggregate relationship */
  contents_aggregate: Contents_Aggregate;
  graph_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  label: Scalars['String']['output'];
  nextNode?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  node?: Maybe<Nodes>;
  /** An array relationship */
  nodes: Array<Nodes>;
  /** An aggregate relationship */
  nodes_aggregate: Nodes_Aggregate;
  number: Scalars['Int']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};


/** columns and relationships of "nodes" */
export type NodesContentsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


/** columns and relationships of "nodes" */
export type NodesContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


/** columns and relationships of "nodes" */
export type NodesNodesArgs = {
  distinct_on?: InputMaybe<Array<Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nodes_Order_By>>;
  where?: InputMaybe<Nodes_Bool_Exp>;
};


/** columns and relationships of "nodes" */
export type NodesNodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nodes_Order_By>>;
  where?: InputMaybe<Nodes_Bool_Exp>;
};

/** aggregated selection of "nodes" */
export type Nodes_Aggregate = {
  __typename?: 'nodes_aggregate';
  aggregate?: Maybe<Nodes_Aggregate_Fields>;
  nodes: Array<Nodes>;
};

export type Nodes_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Nodes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Nodes_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Nodes_Aggregate_Bool_Exp_Count>;
};

export type Nodes_Aggregate_Bool_Exp_Bool_And = {
  arguments: Nodes_Select_Column_Nodes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Nodes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Nodes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Nodes_Select_Column_Nodes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Nodes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Nodes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nodes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Nodes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nodes" */
export type Nodes_Aggregate_Fields = {
  __typename?: 'nodes_aggregate_fields';
  avg?: Maybe<Nodes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Nodes_Max_Fields>;
  min?: Maybe<Nodes_Min_Fields>;
  stddev?: Maybe<Nodes_Stddev_Fields>;
  stddev_pop?: Maybe<Nodes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nodes_Stddev_Samp_Fields>;
  sum?: Maybe<Nodes_Sum_Fields>;
  var_pop?: Maybe<Nodes_Var_Pop_Fields>;
  var_samp?: Maybe<Nodes_Var_Samp_Fields>;
  variance?: Maybe<Nodes_Variance_Fields>;
};


/** aggregate fields of "nodes" */
export type Nodes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nodes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "nodes" */
export type Nodes_Aggregate_Order_By = {
  avg?: InputMaybe<Nodes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nodes_Max_Order_By>;
  min?: InputMaybe<Nodes_Min_Order_By>;
  stddev?: InputMaybe<Nodes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nodes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nodes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nodes_Sum_Order_By>;
  var_pop?: InputMaybe<Nodes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nodes_Var_Samp_Order_By>;
  variance?: InputMaybe<Nodes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nodes" */
export type Nodes_Arr_Rel_Insert_Input = {
  data: Array<Nodes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nodes_On_Conflict>;
};

/** aggregate avg on columns */
export type Nodes_Avg_Fields = {
  __typename?: 'nodes_avg_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "nodes" */
export type Nodes_Avg_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nodes". All fields are combined with a logical 'AND'. */
export type Nodes_Bool_Exp = {
  _and?: InputMaybe<Array<Nodes_Bool_Exp>>;
  _not?: InputMaybe<Nodes_Bool_Exp>;
  _or?: InputMaybe<Array<Nodes_Bool_Exp>>;
  children?: InputMaybe<Int_Array_Comparison_Exp>;
  collapsed?: InputMaybe<Boolean_Comparison_Exp>;
  contents?: InputMaybe<Contents_Bool_Exp>;
  contents_aggregate?: InputMaybe<Contents_Aggregate_Bool_Exp>;
  graph_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  nextNode?: InputMaybe<Int_Comparison_Exp>;
  node?: InputMaybe<Nodes_Bool_Exp>;
  nodes?: InputMaybe<Nodes_Bool_Exp>;
  nodes_aggregate?: InputMaybe<Nodes_Aggregate_Bool_Exp>;
  number?: InputMaybe<Int_Comparison_Exp>;
  parentId?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nodes" */
export enum Nodes_Constraint {
  /** unique or primary key constraint on columns "number" */
  NodesNumberKey = 'nodes_number_key',
  /** unique or primary key constraint on columns "id", "number" */
  NodesPkey = 'nodes_pkey'
}

/** input type for incrementing numeric columns in table "nodes" */
export type Nodes_Inc_Input = {
  nextNode?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "nodes" */
export type Nodes_Insert_Input = {
  children?: InputMaybe<Array<Scalars['Int']['input']>>;
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  contents?: InputMaybe<Contents_Arr_Rel_Insert_Input>;
  graph_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  nextNode?: InputMaybe<Scalars['Int']['input']>;
  node?: InputMaybe<Nodes_Obj_Rel_Insert_Input>;
  nodes?: InputMaybe<Nodes_Arr_Rel_Insert_Input>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Nodes_Max_Fields = {
  __typename?: 'nodes_max_fields';
  children?: Maybe<Array<Scalars['Int']['output']>>;
  graph_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  nextNode?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "nodes" */
export type Nodes_Max_Order_By = {
  children?: InputMaybe<Order_By>;
  graph_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nodes_Min_Fields = {
  __typename?: 'nodes_min_fields';
  children?: Maybe<Array<Scalars['Int']['output']>>;
  graph_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  nextNode?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "nodes" */
export type Nodes_Min_Order_By = {
  children?: InputMaybe<Order_By>;
  graph_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nodes" */
export type Nodes_Mutation_Response = {
  __typename?: 'nodes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Nodes>;
};

/** input type for inserting object relation for remote table "nodes" */
export type Nodes_Obj_Rel_Insert_Input = {
  data: Nodes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nodes_On_Conflict>;
};

/** on_conflict condition type for table "nodes" */
export type Nodes_On_Conflict = {
  constraint: Nodes_Constraint;
  update_columns?: Array<Nodes_Update_Column>;
  where?: InputMaybe<Nodes_Bool_Exp>;
};

/** Ordering options when selecting data from "nodes". */
export type Nodes_Order_By = {
  children?: InputMaybe<Order_By>;
  collapsed?: InputMaybe<Order_By>;
  contents_aggregate?: InputMaybe<Contents_Aggregate_Order_By>;
  graph_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  nextNode?: InputMaybe<Order_By>;
  node?: InputMaybe<Nodes_Order_By>;
  nodes_aggregate?: InputMaybe<Nodes_Aggregate_Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nodes */
export type Nodes_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
  number: Scalars['Int']['input'];
};

/** select columns of table "nodes" */
export enum Nodes_Select_Column {
  /** column name */
  Children = 'children',
  /** column name */
  Collapsed = 'collapsed',
  /** column name */
  GraphId = 'graph_id',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  NextNode = 'nextNode',
  /** column name */
  Number = 'number',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Type = 'type'
}

/** select "nodes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "nodes" */
export enum Nodes_Select_Column_Nodes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Collapsed = 'collapsed'
}

/** select "nodes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "nodes" */
export enum Nodes_Select_Column_Nodes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Collapsed = 'collapsed'
}

/** input type for updating data in table "nodes" */
export type Nodes_Set_Input = {
  children?: InputMaybe<Array<Scalars['Int']['input']>>;
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  graph_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  nextNode?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Nodes_Stddev_Fields = {
  __typename?: 'nodes_stddev_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "nodes" */
export type Nodes_Stddev_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nodes_Stddev_Pop_Fields = {
  __typename?: 'nodes_stddev_pop_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "nodes" */
export type Nodes_Stddev_Pop_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nodes_Stddev_Samp_Fields = {
  __typename?: 'nodes_stddev_samp_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "nodes" */
export type Nodes_Stddev_Samp_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nodes" */
export type Nodes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nodes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nodes_Stream_Cursor_Value_Input = {
  children?: InputMaybe<Array<Scalars['Int']['input']>>;
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  graph_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  nextNode?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Nodes_Sum_Fields = {
  __typename?: 'nodes_sum_fields';
  nextNode?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "nodes" */
export type Nodes_Sum_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** update columns of table "nodes" */
export enum Nodes_Update_Column {
  /** column name */
  Children = 'children',
  /** column name */
  Collapsed = 'collapsed',
  /** column name */
  GraphId = 'graph_id',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  NextNode = 'nextNode',
  /** column name */
  Number = 'number',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Type = 'type'
}

export type Nodes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nodes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nodes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Nodes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nodes_Var_Pop_Fields = {
  __typename?: 'nodes_var_pop_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "nodes" */
export type Nodes_Var_Pop_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nodes_Var_Samp_Fields = {
  __typename?: 'nodes_var_samp_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "nodes" */
export type Nodes_Var_Samp_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nodes_Variance_Fields = {
  __typename?: 'nodes_variance_fields';
  nextNode?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  parentId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "nodes" */
export type Nodes_Variance_Order_By = {
  nextNode?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "parent_child_nodes" */
export type Parent_Child_Nodes = {
  __typename?: 'parent_child_nodes';
  children: Scalars['Int']['output'];
  parent_id: Scalars['Int']['output'];
};

/** aggregated selection of "parent_child_nodes" */
export type Parent_Child_Nodes_Aggregate = {
  __typename?: 'parent_child_nodes_aggregate';
  aggregate?: Maybe<Parent_Child_Nodes_Aggregate_Fields>;
  nodes: Array<Parent_Child_Nodes>;
};

/** aggregate fields of "parent_child_nodes" */
export type Parent_Child_Nodes_Aggregate_Fields = {
  __typename?: 'parent_child_nodes_aggregate_fields';
  avg?: Maybe<Parent_Child_Nodes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Parent_Child_Nodes_Max_Fields>;
  min?: Maybe<Parent_Child_Nodes_Min_Fields>;
  stddev?: Maybe<Parent_Child_Nodes_Stddev_Fields>;
  stddev_pop?: Maybe<Parent_Child_Nodes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parent_Child_Nodes_Stddev_Samp_Fields>;
  sum?: Maybe<Parent_Child_Nodes_Sum_Fields>;
  var_pop?: Maybe<Parent_Child_Nodes_Var_Pop_Fields>;
  var_samp?: Maybe<Parent_Child_Nodes_Var_Samp_Fields>;
  variance?: Maybe<Parent_Child_Nodes_Variance_Fields>;
};


/** aggregate fields of "parent_child_nodes" */
export type Parent_Child_Nodes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parent_Child_Nodes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Parent_Child_Nodes_Avg_Fields = {
  __typename?: 'parent_child_nodes_avg_fields';
  children?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "parent_child_nodes". All fields are combined with a logical 'AND'. */
export type Parent_Child_Nodes_Bool_Exp = {
  _and?: InputMaybe<Array<Parent_Child_Nodes_Bool_Exp>>;
  _not?: InputMaybe<Parent_Child_Nodes_Bool_Exp>;
  _or?: InputMaybe<Array<Parent_Child_Nodes_Bool_Exp>>;
  children?: InputMaybe<Int_Comparison_Exp>;
  parent_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "parent_child_nodes" */
export type Parent_Child_Nodes_Inc_Input = {
  children?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "parent_child_nodes" */
export type Parent_Child_Nodes_Insert_Input = {
  children?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Parent_Child_Nodes_Max_Fields = {
  __typename?: 'parent_child_nodes_max_fields';
  children?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Parent_Child_Nodes_Min_Fields = {
  __typename?: 'parent_child_nodes_min_fields';
  children?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "parent_child_nodes" */
export type Parent_Child_Nodes_Mutation_Response = {
  __typename?: 'parent_child_nodes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Parent_Child_Nodes>;
};

/** Ordering options when selecting data from "parent_child_nodes". */
export type Parent_Child_Nodes_Order_By = {
  children?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** select columns of table "parent_child_nodes" */
export enum Parent_Child_Nodes_Select_Column {
  /** column name */
  Children = 'children',
  /** column name */
  ParentId = 'parent_id'
}

/** input type for updating data in table "parent_child_nodes" */
export type Parent_Child_Nodes_Set_Input = {
  children?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Parent_Child_Nodes_Stddev_Fields = {
  __typename?: 'parent_child_nodes_stddev_fields';
  children?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Parent_Child_Nodes_Stddev_Pop_Fields = {
  __typename?: 'parent_child_nodes_stddev_pop_fields';
  children?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Parent_Child_Nodes_Stddev_Samp_Fields = {
  __typename?: 'parent_child_nodes_stddev_samp_fields';
  children?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "parent_child_nodes" */
export type Parent_Child_Nodes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Parent_Child_Nodes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Parent_Child_Nodes_Stream_Cursor_Value_Input = {
  children?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Parent_Child_Nodes_Sum_Fields = {
  __typename?: 'parent_child_nodes_sum_fields';
  children?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['Int']['output']>;
};

export type Parent_Child_Nodes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Parent_Child_Nodes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Parent_Child_Nodes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Parent_Child_Nodes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Parent_Child_Nodes_Var_Pop_Fields = {
  __typename?: 'parent_child_nodes_var_pop_fields';
  children?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Parent_Child_Nodes_Var_Samp_Fields = {
  __typename?: 'parent_child_nodes_var_samp_fields';
  children?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Parent_Child_Nodes_Variance_Fields = {
  __typename?: 'parent_child_nodes_variance_fields';
  children?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  admin_?: Maybe<Admin_Query>;
  app_loader_?: Maybe<App_Loader_Query_Root>;
  /** fetch data from the table: "app_loader.data_store" */
  app_loader_data_store: Array<App_Loader_Data_Store>;
  /** fetch aggregated fields from the table: "app_loader.data_store" */
  app_loader_data_store_aggregate: App_Loader_Data_Store_Aggregate;
  /** fetch data from the table: "app_loader.data_store" using primary key columns */
  app_loader_data_store_by_pk?: Maybe<App_Loader_Data_Store>;
  /** fetch data from the table: "apps.app" */
  apps_app: Array<Apps_App>;
  /** fetch aggregated fields from the table: "apps.app" */
  apps_app_aggregate: Apps_App_Aggregate;
  /** fetch data from the table: "apps.app" using primary key columns */
  apps_app_by_pk?: Maybe<Apps_App>;
  /** fetch data from the table: "apps.app_config" */
  apps_app_config: Array<Apps_App_Config>;
  /** fetch aggregated fields from the table: "apps.app_config" */
  apps_app_config_aggregate: Apps_App_Config_Aggregate;
  /** fetch data from the table: "apps.app_config" using primary key columns */
  apps_app_config_by_pk?: Maybe<Apps_App_Config>;
  /** fetch data from the table: "apps.app_file" */
  apps_app_file: Array<Apps_App_File>;
  /** fetch aggregated fields from the table: "apps.app_file" */
  apps_app_file_aggregate: Apps_App_File_Aggregate;
  /** fetch data from the table: "apps.app_file" using primary key columns */
  apps_app_file_by_pk?: Maybe<Apps_App_File>;
  /** fetch data from the table: "apps.convert_roundtrip" */
  apps_convert_roundtrip: Array<Apps_Convert_Roundtrip>;
  /** fetch aggregated fields from the table: "apps.convert_roundtrip" */
  apps_convert_roundtrip_aggregate: Apps_Convert_Roundtrip_Aggregate;
  /** fetch data from the table: "apps.convert_roundtrip" using primary key columns */
  apps_convert_roundtrip_by_pk?: Maybe<Apps_Convert_Roundtrip>;
  /** fetch data from the table: "apps.csv_file" */
  apps_csv_file: Array<Apps_Csv_File>;
  /** fetch aggregated fields from the table: "apps.csv_file" */
  apps_csv_file_aggregate: Apps_Csv_File_Aggregate;
  /** fetch data from the table: "apps.csv_file" using primary key columns */
  apps_csv_file_by_pk?: Maybe<Apps_Csv_File>;
  /** fetch data from the table: "apps.customer" */
  apps_customer: Array<Apps_Customer>;
  /** fetch aggregated fields from the table: "apps.customer" */
  apps_customer_aggregate: Apps_Customer_Aggregate;
  /** fetch data from the table: "apps.customer" using primary key columns */
  apps_customer_by_pk?: Maybe<Apps_Customer>;
  /** fetch data from the table: "apps.customer_config" */
  apps_customer_config: Array<Apps_Customer_Config>;
  /** fetch aggregated fields from the table: "apps.customer_config" */
  apps_customer_config_aggregate: Apps_Customer_Config_Aggregate;
  /** fetch data from the table: "apps.customer_config" using primary key columns */
  apps_customer_config_by_pk?: Maybe<Apps_Customer_Config>;
  /** fetch data from the table: "apps.deployment" */
  apps_deployment: Array<Apps_Deployment>;
  /** fetch aggregated fields from the table: "apps.deployment" */
  apps_deployment_aggregate: Apps_Deployment_Aggregate;
  /** fetch data from the table: "apps.deployment" using primary key columns */
  apps_deployment_by_pk?: Maybe<Apps_Deployment>;
  /** fetch data from the table: "apps.environment" */
  apps_environment: Array<Apps_Environment>;
  /** fetch aggregated fields from the table: "apps.environment" */
  apps_environment_aggregate: Apps_Environment_Aggregate;
  /** fetch data from the table: "apps.environment" using primary key columns */
  apps_environment_by_pk?: Maybe<Apps_Environment>;
  /** fetch data from the table: "apps.file" */
  apps_file: Array<Apps_File>;
  /** fetch aggregated fields from the table: "apps.file" */
  apps_file_aggregate: Apps_File_Aggregate;
  /** fetch data from the table: "apps.file" using primary key columns */
  apps_file_by_pk?: Maybe<Apps_File>;
  /** fetch data from the table: "apps.import_fail" */
  apps_import_fail: Array<Apps_Import_Fail>;
  /** fetch aggregated fields from the table: "apps.import_fail" */
  apps_import_fail_aggregate: Apps_Import_Fail_Aggregate;
  /** fetch data from the table: "apps.import_fail" using primary key columns */
  apps_import_fail_by_pk?: Maybe<Apps_Import_Fail>;
  /** fetch data from the table: "apps.import_fail_node" */
  apps_import_fail_node: Array<Apps_Import_Fail_Node>;
  /** fetch aggregated fields from the table: "apps.import_fail_node" */
  apps_import_fail_node_aggregate: Apps_Import_Fail_Node_Aggregate;
  /** fetch data from the table: "apps.import_fail_node" using primary key columns */
  apps_import_fail_node_by_pk?: Maybe<Apps_Import_Fail_Node>;
  /** fetch data from the table: "apps.nlu_files" */
  apps_nlu_files: Array<Apps_Nlu_Files>;
  /** fetch aggregated fields from the table: "apps.nlu_files" */
  apps_nlu_files_aggregate: Apps_Nlu_Files_Aggregate;
  /** fetch data from the table: "apps.nlu_files" using primary key columns */
  apps_nlu_files_by_pk?: Maybe<Apps_Nlu_Files>;
  /** fetch data from the table: "apps.node" */
  apps_node: Array<Apps_Node>;
  /** fetch aggregated fields from the table: "apps.node" */
  apps_node_aggregate: Apps_Node_Aggregate;
  /** fetch data from the table: "apps.node" using primary key columns */
  apps_node_by_pk?: Maybe<Apps_Node>;
  /** fetch data from the table: "apps.node_import_flat" */
  apps_node_import_flat: Array<Apps_Node_Import_Flat>;
  /** fetch aggregated fields from the table: "apps.node_import_flat" */
  apps_node_import_flat_aggregate: Apps_Node_Import_Flat_Aggregate;
  /** fetch data from the table: "apps.node_import_flat" using primary key columns */
  apps_node_import_flat_by_pk?: Maybe<Apps_Node_Import_Flat>;
  /** fetch data from the table: "apps.node_import_json" */
  apps_node_import_json: Array<Apps_Node_Import_Json>;
  /** fetch aggregated fields from the table: "apps.node_import_json" */
  apps_node_import_json_aggregate: Apps_Node_Import_Json_Aggregate;
  /** fetch data from the table: "apps.node_import_json" using primary key columns */
  apps_node_import_json_by_pk?: Maybe<Apps_Node_Import_Json>;
  /** fetch data from the table: "apps.node_instance" */
  apps_node_instance: Array<Apps_Node_Instance>;
  /** fetch aggregated fields from the table: "apps.node_instance" */
  apps_node_instance_aggregate: Apps_Node_Instance_Aggregate;
  /** fetch data from the table: "apps.node_instance" using primary key columns */
  apps_node_instance_by_pk?: Maybe<Apps_Node_Instance>;
  /** fetch data from the table: "apps.node_outgoing_edge_view" */
  apps_node_outgoing_edge_view: Array<Apps_Node_Outgoing_Edge_View>;
  /** fetch aggregated fields from the table: "apps.node_outgoing_edge_view" */
  apps_node_outgoing_edge_view_aggregate: Apps_Node_Outgoing_Edge_View_Aggregate;
  /** fetch data from the table: "apps.rich_asset" */
  apps_rich_asset: Array<Apps_Rich_Asset>;
  /** fetch aggregated fields from the table: "apps.rich_asset" */
  apps_rich_asset_aggregate: Apps_Rich_Asset_Aggregate;
  /** fetch data from the table: "apps.rich_asset" using primary key columns */
  apps_rich_asset_by_pk?: Maybe<Apps_Rich_Asset>;
  /** fetch data from the table: "apps.version" */
  apps_version: Array<Apps_Version>;
  /** fetch aggregated fields from the table: "apps.version" */
  apps_version_aggregate: Apps_Version_Aggregate;
  /** fetch data from the table: "apps.version" using primary key columns */
  apps_version_by_pk?: Maybe<Apps_Version>;
  /** fetch data from the table: "apps.version_display_data" */
  apps_version_display_data: Array<Apps_Version_Display_Data>;
  /** fetch aggregated fields from the table: "apps.version_display_data" */
  apps_version_display_data_aggregate: Apps_Version_Display_Data_Aggregate;
  /** fetch data from the table: "apps.version_display_data" using primary key columns */
  apps_version_display_data_by_pk?: Maybe<Apps_Version_Display_Data>;
  /** fetch data from the table: "apps.version_file" */
  apps_version_file: Array<Apps_Version_File>;
  /** fetch aggregated fields from the table: "apps.version_file" */
  apps_version_file_aggregate: Apps_Version_File_Aggregate;
  /** fetch data from the table: "apps.version_file" using primary key columns */
  apps_version_file_by_pk?: Maybe<Apps_Version_File>;
  /** fetch data from the table: "apps.version_node_instance" */
  apps_version_node_instance: Array<Apps_Version_Node_Instance>;
  /** fetch aggregated fields from the table: "apps.version_node_instance" */
  apps_version_node_instance_aggregate: Apps_Version_Node_Instance_Aggregate;
  /** fetch data from the table: "apps.version_node_instance" using primary key columns */
  apps_version_node_instance_by_pk?: Maybe<Apps_Version_Node_Instance>;
  fe_proto_?: Maybe<Fe_Proto_Query_Root>;
  getBotAssetById?: Maybe<Asset>;
  getBotAssets?: Maybe<List>;
  getBotById?: Maybe<Bot>;
  getBotVersions?: Maybe<List>;
  getCustomerBots?: Maybe<List>;
  getCustomerById?: Maybe<Customer>;
  getCustomerPackages?: Maybe<Customer>;
  getCustomerResources?: Maybe<Customer>;
  getCustomers?: Maybe<List>;
  /**
   * Always returns empty data and a
   * 200 response.
   */
  getEntities?: Maybe<Version>;
  getEnvBotVersions: Scalars['String']['output'];
  getGraphMd: Scalars['String']['output'];
  getHealthCheck: Scalars['String']['output'];
  /**
   * Always returns empty data and a
   * 200 response.
   */
  getIntents?: Maybe<Version>;
  getListEnvs?: Maybe<List>;
  /** string and a 500 response. */
  getLivenessCheck: Scalars['JSON']['output'];
  getPdfImage: Scalars['String']['output'];
  /** Always return an empty response. */
  getSupervisedLearning?: Maybe<Version>;
  getVersionById?: Maybe<Version>;
  /** and is not in the blacklist. */
  getVersionFile: Scalars['String']['output'];
  /** has been compiled. */
  getVersionGraph: Scalars['String']['output'];
  /** Do not include a script if it is in the blacklist */
  getVersionScript: Scalars['String']['output'];
  getVersionSearchNluTest: Scalars['String']['output'];
  /**
   * json exists for the given version, return the stopwords
   * otherwise return a list of errors, e.g. "No stopwords for given version"
   */
  getVersionStopwords: Scalars['String']['output'];
  getVersionValidate: Scalars['String']['output'];
  /** fetch data from the table: "tenancy._ProjectReleaseChannelConfigToVariable" */
  tenancy__ProjectReleaseChannelConfigToVariable: Array<Tenancy__ProjectReleaseChannelConfigToVariable>;
  /** fetch aggregated fields from the table: "tenancy._ProjectReleaseChannelConfigToVariable" */
  tenancy__ProjectReleaseChannelConfigToVariable_aggregate: Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate;
  /** fetch data from the table: "tenancy._prisma_migrations" */
  tenancy__prisma_migrations: Array<Tenancy__Prisma_Migrations>;
  /** fetch aggregated fields from the table: "tenancy._prisma_migrations" */
  tenancy__prisma_migrations_aggregate: Tenancy__Prisma_Migrations_Aggregate;
  /** fetch data from the table: "tenancy._prisma_migrations" using primary key columns */
  tenancy__prisma_migrations_by_pk?: Maybe<Tenancy__Prisma_Migrations>;
  /** fetch data from the table: "tenancy._role_projects" */
  tenancy__role_projects: Array<Tenancy__Role_Projects>;
  /** fetch aggregated fields from the table: "tenancy._role_projects" */
  tenancy__role_projects_aggregate: Tenancy__Role_Projects_Aggregate;
  /** fetch data from the table: "tenancy._team_account_project_roles" */
  tenancy__team_account_project_roles: Array<Tenancy__Team_Account_Project_Roles>;
  /** fetch aggregated fields from the table: "tenancy._team_account_project_roles" */
  tenancy__team_account_project_roles_aggregate: Tenancy__Team_Account_Project_Roles_Aggregate;
  /** fetch data from the table: "tenancy._team_account_release_channel_config_roles" */
  tenancy__team_account_release_channel_config_roles: Array<Tenancy__Team_Account_Release_Channel_Config_Roles>;
  /** fetch aggregated fields from the table: "tenancy._team_account_release_channel_config_roles" */
  tenancy__team_account_release_channel_config_roles_aggregate: Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate;
  /** fetch data from the table: "tenancy._team_account_roles" */
  tenancy__team_account_roles: Array<Tenancy__Team_Account_Roles>;
  /** fetch aggregated fields from the table: "tenancy._team_account_roles" */
  tenancy__team_account_roles_aggregate: Tenancy__Team_Account_Roles_Aggregate;
  /** fetch data from the table: "tenancy._user_account_project_roles" */
  tenancy__user_account_project_roles: Array<Tenancy__User_Account_Project_Roles>;
  /** fetch aggregated fields from the table: "tenancy._user_account_project_roles" */
  tenancy__user_account_project_roles_aggregate: Tenancy__User_Account_Project_Roles_Aggregate;
  /** fetch data from the table: "tenancy._user_account_release_channel_config_roles" */
  tenancy__user_account_release_channel_config_roles: Array<Tenancy__User_Account_Release_Channel_Config_Roles>;
  /** fetch aggregated fields from the table: "tenancy._user_account_release_channel_config_roles" */
  tenancy__user_account_release_channel_config_roles_aggregate: Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate;
  /** fetch data from the table: "tenancy._user_account_roles" */
  tenancy__user_account_roles: Array<Tenancy__User_Account_Roles>;
  /** fetch aggregated fields from the table: "tenancy._user_account_roles" */
  tenancy__user_account_roles_aggregate: Tenancy__User_Account_Roles_Aggregate;
  /** fetch data from the table: "tenancy._user_teams" */
  tenancy__user_teams: Array<Tenancy__User_Teams>;
  /** fetch aggregated fields from the table: "tenancy._user_teams" */
  tenancy__user_teams_aggregate: Tenancy__User_Teams_Aggregate;
  /** fetch data from the table: "tenancy.account_scheduled_job_runs" */
  tenancy_account_scheduled_job_runs: Array<Tenancy_Account_Scheduled_Job_Runs>;
  /** fetch aggregated fields from the table: "tenancy.account_scheduled_job_runs" */
  tenancy_account_scheduled_job_runs_aggregate: Tenancy_Account_Scheduled_Job_Runs_Aggregate;
  /** fetch data from the table: "tenancy.account_scheduled_job_runs" using primary key columns */
  tenancy_account_scheduled_job_runs_by_pk?: Maybe<Tenancy_Account_Scheduled_Job_Runs>;
  /** fetch data from the table: "tenancy.account_scheduled_jobs" */
  tenancy_account_scheduled_jobs: Array<Tenancy_Account_Scheduled_Jobs>;
  /** fetch aggregated fields from the table: "tenancy.account_scheduled_jobs" */
  tenancy_account_scheduled_jobs_aggregate: Tenancy_Account_Scheduled_Jobs_Aggregate;
  /** fetch data from the table: "tenancy.account_scheduled_jobs" using primary key columns */
  tenancy_account_scheduled_jobs_by_pk?: Maybe<Tenancy_Account_Scheduled_Jobs>;
  /** fetch data from the table: "tenancy.accounts" */
  tenancy_accounts: Array<Tenancy_Accounts>;
  /** fetch aggregated fields from the table: "tenancy.accounts" */
  tenancy_accounts_aggregate: Tenancy_Accounts_Aggregate;
  /** fetch data from the table: "tenancy.accounts" using primary key columns */
  tenancy_accounts_by_pk?: Maybe<Tenancy_Accounts>;
  /** fetch data from the table: "tenancy.action" */
  tenancy_action: Array<Tenancy_Action>;
  /** fetch aggregated fields from the table: "tenancy.action" */
  tenancy_action_aggregate: Tenancy_Action_Aggregate;
  /** fetch data from the table: "tenancy.action" using primary key columns */
  tenancy_action_by_pk?: Maybe<Tenancy_Action>;
  /** fetch data from the table: "tenancy.app_config" */
  tenancy_app_config: Array<Tenancy_App_Config>;
  /** fetch aggregated fields from the table: "tenancy.app_config" */
  tenancy_app_config_aggregate: Tenancy_App_Config_Aggregate;
  /** fetch data from the table: "tenancy.app_config" using primary key columns */
  tenancy_app_config_by_pk?: Maybe<Tenancy_App_Config>;
  /** fetch data from the table: "tenancy.applet" */
  tenancy_applet: Array<Tenancy_Applet>;
  /** fetch aggregated fields from the table: "tenancy.applet" */
  tenancy_applet_aggregate: Tenancy_Applet_Aggregate;
  /** fetch data from the table: "tenancy.applet" using primary key columns */
  tenancy_applet_by_pk?: Maybe<Tenancy_Applet>;
  /** fetch data from the table: "tenancy.applet_instance" */
  tenancy_applet_instance: Array<Tenancy_Applet_Instance>;
  /** fetch aggregated fields from the table: "tenancy.applet_instance" */
  tenancy_applet_instance_aggregate: Tenancy_Applet_Instance_Aggregate;
  /** fetch data from the table: "tenancy.applet_instance" using primary key columns */
  tenancy_applet_instance_by_pk?: Maybe<Tenancy_Applet_Instance>;
  /** fetch data from the table: "tenancy.applet_version" */
  tenancy_applet_version: Array<Tenancy_Applet_Version>;
  /** fetch aggregated fields from the table: "tenancy.applet_version" */
  tenancy_applet_version_aggregate: Tenancy_Applet_Version_Aggregate;
  /** fetch data from the table: "tenancy.applet_version" using primary key columns */
  tenancy_applet_version_by_pk?: Maybe<Tenancy_Applet_Version>;
  /** fetch data from the table: "tenancy.audit_logs" */
  tenancy_audit_logs: Array<Tenancy_Audit_Logs>;
  /** fetch aggregated fields from the table: "tenancy.audit_logs" */
  tenancy_audit_logs_aggregate: Tenancy_Audit_Logs_Aggregate;
  /** fetch data from the table: "tenancy.audit_logs" using primary key columns */
  tenancy_audit_logs_by_pk?: Maybe<Tenancy_Audit_Logs>;
  /** fetch data from the table: "tenancy.condition" */
  tenancy_condition: Array<Tenancy_Condition>;
  /** fetch aggregated fields from the table: "tenancy.condition" */
  tenancy_condition_aggregate: Tenancy_Condition_Aggregate;
  /** fetch data from the table: "tenancy.condition" using primary key columns */
  tenancy_condition_by_pk?: Maybe<Tenancy_Condition>;
  /** fetch data from the table: "tenancy.country" */
  tenancy_country: Array<Tenancy_Country>;
  /** fetch aggregated fields from the table: "tenancy.country" */
  tenancy_country_aggregate: Tenancy_Country_Aggregate;
  /** fetch data from the table: "tenancy.country" using primary key columns */
  tenancy_country_by_pk?: Maybe<Tenancy_Country>;
  /** fetch data from the table: "tenancy.domain" */
  tenancy_domain: Array<Tenancy_Domain>;
  /** fetch aggregated fields from the table: "tenancy.domain" */
  tenancy_domain_aggregate: Tenancy_Domain_Aggregate;
  /** fetch data from the table: "tenancy.domain" using primary key columns */
  tenancy_domain_by_pk?: Maybe<Tenancy_Domain>;
  /** fetch data from the table: "tenancy.failed_kafka_events" */
  tenancy_failed_kafka_events: Array<Tenancy_Failed_Kafka_Events>;
  /** fetch aggregated fields from the table: "tenancy.failed_kafka_events" */
  tenancy_failed_kafka_events_aggregate: Tenancy_Failed_Kafka_Events_Aggregate;
  /** fetch data from the table: "tenancy.failed_kafka_events" using primary key columns */
  tenancy_failed_kafka_events_by_pk?: Maybe<Tenancy_Failed_Kafka_Events>;
  /** fetch data from the table: "tenancy.file" */
  tenancy_file: Array<Tenancy_File>;
  /** fetch aggregated fields from the table: "tenancy.file" */
  tenancy_file_aggregate: Tenancy_File_Aggregate;
  /** fetch data from the table: "tenancy.file" using primary key columns */
  tenancy_file_by_pk?: Maybe<Tenancy_File>;
  /** fetch data from the table: "tenancy.locale" */
  tenancy_locale: Array<Tenancy_Locale>;
  /** fetch aggregated fields from the table: "tenancy.locale" */
  tenancy_locale_aggregate: Tenancy_Locale_Aggregate;
  /** fetch data from the table: "tenancy.locale" using primary key columns */
  tenancy_locale_by_pk?: Maybe<Tenancy_Locale>;
  /** fetch data from the table: "tenancy.localization_settings" */
  tenancy_localization_settings: Array<Tenancy_Localization_Settings>;
  /** fetch aggregated fields from the table: "tenancy.localization_settings" */
  tenancy_localization_settings_aggregate: Tenancy_Localization_Settings_Aggregate;
  /** fetch data from the table: "tenancy.localization_settings" using primary key columns */
  tenancy_localization_settings_by_pk?: Maybe<Tenancy_Localization_Settings>;
  /** fetch data from the table: "tenancy.package" */
  tenancy_package: Array<Tenancy_Package>;
  /** fetch aggregated fields from the table: "tenancy.package" */
  tenancy_package_aggregate: Tenancy_Package_Aggregate;
  /** fetch data from the table: "tenancy.package" using primary key columns */
  tenancy_package_by_pk?: Maybe<Tenancy_Package>;
  /** fetch data from the table: "tenancy.package_version" */
  tenancy_package_version: Array<Tenancy_Package_Version>;
  /** fetch aggregated fields from the table: "tenancy.package_version" */
  tenancy_package_version_aggregate: Tenancy_Package_Version_Aggregate;
  /** fetch data from the table: "tenancy.package_version" using primary key columns */
  tenancy_package_version_by_pk?: Maybe<Tenancy_Package_Version>;
  /** fetch data from the table: "tenancy.products" */
  tenancy_products: Array<Tenancy_Products>;
  /** fetch aggregated fields from the table: "tenancy.products" */
  tenancy_products_aggregate: Tenancy_Products_Aggregate;
  /** fetch data from the table: "tenancy.products" using primary key columns */
  tenancy_products_by_pk?: Maybe<Tenancy_Products>;
  /** fetch data from the table: "tenancy.project_product_settings" */
  tenancy_project_product_settings: Array<Tenancy_Project_Product_Settings>;
  /** fetch aggregated fields from the table: "tenancy.project_product_settings" */
  tenancy_project_product_settings_aggregate: Tenancy_Project_Product_Settings_Aggregate;
  /** fetch data from the table: "tenancy.project_product_settings" using primary key columns */
  tenancy_project_product_settings_by_pk?: Maybe<Tenancy_Project_Product_Settings>;
  /** fetch data from the table: "tenancy.project_release_channel_config" */
  tenancy_project_release_channel_config: Array<Tenancy_Project_Release_Channel_Config>;
  /** fetch aggregated fields from the table: "tenancy.project_release_channel_config" */
  tenancy_project_release_channel_config_aggregate: Tenancy_Project_Release_Channel_Config_Aggregate;
  /** fetch data from the table: "tenancy.project_release_channel_config" using primary key columns */
  tenancy_project_release_channel_config_by_pk?: Maybe<Tenancy_Project_Release_Channel_Config>;
  /** fetch data from the table: "tenancy.projects" */
  tenancy_projects: Array<Tenancy_Projects>;
  /** fetch aggregated fields from the table: "tenancy.projects" */
  tenancy_projects_aggregate: Tenancy_Projects_Aggregate;
  /** fetch data from the table: "tenancy.projects" using primary key columns */
  tenancy_projects_by_pk?: Maybe<Tenancy_Projects>;
  /** fetch data from the table: "tenancy.release_channel" */
  tenancy_release_channel: Array<Tenancy_Release_Channel>;
  /** fetch aggregated fields from the table: "tenancy.release_channel" */
  tenancy_release_channel_aggregate: Tenancy_Release_Channel_Aggregate;
  /** fetch data from the table: "tenancy.release_channel" using primary key columns */
  tenancy_release_channel_by_pk?: Maybe<Tenancy_Release_Channel>;
  /** fetch data from the table: "tenancy.roles" */
  tenancy_roles: Array<Tenancy_Roles>;
  /** fetch aggregated fields from the table: "tenancy.roles" */
  tenancy_roles_aggregate: Tenancy_Roles_Aggregate;
  /** fetch data from the table: "tenancy.roles" using primary key columns */
  tenancy_roles_by_pk?: Maybe<Tenancy_Roles>;
  /** fetch data from the table: "tenancy.scheduled_jobs" */
  tenancy_scheduled_jobs: Array<Tenancy_Scheduled_Jobs>;
  /** fetch aggregated fields from the table: "tenancy.scheduled_jobs" */
  tenancy_scheduled_jobs_aggregate: Tenancy_Scheduled_Jobs_Aggregate;
  /** fetch data from the table: "tenancy.scheduled_jobs" using primary key columns */
  tenancy_scheduled_jobs_by_pk?: Maybe<Tenancy_Scheduled_Jobs>;
  /** fetch data from the table: "tenancy.team_account_projects" */
  tenancy_team_account_projects: Array<Tenancy_Team_Account_Projects>;
  /** fetch aggregated fields from the table: "tenancy.team_account_projects" */
  tenancy_team_account_projects_aggregate: Tenancy_Team_Account_Projects_Aggregate;
  /** fetch data from the table: "tenancy.team_account_projects" using primary key columns */
  tenancy_team_account_projects_by_pk?: Maybe<Tenancy_Team_Account_Projects>;
  /** fetch data from the table: "tenancy.team_account_release_channel_config" */
  tenancy_team_account_release_channel_config: Array<Tenancy_Team_Account_Release_Channel_Config>;
  /** fetch aggregated fields from the table: "tenancy.team_account_release_channel_config" */
  tenancy_team_account_release_channel_config_aggregate: Tenancy_Team_Account_Release_Channel_Config_Aggregate;
  /** fetch data from the table: "tenancy.team_account_release_channel_config" using primary key columns */
  tenancy_team_account_release_channel_config_by_pk?: Maybe<Tenancy_Team_Account_Release_Channel_Config>;
  /** fetch data from the table: "tenancy.team_accounts" */
  tenancy_team_accounts: Array<Tenancy_Team_Accounts>;
  /** fetch aggregated fields from the table: "tenancy.team_accounts" */
  tenancy_team_accounts_aggregate: Tenancy_Team_Accounts_Aggregate;
  /** fetch data from the table: "tenancy.team_accounts" using primary key columns */
  tenancy_team_accounts_by_pk?: Maybe<Tenancy_Team_Accounts>;
  /** fetch data from the table: "tenancy.teams" */
  tenancy_teams: Array<Tenancy_Teams>;
  /** fetch aggregated fields from the table: "tenancy.teams" */
  tenancy_teams_aggregate: Tenancy_Teams_Aggregate;
  /** fetch data from the table: "tenancy.teams" using primary key columns */
  tenancy_teams_by_pk?: Maybe<Tenancy_Teams>;
  /** fetch data from the table: "tenancy.time_zone" */
  tenancy_time_zone: Array<Tenancy_Time_Zone>;
  /** fetch aggregated fields from the table: "tenancy.time_zone" */
  tenancy_time_zone_aggregate: Tenancy_Time_Zone_Aggregate;
  /** fetch data from the table: "tenancy.time_zone" using primary key columns */
  tenancy_time_zone_by_pk?: Maybe<Tenancy_Time_Zone>;
  /** fetch data from the table: "tenancy.trigger" */
  tenancy_trigger: Array<Tenancy_Trigger>;
  /** fetch aggregated fields from the table: "tenancy.trigger" */
  tenancy_trigger_aggregate: Tenancy_Trigger_Aggregate;
  /** fetch data from the table: "tenancy.trigger" using primary key columns */
  tenancy_trigger_by_pk?: Maybe<Tenancy_Trigger>;
  /** fetch data from the table: "tenancy.user_account_projects" */
  tenancy_user_account_projects: Array<Tenancy_User_Account_Projects>;
  /** fetch aggregated fields from the table: "tenancy.user_account_projects" */
  tenancy_user_account_projects_aggregate: Tenancy_User_Account_Projects_Aggregate;
  /** fetch data from the table: "tenancy.user_account_projects" using primary key columns */
  tenancy_user_account_projects_by_pk?: Maybe<Tenancy_User_Account_Projects>;
  /** fetch data from the table: "tenancy.user_account_release_channel_config" */
  tenancy_user_account_release_channel_config: Array<Tenancy_User_Account_Release_Channel_Config>;
  /** fetch aggregated fields from the table: "tenancy.user_account_release_channel_config" */
  tenancy_user_account_release_channel_config_aggregate: Tenancy_User_Account_Release_Channel_Config_Aggregate;
  /** fetch data from the table: "tenancy.user_account_release_channel_config" using primary key columns */
  tenancy_user_account_release_channel_config_by_pk?: Maybe<Tenancy_User_Account_Release_Channel_Config>;
  /** fetch data from the table: "tenancy.user_accounts" */
  tenancy_user_accounts: Array<Tenancy_User_Accounts>;
  /** fetch aggregated fields from the table: "tenancy.user_accounts" */
  tenancy_user_accounts_aggregate: Tenancy_User_Accounts_Aggregate;
  /** fetch data from the table: "tenancy.user_accounts" using primary key columns */
  tenancy_user_accounts_by_pk?: Maybe<Tenancy_User_Accounts>;
  /** fetch data from the table: "tenancy.users" */
  tenancy_users: Array<Tenancy_Users>;
  /** fetch aggregated fields from the table: "tenancy.users" */
  tenancy_users_aggregate: Tenancy_Users_Aggregate;
  /** fetch data from the table: "tenancy.users" using primary key columns */
  tenancy_users_by_pk?: Maybe<Tenancy_Users>;
  /** fetch data from the table: "tenancy.variable" */
  tenancy_variable: Array<Tenancy_Variable>;
  /** fetch aggregated fields from the table: "tenancy.variable" */
  tenancy_variable_aggregate: Tenancy_Variable_Aggregate;
  /** fetch data from the table: "tenancy.variable" using primary key columns */
  tenancy_variable_by_pk?: Maybe<Tenancy_Variable>;
};


export type Query_RootApp_Loader_Data_StoreArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Data_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Data_Store_Order_By>>;
  where?: InputMaybe<App_Loader_Data_Store_Bool_Exp>;
};


export type Query_RootApp_Loader_Data_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Data_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Data_Store_Order_By>>;
  where?: InputMaybe<App_Loader_Data_Store_Bool_Exp>;
};


export type Query_RootApp_Loader_Data_Store_By_PkArgs = {
  session_id: Scalars['String']['input'];
};


export type Query_RootApps_AppArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Order_By>>;
  where?: InputMaybe<Apps_App_Bool_Exp>;
};


export type Query_RootApps_App_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Order_By>>;
  where?: InputMaybe<Apps_App_Bool_Exp>;
};


export type Query_RootApps_App_By_PkArgs = {
  app_id: Scalars['Int']['input'];
};


export type Query_RootApps_App_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Config_Order_By>>;
  where?: InputMaybe<Apps_App_Config_Bool_Exp>;
};


export type Query_RootApps_App_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Config_Order_By>>;
  where?: InputMaybe<Apps_App_Config_Bool_Exp>;
};


export type Query_RootApps_App_Config_By_PkArgs = {
  app_config_id: Scalars['Int']['input'];
};


export type Query_RootApps_App_FileArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_File_Order_By>>;
  where?: InputMaybe<Apps_App_File_Bool_Exp>;
};


export type Query_RootApps_App_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_File_Order_By>>;
  where?: InputMaybe<Apps_App_File_Bool_Exp>;
};


export type Query_RootApps_App_File_By_PkArgs = {
  app_id: Scalars['bigint']['input'];
  filename: Scalars['String']['input'];
  parent_path: Scalars['String']['input'];
};


export type Query_RootApps_Convert_RoundtripArgs = {
  distinct_on?: InputMaybe<Array<Apps_Convert_Roundtrip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Convert_Roundtrip_Order_By>>;
  where?: InputMaybe<Apps_Convert_Roundtrip_Bool_Exp>;
};


export type Query_RootApps_Convert_Roundtrip_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Convert_Roundtrip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Convert_Roundtrip_Order_By>>;
  where?: InputMaybe<Apps_Convert_Roundtrip_Bool_Exp>;
};


export type Query_RootApps_Convert_Roundtrip_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


export type Query_RootApps_Csv_FileArgs = {
  distinct_on?: InputMaybe<Array<Apps_Csv_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Csv_File_Order_By>>;
  where?: InputMaybe<Apps_Csv_File_Bool_Exp>;
};


export type Query_RootApps_Csv_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Csv_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Csv_File_Order_By>>;
  where?: InputMaybe<Apps_Csv_File_Bool_Exp>;
};


export type Query_RootApps_Csv_File_By_PkArgs = {
  csv_file_id: Scalars['Int']['input'];
};


export type Query_RootApps_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Apps_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Customer_Order_By>>;
  where?: InputMaybe<Apps_Customer_Bool_Exp>;
};


export type Query_RootApps_Customer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Customer_Order_By>>;
  where?: InputMaybe<Apps_Customer_Bool_Exp>;
};


export type Query_RootApps_Customer_By_PkArgs = {
  customer_id: Scalars['Int']['input'];
};


export type Query_RootApps_Customer_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Apps_Customer_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Customer_Config_Order_By>>;
  where?: InputMaybe<Apps_Customer_Config_Bool_Exp>;
};


export type Query_RootApps_Customer_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Customer_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Customer_Config_Order_By>>;
  where?: InputMaybe<Apps_Customer_Config_Bool_Exp>;
};


export type Query_RootApps_Customer_Config_By_PkArgs = {
  customer_config_id: Scalars['Int']['input'];
};


export type Query_RootApps_DeploymentArgs = {
  distinct_on?: InputMaybe<Array<Apps_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Deployment_Order_By>>;
  where?: InputMaybe<Apps_Deployment_Bool_Exp>;
};


export type Query_RootApps_Deployment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Deployment_Order_By>>;
  where?: InputMaybe<Apps_Deployment_Bool_Exp>;
};


export type Query_RootApps_Deployment_By_PkArgs = {
  deployment_id: Scalars['Int']['input'];
};


export type Query_RootApps_EnvironmentArgs = {
  distinct_on?: InputMaybe<Array<Apps_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Environment_Order_By>>;
  where?: InputMaybe<Apps_Environment_Bool_Exp>;
};


export type Query_RootApps_Environment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Environment_Order_By>>;
  where?: InputMaybe<Apps_Environment_Bool_Exp>;
};


export type Query_RootApps_Environment_By_PkArgs = {
  environment_id: Scalars['Int']['input'];
};


export type Query_RootApps_FileArgs = {
  distinct_on?: InputMaybe<Array<Apps_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_File_Order_By>>;
  where?: InputMaybe<Apps_File_Bool_Exp>;
};


export type Query_RootApps_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_File_Order_By>>;
  where?: InputMaybe<Apps_File_Bool_Exp>;
};


export type Query_RootApps_File_By_PkArgs = {
  file_id: Scalars['bigint']['input'];
};


export type Query_RootApps_Import_FailArgs = {
  distinct_on?: InputMaybe<Array<Apps_Import_Fail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Import_Fail_Order_By>>;
  where?: InputMaybe<Apps_Import_Fail_Bool_Exp>;
};


export type Query_RootApps_Import_Fail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Import_Fail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Import_Fail_Order_By>>;
  where?: InputMaybe<Apps_Import_Fail_Bool_Exp>;
};


export type Query_RootApps_Import_Fail_By_PkArgs = {
  import_fail_id: Scalars['Int']['input'];
};


export type Query_RootApps_Import_Fail_NodeArgs = {
  distinct_on?: InputMaybe<Array<Apps_Import_Fail_Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Import_Fail_Node_Order_By>>;
  where?: InputMaybe<Apps_Import_Fail_Node_Bool_Exp>;
};


export type Query_RootApps_Import_Fail_Node_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Import_Fail_Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Import_Fail_Node_Order_By>>;
  where?: InputMaybe<Apps_Import_Fail_Node_Bool_Exp>;
};


export type Query_RootApps_Import_Fail_Node_By_PkArgs = {
  import_fail_node_id: Scalars['bigint']['input'];
};


export type Query_RootApps_Nlu_FilesArgs = {
  distinct_on?: InputMaybe<Array<Apps_Nlu_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Nlu_Files_Order_By>>;
  where?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
};


export type Query_RootApps_Nlu_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Nlu_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Nlu_Files_Order_By>>;
  where?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
};


export type Query_RootApps_Nlu_Files_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


export type Query_RootApps_NodeArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Order_By>>;
  where?: InputMaybe<Apps_Node_Bool_Exp>;
};


export type Query_RootApps_Node_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Order_By>>;
  where?: InputMaybe<Apps_Node_Bool_Exp>;
};


export type Query_RootApps_Node_By_PkArgs = {
  node_id: Scalars['bigint']['input'];
};


export type Query_RootApps_Node_Import_FlatArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Import_Flat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Import_Flat_Order_By>>;
  where?: InputMaybe<Apps_Node_Import_Flat_Bool_Exp>;
};


export type Query_RootApps_Node_Import_Flat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Import_Flat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Import_Flat_Order_By>>;
  where?: InputMaybe<Apps_Node_Import_Flat_Bool_Exp>;
};


export type Query_RootApps_Node_Import_Flat_By_PkArgs = {
  node_import_flat_id: Scalars['Int']['input'];
};


export type Query_RootApps_Node_Import_JsonArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Import_Json_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Import_Json_Order_By>>;
  where?: InputMaybe<Apps_Node_Import_Json_Bool_Exp>;
};


export type Query_RootApps_Node_Import_Json_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Import_Json_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Import_Json_Order_By>>;
  where?: InputMaybe<Apps_Node_Import_Json_Bool_Exp>;
};


export type Query_RootApps_Node_Import_Json_By_PkArgs = {
  node_import_json_id: Scalars['Int']['input'];
};


export type Query_RootApps_Node_InstanceArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
};


export type Query_RootApps_Node_Instance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
};


export type Query_RootApps_Node_Instance_By_PkArgs = {
  node_instance_id: Scalars['bigint']['input'];
};


export type Query_RootApps_Node_Outgoing_Edge_ViewArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Outgoing_Edge_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Outgoing_Edge_View_Order_By>>;
  where?: InputMaybe<Apps_Node_Outgoing_Edge_View_Bool_Exp>;
};


export type Query_RootApps_Node_Outgoing_Edge_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Outgoing_Edge_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Outgoing_Edge_View_Order_By>>;
  where?: InputMaybe<Apps_Node_Outgoing_Edge_View_Bool_Exp>;
};


export type Query_RootApps_Rich_AssetArgs = {
  distinct_on?: InputMaybe<Array<Apps_Rich_Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Rich_Asset_Order_By>>;
  where?: InputMaybe<Apps_Rich_Asset_Bool_Exp>;
};


export type Query_RootApps_Rich_Asset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Rich_Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Rich_Asset_Order_By>>;
  where?: InputMaybe<Apps_Rich_Asset_Bool_Exp>;
};


export type Query_RootApps_Rich_Asset_By_PkArgs = {
  rich_asset_id: Scalars['bigint']['input'];
};


export type Query_RootApps_VersionArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};


export type Query_RootApps_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};


export type Query_RootApps_Version_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


export type Query_RootApps_Version_Display_DataArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Display_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Display_Data_Order_By>>;
  where?: InputMaybe<Apps_Version_Display_Data_Bool_Exp>;
};


export type Query_RootApps_Version_Display_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Display_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Display_Data_Order_By>>;
  where?: InputMaybe<Apps_Version_Display_Data_Bool_Exp>;
};


export type Query_RootApps_Version_Display_Data_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


export type Query_RootApps_Version_FileArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_File_Order_By>>;
  where?: InputMaybe<Apps_Version_File_Bool_Exp>;
};


export type Query_RootApps_Version_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_File_Order_By>>;
  where?: InputMaybe<Apps_Version_File_Bool_Exp>;
};


export type Query_RootApps_Version_File_By_PkArgs = {
  filename: Scalars['String']['input'];
  parent_path: Scalars['String']['input'];
  version_id: Scalars['bigint']['input'];
};


export type Query_RootApps_Version_Node_InstanceArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
};


export type Query_RootApps_Version_Node_Instance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
};


export type Query_RootApps_Version_Node_Instance_By_PkArgs = {
  node_number: Scalars['bigint']['input'];
  version_id: Scalars['bigint']['input'];
};


export type Query_RootGetBotAssetByIdArgs = {
  assetId: Scalars['String']['input'];
  botId: Scalars['String']['input'];
};


export type Query_RootGetBotAssetsArgs = {
  botId: Scalars['String']['input'];
};


export type Query_RootGetBotByIdArgs = {
  botId: Scalars['String']['input'];
};


export type Query_RootGetBotVersionsArgs = {
  botId: Scalars['String']['input'];
};


export type Query_RootGetCustomerBotsArgs = {
  customerId: Scalars['String']['input'];
};


export type Query_RootGetCustomerByIdArgs = {
  customerId: Scalars['String']['input'];
};


export type Query_RootGetCustomerPackagesArgs = {
  customerId: Scalars['String']['input'];
};


export type Query_RootGetCustomerResourcesArgs = {
  customerId: Scalars['String']['input'];
};


export type Query_RootGetEntitiesArgs = {
  botId: Scalars['String']['input'];
};


export type Query_RootGetEnvBotVersionsArgs = {
  botId: Scalars['String']['input'];
};


export type Query_RootGetGraphMdArgs = {
  queryURL: Scalars['String']['input'];
};


export type Query_RootGetIntentsArgs = {
  botId: Scalars['String']['input'];
};


export type Query_RootGetPdfImageArgs = {
  queryURL: Scalars['String']['input'];
};


export type Query_RootGetSupervisedLearningArgs = {
  botId: Scalars['String']['input'];
};


export type Query_RootGetVersionByIdArgs = {
  versionId: Scalars['String']['input'];
};


export type Query_RootGetVersionFileArgs = {
  filePath: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};


export type Query_RootGetVersionGraphArgs = {
  accept?: InputMaybe<Accept>;
  compiled?: InputMaybe<Scalars['String']['input']>;
  versionId: Scalars['String']['input'];
};


export type Query_RootGetVersionScriptArgs = {
  filePath: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};


export type Query_RootGetVersionSearchNluTestArgs = {
  searchNLUEndpointName: Scalars['String']['input'];
  utterance: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};


export type Query_RootGetVersionStopwordsArgs = {
  versionId: Scalars['String']['input'];
};


export type Query_RootGetVersionValidateArgs = {
  versionId: Scalars['String']['input'];
};


export type Query_RootTenancy__ProjectReleaseChannelConfigToVariableArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Order_By>>;
  where?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
};


export type Query_RootTenancy__ProjectReleaseChannelConfigToVariable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Order_By>>;
  where?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
};


export type Query_RootTenancy__Prisma_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Prisma_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Prisma_Migrations_Order_By>>;
  where?: InputMaybe<Tenancy__Prisma_Migrations_Bool_Exp>;
};


export type Query_RootTenancy__Prisma_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Prisma_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Prisma_Migrations_Order_By>>;
  where?: InputMaybe<Tenancy__Prisma_Migrations_Bool_Exp>;
};


export type Query_RootTenancy__Prisma_Migrations_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy__Role_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Role_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Role_Projects_Order_By>>;
  where?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
};


export type Query_RootTenancy__Role_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Role_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Role_Projects_Order_By>>;
  where?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
};


export type Query_RootTenancy__Team_Account_Project_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
};


export type Query_RootTenancy__Team_Account_Project_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
};


export type Query_RootTenancy__Team_Account_Release_Channel_Config_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


export type Query_RootTenancy__Team_Account_Release_Channel_Config_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


export type Query_RootTenancy__Team_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
};


export type Query_RootTenancy__Team_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
};


export type Query_RootTenancy__User_Account_Project_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
};


export type Query_RootTenancy__User_Account_Project_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
};


export type Query_RootTenancy__User_Account_Release_Channel_Config_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


export type Query_RootTenancy__User_Account_Release_Channel_Config_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


export type Query_RootTenancy__User_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
};


export type Query_RootTenancy__User_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
};


export type Query_RootTenancy__User_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Teams_Order_By>>;
  where?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
};


export type Query_RootTenancy__User_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Teams_Order_By>>;
  where?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
};


export type Query_RootTenancy_Account_Scheduled_Job_RunsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
};


export type Query_RootTenancy_Account_Scheduled_Job_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
};


export type Query_RootTenancy_Account_Scheduled_Job_Runs_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Account_Scheduled_JobsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
};


export type Query_RootTenancy_Account_Scheduled_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
};


export type Query_RootTenancy_Account_Scheduled_Jobs_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
};


export type Query_RootTenancy_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
};


export type Query_RootTenancy_Accounts_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_ActionArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Action_Order_By>>;
  where?: InputMaybe<Tenancy_Action_Bool_Exp>;
};


export type Query_RootTenancy_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Action_Order_By>>;
  where?: InputMaybe<Tenancy_Action_Bool_Exp>;
};


export type Query_RootTenancy_Action_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_App_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_App_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_App_Config_Order_By>>;
  where?: InputMaybe<Tenancy_App_Config_Bool_Exp>;
};


export type Query_RootTenancy_App_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_App_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_App_Config_Order_By>>;
  where?: InputMaybe<Tenancy_App_Config_Bool_Exp>;
};


export type Query_RootTenancy_App_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_AppletArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Bool_Exp>;
};


export type Query_RootTenancy_Applet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Bool_Exp>;
};


export type Query_RootTenancy_Applet_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Applet_InstanceArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Instance_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
};


export type Query_RootTenancy_Applet_Instance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Instance_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
};


export type Query_RootTenancy_Applet_Instance_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Applet_VersionArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
};


export type Query_RootTenancy_Applet_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
};


export type Query_RootTenancy_Applet_Version_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Audit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Audit_Logs_Order_By>>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};


export type Query_RootTenancy_Audit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Audit_Logs_Order_By>>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};


export type Query_RootTenancy_Audit_Logs_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_ConditionArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Condition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Condition_Order_By>>;
  where?: InputMaybe<Tenancy_Condition_Bool_Exp>;
};


export type Query_RootTenancy_Condition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Condition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Condition_Order_By>>;
  where?: InputMaybe<Tenancy_Condition_Bool_Exp>;
};


export type Query_RootTenancy_Condition_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_CountryArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Country_Order_By>>;
  where?: InputMaybe<Tenancy_Country_Bool_Exp>;
};


export type Query_RootTenancy_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Country_Order_By>>;
  where?: InputMaybe<Tenancy_Country_Bool_Exp>;
};


export type Query_RootTenancy_Country_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_DomainArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Domain_Order_By>>;
  where?: InputMaybe<Tenancy_Domain_Bool_Exp>;
};


export type Query_RootTenancy_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Domain_Order_By>>;
  where?: InputMaybe<Tenancy_Domain_Bool_Exp>;
};


export type Query_RootTenancy_Domain_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Failed_Kafka_EventsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Failed_Kafka_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Failed_Kafka_Events_Order_By>>;
  where?: InputMaybe<Tenancy_Failed_Kafka_Events_Bool_Exp>;
};


export type Query_RootTenancy_Failed_Kafka_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Failed_Kafka_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Failed_Kafka_Events_Order_By>>;
  where?: InputMaybe<Tenancy_Failed_Kafka_Events_Bool_Exp>;
};


export type Query_RootTenancy_Failed_Kafka_Events_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_FileArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


export type Query_RootTenancy_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


export type Query_RootTenancy_File_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_LocaleArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Locale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Locale_Order_By>>;
  where?: InputMaybe<Tenancy_Locale_Bool_Exp>;
};


export type Query_RootTenancy_Locale_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Locale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Locale_Order_By>>;
  where?: InputMaybe<Tenancy_Locale_Bool_Exp>;
};


export type Query_RootTenancy_Locale_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Localization_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


export type Query_RootTenancy_Localization_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


export type Query_RootTenancy_Localization_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_PackageArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Bool_Exp>;
};


export type Query_RootTenancy_Package_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Bool_Exp>;
};


export type Query_RootTenancy_Package_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Package_VersionArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
};


export type Query_RootTenancy_Package_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
};


export type Query_RootTenancy_Package_Version_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Products_Order_By>>;
  where?: InputMaybe<Tenancy_Products_Bool_Exp>;
};


export type Query_RootTenancy_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Products_Order_By>>;
  where?: InputMaybe<Tenancy_Products_Bool_Exp>;
};


export type Query_RootTenancy_Products_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Project_Product_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Product_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
};


export type Query_RootTenancy_Project_Product_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Product_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
};


export type Query_RootTenancy_Project_Product_Settings_By_PkArgs = {
  productId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};


export type Query_RootTenancy_Project_Release_Channel_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
};


export type Query_RootTenancy_Project_Release_Channel_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
};


export type Query_RootTenancy_Project_Release_Channel_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Projects_Bool_Exp>;
};


export type Query_RootTenancy_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Projects_Bool_Exp>;
};


export type Query_RootTenancy_Projects_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Release_ChannelArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Release_Channel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Release_Channel_Order_By>>;
  where?: InputMaybe<Tenancy_Release_Channel_Bool_Exp>;
};


export type Query_RootTenancy_Release_Channel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Release_Channel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Release_Channel_Order_By>>;
  where?: InputMaybe<Tenancy_Release_Channel_Bool_Exp>;
};


export type Query_RootTenancy_Release_Channel_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Roles_Order_By>>;
  where?: InputMaybe<Tenancy_Roles_Bool_Exp>;
};


export type Query_RootTenancy_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Roles_Order_By>>;
  where?: InputMaybe<Tenancy_Roles_Bool_Exp>;
};


export type Query_RootTenancy_Roles_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Scheduled_JobsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Scheduled_Jobs_Bool_Exp>;
};


export type Query_RootTenancy_Scheduled_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Scheduled_Jobs_Bool_Exp>;
};


export type Query_RootTenancy_Scheduled_Jobs_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Team_Account_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
};


export type Query_RootTenancy_Team_Account_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
};


export type Query_RootTenancy_Team_Account_Projects_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Team_Account_Release_Channel_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
};


export type Query_RootTenancy_Team_Account_Release_Channel_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
};


export type Query_RootTenancy_Team_Account_Release_Channel_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Team_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};


export type Query_RootTenancy_Team_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};


export type Query_RootTenancy_Team_Accounts_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Teams_Order_By>>;
  where?: InputMaybe<Tenancy_Teams_Bool_Exp>;
};


export type Query_RootTenancy_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Teams_Order_By>>;
  where?: InputMaybe<Tenancy_Teams_Bool_Exp>;
};


export type Query_RootTenancy_Teams_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_Time_ZoneArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Time_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Time_Zone_Order_By>>;
  where?: InputMaybe<Tenancy_Time_Zone_Bool_Exp>;
};


export type Query_RootTenancy_Time_Zone_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Time_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Time_Zone_Order_By>>;
  where?: InputMaybe<Tenancy_Time_Zone_Bool_Exp>;
};


export type Query_RootTenancy_Time_Zone_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_TriggerArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Trigger_Order_By>>;
  where?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
};


export type Query_RootTenancy_Trigger_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Trigger_Order_By>>;
  where?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
};


export type Query_RootTenancy_Trigger_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_User_Account_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
};


export type Query_RootTenancy_User_Account_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
};


export type Query_RootTenancy_User_Account_Projects_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_User_Account_Release_Channel_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
};


export type Query_RootTenancy_User_Account_Release_Channel_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
};


export type Query_RootTenancy_User_Account_Release_Channel_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_User_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};


export type Query_RootTenancy_User_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};


export type Query_RootTenancy_User_Accounts_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_UsersArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Users_Order_By>>;
  where?: InputMaybe<Tenancy_Users_Bool_Exp>;
};


export type Query_RootTenancy_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Users_Order_By>>;
  where?: InputMaybe<Tenancy_Users_Bool_Exp>;
};


export type Query_RootTenancy_Users_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTenancy_VariableArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Variable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Variable_Order_By>>;
  where?: InputMaybe<Tenancy_Variable_Bool_Exp>;
};


export type Query_RootTenancy_Variable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Variable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Variable_Order_By>>;
  where?: InputMaybe<Tenancy_Variable_Bool_Exp>;
};


export type Query_RootTenancy_Variable_By_PkArgs = {
  id: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "rich_asset_type_enum". All fields are combined with logical 'AND'. */
export type Rich_Asset_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  _gt?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  _gte?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  _in?: InputMaybe<Array<Scalars['rich_asset_type_enum']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  _lte?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  _neq?: InputMaybe<Scalars['rich_asset_type_enum']['input']>;
  _nin?: InputMaybe<Array<Scalars['rich_asset_type_enum']['input']>>;
};

/** columns and relationships of "sso_domains" */
export type Sso_Domains = {
  __typename?: 'sso_domains';
  /** An object relationship */
  auth_provider: Auth_Providers;
  domain: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  provider: Auth_Providers_Enum;
};

/** aggregated selection of "sso_domains" */
export type Sso_Domains_Aggregate = {
  __typename?: 'sso_domains_aggregate';
  aggregate?: Maybe<Sso_Domains_Aggregate_Fields>;
  nodes: Array<Sso_Domains>;
};

/** aggregate fields of "sso_domains" */
export type Sso_Domains_Aggregate_Fields = {
  __typename?: 'sso_domains_aggregate_fields';
  avg?: Maybe<Sso_Domains_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Sso_Domains_Max_Fields>;
  min?: Maybe<Sso_Domains_Min_Fields>;
  stddev?: Maybe<Sso_Domains_Stddev_Fields>;
  stddev_pop?: Maybe<Sso_Domains_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sso_Domains_Stddev_Samp_Fields>;
  sum?: Maybe<Sso_Domains_Sum_Fields>;
  var_pop?: Maybe<Sso_Domains_Var_Pop_Fields>;
  var_samp?: Maybe<Sso_Domains_Var_Samp_Fields>;
  variance?: Maybe<Sso_Domains_Variance_Fields>;
};


/** aggregate fields of "sso_domains" */
export type Sso_Domains_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sso_Domains_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Sso_Domains_Avg_Fields = {
  __typename?: 'sso_domains_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "sso_domains". All fields are combined with a logical 'AND'. */
export type Sso_Domains_Bool_Exp = {
  _and?: InputMaybe<Array<Sso_Domains_Bool_Exp>>;
  _not?: InputMaybe<Sso_Domains_Bool_Exp>;
  _or?: InputMaybe<Array<Sso_Domains_Bool_Exp>>;
  auth_provider?: InputMaybe<Auth_Providers_Bool_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Auth_Providers_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "sso_domains" */
export enum Sso_Domains_Constraint {
  /** unique or primary key constraint on columns "id" */
  SsoDomainsPkey = 'sso_domains_pkey'
}

/** input type for incrementing numeric columns in table "sso_domains" */
export type Sso_Domains_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "sso_domains" */
export type Sso_Domains_Insert_Input = {
  auth_provider?: InputMaybe<Auth_Providers_Obj_Rel_Insert_Input>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<Auth_Providers_Enum>;
};

/** aggregate max on columns */
export type Sso_Domains_Max_Fields = {
  __typename?: 'sso_domains_max_fields';
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Sso_Domains_Min_Fields = {
  __typename?: 'sso_domains_min_fields';
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "sso_domains" */
export type Sso_Domains_Mutation_Response = {
  __typename?: 'sso_domains_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Sso_Domains>;
};

/** on_conflict condition type for table "sso_domains" */
export type Sso_Domains_On_Conflict = {
  constraint: Sso_Domains_Constraint;
  update_columns?: Array<Sso_Domains_Update_Column>;
  where?: InputMaybe<Sso_Domains_Bool_Exp>;
};

/** Ordering options when selecting data from "sso_domains". */
export type Sso_Domains_Order_By = {
  auth_provider?: InputMaybe<Auth_Providers_Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sso_domains */
export type Sso_Domains_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "sso_domains" */
export enum Sso_Domains_Select_Column {
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Provider = 'provider'
}

/** input type for updating data in table "sso_domains" */
export type Sso_Domains_Set_Input = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<Auth_Providers_Enum>;
};

/** aggregate stddev on columns */
export type Sso_Domains_Stddev_Fields = {
  __typename?: 'sso_domains_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Sso_Domains_Stddev_Pop_Fields = {
  __typename?: 'sso_domains_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Sso_Domains_Stddev_Samp_Fields = {
  __typename?: 'sso_domains_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "sso_domains" */
export type Sso_Domains_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sso_Domains_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sso_Domains_Stream_Cursor_Value_Input = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<Auth_Providers_Enum>;
};

/** aggregate sum on columns */
export type Sso_Domains_Sum_Fields = {
  __typename?: 'sso_domains_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "sso_domains" */
export enum Sso_Domains_Update_Column {
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Provider = 'provider'
}

export type Sso_Domains_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sso_Domains_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sso_Domains_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sso_Domains_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sso_Domains_Var_Pop_Fields = {
  __typename?: 'sso_domains_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Sso_Domains_Var_Samp_Fields = {
  __typename?: 'sso_domains_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Sso_Domains_Variance_Fields = {
  __typename?: 'sso_domains_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  admin_?: Maybe<Admin_Subscription>;
  app_loader_?: Maybe<App_Loader_Subscription_Root>;
  /** fetch data from the table: "app_loader.data_store" */
  app_loader_data_store: Array<App_Loader_Data_Store>;
  /** fetch aggregated fields from the table: "app_loader.data_store" */
  app_loader_data_store_aggregate: App_Loader_Data_Store_Aggregate;
  /** fetch data from the table: "app_loader.data_store" using primary key columns */
  app_loader_data_store_by_pk?: Maybe<App_Loader_Data_Store>;
  /** fetch data from the table in a streaming manner: "app_loader.data_store" */
  app_loader_data_store_stream: Array<App_Loader_Data_Store>;
  /** fetch data from the table: "apps.app" */
  apps_app: Array<Apps_App>;
  /** fetch aggregated fields from the table: "apps.app" */
  apps_app_aggregate: Apps_App_Aggregate;
  /** fetch data from the table: "apps.app" using primary key columns */
  apps_app_by_pk?: Maybe<Apps_App>;
  /** fetch data from the table: "apps.app_config" */
  apps_app_config: Array<Apps_App_Config>;
  /** fetch aggregated fields from the table: "apps.app_config" */
  apps_app_config_aggregate: Apps_App_Config_Aggregate;
  /** fetch data from the table: "apps.app_config" using primary key columns */
  apps_app_config_by_pk?: Maybe<Apps_App_Config>;
  /** fetch data from the table in a streaming manner: "apps.app_config" */
  apps_app_config_stream: Array<Apps_App_Config>;
  /** fetch data from the table: "apps.app_file" */
  apps_app_file: Array<Apps_App_File>;
  /** fetch aggregated fields from the table: "apps.app_file" */
  apps_app_file_aggregate: Apps_App_File_Aggregate;
  /** fetch data from the table: "apps.app_file" using primary key columns */
  apps_app_file_by_pk?: Maybe<Apps_App_File>;
  /** fetch data from the table in a streaming manner: "apps.app_file" */
  apps_app_file_stream: Array<Apps_App_File>;
  /** fetch data from the table in a streaming manner: "apps.app" */
  apps_app_stream: Array<Apps_App>;
  /** fetch data from the table: "apps.convert_roundtrip" */
  apps_convert_roundtrip: Array<Apps_Convert_Roundtrip>;
  /** fetch aggregated fields from the table: "apps.convert_roundtrip" */
  apps_convert_roundtrip_aggregate: Apps_Convert_Roundtrip_Aggregate;
  /** fetch data from the table: "apps.convert_roundtrip" using primary key columns */
  apps_convert_roundtrip_by_pk?: Maybe<Apps_Convert_Roundtrip>;
  /** fetch data from the table in a streaming manner: "apps.convert_roundtrip" */
  apps_convert_roundtrip_stream: Array<Apps_Convert_Roundtrip>;
  /** fetch data from the table: "apps.csv_file" */
  apps_csv_file: Array<Apps_Csv_File>;
  /** fetch aggregated fields from the table: "apps.csv_file" */
  apps_csv_file_aggregate: Apps_Csv_File_Aggregate;
  /** fetch data from the table: "apps.csv_file" using primary key columns */
  apps_csv_file_by_pk?: Maybe<Apps_Csv_File>;
  /** fetch data from the table in a streaming manner: "apps.csv_file" */
  apps_csv_file_stream: Array<Apps_Csv_File>;
  /** fetch data from the table: "apps.customer" */
  apps_customer: Array<Apps_Customer>;
  /** fetch aggregated fields from the table: "apps.customer" */
  apps_customer_aggregate: Apps_Customer_Aggregate;
  /** fetch data from the table: "apps.customer" using primary key columns */
  apps_customer_by_pk?: Maybe<Apps_Customer>;
  /** fetch data from the table: "apps.customer_config" */
  apps_customer_config: Array<Apps_Customer_Config>;
  /** fetch aggregated fields from the table: "apps.customer_config" */
  apps_customer_config_aggregate: Apps_Customer_Config_Aggregate;
  /** fetch data from the table: "apps.customer_config" using primary key columns */
  apps_customer_config_by_pk?: Maybe<Apps_Customer_Config>;
  /** fetch data from the table in a streaming manner: "apps.customer_config" */
  apps_customer_config_stream: Array<Apps_Customer_Config>;
  /** fetch data from the table in a streaming manner: "apps.customer" */
  apps_customer_stream: Array<Apps_Customer>;
  /** fetch data from the table: "apps.deployment" */
  apps_deployment: Array<Apps_Deployment>;
  /** fetch aggregated fields from the table: "apps.deployment" */
  apps_deployment_aggregate: Apps_Deployment_Aggregate;
  /** fetch data from the table: "apps.deployment" using primary key columns */
  apps_deployment_by_pk?: Maybe<Apps_Deployment>;
  /** fetch data from the table in a streaming manner: "apps.deployment" */
  apps_deployment_stream: Array<Apps_Deployment>;
  /** fetch data from the table: "apps.environment" */
  apps_environment: Array<Apps_Environment>;
  /** fetch aggregated fields from the table: "apps.environment" */
  apps_environment_aggregate: Apps_Environment_Aggregate;
  /** fetch data from the table: "apps.environment" using primary key columns */
  apps_environment_by_pk?: Maybe<Apps_Environment>;
  /** fetch data from the table in a streaming manner: "apps.environment" */
  apps_environment_stream: Array<Apps_Environment>;
  /** fetch data from the table: "apps.file" */
  apps_file: Array<Apps_File>;
  /** fetch aggregated fields from the table: "apps.file" */
  apps_file_aggregate: Apps_File_Aggregate;
  /** fetch data from the table: "apps.file" using primary key columns */
  apps_file_by_pk?: Maybe<Apps_File>;
  /** fetch data from the table in a streaming manner: "apps.file" */
  apps_file_stream: Array<Apps_File>;
  /** fetch data from the table: "apps.import_fail" */
  apps_import_fail: Array<Apps_Import_Fail>;
  /** fetch aggregated fields from the table: "apps.import_fail" */
  apps_import_fail_aggregate: Apps_Import_Fail_Aggregate;
  /** fetch data from the table: "apps.import_fail" using primary key columns */
  apps_import_fail_by_pk?: Maybe<Apps_Import_Fail>;
  /** fetch data from the table: "apps.import_fail_node" */
  apps_import_fail_node: Array<Apps_Import_Fail_Node>;
  /** fetch aggregated fields from the table: "apps.import_fail_node" */
  apps_import_fail_node_aggregate: Apps_Import_Fail_Node_Aggregate;
  /** fetch data from the table: "apps.import_fail_node" using primary key columns */
  apps_import_fail_node_by_pk?: Maybe<Apps_Import_Fail_Node>;
  /** fetch data from the table in a streaming manner: "apps.import_fail_node" */
  apps_import_fail_node_stream: Array<Apps_Import_Fail_Node>;
  /** fetch data from the table in a streaming manner: "apps.import_fail" */
  apps_import_fail_stream: Array<Apps_Import_Fail>;
  /** fetch data from the table: "apps.nlu_files" */
  apps_nlu_files: Array<Apps_Nlu_Files>;
  /** fetch aggregated fields from the table: "apps.nlu_files" */
  apps_nlu_files_aggregate: Apps_Nlu_Files_Aggregate;
  /** fetch data from the table: "apps.nlu_files" using primary key columns */
  apps_nlu_files_by_pk?: Maybe<Apps_Nlu_Files>;
  /** fetch data from the table in a streaming manner: "apps.nlu_files" */
  apps_nlu_files_stream: Array<Apps_Nlu_Files>;
  /** fetch data from the table: "apps.node" */
  apps_node: Array<Apps_Node>;
  /** fetch aggregated fields from the table: "apps.node" */
  apps_node_aggregate: Apps_Node_Aggregate;
  /** fetch data from the table: "apps.node" using primary key columns */
  apps_node_by_pk?: Maybe<Apps_Node>;
  /** fetch data from the table: "apps.node_import_flat" */
  apps_node_import_flat: Array<Apps_Node_Import_Flat>;
  /** fetch aggregated fields from the table: "apps.node_import_flat" */
  apps_node_import_flat_aggregate: Apps_Node_Import_Flat_Aggregate;
  /** fetch data from the table: "apps.node_import_flat" using primary key columns */
  apps_node_import_flat_by_pk?: Maybe<Apps_Node_Import_Flat>;
  /** fetch data from the table in a streaming manner: "apps.node_import_flat" */
  apps_node_import_flat_stream: Array<Apps_Node_Import_Flat>;
  /** fetch data from the table: "apps.node_import_json" */
  apps_node_import_json: Array<Apps_Node_Import_Json>;
  /** fetch aggregated fields from the table: "apps.node_import_json" */
  apps_node_import_json_aggregate: Apps_Node_Import_Json_Aggregate;
  /** fetch data from the table: "apps.node_import_json" using primary key columns */
  apps_node_import_json_by_pk?: Maybe<Apps_Node_Import_Json>;
  /** fetch data from the table in a streaming manner: "apps.node_import_json" */
  apps_node_import_json_stream: Array<Apps_Node_Import_Json>;
  /** fetch data from the table: "apps.node_instance" */
  apps_node_instance: Array<Apps_Node_Instance>;
  /** fetch aggregated fields from the table: "apps.node_instance" */
  apps_node_instance_aggregate: Apps_Node_Instance_Aggregate;
  /** fetch data from the table: "apps.node_instance" using primary key columns */
  apps_node_instance_by_pk?: Maybe<Apps_Node_Instance>;
  /** fetch data from the table in a streaming manner: "apps.node_instance" */
  apps_node_instance_stream: Array<Apps_Node_Instance>;
  /** fetch data from the table: "apps.node_outgoing_edge_view" */
  apps_node_outgoing_edge_view: Array<Apps_Node_Outgoing_Edge_View>;
  /** fetch aggregated fields from the table: "apps.node_outgoing_edge_view" */
  apps_node_outgoing_edge_view_aggregate: Apps_Node_Outgoing_Edge_View_Aggregate;
  /** fetch data from the table in a streaming manner: "apps.node_outgoing_edge_view" */
  apps_node_outgoing_edge_view_stream: Array<Apps_Node_Outgoing_Edge_View>;
  /** fetch data from the table in a streaming manner: "apps.node" */
  apps_node_stream: Array<Apps_Node>;
  /** fetch data from the table: "apps.rich_asset" */
  apps_rich_asset: Array<Apps_Rich_Asset>;
  /** fetch aggregated fields from the table: "apps.rich_asset" */
  apps_rich_asset_aggregate: Apps_Rich_Asset_Aggregate;
  /** fetch data from the table: "apps.rich_asset" using primary key columns */
  apps_rich_asset_by_pk?: Maybe<Apps_Rich_Asset>;
  /** fetch data from the table in a streaming manner: "apps.rich_asset" */
  apps_rich_asset_stream: Array<Apps_Rich_Asset>;
  /** fetch data from the table: "apps.version" */
  apps_version: Array<Apps_Version>;
  /** fetch aggregated fields from the table: "apps.version" */
  apps_version_aggregate: Apps_Version_Aggregate;
  /** fetch data from the table: "apps.version" using primary key columns */
  apps_version_by_pk?: Maybe<Apps_Version>;
  /** fetch data from the table: "apps.version_display_data" */
  apps_version_display_data: Array<Apps_Version_Display_Data>;
  /** fetch aggregated fields from the table: "apps.version_display_data" */
  apps_version_display_data_aggregate: Apps_Version_Display_Data_Aggregate;
  /** fetch data from the table: "apps.version_display_data" using primary key columns */
  apps_version_display_data_by_pk?: Maybe<Apps_Version_Display_Data>;
  /** fetch data from the table in a streaming manner: "apps.version_display_data" */
  apps_version_display_data_stream: Array<Apps_Version_Display_Data>;
  /** fetch data from the table: "apps.version_file" */
  apps_version_file: Array<Apps_Version_File>;
  /** fetch aggregated fields from the table: "apps.version_file" */
  apps_version_file_aggregate: Apps_Version_File_Aggregate;
  /** fetch data from the table: "apps.version_file" using primary key columns */
  apps_version_file_by_pk?: Maybe<Apps_Version_File>;
  /** fetch data from the table in a streaming manner: "apps.version_file" */
  apps_version_file_stream: Array<Apps_Version_File>;
  /** fetch data from the table: "apps.version_node_instance" */
  apps_version_node_instance: Array<Apps_Version_Node_Instance>;
  /** fetch aggregated fields from the table: "apps.version_node_instance" */
  apps_version_node_instance_aggregate: Apps_Version_Node_Instance_Aggregate;
  /** fetch data from the table: "apps.version_node_instance" using primary key columns */
  apps_version_node_instance_by_pk?: Maybe<Apps_Version_Node_Instance>;
  /** fetch data from the table in a streaming manner: "apps.version_node_instance" */
  apps_version_node_instance_stream: Array<Apps_Version_Node_Instance>;
  /** fetch data from the table in a streaming manner: "apps.version" */
  apps_version_stream: Array<Apps_Version>;
  fe_proto_?: Maybe<Fe_Proto_Subscription_Root>;
  /** fetch data from the table: "tenancy._ProjectReleaseChannelConfigToVariable" */
  tenancy__ProjectReleaseChannelConfigToVariable: Array<Tenancy__ProjectReleaseChannelConfigToVariable>;
  /** fetch aggregated fields from the table: "tenancy._ProjectReleaseChannelConfigToVariable" */
  tenancy__ProjectReleaseChannelConfigToVariable_aggregate: Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate;
  /** fetch data from the table in a streaming manner: "tenancy._ProjectReleaseChannelConfigToVariable" */
  tenancy__ProjectReleaseChannelConfigToVariable_stream: Array<Tenancy__ProjectReleaseChannelConfigToVariable>;
  /** fetch data from the table: "tenancy._prisma_migrations" */
  tenancy__prisma_migrations: Array<Tenancy__Prisma_Migrations>;
  /** fetch aggregated fields from the table: "tenancy._prisma_migrations" */
  tenancy__prisma_migrations_aggregate: Tenancy__Prisma_Migrations_Aggregate;
  /** fetch data from the table: "tenancy._prisma_migrations" using primary key columns */
  tenancy__prisma_migrations_by_pk?: Maybe<Tenancy__Prisma_Migrations>;
  /** fetch data from the table in a streaming manner: "tenancy._prisma_migrations" */
  tenancy__prisma_migrations_stream: Array<Tenancy__Prisma_Migrations>;
  /** fetch data from the table: "tenancy._role_projects" */
  tenancy__role_projects: Array<Tenancy__Role_Projects>;
  /** fetch aggregated fields from the table: "tenancy._role_projects" */
  tenancy__role_projects_aggregate: Tenancy__Role_Projects_Aggregate;
  /** fetch data from the table in a streaming manner: "tenancy._role_projects" */
  tenancy__role_projects_stream: Array<Tenancy__Role_Projects>;
  /** fetch data from the table: "tenancy._team_account_project_roles" */
  tenancy__team_account_project_roles: Array<Tenancy__Team_Account_Project_Roles>;
  /** fetch aggregated fields from the table: "tenancy._team_account_project_roles" */
  tenancy__team_account_project_roles_aggregate: Tenancy__Team_Account_Project_Roles_Aggregate;
  /** fetch data from the table in a streaming manner: "tenancy._team_account_project_roles" */
  tenancy__team_account_project_roles_stream: Array<Tenancy__Team_Account_Project_Roles>;
  /** fetch data from the table: "tenancy._team_account_release_channel_config_roles" */
  tenancy__team_account_release_channel_config_roles: Array<Tenancy__Team_Account_Release_Channel_Config_Roles>;
  /** fetch aggregated fields from the table: "tenancy._team_account_release_channel_config_roles" */
  tenancy__team_account_release_channel_config_roles_aggregate: Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate;
  /** fetch data from the table in a streaming manner: "tenancy._team_account_release_channel_config_roles" */
  tenancy__team_account_release_channel_config_roles_stream: Array<Tenancy__Team_Account_Release_Channel_Config_Roles>;
  /** fetch data from the table: "tenancy._team_account_roles" */
  tenancy__team_account_roles: Array<Tenancy__Team_Account_Roles>;
  /** fetch aggregated fields from the table: "tenancy._team_account_roles" */
  tenancy__team_account_roles_aggregate: Tenancy__Team_Account_Roles_Aggregate;
  /** fetch data from the table in a streaming manner: "tenancy._team_account_roles" */
  tenancy__team_account_roles_stream: Array<Tenancy__Team_Account_Roles>;
  /** fetch data from the table: "tenancy._user_account_project_roles" */
  tenancy__user_account_project_roles: Array<Tenancy__User_Account_Project_Roles>;
  /** fetch aggregated fields from the table: "tenancy._user_account_project_roles" */
  tenancy__user_account_project_roles_aggregate: Tenancy__User_Account_Project_Roles_Aggregate;
  /** fetch data from the table in a streaming manner: "tenancy._user_account_project_roles" */
  tenancy__user_account_project_roles_stream: Array<Tenancy__User_Account_Project_Roles>;
  /** fetch data from the table: "tenancy._user_account_release_channel_config_roles" */
  tenancy__user_account_release_channel_config_roles: Array<Tenancy__User_Account_Release_Channel_Config_Roles>;
  /** fetch aggregated fields from the table: "tenancy._user_account_release_channel_config_roles" */
  tenancy__user_account_release_channel_config_roles_aggregate: Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate;
  /** fetch data from the table in a streaming manner: "tenancy._user_account_release_channel_config_roles" */
  tenancy__user_account_release_channel_config_roles_stream: Array<Tenancy__User_Account_Release_Channel_Config_Roles>;
  /** fetch data from the table: "tenancy._user_account_roles" */
  tenancy__user_account_roles: Array<Tenancy__User_Account_Roles>;
  /** fetch aggregated fields from the table: "tenancy._user_account_roles" */
  tenancy__user_account_roles_aggregate: Tenancy__User_Account_Roles_Aggregate;
  /** fetch data from the table in a streaming manner: "tenancy._user_account_roles" */
  tenancy__user_account_roles_stream: Array<Tenancy__User_Account_Roles>;
  /** fetch data from the table: "tenancy._user_teams" */
  tenancy__user_teams: Array<Tenancy__User_Teams>;
  /** fetch aggregated fields from the table: "tenancy._user_teams" */
  tenancy__user_teams_aggregate: Tenancy__User_Teams_Aggregate;
  /** fetch data from the table in a streaming manner: "tenancy._user_teams" */
  tenancy__user_teams_stream: Array<Tenancy__User_Teams>;
  /** fetch data from the table: "tenancy.account_scheduled_job_runs" */
  tenancy_account_scheduled_job_runs: Array<Tenancy_Account_Scheduled_Job_Runs>;
  /** fetch aggregated fields from the table: "tenancy.account_scheduled_job_runs" */
  tenancy_account_scheduled_job_runs_aggregate: Tenancy_Account_Scheduled_Job_Runs_Aggregate;
  /** fetch data from the table: "tenancy.account_scheduled_job_runs" using primary key columns */
  tenancy_account_scheduled_job_runs_by_pk?: Maybe<Tenancy_Account_Scheduled_Job_Runs>;
  /** fetch data from the table in a streaming manner: "tenancy.account_scheduled_job_runs" */
  tenancy_account_scheduled_job_runs_stream: Array<Tenancy_Account_Scheduled_Job_Runs>;
  /** fetch data from the table: "tenancy.account_scheduled_jobs" */
  tenancy_account_scheduled_jobs: Array<Tenancy_Account_Scheduled_Jobs>;
  /** fetch aggregated fields from the table: "tenancy.account_scheduled_jobs" */
  tenancy_account_scheduled_jobs_aggregate: Tenancy_Account_Scheduled_Jobs_Aggregate;
  /** fetch data from the table: "tenancy.account_scheduled_jobs" using primary key columns */
  tenancy_account_scheduled_jobs_by_pk?: Maybe<Tenancy_Account_Scheduled_Jobs>;
  /** fetch data from the table in a streaming manner: "tenancy.account_scheduled_jobs" */
  tenancy_account_scheduled_jobs_stream: Array<Tenancy_Account_Scheduled_Jobs>;
  /** fetch data from the table: "tenancy.accounts" */
  tenancy_accounts: Array<Tenancy_Accounts>;
  /** fetch aggregated fields from the table: "tenancy.accounts" */
  tenancy_accounts_aggregate: Tenancy_Accounts_Aggregate;
  /** fetch data from the table: "tenancy.accounts" using primary key columns */
  tenancy_accounts_by_pk?: Maybe<Tenancy_Accounts>;
  /** fetch data from the table in a streaming manner: "tenancy.accounts" */
  tenancy_accounts_stream: Array<Tenancy_Accounts>;
  /** fetch data from the table: "tenancy.action" */
  tenancy_action: Array<Tenancy_Action>;
  /** fetch aggregated fields from the table: "tenancy.action" */
  tenancy_action_aggregate: Tenancy_Action_Aggregate;
  /** fetch data from the table: "tenancy.action" using primary key columns */
  tenancy_action_by_pk?: Maybe<Tenancy_Action>;
  /** fetch data from the table in a streaming manner: "tenancy.action" */
  tenancy_action_stream: Array<Tenancy_Action>;
  /** fetch data from the table: "tenancy.app_config" */
  tenancy_app_config: Array<Tenancy_App_Config>;
  /** fetch aggregated fields from the table: "tenancy.app_config" */
  tenancy_app_config_aggregate: Tenancy_App_Config_Aggregate;
  /** fetch data from the table: "tenancy.app_config" using primary key columns */
  tenancy_app_config_by_pk?: Maybe<Tenancy_App_Config>;
  /** fetch data from the table in a streaming manner: "tenancy.app_config" */
  tenancy_app_config_stream: Array<Tenancy_App_Config>;
  /** fetch data from the table: "tenancy.applet" */
  tenancy_applet: Array<Tenancy_Applet>;
  /** fetch aggregated fields from the table: "tenancy.applet" */
  tenancy_applet_aggregate: Tenancy_Applet_Aggregate;
  /** fetch data from the table: "tenancy.applet" using primary key columns */
  tenancy_applet_by_pk?: Maybe<Tenancy_Applet>;
  /** fetch data from the table: "tenancy.applet_instance" */
  tenancy_applet_instance: Array<Tenancy_Applet_Instance>;
  /** fetch aggregated fields from the table: "tenancy.applet_instance" */
  tenancy_applet_instance_aggregate: Tenancy_Applet_Instance_Aggregate;
  /** fetch data from the table: "tenancy.applet_instance" using primary key columns */
  tenancy_applet_instance_by_pk?: Maybe<Tenancy_Applet_Instance>;
  /** fetch data from the table in a streaming manner: "tenancy.applet_instance" */
  tenancy_applet_instance_stream: Array<Tenancy_Applet_Instance>;
  /** fetch data from the table in a streaming manner: "tenancy.applet" */
  tenancy_applet_stream: Array<Tenancy_Applet>;
  /** fetch data from the table: "tenancy.applet_version" */
  tenancy_applet_version: Array<Tenancy_Applet_Version>;
  /** fetch aggregated fields from the table: "tenancy.applet_version" */
  tenancy_applet_version_aggregate: Tenancy_Applet_Version_Aggregate;
  /** fetch data from the table: "tenancy.applet_version" using primary key columns */
  tenancy_applet_version_by_pk?: Maybe<Tenancy_Applet_Version>;
  /** fetch data from the table in a streaming manner: "tenancy.applet_version" */
  tenancy_applet_version_stream: Array<Tenancy_Applet_Version>;
  /** fetch data from the table: "tenancy.audit_logs" */
  tenancy_audit_logs: Array<Tenancy_Audit_Logs>;
  /** fetch aggregated fields from the table: "tenancy.audit_logs" */
  tenancy_audit_logs_aggregate: Tenancy_Audit_Logs_Aggregate;
  /** fetch data from the table: "tenancy.audit_logs" using primary key columns */
  tenancy_audit_logs_by_pk?: Maybe<Tenancy_Audit_Logs>;
  /** fetch data from the table in a streaming manner: "tenancy.audit_logs" */
  tenancy_audit_logs_stream: Array<Tenancy_Audit_Logs>;
  /** fetch data from the table: "tenancy.condition" */
  tenancy_condition: Array<Tenancy_Condition>;
  /** fetch aggregated fields from the table: "tenancy.condition" */
  tenancy_condition_aggregate: Tenancy_Condition_Aggregate;
  /** fetch data from the table: "tenancy.condition" using primary key columns */
  tenancy_condition_by_pk?: Maybe<Tenancy_Condition>;
  /** fetch data from the table in a streaming manner: "tenancy.condition" */
  tenancy_condition_stream: Array<Tenancy_Condition>;
  /** fetch data from the table: "tenancy.country" */
  tenancy_country: Array<Tenancy_Country>;
  /** fetch aggregated fields from the table: "tenancy.country" */
  tenancy_country_aggregate: Tenancy_Country_Aggregate;
  /** fetch data from the table: "tenancy.country" using primary key columns */
  tenancy_country_by_pk?: Maybe<Tenancy_Country>;
  /** fetch data from the table in a streaming manner: "tenancy.country" */
  tenancy_country_stream: Array<Tenancy_Country>;
  /** fetch data from the table: "tenancy.domain" */
  tenancy_domain: Array<Tenancy_Domain>;
  /** fetch aggregated fields from the table: "tenancy.domain" */
  tenancy_domain_aggregate: Tenancy_Domain_Aggregate;
  /** fetch data from the table: "tenancy.domain" using primary key columns */
  tenancy_domain_by_pk?: Maybe<Tenancy_Domain>;
  /** fetch data from the table in a streaming manner: "tenancy.domain" */
  tenancy_domain_stream: Array<Tenancy_Domain>;
  /** fetch data from the table: "tenancy.failed_kafka_events" */
  tenancy_failed_kafka_events: Array<Tenancy_Failed_Kafka_Events>;
  /** fetch aggregated fields from the table: "tenancy.failed_kafka_events" */
  tenancy_failed_kafka_events_aggregate: Tenancy_Failed_Kafka_Events_Aggregate;
  /** fetch data from the table: "tenancy.failed_kafka_events" using primary key columns */
  tenancy_failed_kafka_events_by_pk?: Maybe<Tenancy_Failed_Kafka_Events>;
  /** fetch data from the table in a streaming manner: "tenancy.failed_kafka_events" */
  tenancy_failed_kafka_events_stream: Array<Tenancy_Failed_Kafka_Events>;
  /** fetch data from the table: "tenancy.file" */
  tenancy_file: Array<Tenancy_File>;
  /** fetch aggregated fields from the table: "tenancy.file" */
  tenancy_file_aggregate: Tenancy_File_Aggregate;
  /** fetch data from the table: "tenancy.file" using primary key columns */
  tenancy_file_by_pk?: Maybe<Tenancy_File>;
  /** fetch data from the table in a streaming manner: "tenancy.file" */
  tenancy_file_stream: Array<Tenancy_File>;
  /** fetch data from the table: "tenancy.locale" */
  tenancy_locale: Array<Tenancy_Locale>;
  /** fetch aggregated fields from the table: "tenancy.locale" */
  tenancy_locale_aggregate: Tenancy_Locale_Aggregate;
  /** fetch data from the table: "tenancy.locale" using primary key columns */
  tenancy_locale_by_pk?: Maybe<Tenancy_Locale>;
  /** fetch data from the table in a streaming manner: "tenancy.locale" */
  tenancy_locale_stream: Array<Tenancy_Locale>;
  /** fetch data from the table: "tenancy.localization_settings" */
  tenancy_localization_settings: Array<Tenancy_Localization_Settings>;
  /** fetch aggregated fields from the table: "tenancy.localization_settings" */
  tenancy_localization_settings_aggregate: Tenancy_Localization_Settings_Aggregate;
  /** fetch data from the table: "tenancy.localization_settings" using primary key columns */
  tenancy_localization_settings_by_pk?: Maybe<Tenancy_Localization_Settings>;
  /** fetch data from the table in a streaming manner: "tenancy.localization_settings" */
  tenancy_localization_settings_stream: Array<Tenancy_Localization_Settings>;
  /** fetch data from the table: "tenancy.package" */
  tenancy_package: Array<Tenancy_Package>;
  /** fetch aggregated fields from the table: "tenancy.package" */
  tenancy_package_aggregate: Tenancy_Package_Aggregate;
  /** fetch data from the table: "tenancy.package" using primary key columns */
  tenancy_package_by_pk?: Maybe<Tenancy_Package>;
  /** fetch data from the table in a streaming manner: "tenancy.package" */
  tenancy_package_stream: Array<Tenancy_Package>;
  /** fetch data from the table: "tenancy.package_version" */
  tenancy_package_version: Array<Tenancy_Package_Version>;
  /** fetch aggregated fields from the table: "tenancy.package_version" */
  tenancy_package_version_aggregate: Tenancy_Package_Version_Aggregate;
  /** fetch data from the table: "tenancy.package_version" using primary key columns */
  tenancy_package_version_by_pk?: Maybe<Tenancy_Package_Version>;
  /** fetch data from the table in a streaming manner: "tenancy.package_version" */
  tenancy_package_version_stream: Array<Tenancy_Package_Version>;
  /** fetch data from the table: "tenancy.products" */
  tenancy_products: Array<Tenancy_Products>;
  /** fetch aggregated fields from the table: "tenancy.products" */
  tenancy_products_aggregate: Tenancy_Products_Aggregate;
  /** fetch data from the table: "tenancy.products" using primary key columns */
  tenancy_products_by_pk?: Maybe<Tenancy_Products>;
  /** fetch data from the table in a streaming manner: "tenancy.products" */
  tenancy_products_stream: Array<Tenancy_Products>;
  /** fetch data from the table: "tenancy.project_product_settings" */
  tenancy_project_product_settings: Array<Tenancy_Project_Product_Settings>;
  /** fetch aggregated fields from the table: "tenancy.project_product_settings" */
  tenancy_project_product_settings_aggregate: Tenancy_Project_Product_Settings_Aggregate;
  /** fetch data from the table: "tenancy.project_product_settings" using primary key columns */
  tenancy_project_product_settings_by_pk?: Maybe<Tenancy_Project_Product_Settings>;
  /** fetch data from the table in a streaming manner: "tenancy.project_product_settings" */
  tenancy_project_product_settings_stream: Array<Tenancy_Project_Product_Settings>;
  /** fetch data from the table: "tenancy.project_release_channel_config" */
  tenancy_project_release_channel_config: Array<Tenancy_Project_Release_Channel_Config>;
  /** fetch aggregated fields from the table: "tenancy.project_release_channel_config" */
  tenancy_project_release_channel_config_aggregate: Tenancy_Project_Release_Channel_Config_Aggregate;
  /** fetch data from the table: "tenancy.project_release_channel_config" using primary key columns */
  tenancy_project_release_channel_config_by_pk?: Maybe<Tenancy_Project_Release_Channel_Config>;
  /** fetch data from the table in a streaming manner: "tenancy.project_release_channel_config" */
  tenancy_project_release_channel_config_stream: Array<Tenancy_Project_Release_Channel_Config>;
  /** fetch data from the table: "tenancy.projects" */
  tenancy_projects: Array<Tenancy_Projects>;
  /** fetch aggregated fields from the table: "tenancy.projects" */
  tenancy_projects_aggregate: Tenancy_Projects_Aggregate;
  /** fetch data from the table: "tenancy.projects" using primary key columns */
  tenancy_projects_by_pk?: Maybe<Tenancy_Projects>;
  /** fetch data from the table in a streaming manner: "tenancy.projects" */
  tenancy_projects_stream: Array<Tenancy_Projects>;
  /** fetch data from the table: "tenancy.release_channel" */
  tenancy_release_channel: Array<Tenancy_Release_Channel>;
  /** fetch aggregated fields from the table: "tenancy.release_channel" */
  tenancy_release_channel_aggregate: Tenancy_Release_Channel_Aggregate;
  /** fetch data from the table: "tenancy.release_channel" using primary key columns */
  tenancy_release_channel_by_pk?: Maybe<Tenancy_Release_Channel>;
  /** fetch data from the table in a streaming manner: "tenancy.release_channel" */
  tenancy_release_channel_stream: Array<Tenancy_Release_Channel>;
  /** fetch data from the table: "tenancy.roles" */
  tenancy_roles: Array<Tenancy_Roles>;
  /** fetch aggregated fields from the table: "tenancy.roles" */
  tenancy_roles_aggregate: Tenancy_Roles_Aggregate;
  /** fetch data from the table: "tenancy.roles" using primary key columns */
  tenancy_roles_by_pk?: Maybe<Tenancy_Roles>;
  /** fetch data from the table in a streaming manner: "tenancy.roles" */
  tenancy_roles_stream: Array<Tenancy_Roles>;
  /** fetch data from the table: "tenancy.scheduled_jobs" */
  tenancy_scheduled_jobs: Array<Tenancy_Scheduled_Jobs>;
  /** fetch aggregated fields from the table: "tenancy.scheduled_jobs" */
  tenancy_scheduled_jobs_aggregate: Tenancy_Scheduled_Jobs_Aggregate;
  /** fetch data from the table: "tenancy.scheduled_jobs" using primary key columns */
  tenancy_scheduled_jobs_by_pk?: Maybe<Tenancy_Scheduled_Jobs>;
  /** fetch data from the table in a streaming manner: "tenancy.scheduled_jobs" */
  tenancy_scheduled_jobs_stream: Array<Tenancy_Scheduled_Jobs>;
  /** fetch data from the table: "tenancy.team_account_projects" */
  tenancy_team_account_projects: Array<Tenancy_Team_Account_Projects>;
  /** fetch aggregated fields from the table: "tenancy.team_account_projects" */
  tenancy_team_account_projects_aggregate: Tenancy_Team_Account_Projects_Aggregate;
  /** fetch data from the table: "tenancy.team_account_projects" using primary key columns */
  tenancy_team_account_projects_by_pk?: Maybe<Tenancy_Team_Account_Projects>;
  /** fetch data from the table in a streaming manner: "tenancy.team_account_projects" */
  tenancy_team_account_projects_stream: Array<Tenancy_Team_Account_Projects>;
  /** fetch data from the table: "tenancy.team_account_release_channel_config" */
  tenancy_team_account_release_channel_config: Array<Tenancy_Team_Account_Release_Channel_Config>;
  /** fetch aggregated fields from the table: "tenancy.team_account_release_channel_config" */
  tenancy_team_account_release_channel_config_aggregate: Tenancy_Team_Account_Release_Channel_Config_Aggregate;
  /** fetch data from the table: "tenancy.team_account_release_channel_config" using primary key columns */
  tenancy_team_account_release_channel_config_by_pk?: Maybe<Tenancy_Team_Account_Release_Channel_Config>;
  /** fetch data from the table in a streaming manner: "tenancy.team_account_release_channel_config" */
  tenancy_team_account_release_channel_config_stream: Array<Tenancy_Team_Account_Release_Channel_Config>;
  /** fetch data from the table: "tenancy.team_accounts" */
  tenancy_team_accounts: Array<Tenancy_Team_Accounts>;
  /** fetch aggregated fields from the table: "tenancy.team_accounts" */
  tenancy_team_accounts_aggregate: Tenancy_Team_Accounts_Aggregate;
  /** fetch data from the table: "tenancy.team_accounts" using primary key columns */
  tenancy_team_accounts_by_pk?: Maybe<Tenancy_Team_Accounts>;
  /** fetch data from the table in a streaming manner: "tenancy.team_accounts" */
  tenancy_team_accounts_stream: Array<Tenancy_Team_Accounts>;
  /** fetch data from the table: "tenancy.teams" */
  tenancy_teams: Array<Tenancy_Teams>;
  /** fetch aggregated fields from the table: "tenancy.teams" */
  tenancy_teams_aggregate: Tenancy_Teams_Aggregate;
  /** fetch data from the table: "tenancy.teams" using primary key columns */
  tenancy_teams_by_pk?: Maybe<Tenancy_Teams>;
  /** fetch data from the table in a streaming manner: "tenancy.teams" */
  tenancy_teams_stream: Array<Tenancy_Teams>;
  /** fetch data from the table: "tenancy.time_zone" */
  tenancy_time_zone: Array<Tenancy_Time_Zone>;
  /** fetch aggregated fields from the table: "tenancy.time_zone" */
  tenancy_time_zone_aggregate: Tenancy_Time_Zone_Aggregate;
  /** fetch data from the table: "tenancy.time_zone" using primary key columns */
  tenancy_time_zone_by_pk?: Maybe<Tenancy_Time_Zone>;
  /** fetch data from the table in a streaming manner: "tenancy.time_zone" */
  tenancy_time_zone_stream: Array<Tenancy_Time_Zone>;
  /** fetch data from the table: "tenancy.trigger" */
  tenancy_trigger: Array<Tenancy_Trigger>;
  /** fetch aggregated fields from the table: "tenancy.trigger" */
  tenancy_trigger_aggregate: Tenancy_Trigger_Aggregate;
  /** fetch data from the table: "tenancy.trigger" using primary key columns */
  tenancy_trigger_by_pk?: Maybe<Tenancy_Trigger>;
  /** fetch data from the table in a streaming manner: "tenancy.trigger" */
  tenancy_trigger_stream: Array<Tenancy_Trigger>;
  /** fetch data from the table: "tenancy.user_account_projects" */
  tenancy_user_account_projects: Array<Tenancy_User_Account_Projects>;
  /** fetch aggregated fields from the table: "tenancy.user_account_projects" */
  tenancy_user_account_projects_aggregate: Tenancy_User_Account_Projects_Aggregate;
  /** fetch data from the table: "tenancy.user_account_projects" using primary key columns */
  tenancy_user_account_projects_by_pk?: Maybe<Tenancy_User_Account_Projects>;
  /** fetch data from the table in a streaming manner: "tenancy.user_account_projects" */
  tenancy_user_account_projects_stream: Array<Tenancy_User_Account_Projects>;
  /** fetch data from the table: "tenancy.user_account_release_channel_config" */
  tenancy_user_account_release_channel_config: Array<Tenancy_User_Account_Release_Channel_Config>;
  /** fetch aggregated fields from the table: "tenancy.user_account_release_channel_config" */
  tenancy_user_account_release_channel_config_aggregate: Tenancy_User_Account_Release_Channel_Config_Aggregate;
  /** fetch data from the table: "tenancy.user_account_release_channel_config" using primary key columns */
  tenancy_user_account_release_channel_config_by_pk?: Maybe<Tenancy_User_Account_Release_Channel_Config>;
  /** fetch data from the table in a streaming manner: "tenancy.user_account_release_channel_config" */
  tenancy_user_account_release_channel_config_stream: Array<Tenancy_User_Account_Release_Channel_Config>;
  /** fetch data from the table: "tenancy.user_accounts" */
  tenancy_user_accounts: Array<Tenancy_User_Accounts>;
  /** fetch aggregated fields from the table: "tenancy.user_accounts" */
  tenancy_user_accounts_aggregate: Tenancy_User_Accounts_Aggregate;
  /** fetch data from the table: "tenancy.user_accounts" using primary key columns */
  tenancy_user_accounts_by_pk?: Maybe<Tenancy_User_Accounts>;
  /** fetch data from the table in a streaming manner: "tenancy.user_accounts" */
  tenancy_user_accounts_stream: Array<Tenancy_User_Accounts>;
  /** fetch data from the table: "tenancy.users" */
  tenancy_users: Array<Tenancy_Users>;
  /** fetch aggregated fields from the table: "tenancy.users" */
  tenancy_users_aggregate: Tenancy_Users_Aggregate;
  /** fetch data from the table: "tenancy.users" using primary key columns */
  tenancy_users_by_pk?: Maybe<Tenancy_Users>;
  /** fetch data from the table in a streaming manner: "tenancy.users" */
  tenancy_users_stream: Array<Tenancy_Users>;
  /** fetch data from the table: "tenancy.variable" */
  tenancy_variable: Array<Tenancy_Variable>;
  /** fetch aggregated fields from the table: "tenancy.variable" */
  tenancy_variable_aggregate: Tenancy_Variable_Aggregate;
  /** fetch data from the table: "tenancy.variable" using primary key columns */
  tenancy_variable_by_pk?: Maybe<Tenancy_Variable>;
  /** fetch data from the table in a streaming manner: "tenancy.variable" */
  tenancy_variable_stream: Array<Tenancy_Variable>;
};


export type Subscription_RootApp_Loader_Data_StoreArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Data_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Data_Store_Order_By>>;
  where?: InputMaybe<App_Loader_Data_Store_Bool_Exp>;
};


export type Subscription_RootApp_Loader_Data_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Loader_Data_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<App_Loader_Data_Store_Order_By>>;
  where?: InputMaybe<App_Loader_Data_Store_Bool_Exp>;
};


export type Subscription_RootApp_Loader_Data_Store_By_PkArgs = {
  session_id: Scalars['String']['input'];
};


export type Subscription_RootApp_Loader_Data_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<App_Loader_Data_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Loader_Data_Store_Bool_Exp>;
};


export type Subscription_RootApps_AppArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Order_By>>;
  where?: InputMaybe<Apps_App_Bool_Exp>;
};


export type Subscription_RootApps_App_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Order_By>>;
  where?: InputMaybe<Apps_App_Bool_Exp>;
};


export type Subscription_RootApps_App_By_PkArgs = {
  app_id: Scalars['Int']['input'];
};


export type Subscription_RootApps_App_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Config_Order_By>>;
  where?: InputMaybe<Apps_App_Config_Bool_Exp>;
};


export type Subscription_RootApps_App_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_Config_Order_By>>;
  where?: InputMaybe<Apps_App_Config_Bool_Exp>;
};


export type Subscription_RootApps_App_Config_By_PkArgs = {
  app_config_id: Scalars['Int']['input'];
};


export type Subscription_RootApps_App_Config_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_App_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_App_Config_Bool_Exp>;
};


export type Subscription_RootApps_App_FileArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_File_Order_By>>;
  where?: InputMaybe<Apps_App_File_Bool_Exp>;
};


export type Subscription_RootApps_App_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_App_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_App_File_Order_By>>;
  where?: InputMaybe<Apps_App_File_Bool_Exp>;
};


export type Subscription_RootApps_App_File_By_PkArgs = {
  app_id: Scalars['bigint']['input'];
  filename: Scalars['String']['input'];
  parent_path: Scalars['String']['input'];
};


export type Subscription_RootApps_App_File_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_App_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_App_File_Bool_Exp>;
};


export type Subscription_RootApps_App_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_App_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_App_Bool_Exp>;
};


export type Subscription_RootApps_Convert_RoundtripArgs = {
  distinct_on?: InputMaybe<Array<Apps_Convert_Roundtrip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Convert_Roundtrip_Order_By>>;
  where?: InputMaybe<Apps_Convert_Roundtrip_Bool_Exp>;
};


export type Subscription_RootApps_Convert_Roundtrip_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Convert_Roundtrip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Convert_Roundtrip_Order_By>>;
  where?: InputMaybe<Apps_Convert_Roundtrip_Bool_Exp>;
};


export type Subscription_RootApps_Convert_Roundtrip_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


export type Subscription_RootApps_Convert_Roundtrip_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Convert_Roundtrip_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Convert_Roundtrip_Bool_Exp>;
};


export type Subscription_RootApps_Csv_FileArgs = {
  distinct_on?: InputMaybe<Array<Apps_Csv_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Csv_File_Order_By>>;
  where?: InputMaybe<Apps_Csv_File_Bool_Exp>;
};


export type Subscription_RootApps_Csv_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Csv_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Csv_File_Order_By>>;
  where?: InputMaybe<Apps_Csv_File_Bool_Exp>;
};


export type Subscription_RootApps_Csv_File_By_PkArgs = {
  csv_file_id: Scalars['Int']['input'];
};


export type Subscription_RootApps_Csv_File_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Csv_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Csv_File_Bool_Exp>;
};


export type Subscription_RootApps_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Apps_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Customer_Order_By>>;
  where?: InputMaybe<Apps_Customer_Bool_Exp>;
};


export type Subscription_RootApps_Customer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Customer_Order_By>>;
  where?: InputMaybe<Apps_Customer_Bool_Exp>;
};


export type Subscription_RootApps_Customer_By_PkArgs = {
  customer_id: Scalars['Int']['input'];
};


export type Subscription_RootApps_Customer_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Apps_Customer_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Customer_Config_Order_By>>;
  where?: InputMaybe<Apps_Customer_Config_Bool_Exp>;
};


export type Subscription_RootApps_Customer_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Customer_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Customer_Config_Order_By>>;
  where?: InputMaybe<Apps_Customer_Config_Bool_Exp>;
};


export type Subscription_RootApps_Customer_Config_By_PkArgs = {
  customer_config_id: Scalars['Int']['input'];
};


export type Subscription_RootApps_Customer_Config_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Customer_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Customer_Config_Bool_Exp>;
};


export type Subscription_RootApps_Customer_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Customer_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Customer_Bool_Exp>;
};


export type Subscription_RootApps_DeploymentArgs = {
  distinct_on?: InputMaybe<Array<Apps_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Deployment_Order_By>>;
  where?: InputMaybe<Apps_Deployment_Bool_Exp>;
};


export type Subscription_RootApps_Deployment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Deployment_Order_By>>;
  where?: InputMaybe<Apps_Deployment_Bool_Exp>;
};


export type Subscription_RootApps_Deployment_By_PkArgs = {
  deployment_id: Scalars['Int']['input'];
};


export type Subscription_RootApps_Deployment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Deployment_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Deployment_Bool_Exp>;
};


export type Subscription_RootApps_EnvironmentArgs = {
  distinct_on?: InputMaybe<Array<Apps_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Environment_Order_By>>;
  where?: InputMaybe<Apps_Environment_Bool_Exp>;
};


export type Subscription_RootApps_Environment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Environment_Order_By>>;
  where?: InputMaybe<Apps_Environment_Bool_Exp>;
};


export type Subscription_RootApps_Environment_By_PkArgs = {
  environment_id: Scalars['Int']['input'];
};


export type Subscription_RootApps_Environment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Environment_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Environment_Bool_Exp>;
};


export type Subscription_RootApps_FileArgs = {
  distinct_on?: InputMaybe<Array<Apps_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_File_Order_By>>;
  where?: InputMaybe<Apps_File_Bool_Exp>;
};


export type Subscription_RootApps_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_File_Order_By>>;
  where?: InputMaybe<Apps_File_Bool_Exp>;
};


export type Subscription_RootApps_File_By_PkArgs = {
  file_id: Scalars['bigint']['input'];
};


export type Subscription_RootApps_File_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_File_Bool_Exp>;
};


export type Subscription_RootApps_Import_FailArgs = {
  distinct_on?: InputMaybe<Array<Apps_Import_Fail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Import_Fail_Order_By>>;
  where?: InputMaybe<Apps_Import_Fail_Bool_Exp>;
};


export type Subscription_RootApps_Import_Fail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Import_Fail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Import_Fail_Order_By>>;
  where?: InputMaybe<Apps_Import_Fail_Bool_Exp>;
};


export type Subscription_RootApps_Import_Fail_By_PkArgs = {
  import_fail_id: Scalars['Int']['input'];
};


export type Subscription_RootApps_Import_Fail_NodeArgs = {
  distinct_on?: InputMaybe<Array<Apps_Import_Fail_Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Import_Fail_Node_Order_By>>;
  where?: InputMaybe<Apps_Import_Fail_Node_Bool_Exp>;
};


export type Subscription_RootApps_Import_Fail_Node_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Import_Fail_Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Import_Fail_Node_Order_By>>;
  where?: InputMaybe<Apps_Import_Fail_Node_Bool_Exp>;
};


export type Subscription_RootApps_Import_Fail_Node_By_PkArgs = {
  import_fail_node_id: Scalars['bigint']['input'];
};


export type Subscription_RootApps_Import_Fail_Node_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Import_Fail_Node_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Import_Fail_Node_Bool_Exp>;
};


export type Subscription_RootApps_Import_Fail_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Import_Fail_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Import_Fail_Bool_Exp>;
};


export type Subscription_RootApps_Nlu_FilesArgs = {
  distinct_on?: InputMaybe<Array<Apps_Nlu_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Nlu_Files_Order_By>>;
  where?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
};


export type Subscription_RootApps_Nlu_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Nlu_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Nlu_Files_Order_By>>;
  where?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
};


export type Subscription_RootApps_Nlu_Files_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


export type Subscription_RootApps_Nlu_Files_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Nlu_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Nlu_Files_Bool_Exp>;
};


export type Subscription_RootApps_NodeArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Order_By>>;
  where?: InputMaybe<Apps_Node_Bool_Exp>;
};


export type Subscription_RootApps_Node_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Order_By>>;
  where?: InputMaybe<Apps_Node_Bool_Exp>;
};


export type Subscription_RootApps_Node_By_PkArgs = {
  node_id: Scalars['bigint']['input'];
};


export type Subscription_RootApps_Node_Import_FlatArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Import_Flat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Import_Flat_Order_By>>;
  where?: InputMaybe<Apps_Node_Import_Flat_Bool_Exp>;
};


export type Subscription_RootApps_Node_Import_Flat_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Import_Flat_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Import_Flat_Order_By>>;
  where?: InputMaybe<Apps_Node_Import_Flat_Bool_Exp>;
};


export type Subscription_RootApps_Node_Import_Flat_By_PkArgs = {
  node_import_flat_id: Scalars['Int']['input'];
};


export type Subscription_RootApps_Node_Import_Flat_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Node_Import_Flat_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Node_Import_Flat_Bool_Exp>;
};


export type Subscription_RootApps_Node_Import_JsonArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Import_Json_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Import_Json_Order_By>>;
  where?: InputMaybe<Apps_Node_Import_Json_Bool_Exp>;
};


export type Subscription_RootApps_Node_Import_Json_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Import_Json_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Import_Json_Order_By>>;
  where?: InputMaybe<Apps_Node_Import_Json_Bool_Exp>;
};


export type Subscription_RootApps_Node_Import_Json_By_PkArgs = {
  node_import_json_id: Scalars['Int']['input'];
};


export type Subscription_RootApps_Node_Import_Json_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Node_Import_Json_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Node_Import_Json_Bool_Exp>;
};


export type Subscription_RootApps_Node_InstanceArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
};


export type Subscription_RootApps_Node_Instance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
};


export type Subscription_RootApps_Node_Instance_By_PkArgs = {
  node_instance_id: Scalars['bigint']['input'];
};


export type Subscription_RootApps_Node_Instance_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Node_Instance_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Node_Instance_Bool_Exp>;
};


export type Subscription_RootApps_Node_Outgoing_Edge_ViewArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Outgoing_Edge_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Outgoing_Edge_View_Order_By>>;
  where?: InputMaybe<Apps_Node_Outgoing_Edge_View_Bool_Exp>;
};


export type Subscription_RootApps_Node_Outgoing_Edge_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Node_Outgoing_Edge_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Node_Outgoing_Edge_View_Order_By>>;
  where?: InputMaybe<Apps_Node_Outgoing_Edge_View_Bool_Exp>;
};


export type Subscription_RootApps_Node_Outgoing_Edge_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Node_Outgoing_Edge_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Node_Outgoing_Edge_View_Bool_Exp>;
};


export type Subscription_RootApps_Node_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Node_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Node_Bool_Exp>;
};


export type Subscription_RootApps_Rich_AssetArgs = {
  distinct_on?: InputMaybe<Array<Apps_Rich_Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Rich_Asset_Order_By>>;
  where?: InputMaybe<Apps_Rich_Asset_Bool_Exp>;
};


export type Subscription_RootApps_Rich_Asset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Rich_Asset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Rich_Asset_Order_By>>;
  where?: InputMaybe<Apps_Rich_Asset_Bool_Exp>;
};


export type Subscription_RootApps_Rich_Asset_By_PkArgs = {
  rich_asset_id: Scalars['bigint']['input'];
};


export type Subscription_RootApps_Rich_Asset_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Rich_Asset_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Rich_Asset_Bool_Exp>;
};


export type Subscription_RootApps_VersionArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};


export type Subscription_RootApps_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};


export type Subscription_RootApps_Version_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


export type Subscription_RootApps_Version_Display_DataArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Display_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Display_Data_Order_By>>;
  where?: InputMaybe<Apps_Version_Display_Data_Bool_Exp>;
};


export type Subscription_RootApps_Version_Display_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Display_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Display_Data_Order_By>>;
  where?: InputMaybe<Apps_Version_Display_Data_Bool_Exp>;
};


export type Subscription_RootApps_Version_Display_Data_By_PkArgs = {
  version_id: Scalars['bigint']['input'];
};


export type Subscription_RootApps_Version_Display_Data_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Version_Display_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Version_Display_Data_Bool_Exp>;
};


export type Subscription_RootApps_Version_FileArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_File_Order_By>>;
  where?: InputMaybe<Apps_Version_File_Bool_Exp>;
};


export type Subscription_RootApps_Version_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_File_Order_By>>;
  where?: InputMaybe<Apps_Version_File_Bool_Exp>;
};


export type Subscription_RootApps_Version_File_By_PkArgs = {
  filename: Scalars['String']['input'];
  parent_path: Scalars['String']['input'];
  version_id: Scalars['bigint']['input'];
};


export type Subscription_RootApps_Version_File_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Version_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Version_File_Bool_Exp>;
};


export type Subscription_RootApps_Version_Node_InstanceArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
};


export type Subscription_RootApps_Version_Node_Instance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Node_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Node_Instance_Order_By>>;
  where?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
};


export type Subscription_RootApps_Version_Node_Instance_By_PkArgs = {
  node_number: Scalars['bigint']['input'];
  version_id: Scalars['bigint']['input'];
};


export type Subscription_RootApps_Version_Node_Instance_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Version_Node_Instance_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Version_Node_Instance_Bool_Exp>;
};


export type Subscription_RootApps_Version_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Version_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};


export type Subscription_RootTenancy__ProjectReleaseChannelConfigToVariableArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Order_By>>;
  where?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
};


export type Subscription_RootTenancy__ProjectReleaseChannelConfigToVariable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Order_By>>;
  where?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
};


export type Subscription_RootTenancy__ProjectReleaseChannelConfigToVariable_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
};


export type Subscription_RootTenancy__Prisma_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Prisma_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Prisma_Migrations_Order_By>>;
  where?: InputMaybe<Tenancy__Prisma_Migrations_Bool_Exp>;
};


export type Subscription_RootTenancy__Prisma_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Prisma_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Prisma_Migrations_Order_By>>;
  where?: InputMaybe<Tenancy__Prisma_Migrations_Bool_Exp>;
};


export type Subscription_RootTenancy__Prisma_Migrations_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy__Prisma_Migrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy__Prisma_Migrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy__Prisma_Migrations_Bool_Exp>;
};


export type Subscription_RootTenancy__Role_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Role_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Role_Projects_Order_By>>;
  where?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy__Role_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Role_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Role_Projects_Order_By>>;
  where?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy__Role_Projects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy__Role_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy__Team_Account_Project_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__Team_Account_Project_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__Team_Account_Project_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy__Team_Account_Project_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__Team_Account_Release_Channel_Config_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__Team_Account_Release_Channel_Config_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__Team_Account_Release_Channel_Config_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__Team_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__Team_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__Team_Account_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy__Team_Account_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__User_Account_Project_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__User_Account_Project_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__User_Account_Project_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy__User_Account_Project_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__User_Account_Release_Channel_Config_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__User_Account_Release_Channel_Config_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__User_Account_Release_Channel_Config_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__User_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__User_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__User_Account_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy__User_Account_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy__User_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Teams_Order_By>>;
  where?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
};


export type Subscription_RootTenancy__User_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Teams_Order_By>>;
  where?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
};


export type Subscription_RootTenancy__User_Teams_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy__User_Teams_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
};


export type Subscription_RootTenancy_Account_Scheduled_Job_RunsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
};


export type Subscription_RootTenancy_Account_Scheduled_Job_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
};


export type Subscription_RootTenancy_Account_Scheduled_Job_Runs_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Account_Scheduled_Job_Runs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
};


export type Subscription_RootTenancy_Account_Scheduled_JobsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
};


export type Subscription_RootTenancy_Account_Scheduled_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
};


export type Subscription_RootTenancy_Account_Scheduled_Jobs_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Account_Scheduled_Jobs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Account_Scheduled_Jobs_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
};


export type Subscription_RootTenancy_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
};


export type Subscription_RootTenancy_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
};


export type Subscription_RootTenancy_Accounts_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Accounts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
};


export type Subscription_RootTenancy_ActionArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Action_Order_By>>;
  where?: InputMaybe<Tenancy_Action_Bool_Exp>;
};


export type Subscription_RootTenancy_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Action_Order_By>>;
  where?: InputMaybe<Tenancy_Action_Bool_Exp>;
};


export type Subscription_RootTenancy_Action_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Action_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Action_Bool_Exp>;
};


export type Subscription_RootTenancy_App_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_App_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_App_Config_Order_By>>;
  where?: InputMaybe<Tenancy_App_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_App_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_App_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_App_Config_Order_By>>;
  where?: InputMaybe<Tenancy_App_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_App_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_App_Config_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_App_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_App_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_AppletArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Bool_Exp>;
};


export type Subscription_RootTenancy_Applet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Bool_Exp>;
};


export type Subscription_RootTenancy_Applet_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Applet_InstanceArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Instance_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
};


export type Subscription_RootTenancy_Applet_Instance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Instance_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
};


export type Subscription_RootTenancy_Applet_Instance_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Applet_Instance_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Applet_Instance_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
};


export type Subscription_RootTenancy_Applet_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Applet_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Applet_Bool_Exp>;
};


export type Subscription_RootTenancy_Applet_VersionArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
};


export type Subscription_RootTenancy_Applet_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
};


export type Subscription_RootTenancy_Applet_Version_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Applet_Version_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Applet_Version_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
};


export type Subscription_RootTenancy_Audit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Audit_Logs_Order_By>>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};


export type Subscription_RootTenancy_Audit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Audit_Logs_Order_By>>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};


export type Subscription_RootTenancy_Audit_Logs_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Audit_Logs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Audit_Logs_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};


export type Subscription_RootTenancy_ConditionArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Condition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Condition_Order_By>>;
  where?: InputMaybe<Tenancy_Condition_Bool_Exp>;
};


export type Subscription_RootTenancy_Condition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Condition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Condition_Order_By>>;
  where?: InputMaybe<Tenancy_Condition_Bool_Exp>;
};


export type Subscription_RootTenancy_Condition_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Condition_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Condition_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Condition_Bool_Exp>;
};


export type Subscription_RootTenancy_CountryArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Country_Order_By>>;
  where?: InputMaybe<Tenancy_Country_Bool_Exp>;
};


export type Subscription_RootTenancy_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Country_Order_By>>;
  where?: InputMaybe<Tenancy_Country_Bool_Exp>;
};


export type Subscription_RootTenancy_Country_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Country_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Country_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Country_Bool_Exp>;
};


export type Subscription_RootTenancy_DomainArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Domain_Order_By>>;
  where?: InputMaybe<Tenancy_Domain_Bool_Exp>;
};


export type Subscription_RootTenancy_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Domain_Order_By>>;
  where?: InputMaybe<Tenancy_Domain_Bool_Exp>;
};


export type Subscription_RootTenancy_Domain_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Domain_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Domain_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Domain_Bool_Exp>;
};


export type Subscription_RootTenancy_Failed_Kafka_EventsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Failed_Kafka_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Failed_Kafka_Events_Order_By>>;
  where?: InputMaybe<Tenancy_Failed_Kafka_Events_Bool_Exp>;
};


export type Subscription_RootTenancy_Failed_Kafka_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Failed_Kafka_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Failed_Kafka_Events_Order_By>>;
  where?: InputMaybe<Tenancy_Failed_Kafka_Events_Bool_Exp>;
};


export type Subscription_RootTenancy_Failed_Kafka_Events_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Failed_Kafka_Events_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Failed_Kafka_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Failed_Kafka_Events_Bool_Exp>;
};


export type Subscription_RootTenancy_FileArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


export type Subscription_RootTenancy_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


export type Subscription_RootTenancy_File_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_File_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


export type Subscription_RootTenancy_LocaleArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Locale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Locale_Order_By>>;
  where?: InputMaybe<Tenancy_Locale_Bool_Exp>;
};


export type Subscription_RootTenancy_Locale_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Locale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Locale_Order_By>>;
  where?: InputMaybe<Tenancy_Locale_Bool_Exp>;
};


export type Subscription_RootTenancy_Locale_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Locale_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Locale_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Locale_Bool_Exp>;
};


export type Subscription_RootTenancy_Localization_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


export type Subscription_RootTenancy_Localization_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


export type Subscription_RootTenancy_Localization_Settings_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Localization_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Localization_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


export type Subscription_RootTenancy_PackageArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Bool_Exp>;
};


export type Subscription_RootTenancy_Package_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Bool_Exp>;
};


export type Subscription_RootTenancy_Package_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Package_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Package_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Package_Bool_Exp>;
};


export type Subscription_RootTenancy_Package_VersionArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
};


export type Subscription_RootTenancy_Package_Version_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
};


export type Subscription_RootTenancy_Package_Version_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Package_Version_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Package_Version_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
};


export type Subscription_RootTenancy_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Products_Order_By>>;
  where?: InputMaybe<Tenancy_Products_Bool_Exp>;
};


export type Subscription_RootTenancy_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Products_Order_By>>;
  where?: InputMaybe<Tenancy_Products_Bool_Exp>;
};


export type Subscription_RootTenancy_Products_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Products_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Products_Bool_Exp>;
};


export type Subscription_RootTenancy_Project_Product_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Product_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
};


export type Subscription_RootTenancy_Project_Product_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Product_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
};


export type Subscription_RootTenancy_Project_Product_Settings_By_PkArgs = {
  productId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Project_Product_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Project_Product_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
};


export type Subscription_RootTenancy_Project_Release_Channel_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_Project_Release_Channel_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_Project_Release_Channel_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Project_Release_Channel_Config_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Project_Release_Channel_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy_Projects_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Projects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy_Release_ChannelArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Release_Channel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Release_Channel_Order_By>>;
  where?: InputMaybe<Tenancy_Release_Channel_Bool_Exp>;
};


export type Subscription_RootTenancy_Release_Channel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Release_Channel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Release_Channel_Order_By>>;
  where?: InputMaybe<Tenancy_Release_Channel_Bool_Exp>;
};


export type Subscription_RootTenancy_Release_Channel_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Release_Channel_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Release_Channel_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Release_Channel_Bool_Exp>;
};


export type Subscription_RootTenancy_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Roles_Order_By>>;
  where?: InputMaybe<Tenancy_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Roles_Order_By>>;
  where?: InputMaybe<Tenancy_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy_Roles_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Roles_Bool_Exp>;
};


export type Subscription_RootTenancy_Scheduled_JobsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Scheduled_Jobs_Bool_Exp>;
};


export type Subscription_RootTenancy_Scheduled_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Scheduled_Jobs_Bool_Exp>;
};


export type Subscription_RootTenancy_Scheduled_Jobs_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Scheduled_Jobs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Scheduled_Jobs_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Scheduled_Jobs_Bool_Exp>;
};


export type Subscription_RootTenancy_Team_Account_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy_Team_Account_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy_Team_Account_Projects_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Team_Account_Projects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Team_Account_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy_Team_Account_Release_Channel_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_Team_Account_Release_Channel_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_Team_Account_Release_Channel_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Team_Account_Release_Channel_Config_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_Team_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};


export type Subscription_RootTenancy_Team_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};


export type Subscription_RootTenancy_Team_Accounts_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Team_Accounts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Team_Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};


export type Subscription_RootTenancy_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Teams_Order_By>>;
  where?: InputMaybe<Tenancy_Teams_Bool_Exp>;
};


export type Subscription_RootTenancy_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Teams_Order_By>>;
  where?: InputMaybe<Tenancy_Teams_Bool_Exp>;
};


export type Subscription_RootTenancy_Teams_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Teams_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Teams_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Teams_Bool_Exp>;
};


export type Subscription_RootTenancy_Time_ZoneArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Time_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Time_Zone_Order_By>>;
  where?: InputMaybe<Tenancy_Time_Zone_Bool_Exp>;
};


export type Subscription_RootTenancy_Time_Zone_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Time_Zone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Time_Zone_Order_By>>;
  where?: InputMaybe<Tenancy_Time_Zone_Bool_Exp>;
};


export type Subscription_RootTenancy_Time_Zone_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Time_Zone_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Time_Zone_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Time_Zone_Bool_Exp>;
};


export type Subscription_RootTenancy_TriggerArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Trigger_Order_By>>;
  where?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
};


export type Subscription_RootTenancy_Trigger_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Trigger_Order_By>>;
  where?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
};


export type Subscription_RootTenancy_Trigger_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Trigger_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Trigger_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
};


export type Subscription_RootTenancy_User_Account_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy_User_Account_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy_User_Account_Projects_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_User_Account_Projects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_User_Account_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
};


export type Subscription_RootTenancy_User_Account_Release_Channel_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_User_Account_Release_Channel_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_User_Account_Release_Channel_Config_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_User_Account_Release_Channel_Config_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_User_Account_Release_Channel_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
};


export type Subscription_RootTenancy_User_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};


export type Subscription_RootTenancy_User_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};


export type Subscription_RootTenancy_User_Accounts_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_User_Accounts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_User_Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};


export type Subscription_RootTenancy_UsersArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Users_Order_By>>;
  where?: InputMaybe<Tenancy_Users_Bool_Exp>;
};


export type Subscription_RootTenancy_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Users_Order_By>>;
  where?: InputMaybe<Tenancy_Users_Bool_Exp>;
};


export type Subscription_RootTenancy_Users_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Users_Bool_Exp>;
};


export type Subscription_RootTenancy_VariableArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Variable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Variable_Order_By>>;
  where?: InputMaybe<Tenancy_Variable_Bool_Exp>;
};


export type Subscription_RootTenancy_Variable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Variable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Variable_Order_By>>;
  where?: InputMaybe<Tenancy_Variable_Bool_Exp>;
};


export type Subscription_RootTenancy_Variable_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTenancy_Variable_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tenancy_Variable_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenancy_Variable_Bool_Exp>;
};

/** columns and relationships of "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable = {
  __typename?: 'tenancy__ProjectReleaseChannelConfigToVariable';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  /** An object relationship */
  project_release_channel_config: Tenancy_Project_Release_Channel_Config;
  /** An object relationship */
  variable: Tenancy_Variable;
};

/** aggregated selection of "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate = {
  __typename?: 'tenancy__ProjectReleaseChannelConfigToVariable_aggregate';
  aggregate?: Maybe<Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate_Fields>;
  nodes: Array<Tenancy__ProjectReleaseChannelConfigToVariable>;
};

export type Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate_Bool_Exp_Count>;
};

export type Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate_Fields = {
  __typename?: 'tenancy__ProjectReleaseChannelConfigToVariable_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy__ProjectReleaseChannelConfigToVariable_Max_Fields>;
  min?: Maybe<Tenancy__ProjectReleaseChannelConfigToVariable_Min_Fields>;
};


/** aggregate fields of "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Max_Order_By>;
  min?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Arr_Rel_Insert_Input = {
  data: Array<Tenancy__ProjectReleaseChannelConfigToVariable_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy._ProjectReleaseChannelConfigToVariable". All fields are combined with a logical 'AND'. */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>>;
  _not?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
  variable?: InputMaybe<Tenancy_Variable_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy._ProjectReleaseChannelConfigToVariable" */
export enum Tenancy__ProjectReleaseChannelConfigToVariable_Constraint {
  /** unique or primary key constraint on columns "A", "B" */
  ProjectReleaseChannelConfigToVariableAbUnique = '_ProjectReleaseChannelConfigToVariable_AB_unique'
}

/** input type for inserting data into table "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Obj_Rel_Insert_Input>;
  variable?: InputMaybe<Tenancy_Variable_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Max_Fields = {
  __typename?: 'tenancy__ProjectReleaseChannelConfigToVariable_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Max_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Min_Fields = {
  __typename?: 'tenancy__ProjectReleaseChannelConfigToVariable_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Min_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Mutation_Response = {
  __typename?: 'tenancy__ProjectReleaseChannelConfigToVariable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy__ProjectReleaseChannelConfigToVariable>;
};

/** on_conflict condition type for table "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_On_Conflict = {
  constraint: Tenancy__ProjectReleaseChannelConfigToVariable_Constraint;
  update_columns?: Array<Tenancy__ProjectReleaseChannelConfigToVariable_Update_Column>;
  where?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy._ProjectReleaseChannelConfigToVariable". */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Order_By>;
  variable?: InputMaybe<Tenancy_Variable_Order_By>;
};

/** select columns of table "tenancy._ProjectReleaseChannelConfigToVariable" */
export enum Tenancy__ProjectReleaseChannelConfigToVariable_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "tenancy._ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy__ProjectReleaseChannelConfigToVariable" */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy__ProjectReleaseChannelConfigToVariable_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy__ProjectReleaseChannelConfigToVariable_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy._ProjectReleaseChannelConfigToVariable" */
export enum Tenancy__ProjectReleaseChannelConfigToVariable_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Tenancy__ProjectReleaseChannelConfigToVariable_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp;
};

/** columns and relationships of "tenancy._prisma_migrations" */
export type Tenancy__Prisma_Migrations = {
  __typename?: 'tenancy__prisma_migrations';
  applied_steps_count: Scalars['Int']['output'];
  checksum: Scalars['String']['output'];
  finished_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['String']['output'];
  logs?: Maybe<Scalars['String']['output']>;
  migration_name: Scalars['String']['output'];
  rolled_back_at?: Maybe<Scalars['timestamptz']['output']>;
  started_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "tenancy._prisma_migrations" */
export type Tenancy__Prisma_Migrations_Aggregate = {
  __typename?: 'tenancy__prisma_migrations_aggregate';
  aggregate?: Maybe<Tenancy__Prisma_Migrations_Aggregate_Fields>;
  nodes: Array<Tenancy__Prisma_Migrations>;
};

/** aggregate fields of "tenancy._prisma_migrations" */
export type Tenancy__Prisma_Migrations_Aggregate_Fields = {
  __typename?: 'tenancy__prisma_migrations_aggregate_fields';
  avg?: Maybe<Tenancy__Prisma_Migrations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy__Prisma_Migrations_Max_Fields>;
  min?: Maybe<Tenancy__Prisma_Migrations_Min_Fields>;
  stddev?: Maybe<Tenancy__Prisma_Migrations_Stddev_Fields>;
  stddev_pop?: Maybe<Tenancy__Prisma_Migrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenancy__Prisma_Migrations_Stddev_Samp_Fields>;
  sum?: Maybe<Tenancy__Prisma_Migrations_Sum_Fields>;
  var_pop?: Maybe<Tenancy__Prisma_Migrations_Var_Pop_Fields>;
  var_samp?: Maybe<Tenancy__Prisma_Migrations_Var_Samp_Fields>;
  variance?: Maybe<Tenancy__Prisma_Migrations_Variance_Fields>;
};


/** aggregate fields of "tenancy._prisma_migrations" */
export type Tenancy__Prisma_Migrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy__Prisma_Migrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Tenancy__Prisma_Migrations_Avg_Fields = {
  __typename?: 'tenancy__prisma_migrations_avg_fields';
  applied_steps_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tenancy._prisma_migrations". All fields are combined with a logical 'AND'. */
export type Tenancy__Prisma_Migrations_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy__Prisma_Migrations_Bool_Exp>>;
  _not?: InputMaybe<Tenancy__Prisma_Migrations_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy__Prisma_Migrations_Bool_Exp>>;
  applied_steps_count?: InputMaybe<Int_Comparison_Exp>;
  checksum?: InputMaybe<String_Comparison_Exp>;
  finished_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  logs?: InputMaybe<String_Comparison_Exp>;
  migration_name?: InputMaybe<String_Comparison_Exp>;
  rolled_back_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy._prisma_migrations" */
export enum Tenancy__Prisma_Migrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrismaMigrationsPkey = '_prisma_migrations_pkey'
}

/** input type for incrementing numeric columns in table "tenancy._prisma_migrations" */
export type Tenancy__Prisma_Migrations_Inc_Input = {
  applied_steps_count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tenancy._prisma_migrations" */
export type Tenancy__Prisma_Migrations_Insert_Input = {
  applied_steps_count?: InputMaybe<Scalars['Int']['input']>;
  checksum?: InputMaybe<Scalars['String']['input']>;
  finished_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  logs?: InputMaybe<Scalars['String']['input']>;
  migration_name?: InputMaybe<Scalars['String']['input']>;
  rolled_back_at?: InputMaybe<Scalars['timestamptz']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Tenancy__Prisma_Migrations_Max_Fields = {
  __typename?: 'tenancy__prisma_migrations_max_fields';
  applied_steps_count?: Maybe<Scalars['Int']['output']>;
  checksum?: Maybe<Scalars['String']['output']>;
  finished_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  logs?: Maybe<Scalars['String']['output']>;
  migration_name?: Maybe<Scalars['String']['output']>;
  rolled_back_at?: Maybe<Scalars['timestamptz']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Tenancy__Prisma_Migrations_Min_Fields = {
  __typename?: 'tenancy__prisma_migrations_min_fields';
  applied_steps_count?: Maybe<Scalars['Int']['output']>;
  checksum?: Maybe<Scalars['String']['output']>;
  finished_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  logs?: Maybe<Scalars['String']['output']>;
  migration_name?: Maybe<Scalars['String']['output']>;
  rolled_back_at?: Maybe<Scalars['timestamptz']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "tenancy._prisma_migrations" */
export type Tenancy__Prisma_Migrations_Mutation_Response = {
  __typename?: 'tenancy__prisma_migrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy__Prisma_Migrations>;
};

/** on_conflict condition type for table "tenancy._prisma_migrations" */
export type Tenancy__Prisma_Migrations_On_Conflict = {
  constraint: Tenancy__Prisma_Migrations_Constraint;
  update_columns?: Array<Tenancy__Prisma_Migrations_Update_Column>;
  where?: InputMaybe<Tenancy__Prisma_Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy._prisma_migrations". */
export type Tenancy__Prisma_Migrations_Order_By = {
  applied_steps_count?: InputMaybe<Order_By>;
  checksum?: InputMaybe<Order_By>;
  finished_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logs?: InputMaybe<Order_By>;
  migration_name?: InputMaybe<Order_By>;
  rolled_back_at?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy._prisma_migrations */
export type Tenancy__Prisma_Migrations_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy._prisma_migrations" */
export enum Tenancy__Prisma_Migrations_Select_Column {
  /** column name */
  AppliedStepsCount = 'applied_steps_count',
  /** column name */
  Checksum = 'checksum',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  Logs = 'logs',
  /** column name */
  MigrationName = 'migration_name',
  /** column name */
  RolledBackAt = 'rolled_back_at',
  /** column name */
  StartedAt = 'started_at'
}

/** input type for updating data in table "tenancy._prisma_migrations" */
export type Tenancy__Prisma_Migrations_Set_Input = {
  applied_steps_count?: InputMaybe<Scalars['Int']['input']>;
  checksum?: InputMaybe<Scalars['String']['input']>;
  finished_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  logs?: InputMaybe<Scalars['String']['input']>;
  migration_name?: InputMaybe<Scalars['String']['input']>;
  rolled_back_at?: InputMaybe<Scalars['timestamptz']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Tenancy__Prisma_Migrations_Stddev_Fields = {
  __typename?: 'tenancy__prisma_migrations_stddev_fields';
  applied_steps_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Tenancy__Prisma_Migrations_Stddev_Pop_Fields = {
  __typename?: 'tenancy__prisma_migrations_stddev_pop_fields';
  applied_steps_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Tenancy__Prisma_Migrations_Stddev_Samp_Fields = {
  __typename?: 'tenancy__prisma_migrations_stddev_samp_fields';
  applied_steps_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "tenancy__prisma_migrations" */
export type Tenancy__Prisma_Migrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy__Prisma_Migrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy__Prisma_Migrations_Stream_Cursor_Value_Input = {
  applied_steps_count?: InputMaybe<Scalars['Int']['input']>;
  checksum?: InputMaybe<Scalars['String']['input']>;
  finished_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  logs?: InputMaybe<Scalars['String']['input']>;
  migration_name?: InputMaybe<Scalars['String']['input']>;
  rolled_back_at?: InputMaybe<Scalars['timestamptz']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Tenancy__Prisma_Migrations_Sum_Fields = {
  __typename?: 'tenancy__prisma_migrations_sum_fields';
  applied_steps_count?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "tenancy._prisma_migrations" */
export enum Tenancy__Prisma_Migrations_Update_Column {
  /** column name */
  AppliedStepsCount = 'applied_steps_count',
  /** column name */
  Checksum = 'checksum',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  Logs = 'logs',
  /** column name */
  MigrationName = 'migration_name',
  /** column name */
  RolledBackAt = 'rolled_back_at',
  /** column name */
  StartedAt = 'started_at'
}

export type Tenancy__Prisma_Migrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tenancy__Prisma_Migrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy__Prisma_Migrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy__Prisma_Migrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tenancy__Prisma_Migrations_Var_Pop_Fields = {
  __typename?: 'tenancy__prisma_migrations_var_pop_fields';
  applied_steps_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Tenancy__Prisma_Migrations_Var_Samp_Fields = {
  __typename?: 'tenancy__prisma_migrations_var_samp_fields';
  applied_steps_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Tenancy__Prisma_Migrations_Variance_Fields = {
  __typename?: 'tenancy__prisma_migrations_variance_fields';
  applied_steps_count?: Maybe<Scalars['Float']['output']>;
};

/** remove? */
export type Tenancy__Role_Projects = {
  __typename?: 'tenancy__role_projects';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  /** An object relationship */
  project: Tenancy_Projects;
  /** An object relationship */
  role: Tenancy_Roles;
};

/** aggregated selection of "tenancy._role_projects" */
export type Tenancy__Role_Projects_Aggregate = {
  __typename?: 'tenancy__role_projects_aggregate';
  aggregate?: Maybe<Tenancy__Role_Projects_Aggregate_Fields>;
  nodes: Array<Tenancy__Role_Projects>;
};

export type Tenancy__Role_Projects_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy__Role_Projects_Aggregate_Bool_Exp_Count>;
};

export type Tenancy__Role_Projects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy__Role_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy._role_projects" */
export type Tenancy__Role_Projects_Aggregate_Fields = {
  __typename?: 'tenancy__role_projects_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy__Role_Projects_Max_Fields>;
  min?: Maybe<Tenancy__Role_Projects_Min_Fields>;
};


/** aggregate fields of "tenancy._role_projects" */
export type Tenancy__Role_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy__Role_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy._role_projects" */
export type Tenancy__Role_Projects_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy__Role_Projects_Max_Order_By>;
  min?: InputMaybe<Tenancy__Role_Projects_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy._role_projects" */
export type Tenancy__Role_Projects_Arr_Rel_Insert_Input = {
  data: Array<Tenancy__Role_Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy__Role_Projects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy._role_projects". All fields are combined with a logical 'AND'. */
export type Tenancy__Role_Projects_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Tenancy__Role_Projects_Bool_Exp>>;
  _not?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy__Role_Projects_Bool_Exp>>;
  project?: InputMaybe<Tenancy_Projects_Bool_Exp>;
  role?: InputMaybe<Tenancy_Roles_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy._role_projects" */
export enum Tenancy__Role_Projects_Constraint {
  /** unique or primary key constraint on columns "A", "B" */
  RoleProjectsAbUnique = '_role_projects_AB_unique'
}

/** input type for inserting data into table "tenancy._role_projects" */
export type Tenancy__Role_Projects_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Tenancy_Projects_Obj_Rel_Insert_Input>;
  role?: InputMaybe<Tenancy_Roles_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy__Role_Projects_Max_Fields = {
  __typename?: 'tenancy__role_projects_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy._role_projects" */
export type Tenancy__Role_Projects_Max_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy__Role_Projects_Min_Fields = {
  __typename?: 'tenancy__role_projects_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy._role_projects" */
export type Tenancy__Role_Projects_Min_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy._role_projects" */
export type Tenancy__Role_Projects_Mutation_Response = {
  __typename?: 'tenancy__role_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy__Role_Projects>;
};

/** on_conflict condition type for table "tenancy._role_projects" */
export type Tenancy__Role_Projects_On_Conflict = {
  constraint: Tenancy__Role_Projects_Constraint;
  update_columns?: Array<Tenancy__Role_Projects_Update_Column>;
  where?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy._role_projects". */
export type Tenancy__Role_Projects_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  project?: InputMaybe<Tenancy_Projects_Order_By>;
  role?: InputMaybe<Tenancy_Roles_Order_By>;
};

/** select columns of table "tenancy._role_projects" */
export enum Tenancy__Role_Projects_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "tenancy._role_projects" */
export type Tenancy__Role_Projects_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy__role_projects" */
export type Tenancy__Role_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy__Role_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy__Role_Projects_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy._role_projects" */
export enum Tenancy__Role_Projects_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Tenancy__Role_Projects_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy__Role_Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy__Role_Projects_Bool_Exp;
};

/** columns and relationships of "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles = {
  __typename?: 'tenancy__team_account_project_roles';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  /** An object relationship */
  role: Tenancy_Roles;
  /** An object relationship */
  team_account_project: Tenancy_Team_Account_Projects;
};

/** aggregated selection of "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_Aggregate = {
  __typename?: 'tenancy__team_account_project_roles_aggregate';
  aggregate?: Maybe<Tenancy__Team_Account_Project_Roles_Aggregate_Fields>;
  nodes: Array<Tenancy__Team_Account_Project_Roles>;
};

export type Tenancy__Team_Account_Project_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy__Team_Account_Project_Roles_Aggregate_Bool_Exp_Count>;
};

export type Tenancy__Team_Account_Project_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_Aggregate_Fields = {
  __typename?: 'tenancy__team_account_project_roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy__Team_Account_Project_Roles_Max_Fields>;
  min?: Maybe<Tenancy__Team_Account_Project_Roles_Min_Fields>;
};


/** aggregate fields of "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy__Team_Account_Project_Roles_Max_Order_By>;
  min?: InputMaybe<Tenancy__Team_Account_Project_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_Arr_Rel_Insert_Input = {
  data: Array<Tenancy__Team_Account_Project_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy__Team_Account_Project_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy._team_account_project_roles". All fields are combined with a logical 'AND'. */
export type Tenancy__Team_Account_Project_Roles_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Bool_Exp>>;
  _not?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Bool_Exp>>;
  role?: InputMaybe<Tenancy_Roles_Bool_Exp>;
  team_account_project?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy._team_account_project_roles" */
export enum Tenancy__Team_Account_Project_Roles_Constraint {
  /** unique or primary key constraint on columns "A", "B" */
  TeamAccountProjectRolesAbUnique = '_team_account_project_roles_AB_unique'
}

/** input type for inserting data into table "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Tenancy_Roles_Obj_Rel_Insert_Input>;
  team_account_project?: InputMaybe<Tenancy_Team_Account_Projects_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy__Team_Account_Project_Roles_Max_Fields = {
  __typename?: 'tenancy__team_account_project_roles_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_Max_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy__Team_Account_Project_Roles_Min_Fields = {
  __typename?: 'tenancy__team_account_project_roles_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_Min_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_Mutation_Response = {
  __typename?: 'tenancy__team_account_project_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy__Team_Account_Project_Roles>;
};

/** on_conflict condition type for table "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_On_Conflict = {
  constraint: Tenancy__Team_Account_Project_Roles_Constraint;
  update_columns?: Array<Tenancy__Team_Account_Project_Roles_Update_Column>;
  where?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy._team_account_project_roles". */
export type Tenancy__Team_Account_Project_Roles_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  role?: InputMaybe<Tenancy_Roles_Order_By>;
  team_account_project?: InputMaybe<Tenancy_Team_Account_Projects_Order_By>;
};

/** select columns of table "tenancy._team_account_project_roles" */
export enum Tenancy__Team_Account_Project_Roles_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "tenancy._team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy__team_account_project_roles" */
export type Tenancy__Team_Account_Project_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy__Team_Account_Project_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy__Team_Account_Project_Roles_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy._team_account_project_roles" */
export enum Tenancy__Team_Account_Project_Roles_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Tenancy__Team_Account_Project_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy__Team_Account_Project_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy__Team_Account_Project_Roles_Bool_Exp;
};

/** columns and relationships of "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles = {
  __typename?: 'tenancy__team_account_release_channel_config_roles';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  /** An object relationship */
  role: Tenancy_Roles;
  /** An object relationship */
  team_account_release_channel_config: Tenancy_Team_Account_Release_Channel_Config;
};

/** aggregated selection of "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate = {
  __typename?: 'tenancy__team_account_release_channel_config_roles_aggregate';
  aggregate?: Maybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate_Fields>;
  nodes: Array<Tenancy__Team_Account_Release_Channel_Config_Roles>;
};

export type Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate_Bool_Exp_Count>;
};

export type Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate_Fields = {
  __typename?: 'tenancy__team_account_release_channel_config_roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Max_Fields>;
  min?: Maybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Min_Fields>;
};


/** aggregate fields of "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Max_Order_By>;
  min?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Arr_Rel_Insert_Input = {
  data: Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy._team_account_release_channel_config_roles". All fields are combined with a logical 'AND'. */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>>;
  _not?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>>;
  role?: InputMaybe<Tenancy_Roles_Bool_Exp>;
  team_account_release_channel_config?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy._team_account_release_channel_config_roles" */
export enum Tenancy__Team_Account_Release_Channel_Config_Roles_Constraint {
  /** unique or primary key constraint on columns "A", "B" */
  TeamAccountReleaseChannelConfigRolesAbUnique = '_team_account_release_channel_config_roles_AB_unique'
}

/** input type for inserting data into table "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Tenancy_Roles_Obj_Rel_Insert_Input>;
  team_account_release_channel_config?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Max_Fields = {
  __typename?: 'tenancy__team_account_release_channel_config_roles_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Max_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Min_Fields = {
  __typename?: 'tenancy__team_account_release_channel_config_roles_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Min_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Mutation_Response = {
  __typename?: 'tenancy__team_account_release_channel_config_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy__Team_Account_Release_Channel_Config_Roles>;
};

/** on_conflict condition type for table "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_On_Conflict = {
  constraint: Tenancy__Team_Account_Release_Channel_Config_Roles_Constraint;
  update_columns?: Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Update_Column>;
  where?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy._team_account_release_channel_config_roles". */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  role?: InputMaybe<Tenancy_Roles_Order_By>;
  team_account_release_channel_config?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Order_By>;
};

/** select columns of table "tenancy._team_account_release_channel_config_roles" */
export enum Tenancy__Team_Account_Release_Channel_Config_Roles_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "tenancy._team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy__team_account_release_channel_config_roles" */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy__Team_Account_Release_Channel_Config_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy__Team_Account_Release_Channel_Config_Roles_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy._team_account_release_channel_config_roles" */
export enum Tenancy__Team_Account_Release_Channel_Config_Roles_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Tenancy__Team_Account_Release_Channel_Config_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp;
};

/** columns and relationships of "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles = {
  __typename?: 'tenancy__team_account_roles';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  /** An object relationship */
  role: Tenancy_Roles;
  /** An object relationship */
  team_account: Tenancy_Team_Accounts;
};

/** aggregated selection of "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles_Aggregate = {
  __typename?: 'tenancy__team_account_roles_aggregate';
  aggregate?: Maybe<Tenancy__Team_Account_Roles_Aggregate_Fields>;
  nodes: Array<Tenancy__Team_Account_Roles>;
};

export type Tenancy__Team_Account_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy__Team_Account_Roles_Aggregate_Bool_Exp_Count>;
};

export type Tenancy__Team_Account_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy__Team_Account_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles_Aggregate_Fields = {
  __typename?: 'tenancy__team_account_roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy__Team_Account_Roles_Max_Fields>;
  min?: Maybe<Tenancy__Team_Account_Roles_Min_Fields>;
};


/** aggregate fields of "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy__Team_Account_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy__Team_Account_Roles_Max_Order_By>;
  min?: InputMaybe<Tenancy__Team_Account_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles_Arr_Rel_Insert_Input = {
  data: Array<Tenancy__Team_Account_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy__Team_Account_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy._team_account_roles". All fields are combined with a logical 'AND'. */
export type Tenancy__Team_Account_Roles_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Tenancy__Team_Account_Roles_Bool_Exp>>;
  _not?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy__Team_Account_Roles_Bool_Exp>>;
  role?: InputMaybe<Tenancy_Roles_Bool_Exp>;
  team_account?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy._team_account_roles" */
export enum Tenancy__Team_Account_Roles_Constraint {
  /** unique or primary key constraint on columns "A", "B" */
  TeamAccountRolesAbUnique = '_team_account_roles_AB_unique'
}

/** input type for inserting data into table "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Tenancy_Roles_Obj_Rel_Insert_Input>;
  team_account?: InputMaybe<Tenancy_Team_Accounts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy__Team_Account_Roles_Max_Fields = {
  __typename?: 'tenancy__team_account_roles_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles_Max_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy__Team_Account_Roles_Min_Fields = {
  __typename?: 'tenancy__team_account_roles_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles_Min_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles_Mutation_Response = {
  __typename?: 'tenancy__team_account_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy__Team_Account_Roles>;
};

/** on_conflict condition type for table "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles_On_Conflict = {
  constraint: Tenancy__Team_Account_Roles_Constraint;
  update_columns?: Array<Tenancy__Team_Account_Roles_Update_Column>;
  where?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy._team_account_roles". */
export type Tenancy__Team_Account_Roles_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  role?: InputMaybe<Tenancy_Roles_Order_By>;
  team_account?: InputMaybe<Tenancy_Team_Accounts_Order_By>;
};

/** select columns of table "tenancy._team_account_roles" */
export enum Tenancy__Team_Account_Roles_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "tenancy._team_account_roles" */
export type Tenancy__Team_Account_Roles_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy__team_account_roles" */
export type Tenancy__Team_Account_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy__Team_Account_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy__Team_Account_Roles_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy._team_account_roles" */
export enum Tenancy__Team_Account_Roles_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Tenancy__Team_Account_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy__Team_Account_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy__Team_Account_Roles_Bool_Exp;
};

/** columns and relationships of "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles = {
  __typename?: 'tenancy__user_account_project_roles';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  /** An object relationship */
  role: Tenancy_Roles;
  /** An object relationship */
  user_account_project: Tenancy_User_Account_Projects;
};

/** aggregated selection of "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_Aggregate = {
  __typename?: 'tenancy__user_account_project_roles_aggregate';
  aggregate?: Maybe<Tenancy__User_Account_Project_Roles_Aggregate_Fields>;
  nodes: Array<Tenancy__User_Account_Project_Roles>;
};

export type Tenancy__User_Account_Project_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy__User_Account_Project_Roles_Aggregate_Bool_Exp_Count>;
};

export type Tenancy__User_Account_Project_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_Aggregate_Fields = {
  __typename?: 'tenancy__user_account_project_roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy__User_Account_Project_Roles_Max_Fields>;
  min?: Maybe<Tenancy__User_Account_Project_Roles_Min_Fields>;
};


/** aggregate fields of "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy__User_Account_Project_Roles_Max_Order_By>;
  min?: InputMaybe<Tenancy__User_Account_Project_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_Arr_Rel_Insert_Input = {
  data: Array<Tenancy__User_Account_Project_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy__User_Account_Project_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy._user_account_project_roles". All fields are combined with a logical 'AND'. */
export type Tenancy__User_Account_Project_Roles_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Bool_Exp>>;
  _not?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Bool_Exp>>;
  role?: InputMaybe<Tenancy_Roles_Bool_Exp>;
  user_account_project?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy._user_account_project_roles" */
export enum Tenancy__User_Account_Project_Roles_Constraint {
  /** unique or primary key constraint on columns "A", "B" */
  UserAccountProjectRolesAbUnique = '_user_account_project_roles_AB_unique'
}

/** input type for inserting data into table "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Tenancy_Roles_Obj_Rel_Insert_Input>;
  user_account_project?: InputMaybe<Tenancy_User_Account_Projects_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy__User_Account_Project_Roles_Max_Fields = {
  __typename?: 'tenancy__user_account_project_roles_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_Max_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy__User_Account_Project_Roles_Min_Fields = {
  __typename?: 'tenancy__user_account_project_roles_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_Min_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_Mutation_Response = {
  __typename?: 'tenancy__user_account_project_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy__User_Account_Project_Roles>;
};

/** on_conflict condition type for table "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_On_Conflict = {
  constraint: Tenancy__User_Account_Project_Roles_Constraint;
  update_columns?: Array<Tenancy__User_Account_Project_Roles_Update_Column>;
  where?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy._user_account_project_roles". */
export type Tenancy__User_Account_Project_Roles_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  role?: InputMaybe<Tenancy_Roles_Order_By>;
  user_account_project?: InputMaybe<Tenancy_User_Account_Projects_Order_By>;
};

/** select columns of table "tenancy._user_account_project_roles" */
export enum Tenancy__User_Account_Project_Roles_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "tenancy._user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy__user_account_project_roles" */
export type Tenancy__User_Account_Project_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy__User_Account_Project_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy__User_Account_Project_Roles_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy._user_account_project_roles" */
export enum Tenancy__User_Account_Project_Roles_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Tenancy__User_Account_Project_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy__User_Account_Project_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy__User_Account_Project_Roles_Bool_Exp;
};

/** columns and relationships of "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles = {
  __typename?: 'tenancy__user_account_release_channel_config_roles';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  /** An object relationship */
  role: Tenancy_Roles;
  /** An object relationship */
  user_account_release_channel_config: Tenancy_User_Account_Release_Channel_Config;
};

/** aggregated selection of "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate = {
  __typename?: 'tenancy__user_account_release_channel_config_roles_aggregate';
  aggregate?: Maybe<Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate_Fields>;
  nodes: Array<Tenancy__User_Account_Release_Channel_Config_Roles>;
};

export type Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate_Bool_Exp_Count>;
};

export type Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate_Fields = {
  __typename?: 'tenancy__user_account_release_channel_config_roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy__User_Account_Release_Channel_Config_Roles_Max_Fields>;
  min?: Maybe<Tenancy__User_Account_Release_Channel_Config_Roles_Min_Fields>;
};


/** aggregate fields of "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Max_Order_By>;
  min?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Arr_Rel_Insert_Input = {
  data: Array<Tenancy__User_Account_Release_Channel_Config_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy._user_account_release_channel_config_roles". All fields are combined with a logical 'AND'. */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>>;
  _not?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>>;
  role?: InputMaybe<Tenancy_Roles_Bool_Exp>;
  user_account_release_channel_config?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy._user_account_release_channel_config_roles" */
export enum Tenancy__User_Account_Release_Channel_Config_Roles_Constraint {
  /** unique or primary key constraint on columns "A", "B" */
  UserAccountReleaseChannelConfigRolesAbUnique = '_user_account_release_channel_config_roles_AB_unique'
}

/** input type for inserting data into table "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Tenancy_Roles_Obj_Rel_Insert_Input>;
  user_account_release_channel_config?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Max_Fields = {
  __typename?: 'tenancy__user_account_release_channel_config_roles_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Max_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Min_Fields = {
  __typename?: 'tenancy__user_account_release_channel_config_roles_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Min_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Mutation_Response = {
  __typename?: 'tenancy__user_account_release_channel_config_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy__User_Account_Release_Channel_Config_Roles>;
};

/** on_conflict condition type for table "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_On_Conflict = {
  constraint: Tenancy__User_Account_Release_Channel_Config_Roles_Constraint;
  update_columns?: Array<Tenancy__User_Account_Release_Channel_Config_Roles_Update_Column>;
  where?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy._user_account_release_channel_config_roles". */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  role?: InputMaybe<Tenancy_Roles_Order_By>;
  user_account_release_channel_config?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Order_By>;
};

/** select columns of table "tenancy._user_account_release_channel_config_roles" */
export enum Tenancy__User_Account_Release_Channel_Config_Roles_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "tenancy._user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy__user_account_release_channel_config_roles" */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy__User_Account_Release_Channel_Config_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy__User_Account_Release_Channel_Config_Roles_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy._user_account_release_channel_config_roles" */
export enum Tenancy__User_Account_Release_Channel_Config_Roles_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Tenancy__User_Account_Release_Channel_Config_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp;
};

/** columns and relationships of "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles = {
  __typename?: 'tenancy__user_account_roles';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  /** An object relationship */
  role: Tenancy_Roles;
  /** An object relationship */
  user_account: Tenancy_User_Accounts;
};

/** aggregated selection of "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles_Aggregate = {
  __typename?: 'tenancy__user_account_roles_aggregate';
  aggregate?: Maybe<Tenancy__User_Account_Roles_Aggregate_Fields>;
  nodes: Array<Tenancy__User_Account_Roles>;
};

export type Tenancy__User_Account_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy__User_Account_Roles_Aggregate_Bool_Exp_Count>;
};

export type Tenancy__User_Account_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy__User_Account_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles_Aggregate_Fields = {
  __typename?: 'tenancy__user_account_roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy__User_Account_Roles_Max_Fields>;
  min?: Maybe<Tenancy__User_Account_Roles_Min_Fields>;
};


/** aggregate fields of "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy__User_Account_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy__User_Account_Roles_Max_Order_By>;
  min?: InputMaybe<Tenancy__User_Account_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles_Arr_Rel_Insert_Input = {
  data: Array<Tenancy__User_Account_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy__User_Account_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy._user_account_roles". All fields are combined with a logical 'AND'. */
export type Tenancy__User_Account_Roles_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Tenancy__User_Account_Roles_Bool_Exp>>;
  _not?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy__User_Account_Roles_Bool_Exp>>;
  role?: InputMaybe<Tenancy_Roles_Bool_Exp>;
  user_account?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy._user_account_roles" */
export enum Tenancy__User_Account_Roles_Constraint {
  /** unique or primary key constraint on columns "A", "B" */
  UserAccountRolesAbUnique = '_user_account_roles_AB_unique'
}

/** input type for inserting data into table "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Tenancy_Roles_Obj_Rel_Insert_Input>;
  user_account?: InputMaybe<Tenancy_User_Accounts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy__User_Account_Roles_Max_Fields = {
  __typename?: 'tenancy__user_account_roles_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles_Max_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy__User_Account_Roles_Min_Fields = {
  __typename?: 'tenancy__user_account_roles_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles_Min_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles_Mutation_Response = {
  __typename?: 'tenancy__user_account_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy__User_Account_Roles>;
};

/** on_conflict condition type for table "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles_On_Conflict = {
  constraint: Tenancy__User_Account_Roles_Constraint;
  update_columns?: Array<Tenancy__User_Account_Roles_Update_Column>;
  where?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy._user_account_roles". */
export type Tenancy__User_Account_Roles_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  role?: InputMaybe<Tenancy_Roles_Order_By>;
  user_account?: InputMaybe<Tenancy_User_Accounts_Order_By>;
};

/** select columns of table "tenancy._user_account_roles" */
export enum Tenancy__User_Account_Roles_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "tenancy._user_account_roles" */
export type Tenancy__User_Account_Roles_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy__user_account_roles" */
export type Tenancy__User_Account_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy__User_Account_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy__User_Account_Roles_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy._user_account_roles" */
export enum Tenancy__User_Account_Roles_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Tenancy__User_Account_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy__User_Account_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy__User_Account_Roles_Bool_Exp;
};

/** columns and relationships of "tenancy._user_teams" */
export type Tenancy__User_Teams = {
  __typename?: 'tenancy__user_teams';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  /** An object relationship */
  team: Tenancy_Teams;
  /** An object relationship */
  user: Tenancy_Users;
};

/** aggregated selection of "tenancy._user_teams" */
export type Tenancy__User_Teams_Aggregate = {
  __typename?: 'tenancy__user_teams_aggregate';
  aggregate?: Maybe<Tenancy__User_Teams_Aggregate_Fields>;
  nodes: Array<Tenancy__User_Teams>;
};

export type Tenancy__User_Teams_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy__User_Teams_Aggregate_Bool_Exp_Count>;
};

export type Tenancy__User_Teams_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy__User_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy._user_teams" */
export type Tenancy__User_Teams_Aggregate_Fields = {
  __typename?: 'tenancy__user_teams_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy__User_Teams_Max_Fields>;
  min?: Maybe<Tenancy__User_Teams_Min_Fields>;
};


/** aggregate fields of "tenancy._user_teams" */
export type Tenancy__User_Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy__User_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy._user_teams" */
export type Tenancy__User_Teams_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy__User_Teams_Max_Order_By>;
  min?: InputMaybe<Tenancy__User_Teams_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy._user_teams" */
export type Tenancy__User_Teams_Arr_Rel_Insert_Input = {
  data: Array<Tenancy__User_Teams_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy__User_Teams_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy._user_teams". All fields are combined with a logical 'AND'. */
export type Tenancy__User_Teams_Bool_Exp = {
  A?: InputMaybe<String_Comparison_Exp>;
  B?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Tenancy__User_Teams_Bool_Exp>>;
  _not?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy__User_Teams_Bool_Exp>>;
  team?: InputMaybe<Tenancy_Teams_Bool_Exp>;
  user?: InputMaybe<Tenancy_Users_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy._user_teams" */
export enum Tenancy__User_Teams_Constraint {
  /** unique or primary key constraint on columns "A", "B" */
  UserTeamsAbUnique = '_user_teams_AB_unique'
}

/** input type for inserting data into table "tenancy._user_teams" */
export type Tenancy__User_Teams_Insert_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Tenancy_Teams_Obj_Rel_Insert_Input>;
  user?: InputMaybe<Tenancy_Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy__User_Teams_Max_Fields = {
  __typename?: 'tenancy__user_teams_max_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy._user_teams" */
export type Tenancy__User_Teams_Max_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy__User_Teams_Min_Fields = {
  __typename?: 'tenancy__user_teams_min_fields';
  A?: Maybe<Scalars['String']['output']>;
  B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy._user_teams" */
export type Tenancy__User_Teams_Min_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy._user_teams" */
export type Tenancy__User_Teams_Mutation_Response = {
  __typename?: 'tenancy__user_teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy__User_Teams>;
};

/** on_conflict condition type for table "tenancy._user_teams" */
export type Tenancy__User_Teams_On_Conflict = {
  constraint: Tenancy__User_Teams_Constraint;
  update_columns?: Array<Tenancy__User_Teams_Update_Column>;
  where?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy._user_teams". */
export type Tenancy__User_Teams_Order_By = {
  A?: InputMaybe<Order_By>;
  B?: InputMaybe<Order_By>;
  team?: InputMaybe<Tenancy_Teams_Order_By>;
  user?: InputMaybe<Tenancy_Users_Order_By>;
};

/** select columns of table "tenancy._user_teams" */
export enum Tenancy__User_Teams_Select_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

/** input type for updating data in table "tenancy._user_teams" */
export type Tenancy__User_Teams_Set_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy__user_teams" */
export type Tenancy__User_Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy__User_Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy__User_Teams_Stream_Cursor_Value_Input = {
  A?: InputMaybe<Scalars['String']['input']>;
  B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy._user_teams" */
export enum Tenancy__User_Teams_Update_Column {
  /** column name */
  A = 'A',
  /** column name */
  B = 'B'
}

export type Tenancy__User_Teams_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy__User_Teams_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy__User_Teams_Bool_Exp;
};

/** columns and relationships of "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs = {
  __typename?: 'tenancy_account_scheduled_job_runs';
  accountScheduledJobId: Scalars['String']['output'];
  /** An object relationship */
  account_scheduled_job: Tenancy_Account_Scheduled_Jobs;
  completedAt?: Maybe<Scalars['timestamp']['output']>;
  createdAt: Scalars['timestamp']['output'];
  executionDetails?: Maybe<Scalars['jsonb']['output']>;
  hasActivity: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  startedAt?: Maybe<Scalars['timestamp']['output']>;
  succeeded: Scalars['Boolean']['output'];
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_RunsExecutionDetailsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_Aggregate = {
  __typename?: 'tenancy_account_scheduled_job_runs_aggregate';
  aggregate?: Maybe<Tenancy_Account_Scheduled_Job_Runs_Aggregate_Fields>;
  nodes: Array<Tenancy_Account_Scheduled_Job_Runs>;
};

export type Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenancy_Account_Scheduled_Job_Runs_Select_Column_Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenancy_Account_Scheduled_Job_Runs_Select_Column_Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_Aggregate_Fields = {
  __typename?: 'tenancy_account_scheduled_job_runs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Account_Scheduled_Job_Runs_Max_Fields>;
  min?: Maybe<Tenancy_Account_Scheduled_Job_Runs_Min_Fields>;
};


/** aggregate fields of "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Max_Order_By>;
  min?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Account_Scheduled_Job_Runs_Append_Input = {
  executionDetails?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Account_Scheduled_Job_Runs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.account_scheduled_job_runs". All fields are combined with a logical 'AND'. */
export type Tenancy_Account_Scheduled_Job_Runs_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>>;
  accountScheduledJobId?: InputMaybe<String_Comparison_Exp>;
  account_scheduled_job?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
  completedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  executionDetails?: InputMaybe<Jsonb_Comparison_Exp>;
  hasActivity?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  startedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  succeeded?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.account_scheduled_job_runs" */
export enum Tenancy_Account_Scheduled_Job_Runs_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountScheduledJobRunsPkey = 'account_scheduled_job_runs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Account_Scheduled_Job_Runs_Delete_At_Path_Input = {
  executionDetails?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Account_Scheduled_Job_Runs_Delete_Elem_Input = {
  executionDetails?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Account_Scheduled_Job_Runs_Delete_Key_Input = {
  executionDetails?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_Insert_Input = {
  accountScheduledJobId?: InputMaybe<Scalars['String']['input']>;
  account_scheduled_job?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Obj_Rel_Insert_Input>;
  completedAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  executionDetails?: InputMaybe<Scalars['jsonb']['input']>;
  hasActivity?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['timestamp']['input']>;
  succeeded?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Account_Scheduled_Job_Runs_Max_Fields = {
  __typename?: 'tenancy_account_scheduled_job_runs_max_fields';
  accountScheduledJobId?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['timestamp']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_Max_Order_By = {
  accountScheduledJobId?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Account_Scheduled_Job_Runs_Min_Fields = {
  __typename?: 'tenancy_account_scheduled_job_runs_min_fields';
  accountScheduledJobId?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['timestamp']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_Min_Order_By = {
  accountScheduledJobId?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_Mutation_Response = {
  __typename?: 'tenancy_account_scheduled_job_runs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Account_Scheduled_Job_Runs>;
};

/** on_conflict condition type for table "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_On_Conflict = {
  constraint: Tenancy_Account_Scheduled_Job_Runs_Constraint;
  update_columns?: Array<Tenancy_Account_Scheduled_Job_Runs_Update_Column>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.account_scheduled_job_runs". */
export type Tenancy_Account_Scheduled_Job_Runs_Order_By = {
  accountScheduledJobId?: InputMaybe<Order_By>;
  account_scheduled_job?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  executionDetails?: InputMaybe<Order_By>;
  hasActivity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  succeeded?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.account_scheduled_job_runs */
export type Tenancy_Account_Scheduled_Job_Runs_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Account_Scheduled_Job_Runs_Prepend_Input = {
  executionDetails?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.account_scheduled_job_runs" */
export enum Tenancy_Account_Scheduled_Job_Runs_Select_Column {
  /** column name */
  AccountScheduledJobId = 'accountScheduledJobId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExecutionDetails = 'executionDetails',
  /** column name */
  HasActivity = 'hasActivity',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  Succeeded = 'succeeded',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "tenancy_account_scheduled_job_runs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenancy.account_scheduled_job_runs" */
export enum Tenancy_Account_Scheduled_Job_Runs_Select_Column_Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasActivity = 'hasActivity',
  /** column name */
  Succeeded = 'succeeded'
}

/** select "tenancy_account_scheduled_job_runs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenancy.account_scheduled_job_runs" */
export enum Tenancy_Account_Scheduled_Job_Runs_Select_Column_Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasActivity = 'hasActivity',
  /** column name */
  Succeeded = 'succeeded'
}

/** input type for updating data in table "tenancy.account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_Set_Input = {
  accountScheduledJobId?: InputMaybe<Scalars['String']['input']>;
  completedAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  executionDetails?: InputMaybe<Scalars['jsonb']['input']>;
  hasActivity?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['timestamp']['input']>;
  succeeded?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "tenancy_account_scheduled_job_runs" */
export type Tenancy_Account_Scheduled_Job_Runs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Account_Scheduled_Job_Runs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Account_Scheduled_Job_Runs_Stream_Cursor_Value_Input = {
  accountScheduledJobId?: InputMaybe<Scalars['String']['input']>;
  completedAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  executionDetails?: InputMaybe<Scalars['jsonb']['input']>;
  hasActivity?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['timestamp']['input']>;
  succeeded?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "tenancy.account_scheduled_job_runs" */
export enum Tenancy_Account_Scheduled_Job_Runs_Update_Column {
  /** column name */
  AccountScheduledJobId = 'accountScheduledJobId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExecutionDetails = 'executionDetails',
  /** column name */
  HasActivity = 'hasActivity',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  Succeeded = 'succeeded',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Tenancy_Account_Scheduled_Job_Runs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Account_Scheduled_Job_Runs_Bool_Exp;
};

/** columns and relationships of "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs = {
  __typename?: 'tenancy_account_scheduled_jobs';
  /** An object relationship */
  account: Tenancy_Accounts;
  accountId: Scalars['String']['output'];
  /** An array relationship */
  account_scheduled_job_runs: Array<Tenancy_Account_Scheduled_Job_Runs>;
  /** An aggregate relationship */
  account_scheduled_job_runs_aggregate: Tenancy_Account_Scheduled_Job_Runs_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  isPaused: Scalars['Boolean']['output'];
  lastRanAt?: Maybe<Scalars['timestamp']['output']>;
  scheduledJobId: Scalars['String']['output'];
  /** An object relationship */
  scheduled_job: Tenancy_Scheduled_Jobs;
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_JobsAccount_Scheduled_Job_RunsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
};


/** columns and relationships of "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_JobsAccount_Scheduled_Job_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Job_Runs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
};

/** aggregated selection of "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Aggregate = {
  __typename?: 'tenancy_account_scheduled_jobs_aggregate';
  aggregate?: Maybe<Tenancy_Account_Scheduled_Jobs_Aggregate_Fields>;
  nodes: Array<Tenancy_Account_Scheduled_Jobs>;
};

export type Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenancy_Account_Scheduled_Jobs_Select_Column_Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenancy_Account_Scheduled_Jobs_Select_Column_Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Aggregate_Fields = {
  __typename?: 'tenancy_account_scheduled_jobs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Account_Scheduled_Jobs_Max_Fields>;
  min?: Maybe<Tenancy_Account_Scheduled_Jobs_Min_Fields>;
};


/** aggregate fields of "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Max_Order_By>;
  min?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Account_Scheduled_Jobs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Account_Scheduled_Jobs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.account_scheduled_jobs". All fields are combined with a logical 'AND'. */
export type Tenancy_Account_Scheduled_Jobs_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Bool_Exp>>;
  account?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  account_scheduled_job_runs?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Bool_Exp>;
  account_scheduled_job_runs_aggregate?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isPaused?: InputMaybe<Boolean_Comparison_Exp>;
  lastRanAt?: InputMaybe<Timestamp_Comparison_Exp>;
  scheduledJobId?: InputMaybe<String_Comparison_Exp>;
  scheduled_job?: InputMaybe<Tenancy_Scheduled_Jobs_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.account_scheduled_jobs" */
export enum Tenancy_Account_Scheduled_Jobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountScheduledJobsPkey = 'account_scheduled_jobs_pkey',
  /** unique or primary key constraint on columns "scheduledJobId", "accountId" */
  AccountScheduledJobsScheduledJobIdAccountIdKey = 'account_scheduled_jobs_scheduledJobId_accountId_key'
}

/** input type for inserting data into table "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Insert_Input = {
  account?: InputMaybe<Tenancy_Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']['input']>;
  account_scheduled_job_runs?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPaused?: InputMaybe<Scalars['Boolean']['input']>;
  lastRanAt?: InputMaybe<Scalars['timestamp']['input']>;
  scheduledJobId?: InputMaybe<Scalars['String']['input']>;
  scheduled_job?: InputMaybe<Tenancy_Scheduled_Jobs_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Account_Scheduled_Jobs_Max_Fields = {
  __typename?: 'tenancy_account_scheduled_jobs_max_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastRanAt?: Maybe<Scalars['timestamp']['output']>;
  scheduledJobId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastRanAt?: InputMaybe<Order_By>;
  scheduledJobId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Account_Scheduled_Jobs_Min_Fields = {
  __typename?: 'tenancy_account_scheduled_jobs_min_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastRanAt?: Maybe<Scalars['timestamp']['output']>;
  scheduledJobId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastRanAt?: InputMaybe<Order_By>;
  scheduledJobId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Mutation_Response = {
  __typename?: 'tenancy_account_scheduled_jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Account_Scheduled_Jobs>;
};

/** input type for inserting object relation for remote table "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Obj_Rel_Insert_Input = {
  data: Tenancy_Account_Scheduled_Jobs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Account_Scheduled_Jobs_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_On_Conflict = {
  constraint: Tenancy_Account_Scheduled_Jobs_Constraint;
  update_columns?: Array<Tenancy_Account_Scheduled_Jobs_Update_Column>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.account_scheduled_jobs". */
export type Tenancy_Account_Scheduled_Jobs_Order_By = {
  account?: InputMaybe<Tenancy_Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  account_scheduled_job_runs_aggregate?: InputMaybe<Tenancy_Account_Scheduled_Job_Runs_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isPaused?: InputMaybe<Order_By>;
  lastRanAt?: InputMaybe<Order_By>;
  scheduledJobId?: InputMaybe<Order_By>;
  scheduled_job?: InputMaybe<Tenancy_Scheduled_Jobs_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.account_scheduled_jobs */
export type Tenancy_Account_Scheduled_Jobs_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.account_scheduled_jobs" */
export enum Tenancy_Account_Scheduled_Jobs_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPaused = 'isPaused',
  /** column name */
  LastRanAt = 'lastRanAt',
  /** column name */
  ScheduledJobId = 'scheduledJobId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "tenancy_account_scheduled_jobs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenancy.account_scheduled_jobs" */
export enum Tenancy_Account_Scheduled_Jobs_Select_Column_Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPaused = 'isPaused'
}

/** select "tenancy_account_scheduled_jobs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenancy.account_scheduled_jobs" */
export enum Tenancy_Account_Scheduled_Jobs_Select_Column_Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPaused = 'isPaused'
}

/** input type for updating data in table "tenancy.account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Set_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPaused?: InputMaybe<Scalars['Boolean']['input']>;
  lastRanAt?: InputMaybe<Scalars['timestamp']['input']>;
  scheduledJobId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "tenancy_account_scheduled_jobs" */
export type Tenancy_Account_Scheduled_Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Account_Scheduled_Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Account_Scheduled_Jobs_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPaused?: InputMaybe<Scalars['Boolean']['input']>;
  lastRanAt?: InputMaybe<Scalars['timestamp']['input']>;
  scheduledJobId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "tenancy.account_scheduled_jobs" */
export enum Tenancy_Account_Scheduled_Jobs_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPaused = 'isPaused',
  /** column name */
  LastRanAt = 'lastRanAt',
  /** column name */
  ScheduledJobId = 'scheduledJobId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Tenancy_Account_Scheduled_Jobs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Account_Scheduled_Jobs_Bool_Exp;
};

/** columns and relationships of "tenancy.accounts" */
export type Tenancy_Accounts = {
  __typename?: 'tenancy_accounts';
  /** An object relationship */
  account?: Maybe<Tenancy_Accounts>;
  accountManagerId?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  account_scheduled_jobs: Array<Tenancy_Account_Scheduled_Jobs>;
  /** An aggregate relationship */
  account_scheduled_jobs_aggregate: Tenancy_Account_Scheduled_Jobs_Aggregate;
  /** An array relationship */
  accounts: Array<Tenancy_Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Tenancy_Accounts_Aggregate;
  allParentIds?: Maybe<Array<Scalars['String']['output']>>;
  /** An array relationship */
  audit_logs: Array<Tenancy_Audit_Logs>;
  /** An aggregate relationship */
  audit_logs_aggregate: Tenancy_Audit_Logs_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  /** An array relationship */
  files: Array<Tenancy_File>;
  /** An aggregate relationship */
  files_aggregate: Tenancy_File_Aggregate;
  id: Scalars['String']['output'];
  isManaged: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  projects: Array<Tenancy_Projects>;
  /** An aggregate relationship */
  projects_aggregate: Tenancy_Projects_Aggregate;
  /** An array relationship */
  roles: Array<Tenancy_Roles>;
  /** An aggregate relationship */
  roles_aggregate: Tenancy_Roles_Aggregate;
  settings?: Maybe<Scalars['jsonb']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  /** An array relationship */
  team_accounts: Array<Tenancy_Team_Accounts>;
  /** An aggregate relationship */
  team_accounts_aggregate: Tenancy_Team_Accounts_Aggregate;
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
  /** An object relationship */
  user?: Maybe<Tenancy_Users>;
  /** An array relationship */
  user_accounts: Array<Tenancy_User_Accounts>;
  /** An aggregate relationship */
  user_accounts_aggregate: Tenancy_User_Accounts_Aggregate;
  /** An array relationship */
  variables: Array<Tenancy_Variable>;
  /** An aggregate relationship */
  variables_aggregate: Tenancy_Variable_Aggregate;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsAccount_Scheduled_JobsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsAccount_Scheduled_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsAccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsAudit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Audit_Logs_Order_By>>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsAudit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Audit_Logs_Order_By>>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsFilesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsRolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Roles_Order_By>>;
  where?: InputMaybe<Tenancy_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Roles_Order_By>>;
  where?: InputMaybe<Tenancy_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsTeam_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsTeam_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsUser_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsUser_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsVariablesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Variable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Variable_Order_By>>;
  where?: InputMaybe<Tenancy_Variable_Bool_Exp>;
};


/** columns and relationships of "tenancy.accounts" */
export type Tenancy_AccountsVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Variable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Variable_Order_By>>;
  where?: InputMaybe<Tenancy_Variable_Bool_Exp>;
};

/** aggregated selection of "tenancy.accounts" */
export type Tenancy_Accounts_Aggregate = {
  __typename?: 'tenancy_accounts_aggregate';
  aggregate?: Maybe<Tenancy_Accounts_Aggregate_Fields>;
  nodes: Array<Tenancy_Accounts>;
};

export type Tenancy_Accounts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tenancy_Accounts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tenancy_Accounts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tenancy_Accounts_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Accounts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenancy_Accounts_Select_Column_Tenancy_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_Accounts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenancy_Accounts_Select_Column_Tenancy_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.accounts" */
export type Tenancy_Accounts_Aggregate_Fields = {
  __typename?: 'tenancy_accounts_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Accounts_Max_Fields>;
  min?: Maybe<Tenancy_Accounts_Min_Fields>;
};


/** aggregate fields of "tenancy.accounts" */
export type Tenancy_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.accounts" */
export type Tenancy_Accounts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Accounts_Max_Order_By>;
  min?: InputMaybe<Tenancy_Accounts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Accounts_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.accounts" */
export type Tenancy_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.accounts". All fields are combined with a logical 'AND'. */
export type Tenancy_Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Accounts_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Accounts_Bool_Exp>>;
  account?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  accountManagerId?: InputMaybe<String_Comparison_Exp>;
  account_scheduled_jobs?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
  account_scheduled_jobs_aggregate?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp>;
  accounts?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Tenancy_Accounts_Aggregate_Bool_Exp>;
  allParentIds?: InputMaybe<String_Array_Comparison_Exp>;
  audit_logs?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
  audit_logs_aggregate?: InputMaybe<Tenancy_Audit_Logs_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  files?: InputMaybe<Tenancy_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Tenancy_File_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isManaged?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parentId?: InputMaybe<String_Comparison_Exp>;
  picture?: InputMaybe<String_Comparison_Exp>;
  projects?: InputMaybe<Tenancy_Projects_Bool_Exp>;
  projects_aggregate?: InputMaybe<Tenancy_Projects_Aggregate_Bool_Exp>;
  roles?: InputMaybe<Tenancy_Roles_Bool_Exp>;
  roles_aggregate?: InputMaybe<Tenancy_Roles_Aggregate_Bool_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  shortId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  team_accounts?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
  team_accounts_aggregate?: InputMaybe<Tenancy_Team_Accounts_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Tenancy_Users_Bool_Exp>;
  user_accounts?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
  user_accounts_aggregate?: InputMaybe<Tenancy_User_Accounts_Aggregate_Bool_Exp>;
  variables?: InputMaybe<Tenancy_Variable_Bool_Exp>;
  variables_aggregate?: InputMaybe<Tenancy_Variable_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy.accounts" */
export enum Tenancy_Accounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Accounts_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Accounts_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Accounts_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.accounts" */
export type Tenancy_Accounts_Insert_Input = {
  account?: InputMaybe<Tenancy_Accounts_Obj_Rel_Insert_Input>;
  accountManagerId?: InputMaybe<Scalars['String']['input']>;
  account_scheduled_jobs?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Arr_Rel_Insert_Input>;
  accounts?: InputMaybe<Tenancy_Accounts_Arr_Rel_Insert_Input>;
  allParentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  audit_logs?: InputMaybe<Tenancy_Audit_Logs_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Tenancy_File_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManaged?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<Tenancy_Projects_Arr_Rel_Insert_Input>;
  roles?: InputMaybe<Tenancy_Roles_Arr_Rel_Insert_Input>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  team_accounts?: InputMaybe<Tenancy_Team_Accounts_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Tenancy_Users_Obj_Rel_Insert_Input>;
  user_accounts?: InputMaybe<Tenancy_User_Accounts_Arr_Rel_Insert_Input>;
  variables?: InputMaybe<Tenancy_Variable_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy_Accounts_Max_Fields = {
  __typename?: 'tenancy_accounts_max_fields';
  accountManagerId?: Maybe<Scalars['String']['output']>;
  allParentIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.accounts" */
export type Tenancy_Accounts_Max_Order_By = {
  accountManagerId?: InputMaybe<Order_By>;
  allParentIds?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  picture?: InputMaybe<Order_By>;
  shortId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Accounts_Min_Fields = {
  __typename?: 'tenancy_accounts_min_fields';
  accountManagerId?: Maybe<Scalars['String']['output']>;
  allParentIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.accounts" */
export type Tenancy_Accounts_Min_Order_By = {
  accountManagerId?: InputMaybe<Order_By>;
  allParentIds?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  picture?: InputMaybe<Order_By>;
  shortId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.accounts" */
export type Tenancy_Accounts_Mutation_Response = {
  __typename?: 'tenancy_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Accounts>;
};

/** input type for inserting object relation for remote table "tenancy.accounts" */
export type Tenancy_Accounts_Obj_Rel_Insert_Input = {
  data: Tenancy_Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Accounts_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.accounts" */
export type Tenancy_Accounts_On_Conflict = {
  constraint: Tenancy_Accounts_Constraint;
  update_columns?: Array<Tenancy_Accounts_Update_Column>;
  where?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.accounts". */
export type Tenancy_Accounts_Order_By = {
  account?: InputMaybe<Tenancy_Accounts_Order_By>;
  accountManagerId?: InputMaybe<Order_By>;
  account_scheduled_jobs_aggregate?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Aggregate_Order_By>;
  accounts_aggregate?: InputMaybe<Tenancy_Accounts_Aggregate_Order_By>;
  allParentIds?: InputMaybe<Order_By>;
  audit_logs_aggregate?: InputMaybe<Tenancy_Audit_Logs_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Tenancy_File_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isManaged?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  picture?: InputMaybe<Order_By>;
  projects_aggregate?: InputMaybe<Tenancy_Projects_Aggregate_Order_By>;
  roles_aggregate?: InputMaybe<Tenancy_Roles_Aggregate_Order_By>;
  settings?: InputMaybe<Order_By>;
  shortId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_accounts_aggregate?: InputMaybe<Tenancy_Team_Accounts_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  user?: InputMaybe<Tenancy_Users_Order_By>;
  user_accounts_aggregate?: InputMaybe<Tenancy_User_Accounts_Aggregate_Order_By>;
  variables_aggregate?: InputMaybe<Tenancy_Variable_Aggregate_Order_By>;
};

/** primary key columns input for table: tenancy.accounts */
export type Tenancy_Accounts_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Accounts_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.accounts" */
export enum Tenancy_Accounts_Select_Column {
  /** column name */
  AccountManagerId = 'accountManagerId',
  /** column name */
  AllParentIds = 'allParentIds',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  IsManaged = 'isManaged',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Picture = 'picture',
  /** column name */
  Settings = 'settings',
  /** column name */
  ShortId = 'shortId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** select "tenancy_accounts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenancy.accounts" */
export enum Tenancy_Accounts_Select_Column_Tenancy_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsManaged = 'isManaged'
}

/** select "tenancy_accounts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenancy.accounts" */
export enum Tenancy_Accounts_Select_Column_Tenancy_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsManaged = 'isManaged'
}

/** input type for updating data in table "tenancy.accounts" */
export type Tenancy_Accounts_Set_Input = {
  accountManagerId?: InputMaybe<Scalars['String']['input']>;
  allParentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManaged?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_accounts" */
export type Tenancy_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Accounts_Stream_Cursor_Value_Input = {
  accountManagerId?: InputMaybe<Scalars['String']['input']>;
  allParentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManaged?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.accounts" */
export enum Tenancy_Accounts_Update_Column {
  /** column name */
  AccountManagerId = 'accountManagerId',
  /** column name */
  AllParentIds = 'allParentIds',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  IsManaged = 'isManaged',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Picture = 'picture',
  /** column name */
  Settings = 'settings',
  /** column name */
  ShortId = 'shortId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Tenancy_Accounts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Accounts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Accounts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Accounts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Accounts_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Accounts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Accounts_Bool_Exp;
};

/** columns and relationships of "tenancy.action" */
export type Tenancy_Action = {
  __typename?: 'tenancy_action';
  appletInstanceId: Scalars['String']['output'];
  /** An object relationship */
  applet_instance: Tenancy_Applet_Instance;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  latestVersionWhileCreating?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  packageVersionId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  package_version?: Maybe<Tenancy_Package_Version>;
};


/** columns and relationships of "tenancy.action" */
export type Tenancy_ActionOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.action" */
export type Tenancy_Action_Aggregate = {
  __typename?: 'tenancy_action_aggregate';
  aggregate?: Maybe<Tenancy_Action_Aggregate_Fields>;
  nodes: Array<Tenancy_Action>;
};

export type Tenancy_Action_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Action_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Action_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Action_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.action" */
export type Tenancy_Action_Aggregate_Fields = {
  __typename?: 'tenancy_action_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Action_Max_Fields>;
  min?: Maybe<Tenancy_Action_Min_Fields>;
};


/** aggregate fields of "tenancy.action" */
export type Tenancy_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.action" */
export type Tenancy_Action_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Action_Max_Order_By>;
  min?: InputMaybe<Tenancy_Action_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Action_Append_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.action" */
export type Tenancy_Action_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Action_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Action_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.action". All fields are combined with a logical 'AND'. */
export type Tenancy_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Action_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Action_Bool_Exp>>;
  appletInstanceId?: InputMaybe<String_Comparison_Exp>;
  applet_instance?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  latestVersionWhileCreating?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
  packageId?: InputMaybe<String_Comparison_Exp>;
  packageVersionId?: InputMaybe<String_Comparison_Exp>;
  package_version?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy.action" */
export enum Tenancy_Action_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActionPkey = 'action_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Action_Delete_At_Path_Input = {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Action_Delete_Elem_Input = {
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Action_Delete_Key_Input = {
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.action" */
export type Tenancy_Action_Insert_Input = {
  appletInstanceId?: InputMaybe<Scalars['String']['input']>;
  applet_instance?: InputMaybe<Tenancy_Applet_Instance_Obj_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionWhileCreating?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
  package_version?: InputMaybe<Tenancy_Package_Version_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy_Action_Max_Fields = {
  __typename?: 'tenancy_action_max_fields';
  appletInstanceId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latestVersionWhileCreating?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  packageVersionId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.action" */
export type Tenancy_Action_Max_Order_By = {
  appletInstanceId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionWhileCreating?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  packageVersionId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Action_Min_Fields = {
  __typename?: 'tenancy_action_min_fields';
  appletInstanceId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latestVersionWhileCreating?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  packageVersionId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.action" */
export type Tenancy_Action_Min_Order_By = {
  appletInstanceId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionWhileCreating?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  packageVersionId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.action" */
export type Tenancy_Action_Mutation_Response = {
  __typename?: 'tenancy_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Action>;
};

/** on_conflict condition type for table "tenancy.action" */
export type Tenancy_Action_On_Conflict = {
  constraint: Tenancy_Action_Constraint;
  update_columns?: Array<Tenancy_Action_Update_Column>;
  where?: InputMaybe<Tenancy_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.action". */
export type Tenancy_Action_Order_By = {
  appletInstanceId?: InputMaybe<Order_By>;
  applet_instance?: InputMaybe<Tenancy_Applet_Instance_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionWhileCreating?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  packageVersionId?: InputMaybe<Order_By>;
  package_version?: InputMaybe<Tenancy_Package_Version_Order_By>;
};

/** primary key columns input for table: tenancy.action */
export type Tenancy_Action_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Action_Prepend_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.action" */
export enum Tenancy_Action_Select_Column {
  /** column name */
  AppletInstanceId = 'appletInstanceId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LatestVersionWhileCreating = 'latestVersionWhileCreating',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  PackageVersionId = 'packageVersionId'
}

/** input type for updating data in table "tenancy.action" */
export type Tenancy_Action_Set_Input = {
  appletInstanceId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionWhileCreating?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_action" */
export type Tenancy_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Action_Stream_Cursor_Value_Input = {
  appletInstanceId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionWhileCreating?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.action" */
export enum Tenancy_Action_Update_Column {
  /** column name */
  AppletInstanceId = 'appletInstanceId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LatestVersionWhileCreating = 'latestVersionWhileCreating',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  PackageVersionId = 'packageVersionId'
}

export type Tenancy_Action_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Action_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Action_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Action_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Action_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Action_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Action_Bool_Exp;
};

/** columns and relationships of "tenancy.app_config" */
export type Tenancy_App_Config = {
  __typename?: 'tenancy_app_config';
  id: Scalars['String']['output'];
  isShortIdsAssigned: Scalars['Boolean']['output'];
  kafkaOffset: Scalars['String']['output'];
};

/** aggregated selection of "tenancy.app_config" */
export type Tenancy_App_Config_Aggregate = {
  __typename?: 'tenancy_app_config_aggregate';
  aggregate?: Maybe<Tenancy_App_Config_Aggregate_Fields>;
  nodes: Array<Tenancy_App_Config>;
};

/** aggregate fields of "tenancy.app_config" */
export type Tenancy_App_Config_Aggregate_Fields = {
  __typename?: 'tenancy_app_config_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_App_Config_Max_Fields>;
  min?: Maybe<Tenancy_App_Config_Min_Fields>;
};


/** aggregate fields of "tenancy.app_config" */
export type Tenancy_App_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_App_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tenancy.app_config". All fields are combined with a logical 'AND'. */
export type Tenancy_App_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_App_Config_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_App_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_App_Config_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  isShortIdsAssigned?: InputMaybe<Boolean_Comparison_Exp>;
  kafkaOffset?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.app_config" */
export enum Tenancy_App_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppConfigPkey = 'app_config_pkey'
}

/** input type for inserting data into table "tenancy.app_config" */
export type Tenancy_App_Config_Insert_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  isShortIdsAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  kafkaOffset?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_App_Config_Max_Fields = {
  __typename?: 'tenancy_app_config_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  kafkaOffset?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tenancy_App_Config_Min_Fields = {
  __typename?: 'tenancy_app_config_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  kafkaOffset?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tenancy.app_config" */
export type Tenancy_App_Config_Mutation_Response = {
  __typename?: 'tenancy_app_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_App_Config>;
};

/** on_conflict condition type for table "tenancy.app_config" */
export type Tenancy_App_Config_On_Conflict = {
  constraint: Tenancy_App_Config_Constraint;
  update_columns?: Array<Tenancy_App_Config_Update_Column>;
  where?: InputMaybe<Tenancy_App_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.app_config". */
export type Tenancy_App_Config_Order_By = {
  id?: InputMaybe<Order_By>;
  isShortIdsAssigned?: InputMaybe<Order_By>;
  kafkaOffset?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.app_config */
export type Tenancy_App_Config_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.app_config" */
export enum Tenancy_App_Config_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsShortIdsAssigned = 'isShortIdsAssigned',
  /** column name */
  KafkaOffset = 'kafkaOffset'
}

/** input type for updating data in table "tenancy.app_config" */
export type Tenancy_App_Config_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  isShortIdsAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  kafkaOffset?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_app_config" */
export type Tenancy_App_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_App_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_App_Config_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  isShortIdsAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  kafkaOffset?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.app_config" */
export enum Tenancy_App_Config_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsShortIdsAssigned = 'isShortIdsAssigned',
  /** column name */
  KafkaOffset = 'kafkaOffset'
}

export type Tenancy_App_Config_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_App_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_App_Config_Bool_Exp;
};

/** columns and relationships of "tenancy.applet" */
export type Tenancy_Applet = {
  __typename?: 'tenancy_applet';
  appletCategory: Scalars['AppletCategory']['output'];
  appletType: Scalars['AppletType']['output'];
  /** An object relationship */
  applet_version?: Maybe<Tenancy_Applet_Version>;
  /** An array relationship */
  applet_versions: Array<Tenancy_Applet_Version>;
  /** An aggregate relationship */
  applet_versions_aggregate: Tenancy_Applet_Version_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  files: Array<Tenancy_File>;
  /** An aggregate relationship */
  files_aggregate: Tenancy_File_Aggregate;
  id: Scalars['String']['output'];
  latestVersionId?: Maybe<Scalars['String']['output']>;
  pkgName: Scalars['String']['output'];
  schema: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "tenancy.applet" */
export type Tenancy_AppletApplet_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet" */
export type Tenancy_AppletApplet_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet" */
export type Tenancy_AppletFilesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet" */
export type Tenancy_AppletFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet" */
export type Tenancy_AppletSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.applet" */
export type Tenancy_Applet_Aggregate = {
  __typename?: 'tenancy_applet_aggregate';
  aggregate?: Maybe<Tenancy_Applet_Aggregate_Fields>;
  nodes: Array<Tenancy_Applet>;
};

export type Tenancy_Applet_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Applet_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Applet_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Applet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Applet_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.applet" */
export type Tenancy_Applet_Aggregate_Fields = {
  __typename?: 'tenancy_applet_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Applet_Max_Fields>;
  min?: Maybe<Tenancy_Applet_Min_Fields>;
};


/** aggregate fields of "tenancy.applet" */
export type Tenancy_Applet_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Applet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.applet" */
export type Tenancy_Applet_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Applet_Max_Order_By>;
  min?: InputMaybe<Tenancy_Applet_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Applet_Append_Input = {
  schema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.applet" */
export type Tenancy_Applet_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Applet_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Applet_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.applet". All fields are combined with a logical 'AND'. */
export type Tenancy_Applet_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Applet_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Applet_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Applet_Bool_Exp>>;
  appletCategory?: InputMaybe<AppletCategory_Comparison_Exp>;
  appletType?: InputMaybe<AppletType_Comparison_Exp>;
  applet_version?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
  applet_versions?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
  applet_versions_aggregate?: InputMaybe<Tenancy_Applet_Version_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  files?: InputMaybe<Tenancy_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Tenancy_File_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  latestVersionId?: InputMaybe<String_Comparison_Exp>;
  pkgName?: InputMaybe<String_Comparison_Exp>;
  schema?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.applet" */
export enum Tenancy_Applet_Constraint {
  /** unique or primary key constraint on columns "latestVersionId" */
  AppletLatestVersionIdKey = 'applet_latestVersionId_key',
  /** unique or primary key constraint on columns "id" */
  AppletPkey = 'applet_pkey',
  /** unique or primary key constraint on columns "pkgName" */
  AppletPkgNameKey = 'applet_pkgName_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Applet_Delete_At_Path_Input = {
  schema?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Applet_Delete_Elem_Input = {
  schema?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Applet_Delete_Key_Input = {
  schema?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.applet" */
export type Tenancy_Applet_Insert_Input = {
  appletCategory?: InputMaybe<Scalars['AppletCategory']['input']>;
  appletType?: InputMaybe<Scalars['AppletType']['input']>;
  applet_version?: InputMaybe<Tenancy_Applet_Version_Obj_Rel_Insert_Input>;
  applet_versions?: InputMaybe<Tenancy_Applet_Version_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Tenancy_File_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionId?: InputMaybe<Scalars['String']['input']>;
  pkgName?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** columns and relationships of "tenancy.applet_instance" */
export type Tenancy_Applet_Instance = {
  __typename?: 'tenancy_applet_instance';
  /** An array relationship */
  actions: Array<Tenancy_Action>;
  /** An aggregate relationship */
  actions_aggregate: Tenancy_Action_Aggregate;
  appletVersionId: Scalars['String']['output'];
  /** An object relationship */
  applet_version: Tenancy_Applet_Version;
  /** An array relationship */
  conditions: Array<Tenancy_Condition>;
  /** An aggregate relationship */
  conditions_aggregate: Tenancy_Condition_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['jsonb']['output']>;
  projectReleaseChannelConfigId: Scalars['String']['output'];
  /** An object relationship */
  project_release_channel_config: Tenancy_Project_Release_Channel_Config;
  shortId?: Maybe<Scalars['String']['output']>;
  status: Scalars['AppletStatus']['output'];
  /** An array relationship */
  triggers: Array<Tenancy_Trigger>;
  /** An aggregate relationship */
  triggers_aggregate: Tenancy_Trigger_Aggregate;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "tenancy.applet_instance" */
export type Tenancy_Applet_InstanceActionsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Action_Order_By>>;
  where?: InputMaybe<Tenancy_Action_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet_instance" */
export type Tenancy_Applet_InstanceActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Action_Order_By>>;
  where?: InputMaybe<Tenancy_Action_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet_instance" */
export type Tenancy_Applet_InstanceConditionsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Condition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Condition_Order_By>>;
  where?: InputMaybe<Tenancy_Condition_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet_instance" */
export type Tenancy_Applet_InstanceConditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Condition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Condition_Order_By>>;
  where?: InputMaybe<Tenancy_Condition_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet_instance" */
export type Tenancy_Applet_InstanceOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tenancy.applet_instance" */
export type Tenancy_Applet_InstanceTriggersArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Trigger_Order_By>>;
  where?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet_instance" */
export type Tenancy_Applet_InstanceTriggers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Trigger_Order_By>>;
  where?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
};

/** aggregated selection of "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_Aggregate = {
  __typename?: 'tenancy_applet_instance_aggregate';
  aggregate?: Maybe<Tenancy_Applet_Instance_Aggregate_Fields>;
  nodes: Array<Tenancy_Applet_Instance>;
};

export type Tenancy_Applet_Instance_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Applet_Instance_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Applet_Instance_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Applet_Instance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_Aggregate_Fields = {
  __typename?: 'tenancy_applet_instance_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Applet_Instance_Max_Fields>;
  min?: Maybe<Tenancy_Applet_Instance_Min_Fields>;
};


/** aggregate fields of "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Applet_Instance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Applet_Instance_Max_Order_By>;
  min?: InputMaybe<Tenancy_Applet_Instance_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Applet_Instance_Append_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Applet_Instance_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Applet_Instance_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.applet_instance". All fields are combined with a logical 'AND'. */
export type Tenancy_Applet_Instance_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Applet_Instance_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Applet_Instance_Bool_Exp>>;
  actions?: InputMaybe<Tenancy_Action_Bool_Exp>;
  actions_aggregate?: InputMaybe<Tenancy_Action_Aggregate_Bool_Exp>;
  appletVersionId?: InputMaybe<String_Comparison_Exp>;
  applet_version?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
  conditions?: InputMaybe<Tenancy_Condition_Bool_Exp>;
  conditions_aggregate?: InputMaybe<Tenancy_Condition_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
  projectReleaseChannelConfigId?: InputMaybe<String_Comparison_Exp>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
  shortId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<AppletStatus_Comparison_Exp>;
  triggers?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
  triggers_aggregate?: InputMaybe<Tenancy_Trigger_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.applet_instance" */
export enum Tenancy_Applet_Instance_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppletInstancePkey = 'applet_instance_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Applet_Instance_Delete_At_Path_Input = {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Applet_Instance_Delete_Elem_Input = {
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Applet_Instance_Delete_Key_Input = {
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_Insert_Input = {
  actions?: InputMaybe<Tenancy_Action_Arr_Rel_Insert_Input>;
  appletVersionId?: InputMaybe<Scalars['String']['input']>;
  applet_version?: InputMaybe<Tenancy_Applet_Version_Obj_Rel_Insert_Input>;
  conditions?: InputMaybe<Tenancy_Condition_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Obj_Rel_Insert_Input>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['AppletStatus']['input']>;
  triggers?: InputMaybe<Tenancy_Trigger_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Applet_Instance_Max_Fields = {
  __typename?: 'tenancy_applet_instance_max_fields';
  appletVersionId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectReleaseChannelConfigId?: Maybe<Scalars['String']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['AppletStatus']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_Max_Order_By = {
  appletVersionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  shortId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Applet_Instance_Min_Fields = {
  __typename?: 'tenancy_applet_instance_min_fields';
  appletVersionId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectReleaseChannelConfigId?: Maybe<Scalars['String']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['AppletStatus']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_Min_Order_By = {
  appletVersionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  shortId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_Mutation_Response = {
  __typename?: 'tenancy_applet_instance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Applet_Instance>;
};

/** input type for inserting object relation for remote table "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_Obj_Rel_Insert_Input = {
  data: Tenancy_Applet_Instance_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Applet_Instance_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_On_Conflict = {
  constraint: Tenancy_Applet_Instance_Constraint;
  update_columns?: Array<Tenancy_Applet_Instance_Update_Column>;
  where?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.applet_instance". */
export type Tenancy_Applet_Instance_Order_By = {
  actions_aggregate?: InputMaybe<Tenancy_Action_Aggregate_Order_By>;
  appletVersionId?: InputMaybe<Order_By>;
  applet_version?: InputMaybe<Tenancy_Applet_Version_Order_By>;
  conditions_aggregate?: InputMaybe<Tenancy_Condition_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Order_By>;
  shortId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  triggers_aggregate?: InputMaybe<Tenancy_Trigger_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.applet_instance */
export type Tenancy_Applet_Instance_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Applet_Instance_Prepend_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.applet_instance" */
export enum Tenancy_Applet_Instance_Select_Column {
  /** column name */
  AppletVersionId = 'appletVersionId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  ProjectReleaseChannelConfigId = 'projectReleaseChannelConfigId',
  /** column name */
  ShortId = 'shortId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** input type for updating data in table "tenancy.applet_instance" */
export type Tenancy_Applet_Instance_Set_Input = {
  appletVersionId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['AppletStatus']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_applet_instance" */
export type Tenancy_Applet_Instance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Applet_Instance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Applet_Instance_Stream_Cursor_Value_Input = {
  appletVersionId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['AppletStatus']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.applet_instance" */
export enum Tenancy_Applet_Instance_Update_Column {
  /** column name */
  AppletVersionId = 'appletVersionId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  ProjectReleaseChannelConfigId = 'projectReleaseChannelConfigId',
  /** column name */
  ShortId = 'shortId',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Tenancy_Applet_Instance_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Applet_Instance_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Applet_Instance_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Applet_Instance_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Applet_Instance_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Applet_Instance_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Applet_Instance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Applet_Instance_Bool_Exp;
};

/** aggregate max on columns */
export type Tenancy_Applet_Max_Fields = {
  __typename?: 'tenancy_applet_max_fields';
  appletCategory?: Maybe<Scalars['AppletCategory']['output']>;
  appletType?: Maybe<Scalars['AppletType']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latestVersionId?: Maybe<Scalars['String']['output']>;
  pkgName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "tenancy.applet" */
export type Tenancy_Applet_Max_Order_By = {
  appletCategory?: InputMaybe<Order_By>;
  appletType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionId?: InputMaybe<Order_By>;
  pkgName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Applet_Min_Fields = {
  __typename?: 'tenancy_applet_min_fields';
  appletCategory?: Maybe<Scalars['AppletCategory']['output']>;
  appletType?: Maybe<Scalars['AppletType']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latestVersionId?: Maybe<Scalars['String']['output']>;
  pkgName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "tenancy.applet" */
export type Tenancy_Applet_Min_Order_By = {
  appletCategory?: InputMaybe<Order_By>;
  appletType?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionId?: InputMaybe<Order_By>;
  pkgName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.applet" */
export type Tenancy_Applet_Mutation_Response = {
  __typename?: 'tenancy_applet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Applet>;
};

/** input type for inserting object relation for remote table "tenancy.applet" */
export type Tenancy_Applet_Obj_Rel_Insert_Input = {
  data: Tenancy_Applet_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Applet_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.applet" */
export type Tenancy_Applet_On_Conflict = {
  constraint: Tenancy_Applet_Constraint;
  update_columns?: Array<Tenancy_Applet_Update_Column>;
  where?: InputMaybe<Tenancy_Applet_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.applet". */
export type Tenancy_Applet_Order_By = {
  appletCategory?: InputMaybe<Order_By>;
  appletType?: InputMaybe<Order_By>;
  applet_version?: InputMaybe<Tenancy_Applet_Version_Order_By>;
  applet_versions_aggregate?: InputMaybe<Tenancy_Applet_Version_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Tenancy_File_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionId?: InputMaybe<Order_By>;
  pkgName?: InputMaybe<Order_By>;
  schema?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.applet */
export type Tenancy_Applet_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Applet_Prepend_Input = {
  schema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.applet" */
export enum Tenancy_Applet_Select_Column {
  /** column name */
  AppletCategory = 'appletCategory',
  /** column name */
  AppletType = 'appletType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  LatestVersionId = 'latestVersionId',
  /** column name */
  PkgName = 'pkgName',
  /** column name */
  Schema = 'schema',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "tenancy.applet" */
export type Tenancy_Applet_Set_Input = {
  appletCategory?: InputMaybe<Scalars['AppletCategory']['input']>;
  appletType?: InputMaybe<Scalars['AppletType']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionId?: InputMaybe<Scalars['String']['input']>;
  pkgName?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "tenancy_applet" */
export type Tenancy_Applet_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Applet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Applet_Stream_Cursor_Value_Input = {
  appletCategory?: InputMaybe<Scalars['AppletCategory']['input']>;
  appletType?: InputMaybe<Scalars['AppletType']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionId?: InputMaybe<Scalars['String']['input']>;
  pkgName?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "tenancy.applet" */
export enum Tenancy_Applet_Update_Column {
  /** column name */
  AppletCategory = 'appletCategory',
  /** column name */
  AppletType = 'appletType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  LatestVersionId = 'latestVersionId',
  /** column name */
  PkgName = 'pkgName',
  /** column name */
  Schema = 'schema',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Tenancy_Applet_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Applet_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Applet_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Applet_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Applet_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Applet_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Applet_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Applet_Bool_Exp;
};

/** columns and relationships of "tenancy.applet_version" */
export type Tenancy_Applet_Version = {
  __typename?: 'tenancy_applet_version';
  /** An object relationship */
  applet: Tenancy_Applet;
  appletId: Scalars['String']['output'];
  /** An array relationship */
  applet_instances: Array<Tenancy_Applet_Instance>;
  /** An aggregate relationship */
  applet_instances_aggregate: Tenancy_Applet_Instance_Aggregate;
  /** An array relationship */
  applets: Array<Tenancy_Applet>;
  /** An aggregate relationship */
  applets_aggregate: Tenancy_Applet_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  schema: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamp']['output'];
  version: Scalars['String']['output'];
};


/** columns and relationships of "tenancy.applet_version" */
export type Tenancy_Applet_VersionApplet_InstancesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Instance_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet_version" */
export type Tenancy_Applet_VersionApplet_Instances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Instance_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet_version" */
export type Tenancy_Applet_VersionAppletsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet_version" */
export type Tenancy_Applet_VersionApplets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Bool_Exp>;
};


/** columns and relationships of "tenancy.applet_version" */
export type Tenancy_Applet_VersionSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.applet_version" */
export type Tenancy_Applet_Version_Aggregate = {
  __typename?: 'tenancy_applet_version_aggregate';
  aggregate?: Maybe<Tenancy_Applet_Version_Aggregate_Fields>;
  nodes: Array<Tenancy_Applet_Version>;
};

export type Tenancy_Applet_Version_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Applet_Version_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Applet_Version_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Applet_Version_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.applet_version" */
export type Tenancy_Applet_Version_Aggregate_Fields = {
  __typename?: 'tenancy_applet_version_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Applet_Version_Max_Fields>;
  min?: Maybe<Tenancy_Applet_Version_Min_Fields>;
};


/** aggregate fields of "tenancy.applet_version" */
export type Tenancy_Applet_Version_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Applet_Version_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.applet_version" */
export type Tenancy_Applet_Version_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Applet_Version_Max_Order_By>;
  min?: InputMaybe<Tenancy_Applet_Version_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Applet_Version_Append_Input = {
  schema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.applet_version" */
export type Tenancy_Applet_Version_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Applet_Version_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Applet_Version_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.applet_version". All fields are combined with a logical 'AND'. */
export type Tenancy_Applet_Version_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Applet_Version_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Applet_Version_Bool_Exp>>;
  applet?: InputMaybe<Tenancy_Applet_Bool_Exp>;
  appletId?: InputMaybe<String_Comparison_Exp>;
  applet_instances?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
  applet_instances_aggregate?: InputMaybe<Tenancy_Applet_Instance_Aggregate_Bool_Exp>;
  applets?: InputMaybe<Tenancy_Applet_Bool_Exp>;
  applets_aggregate?: InputMaybe<Tenancy_Applet_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  schema?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.applet_version" */
export enum Tenancy_Applet_Version_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppletVersionPkey = 'applet_version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Applet_Version_Delete_At_Path_Input = {
  schema?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Applet_Version_Delete_Elem_Input = {
  schema?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Applet_Version_Delete_Key_Input = {
  schema?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.applet_version" */
export type Tenancy_Applet_Version_Insert_Input = {
  applet?: InputMaybe<Tenancy_Applet_Obj_Rel_Insert_Input>;
  appletId?: InputMaybe<Scalars['String']['input']>;
  applet_instances?: InputMaybe<Tenancy_Applet_Instance_Arr_Rel_Insert_Input>;
  applets?: InputMaybe<Tenancy_Applet_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Applet_Version_Max_Fields = {
  __typename?: 'tenancy_applet_version_max_fields';
  appletId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.applet_version" */
export type Tenancy_Applet_Version_Max_Order_By = {
  appletId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Applet_Version_Min_Fields = {
  __typename?: 'tenancy_applet_version_min_fields';
  appletId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.applet_version" */
export type Tenancy_Applet_Version_Min_Order_By = {
  appletId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.applet_version" */
export type Tenancy_Applet_Version_Mutation_Response = {
  __typename?: 'tenancy_applet_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Applet_Version>;
};

/** input type for inserting object relation for remote table "tenancy.applet_version" */
export type Tenancy_Applet_Version_Obj_Rel_Insert_Input = {
  data: Tenancy_Applet_Version_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Applet_Version_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.applet_version" */
export type Tenancy_Applet_Version_On_Conflict = {
  constraint: Tenancy_Applet_Version_Constraint;
  update_columns?: Array<Tenancy_Applet_Version_Update_Column>;
  where?: InputMaybe<Tenancy_Applet_Version_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.applet_version". */
export type Tenancy_Applet_Version_Order_By = {
  applet?: InputMaybe<Tenancy_Applet_Order_By>;
  appletId?: InputMaybe<Order_By>;
  applet_instances_aggregate?: InputMaybe<Tenancy_Applet_Instance_Aggregate_Order_By>;
  applets_aggregate?: InputMaybe<Tenancy_Applet_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  schema?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.applet_version */
export type Tenancy_Applet_Version_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Applet_Version_Prepend_Input = {
  schema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.applet_version" */
export enum Tenancy_Applet_Version_Select_Column {
  /** column name */
  AppletId = 'appletId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Schema = 'schema',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "tenancy.applet_version" */
export type Tenancy_Applet_Version_Set_Input = {
  appletId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_applet_version" */
export type Tenancy_Applet_Version_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Applet_Version_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Applet_Version_Stream_Cursor_Value_Input = {
  appletId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.applet_version" */
export enum Tenancy_Applet_Version_Update_Column {
  /** column name */
  AppletId = 'appletId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Schema = 'schema',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Version = 'version'
}

export type Tenancy_Applet_Version_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Applet_Version_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Applet_Version_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Applet_Version_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Applet_Version_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Applet_Version_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Applet_Version_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Applet_Version_Bool_Exp;
};

/** columns and relationships of "tenancy.audit_logs" */
export type Tenancy_Audit_Logs = {
  __typename?: 'tenancy_audit_logs';
  /** An object relationship */
  account?: Maybe<Tenancy_Accounts>;
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  logDetails: Scalars['jsonb']['output'];
  /** An object relationship */
  product: Tenancy_Products;
  productId: Scalars['String']['output'];
  /** An object relationship */
  user: Tenancy_Users;
  userId: Scalars['String']['output'];
};


/** columns and relationships of "tenancy.audit_logs" */
export type Tenancy_Audit_LogsLogDetailsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.audit_logs" */
export type Tenancy_Audit_Logs_Aggregate = {
  __typename?: 'tenancy_audit_logs_aggregate';
  aggregate?: Maybe<Tenancy_Audit_Logs_Aggregate_Fields>;
  nodes: Array<Tenancy_Audit_Logs>;
};

export type Tenancy_Audit_Logs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Audit_Logs_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Audit_Logs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.audit_logs" */
export type Tenancy_Audit_Logs_Aggregate_Fields = {
  __typename?: 'tenancy_audit_logs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Audit_Logs_Max_Fields>;
  min?: Maybe<Tenancy_Audit_Logs_Min_Fields>;
};


/** aggregate fields of "tenancy.audit_logs" */
export type Tenancy_Audit_Logs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.audit_logs" */
export type Tenancy_Audit_Logs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Audit_Logs_Max_Order_By>;
  min?: InputMaybe<Tenancy_Audit_Logs_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Audit_Logs_Append_Input = {
  logDetails?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.audit_logs" */
export type Tenancy_Audit_Logs_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Audit_Logs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Audit_Logs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.audit_logs". All fields are combined with a logical 'AND'. */
export type Tenancy_Audit_Logs_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Audit_Logs_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Audit_Logs_Bool_Exp>>;
  account?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  logDetails?: InputMaybe<Jsonb_Comparison_Exp>;
  product?: InputMaybe<Tenancy_Products_Bool_Exp>;
  productId?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Tenancy_Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.audit_logs" */
export enum Tenancy_Audit_Logs_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuditLogsPkey = 'audit_logs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Audit_Logs_Delete_At_Path_Input = {
  logDetails?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Audit_Logs_Delete_Elem_Input = {
  logDetails?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Audit_Logs_Delete_Key_Input = {
  logDetails?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.audit_logs" */
export type Tenancy_Audit_Logs_Insert_Input = {
  account?: InputMaybe<Tenancy_Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  logDetails?: InputMaybe<Scalars['jsonb']['input']>;
  product?: InputMaybe<Tenancy_Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Tenancy_Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Audit_Logs_Max_Fields = {
  __typename?: 'tenancy_audit_logs_max_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.audit_logs" */
export type Tenancy_Audit_Logs_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Audit_Logs_Min_Fields = {
  __typename?: 'tenancy_audit_logs_min_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.audit_logs" */
export type Tenancy_Audit_Logs_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.audit_logs" */
export type Tenancy_Audit_Logs_Mutation_Response = {
  __typename?: 'tenancy_audit_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Audit_Logs>;
};

/** on_conflict condition type for table "tenancy.audit_logs" */
export type Tenancy_Audit_Logs_On_Conflict = {
  constraint: Tenancy_Audit_Logs_Constraint;
  update_columns?: Array<Tenancy_Audit_Logs_Update_Column>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.audit_logs". */
export type Tenancy_Audit_Logs_Order_By = {
  account?: InputMaybe<Tenancy_Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logDetails?: InputMaybe<Order_By>;
  product?: InputMaybe<Tenancy_Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  user?: InputMaybe<Tenancy_Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.audit_logs */
export type Tenancy_Audit_Logs_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Audit_Logs_Prepend_Input = {
  logDetails?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.audit_logs" */
export enum Tenancy_Audit_Logs_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LogDetails = 'logDetails',
  /** column name */
  ProductId = 'productId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "tenancy.audit_logs" */
export type Tenancy_Audit_Logs_Set_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  logDetails?: InputMaybe<Scalars['jsonb']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_audit_logs" */
export type Tenancy_Audit_Logs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Audit_Logs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Audit_Logs_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  logDetails?: InputMaybe<Scalars['jsonb']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.audit_logs" */
export enum Tenancy_Audit_Logs_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LogDetails = 'logDetails',
  /** column name */
  ProductId = 'productId',
  /** column name */
  UserId = 'userId'
}

export type Tenancy_Audit_Logs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Audit_Logs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Audit_Logs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Audit_Logs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Audit_Logs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Audit_Logs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Audit_Logs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Audit_Logs_Bool_Exp;
};

/** columns and relationships of "tenancy.condition" */
export type Tenancy_Condition = {
  __typename?: 'tenancy_condition';
  appletInstanceId: Scalars['String']['output'];
  /** An object relationship */
  applet_instance: Tenancy_Applet_Instance;
  id: Scalars['String']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
};


/** columns and relationships of "tenancy.condition" */
export type Tenancy_ConditionOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.condition" */
export type Tenancy_Condition_Aggregate = {
  __typename?: 'tenancy_condition_aggregate';
  aggregate?: Maybe<Tenancy_Condition_Aggregate_Fields>;
  nodes: Array<Tenancy_Condition>;
};

export type Tenancy_Condition_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Condition_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Condition_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Condition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Condition_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.condition" */
export type Tenancy_Condition_Aggregate_Fields = {
  __typename?: 'tenancy_condition_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Condition_Max_Fields>;
  min?: Maybe<Tenancy_Condition_Min_Fields>;
};


/** aggregate fields of "tenancy.condition" */
export type Tenancy_Condition_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Condition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.condition" */
export type Tenancy_Condition_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Condition_Max_Order_By>;
  min?: InputMaybe<Tenancy_Condition_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Condition_Append_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.condition" */
export type Tenancy_Condition_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Condition_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Condition_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.condition". All fields are combined with a logical 'AND'. */
export type Tenancy_Condition_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Condition_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Condition_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Condition_Bool_Exp>>;
  appletInstanceId?: InputMaybe<String_Comparison_Exp>;
  applet_instance?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.condition" */
export enum Tenancy_Condition_Constraint {
  /** unique or primary key constraint on columns "appletInstanceId" */
  ConditionAppletInstanceIdKey = 'condition_appletInstanceId_key',
  /** unique or primary key constraint on columns "id" */
  ConditionPkey = 'condition_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Condition_Delete_At_Path_Input = {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Condition_Delete_Elem_Input = {
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Condition_Delete_Key_Input = {
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.condition" */
export type Tenancy_Condition_Insert_Input = {
  appletInstanceId?: InputMaybe<Scalars['String']['input']>;
  applet_instance?: InputMaybe<Tenancy_Applet_Instance_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Condition_Max_Fields = {
  __typename?: 'tenancy_condition_max_fields';
  appletInstanceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.condition" */
export type Tenancy_Condition_Max_Order_By = {
  appletInstanceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Condition_Min_Fields = {
  __typename?: 'tenancy_condition_min_fields';
  appletInstanceId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.condition" */
export type Tenancy_Condition_Min_Order_By = {
  appletInstanceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.condition" */
export type Tenancy_Condition_Mutation_Response = {
  __typename?: 'tenancy_condition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Condition>;
};

/** on_conflict condition type for table "tenancy.condition" */
export type Tenancy_Condition_On_Conflict = {
  constraint: Tenancy_Condition_Constraint;
  update_columns?: Array<Tenancy_Condition_Update_Column>;
  where?: InputMaybe<Tenancy_Condition_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.condition". */
export type Tenancy_Condition_Order_By = {
  appletInstanceId?: InputMaybe<Order_By>;
  applet_instance?: InputMaybe<Tenancy_Applet_Instance_Order_By>;
  id?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.condition */
export type Tenancy_Condition_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Condition_Prepend_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.condition" */
export enum Tenancy_Condition_Select_Column {
  /** column name */
  AppletInstanceId = 'appletInstanceId',
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options'
}

/** input type for updating data in table "tenancy.condition" */
export type Tenancy_Condition_Set_Input = {
  appletInstanceId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "tenancy_condition" */
export type Tenancy_Condition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Condition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Condition_Stream_Cursor_Value_Input = {
  appletInstanceId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "tenancy.condition" */
export enum Tenancy_Condition_Update_Column {
  /** column name */
  AppletInstanceId = 'appletInstanceId',
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options'
}

export type Tenancy_Condition_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Condition_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Condition_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Condition_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Condition_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Condition_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Condition_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Condition_Bool_Exp;
};

/** columns and relationships of "tenancy.country" */
export type Tenancy_Country = {
  __typename?: 'tenancy_country';
  code: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  iso3?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  localization_settings: Array<Tenancy_Localization_Settings>;
  /** An aggregate relationship */
  localization_settings_aggregate: Tenancy_Localization_Settings_Aggregate;
  name: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
};


/** columns and relationships of "tenancy.country" */
export type Tenancy_CountryLocalization_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


/** columns and relationships of "tenancy.country" */
export type Tenancy_CountryLocalization_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};

/** aggregated selection of "tenancy.country" */
export type Tenancy_Country_Aggregate = {
  __typename?: 'tenancy_country_aggregate';
  aggregate?: Maybe<Tenancy_Country_Aggregate_Fields>;
  nodes: Array<Tenancy_Country>;
};

/** aggregate fields of "tenancy.country" */
export type Tenancy_Country_Aggregate_Fields = {
  __typename?: 'tenancy_country_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Country_Max_Fields>;
  min?: Maybe<Tenancy_Country_Min_Fields>;
};


/** aggregate fields of "tenancy.country" */
export type Tenancy_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tenancy.country". All fields are combined with a logical 'AND'. */
export type Tenancy_Country_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Country_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Country_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Country_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  iso3?: InputMaybe<String_Comparison_Exp>;
  localization_settings?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
  localization_settings_aggregate?: InputMaybe<Tenancy_Localization_Settings_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.country" */
export enum Tenancy_Country_Constraint {
  /** unique or primary key constraint on columns "id" */
  CountryPkey = 'country_pkey'
}

/** input type for inserting data into table "tenancy.country" */
export type Tenancy_Country_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  iso3?: InputMaybe<Scalars['String']['input']>;
  localization_settings?: InputMaybe<Tenancy_Localization_Settings_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Country_Max_Fields = {
  __typename?: 'tenancy_country_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  iso3?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tenancy_Country_Min_Fields = {
  __typename?: 'tenancy_country_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  iso3?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tenancy.country" */
export type Tenancy_Country_Mutation_Response = {
  __typename?: 'tenancy_country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Country>;
};

/** input type for inserting object relation for remote table "tenancy.country" */
export type Tenancy_Country_Obj_Rel_Insert_Input = {
  data: Tenancy_Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Country_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.country" */
export type Tenancy_Country_On_Conflict = {
  constraint: Tenancy_Country_Constraint;
  update_columns?: Array<Tenancy_Country_Update_Column>;
  where?: InputMaybe<Tenancy_Country_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.country". */
export type Tenancy_Country_Order_By = {
  code?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iso3?: InputMaybe<Order_By>;
  localization_settings_aggregate?: InputMaybe<Tenancy_Localization_Settings_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.country */
export type Tenancy_Country_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.country" */
export enum Tenancy_Country_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  Iso3 = 'iso3',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** input type for updating data in table "tenancy.country" */
export type Tenancy_Country_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  iso3?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_country" */
export type Tenancy_Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Country_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  iso3?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.country" */
export enum Tenancy_Country_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  Iso3 = 'iso3',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Tenancy_Country_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Country_Bool_Exp;
};

/** columns and relationships of "tenancy.domain" */
export type Tenancy_Domain = {
  __typename?: 'tenancy_domain';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  host?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastVerifiedAt?: Maybe<Scalars['timestamp']['output']>;
  projectReleaseChannelConfigId: Scalars['String']['output'];
  /** An object relationship */
  project_release_channel_config: Tenancy_Project_Release_Channel_Config;
  type: Scalars['DomainType']['output'];
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
  url: Scalars['String']['output'];
  verificationStatus: Scalars['DomainVerificationStatus']['output'];
  verifiedThroughId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "tenancy.domain" */
export type Tenancy_Domain_Aggregate = {
  __typename?: 'tenancy_domain_aggregate';
  aggregate?: Maybe<Tenancy_Domain_Aggregate_Fields>;
  nodes: Array<Tenancy_Domain>;
};

export type Tenancy_Domain_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Domain_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Domain_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Domain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Domain_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.domain" */
export type Tenancy_Domain_Aggregate_Fields = {
  __typename?: 'tenancy_domain_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Domain_Max_Fields>;
  min?: Maybe<Tenancy_Domain_Min_Fields>;
};


/** aggregate fields of "tenancy.domain" */
export type Tenancy_Domain_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Domain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.domain" */
export type Tenancy_Domain_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Domain_Max_Order_By>;
  min?: InputMaybe<Tenancy_Domain_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy.domain" */
export type Tenancy_Domain_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Domain_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Domain_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.domain". All fields are combined with a logical 'AND'. */
export type Tenancy_Domain_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Domain_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Domain_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Domain_Bool_Exp>>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  host?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lastVerifiedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  projectReleaseChannelConfigId?: InputMaybe<String_Comparison_Exp>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
  type?: InputMaybe<DomainType_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  verificationStatus?: InputMaybe<DomainVerificationStatus_Comparison_Exp>;
  verifiedThroughId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.domain" */
export enum Tenancy_Domain_Constraint {
  /** unique or primary key constraint on columns "id" */
  DomainPkey = 'domain_pkey'
}

/** input type for inserting data into table "tenancy.domain" */
export type Tenancy_Domain_Insert_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastVerifiedAt?: InputMaybe<Scalars['timestamp']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['DomainType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  verificationStatus?: InputMaybe<Scalars['DomainVerificationStatus']['input']>;
  verifiedThroughId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Domain_Max_Fields = {
  __typename?: 'tenancy_domain_max_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastVerifiedAt?: Maybe<Scalars['timestamp']['output']>;
  projectReleaseChannelConfigId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['DomainType']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  verificationStatus?: Maybe<Scalars['DomainVerificationStatus']['output']>;
  verifiedThroughId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.domain" */
export type Tenancy_Domain_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  host?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastVerifiedAt?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  verificationStatus?: InputMaybe<Order_By>;
  verifiedThroughId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Domain_Min_Fields = {
  __typename?: 'tenancy_domain_min_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastVerifiedAt?: Maybe<Scalars['timestamp']['output']>;
  projectReleaseChannelConfigId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['DomainType']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  verificationStatus?: Maybe<Scalars['DomainVerificationStatus']['output']>;
  verifiedThroughId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.domain" */
export type Tenancy_Domain_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  host?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastVerifiedAt?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  verificationStatus?: InputMaybe<Order_By>;
  verifiedThroughId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.domain" */
export type Tenancy_Domain_Mutation_Response = {
  __typename?: 'tenancy_domain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Domain>;
};

/** on_conflict condition type for table "tenancy.domain" */
export type Tenancy_Domain_On_Conflict = {
  constraint: Tenancy_Domain_Constraint;
  update_columns?: Array<Tenancy_Domain_Update_Column>;
  where?: InputMaybe<Tenancy_Domain_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.domain". */
export type Tenancy_Domain_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  host?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastVerifiedAt?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  verificationStatus?: InputMaybe<Order_By>;
  verifiedThroughId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.domain */
export type Tenancy_Domain_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.domain" */
export enum Tenancy_Domain_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Host = 'host',
  /** column name */
  Id = 'id',
  /** column name */
  LastVerifiedAt = 'lastVerifiedAt',
  /** column name */
  ProjectReleaseChannelConfigId = 'projectReleaseChannelConfigId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  Url = 'url',
  /** column name */
  VerificationStatus = 'verificationStatus',
  /** column name */
  VerifiedThroughId = 'verifiedThroughId'
}

/** input type for updating data in table "tenancy.domain" */
export type Tenancy_Domain_Set_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastVerifiedAt?: InputMaybe<Scalars['timestamp']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['DomainType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  verificationStatus?: InputMaybe<Scalars['DomainVerificationStatus']['input']>;
  verifiedThroughId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_domain" */
export type Tenancy_Domain_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Domain_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Domain_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastVerifiedAt?: InputMaybe<Scalars['timestamp']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['DomainType']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  verificationStatus?: InputMaybe<Scalars['DomainVerificationStatus']['input']>;
  verifiedThroughId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.domain" */
export enum Tenancy_Domain_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Host = 'host',
  /** column name */
  Id = 'id',
  /** column name */
  LastVerifiedAt = 'lastVerifiedAt',
  /** column name */
  ProjectReleaseChannelConfigId = 'projectReleaseChannelConfigId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  Url = 'url',
  /** column name */
  VerificationStatus = 'verificationStatus',
  /** column name */
  VerifiedThroughId = 'verifiedThroughId'
}

export type Tenancy_Domain_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Domain_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Domain_Bool_Exp;
};

/** columns and relationships of "tenancy.failed_kafka_events" */
export type Tenancy_Failed_Kafka_Events = {
  __typename?: 'tenancy_failed_kafka_events';
  createdAt: Scalars['timestamp']['output'];
  eventName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "tenancy.failed_kafka_events" */
export type Tenancy_Failed_Kafka_Events_Aggregate = {
  __typename?: 'tenancy_failed_kafka_events_aggregate';
  aggregate?: Maybe<Tenancy_Failed_Kafka_Events_Aggregate_Fields>;
  nodes: Array<Tenancy_Failed_Kafka_Events>;
};

/** aggregate fields of "tenancy.failed_kafka_events" */
export type Tenancy_Failed_Kafka_Events_Aggregate_Fields = {
  __typename?: 'tenancy_failed_kafka_events_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Failed_Kafka_Events_Max_Fields>;
  min?: Maybe<Tenancy_Failed_Kafka_Events_Min_Fields>;
};


/** aggregate fields of "tenancy.failed_kafka_events" */
export type Tenancy_Failed_Kafka_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Failed_Kafka_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tenancy.failed_kafka_events". All fields are combined with a logical 'AND'. */
export type Tenancy_Failed_Kafka_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Failed_Kafka_Events_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Failed_Kafka_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Failed_Kafka_Events_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  eventName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.failed_kafka_events" */
export enum Tenancy_Failed_Kafka_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  FailedKafkaEventsPkey = 'failed_kafka_events_pkey'
}

/** input type for inserting data into table "tenancy.failed_kafka_events" */
export type Tenancy_Failed_Kafka_Events_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  eventName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Failed_Kafka_Events_Max_Fields = {
  __typename?: 'tenancy_failed_kafka_events_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  eventName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tenancy_Failed_Kafka_Events_Min_Fields = {
  __typename?: 'tenancy_failed_kafka_events_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  eventName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tenancy.failed_kafka_events" */
export type Tenancy_Failed_Kafka_Events_Mutation_Response = {
  __typename?: 'tenancy_failed_kafka_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Failed_Kafka_Events>;
};

/** on_conflict condition type for table "tenancy.failed_kafka_events" */
export type Tenancy_Failed_Kafka_Events_On_Conflict = {
  constraint: Tenancy_Failed_Kafka_Events_Constraint;
  update_columns?: Array<Tenancy_Failed_Kafka_Events_Update_Column>;
  where?: InputMaybe<Tenancy_Failed_Kafka_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.failed_kafka_events". */
export type Tenancy_Failed_Kafka_Events_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  eventName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.failed_kafka_events */
export type Tenancy_Failed_Kafka_Events_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.failed_kafka_events" */
export enum Tenancy_Failed_Kafka_Events_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventName = 'eventName',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "tenancy.failed_kafka_events" */
export type Tenancy_Failed_Kafka_Events_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  eventName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_failed_kafka_events" */
export type Tenancy_Failed_Kafka_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Failed_Kafka_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Failed_Kafka_Events_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  eventName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.failed_kafka_events" */
export enum Tenancy_Failed_Kafka_Events_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventName = 'eventName',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type Tenancy_Failed_Kafka_Events_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Failed_Kafka_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Failed_Kafka_Events_Bool_Exp;
};

/** columns and relationships of "tenancy.file" */
export type Tenancy_File = {
  __typename?: 'tenancy_file';
  /** An object relationship */
  account?: Maybe<Tenancy_Accounts>;
  accountId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  applet?: Maybe<Tenancy_Applet>;
  appletId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  project?: Maybe<Tenancy_Projects>;
  projectId?: Maybe<Scalars['String']['output']>;
  publicId: Scalars['String']['output'];
  settings?: Maybe<Scalars['jsonb']['output']>;
  url: Scalars['String']['output'];
  /** An object relationship */
  user?: Maybe<Tenancy_Users>;
  userId?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "tenancy.file" */
export type Tenancy_FileSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.file" */
export type Tenancy_File_Aggregate = {
  __typename?: 'tenancy_file_aggregate';
  aggregate?: Maybe<Tenancy_File_Aggregate_Fields>;
  nodes: Array<Tenancy_File>;
};

export type Tenancy_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_File_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.file" */
export type Tenancy_File_Aggregate_Fields = {
  __typename?: 'tenancy_file_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_File_Max_Fields>;
  min?: Maybe<Tenancy_File_Min_Fields>;
};


/** aggregate fields of "tenancy.file" */
export type Tenancy_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.file" */
export type Tenancy_File_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_File_Max_Order_By>;
  min?: InputMaybe<Tenancy_File_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_File_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.file" */
export type Tenancy_File_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_File_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.file". All fields are combined with a logical 'AND'. */
export type Tenancy_File_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_File_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_File_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_File_Bool_Exp>>;
  account?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  applet?: InputMaybe<Tenancy_Applet_Bool_Exp>;
  appletId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Tenancy_Projects_Bool_Exp>;
  projectId?: InputMaybe<String_Comparison_Exp>;
  publicId?: InputMaybe<String_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Tenancy_Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.file" */
export enum Tenancy_File_Constraint {
  /** unique or primary key constraint on columns "accountId" */
  FileAccountIdKey = 'file_accountId_key',
  /** unique or primary key constraint on columns "appletId" */
  FileAppletIdKey = 'file_appletId_key',
  /** unique or primary key constraint on columns "id" */
  FilePkey = 'file_pkey',
  /** unique or primary key constraint on columns "projectId" */
  FileProjectIdKey = 'file_projectId_key',
  /** unique or primary key constraint on columns "userId" */
  FileUserIdKey = 'file_userId_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_File_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_File_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_File_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.file" */
export type Tenancy_File_Insert_Input = {
  account?: InputMaybe<Tenancy_Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']['input']>;
  applet?: InputMaybe<Tenancy_Applet_Obj_Rel_Insert_Input>;
  appletId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Tenancy_Projects_Obj_Rel_Insert_Input>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  publicId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Tenancy_Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_File_Max_Fields = {
  __typename?: 'tenancy_file_max_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  appletId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  publicId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.file" */
export type Tenancy_File_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  appletId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  publicId?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_File_Min_Fields = {
  __typename?: 'tenancy_file_min_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  appletId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  publicId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.file" */
export type Tenancy_File_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  appletId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  publicId?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.file" */
export type Tenancy_File_Mutation_Response = {
  __typename?: 'tenancy_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_File>;
};

/** on_conflict condition type for table "tenancy.file" */
export type Tenancy_File_On_Conflict = {
  constraint: Tenancy_File_Constraint;
  update_columns?: Array<Tenancy_File_Update_Column>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.file". */
export type Tenancy_File_Order_By = {
  account?: InputMaybe<Tenancy_Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  applet?: InputMaybe<Tenancy_Applet_Order_By>;
  appletId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Tenancy_Projects_Order_By>;
  projectId?: InputMaybe<Order_By>;
  publicId?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user?: InputMaybe<Tenancy_Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.file */
export type Tenancy_File_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_File_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.file" */
export enum Tenancy_File_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AppletId = 'appletId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  PublicId = 'publicId',
  /** column name */
  Settings = 'settings',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "tenancy.file" */
export type Tenancy_File_Set_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  appletId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  publicId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_file" */
export type Tenancy_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_File_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  appletId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  publicId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.file" */
export enum Tenancy_File_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AppletId = 'appletId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  PublicId = 'publicId',
  /** column name */
  Settings = 'settings',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId'
}

export type Tenancy_File_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_File_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_File_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_File_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_File_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_File_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_File_Bool_Exp;
};

/** columns and relationships of "tenancy.locale" */
export type Tenancy_Locale = {
  __typename?: 'tenancy_locale';
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isoCode: Scalars['String']['output'];
  languageCode: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  /** An array relationship */
  localization_settings: Array<Tenancy_Localization_Settings>;
  /** An aggregate relationship */
  localization_settings_aggregate: Tenancy_Localization_Settings_Aggregate;
  name: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
};


/** columns and relationships of "tenancy.locale" */
export type Tenancy_LocaleLocalization_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


/** columns and relationships of "tenancy.locale" */
export type Tenancy_LocaleLocalization_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};

/** aggregated selection of "tenancy.locale" */
export type Tenancy_Locale_Aggregate = {
  __typename?: 'tenancy_locale_aggregate';
  aggregate?: Maybe<Tenancy_Locale_Aggregate_Fields>;
  nodes: Array<Tenancy_Locale>;
};

/** aggregate fields of "tenancy.locale" */
export type Tenancy_Locale_Aggregate_Fields = {
  __typename?: 'tenancy_locale_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Locale_Max_Fields>;
  min?: Maybe<Tenancy_Locale_Min_Fields>;
};


/** aggregate fields of "tenancy.locale" */
export type Tenancy_Locale_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Locale_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tenancy.locale". All fields are combined with a logical 'AND'. */
export type Tenancy_Locale_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Locale_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Locale_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Locale_Bool_Exp>>;
  countryCode?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isoCode?: InputMaybe<String_Comparison_Exp>;
  languageCode?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  localization_settings?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
  localization_settings_aggregate?: InputMaybe<Tenancy_Localization_Settings_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.locale" */
export enum Tenancy_Locale_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocalePkey = 'locale_pkey'
}

/** input type for inserting data into table "tenancy.locale" */
export type Tenancy_Locale_Insert_Input = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isoCode?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  localization_settings?: InputMaybe<Tenancy_Localization_Settings_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Locale_Max_Fields = {
  __typename?: 'tenancy_locale_max_fields';
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isoCode?: Maybe<Scalars['String']['output']>;
  languageCode?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tenancy_Locale_Min_Fields = {
  __typename?: 'tenancy_locale_min_fields';
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isoCode?: Maybe<Scalars['String']['output']>;
  languageCode?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tenancy.locale" */
export type Tenancy_Locale_Mutation_Response = {
  __typename?: 'tenancy_locale_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Locale>;
};

/** input type for inserting object relation for remote table "tenancy.locale" */
export type Tenancy_Locale_Obj_Rel_Insert_Input = {
  data: Tenancy_Locale_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Locale_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.locale" */
export type Tenancy_Locale_On_Conflict = {
  constraint: Tenancy_Locale_Constraint;
  update_columns?: Array<Tenancy_Locale_Update_Column>;
  where?: InputMaybe<Tenancy_Locale_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.locale". */
export type Tenancy_Locale_Order_By = {
  countryCode?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isoCode?: InputMaybe<Order_By>;
  languageCode?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  localization_settings_aggregate?: InputMaybe<Tenancy_Localization_Settings_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.locale */
export type Tenancy_Locale_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.locale" */
export enum Tenancy_Locale_Select_Column {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'isoCode',
  /** column name */
  LanguageCode = 'languageCode',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** input type for updating data in table "tenancy.locale" */
export type Tenancy_Locale_Set_Input = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isoCode?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_locale" */
export type Tenancy_Locale_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Locale_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Locale_Stream_Cursor_Value_Input = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isoCode?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.locale" */
export enum Tenancy_Locale_Update_Column {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'isoCode',
  /** column name */
  LanguageCode = 'languageCode',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Tenancy_Locale_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Locale_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Locale_Bool_Exp;
};

/** columns and relationships of "tenancy.localization_settings" */
export type Tenancy_Localization_Settings = {
  __typename?: 'tenancy_localization_settings';
  /** An object relationship */
  country?: Maybe<Tenancy_Country>;
  countryId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  locale?: Maybe<Tenancy_Locale>;
  localeId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  project?: Maybe<Tenancy_Projects>;
  projectId?: Maybe<Scalars['String']['output']>;
  timeZoneId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  time_zone?: Maybe<Tenancy_Time_Zone>;
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
  /** An object relationship */
  user?: Maybe<Tenancy_Users>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "tenancy.localization_settings" */
export type Tenancy_Localization_Settings_Aggregate = {
  __typename?: 'tenancy_localization_settings_aggregate';
  aggregate?: Maybe<Tenancy_Localization_Settings_Aggregate_Fields>;
  nodes: Array<Tenancy_Localization_Settings>;
};

export type Tenancy_Localization_Settings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Localization_Settings_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Localization_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.localization_settings" */
export type Tenancy_Localization_Settings_Aggregate_Fields = {
  __typename?: 'tenancy_localization_settings_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Localization_Settings_Max_Fields>;
  min?: Maybe<Tenancy_Localization_Settings_Min_Fields>;
};


/** aggregate fields of "tenancy.localization_settings" */
export type Tenancy_Localization_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.localization_settings" */
export type Tenancy_Localization_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Localization_Settings_Max_Order_By>;
  min?: InputMaybe<Tenancy_Localization_Settings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy.localization_settings" */
export type Tenancy_Localization_Settings_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Localization_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Localization_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.localization_settings". All fields are combined with a logical 'AND'. */
export type Tenancy_Localization_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Localization_Settings_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Localization_Settings_Bool_Exp>>;
  country?: InputMaybe<Tenancy_Country_Bool_Exp>;
  countryId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<Tenancy_Locale_Bool_Exp>;
  localeId?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Tenancy_Projects_Bool_Exp>;
  projectId?: InputMaybe<String_Comparison_Exp>;
  timeZoneId?: InputMaybe<String_Comparison_Exp>;
  time_zone?: InputMaybe<Tenancy_Time_Zone_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Tenancy_Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.localization_settings" */
export enum Tenancy_Localization_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocalizationSettingsPkey = 'localization_settings_pkey',
  /** unique or primary key constraint on columns "projectId" */
  LocalizationSettingsProjectIdKey = 'localization_settings_projectId_key',
  /** unique or primary key constraint on columns "userId" */
  LocalizationSettingsUserIdKey = 'localization_settings_userId_key'
}

/** input type for inserting data into table "tenancy.localization_settings" */
export type Tenancy_Localization_Settings_Insert_Input = {
  country?: InputMaybe<Tenancy_Country_Obj_Rel_Insert_Input>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Tenancy_Locale_Obj_Rel_Insert_Input>;
  localeId?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Tenancy_Projects_Obj_Rel_Insert_Input>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Tenancy_Time_Zone_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Tenancy_Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Localization_Settings_Max_Fields = {
  __typename?: 'tenancy_localization_settings_max_fields';
  countryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  localeId?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  timeZoneId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.localization_settings" */
export type Tenancy_Localization_Settings_Max_Order_By = {
  countryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  localeId?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  timeZoneId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Localization_Settings_Min_Fields = {
  __typename?: 'tenancy_localization_settings_min_fields';
  countryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  localeId?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  timeZoneId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.localization_settings" */
export type Tenancy_Localization_Settings_Min_Order_By = {
  countryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  localeId?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  timeZoneId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.localization_settings" */
export type Tenancy_Localization_Settings_Mutation_Response = {
  __typename?: 'tenancy_localization_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Localization_Settings>;
};

/** on_conflict condition type for table "tenancy.localization_settings" */
export type Tenancy_Localization_Settings_On_Conflict = {
  constraint: Tenancy_Localization_Settings_Constraint;
  update_columns?: Array<Tenancy_Localization_Settings_Update_Column>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.localization_settings". */
export type Tenancy_Localization_Settings_Order_By = {
  country?: InputMaybe<Tenancy_Country_Order_By>;
  countryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locale?: InputMaybe<Tenancy_Locale_Order_By>;
  localeId?: InputMaybe<Order_By>;
  project?: InputMaybe<Tenancy_Projects_Order_By>;
  projectId?: InputMaybe<Order_By>;
  timeZoneId?: InputMaybe<Order_By>;
  time_zone?: InputMaybe<Tenancy_Time_Zone_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  user?: InputMaybe<Tenancy_Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.localization_settings */
export type Tenancy_Localization_Settings_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.localization_settings" */
export enum Tenancy_Localization_Settings_Select_Column {
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  LocaleId = 'localeId',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  TimeZoneId = 'timeZoneId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "tenancy.localization_settings" */
export type Tenancy_Localization_Settings_Set_Input = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  localeId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_localization_settings" */
export type Tenancy_Localization_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Localization_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Localization_Settings_Stream_Cursor_Value_Input = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  localeId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.localization_settings" */
export enum Tenancy_Localization_Settings_Update_Column {
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  LocaleId = 'localeId',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  TimeZoneId = 'timeZoneId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  UserId = 'userId'
}

export type Tenancy_Localization_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Localization_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Localization_Settings_Bool_Exp;
};

/** columns and relationships of "tenancy.package" */
export type Tenancy_Package = {
  __typename?: 'tenancy_package';
  createdAt: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  latestVersionId?: Maybe<Scalars['String']['output']>;
  packageTypes?: Maybe<Array<Scalars['PackageType']['output']>>;
  /** An object relationship */
  package_version?: Maybe<Tenancy_Package_Version>;
  /** An array relationship */
  package_versions: Array<Tenancy_Package_Version>;
  /** An aggregate relationship */
  package_versions_aggregate: Tenancy_Package_Version_Aggregate;
  pkgName: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "tenancy.package" */
export type Tenancy_PackagePackage_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
};


/** columns and relationships of "tenancy.package" */
export type Tenancy_PackagePackage_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Version_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
};

/** aggregated selection of "tenancy.package" */
export type Tenancy_Package_Aggregate = {
  __typename?: 'tenancy_package_aggregate';
  aggregate?: Maybe<Tenancy_Package_Aggregate_Fields>;
  nodes: Array<Tenancy_Package>;
};

export type Tenancy_Package_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Package_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Package_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Package_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Package_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.package" */
export type Tenancy_Package_Aggregate_Fields = {
  __typename?: 'tenancy_package_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Package_Max_Fields>;
  min?: Maybe<Tenancy_Package_Min_Fields>;
};


/** aggregate fields of "tenancy.package" */
export type Tenancy_Package_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Package_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.package" */
export type Tenancy_Package_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Package_Max_Order_By>;
  min?: InputMaybe<Tenancy_Package_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy.package" */
export type Tenancy_Package_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Package_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Package_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.package". All fields are combined with a logical 'AND'. */
export type Tenancy_Package_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Package_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Package_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Package_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  latestVersionId?: InputMaybe<String_Comparison_Exp>;
  packageTypes?: InputMaybe<PackageType_Array_Comparison_Exp>;
  package_version?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
  package_versions?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
  package_versions_aggregate?: InputMaybe<Tenancy_Package_Version_Aggregate_Bool_Exp>;
  pkgName?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.package" */
export enum Tenancy_Package_Constraint {
  /** unique or primary key constraint on columns "latestVersionId" */
  PackageLatestVersionIdKey = 'package_latestVersionId_key',
  /** unique or primary key constraint on columns "id" */
  PackagePkey = 'package_pkey',
  /** unique or primary key constraint on columns "pkgName" */
  PackagePkgNameKey = 'package_pkgName_key'
}

/** input type for inserting data into table "tenancy.package" */
export type Tenancy_Package_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionId?: InputMaybe<Scalars['String']['input']>;
  packageTypes?: InputMaybe<Array<Scalars['PackageType']['input']>>;
  package_version?: InputMaybe<Tenancy_Package_Version_Obj_Rel_Insert_Input>;
  package_versions?: InputMaybe<Tenancy_Package_Version_Arr_Rel_Insert_Input>;
  pkgName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Package_Max_Fields = {
  __typename?: 'tenancy_package_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latestVersionId?: Maybe<Scalars['String']['output']>;
  packageTypes?: Maybe<Array<Scalars['PackageType']['output']>>;
  pkgName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "tenancy.package" */
export type Tenancy_Package_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionId?: InputMaybe<Order_By>;
  packageTypes?: InputMaybe<Order_By>;
  pkgName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Package_Min_Fields = {
  __typename?: 'tenancy_package_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latestVersionId?: Maybe<Scalars['String']['output']>;
  packageTypes?: Maybe<Array<Scalars['PackageType']['output']>>;
  pkgName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "tenancy.package" */
export type Tenancy_Package_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionId?: InputMaybe<Order_By>;
  packageTypes?: InputMaybe<Order_By>;
  pkgName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.package" */
export type Tenancy_Package_Mutation_Response = {
  __typename?: 'tenancy_package_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Package>;
};

/** input type for inserting object relation for remote table "tenancy.package" */
export type Tenancy_Package_Obj_Rel_Insert_Input = {
  data: Tenancy_Package_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Package_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.package" */
export type Tenancy_Package_On_Conflict = {
  constraint: Tenancy_Package_Constraint;
  update_columns?: Array<Tenancy_Package_Update_Column>;
  where?: InputMaybe<Tenancy_Package_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.package". */
export type Tenancy_Package_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionId?: InputMaybe<Order_By>;
  packageTypes?: InputMaybe<Order_By>;
  package_version?: InputMaybe<Tenancy_Package_Version_Order_By>;
  package_versions_aggregate?: InputMaybe<Tenancy_Package_Version_Aggregate_Order_By>;
  pkgName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.package */
export type Tenancy_Package_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.package" */
export enum Tenancy_Package_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  LatestVersionId = 'latestVersionId',
  /** column name */
  PackageTypes = 'packageTypes',
  /** column name */
  PkgName = 'pkgName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "tenancy.package" */
export type Tenancy_Package_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionId?: InputMaybe<Scalars['String']['input']>;
  packageTypes?: InputMaybe<Array<Scalars['PackageType']['input']>>;
  pkgName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "tenancy_package" */
export type Tenancy_Package_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Package_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Package_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionId?: InputMaybe<Scalars['String']['input']>;
  packageTypes?: InputMaybe<Array<Scalars['PackageType']['input']>>;
  pkgName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "tenancy.package" */
export enum Tenancy_Package_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  LatestVersionId = 'latestVersionId',
  /** column name */
  PackageTypes = 'packageTypes',
  /** column name */
  PkgName = 'pkgName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Tenancy_Package_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Package_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Package_Bool_Exp;
};

/** columns and relationships of "tenancy.package_version" */
export type Tenancy_Package_Version = {
  __typename?: 'tenancy_package_version';
  /** An array relationship */
  actions: Array<Tenancy_Action>;
  /** An aggregate relationship */
  actions_aggregate: Tenancy_Action_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  isLatestInMinor: Scalars['Boolean']['output'];
  /** An object relationship */
  package: Tenancy_Package;
  packageId: Scalars['String']['output'];
  packageJson: Scalars['jsonb']['output'];
  /** An array relationship */
  packages: Array<Tenancy_Package>;
  /** An aggregate relationship */
  packages_aggregate: Tenancy_Package_Aggregate;
  schema: Scalars['jsonb']['output'];
  /** An array relationship */
  triggers: Array<Tenancy_Trigger>;
  /** An aggregate relationship */
  triggers_aggregate: Tenancy_Trigger_Aggregate;
  version: Scalars['String']['output'];
};


/** columns and relationships of "tenancy.package_version" */
export type Tenancy_Package_VersionActionsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Action_Order_By>>;
  where?: InputMaybe<Tenancy_Action_Bool_Exp>;
};


/** columns and relationships of "tenancy.package_version" */
export type Tenancy_Package_VersionActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Action_Order_By>>;
  where?: InputMaybe<Tenancy_Action_Bool_Exp>;
};


/** columns and relationships of "tenancy.package_version" */
export type Tenancy_Package_VersionPackageJsonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tenancy.package_version" */
export type Tenancy_Package_VersionPackagesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Bool_Exp>;
};


/** columns and relationships of "tenancy.package_version" */
export type Tenancy_Package_VersionPackages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Package_Order_By>>;
  where?: InputMaybe<Tenancy_Package_Bool_Exp>;
};


/** columns and relationships of "tenancy.package_version" */
export type Tenancy_Package_VersionSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tenancy.package_version" */
export type Tenancy_Package_VersionTriggersArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Trigger_Order_By>>;
  where?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
};


/** columns and relationships of "tenancy.package_version" */
export type Tenancy_Package_VersionTriggers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Trigger_Order_By>>;
  where?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
};

/** aggregated selection of "tenancy.package_version" */
export type Tenancy_Package_Version_Aggregate = {
  __typename?: 'tenancy_package_version_aggregate';
  aggregate?: Maybe<Tenancy_Package_Version_Aggregate_Fields>;
  nodes: Array<Tenancy_Package_Version>;
};

export type Tenancy_Package_Version_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tenancy_Package_Version_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tenancy_Package_Version_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tenancy_Package_Version_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Package_Version_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenancy_Package_Version_Select_Column_Tenancy_Package_Version_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_Package_Version_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenancy_Package_Version_Select_Column_Tenancy_Package_Version_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_Package_Version_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Package_Version_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.package_version" */
export type Tenancy_Package_Version_Aggregate_Fields = {
  __typename?: 'tenancy_package_version_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Package_Version_Max_Fields>;
  min?: Maybe<Tenancy_Package_Version_Min_Fields>;
};


/** aggregate fields of "tenancy.package_version" */
export type Tenancy_Package_Version_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Package_Version_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.package_version" */
export type Tenancy_Package_Version_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Package_Version_Max_Order_By>;
  min?: InputMaybe<Tenancy_Package_Version_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Package_Version_Append_Input = {
  packageJson?: InputMaybe<Scalars['jsonb']['input']>;
  schema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.package_version" */
export type Tenancy_Package_Version_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Package_Version_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Package_Version_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.package_version". All fields are combined with a logical 'AND'. */
export type Tenancy_Package_Version_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Package_Version_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Package_Version_Bool_Exp>>;
  actions?: InputMaybe<Tenancy_Action_Bool_Exp>;
  actions_aggregate?: InputMaybe<Tenancy_Action_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isLatestInMinor?: InputMaybe<Boolean_Comparison_Exp>;
  package?: InputMaybe<Tenancy_Package_Bool_Exp>;
  packageId?: InputMaybe<String_Comparison_Exp>;
  packageJson?: InputMaybe<Jsonb_Comparison_Exp>;
  packages?: InputMaybe<Tenancy_Package_Bool_Exp>;
  packages_aggregate?: InputMaybe<Tenancy_Package_Aggregate_Bool_Exp>;
  schema?: InputMaybe<Jsonb_Comparison_Exp>;
  triggers?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
  triggers_aggregate?: InputMaybe<Tenancy_Trigger_Aggregate_Bool_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.package_version" */
export enum Tenancy_Package_Version_Constraint {
  /** unique or primary key constraint on columns "id" */
  PackageVersionPkey = 'package_version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Package_Version_Delete_At_Path_Input = {
  packageJson?: InputMaybe<Array<Scalars['String']['input']>>;
  schema?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Package_Version_Delete_Elem_Input = {
  packageJson?: InputMaybe<Scalars['Int']['input']>;
  schema?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Package_Version_Delete_Key_Input = {
  packageJson?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.package_version" */
export type Tenancy_Package_Version_Insert_Input = {
  actions?: InputMaybe<Tenancy_Action_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isLatestInMinor?: InputMaybe<Scalars['Boolean']['input']>;
  package?: InputMaybe<Tenancy_Package_Obj_Rel_Insert_Input>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  packageJson?: InputMaybe<Scalars['jsonb']['input']>;
  packages?: InputMaybe<Tenancy_Package_Arr_Rel_Insert_Input>;
  schema?: InputMaybe<Scalars['jsonb']['input']>;
  triggers?: InputMaybe<Tenancy_Trigger_Arr_Rel_Insert_Input>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Package_Version_Max_Fields = {
  __typename?: 'tenancy_package_version_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.package_version" */
export type Tenancy_Package_Version_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Package_Version_Min_Fields = {
  __typename?: 'tenancy_package_version_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.package_version" */
export type Tenancy_Package_Version_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.package_version" */
export type Tenancy_Package_Version_Mutation_Response = {
  __typename?: 'tenancy_package_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Package_Version>;
};

/** input type for inserting object relation for remote table "tenancy.package_version" */
export type Tenancy_Package_Version_Obj_Rel_Insert_Input = {
  data: Tenancy_Package_Version_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Package_Version_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.package_version" */
export type Tenancy_Package_Version_On_Conflict = {
  constraint: Tenancy_Package_Version_Constraint;
  update_columns?: Array<Tenancy_Package_Version_Update_Column>;
  where?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.package_version". */
export type Tenancy_Package_Version_Order_By = {
  actions_aggregate?: InputMaybe<Tenancy_Action_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isLatestInMinor?: InputMaybe<Order_By>;
  package?: InputMaybe<Tenancy_Package_Order_By>;
  packageId?: InputMaybe<Order_By>;
  packageJson?: InputMaybe<Order_By>;
  packages_aggregate?: InputMaybe<Tenancy_Package_Aggregate_Order_By>;
  schema?: InputMaybe<Order_By>;
  triggers_aggregate?: InputMaybe<Tenancy_Trigger_Aggregate_Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.package_version */
export type Tenancy_Package_Version_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Package_Version_Prepend_Input = {
  packageJson?: InputMaybe<Scalars['jsonb']['input']>;
  schema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.package_version" */
export enum Tenancy_Package_Version_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsLatestInMinor = 'isLatestInMinor',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  PackageJson = 'packageJson',
  /** column name */
  Schema = 'schema',
  /** column name */
  Version = 'version'
}

/** select "tenancy_package_version_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenancy.package_version" */
export enum Tenancy_Package_Version_Select_Column_Tenancy_Package_Version_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsLatestInMinor = 'isLatestInMinor'
}

/** select "tenancy_package_version_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenancy.package_version" */
export enum Tenancy_Package_Version_Select_Column_Tenancy_Package_Version_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsLatestInMinor = 'isLatestInMinor'
}

/** input type for updating data in table "tenancy.package_version" */
export type Tenancy_Package_Version_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isLatestInMinor?: InputMaybe<Scalars['Boolean']['input']>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  packageJson?: InputMaybe<Scalars['jsonb']['input']>;
  schema?: InputMaybe<Scalars['jsonb']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_package_version" */
export type Tenancy_Package_Version_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Package_Version_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Package_Version_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isLatestInMinor?: InputMaybe<Scalars['Boolean']['input']>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  packageJson?: InputMaybe<Scalars['jsonb']['input']>;
  schema?: InputMaybe<Scalars['jsonb']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.package_version" */
export enum Tenancy_Package_Version_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsLatestInMinor = 'isLatestInMinor',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  PackageJson = 'packageJson',
  /** column name */
  Schema = 'schema',
  /** column name */
  Version = 'version'
}

export type Tenancy_Package_Version_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Package_Version_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Package_Version_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Package_Version_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Package_Version_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Package_Version_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Package_Version_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Package_Version_Bool_Exp;
};

/** columns and relationships of "tenancy.products" */
export type Tenancy_Products = {
  __typename?: 'tenancy_products';
  /** An array relationship */
  audit_logs: Array<Tenancy_Audit_Logs>;
  /** An aggregate relationship */
  audit_logs_aggregate: Tenancy_Audit_Logs_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  project_product_settings: Array<Tenancy_Project_Product_Settings>;
  /** An aggregate relationship */
  project_product_settings_aggregate: Tenancy_Project_Product_Settings_Aggregate;
  /** An array relationship */
  roles: Array<Tenancy_Roles>;
  /** An aggregate relationship */
  roles_aggregate: Tenancy_Roles_Aggregate;
  settings?: Maybe<Scalars['jsonb']['output']>;
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "tenancy.products" */
export type Tenancy_ProductsAudit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Audit_Logs_Order_By>>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};


/** columns and relationships of "tenancy.products" */
export type Tenancy_ProductsAudit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Audit_Logs_Order_By>>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};


/** columns and relationships of "tenancy.products" */
export type Tenancy_ProductsProject_Product_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Product_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
};


/** columns and relationships of "tenancy.products" */
export type Tenancy_ProductsProject_Product_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Product_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
};


/** columns and relationships of "tenancy.products" */
export type Tenancy_ProductsRolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Roles_Order_By>>;
  where?: InputMaybe<Tenancy_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.products" */
export type Tenancy_ProductsRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Roles_Order_By>>;
  where?: InputMaybe<Tenancy_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.products" */
export type Tenancy_ProductsSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.products" */
export type Tenancy_Products_Aggregate = {
  __typename?: 'tenancy_products_aggregate';
  aggregate?: Maybe<Tenancy_Products_Aggregate_Fields>;
  nodes: Array<Tenancy_Products>;
};

/** aggregate fields of "tenancy.products" */
export type Tenancy_Products_Aggregate_Fields = {
  __typename?: 'tenancy_products_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Products_Max_Fields>;
  min?: Maybe<Tenancy_Products_Min_Fields>;
};


/** aggregate fields of "tenancy.products" */
export type Tenancy_Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Products_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "tenancy.products". All fields are combined with a logical 'AND'. */
export type Tenancy_Products_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Products_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Products_Bool_Exp>>;
  audit_logs?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
  audit_logs_aggregate?: InputMaybe<Tenancy_Audit_Logs_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project_product_settings?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
  project_product_settings_aggregate?: InputMaybe<Tenancy_Project_Product_Settings_Aggregate_Bool_Exp>;
  roles?: InputMaybe<Tenancy_Roles_Bool_Exp>;
  roles_aggregate?: InputMaybe<Tenancy_Roles_Aggregate_Bool_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.products" */
export enum Tenancy_Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductsPkey = 'products_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Products_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Products_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Products_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.products" */
export type Tenancy_Products_Insert_Input = {
  audit_logs?: InputMaybe<Tenancy_Audit_Logs_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project_product_settings?: InputMaybe<Tenancy_Project_Product_Settings_Arr_Rel_Insert_Input>;
  roles?: InputMaybe<Tenancy_Roles_Arr_Rel_Insert_Input>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Products_Max_Fields = {
  __typename?: 'tenancy_products_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Tenancy_Products_Min_Fields = {
  __typename?: 'tenancy_products_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "tenancy.products" */
export type Tenancy_Products_Mutation_Response = {
  __typename?: 'tenancy_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Products>;
};

/** input type for inserting object relation for remote table "tenancy.products" */
export type Tenancy_Products_Obj_Rel_Insert_Input = {
  data: Tenancy_Products_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Products_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.products" */
export type Tenancy_Products_On_Conflict = {
  constraint: Tenancy_Products_Constraint;
  update_columns?: Array<Tenancy_Products_Update_Column>;
  where?: InputMaybe<Tenancy_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.products". */
export type Tenancy_Products_Order_By = {
  audit_logs_aggregate?: InputMaybe<Tenancy_Audit_Logs_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_product_settings_aggregate?: InputMaybe<Tenancy_Project_Product_Settings_Aggregate_Order_By>;
  roles_aggregate?: InputMaybe<Tenancy_Roles_Aggregate_Order_By>;
  settings?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.products */
export type Tenancy_Products_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Products_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.products" */
export enum Tenancy_Products_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "tenancy.products" */
export type Tenancy_Products_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "tenancy_products" */
export type Tenancy_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Products_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "tenancy.products" */
export enum Tenancy_Products_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Tenancy_Products_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Products_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Products_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Products_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Products_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Products_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Products_Bool_Exp;
};

/** columns and relationships of "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings = {
  __typename?: 'tenancy_project_product_settings';
  /** An object relationship */
  product: Tenancy_Products;
  productId: Scalars['String']['output'];
  /** An object relationship */
  project: Tenancy_Projects;
  projectId: Scalars['String']['output'];
  settings?: Maybe<Scalars['jsonb']['output']>;
};


/** columns and relationships of "tenancy.project_product_settings" */
export type Tenancy_Project_Product_SettingsSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings_Aggregate = {
  __typename?: 'tenancy_project_product_settings_aggregate';
  aggregate?: Maybe<Tenancy_Project_Product_Settings_Aggregate_Fields>;
  nodes: Array<Tenancy_Project_Product_Settings>;
};

export type Tenancy_Project_Product_Settings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Project_Product_Settings_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Project_Product_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Project_Product_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings_Aggregate_Fields = {
  __typename?: 'tenancy_project_product_settings_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Project_Product_Settings_Max_Fields>;
  min?: Maybe<Tenancy_Project_Product_Settings_Min_Fields>;
};


/** aggregate fields of "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Project_Product_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Project_Product_Settings_Max_Order_By>;
  min?: InputMaybe<Tenancy_Project_Product_Settings_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Project_Product_Settings_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Project_Product_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Project_Product_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.project_product_settings". All fields are combined with a logical 'AND'. */
export type Tenancy_Project_Product_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Project_Product_Settings_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Project_Product_Settings_Bool_Exp>>;
  product?: InputMaybe<Tenancy_Products_Bool_Exp>;
  productId?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Tenancy_Projects_Bool_Exp>;
  projectId?: InputMaybe<String_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.project_product_settings" */
export enum Tenancy_Project_Product_Settings_Constraint {
  /** unique or primary key constraint on columns "projectId", "productId" */
  ProjectProductSettingsPkey = 'project_product_settings_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Project_Product_Settings_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Project_Product_Settings_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Project_Product_Settings_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings_Insert_Input = {
  product?: InputMaybe<Tenancy_Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Tenancy_Projects_Obj_Rel_Insert_Input>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Project_Product_Settings_Max_Fields = {
  __typename?: 'tenancy_project_product_settings_max_fields';
  productId?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings_Max_Order_By = {
  productId?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Project_Product_Settings_Min_Fields = {
  __typename?: 'tenancy_project_product_settings_min_fields';
  productId?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings_Min_Order_By = {
  productId?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings_Mutation_Response = {
  __typename?: 'tenancy_project_product_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Project_Product_Settings>;
};

/** on_conflict condition type for table "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings_On_Conflict = {
  constraint: Tenancy_Project_Product_Settings_Constraint;
  update_columns?: Array<Tenancy_Project_Product_Settings_Update_Column>;
  where?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.project_product_settings". */
export type Tenancy_Project_Product_Settings_Order_By = {
  product?: InputMaybe<Tenancy_Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  project?: InputMaybe<Tenancy_Projects_Order_By>;
  projectId?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.project_product_settings */
export type Tenancy_Project_Product_Settings_Pk_Columns_Input = {
  productId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Project_Product_Settings_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.project_product_settings" */
export enum Tenancy_Project_Product_Settings_Select_Column {
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  Settings = 'settings'
}

/** input type for updating data in table "tenancy.project_product_settings" */
export type Tenancy_Project_Product_Settings_Set_Input = {
  productId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "tenancy_project_product_settings" */
export type Tenancy_Project_Product_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Project_Product_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Project_Product_Settings_Stream_Cursor_Value_Input = {
  productId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "tenancy.project_product_settings" */
export enum Tenancy_Project_Product_Settings_Update_Column {
  /** column name */
  ProductId = 'productId',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  Settings = 'settings'
}

export type Tenancy_Project_Product_Settings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Project_Product_Settings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Project_Product_Settings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Project_Product_Settings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Project_Product_Settings_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Project_Product_Settings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Project_Product_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Project_Product_Settings_Bool_Exp;
};

/** columns and relationships of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config = {
  __typename?: 'tenancy_project_release_channel_config';
  /** An array relationship */
  _ProjectReleaseChannelConfigToVariables: Array<Tenancy__ProjectReleaseChannelConfigToVariable>;
  /** An aggregate relationship */
  _ProjectReleaseChannelConfigToVariables_aggregate: Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate;
  /** An array relationship */
  applet_instances: Array<Tenancy_Applet_Instance>;
  /** An aggregate relationship */
  applet_instances_aggregate: Tenancy_Applet_Instance_Aggregate;
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  domains: Array<Tenancy_Domain>;
  /** An aggregate relationship */
  domains_aggregate: Tenancy_Domain_Aggregate;
  id: Scalars['String']['output'];
  /** An object relationship */
  project: Tenancy_Projects;
  projectId: Scalars['String']['output'];
  releaseChannelId: Scalars['String']['output'];
  /** An object relationship */
  release_channel: Tenancy_Release_Channel;
  shortId?: Maybe<Scalars['String']['output']>;
  status: Scalars['ReleaseChannelStatus']['output'];
  /** An array relationship */
  team_account_release_channel_configs: Array<Tenancy_Team_Account_Release_Channel_Config>;
  /** An aggregate relationship */
  team_account_release_channel_configs_aggregate: Tenancy_Team_Account_Release_Channel_Config_Aggregate;
  /** An array relationship */
  user_account_release_channel_configs: Array<Tenancy_User_Account_Release_Channel_Config>;
  /** An aggregate relationship */
  user_account_release_channel_configs_aggregate: Tenancy_User_Account_Release_Channel_Config_Aggregate;
};


/** columns and relationships of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_ProjectReleaseChannelConfigToVariablesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Order_By>>;
  where?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
};


/** columns and relationships of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_ProjectReleaseChannelConfigToVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Order_By>>;
  where?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
};


/** columns and relationships of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_ConfigApplet_InstancesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Instance_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
};


/** columns and relationships of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_ConfigApplet_Instances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Applet_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Applet_Instance_Order_By>>;
  where?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
};


/** columns and relationships of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_ConfigDomainsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Domain_Order_By>>;
  where?: InputMaybe<Tenancy_Domain_Bool_Exp>;
};


/** columns and relationships of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_ConfigDomains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Domain_Order_By>>;
  where?: InputMaybe<Tenancy_Domain_Bool_Exp>;
};


/** columns and relationships of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_ConfigTeam_Account_Release_Channel_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
};


/** columns and relationships of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_ConfigTeam_Account_Release_Channel_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
};


/** columns and relationships of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_ConfigUser_Account_Release_Channel_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
};


/** columns and relationships of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_ConfigUser_Account_Release_Channel_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
};

/** aggregated selection of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Aggregate = {
  __typename?: 'tenancy_project_release_channel_config_aggregate';
  aggregate?: Maybe<Tenancy_Project_Release_Channel_Config_Aggregate_Fields>;
  nodes: Array<Tenancy_Project_Release_Channel_Config>;
};

export type Tenancy_Project_Release_Channel_Config_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Project_Release_Channel_Config_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Project_Release_Channel_Config_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Aggregate_Fields = {
  __typename?: 'tenancy_project_release_channel_config_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Project_Release_Channel_Config_Max_Fields>;
  min?: Maybe<Tenancy_Project_Release_Channel_Config_Min_Fields>;
};


/** aggregate fields of "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Project_Release_Channel_Config_Max_Order_By>;
  min?: InputMaybe<Tenancy_Project_Release_Channel_Config_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Project_Release_Channel_Config_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Project_Release_Channel_Config_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.project_release_channel_config". All fields are combined with a logical 'AND'. */
export type Tenancy_Project_Release_Channel_Config_Bool_Exp = {
  _ProjectReleaseChannelConfigToVariables?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
  _ProjectReleaseChannelConfigToVariables_aggregate?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Bool_Exp>>;
  applet_instances?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
  applet_instances_aggregate?: InputMaybe<Tenancy_Applet_Instance_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  domains?: InputMaybe<Tenancy_Domain_Bool_Exp>;
  domains_aggregate?: InputMaybe<Tenancy_Domain_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Tenancy_Projects_Bool_Exp>;
  projectId?: InputMaybe<String_Comparison_Exp>;
  releaseChannelId?: InputMaybe<String_Comparison_Exp>;
  release_channel?: InputMaybe<Tenancy_Release_Channel_Bool_Exp>;
  shortId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<ReleaseChannelStatus_Comparison_Exp>;
  team_account_release_channel_configs?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
  team_account_release_channel_configs_aggregate?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Aggregate_Bool_Exp>;
  user_account_release_channel_configs?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
  user_account_release_channel_configs_aggregate?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy.project_release_channel_config" */
export enum Tenancy_Project_Release_Channel_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectReleaseChannelConfigPkey = 'project_release_channel_config_pkey'
}

/** input type for inserting data into table "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Insert_Input = {
  _ProjectReleaseChannelConfigToVariables?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Arr_Rel_Insert_Input>;
  applet_instances?: InputMaybe<Tenancy_Applet_Instance_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Tenancy_Domain_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Tenancy_Projects_Obj_Rel_Insert_Input>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  releaseChannelId?: InputMaybe<Scalars['String']['input']>;
  release_channel?: InputMaybe<Tenancy_Release_Channel_Obj_Rel_Insert_Input>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['ReleaseChannelStatus']['input']>;
  team_account_release_channel_configs?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Arr_Rel_Insert_Input>;
  user_account_release_channel_configs?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy_Project_Release_Channel_Config_Max_Fields = {
  __typename?: 'tenancy_project_release_channel_config_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  releaseChannelId?: Maybe<Scalars['String']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['ReleaseChannelStatus']['output']>;
};

/** order by max() on columns of table "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  releaseChannelId?: InputMaybe<Order_By>;
  shortId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Project_Release_Channel_Config_Min_Fields = {
  __typename?: 'tenancy_project_release_channel_config_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  releaseChannelId?: Maybe<Scalars['String']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['ReleaseChannelStatus']['output']>;
};

/** order by min() on columns of table "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  releaseChannelId?: InputMaybe<Order_By>;
  shortId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Mutation_Response = {
  __typename?: 'tenancy_project_release_channel_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Project_Release_Channel_Config>;
};

/** input type for inserting object relation for remote table "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Obj_Rel_Insert_Input = {
  data: Tenancy_Project_Release_Channel_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Project_Release_Channel_Config_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_On_Conflict = {
  constraint: Tenancy_Project_Release_Channel_Config_Constraint;
  update_columns?: Array<Tenancy_Project_Release_Channel_Config_Update_Column>;
  where?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.project_release_channel_config". */
export type Tenancy_Project_Release_Channel_Config_Order_By = {
  _ProjectReleaseChannelConfigToVariables_aggregate?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate_Order_By>;
  applet_instances_aggregate?: InputMaybe<Tenancy_Applet_Instance_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  domains_aggregate?: InputMaybe<Tenancy_Domain_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Tenancy_Projects_Order_By>;
  projectId?: InputMaybe<Order_By>;
  releaseChannelId?: InputMaybe<Order_By>;
  release_channel?: InputMaybe<Tenancy_Release_Channel_Order_By>;
  shortId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_account_release_channel_configs_aggregate?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Aggregate_Order_By>;
  user_account_release_channel_configs_aggregate?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Aggregate_Order_By>;
};

/** primary key columns input for table: tenancy.project_release_channel_config */
export type Tenancy_Project_Release_Channel_Config_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.project_release_channel_config" */
export enum Tenancy_Project_Release_Channel_Config_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  ReleaseChannelId = 'releaseChannelId',
  /** column name */
  ShortId = 'shortId',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "tenancy.project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  releaseChannelId?: InputMaybe<Scalars['String']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['ReleaseChannelStatus']['input']>;
};

/** Streaming cursor of the table "tenancy_project_release_channel_config" */
export type Tenancy_Project_Release_Channel_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Project_Release_Channel_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Project_Release_Channel_Config_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  releaseChannelId?: InputMaybe<Scalars['String']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['ReleaseChannelStatus']['input']>;
};

/** update columns of table "tenancy.project_release_channel_config" */
export enum Tenancy_Project_Release_Channel_Config_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  ReleaseChannelId = 'releaseChannelId',
  /** column name */
  ShortId = 'shortId',
  /** column name */
  Status = 'status'
}

export type Tenancy_Project_Release_Channel_Config_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Project_Release_Channel_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Project_Release_Channel_Config_Bool_Exp;
};

/** columns and relationships of "tenancy.projects" */
export type Tenancy_Projects = {
  __typename?: 'tenancy_projects';
  /** An array relationship */
  _role_projects: Array<Tenancy__Role_Projects>;
  /** An aggregate relationship */
  _role_projects_aggregate: Tenancy__Role_Projects_Aggregate;
  /** An object relationship */
  account: Tenancy_Accounts;
  accountId: Scalars['String']['output'];
  ccSyncStatus: Scalars['ContactCenterSyncStatus']['output'];
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  files: Array<Tenancy_File>;
  /** An aggregate relationship */
  files_aggregate: Tenancy_File_Aggregate;
  id: Scalars['String']['output'];
  /** An array relationship */
  localization_settings: Array<Tenancy_Localization_Settings>;
  /** An aggregate relationship */
  localization_settings_aggregate: Tenancy_Localization_Settings_Aggregate;
  name: Scalars['String']['output'];
  picture?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  project_product_settings: Array<Tenancy_Project_Product_Settings>;
  /** An aggregate relationship */
  project_product_settings_aggregate: Tenancy_Project_Product_Settings_Aggregate;
  /** An array relationship */
  project_release_channel_configs: Array<Tenancy_Project_Release_Channel_Config>;
  /** An aggregate relationship */
  project_release_channel_configs_aggregate: Tenancy_Project_Release_Channel_Config_Aggregate;
  settings?: Maybe<Scalars['jsonb']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  team_account_projects: Array<Tenancy_Team_Account_Projects>;
  /** An aggregate relationship */
  team_account_projects_aggregate: Tenancy_Team_Account_Projects_Aggregate;
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
  /** An array relationship */
  user_account_projects: Array<Tenancy_User_Account_Projects>;
  /** An aggregate relationship */
  user_account_projects_aggregate: Tenancy_User_Account_Projects_Aggregate;
  /** An array relationship */
  variables: Array<Tenancy_Variable>;
  /** An aggregate relationship */
  variables_aggregate: Tenancy_Variable_Aggregate;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_Projects_Role_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Role_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Role_Projects_Order_By>>;
  where?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_Projects_Role_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Role_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Role_Projects_Order_By>>;
  where?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsFilesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsLocalization_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsLocalization_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsProject_Product_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Product_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsProject_Product_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Product_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Product_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsProject_Release_Channel_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsProject_Release_Channel_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsTeam_Account_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsTeam_Account_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsUser_Account_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsUser_Account_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsVariablesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Variable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Variable_Order_By>>;
  where?: InputMaybe<Tenancy_Variable_Bool_Exp>;
};


/** columns and relationships of "tenancy.projects" */
export type Tenancy_ProjectsVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Variable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Variable_Order_By>>;
  where?: InputMaybe<Tenancy_Variable_Bool_Exp>;
};

/** aggregated selection of "tenancy.projects" */
export type Tenancy_Projects_Aggregate = {
  __typename?: 'tenancy_projects_aggregate';
  aggregate?: Maybe<Tenancy_Projects_Aggregate_Fields>;
  nodes: Array<Tenancy_Projects>;
};

export type Tenancy_Projects_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Projects_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Projects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Projects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.projects" */
export type Tenancy_Projects_Aggregate_Fields = {
  __typename?: 'tenancy_projects_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Projects_Max_Fields>;
  min?: Maybe<Tenancy_Projects_Min_Fields>;
};


/** aggregate fields of "tenancy.projects" */
export type Tenancy_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.projects" */
export type Tenancy_Projects_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Projects_Max_Order_By>;
  min?: InputMaybe<Tenancy_Projects_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Projects_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.projects" */
export type Tenancy_Projects_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Projects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.projects". All fields are combined with a logical 'AND'. */
export type Tenancy_Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Projects_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Projects_Bool_Exp>>;
  _role_projects?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
  _role_projects_aggregate?: InputMaybe<Tenancy__Role_Projects_Aggregate_Bool_Exp>;
  account?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  ccSyncStatus?: InputMaybe<ContactCenterSyncStatus_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  files?: InputMaybe<Tenancy_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Tenancy_File_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  localization_settings?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
  localization_settings_aggregate?: InputMaybe<Tenancy_Localization_Settings_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  picture?: InputMaybe<String_Comparison_Exp>;
  project_product_settings?: InputMaybe<Tenancy_Project_Product_Settings_Bool_Exp>;
  project_product_settings_aggregate?: InputMaybe<Tenancy_Project_Product_Settings_Aggregate_Bool_Exp>;
  project_release_channel_configs?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
  project_release_channel_configs_aggregate?: InputMaybe<Tenancy_Project_Release_Channel_Config_Aggregate_Bool_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  shortId?: InputMaybe<String_Comparison_Exp>;
  team_account_projects?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
  team_account_projects_aggregate?: InputMaybe<Tenancy_Team_Account_Projects_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
  user_account_projects?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
  user_account_projects_aggregate?: InputMaybe<Tenancy_User_Account_Projects_Aggregate_Bool_Exp>;
  variables?: InputMaybe<Tenancy_Variable_Bool_Exp>;
  variables_aggregate?: InputMaybe<Tenancy_Variable_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy.projects" */
export enum Tenancy_Projects_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Projects_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Projects_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Projects_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.projects" */
export type Tenancy_Projects_Insert_Input = {
  _role_projects?: InputMaybe<Tenancy__Role_Projects_Arr_Rel_Insert_Input>;
  account?: InputMaybe<Tenancy_Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']['input']>;
  ccSyncStatus?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Tenancy_File_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  localization_settings?: InputMaybe<Tenancy_Localization_Settings_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  project_product_settings?: InputMaybe<Tenancy_Project_Product_Settings_Arr_Rel_Insert_Input>;
  project_release_channel_configs?: InputMaybe<Tenancy_Project_Release_Channel_Config_Arr_Rel_Insert_Input>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  team_account_projects?: InputMaybe<Tenancy_Team_Account_Projects_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  user_account_projects?: InputMaybe<Tenancy_User_Account_Projects_Arr_Rel_Insert_Input>;
  variables?: InputMaybe<Tenancy_Variable_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy_Projects_Max_Fields = {
  __typename?: 'tenancy_projects_max_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  ccSyncStatus?: Maybe<Scalars['ContactCenterSyncStatus']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.projects" */
export type Tenancy_Projects_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  ccSyncStatus?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  picture?: InputMaybe<Order_By>;
  shortId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Projects_Min_Fields = {
  __typename?: 'tenancy_projects_min_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  ccSyncStatus?: Maybe<Scalars['ContactCenterSyncStatus']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  shortId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.projects" */
export type Tenancy_Projects_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  ccSyncStatus?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  picture?: InputMaybe<Order_By>;
  shortId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.projects" */
export type Tenancy_Projects_Mutation_Response = {
  __typename?: 'tenancy_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Projects>;
};

/** input type for inserting object relation for remote table "tenancy.projects" */
export type Tenancy_Projects_Obj_Rel_Insert_Input = {
  data: Tenancy_Projects_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Projects_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.projects" */
export type Tenancy_Projects_On_Conflict = {
  constraint: Tenancy_Projects_Constraint;
  update_columns?: Array<Tenancy_Projects_Update_Column>;
  where?: InputMaybe<Tenancy_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.projects". */
export type Tenancy_Projects_Order_By = {
  _role_projects_aggregate?: InputMaybe<Tenancy__Role_Projects_Aggregate_Order_By>;
  account?: InputMaybe<Tenancy_Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  ccSyncStatus?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Tenancy_File_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  localization_settings_aggregate?: InputMaybe<Tenancy_Localization_Settings_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  picture?: InputMaybe<Order_By>;
  project_product_settings_aggregate?: InputMaybe<Tenancy_Project_Product_Settings_Aggregate_Order_By>;
  project_release_channel_configs_aggregate?: InputMaybe<Tenancy_Project_Release_Channel_Config_Aggregate_Order_By>;
  settings?: InputMaybe<Order_By>;
  shortId?: InputMaybe<Order_By>;
  team_account_projects_aggregate?: InputMaybe<Tenancy_Team_Account_Projects_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  user_account_projects_aggregate?: InputMaybe<Tenancy_User_Account_Projects_Aggregate_Order_By>;
  variables_aggregate?: InputMaybe<Tenancy_Variable_Aggregate_Order_By>;
};

/** primary key columns input for table: tenancy.projects */
export type Tenancy_Projects_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Projects_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.projects" */
export enum Tenancy_Projects_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CcSyncStatus = 'ccSyncStatus',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Picture = 'picture',
  /** column name */
  Settings = 'settings',
  /** column name */
  ShortId = 'shortId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** input type for updating data in table "tenancy.projects" */
export type Tenancy_Projects_Set_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  ccSyncStatus?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_projects" */
export type Tenancy_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Projects_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  ccSyncStatus?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.projects" */
export enum Tenancy_Projects_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CcSyncStatus = 'ccSyncStatus',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Picture = 'picture',
  /** column name */
  Settings = 'settings',
  /** column name */
  ShortId = 'shortId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Tenancy_Projects_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Projects_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Projects_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Projects_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Projects_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Projects_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Projects_Bool_Exp;
};

/** columns and relationships of "tenancy.release_channel" */
export type Tenancy_Release_Channel = {
  __typename?: 'tenancy_release_channel';
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  project_release_channel_configs: Array<Tenancy_Project_Release_Channel_Config>;
  /** An aggregate relationship */
  project_release_channel_configs_aggregate: Tenancy_Project_Release_Channel_Config_Aggregate;
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
};


/** columns and relationships of "tenancy.release_channel" */
export type Tenancy_Release_ChannelProject_Release_Channel_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
};


/** columns and relationships of "tenancy.release_channel" */
export type Tenancy_Release_ChannelProject_Release_Channel_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Project_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
};

/** aggregated selection of "tenancy.release_channel" */
export type Tenancy_Release_Channel_Aggregate = {
  __typename?: 'tenancy_release_channel_aggregate';
  aggregate?: Maybe<Tenancy_Release_Channel_Aggregate_Fields>;
  nodes: Array<Tenancy_Release_Channel>;
};

/** aggregate fields of "tenancy.release_channel" */
export type Tenancy_Release_Channel_Aggregate_Fields = {
  __typename?: 'tenancy_release_channel_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Release_Channel_Max_Fields>;
  min?: Maybe<Tenancy_Release_Channel_Min_Fields>;
};


/** aggregate fields of "tenancy.release_channel" */
export type Tenancy_Release_Channel_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Release_Channel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tenancy.release_channel". All fields are combined with a logical 'AND'. */
export type Tenancy_Release_Channel_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Release_Channel_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Release_Channel_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Release_Channel_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project_release_channel_configs?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
  project_release_channel_configs_aggregate?: InputMaybe<Tenancy_Project_Release_Channel_Config_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.release_channel" */
export enum Tenancy_Release_Channel_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReleaseChannelPkey = 'release_channel_pkey'
}

/** input type for inserting data into table "tenancy.release_channel" */
export type Tenancy_Release_Channel_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project_release_channel_configs?: InputMaybe<Tenancy_Project_Release_Channel_Config_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Release_Channel_Max_Fields = {
  __typename?: 'tenancy_release_channel_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tenancy_Release_Channel_Min_Fields = {
  __typename?: 'tenancy_release_channel_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tenancy.release_channel" */
export type Tenancy_Release_Channel_Mutation_Response = {
  __typename?: 'tenancy_release_channel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Release_Channel>;
};

/** input type for inserting object relation for remote table "tenancy.release_channel" */
export type Tenancy_Release_Channel_Obj_Rel_Insert_Input = {
  data: Tenancy_Release_Channel_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Release_Channel_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.release_channel" */
export type Tenancy_Release_Channel_On_Conflict = {
  constraint: Tenancy_Release_Channel_Constraint;
  update_columns?: Array<Tenancy_Release_Channel_Update_Column>;
  where?: InputMaybe<Tenancy_Release_Channel_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.release_channel". */
export type Tenancy_Release_Channel_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_release_channel_configs_aggregate?: InputMaybe<Tenancy_Project_Release_Channel_Config_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.release_channel */
export type Tenancy_Release_Channel_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.release_channel" */
export enum Tenancy_Release_Channel_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** input type for updating data in table "tenancy.release_channel" */
export type Tenancy_Release_Channel_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_release_channel" */
export type Tenancy_Release_Channel_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Release_Channel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Release_Channel_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.release_channel" */
export enum Tenancy_Release_Channel_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Tenancy_Release_Channel_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Release_Channel_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Release_Channel_Bool_Exp;
};

/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles = {
  __typename?: 'tenancy_roles';
  /** An array relationship */
  _role_projects: Array<Tenancy__Role_Projects>;
  /** An aggregate relationship */
  _role_projects_aggregate: Tenancy__Role_Projects_Aggregate;
  /** An array relationship */
  _team_account_project_roles: Array<Tenancy__Team_Account_Project_Roles>;
  /** An aggregate relationship */
  _team_account_project_roles_aggregate: Tenancy__Team_Account_Project_Roles_Aggregate;
  /** An array relationship */
  _team_account_release_channel_config_roles: Array<Tenancy__Team_Account_Release_Channel_Config_Roles>;
  /** An aggregate relationship */
  _team_account_release_channel_config_roles_aggregate: Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate;
  /** An array relationship */
  _team_account_roles: Array<Tenancy__Team_Account_Roles>;
  /** An aggregate relationship */
  _team_account_roles_aggregate: Tenancy__Team_Account_Roles_Aggregate;
  /** An array relationship */
  _user_account_project_roles: Array<Tenancy__User_Account_Project_Roles>;
  /** An aggregate relationship */
  _user_account_project_roles_aggregate: Tenancy__User_Account_Project_Roles_Aggregate;
  /** An array relationship */
  _user_account_release_channel_config_roles: Array<Tenancy__User_Account_Release_Channel_Config_Roles>;
  /** An aggregate relationship */
  _user_account_release_channel_config_roles_aggregate: Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate;
  /** An array relationship */
  _user_account_roles: Array<Tenancy__User_Account_Roles>;
  /** An aggregate relationship */
  _user_account_roles_aggregate: Tenancy__User_Account_Roles_Aggregate;
  /** An object relationship */
  account: Tenancy_Accounts;
  accountId: Scalars['String']['output'];
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions: Scalars['jsonb']['output'];
  /** An object relationship */
  product: Tenancy_Products;
  productId: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_Role_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Role_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Role_Projects_Order_By>>;
  where?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_Role_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Role_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Role_Projects_Order_By>>;
  where?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_Team_Account_Project_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_Team_Account_Project_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_Team_Account_Release_Channel_Config_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_Team_Account_Release_Channel_Config_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_Team_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_Team_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_User_Account_Project_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_User_Account_Project_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_User_Account_Release_Channel_Config_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_User_Account_Release_Channel_Config_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_User_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_Roles_User_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.roles" */
export type Tenancy_RolesPermissionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.roles" */
export type Tenancy_Roles_Aggregate = {
  __typename?: 'tenancy_roles_aggregate';
  aggregate?: Maybe<Tenancy_Roles_Aggregate_Fields>;
  nodes: Array<Tenancy_Roles>;
};

export type Tenancy_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Roles_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.roles" */
export type Tenancy_Roles_Aggregate_Fields = {
  __typename?: 'tenancy_roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Roles_Max_Fields>;
  min?: Maybe<Tenancy_Roles_Min_Fields>;
};


/** aggregate fields of "tenancy.roles" */
export type Tenancy_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.roles" */
export type Tenancy_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Roles_Max_Order_By>;
  min?: InputMaybe<Tenancy_Roles_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Roles_Append_Input = {
  permissions?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.roles" */
export type Tenancy_Roles_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.roles". All fields are combined with a logical 'AND'. */
export type Tenancy_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Roles_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Roles_Bool_Exp>>;
  _role_projects?: InputMaybe<Tenancy__Role_Projects_Bool_Exp>;
  _role_projects_aggregate?: InputMaybe<Tenancy__Role_Projects_Aggregate_Bool_Exp>;
  _team_account_project_roles?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
  _team_account_project_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Project_Roles_Aggregate_Bool_Exp>;
  _team_account_release_channel_config_roles?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
  _team_account_release_channel_config_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate_Bool_Exp>;
  _team_account_roles?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
  _team_account_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Roles_Aggregate_Bool_Exp>;
  _user_account_project_roles?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
  _user_account_project_roles_aggregate?: InputMaybe<Tenancy__User_Account_Project_Roles_Aggregate_Bool_Exp>;
  _user_account_release_channel_config_roles?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
  _user_account_release_channel_config_roles_aggregate?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate_Bool_Exp>;
  _user_account_roles?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
  _user_account_roles_aggregate?: InputMaybe<Tenancy__User_Account_Roles_Aggregate_Bool_Exp>;
  account?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  permissions?: InputMaybe<Jsonb_Comparison_Exp>;
  product?: InputMaybe<Tenancy_Products_Bool_Exp>;
  productId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.roles" */
export enum Tenancy_Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  RolesPkey = 'roles_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Roles_Delete_At_Path_Input = {
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Roles_Delete_Elem_Input = {
  permissions?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Roles_Delete_Key_Input = {
  permissions?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.roles" */
export type Tenancy_Roles_Insert_Input = {
  _role_projects?: InputMaybe<Tenancy__Role_Projects_Arr_Rel_Insert_Input>;
  _team_account_project_roles?: InputMaybe<Tenancy__Team_Account_Project_Roles_Arr_Rel_Insert_Input>;
  _team_account_release_channel_config_roles?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Arr_Rel_Insert_Input>;
  _team_account_roles?: InputMaybe<Tenancy__Team_Account_Roles_Arr_Rel_Insert_Input>;
  _user_account_project_roles?: InputMaybe<Tenancy__User_Account_Project_Roles_Arr_Rel_Insert_Input>;
  _user_account_release_channel_config_roles?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Arr_Rel_Insert_Input>;
  _user_account_roles?: InputMaybe<Tenancy__User_Account_Roles_Arr_Rel_Insert_Input>;
  account?: InputMaybe<Tenancy_Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Scalars['jsonb']['input']>;
  product?: InputMaybe<Tenancy_Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Roles_Max_Fields = {
  __typename?: 'tenancy_roles_max_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.roles" */
export type Tenancy_Roles_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Roles_Min_Fields = {
  __typename?: 'tenancy_roles_min_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.roles" */
export type Tenancy_Roles_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.roles" */
export type Tenancy_Roles_Mutation_Response = {
  __typename?: 'tenancy_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Roles>;
};

/** input type for inserting object relation for remote table "tenancy.roles" */
export type Tenancy_Roles_Obj_Rel_Insert_Input = {
  data: Tenancy_Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Roles_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.roles" */
export type Tenancy_Roles_On_Conflict = {
  constraint: Tenancy_Roles_Constraint;
  update_columns?: Array<Tenancy_Roles_Update_Column>;
  where?: InputMaybe<Tenancy_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.roles". */
export type Tenancy_Roles_Order_By = {
  _role_projects_aggregate?: InputMaybe<Tenancy__Role_Projects_Aggregate_Order_By>;
  _team_account_project_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Project_Roles_Aggregate_Order_By>;
  _team_account_release_channel_config_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate_Order_By>;
  _team_account_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Roles_Aggregate_Order_By>;
  _user_account_project_roles_aggregate?: InputMaybe<Tenancy__User_Account_Project_Roles_Aggregate_Order_By>;
  _user_account_release_channel_config_roles_aggregate?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate_Order_By>;
  _user_account_roles_aggregate?: InputMaybe<Tenancy__User_Account_Roles_Aggregate_Order_By>;
  account?: InputMaybe<Tenancy_Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  permissions?: InputMaybe<Order_By>;
  product?: InputMaybe<Tenancy_Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.roles */
export type Tenancy_Roles_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Roles_Prepend_Input = {
  permissions?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.roles" */
export enum Tenancy_Roles_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  ProductId = 'productId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** input type for updating data in table "tenancy.roles" */
export type Tenancy_Roles_Set_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Scalars['jsonb']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_roles" */
export type Tenancy_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Roles_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Scalars['jsonb']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.roles" */
export enum Tenancy_Roles_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  ProductId = 'productId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Tenancy_Roles_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Roles_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Roles_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Roles_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Roles_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Roles_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Roles_Bool_Exp;
};

/** columns and relationships of "tenancy.scheduled_jobs" */
export type Tenancy_Scheduled_Jobs = {
  __typename?: 'tenancy_scheduled_jobs';
  /** An array relationship */
  account_scheduled_jobs: Array<Tenancy_Account_Scheduled_Jobs>;
  /** An aggregate relationship */
  account_scheduled_jobs_aggregate: Tenancy_Account_Scheduled_Jobs_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isSystemJob: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "tenancy.scheduled_jobs" */
export type Tenancy_Scheduled_JobsAccount_Scheduled_JobsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
};


/** columns and relationships of "tenancy.scheduled_jobs" */
export type Tenancy_Scheduled_JobsAccount_Scheduled_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Account_Scheduled_Jobs_Order_By>>;
  where?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
};

/** aggregated selection of "tenancy.scheduled_jobs" */
export type Tenancy_Scheduled_Jobs_Aggregate = {
  __typename?: 'tenancy_scheduled_jobs_aggregate';
  aggregate?: Maybe<Tenancy_Scheduled_Jobs_Aggregate_Fields>;
  nodes: Array<Tenancy_Scheduled_Jobs>;
};

/** aggregate fields of "tenancy.scheduled_jobs" */
export type Tenancy_Scheduled_Jobs_Aggregate_Fields = {
  __typename?: 'tenancy_scheduled_jobs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Scheduled_Jobs_Max_Fields>;
  min?: Maybe<Tenancy_Scheduled_Jobs_Min_Fields>;
};


/** aggregate fields of "tenancy.scheduled_jobs" */
export type Tenancy_Scheduled_Jobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Scheduled_Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tenancy.scheduled_jobs". All fields are combined with a logical 'AND'. */
export type Tenancy_Scheduled_Jobs_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Scheduled_Jobs_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Scheduled_Jobs_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Scheduled_Jobs_Bool_Exp>>;
  account_scheduled_jobs?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Bool_Exp>;
  account_scheduled_jobs_aggregate?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isSystemJob?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.scheduled_jobs" */
export enum Tenancy_Scheduled_Jobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ScheduledJobsPkey = 'scheduled_jobs_pkey'
}

/** input type for inserting data into table "tenancy.scheduled_jobs" */
export type Tenancy_Scheduled_Jobs_Insert_Input = {
  account_scheduled_jobs?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isSystemJob?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Scheduled_Jobs_Max_Fields = {
  __typename?: 'tenancy_scheduled_jobs_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Tenancy_Scheduled_Jobs_Min_Fields = {
  __typename?: 'tenancy_scheduled_jobs_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "tenancy.scheduled_jobs" */
export type Tenancy_Scheduled_Jobs_Mutation_Response = {
  __typename?: 'tenancy_scheduled_jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Scheduled_Jobs>;
};

/** input type for inserting object relation for remote table "tenancy.scheduled_jobs" */
export type Tenancy_Scheduled_Jobs_Obj_Rel_Insert_Input = {
  data: Tenancy_Scheduled_Jobs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Scheduled_Jobs_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.scheduled_jobs" */
export type Tenancy_Scheduled_Jobs_On_Conflict = {
  constraint: Tenancy_Scheduled_Jobs_Constraint;
  update_columns?: Array<Tenancy_Scheduled_Jobs_Update_Column>;
  where?: InputMaybe<Tenancy_Scheduled_Jobs_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.scheduled_jobs". */
export type Tenancy_Scheduled_Jobs_Order_By = {
  account_scheduled_jobs_aggregate?: InputMaybe<Tenancy_Account_Scheduled_Jobs_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isSystemJob?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.scheduled_jobs */
export type Tenancy_Scheduled_Jobs_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.scheduled_jobs" */
export enum Tenancy_Scheduled_Jobs_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsSystemJob = 'isSystemJob',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "tenancy.scheduled_jobs" */
export type Tenancy_Scheduled_Jobs_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isSystemJob?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "tenancy_scheduled_jobs" */
export type Tenancy_Scheduled_Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Scheduled_Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Scheduled_Jobs_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isSystemJob?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "tenancy.scheduled_jobs" */
export enum Tenancy_Scheduled_Jobs_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsSystemJob = 'isSystemJob',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Tenancy_Scheduled_Jobs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Scheduled_Jobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Scheduled_Jobs_Bool_Exp;
};

/** columns and relationships of "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects = {
  __typename?: 'tenancy_team_account_projects';
  /** An array relationship */
  _team_account_project_roles: Array<Tenancy__Team_Account_Project_Roles>;
  /** An aggregate relationship */
  _team_account_project_roles_aggregate: Tenancy__Team_Account_Project_Roles_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  project: Tenancy_Projects;
  projectId: Scalars['String']['output'];
  settings?: Maybe<Scalars['jsonb']['output']>;
  teamAccountId: Scalars['String']['output'];
  /** An object relationship */
  team_account: Tenancy_Team_Accounts;
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Team_Account_Project_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Team_Account_Project_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.team_account_projects" */
export type Tenancy_Team_Account_ProjectsSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Aggregate = {
  __typename?: 'tenancy_team_account_projects_aggregate';
  aggregate?: Maybe<Tenancy_Team_Account_Projects_Aggregate_Fields>;
  nodes: Array<Tenancy_Team_Account_Projects>;
};

export type Tenancy_Team_Account_Projects_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Team_Account_Projects_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Team_Account_Projects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Team_Account_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Aggregate_Fields = {
  __typename?: 'tenancy_team_account_projects_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Team_Account_Projects_Max_Fields>;
  min?: Maybe<Tenancy_Team_Account_Projects_Min_Fields>;
};


/** aggregate fields of "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Team_Account_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Team_Account_Projects_Max_Order_By>;
  min?: InputMaybe<Tenancy_Team_Account_Projects_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Team_Account_Projects_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Team_Account_Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Team_Account_Projects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.team_account_projects". All fields are combined with a logical 'AND'. */
export type Tenancy_Team_Account_Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Team_Account_Projects_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Team_Account_Projects_Bool_Exp>>;
  _team_account_project_roles?: InputMaybe<Tenancy__Team_Account_Project_Roles_Bool_Exp>;
  _team_account_project_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Project_Roles_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Tenancy_Projects_Bool_Exp>;
  projectId?: InputMaybe<String_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  teamAccountId?: InputMaybe<String_Comparison_Exp>;
  team_account?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.team_account_projects" */
export enum Tenancy_Team_Account_Projects_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamAccountProjectsPkey = 'team_account_projects_pkey',
  /** unique or primary key constraint on columns "projectId", "teamAccountId" */
  TeamAccountProjectsTeamAccountIdProjectIdKey = 'team_account_projects_teamAccountId_projectId_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Team_Account_Projects_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Team_Account_Projects_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Team_Account_Projects_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Insert_Input = {
  _team_account_project_roles?: InputMaybe<Tenancy__Team_Account_Project_Roles_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Tenancy_Projects_Obj_Rel_Insert_Input>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  teamAccountId?: InputMaybe<Scalars['String']['input']>;
  team_account?: InputMaybe<Tenancy_Team_Accounts_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Team_Account_Projects_Max_Fields = {
  __typename?: 'tenancy_team_account_projects_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  teamAccountId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  teamAccountId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Team_Account_Projects_Min_Fields = {
  __typename?: 'tenancy_team_account_projects_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  teamAccountId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  teamAccountId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Mutation_Response = {
  __typename?: 'tenancy_team_account_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Team_Account_Projects>;
};

/** input type for inserting object relation for remote table "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Obj_Rel_Insert_Input = {
  data: Tenancy_Team_Account_Projects_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Team_Account_Projects_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_On_Conflict = {
  constraint: Tenancy_Team_Account_Projects_Constraint;
  update_columns?: Array<Tenancy_Team_Account_Projects_Update_Column>;
  where?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.team_account_projects". */
export type Tenancy_Team_Account_Projects_Order_By = {
  _team_account_project_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Project_Roles_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Tenancy_Projects_Order_By>;
  projectId?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
  teamAccountId?: InputMaybe<Order_By>;
  team_account?: InputMaybe<Tenancy_Team_Accounts_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.team_account_projects */
export type Tenancy_Team_Account_Projects_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Team_Account_Projects_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.team_account_projects" */
export enum Tenancy_Team_Account_Projects_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  Settings = 'settings',
  /** column name */
  TeamAccountId = 'teamAccountId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "tenancy.team_account_projects" */
export type Tenancy_Team_Account_Projects_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  teamAccountId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "tenancy_team_account_projects" */
export type Tenancy_Team_Account_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Team_Account_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Team_Account_Projects_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  teamAccountId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "tenancy.team_account_projects" */
export enum Tenancy_Team_Account_Projects_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  Settings = 'settings',
  /** column name */
  TeamAccountId = 'teamAccountId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Tenancy_Team_Account_Projects_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Team_Account_Projects_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Team_Account_Projects_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Team_Account_Projects_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Team_Account_Projects_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Team_Account_Projects_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Team_Account_Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Team_Account_Projects_Bool_Exp;
};

/** columns and relationships of "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config = {
  __typename?: 'tenancy_team_account_release_channel_config';
  /** An array relationship */
  _team_account_release_channel_config_roles: Array<Tenancy__Team_Account_Release_Channel_Config_Roles>;
  /** An aggregate relationship */
  _team_account_release_channel_config_roles_aggregate: Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate;
  id: Scalars['String']['output'];
  projectReleaseChannelConfigId: Scalars['String']['output'];
  /** An object relationship */
  project_release_channel_config: Tenancy_Project_Release_Channel_Config;
  teamAccountId: Scalars['String']['output'];
  /** An object relationship */
  team_account: Tenancy_Team_Accounts;
};


/** columns and relationships of "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Team_Account_Release_Channel_Config_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Team_Account_Release_Channel_Config_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
};

/** aggregated selection of "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Aggregate = {
  __typename?: 'tenancy_team_account_release_channel_config_aggregate';
  aggregate?: Maybe<Tenancy_Team_Account_Release_Channel_Config_Aggregate_Fields>;
  nodes: Array<Tenancy_Team_Account_Release_Channel_Config>;
};

export type Tenancy_Team_Account_Release_Channel_Config_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Team_Account_Release_Channel_Config_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Aggregate_Fields = {
  __typename?: 'tenancy_team_account_release_channel_config_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Team_Account_Release_Channel_Config_Max_Fields>;
  min?: Maybe<Tenancy_Team_Account_Release_Channel_Config_Min_Fields>;
};


/** aggregate fields of "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Max_Order_By>;
  min?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Team_Account_Release_Channel_Config_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.team_account_release_channel_config". All fields are combined with a logical 'AND'. */
export type Tenancy_Team_Account_Release_Channel_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>>;
  _team_account_release_channel_config_roles?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Bool_Exp>;
  _team_account_release_channel_config_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  projectReleaseChannelConfigId?: InputMaybe<String_Comparison_Exp>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
  teamAccountId?: InputMaybe<String_Comparison_Exp>;
  team_account?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy.team_account_release_channel_config" */
export enum Tenancy_Team_Account_Release_Channel_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamAccountReleaseChannelConfigPkey = 'team_account_release_channel_config_pkey'
}

/** input type for inserting data into table "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Insert_Input = {
  _team_account_release_channel_config_roles?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Obj_Rel_Insert_Input>;
  teamAccountId?: InputMaybe<Scalars['String']['input']>;
  team_account?: InputMaybe<Tenancy_Team_Accounts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy_Team_Account_Release_Channel_Config_Max_Fields = {
  __typename?: 'tenancy_team_account_release_channel_config_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  projectReleaseChannelConfigId?: Maybe<Scalars['String']['output']>;
  teamAccountId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  teamAccountId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Team_Account_Release_Channel_Config_Min_Fields = {
  __typename?: 'tenancy_team_account_release_channel_config_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  projectReleaseChannelConfigId?: Maybe<Scalars['String']['output']>;
  teamAccountId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  teamAccountId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Mutation_Response = {
  __typename?: 'tenancy_team_account_release_channel_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Team_Account_Release_Channel_Config>;
};

/** input type for inserting object relation for remote table "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Obj_Rel_Insert_Input = {
  data: Tenancy_Team_Account_Release_Channel_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_On_Conflict = {
  constraint: Tenancy_Team_Account_Release_Channel_Config_Constraint;
  update_columns?: Array<Tenancy_Team_Account_Release_Channel_Config_Update_Column>;
  where?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.team_account_release_channel_config". */
export type Tenancy_Team_Account_Release_Channel_Config_Order_By = {
  _team_account_release_channel_config_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Release_Channel_Config_Roles_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Order_By>;
  teamAccountId?: InputMaybe<Order_By>;
  team_account?: InputMaybe<Tenancy_Team_Accounts_Order_By>;
};

/** primary key columns input for table: tenancy.team_account_release_channel_config */
export type Tenancy_Team_Account_Release_Channel_Config_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.team_account_release_channel_config" */
export enum Tenancy_Team_Account_Release_Channel_Config_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProjectReleaseChannelConfigId = 'projectReleaseChannelConfigId',
  /** column name */
  TeamAccountId = 'teamAccountId'
}

/** input type for updating data in table "tenancy.team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  teamAccountId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_team_account_release_channel_config" */
export type Tenancy_Team_Account_Release_Channel_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Team_Account_Release_Channel_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Team_Account_Release_Channel_Config_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  teamAccountId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.team_account_release_channel_config" */
export enum Tenancy_Team_Account_Release_Channel_Config_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProjectReleaseChannelConfigId = 'projectReleaseChannelConfigId',
  /** column name */
  TeamAccountId = 'teamAccountId'
}

export type Tenancy_Team_Account_Release_Channel_Config_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Team_Account_Release_Channel_Config_Bool_Exp;
};

/** columns and relationships of "tenancy.team_accounts" */
export type Tenancy_Team_Accounts = {
  __typename?: 'tenancy_team_accounts';
  /** An array relationship */
  _team_account_roles: Array<Tenancy__Team_Account_Roles>;
  /** An aggregate relationship */
  _team_account_roles_aggregate: Tenancy__Team_Account_Roles_Aggregate;
  /** An object relationship */
  account: Tenancy_Accounts;
  accountId: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  isPrimaryAccount: Scalars['Boolean']['output'];
  settings?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  team: Tenancy_Teams;
  teamId: Scalars['String']['output'];
  /** An array relationship */
  team_account_projects: Array<Tenancy_Team_Account_Projects>;
  /** An aggregate relationship */
  team_account_projects_aggregate: Tenancy_Team_Account_Projects_Aggregate;
  /** An array relationship */
  team_account_release_channel_configs: Array<Tenancy_Team_Account_Release_Channel_Config>;
  /** An aggregate relationship */
  team_account_release_channel_configs_aggregate: Tenancy_Team_Account_Release_Channel_Config_Aggregate;
  updatedAt: Scalars['timestamp']['output'];
};


/** columns and relationships of "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Team_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Team_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__Team_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__Team_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.team_accounts" */
export type Tenancy_Team_AccountsSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tenancy.team_accounts" */
export type Tenancy_Team_AccountsTeam_Account_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.team_accounts" */
export type Tenancy_Team_AccountsTeam_Account_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.team_accounts" */
export type Tenancy_Team_AccountsTeam_Account_Release_Channel_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
};


/** columns and relationships of "tenancy.team_accounts" */
export type Tenancy_Team_AccountsTeam_Account_Release_Channel_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
};

/** aggregated selection of "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Aggregate = {
  __typename?: 'tenancy_team_accounts_aggregate';
  aggregate?: Maybe<Tenancy_Team_Accounts_Aggregate_Fields>;
  nodes: Array<Tenancy_Team_Accounts>;
};

export type Tenancy_Team_Accounts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tenancy_Team_Accounts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tenancy_Team_Accounts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tenancy_Team_Accounts_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Team_Accounts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenancy_Team_Accounts_Select_Column_Tenancy_Team_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_Team_Accounts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenancy_Team_Accounts_Select_Column_Tenancy_Team_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_Team_Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Team_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Aggregate_Fields = {
  __typename?: 'tenancy_team_accounts_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Team_Accounts_Max_Fields>;
  min?: Maybe<Tenancy_Team_Accounts_Min_Fields>;
};


/** aggregate fields of "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Team_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Team_Accounts_Max_Order_By>;
  min?: InputMaybe<Tenancy_Team_Accounts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Team_Accounts_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Team_Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Team_Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.team_accounts". All fields are combined with a logical 'AND'. */
export type Tenancy_Team_Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Team_Accounts_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Team_Accounts_Bool_Exp>>;
  _team_account_roles?: InputMaybe<Tenancy__Team_Account_Roles_Bool_Exp>;
  _team_account_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Roles_Aggregate_Bool_Exp>;
  account?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isPrimaryAccount?: InputMaybe<Boolean_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  team?: InputMaybe<Tenancy_Teams_Bool_Exp>;
  teamId?: InputMaybe<String_Comparison_Exp>;
  team_account_projects?: InputMaybe<Tenancy_Team_Account_Projects_Bool_Exp>;
  team_account_projects_aggregate?: InputMaybe<Tenancy_Team_Account_Projects_Aggregate_Bool_Exp>;
  team_account_release_channel_configs?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Bool_Exp>;
  team_account_release_channel_configs_aggregate?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.team_accounts" */
export enum Tenancy_Team_Accounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamAccountsPkey = 'team_accounts_pkey',
  /** unique or primary key constraint on columns "teamId", "accountId" */
  TeamAccountsTeamIdAccountIdKey = 'team_accounts_teamId_accountId_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Team_Accounts_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Team_Accounts_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Team_Accounts_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Insert_Input = {
  _team_account_roles?: InputMaybe<Tenancy__Team_Account_Roles_Arr_Rel_Insert_Input>;
  account?: InputMaybe<Tenancy_Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPrimaryAccount?: InputMaybe<Scalars['Boolean']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  team?: InputMaybe<Tenancy_Teams_Obj_Rel_Insert_Input>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  team_account_projects?: InputMaybe<Tenancy_Team_Account_Projects_Arr_Rel_Insert_Input>;
  team_account_release_channel_configs?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Team_Accounts_Max_Fields = {
  __typename?: 'tenancy_team_accounts_max_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  teamId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Team_Accounts_Min_Fields = {
  __typename?: 'tenancy_team_accounts_min_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  teamId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  teamId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Mutation_Response = {
  __typename?: 'tenancy_team_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Team_Accounts>;
};

/** input type for inserting object relation for remote table "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Obj_Rel_Insert_Input = {
  data: Tenancy_Team_Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Team_Accounts_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_On_Conflict = {
  constraint: Tenancy_Team_Accounts_Constraint;
  update_columns?: Array<Tenancy_Team_Accounts_Update_Column>;
  where?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.team_accounts". */
export type Tenancy_Team_Accounts_Order_By = {
  _team_account_roles_aggregate?: InputMaybe<Tenancy__Team_Account_Roles_Aggregate_Order_By>;
  account?: InputMaybe<Tenancy_Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isPrimaryAccount?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
  team?: InputMaybe<Tenancy_Teams_Order_By>;
  teamId?: InputMaybe<Order_By>;
  team_account_projects_aggregate?: InputMaybe<Tenancy_Team_Account_Projects_Aggregate_Order_By>;
  team_account_release_channel_configs_aggregate?: InputMaybe<Tenancy_Team_Account_Release_Channel_Config_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.team_accounts */
export type Tenancy_Team_Accounts_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Team_Accounts_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.team_accounts" */
export enum Tenancy_Team_Accounts_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPrimaryAccount = 'isPrimaryAccount',
  /** column name */
  Settings = 'settings',
  /** column name */
  TeamId = 'teamId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "tenancy_team_accounts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenancy.team_accounts" */
export enum Tenancy_Team_Accounts_Select_Column_Tenancy_Team_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPrimaryAccount = 'isPrimaryAccount'
}

/** select "tenancy_team_accounts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenancy.team_accounts" */
export enum Tenancy_Team_Accounts_Select_Column_Tenancy_Team_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPrimaryAccount = 'isPrimaryAccount'
}

/** input type for updating data in table "tenancy.team_accounts" */
export type Tenancy_Team_Accounts_Set_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPrimaryAccount?: InputMaybe<Scalars['Boolean']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "tenancy_team_accounts" */
export type Tenancy_Team_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Team_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Team_Accounts_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPrimaryAccount?: InputMaybe<Scalars['Boolean']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "tenancy.team_accounts" */
export enum Tenancy_Team_Accounts_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPrimaryAccount = 'isPrimaryAccount',
  /** column name */
  Settings = 'settings',
  /** column name */
  TeamId = 'teamId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Tenancy_Team_Accounts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Team_Accounts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Team_Accounts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Team_Accounts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Team_Accounts_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Team_Accounts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Team_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Team_Accounts_Bool_Exp;
};

/** columns and relationships of "tenancy.teams" */
export type Tenancy_Teams = {
  __typename?: 'tenancy_teams';
  /** An array relationship */
  _user_teams: Array<Tenancy__User_Teams>;
  /** An aggregate relationship */
  _user_teams_aggregate: Tenancy__User_Teams_Aggregate;
  ccSyncStatus: Scalars['ContactCenterSyncStatus']['output'];
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  settings?: Maybe<Scalars['jsonb']['output']>;
  /** An array relationship */
  team_accounts: Array<Tenancy_Team_Accounts>;
  /** An aggregate relationship */
  team_accounts_aggregate: Tenancy_Team_Accounts_Aggregate;
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
};


/** columns and relationships of "tenancy.teams" */
export type Tenancy_Teams_User_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Teams_Order_By>>;
  where?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
};


/** columns and relationships of "tenancy.teams" */
export type Tenancy_Teams_User_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Teams_Order_By>>;
  where?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
};


/** columns and relationships of "tenancy.teams" */
export type Tenancy_TeamsSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tenancy.teams" */
export type Tenancy_TeamsTeam_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenancy.teams" */
export type Tenancy_TeamsTeam_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Team_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Team_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
};

/** aggregated selection of "tenancy.teams" */
export type Tenancy_Teams_Aggregate = {
  __typename?: 'tenancy_teams_aggregate';
  aggregate?: Maybe<Tenancy_Teams_Aggregate_Fields>;
  nodes: Array<Tenancy_Teams>;
};

/** aggregate fields of "tenancy.teams" */
export type Tenancy_Teams_Aggregate_Fields = {
  __typename?: 'tenancy_teams_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Teams_Max_Fields>;
  min?: Maybe<Tenancy_Teams_Min_Fields>;
};


/** aggregate fields of "tenancy.teams" */
export type Tenancy_Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Teams_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "tenancy.teams". All fields are combined with a logical 'AND'. */
export type Tenancy_Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Teams_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Teams_Bool_Exp>>;
  _user_teams?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
  _user_teams_aggregate?: InputMaybe<Tenancy__User_Teams_Aggregate_Bool_Exp>;
  ccSyncStatus?: InputMaybe<ContactCenterSyncStatus_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  team_accounts?: InputMaybe<Tenancy_Team_Accounts_Bool_Exp>;
  team_accounts_aggregate?: InputMaybe<Tenancy_Team_Accounts_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.teams" */
export enum Tenancy_Teams_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamsPkey = 'teams_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Teams_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Teams_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Teams_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.teams" */
export type Tenancy_Teams_Insert_Input = {
  _user_teams?: InputMaybe<Tenancy__User_Teams_Arr_Rel_Insert_Input>;
  ccSyncStatus?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  team_accounts?: InputMaybe<Tenancy_Team_Accounts_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Teams_Max_Fields = {
  __typename?: 'tenancy_teams_max_fields';
  ccSyncStatus?: Maybe<Scalars['ContactCenterSyncStatus']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tenancy_Teams_Min_Fields = {
  __typename?: 'tenancy_teams_min_fields';
  ccSyncStatus?: Maybe<Scalars['ContactCenterSyncStatus']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tenancy.teams" */
export type Tenancy_Teams_Mutation_Response = {
  __typename?: 'tenancy_teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Teams>;
};

/** input type for inserting object relation for remote table "tenancy.teams" */
export type Tenancy_Teams_Obj_Rel_Insert_Input = {
  data: Tenancy_Teams_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Teams_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.teams" */
export type Tenancy_Teams_On_Conflict = {
  constraint: Tenancy_Teams_Constraint;
  update_columns?: Array<Tenancy_Teams_Update_Column>;
  where?: InputMaybe<Tenancy_Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.teams". */
export type Tenancy_Teams_Order_By = {
  _user_teams_aggregate?: InputMaybe<Tenancy__User_Teams_Aggregate_Order_By>;
  ccSyncStatus?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
  team_accounts_aggregate?: InputMaybe<Tenancy_Team_Accounts_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.teams */
export type Tenancy_Teams_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Teams_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.teams" */
export enum Tenancy_Teams_Select_Column {
  /** column name */
  CcSyncStatus = 'ccSyncStatus',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** input type for updating data in table "tenancy.teams" */
export type Tenancy_Teams_Set_Input = {
  ccSyncStatus?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_teams" */
export type Tenancy_Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Teams_Stream_Cursor_Value_Input = {
  ccSyncStatus?: InputMaybe<Scalars['ContactCenterSyncStatus']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.teams" */
export enum Tenancy_Teams_Update_Column {
  /** column name */
  CcSyncStatus = 'ccSyncStatus',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Tenancy_Teams_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Teams_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Teams_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Teams_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Teams_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Teams_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Teams_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Teams_Bool_Exp;
};

/** columns and relationships of "tenancy.time_zone" */
export type Tenancy_Time_Zone = {
  __typename?: 'tenancy_time_zone';
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  localization_settings: Array<Tenancy_Localization_Settings>;
  /** An aggregate relationship */
  localization_settings_aggregate: Tenancy_Localization_Settings_Aggregate;
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
};


/** columns and relationships of "tenancy.time_zone" */
export type Tenancy_Time_ZoneLocalization_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


/** columns and relationships of "tenancy.time_zone" */
export type Tenancy_Time_ZoneLocalization_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};

/** aggregated selection of "tenancy.time_zone" */
export type Tenancy_Time_Zone_Aggregate = {
  __typename?: 'tenancy_time_zone_aggregate';
  aggregate?: Maybe<Tenancy_Time_Zone_Aggregate_Fields>;
  nodes: Array<Tenancy_Time_Zone>;
};

/** aggregate fields of "tenancy.time_zone" */
export type Tenancy_Time_Zone_Aggregate_Fields = {
  __typename?: 'tenancy_time_zone_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Time_Zone_Max_Fields>;
  min?: Maybe<Tenancy_Time_Zone_Min_Fields>;
};


/** aggregate fields of "tenancy.time_zone" */
export type Tenancy_Time_Zone_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Time_Zone_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tenancy.time_zone". All fields are combined with a logical 'AND'. */
export type Tenancy_Time_Zone_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Time_Zone_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Time_Zone_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Time_Zone_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  identifier?: InputMaybe<String_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  localization_settings?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
  localization_settings_aggregate?: InputMaybe<Tenancy_Localization_Settings_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.time_zone" */
export enum Tenancy_Time_Zone_Constraint {
  /** unique or primary key constraint on columns "id" */
  TimeZonePkey = 'time_zone_pkey'
}

/** input type for inserting data into table "tenancy.time_zone" */
export type Tenancy_Time_Zone_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  localization_settings?: InputMaybe<Tenancy_Localization_Settings_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Time_Zone_Max_Fields = {
  __typename?: 'tenancy_time_zone_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tenancy_Time_Zone_Min_Fields = {
  __typename?: 'tenancy_time_zone_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tenancy.time_zone" */
export type Tenancy_Time_Zone_Mutation_Response = {
  __typename?: 'tenancy_time_zone_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Time_Zone>;
};

/** input type for inserting object relation for remote table "tenancy.time_zone" */
export type Tenancy_Time_Zone_Obj_Rel_Insert_Input = {
  data: Tenancy_Time_Zone_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Time_Zone_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.time_zone" */
export type Tenancy_Time_Zone_On_Conflict = {
  constraint: Tenancy_Time_Zone_Constraint;
  update_columns?: Array<Tenancy_Time_Zone_Update_Column>;
  where?: InputMaybe<Tenancy_Time_Zone_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.time_zone". */
export type Tenancy_Time_Zone_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  localization_settings_aggregate?: InputMaybe<Tenancy_Localization_Settings_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.time_zone */
export type Tenancy_Time_Zone_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.time_zone" */
export enum Tenancy_Time_Zone_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

/** input type for updating data in table "tenancy.time_zone" */
export type Tenancy_Time_Zone_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_time_zone" */
export type Tenancy_Time_Zone_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Time_Zone_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Time_Zone_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.time_zone" */
export enum Tenancy_Time_Zone_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy'
}

export type Tenancy_Time_Zone_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Time_Zone_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Time_Zone_Bool_Exp;
};

/** columns and relationships of "tenancy.trigger" */
export type Tenancy_Trigger = {
  __typename?: 'tenancy_trigger';
  appletInstanceId: Scalars['String']['output'];
  /** An object relationship */
  applet_instance: Tenancy_Applet_Instance;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  latestVersionWhileCreating?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  packageVersionId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  package_version?: Maybe<Tenancy_Package_Version>;
};


/** columns and relationships of "tenancy.trigger" */
export type Tenancy_TriggerOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.trigger" */
export type Tenancy_Trigger_Aggregate = {
  __typename?: 'tenancy_trigger_aggregate';
  aggregate?: Maybe<Tenancy_Trigger_Aggregate_Fields>;
  nodes: Array<Tenancy_Trigger>;
};

export type Tenancy_Trigger_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Trigger_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Trigger_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Trigger_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.trigger" */
export type Tenancy_Trigger_Aggregate_Fields = {
  __typename?: 'tenancy_trigger_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Trigger_Max_Fields>;
  min?: Maybe<Tenancy_Trigger_Min_Fields>;
};


/** aggregate fields of "tenancy.trigger" */
export type Tenancy_Trigger_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Trigger_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.trigger" */
export type Tenancy_Trigger_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Trigger_Max_Order_By>;
  min?: InputMaybe<Tenancy_Trigger_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Trigger_Append_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.trigger" */
export type Tenancy_Trigger_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Trigger_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Trigger_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.trigger". All fields are combined with a logical 'AND'. */
export type Tenancy_Trigger_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Trigger_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Trigger_Bool_Exp>>;
  appletInstanceId?: InputMaybe<String_Comparison_Exp>;
  applet_instance?: InputMaybe<Tenancy_Applet_Instance_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  latestVersionWhileCreating?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
  packageId?: InputMaybe<String_Comparison_Exp>;
  packageVersionId?: InputMaybe<String_Comparison_Exp>;
  package_version?: InputMaybe<Tenancy_Package_Version_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy.trigger" */
export enum Tenancy_Trigger_Constraint {
  /** unique or primary key constraint on columns "id" */
  TriggerPkey = 'trigger_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Trigger_Delete_At_Path_Input = {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Trigger_Delete_Elem_Input = {
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Trigger_Delete_Key_Input = {
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.trigger" */
export type Tenancy_Trigger_Insert_Input = {
  appletInstanceId?: InputMaybe<Scalars['String']['input']>;
  applet_instance?: InputMaybe<Tenancy_Applet_Instance_Obj_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionWhileCreating?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
  package_version?: InputMaybe<Tenancy_Package_Version_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy_Trigger_Max_Fields = {
  __typename?: 'tenancy_trigger_max_fields';
  appletInstanceId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latestVersionWhileCreating?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  packageVersionId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.trigger" */
export type Tenancy_Trigger_Max_Order_By = {
  appletInstanceId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionWhileCreating?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  packageVersionId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Trigger_Min_Fields = {
  __typename?: 'tenancy_trigger_min_fields';
  appletInstanceId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latestVersionWhileCreating?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  packageVersionId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.trigger" */
export type Tenancy_Trigger_Min_Order_By = {
  appletInstanceId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionWhileCreating?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  packageVersionId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.trigger" */
export type Tenancy_Trigger_Mutation_Response = {
  __typename?: 'tenancy_trigger_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Trigger>;
};

/** on_conflict condition type for table "tenancy.trigger" */
export type Tenancy_Trigger_On_Conflict = {
  constraint: Tenancy_Trigger_Constraint;
  update_columns?: Array<Tenancy_Trigger_Update_Column>;
  where?: InputMaybe<Tenancy_Trigger_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.trigger". */
export type Tenancy_Trigger_Order_By = {
  appletInstanceId?: InputMaybe<Order_By>;
  applet_instance?: InputMaybe<Tenancy_Applet_Instance_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestVersionWhileCreating?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
  packageId?: InputMaybe<Order_By>;
  packageVersionId?: InputMaybe<Order_By>;
  package_version?: InputMaybe<Tenancy_Package_Version_Order_By>;
};

/** primary key columns input for table: tenancy.trigger */
export type Tenancy_Trigger_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Trigger_Prepend_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.trigger" */
export enum Tenancy_Trigger_Select_Column {
  /** column name */
  AppletInstanceId = 'appletInstanceId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LatestVersionWhileCreating = 'latestVersionWhileCreating',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  PackageVersionId = 'packageVersionId'
}

/** input type for updating data in table "tenancy.trigger" */
export type Tenancy_Trigger_Set_Input = {
  appletInstanceId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionWhileCreating?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_trigger" */
export type Tenancy_Trigger_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Trigger_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Trigger_Stream_Cursor_Value_Input = {
  appletInstanceId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latestVersionWhileCreating?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.trigger" */
export enum Tenancy_Trigger_Update_Column {
  /** column name */
  AppletInstanceId = 'appletInstanceId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LatestVersionWhileCreating = 'latestVersionWhileCreating',
  /** column name */
  Name = 'name',
  /** column name */
  Options = 'options',
  /** column name */
  PackageId = 'packageId',
  /** column name */
  PackageVersionId = 'packageVersionId'
}

export type Tenancy_Trigger_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Trigger_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Trigger_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Trigger_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Trigger_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Trigger_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Trigger_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Trigger_Bool_Exp;
};

/** columns and relationships of "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects = {
  __typename?: 'tenancy_user_account_projects';
  /** An array relationship */
  _user_account_project_roles: Array<Tenancy__User_Account_Project_Roles>;
  /** An aggregate relationship */
  _user_account_project_roles_aggregate: Tenancy__User_Account_Project_Roles_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  project: Tenancy_Projects;
  projectId: Scalars['String']['output'];
  settings?: Maybe<Scalars['jsonb']['output']>;
  updatedAt: Scalars['timestamp']['output'];
  userAccountId: Scalars['String']['output'];
  /** An object relationship */
  user_account: Tenancy_User_Accounts;
};


/** columns and relationships of "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_User_Account_Project_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_User_Account_Project_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Project_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.user_account_projects" */
export type Tenancy_User_Account_ProjectsSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_Aggregate = {
  __typename?: 'tenancy_user_account_projects_aggregate';
  aggregate?: Maybe<Tenancy_User_Account_Projects_Aggregate_Fields>;
  nodes: Array<Tenancy_User_Account_Projects>;
};

export type Tenancy_User_Account_Projects_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_User_Account_Projects_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_User_Account_Projects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_User_Account_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_Aggregate_Fields = {
  __typename?: 'tenancy_user_account_projects_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_User_Account_Projects_Max_Fields>;
  min?: Maybe<Tenancy_User_Account_Projects_Min_Fields>;
};


/** aggregate fields of "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_User_Account_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_User_Account_Projects_Max_Order_By>;
  min?: InputMaybe<Tenancy_User_Account_Projects_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_User_Account_Projects_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_User_Account_Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_User_Account_Projects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.user_account_projects". All fields are combined with a logical 'AND'. */
export type Tenancy_User_Account_Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_User_Account_Projects_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_User_Account_Projects_Bool_Exp>>;
  _user_account_project_roles?: InputMaybe<Tenancy__User_Account_Project_Roles_Bool_Exp>;
  _user_account_project_roles_aggregate?: InputMaybe<Tenancy__User_Account_Project_Roles_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Tenancy_Projects_Bool_Exp>;
  projectId?: InputMaybe<String_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  userAccountId?: InputMaybe<String_Comparison_Exp>;
  user_account?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy.user_account_projects" */
export enum Tenancy_User_Account_Projects_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserAccountProjectsPkey = 'user_account_projects_pkey',
  /** unique or primary key constraint on columns "projectId", "userAccountId" */
  UserAccountProjectsUserAccountIdProjectIdKey = 'user_account_projects_userAccountId_projectId_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_User_Account_Projects_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_User_Account_Projects_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_User_Account_Projects_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_Insert_Input = {
  _user_account_project_roles?: InputMaybe<Tenancy__User_Account_Project_Roles_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Tenancy_Projects_Obj_Rel_Insert_Input>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userAccountId?: InputMaybe<Scalars['String']['input']>;
  user_account?: InputMaybe<Tenancy_User_Accounts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy_User_Account_Projects_Max_Fields = {
  __typename?: 'tenancy_user_account_projects_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userAccountId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userAccountId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_User_Account_Projects_Min_Fields = {
  __typename?: 'tenancy_user_account_projects_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userAccountId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userAccountId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_Mutation_Response = {
  __typename?: 'tenancy_user_account_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_User_Account_Projects>;
};

/** input type for inserting object relation for remote table "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_Obj_Rel_Insert_Input = {
  data: Tenancy_User_Account_Projects_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_User_Account_Projects_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_On_Conflict = {
  constraint: Tenancy_User_Account_Projects_Constraint;
  update_columns?: Array<Tenancy_User_Account_Projects_Update_Column>;
  where?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.user_account_projects". */
export type Tenancy_User_Account_Projects_Order_By = {
  _user_account_project_roles_aggregate?: InputMaybe<Tenancy__User_Account_Project_Roles_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Tenancy_Projects_Order_By>;
  projectId?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userAccountId?: InputMaybe<Order_By>;
  user_account?: InputMaybe<Tenancy_User_Accounts_Order_By>;
};

/** primary key columns input for table: tenancy.user_account_projects */
export type Tenancy_User_Account_Projects_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_User_Account_Projects_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.user_account_projects" */
export enum Tenancy_User_Account_Projects_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserAccountId = 'userAccountId'
}

/** input type for updating data in table "tenancy.user_account_projects" */
export type Tenancy_User_Account_Projects_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userAccountId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_user_account_projects" */
export type Tenancy_User_Account_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_User_Account_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_User_Account_Projects_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userAccountId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.user_account_projects" */
export enum Tenancy_User_Account_Projects_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserAccountId = 'userAccountId'
}

export type Tenancy_User_Account_Projects_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_User_Account_Projects_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_User_Account_Projects_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_User_Account_Projects_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_User_Account_Projects_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_User_Account_Projects_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_User_Account_Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_User_Account_Projects_Bool_Exp;
};

/** columns and relationships of "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config = {
  __typename?: 'tenancy_user_account_release_channel_config';
  /** An array relationship */
  _user_account_release_channel_config_roles: Array<Tenancy__User_Account_Release_Channel_Config_Roles>;
  /** An aggregate relationship */
  _user_account_release_channel_config_roles_aggregate: Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate;
  id: Scalars['String']['output'];
  projectReleaseChannelConfigId: Scalars['String']['output'];
  /** An object relationship */
  project_release_channel_config: Tenancy_Project_Release_Channel_Config;
  userAccountId: Scalars['String']['output'];
  /** An object relationship */
  user_account: Tenancy_User_Accounts;
};


/** columns and relationships of "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_User_Account_Release_Channel_Config_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_User_Account_Release_Channel_Config_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Release_Channel_Config_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
};

/** aggregated selection of "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Aggregate = {
  __typename?: 'tenancy_user_account_release_channel_config_aggregate';
  aggregate?: Maybe<Tenancy_User_Account_Release_Channel_Config_Aggregate_Fields>;
  nodes: Array<Tenancy_User_Account_Release_Channel_Config>;
};

export type Tenancy_User_Account_Release_Channel_Config_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_User_Account_Release_Channel_Config_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Aggregate_Fields = {
  __typename?: 'tenancy_user_account_release_channel_config_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_User_Account_Release_Channel_Config_Max_Fields>;
  min?: Maybe<Tenancy_User_Account_Release_Channel_Config_Min_Fields>;
};


/** aggregate fields of "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Max_Order_By>;
  min?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_User_Account_Release_Channel_Config_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.user_account_release_channel_config". All fields are combined with a logical 'AND'. */
export type Tenancy_User_Account_Release_Channel_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>>;
  _user_account_release_channel_config_roles?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Bool_Exp>;
  _user_account_release_channel_config_roles_aggregate?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  projectReleaseChannelConfigId?: InputMaybe<String_Comparison_Exp>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Bool_Exp>;
  userAccountId?: InputMaybe<String_Comparison_Exp>;
  user_account?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy.user_account_release_channel_config" */
export enum Tenancy_User_Account_Release_Channel_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserAccountReleaseChannelConfigPkey = 'user_account_release_channel_config_pkey'
}

/** input type for inserting data into table "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Insert_Input = {
  _user_account_release_channel_config_roles?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Obj_Rel_Insert_Input>;
  userAccountId?: InputMaybe<Scalars['String']['input']>;
  user_account?: InputMaybe<Tenancy_User_Accounts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy_User_Account_Release_Channel_Config_Max_Fields = {
  __typename?: 'tenancy_user_account_release_channel_config_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  projectReleaseChannelConfigId?: Maybe<Scalars['String']['output']>;
  userAccountId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  userAccountId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_User_Account_Release_Channel_Config_Min_Fields = {
  __typename?: 'tenancy_user_account_release_channel_config_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  projectReleaseChannelConfigId?: Maybe<Scalars['String']['output']>;
  userAccountId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  userAccountId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Mutation_Response = {
  __typename?: 'tenancy_user_account_release_channel_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_User_Account_Release_Channel_Config>;
};

/** input type for inserting object relation for remote table "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Obj_Rel_Insert_Input = {
  data: Tenancy_User_Account_Release_Channel_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_On_Conflict = {
  constraint: Tenancy_User_Account_Release_Channel_Config_Constraint;
  update_columns?: Array<Tenancy_User_Account_Release_Channel_Config_Update_Column>;
  where?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.user_account_release_channel_config". */
export type Tenancy_User_Account_Release_Channel_Config_Order_By = {
  _user_account_release_channel_config_roles_aggregate?: InputMaybe<Tenancy__User_Account_Release_Channel_Config_Roles_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  projectReleaseChannelConfigId?: InputMaybe<Order_By>;
  project_release_channel_config?: InputMaybe<Tenancy_Project_Release_Channel_Config_Order_By>;
  userAccountId?: InputMaybe<Order_By>;
  user_account?: InputMaybe<Tenancy_User_Accounts_Order_By>;
};

/** primary key columns input for table: tenancy.user_account_release_channel_config */
export type Tenancy_User_Account_Release_Channel_Config_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.user_account_release_channel_config" */
export enum Tenancy_User_Account_Release_Channel_Config_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProjectReleaseChannelConfigId = 'projectReleaseChannelConfigId',
  /** column name */
  UserAccountId = 'userAccountId'
}

/** input type for updating data in table "tenancy.user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_user_account_release_channel_config" */
export type Tenancy_User_Account_Release_Channel_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_User_Account_Release_Channel_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_User_Account_Release_Channel_Config_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  projectReleaseChannelConfigId?: InputMaybe<Scalars['String']['input']>;
  userAccountId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.user_account_release_channel_config" */
export enum Tenancy_User_Account_Release_Channel_Config_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProjectReleaseChannelConfigId = 'projectReleaseChannelConfigId',
  /** column name */
  UserAccountId = 'userAccountId'
}

export type Tenancy_User_Account_Release_Channel_Config_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_User_Account_Release_Channel_Config_Bool_Exp;
};

/** columns and relationships of "tenancy.user_accounts" */
export type Tenancy_User_Accounts = {
  __typename?: 'tenancy_user_accounts';
  /** An array relationship */
  _user_account_roles: Array<Tenancy__User_Account_Roles>;
  /** An aggregate relationship */
  _user_account_roles_aggregate: Tenancy__User_Account_Roles_Aggregate;
  /** An object relationship */
  account: Tenancy_Accounts;
  accountId: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['String']['output'];
  isPrimaryAccount: Scalars['Boolean']['output'];
  settings?: Maybe<Scalars['jsonb']['output']>;
  updatedAt: Scalars['timestamp']['output'];
  /** An object relationship */
  user: Tenancy_Users;
  userId: Scalars['String']['output'];
  /** An array relationship */
  user_account_projects: Array<Tenancy_User_Account_Projects>;
  /** An aggregate relationship */
  user_account_projects_aggregate: Tenancy_User_Account_Projects_Aggregate;
  /** An array relationship */
  user_account_release_channel_configs: Array<Tenancy_User_Account_Release_Channel_Config>;
  /** An aggregate relationship */
  user_account_release_channel_configs_aggregate: Tenancy_User_Account_Release_Channel_Config_Aggregate;
};


/** columns and relationships of "tenancy.user_accounts" */
export type Tenancy_User_Accounts_User_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.user_accounts" */
export type Tenancy_User_Accounts_User_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Account_Roles_Order_By>>;
  where?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "tenancy.user_accounts" */
export type Tenancy_User_AccountsSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tenancy.user_accounts" */
export type Tenancy_User_AccountsUser_Account_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.user_accounts" */
export type Tenancy_User_AccountsUser_Account_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Projects_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
};


/** columns and relationships of "tenancy.user_accounts" */
export type Tenancy_User_AccountsUser_Account_Release_Channel_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
};


/** columns and relationships of "tenancy.user_accounts" */
export type Tenancy_User_AccountsUser_Account_Release_Channel_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Account_Release_Channel_Config_Order_By>>;
  where?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
};

/** aggregated selection of "tenancy.user_accounts" */
export type Tenancy_User_Accounts_Aggregate = {
  __typename?: 'tenancy_user_accounts_aggregate';
  aggregate?: Maybe<Tenancy_User_Accounts_Aggregate_Fields>;
  nodes: Array<Tenancy_User_Accounts>;
};

export type Tenancy_User_Accounts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tenancy_User_Accounts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tenancy_User_Accounts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tenancy_User_Accounts_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_User_Accounts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenancy_User_Accounts_Select_Column_Tenancy_User_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_User_Accounts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenancy_User_Accounts_Select_Column_Tenancy_User_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenancy_User_Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_User_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.user_accounts" */
export type Tenancy_User_Accounts_Aggregate_Fields = {
  __typename?: 'tenancy_user_accounts_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_User_Accounts_Max_Fields>;
  min?: Maybe<Tenancy_User_Accounts_Min_Fields>;
};


/** aggregate fields of "tenancy.user_accounts" */
export type Tenancy_User_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_User_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.user_accounts" */
export type Tenancy_User_Accounts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_User_Accounts_Max_Order_By>;
  min?: InputMaybe<Tenancy_User_Accounts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_User_Accounts_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tenancy.user_accounts" */
export type Tenancy_User_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_User_Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_User_Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.user_accounts". All fields are combined with a logical 'AND'. */
export type Tenancy_User_Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_User_Accounts_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_User_Accounts_Bool_Exp>>;
  _user_account_roles?: InputMaybe<Tenancy__User_Account_Roles_Bool_Exp>;
  _user_account_roles_aggregate?: InputMaybe<Tenancy__User_Account_Roles_Aggregate_Bool_Exp>;
  account?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isPrimaryAccount?: InputMaybe<Boolean_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Tenancy_Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  user_account_projects?: InputMaybe<Tenancy_User_Account_Projects_Bool_Exp>;
  user_account_projects_aggregate?: InputMaybe<Tenancy_User_Account_Projects_Aggregate_Bool_Exp>;
  user_account_release_channel_configs?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Bool_Exp>;
  user_account_release_channel_configs_aggregate?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy.user_accounts" */
export enum Tenancy_User_Accounts_Constraint {
  /** unique or primary key constraint on columns "userId", "accountId" */
  UserAccountsAccountIdUserIdKey = 'user_accounts_accountId_userId_key',
  /** unique or primary key constraint on columns "id" */
  UserAccountsPkey = 'user_accounts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_User_Accounts_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_User_Accounts_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_User_Accounts_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.user_accounts" */
export type Tenancy_User_Accounts_Insert_Input = {
  _user_account_roles?: InputMaybe<Tenancy__User_Account_Roles_Arr_Rel_Insert_Input>;
  account?: InputMaybe<Tenancy_Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPrimaryAccount?: InputMaybe<Scalars['Boolean']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Tenancy_Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
  user_account_projects?: InputMaybe<Tenancy_User_Account_Projects_Arr_Rel_Insert_Input>;
  user_account_release_channel_configs?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy_User_Accounts_Max_Fields = {
  __typename?: 'tenancy_user_accounts_max_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tenancy.user_accounts" */
export type Tenancy_User_Accounts_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_User_Accounts_Min_Fields = {
  __typename?: 'tenancy_user_accounts_min_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tenancy.user_accounts" */
export type Tenancy_User_Accounts_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.user_accounts" */
export type Tenancy_User_Accounts_Mutation_Response = {
  __typename?: 'tenancy_user_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_User_Accounts>;
};

/** input type for inserting object relation for remote table "tenancy.user_accounts" */
export type Tenancy_User_Accounts_Obj_Rel_Insert_Input = {
  data: Tenancy_User_Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_User_Accounts_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.user_accounts" */
export type Tenancy_User_Accounts_On_Conflict = {
  constraint: Tenancy_User_Accounts_Constraint;
  update_columns?: Array<Tenancy_User_Accounts_Update_Column>;
  where?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.user_accounts". */
export type Tenancy_User_Accounts_Order_By = {
  _user_account_roles_aggregate?: InputMaybe<Tenancy__User_Account_Roles_Aggregate_Order_By>;
  account?: InputMaybe<Tenancy_Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isPrimaryAccount?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Tenancy_Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  user_account_projects_aggregate?: InputMaybe<Tenancy_User_Account_Projects_Aggregate_Order_By>;
  user_account_release_channel_configs_aggregate?: InputMaybe<Tenancy_User_Account_Release_Channel_Config_Aggregate_Order_By>;
};

/** primary key columns input for table: tenancy.user_accounts */
export type Tenancy_User_Accounts_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_User_Accounts_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.user_accounts" */
export enum Tenancy_User_Accounts_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPrimaryAccount = 'isPrimaryAccount',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** select "tenancy_user_accounts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenancy.user_accounts" */
export enum Tenancy_User_Accounts_Select_Column_Tenancy_User_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPrimaryAccount = 'isPrimaryAccount'
}

/** select "tenancy_user_accounts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenancy.user_accounts" */
export enum Tenancy_User_Accounts_Select_Column_Tenancy_User_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPrimaryAccount = 'isPrimaryAccount'
}

/** input type for updating data in table "tenancy.user_accounts" */
export type Tenancy_User_Accounts_Set_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPrimaryAccount?: InputMaybe<Scalars['Boolean']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_user_accounts" */
export type Tenancy_User_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_User_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_User_Accounts_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPrimaryAccount?: InputMaybe<Scalars['Boolean']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.user_accounts" */
export enum Tenancy_User_Accounts_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPrimaryAccount = 'isPrimaryAccount',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Tenancy_User_Accounts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_User_Accounts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_User_Accounts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_User_Accounts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_User_Accounts_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_User_Accounts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_User_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_User_Accounts_Bool_Exp;
};

/** columns and relationships of "tenancy.users" */
export type Tenancy_Users = {
  __typename?: 'tenancy_users';
  /** An array relationship */
  _user_teams: Array<Tenancy__User_Teams>;
  /** An aggregate relationship */
  _user_teams_aggregate: Tenancy__User_Teams_Aggregate;
  /** An array relationship */
  accounts: Array<Tenancy_Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Tenancy_Accounts_Aggregate;
  /** An array relationship */
  audit_logs: Array<Tenancy_Audit_Logs>;
  /** An aggregate relationship */
  audit_logs_aggregate: Tenancy_Audit_Logs_Aggregate;
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  /** An array relationship */
  files: Array<Tenancy_File>;
  /** An aggregate relationship */
  files_aggregate: Tenancy_File_Aggregate;
  firstName: Scalars['String']['output'];
  hasActiveSessions: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isHidden: Scalars['Boolean']['output'];
  kratosId?: Maybe<Scalars['String']['output']>;
  lastActiveAt: Scalars['timestamp']['output'];
  lastLoggedInAt?: Maybe<Scalars['timestamp']['output']>;
  lastName: Scalars['String']['output'];
  /** An array relationship */
  localization_settings: Array<Tenancy_Localization_Settings>;
  /** An aggregate relationship */
  localization_settings_aggregate: Tenancy_Localization_Settings_Aggregate;
  optionalConsentStatus: Scalars['ConsentStatus']['output'];
  optionalConsentUpdatedAt: Scalars['timestamp']['output'];
  passwordRecoveryStatus?: Maybe<Scalars['PasswordRecoveryStatus']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  requiredConsentStatus: Scalars['ConsentStatus']['output'];
  requiredConsentUpdatedAt: Scalars['timestamp']['output'];
  settings?: Maybe<Scalars['jsonb']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
  userName: Scalars['String']['output'];
  /** An array relationship */
  user_accounts: Array<Tenancy_User_Accounts>;
  /** An aggregate relationship */
  user_accounts_aggregate: Tenancy_User_Accounts_Aggregate;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_Users_User_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Teams_Order_By>>;
  where?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_Users_User_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__User_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__User_Teams_Order_By>>;
  where?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_UsersAccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_UsersAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_UsersAudit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Audit_Logs_Order_By>>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_UsersAudit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Audit_Logs_Order_By>>;
  where?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_UsersFilesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_UsersFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_File_Order_By>>;
  where?: InputMaybe<Tenancy_File_Bool_Exp>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_UsersLocalization_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_UsersLocalization_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_Localization_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_Localization_Settings_Order_By>>;
  where?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_UsersSettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_UsersUser_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};


/** columns and relationships of "tenancy.users" */
export type Tenancy_UsersUser_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy_User_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy_User_Accounts_Order_By>>;
  where?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
};

/** aggregated selection of "tenancy.users" */
export type Tenancy_Users_Aggregate = {
  __typename?: 'tenancy_users_aggregate';
  aggregate?: Maybe<Tenancy_Users_Aggregate_Fields>;
  nodes: Array<Tenancy_Users>;
};

/** aggregate fields of "tenancy.users" */
export type Tenancy_Users_Aggregate_Fields = {
  __typename?: 'tenancy_users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Users_Max_Fields>;
  min?: Maybe<Tenancy_Users_Min_Fields>;
};


/** aggregate fields of "tenancy.users" */
export type Tenancy_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Users_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "tenancy.users". All fields are combined with a logical 'AND'. */
export type Tenancy_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Tenancy_Users_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Users_Bool_Exp>>;
  _user_teams?: InputMaybe<Tenancy__User_Teams_Bool_Exp>;
  _user_teams_aggregate?: InputMaybe<Tenancy__User_Teams_Aggregate_Bool_Exp>;
  accounts?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Tenancy_Accounts_Aggregate_Bool_Exp>;
  audit_logs?: InputMaybe<Tenancy_Audit_Logs_Bool_Exp>;
  audit_logs_aggregate?: InputMaybe<Tenancy_Audit_Logs_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  files?: InputMaybe<Tenancy_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Tenancy_File_Aggregate_Bool_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  hasActiveSessions?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  isHidden?: InputMaybe<Boolean_Comparison_Exp>;
  kratosId?: InputMaybe<String_Comparison_Exp>;
  lastActiveAt?: InputMaybe<Timestamp_Comparison_Exp>;
  lastLoggedInAt?: InputMaybe<Timestamp_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  localization_settings?: InputMaybe<Tenancy_Localization_Settings_Bool_Exp>;
  localization_settings_aggregate?: InputMaybe<Tenancy_Localization_Settings_Aggregate_Bool_Exp>;
  optionalConsentStatus?: InputMaybe<ConsentStatus_Comparison_Exp>;
  optionalConsentUpdatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  passwordRecoveryStatus?: InputMaybe<PasswordRecoveryStatus_Comparison_Exp>;
  picture?: InputMaybe<String_Comparison_Exp>;
  requiredConsentStatus?: InputMaybe<ConsentStatus_Comparison_Exp>;
  requiredConsentUpdatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
  userName?: InputMaybe<String_Comparison_Exp>;
  user_accounts?: InputMaybe<Tenancy_User_Accounts_Bool_Exp>;
  user_accounts_aggregate?: InputMaybe<Tenancy_User_Accounts_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "tenancy.users" */
export enum Tenancy_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenancy_Users_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenancy_Users_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenancy_Users_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tenancy.users" */
export type Tenancy_Users_Insert_Input = {
  _user_teams?: InputMaybe<Tenancy__User_Teams_Arr_Rel_Insert_Input>;
  accounts?: InputMaybe<Tenancy_Accounts_Arr_Rel_Insert_Input>;
  audit_logs?: InputMaybe<Tenancy_Audit_Logs_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Tenancy_File_Arr_Rel_Insert_Input>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasActiveSessions?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  kratosId?: InputMaybe<Scalars['String']['input']>;
  lastActiveAt?: InputMaybe<Scalars['timestamp']['input']>;
  lastLoggedInAt?: InputMaybe<Scalars['timestamp']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  localization_settings?: InputMaybe<Tenancy_Localization_Settings_Arr_Rel_Insert_Input>;
  optionalConsentStatus?: InputMaybe<Scalars['ConsentStatus']['input']>;
  optionalConsentUpdatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  passwordRecoveryStatus?: InputMaybe<Scalars['PasswordRecoveryStatus']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  requiredConsentStatus?: InputMaybe<Scalars['ConsentStatus']['input']>;
  requiredConsentUpdatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  user_accounts?: InputMaybe<Tenancy_User_Accounts_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenancy_Users_Max_Fields = {
  __typename?: 'tenancy_users_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  kratosId?: Maybe<Scalars['String']['output']>;
  lastActiveAt?: Maybe<Scalars['timestamp']['output']>;
  lastLoggedInAt?: Maybe<Scalars['timestamp']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  optionalConsentStatus?: Maybe<Scalars['ConsentStatus']['output']>;
  optionalConsentUpdatedAt?: Maybe<Scalars['timestamp']['output']>;
  passwordRecoveryStatus?: Maybe<Scalars['PasswordRecoveryStatus']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  requiredConsentStatus?: Maybe<Scalars['ConsentStatus']['output']>;
  requiredConsentUpdatedAt?: Maybe<Scalars['timestamp']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tenancy_Users_Min_Fields = {
  __typename?: 'tenancy_users_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  kratosId?: Maybe<Scalars['String']['output']>;
  lastActiveAt?: Maybe<Scalars['timestamp']['output']>;
  lastLoggedInAt?: Maybe<Scalars['timestamp']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  optionalConsentStatus?: Maybe<Scalars['ConsentStatus']['output']>;
  optionalConsentUpdatedAt?: Maybe<Scalars['timestamp']['output']>;
  passwordRecoveryStatus?: Maybe<Scalars['PasswordRecoveryStatus']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  requiredConsentStatus?: Maybe<Scalars['ConsentStatus']['output']>;
  requiredConsentUpdatedAt?: Maybe<Scalars['timestamp']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tenancy.users" */
export type Tenancy_Users_Mutation_Response = {
  __typename?: 'tenancy_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Users>;
};

/** input type for inserting object relation for remote table "tenancy.users" */
export type Tenancy_Users_Obj_Rel_Insert_Input = {
  data: Tenancy_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Users_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.users" */
export type Tenancy_Users_On_Conflict = {
  constraint: Tenancy_Users_Constraint;
  update_columns?: Array<Tenancy_Users_Update_Column>;
  where?: InputMaybe<Tenancy_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.users". */
export type Tenancy_Users_Order_By = {
  _user_teams_aggregate?: InputMaybe<Tenancy__User_Teams_Aggregate_Order_By>;
  accounts_aggregate?: InputMaybe<Tenancy_Accounts_Aggregate_Order_By>;
  audit_logs_aggregate?: InputMaybe<Tenancy_Audit_Logs_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Tenancy_File_Aggregate_Order_By>;
  firstName?: InputMaybe<Order_By>;
  hasActiveSessions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isHidden?: InputMaybe<Order_By>;
  kratosId?: InputMaybe<Order_By>;
  lastActiveAt?: InputMaybe<Order_By>;
  lastLoggedInAt?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  localization_settings_aggregate?: InputMaybe<Tenancy_Localization_Settings_Aggregate_Order_By>;
  optionalConsentStatus?: InputMaybe<Order_By>;
  optionalConsentUpdatedAt?: InputMaybe<Order_By>;
  passwordRecoveryStatus?: InputMaybe<Order_By>;
  picture?: InputMaybe<Order_By>;
  requiredConsentStatus?: InputMaybe<Order_By>;
  requiredConsentUpdatedAt?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  userName?: InputMaybe<Order_By>;
  user_accounts_aggregate?: InputMaybe<Tenancy_User_Accounts_Aggregate_Order_By>;
};

/** primary key columns input for table: tenancy.users */
export type Tenancy_Users_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenancy_Users_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tenancy.users" */
export enum Tenancy_Users_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  HasActiveSessions = 'hasActiveSessions',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  KratosId = 'kratosId',
  /** column name */
  LastActiveAt = 'lastActiveAt',
  /** column name */
  LastLoggedInAt = 'lastLoggedInAt',
  /** column name */
  LastName = 'lastName',
  /** column name */
  OptionalConsentStatus = 'optionalConsentStatus',
  /** column name */
  OptionalConsentUpdatedAt = 'optionalConsentUpdatedAt',
  /** column name */
  PasswordRecoveryStatus = 'passwordRecoveryStatus',
  /** column name */
  Picture = 'picture',
  /** column name */
  RequiredConsentStatus = 'requiredConsentStatus',
  /** column name */
  RequiredConsentUpdatedAt = 'requiredConsentUpdatedAt',
  /** column name */
  Settings = 'settings',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  UserName = 'userName'
}

/** input type for updating data in table "tenancy.users" */
export type Tenancy_Users_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasActiveSessions?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  kratosId?: InputMaybe<Scalars['String']['input']>;
  lastActiveAt?: InputMaybe<Scalars['timestamp']['input']>;
  lastLoggedInAt?: InputMaybe<Scalars['timestamp']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  optionalConsentStatus?: InputMaybe<Scalars['ConsentStatus']['input']>;
  optionalConsentUpdatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  passwordRecoveryStatus?: InputMaybe<Scalars['PasswordRecoveryStatus']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  requiredConsentStatus?: InputMaybe<Scalars['ConsentStatus']['input']>;
  requiredConsentUpdatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tenancy_users" */
export type Tenancy_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Users_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasActiveSessions?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  kratosId?: InputMaybe<Scalars['String']['input']>;
  lastActiveAt?: InputMaybe<Scalars['timestamp']['input']>;
  lastLoggedInAt?: InputMaybe<Scalars['timestamp']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  optionalConsentStatus?: InputMaybe<Scalars['ConsentStatus']['input']>;
  optionalConsentUpdatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  passwordRecoveryStatus?: InputMaybe<Scalars['PasswordRecoveryStatus']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  requiredConsentStatus?: InputMaybe<Scalars['ConsentStatus']['input']>;
  requiredConsentUpdatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  settings?: InputMaybe<Scalars['jsonb']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tenancy.users" */
export enum Tenancy_Users_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  HasActiveSessions = 'hasActiveSessions',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'isHidden',
  /** column name */
  KratosId = 'kratosId',
  /** column name */
  LastActiveAt = 'lastActiveAt',
  /** column name */
  LastLoggedInAt = 'lastLoggedInAt',
  /** column name */
  LastName = 'lastName',
  /** column name */
  OptionalConsentStatus = 'optionalConsentStatus',
  /** column name */
  OptionalConsentUpdatedAt = 'optionalConsentUpdatedAt',
  /** column name */
  PasswordRecoveryStatus = 'passwordRecoveryStatus',
  /** column name */
  Picture = 'picture',
  /** column name */
  RequiredConsentStatus = 'requiredConsentStatus',
  /** column name */
  RequiredConsentUpdatedAt = 'requiredConsentUpdatedAt',
  /** column name */
  Settings = 'settings',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  UserName = 'userName'
}

export type Tenancy_Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tenancy_Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tenancy_Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tenancy_Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tenancy_Users_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tenancy_Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Users_Bool_Exp;
};

/** columns and relationships of "tenancy.variable" */
export type Tenancy_Variable = {
  __typename?: 'tenancy_variable';
  /** An array relationship */
  _ProjectReleaseChannelConfigToVariables: Array<Tenancy__ProjectReleaseChannelConfigToVariable>;
  /** An aggregate relationship */
  _ProjectReleaseChannelConfigToVariables_aggregate: Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate;
  /** An object relationship */
  account: Tenancy_Accounts;
  accountId: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  /** An object relationship */
  project: Tenancy_Projects;
  projectId: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
  updatedBy: Scalars['String']['output'];
  value: Scalars['String']['output'];
  variableAccessLevel: Scalars['VariableAccessLevel']['output'];
};


/** columns and relationships of "tenancy.variable" */
export type Tenancy_Variable_ProjectReleaseChannelConfigToVariablesArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Order_By>>;
  where?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
};


/** columns and relationships of "tenancy.variable" */
export type Tenancy_Variable_ProjectReleaseChannelConfigToVariables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tenancy__ProjectReleaseChannelConfigToVariable_Order_By>>;
  where?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
};

/** aggregated selection of "tenancy.variable" */
export type Tenancy_Variable_Aggregate = {
  __typename?: 'tenancy_variable_aggregate';
  aggregate?: Maybe<Tenancy_Variable_Aggregate_Fields>;
  nodes: Array<Tenancy_Variable>;
};

export type Tenancy_Variable_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenancy_Variable_Aggregate_Bool_Exp_Count>;
};

export type Tenancy_Variable_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenancy_Variable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tenancy_Variable_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenancy.variable" */
export type Tenancy_Variable_Aggregate_Fields = {
  __typename?: 'tenancy_variable_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tenancy_Variable_Max_Fields>;
  min?: Maybe<Tenancy_Variable_Min_Fields>;
};


/** aggregate fields of "tenancy.variable" */
export type Tenancy_Variable_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenancy_Variable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tenancy.variable" */
export type Tenancy_Variable_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenancy_Variable_Max_Order_By>;
  min?: InputMaybe<Tenancy_Variable_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenancy.variable" */
export type Tenancy_Variable_Arr_Rel_Insert_Input = {
  data: Array<Tenancy_Variable_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Variable_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenancy.variable". All fields are combined with a logical 'AND'. */
export type Tenancy_Variable_Bool_Exp = {
  _ProjectReleaseChannelConfigToVariables?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Bool_Exp>;
  _ProjectReleaseChannelConfigToVariables_aggregate?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Tenancy_Variable_Bool_Exp>>;
  _not?: InputMaybe<Tenancy_Variable_Bool_Exp>;
  _or?: InputMaybe<Array<Tenancy_Variable_Bool_Exp>>;
  account?: InputMaybe<Tenancy_Accounts_Bool_Exp>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Tenancy_Projects_Bool_Exp>;
  projectId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  variableAccessLevel?: InputMaybe<VariableAccessLevel_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenancy.variable" */
export enum Tenancy_Variable_Constraint {
  /** unique or primary key constraint on columns "id" */
  VariablePkey = 'variable_pkey'
}

/** input type for inserting data into table "tenancy.variable" */
export type Tenancy_Variable_Insert_Input = {
  _ProjectReleaseChannelConfigToVariables?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Arr_Rel_Insert_Input>;
  account?: InputMaybe<Tenancy_Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Tenancy_Projects_Obj_Rel_Insert_Input>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  variableAccessLevel?: InputMaybe<Scalars['VariableAccessLevel']['input']>;
};

/** aggregate max on columns */
export type Tenancy_Variable_Max_Fields = {
  __typename?: 'tenancy_variable_max_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  variableAccessLevel?: Maybe<Scalars['VariableAccessLevel']['output']>;
};

/** order by max() on columns of table "tenancy.variable" */
export type Tenancy_Variable_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  variableAccessLevel?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenancy_Variable_Min_Fields = {
  __typename?: 'tenancy_variable_min_fields';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  variableAccessLevel?: Maybe<Scalars['VariableAccessLevel']['output']>;
};

/** order by min() on columns of table "tenancy.variable" */
export type Tenancy_Variable_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  projectId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  variableAccessLevel?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenancy.variable" */
export type Tenancy_Variable_Mutation_Response = {
  __typename?: 'tenancy_variable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenancy_Variable>;
};

/** input type for inserting object relation for remote table "tenancy.variable" */
export type Tenancy_Variable_Obj_Rel_Insert_Input = {
  data: Tenancy_Variable_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenancy_Variable_On_Conflict>;
};

/** on_conflict condition type for table "tenancy.variable" */
export type Tenancy_Variable_On_Conflict = {
  constraint: Tenancy_Variable_Constraint;
  update_columns?: Array<Tenancy_Variable_Update_Column>;
  where?: InputMaybe<Tenancy_Variable_Bool_Exp>;
};

/** Ordering options when selecting data from "tenancy.variable". */
export type Tenancy_Variable_Order_By = {
  _ProjectReleaseChannelConfigToVariables_aggregate?: InputMaybe<Tenancy__ProjectReleaseChannelConfigToVariable_Aggregate_Order_By>;
  account?: InputMaybe<Tenancy_Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project?: InputMaybe<Tenancy_Projects_Order_By>;
  projectId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  variableAccessLevel?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenancy.variable */
export type Tenancy_Variable_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "tenancy.variable" */
export enum Tenancy_Variable_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  Value = 'value',
  /** column name */
  VariableAccessLevel = 'variableAccessLevel'
}

/** input type for updating data in table "tenancy.variable" */
export type Tenancy_Variable_Set_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  variableAccessLevel?: InputMaybe<Scalars['VariableAccessLevel']['input']>;
};

/** Streaming cursor of the table "tenancy_variable" */
export type Tenancy_Variable_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenancy_Variable_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenancy_Variable_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  variableAccessLevel?: InputMaybe<Scalars['VariableAccessLevel']['input']>;
};

/** update columns of table "tenancy.variable" */
export enum Tenancy_Variable_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  Value = 'value',
  /** column name */
  VariableAccessLevel = 'variableAccessLevel'
}

export type Tenancy_Variable_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenancy_Variable_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenancy_Variable_Bool_Exp;
};

/** columns and relationships of "text_contents" */
export type Text_Contents = {
  __typename?: 'text_contents';
  id: Scalars['uuid']['output'];
  message: Scalars['String']['output'];
};

/** aggregated selection of "text_contents" */
export type Text_Contents_Aggregate = {
  __typename?: 'text_contents_aggregate';
  aggregate?: Maybe<Text_Contents_Aggregate_Fields>;
  nodes: Array<Text_Contents>;
};

/** aggregate fields of "text_contents" */
export type Text_Contents_Aggregate_Fields = {
  __typename?: 'text_contents_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Text_Contents_Max_Fields>;
  min?: Maybe<Text_Contents_Min_Fields>;
};


/** aggregate fields of "text_contents" */
export type Text_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Text_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "text_contents". All fields are combined with a logical 'AND'. */
export type Text_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Text_Contents_Bool_Exp>>;
  _not?: InputMaybe<Text_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Text_Contents_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "text_contents" */
export enum Text_Contents_Constraint {
  /** unique or primary key constraint on columns "id" */
  TextContentsPkey = 'text_contents_pkey'
}

/** input type for inserting data into table "text_contents" */
export type Text_Contents_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Text_Contents_Max_Fields = {
  __typename?: 'text_contents_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Text_Contents_Min_Fields = {
  __typename?: 'text_contents_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "text_contents" */
export type Text_Contents_Mutation_Response = {
  __typename?: 'text_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Text_Contents>;
};

/** on_conflict condition type for table "text_contents" */
export type Text_Contents_On_Conflict = {
  constraint: Text_Contents_Constraint;
  update_columns?: Array<Text_Contents_Update_Column>;
  where?: InputMaybe<Text_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "text_contents". */
export type Text_Contents_Order_By = {
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
};

/** primary key columns input for table: text_contents */
export type Text_Contents_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "text_contents" */
export enum Text_Contents_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message'
}

/** input type for updating data in table "text_contents" */
export type Text_Contents_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "text_contents" */
export type Text_Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Text_Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Text_Contents_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "text_contents" */
export enum Text_Contents_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message'
}

export type Text_Contents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Text_Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Text_Contents_Bool_Exp;
};

/** columns and relationships of "text_result" */
export type Text_Result = {
  __typename?: 'text_result';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "text_result" */
export type Text_Result_Aggregate = {
  __typename?: 'text_result_aggregate';
  aggregate?: Maybe<Text_Result_Aggregate_Fields>;
  nodes: Array<Text_Result>;
};

/** aggregate fields of "text_result" */
export type Text_Result_Aggregate_Fields = {
  __typename?: 'text_result_aggregate_fields';
  avg?: Maybe<Text_Result_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Text_Result_Max_Fields>;
  min?: Maybe<Text_Result_Min_Fields>;
  stddev?: Maybe<Text_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Text_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Text_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Text_Result_Sum_Fields>;
  var_pop?: Maybe<Text_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Text_Result_Var_Samp_Fields>;
  variance?: Maybe<Text_Result_Variance_Fields>;
};


/** aggregate fields of "text_result" */
export type Text_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Text_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Text_Result_Avg_Fields = {
  __typename?: 'text_result_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "text_result". All fields are combined with a logical 'AND'. */
export type Text_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Text_Result_Bool_Exp>>;
  _not?: InputMaybe<Text_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Text_Result_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "text_result" */
export type Text_Result_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "text_result" */
export type Text_Result_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Text_Result_Max_Fields = {
  __typename?: 'text_result_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Text_Result_Min_Fields = {
  __typename?: 'text_result_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "text_result" */
export type Text_Result_Mutation_Response = {
  __typename?: 'text_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Text_Result>;
};

/** Ordering options when selecting data from "text_result". */
export type Text_Result_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** select columns of table "text_result" */
export enum Text_Result_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "text_result" */
export type Text_Result_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Text_Result_Stddev_Fields = {
  __typename?: 'text_result_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Text_Result_Stddev_Pop_Fields = {
  __typename?: 'text_result_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Text_Result_Stddev_Samp_Fields = {
  __typename?: 'text_result_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "text_result" */
export type Text_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Text_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Text_Result_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Text_Result_Sum_Fields = {
  __typename?: 'text_result_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

export type Text_Result_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Text_Result_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Text_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Text_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Text_Result_Var_Pop_Fields = {
  __typename?: 'text_result_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Text_Result_Var_Samp_Fields = {
  __typename?: 'text_result_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Text_Result_Variance_Fields = {
  __typename?: 'text_result_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type Timetz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timetz']['input']>;
  _gt?: InputMaybe<Scalars['timetz']['input']>;
  _gte?: InputMaybe<Scalars['timetz']['input']>;
  _in?: InputMaybe<Array<Scalars['timetz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timetz']['input']>;
  _lte?: InputMaybe<Scalars['timetz']['input']>;
  _neq?: InputMaybe<Scalars['timetz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timetz']['input']>>;
};

/** columns and relationships of "user_test" */
export type User_Test = {
  __typename?: 'user_test';
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  likesCats?: Maybe<Scalars['Boolean']['output']>;
  time?: Maybe<Scalars['timetz']['output']>;
};

/** aggregated selection of "user_test" */
export type User_Test_Aggregate = {
  __typename?: 'user_test_aggregate';
  aggregate?: Maybe<User_Test_Aggregate_Fields>;
  nodes: Array<User_Test>;
};

/** aggregate fields of "user_test" */
export type User_Test_Aggregate_Fields = {
  __typename?: 'user_test_aggregate_fields';
  avg?: Maybe<User_Test_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Test_Max_Fields>;
  min?: Maybe<User_Test_Min_Fields>;
  stddev?: Maybe<User_Test_Stddev_Fields>;
  stddev_pop?: Maybe<User_Test_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Test_Stddev_Samp_Fields>;
  sum?: Maybe<User_Test_Sum_Fields>;
  var_pop?: Maybe<User_Test_Var_Pop_Fields>;
  var_samp?: Maybe<User_Test_Var_Samp_Fields>;
  variance?: Maybe<User_Test_Variance_Fields>;
};


/** aggregate fields of "user_test" */
export type User_Test_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type User_Test_Avg_Fields = {
  __typename?: 'user_test_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_test". All fields are combined with a logical 'AND'. */
export type User_Test_Bool_Exp = {
  _and?: InputMaybe<Array<User_Test_Bool_Exp>>;
  _not?: InputMaybe<User_Test_Bool_Exp>;
  _or?: InputMaybe<Array<User_Test_Bool_Exp>>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  likesCats?: InputMaybe<Boolean_Comparison_Exp>;
  time?: InputMaybe<Timetz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_test" */
export enum User_Test_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserTestPkey = 'user_test_pkey'
}

/** input type for incrementing numeric columns in table "user_test" */
export type User_Test_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_test" */
export type User_Test_Insert_Input = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  likesCats?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['timetz']['input']>;
};

/** aggregate max on columns */
export type User_Test_Max_Fields = {
  __typename?: 'user_test_max_fields';
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['timetz']['output']>;
};

/** aggregate min on columns */
export type User_Test_Min_Fields = {
  __typename?: 'user_test_min_fields';
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['timetz']['output']>;
};

/** response of any mutation on the table "user_test" */
export type User_Test_Mutation_Response = {
  __typename?: 'user_test_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Test>;
};

/** on_conflict condition type for table "user_test" */
export type User_Test_On_Conflict = {
  constraint: User_Test_Constraint;
  update_columns?: Array<User_Test_Update_Column>;
  where?: InputMaybe<User_Test_Bool_Exp>;
};

/** Ordering options when selecting data from "user_test". */
export type User_Test_Order_By = {
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  likesCats?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_test */
export type User_Test_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "user_test" */
export enum User_Test_Select_Column {
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LikesCats = 'likesCats',
  /** column name */
  Time = 'time'
}

/** input type for updating data in table "user_test" */
export type User_Test_Set_Input = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  likesCats?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['timetz']['input']>;
};

/** aggregate stddev on columns */
export type User_Test_Stddev_Fields = {
  __typename?: 'user_test_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type User_Test_Stddev_Pop_Fields = {
  __typename?: 'user_test_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type User_Test_Stddev_Samp_Fields = {
  __typename?: 'user_test_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_test" */
export type User_Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Test_Stream_Cursor_Value_Input = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  likesCats?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['timetz']['input']>;
};

/** aggregate sum on columns */
export type User_Test_Sum_Fields = {
  __typename?: 'user_test_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "user_test" */
export enum User_Test_Update_Column {
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LikesCats = 'likesCats',
  /** column name */
  Time = 'time'
}

export type User_Test_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Test_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Test_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Test_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Test_Var_Pop_Fields = {
  __typename?: 'user_test_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type User_Test_Var_Samp_Fields = {
  __typename?: 'user_test_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type User_Test_Variance_Fields = {
  __typename?: 'user_test_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "versions" */
export type Versions = {
  __typename?: 'versions';
  app_id: Scalars['uuid']['output'];
  created_at: Scalars['timetz']['output'];
  /** An array relationship */
  deployment_history: Array<Deployments>;
  /** An aggregate relationship */
  deployment_history_aggregate: Deployments_Aggregate;
  /** An object relationship */
  draft_details?: Maybe<Drafts>;
  id: Scalars['uuid']['output'];
  title: Scalars['String']['output'];
};


/** columns and relationships of "versions" */
export type VersionsDeployment_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deployments_Order_By>>;
  where?: InputMaybe<Deployments_Bool_Exp>;
};


/** columns and relationships of "versions" */
export type VersionsDeployment_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deployments_Order_By>>;
  where?: InputMaybe<Deployments_Bool_Exp>;
};

/** aggregated selection of "versions" */
export type Versions_Aggregate = {
  __typename?: 'versions_aggregate';
  aggregate?: Maybe<Versions_Aggregate_Fields>;
  nodes: Array<Versions>;
};

export type Versions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Versions_Aggregate_Bool_Exp_Count>;
};

export type Versions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Versions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Versions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "versions" */
export type Versions_Aggregate_Fields = {
  __typename?: 'versions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Versions_Max_Fields>;
  min?: Maybe<Versions_Min_Fields>;
};


/** aggregate fields of "versions" */
export type Versions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Versions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "versions" */
export type Versions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Versions_Max_Order_By>;
  min?: InputMaybe<Versions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "versions" */
export type Versions_Arr_Rel_Insert_Input = {
  data: Array<Versions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Versions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "versions". All fields are combined with a logical 'AND'. */
export type Versions_Bool_Exp = {
  _and?: InputMaybe<Array<Versions_Bool_Exp>>;
  _not?: InputMaybe<Versions_Bool_Exp>;
  _or?: InputMaybe<Array<Versions_Bool_Exp>>;
  app_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timetz_Comparison_Exp>;
  deployment_history?: InputMaybe<Deployments_Bool_Exp>;
  deployment_history_aggregate?: InputMaybe<Deployments_Aggregate_Bool_Exp>;
  draft_details?: InputMaybe<Drafts_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "versions" */
export enum Versions_Constraint {
  /** unique or primary key constraint on columns "id" */
  VersionsPkey = 'versions_pkey'
}

/** input type for inserting data into table "versions" */
export type Versions_Insert_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timetz']['input']>;
  deployment_history?: InputMaybe<Deployments_Arr_Rel_Insert_Input>;
  draft_details?: InputMaybe<Drafts_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Versions_Max_Fields = {
  __typename?: 'versions_max_fields';
  app_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timetz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "versions" */
export type Versions_Max_Order_By = {
  app_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Versions_Min_Fields = {
  __typename?: 'versions_min_fields';
  app_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timetz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "versions" */
export type Versions_Min_Order_By = {
  app_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "versions" */
export type Versions_Mutation_Response = {
  __typename?: 'versions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Versions>;
};

/** input type for inserting object relation for remote table "versions" */
export type Versions_Obj_Rel_Insert_Input = {
  data: Versions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Versions_On_Conflict>;
};

/** on_conflict condition type for table "versions" */
export type Versions_On_Conflict = {
  constraint: Versions_Constraint;
  update_columns?: Array<Versions_Update_Column>;
  where?: InputMaybe<Versions_Bool_Exp>;
};

/** Ordering options when selecting data from "versions". */
export type Versions_Order_By = {
  app_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deployment_history_aggregate?: InputMaybe<Deployments_Aggregate_Order_By>;
  draft_details?: InputMaybe<Drafts_Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: versions */
export type Versions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "versions" */
export enum Versions_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "versions" */
export type Versions_Set_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timetz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "versions" */
export type Versions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Versions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Versions_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timetz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "versions" */
export enum Versions_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

export type Versions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Versions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Versions_Bool_Exp;
};

/** columns and relationships of "workflow" */
export type Workflow = {
  __typename?: 'workflow';
  app_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "workflow" */
export type Workflow_Aggregate = {
  __typename?: 'workflow_aggregate';
  aggregate?: Maybe<Workflow_Aggregate_Fields>;
  nodes: Array<Workflow>;
};

/** aggregate fields of "workflow" */
export type Workflow_Aggregate_Fields = {
  __typename?: 'workflow_aggregate_fields';
  avg?: Maybe<Workflow_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Workflow_Max_Fields>;
  min?: Maybe<Workflow_Min_Fields>;
  stddev?: Maybe<Workflow_Stddev_Fields>;
  stddev_pop?: Maybe<Workflow_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workflow_Stddev_Samp_Fields>;
  sum?: Maybe<Workflow_Sum_Fields>;
  var_pop?: Maybe<Workflow_Var_Pop_Fields>;
  var_samp?: Maybe<Workflow_Var_Samp_Fields>;
  variance?: Maybe<Workflow_Variance_Fields>;
};


/** aggregate fields of "workflow" */
export type Workflow_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workflow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Workflow_Avg_Fields = {
  __typename?: 'workflow_avg_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "workflow". All fields are combined with a logical 'AND'. */
export type Workflow_Bool_Exp = {
  _and?: InputMaybe<Array<Workflow_Bool_Exp>>;
  _not?: InputMaybe<Workflow_Bool_Exp>;
  _or?: InputMaybe<Array<Workflow_Bool_Exp>>;
  app_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "workflow" */
export enum Workflow_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkflowIdKey = 'workflow_id_key',
  /** unique or primary key constraint on columns "id" */
  WorkflowPkey = 'workflow_pkey'
}

/** input type for incrementing numeric columns in table "workflow" */
export type Workflow_Inc_Input = {
  app_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "workflow" */
export type Workflow_Insert_Input = {
  app_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Workflow_Max_Fields = {
  __typename?: 'workflow_max_fields';
  app_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Workflow_Min_Fields = {
  __typename?: 'workflow_min_fields';
  app_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "workflow" */
export type Workflow_Mutation_Response = {
  __typename?: 'workflow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Workflow>;
};

/** on_conflict condition type for table "workflow" */
export type Workflow_On_Conflict = {
  constraint: Workflow_Constraint;
  update_columns?: Array<Workflow_Update_Column>;
  where?: InputMaybe<Workflow_Bool_Exp>;
};

/** Ordering options when selecting data from "workflow". */
export type Workflow_Order_By = {
  app_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workflow */
export type Workflow_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "workflow" */
export enum Workflow_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "workflow" */
export type Workflow_Set_Input = {
  app_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Workflow_Stddev_Fields = {
  __typename?: 'workflow_stddev_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Workflow_Stddev_Pop_Fields = {
  __typename?: 'workflow_stddev_pop_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Workflow_Stddev_Samp_Fields = {
  __typename?: 'workflow_stddev_samp_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "workflow" */
export type Workflow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workflow_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workflow_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Workflow_Sum_Fields = {
  __typename?: 'workflow_sum_fields';
  app_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "workflow" */
export enum Workflow_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Workflow_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workflow_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workflow_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workflow_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workflow_Var_Pop_Fields = {
  __typename?: 'workflow_var_pop_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Workflow_Var_Samp_Fields = {
  __typename?: 'workflow_var_samp_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Workflow_Variance_Fields = {
  __typename?: 'workflow_variance_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "workflows" */
export type Workflows = {
  __typename?: 'workflows';
  app_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  workflow_apps: Array<Apps_V2>;
  /** An aggregate relationship */
  workflow_apps_aggregate: Apps_V2_Aggregate;
};


/** columns and relationships of "workflows" */
export type WorkflowsWorkflow_AppsArgs = {
  distinct_on?: InputMaybe<Array<Apps_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_V2_Order_By>>;
  where?: InputMaybe<Apps_V2_Bool_Exp>;
};


/** columns and relationships of "workflows" */
export type WorkflowsWorkflow_Apps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_V2_Order_By>>;
  where?: InputMaybe<Apps_V2_Bool_Exp>;
};

/** aggregated selection of "workflows" */
export type Workflows_Aggregate = {
  __typename?: 'workflows_aggregate';
  aggregate?: Maybe<Workflows_Aggregate_Fields>;
  nodes: Array<Workflows>;
};

/** aggregate fields of "workflows" */
export type Workflows_Aggregate_Fields = {
  __typename?: 'workflows_aggregate_fields';
  avg?: Maybe<Workflows_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Workflows_Max_Fields>;
  min?: Maybe<Workflows_Min_Fields>;
  stddev?: Maybe<Workflows_Stddev_Fields>;
  stddev_pop?: Maybe<Workflows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workflows_Stddev_Samp_Fields>;
  sum?: Maybe<Workflows_Sum_Fields>;
  var_pop?: Maybe<Workflows_Var_Pop_Fields>;
  var_samp?: Maybe<Workflows_Var_Samp_Fields>;
  variance?: Maybe<Workflows_Variance_Fields>;
};


/** aggregate fields of "workflows" */
export type Workflows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workflows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Workflows_Avg_Fields = {
  __typename?: 'workflows_avg_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "workflows". All fields are combined with a logical 'AND'. */
export type Workflows_Bool_Exp = {
  _and?: InputMaybe<Array<Workflows_Bool_Exp>>;
  _not?: InputMaybe<Workflows_Bool_Exp>;
  _or?: InputMaybe<Array<Workflows_Bool_Exp>>;
  app_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  workflow_apps?: InputMaybe<Apps_V2_Bool_Exp>;
  workflow_apps_aggregate?: InputMaybe<Apps_V2_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "workflows" */
export enum Workflows_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkflowsIdKey = 'workflows_id_key',
  /** unique or primary key constraint on columns "id" */
  WorkflowsPkey = 'workflows_pkey'
}

/** input type for incrementing numeric columns in table "workflows" */
export type Workflows_Inc_Input = {
  app_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "workflows" */
export type Workflows_Insert_Input = {
  app_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  workflow_apps?: InputMaybe<Apps_V2_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Workflows_Max_Fields = {
  __typename?: 'workflows_max_fields';
  app_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Workflows_Min_Fields = {
  __typename?: 'workflows_min_fields';
  app_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "workflows" */
export type Workflows_Mutation_Response = {
  __typename?: 'workflows_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Workflows>;
};

/** on_conflict condition type for table "workflows" */
export type Workflows_On_Conflict = {
  constraint: Workflows_Constraint;
  update_columns?: Array<Workflows_Update_Column>;
  where?: InputMaybe<Workflows_Bool_Exp>;
};

/** Ordering options when selecting data from "workflows". */
export type Workflows_Order_By = {
  app_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  workflow_apps_aggregate?: InputMaybe<Apps_V2_Aggregate_Order_By>;
};

/** primary key columns input for table: workflows */
export type Workflows_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "workflows" */
export enum Workflows_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "workflows" */
export type Workflows_Set_Input = {
  app_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Workflows_Stddev_Fields = {
  __typename?: 'workflows_stddev_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Workflows_Stddev_Pop_Fields = {
  __typename?: 'workflows_stddev_pop_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Workflows_Stddev_Samp_Fields = {
  __typename?: 'workflows_stddev_samp_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "workflows" */
export type Workflows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workflows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workflows_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Workflows_Sum_Fields = {
  __typename?: 'workflows_sum_fields';
  app_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "workflows" */
export enum Workflows_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Workflows_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workflows_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workflows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workflows_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workflows_Var_Pop_Fields = {
  __typename?: 'workflows_var_pop_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Workflows_Var_Samp_Fields = {
  __typename?: 'workflows_var_samp_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Workflows_Variance_Fields = {
  __typename?: 'workflows_variance_fields';
  app_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type GetGraphQueryVariables = Exact<{
  versionId: Scalars['bigint']['input'];
  appId: Scalars['Int']['input'];
}>;


export type GetGraphQuery = { __typename?: 'query_root', apps_version: Array<{ __typename?: 'apps_version', version_name: string, version_node_instances_aggregate: { __typename?: 'apps_version_node_instance_aggregate', aggregate?: { __typename?: 'apps_version_node_instance_aggregate_fields', count: number } | null }, version_node_instances: Array<{ __typename?: 'apps_version_node_instance', node_number: any, outgoing_nodes?: { __typename?: 'apps_node_outgoing_edge_view', to_node_numbers?: Array<any> | null } | null, node_instance: { __typename?: 'apps_node_instance', id: any, node: { __typename?: 'apps_node', node_name?: string | null, node_type: string, id: any } } }> }> };

export type UpdateNodeTitleMutationVariables = Exact<{
  title: Scalars['String']['input'];
  nodeId: Scalars['bigint']['input'];
}>;


export type UpdateNodeTitleMutation = { __typename?: 'mutation_root', update_apps_node?: { __typename?: 'apps_node_mutation_response', affected_rows: number } | null };

export type UpdateNodeMessagesMutationVariables = Exact<{
  messages: Scalars['jsonb']['input'];
  nodeId: Scalars['bigint']['input'];
}>;


export type UpdateNodeMessagesMutation = { __typename?: 'mutation_root', update_apps_node?: { __typename?: 'apps_node_mutation_response', affected_rows: number } | null };

export type UpdateNodeMutationVariables = Exact<{
  nodeNumber: Scalars['bigint']['input'];
  label: Scalars['String']['input'];
  contents: Scalars['jsonb']['input'];
}>;


export type UpdateNodeMutation = { __typename?: 'mutation_root', update_apps_node?: { __typename?: 'apps_node_mutation_response', affected_rows: number } | null };

export type GetWorkflowQueryVariables = Exact<{
  appId: Scalars['Int']['input'];
}>;


export type GetWorkflowQuery = { __typename?: 'query_root', fe_proto_?: { __typename?: 'fe_proto_query_root', workflow: Array<{ __typename?: 'workflow', id: number, name?: string | null, app_id?: number | null }> } | null };

export type GetMicroAppsQueryVariables = Exact<{
  customerId: Scalars['Int']['input'];
}>;


export type GetMicroAppsQuery = { __typename?: 'query_root', apps_app: Array<{ __typename: 'apps_app', app_name: string, app_id: number, full_name: string, versions: Array<{ __typename: 'apps_version', version_name: string, version_id: any, updated_at?: any | null, readonly_at?: any | null, deployments: Array<{ __typename: 'apps_deployment', deployed_at?: any | null, deployment_id: number, environment_id?: number | null, environment?: { __typename: 'apps_environment', is_production: boolean, environment_name: string, environment_id: number, updated_at?: any | null } | null }> }> }> };

export type GetMicroAppsSubscriptionSubscriptionVariables = Exact<{
  customerId: Scalars['Int']['input'];
}>;


export type GetMicroAppsSubscriptionSubscription = { __typename?: 'subscription_root', apps_app: Array<{ __typename: 'apps_app', app_name: string, app_id: number, full_name: string, versions: Array<{ __typename: 'apps_version', version_name: string, version_id: any, updated_at?: any | null, readonly_at?: any | null, deployments: Array<{ __typename: 'apps_deployment', deployed_at?: any | null, deployment_id: number, environment_id?: number | null, environment?: { __typename: 'apps_environment', is_production: boolean, environment_name: string, environment_id: number, updated_at?: any | null } | null }> }> }> };

export type GetAppsEnvironmentQueryVariables = Exact<{
  customerId: Scalars['Int']['input'];
}>;


export type GetAppsEnvironmentQuery = { __typename?: 'query_root', apps_environment: Array<{ __typename?: 'apps_environment', environment_id: number, environment_name: string }> };

export type UpdateMicroAppNameMutationVariables = Exact<{
  appId: Scalars['Int']['input'];
  customerId: Scalars['Int']['input'];
  appName: Scalars['String']['input'];
}>;


export type UpdateMicroAppNameMutation = { __typename?: 'mutation_root', update_apps_app?: { __typename?: 'apps_app_mutation_response', affected_rows: number } | null };


export const GetGraphDocument = gql`
    query getGraph($versionId: bigint!, $appId: Int!) {
  apps_version(
    where: {version_node_instances_aggregate: {count: {predicate: {_gte: 1}}}, _and: {version_id: {_eq: $versionId}, app_id: {_eq: $appId}}}
  ) {
    version_name
    version_node_instances_aggregate {
      aggregate {
        count
      }
    }
    version_node_instances {
      node_number
      outgoing_nodes {
        to_node_numbers
      }
      node_instance {
        id: node_id
        node {
          node_name
          node_type
          id: node_id
        }
      }
    }
  }
}
    `;
export const UpdateNodeTitleDocument = gql`
    mutation updateNodeTitle($title: String!, $nodeId: bigint!) {
  update_apps_node(where: {node_id: {_eq: $nodeId}}, _set: {node_name: $title}) {
    affected_rows
  }
}
    `;
export const UpdateNodeMessagesDocument = gql`
    mutation updateNodeMessages($messages: jsonb!, $nodeId: bigint!) {
  update_apps_node(where: {node_id: {_eq: $nodeId}}, _set: {messages: $messages}) {
    affected_rows
  }
}
    `;
export const UpdateNodeDocument = gql`
    mutation updateNode($nodeNumber: bigint!, $label: String!, $contents: jsonb!) {
  update_apps_node(
    where: {node_id: {_eq: $nodeNumber}}
    _set: {node_name: $label, messages: $contents}
  ) {
    affected_rows
  }
}
    `;
export const GetWorkflowDocument = gql`
    query getWorkflow($appId: Int!) {
  fe_proto_ {
    workflow(where: {app_id: {_eq: $appId}}) {
      id
      name
      app_id
    }
  }
}
    `;
export const GetMicroAppsDocument = gql`
    query getMicroApps($customerId: Int!) {
  apps_app(where: {customer_id: {_eq: $customerId}}) {
    app_name
    app_id
    full_name
    __typename
    versions {
      deployments {
        environment {
          is_production
          environment_name
          environment_id
          updated_at
          __typename
        }
        deployed_at
        deployment_id
        environment_id
        __typename
      }
      version_name
      version_id
      updated_at
      readonly_at
      __typename
    }
  }
}
    `;
export const GetMicroAppsSubscriptionDocument = gql`
    subscription getMicroAppsSubscription($customerId: Int!) {
  apps_app(where: {customer_id: {_eq: $customerId}}) {
    app_name
    app_id
    full_name
    __typename
    versions {
      deployments {
        environment {
          is_production
          environment_name
          environment_id
          updated_at
          __typename
        }
        deployed_at
        deployment_id
        environment_id
        __typename
      }
      version_name
      version_id
      updated_at
      readonly_at
      __typename
    }
  }
}
    `;
export const GetAppsEnvironmentDocument = gql`
    query getAppsEnvironment($customerId: Int!) {
  apps_environment(
    where: {customer_id: {_eq: $customerId}}
    order_by: {is_production: asc}
  ) {
    environment_id
    environment_name
  }
}
    `;
export const UpdateMicroAppNameDocument = gql`
    mutation updateMicroAppName($appId: Int!, $customerId: Int!, $appName: String!) {
  update_apps_app(
    where: {customer_id: {_eq: $customerId}, app_id: {_eq: $appId}}
    _set: {app_name: $appName}
  ) {
    affected_rows
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getGraph(variables: GetGraphQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetGraphQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetGraphQuery>(GetGraphDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getGraph', 'query');
    },
    updateNodeTitle(variables: UpdateNodeTitleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateNodeTitleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateNodeTitleMutation>(UpdateNodeTitleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateNodeTitle', 'mutation');
    },
    updateNodeMessages(variables: UpdateNodeMessagesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateNodeMessagesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateNodeMessagesMutation>(UpdateNodeMessagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateNodeMessages', 'mutation');
    },
    updateNode(variables: UpdateNodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateNodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateNodeMutation>(UpdateNodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateNode', 'mutation');
    },
    getWorkflow(variables: GetWorkflowQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetWorkflowQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetWorkflowQuery>(GetWorkflowDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getWorkflow', 'query');
    },
    getMicroApps(variables: GetMicroAppsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMicroAppsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMicroAppsQuery>(GetMicroAppsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMicroApps', 'query');
    },
    getMicroAppsSubscription(variables: GetMicroAppsSubscriptionSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMicroAppsSubscriptionSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMicroAppsSubscriptionSubscription>(GetMicroAppsSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMicroAppsSubscription', 'subscription');
    },
    getAppsEnvironment(variables: GetAppsEnvironmentQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAppsEnvironmentQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAppsEnvironmentQuery>(GetAppsEnvironmentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAppsEnvironment', 'query');
    },
    updateMicroAppName(variables: UpdateMicroAppNameMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateMicroAppNameMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMicroAppNameMutation>(UpdateMicroAppNameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateMicroAppName', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;