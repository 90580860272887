import { Sender, Machine, Typestate, Interpreter, assign } from 'xstate';
import { urqlGql, GetUserInfoQuery } from '@pypestream/api-services/urql';
import { Session } from '@ory/client';

import { getXstateUtils, MachineStateSchemaPaths } from './xstate.utils';
import {
  GlobalAppUserEvents,
  sendUserMessage,
  SharedEvents,
} from './app.xstate-utils';
import { haveErrors } from '../utils';
import { kratosApi } from '../kratos.api';

// type User = Partial<GetUserInfoQuery['admin_']['currentUser']> & {
//   kratosId?: Session['identity']['id'];
// };

type KratosId = NonNullable<Session['identity']>['id'];

type User = Partial<NonNullable<GetUserInfoQuery['admin_']>['currentUser']> & {
  kratosId?: KratosId;
};

export interface UserContext {
  user:
    | null
    | (User & {
        kratosId?: KratosId;
      });
  kratosId?: KratosId;
  logoutURL?: URL;
}

export interface UserState {
  states: {
    unknown: Record<string, unknown>;
    authError: Record<string, unknown>;
    loggedOut: {
      states: {
        loading: Record<string, unknown>;
        error: Record<string, unknown>;
        loaded: Record<string, unknown>;
      };
    };
    loggedIn: {
      states: {
        loading: Record<string, unknown>;
        error: Record<string, unknown>;
        loaded: Record<string, unknown>;
      };
    };
  };
}

export type UserEvents =
  | GlobalAppUserEvents
  | SharedEvents
  | {
      type: 'user.refetch';
    }
  | {
      type: 'update.userPreferences';
      userActionPayload: Partial<UserContext>;
    };

/** Fetch user from db */
// async function loadUser({
//   userId,
//   email,
// }: {
//   userId?: string;
//   email?: string;
// }): Promise<{
//   firstName?: string;
//   lastName?: string;
//   profilePic?: string;
//   displayName?: string;
// }> {
//   const today = new Date().toISOString();
//   if (!userId) return {};
//   // throw new Error(`Load User missing userId`);
//   if (!email) throw new Error('Load User missing email');
//   try {
//     return {
//       firstName: 'Samantha',
//       lastName: 'Smith',
//       profilePic: '',
//       displayName: 'Sam',
//     };
//   } catch (e) {
//     console.error(e);
//     throw new Error(
//       `Loading User Details failed, userId probably does not exist in our DB: "${userId}""`
//     );
//   }
// }

export interface UserTypestates extends Typestate<UserContext> {
  context: UserContext;
  value: MachineStateSchemaPaths<UserState['states']>;
}

export type UserInterpreter = Interpreter<
  UserContext,
  UserState,
  UserEvents,
  UserTypestates
>;

const { createInvokablePromise, createXstateHooks: createUserXstateHooks } =
  getXstateUtils<UserContext, UserEvents, UserTypestates, UserState>();

export { createUserXstateHooks };

// const userAuthedTransition: TransitionConfig<
//   UserContext,
//   Extract<UserEvents, { type: 'user.authed' }>
// > = {
//   target: 'loggedIn',
//   cond: function hasUseId(ctx, event) {
//     console.log('userAuthedTransition');
//     if (ctx?.user?.userId) {
//       return true;
//     }
//     console.error(
//       'Attempting to transition to state "loggedIn" but the event does not contain userId',
//       { event }
//     );
//     return false;
//   },
//   actions: [
//     assign((ctx, { user }) => {
//       ctx.user = user;
//     }),
//     forwardTo(managerMachine.id),
//   ],
// };

export const userMachine = Machine<UserContext, UserState, UserEvents>({
  type: 'atomic',
  strict: true,
  id: 'user',
  initial: 'unknown',
  predictableActionArguments: true,
  preserveActionOrder: true,
  on: {
    'user.signOut': {
      target: 'loggedOut',
      actions: [
        assign((ctx) => {
          ctx.user = null;
          // Cookies.remove('pypestream-user-info');
          return ctx;
        }),
      ],
    },
  },
  invoke: {
    id: 'userAuthObserver',
    src: (ctx, event) => async (sendEvent: Sender<GlobalAppUserEvents>) => {
      let logoutUrl = import.meta.env.FE_AUTH_URL;

      if (logoutUrl !== undefined && logoutUrl !== '') {
        logoutUrl = new URL(logoutUrl.replaceAll('"', ''));
      }

      if (!logoutUrl) {
        console.info('----Using default logout URL----');
        logoutUrl = new URL('https://login.pypestream.dev/');
      }

      const returnTo = window.location.href;
      logoutUrl.searchParams.append('return_to', returnTo);
      ctx.logoutURL = logoutUrl;
      // window.auth0 = auth0;
      // console.log('userAuthObserver', ctx, event);
      // ctx.user?.userId;
      // if (ctx.user?.userId) {
      // }
      // const cachedUserData = Cookies.get('pypestream-user-info');
      // note #1. just a quick and dirty way to temporarily "cache" our fake user session. normally this revalidation would be handled by a service like Auth0 via a refresh token
      // note #2. the "proper" thing to do would be to do runtime validation on the incoming info before assigning it (ie. via superstruct)
      // note #3: FYI, our placeholder user Context data doesn't contain any sensitive info!)
      // if (cachedUserData !== undefined) {
      //   sendEvent({
      //     type: 'user.signingBackIn',
      //     user: JSON.parse(cachedUserData),
      //   });
      // }
      // example of how we could have a continuously running background task watching for URL query string parameters (ie. from an Auth0 log in callback) which we parse / remove to validate an OK login
      // we'd want to send out events (via the sendEvent function we have available) if, for example, we wanted to move to an error state
    },
  },
  context: {
    user: null,
  },
  states: {
    unknown: {
      always: [
        {
          target: 'loggedOut',
          cond: function userNotLoggedIn(ctx) {
            return ctx?.user?.email === undefined || ctx.user.email === '';
          },
        },
      ],
      on: {
        'user.signIn': {
          target: 'loggedIn',
          actions: [
            assign((ctx, event) => {
              ctx.user = { ...ctx.user, ...event.user };
              return ctx;
            }),
          ],
        },
        'user.authError': {
          target: 'authError',
          actions: (ctx, event) => {
            sendUserMessage({
              type: 'error',
              text: event.errorMsg,
            });
          },
        },
        // 'user.authed': userAuthedTransition,
      },
    },
    authError: {
      id: 'authError',
      always: [
        {
          target: 'loggedOut',
        },
      ],
    },
    loggedOut: {
      initial: 'loading',
      // entry() {
      //   sendGlobalAppEvent({
      //     type: 'user.loggedOut',
      //   });
      // },
      on: {
        'user.signIn': {
          target: 'loggedIn',
          actions: [
            assign((ctx, event) => {
              ctx.user = { ...ctx.user, ...event.user };
              ctx.kratosId = event?.user?.kratosId;

              return ctx;
            }),
          ],
        },
        // 'user.authed': userAuthedTransition,
      },
      states: {
        loading: {
          invoke: createInvokablePromise({
            src: async (ctx, event) => {
              if (event.type !== 'user.signOut' || !ctx.logoutURL) {
                return;
              }

              try {
                const {
                  data: { logout_token: token },
                } = await kratosApi.createBrowserLogoutFlow();

                kratosApi.updateLogoutFlow({
                  token,
                });
              } catch (error) {
                console.log('error while logging out!', error);
              } finally {
                console.log('redirecting to login page...');
                window.location.href = ctx.logoutURL.toJSON();
              }
            },
            onDoneTarget: 'loaded',
            onErrorTarget: 'error',
          }),
        },
        error: {},
        loaded: {},
      },
    },
    // states: {
    //   idle: {
    //     on: {
    //       'user.signIn': {
    //         target: 'loggingIn',
    //         actions: assign((ctx, { email }) => {
    //           console.log('loggingIn', email);
    //           if (ctx.user !== undefined) ctx.user.email = email;
    //           return ctx;
    //         }),
    //       },
    //     },
    //   },

    //     loggingIn: {
    //       invoke: createInvokablePromise({
    //         id: 'loggingIn',
    //         src: async (ctx, event): Promise<void | UserContext['user']> => {
    //           if (event.type === 'user.signIn') {
    //             if (
    //               event.email === 'test@tester.com'
    //               // && event.password === 'studio-2023'
    //             ) {
    //               return {
    //                 ...ctx.user,
    //                 email: event.email,
    //                 userId: '28433274-bf23-435e-9c71-7ecf2e1da6a9',
    //                 info: {
    //                   displayName: 'Bob',
    //                   firstName: 'Robert',
    //                   lastName: 'Smith',
    //                 },
    //               };
    //             } else {
    //               throw Error('wrong username or password');
    //             }
    //           }
    //         },
    //         onDoneTarget: '#loggedIn',
    //         onDoneAssignContext({ ctx, data }) {
    //           console.log('onDoneAssignContext', data);
    //           if (data !== undefined) {
    //             ctx.user = data;
    //           }
    //         },
    //         onErrorTarget: '#authError',
    //         onErrorActions: [
    //           {
    //             type: 'sendUserMessage',
    //             exec(ctx, event) {
    //               sendUserMessage({
    //                 type: 'error',
    //                 text: `Error logging in user: ${event.data.message}`,
    //                 autoClose: 3000,
    //               });
    //             },
    //           },
    //         ],
    //       }),
    //     },
    //   },
    // },
    loggedIn: {
      id: 'loggedIn',
      initial: 'loading',
      entry(context) {
        // sendGlobalAppEvent({
        //   type: 'app.sendUserMessage',
        //   msg: {
        //     text: 'logged in!!',
        //     type: 'success',
        //     autoClose: 1000,
        //   },
        // });
        // Cookies.set('pypestream-user-info', JSON.stringify(context.user));
      },
      exit: [
        assign((ctx) => {
          ctx.user = null;
          return ctx;
        }),
        {
          type: 'removeUserToken',
          exec: () => {
            console.log('remove user token..');
          },
        },
      ],
      on: {
        'user.refetch': {
          target: 'loggedIn',
        },
      },
      states: {
        // @todo: add loading, error, etc for fetching logged in user's profile photo and such
        loading: {
          id: 'loading',
          invoke: createInvokablePromise({
            src: async () => {
              const { data, error } = await urqlGql.getUserInfo().toPromise();

              if (haveErrors(data)) {
                throw new Error(data.errors?.[0]?.message);
              }

              if (error) {
                throw new Error(error.message);
              }

              return data?.admin_?.currentUser;
            },
            onErrorActions: [
              {
                type: 'sendUserMessage',
                exec(ctx, event) {
                  sendUserMessage({
                    type: 'error',
                    text: `Error fetching user info via graphql: ${event.data.message}`,
                    autoClose: 3000,
                  });
                },
              },
            ],
            onDoneTarget: 'loaded',
            onDoneAssignContext({ ctx, data }) {
              if (data) {
                ctx.user = {
                  ...data,
                  kratosId: ctx.kratosId,
                };

                // sendGlobalAppEvent({
                //   type: 'user.loggedIn',
                // });

                return ctx;
              }
              return ctx;
            },
            onErrorTarget: 'error',
          }),
        },
        error: {},
        loaded: {
          on: {
            'update.userPreferences': {
              actions: [
                assign((ctx, event) => {
                  return {
                    user: {
                      ...ctx.user,
                      ...event.userActionPayload?.user,
                    },
                    kratosId: ctx.kratosId,
                  };
                }),
              ],
            },
          },
        },
      },
    },
  },
});
