import { ProjectInfo } from './project-info';
import { ProjectTooling } from './project-tooling';
import { ProjectLocalization } from './project-localization';
import { ProjectCreated } from './project-created';
import { ProjectEnvironments } from './project-environments';

export const CreateProjectSteps = {
  ProjectInfo,
  ProjectTooling,
  ProjectLocalization,
  ProjectCreated,
  ProjectEnvironments,
};
