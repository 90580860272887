/* eslint-disable @typescript-eslint/ban-ts-comment */
export enum ProductName {
  Manager = 'Manager',
  ContactCenter = 'ContactCenter',
  Analytics = 'Analytics',
  Studio = 'Studio',
}

export type BaseURLsToTools = Record<ProductName, string>;

enum Environment {
  Production = 'production',
  Candidate = 'candidate',
  UAT = 'uat',
}

type LowercaseEnum<T> = {
  // @ts-ignore
  [K in keyof T]: Lowercase<T[K]>;
};

export const createEnumObject = (
  enumObj: typeof ProductName
): LowercaseEnum<typeof ProductName> => {
  const result: Partial<LowercaseEnum<typeof ProductName>> = {};

  for (const [key, value] of Object.entries(enumObj)) {
    // @ts-ignore
    result[key as keyof typeof enumObj] = (
      value as string
    ).toLowerCase() as Lowercase<typeof value>;
  }

  return result as LowercaseEnum<typeof ProductName>;
};

const company = 'pypestream';
const topLevelDomains = {
  production: 'com',
  development: 'dev',
};
const managerLocal = 'localhost:5173';
const analyticsLocal = 'localhost.pypestream.dev:8000';

export const generateBaseURLsToTools = (
  location: Location
): BaseURLsToTools => {
  const { host } = location;

  const { Manager, Studio, ContactCenter, Analytics } =
    createEnumObject(ProductName);

  const baseUrls = {
    studioCandidate: `https://${Studio}.${Environment.Candidate}.${company}.${topLevelDomains.development}`,
    studioProd: `https://${Studio}.${company}.${topLevelDomains.production}`,

    contactCenterCandidate: `https://${ContactCenter}.${Environment.Candidate}.${company}.${topLevelDomains.development}`,
    contactCenterProd: `https://${ContactCenter}.${company}.${topLevelDomains.production}`,

    analyticsLocal: `https://${analyticsLocal}`,
    analyticsCandidate: `https://${Analytics}.${Environment.Candidate}.${company}.${topLevelDomains.development}`,
    analyticsProd: `https://${Analytics}.${company}.${topLevelDomains.production}`,

    managerLocal: `http://${managerLocal}`,
    managerCandidate: `https://${Manager}.${company}.${topLevelDomains.development}`,
    managerProd: `https://${Manager}.${company}.${topLevelDomains.production}`,
  };

  const defaultUrls = {
    Studio: baseUrls.studioCandidate,
    ContactCenter: baseUrls.contactCenterCandidate,
    Analytics: baseUrls.analyticsCandidate,
  };

  const prodUrls = {
    Studio: baseUrls.studioProd,
    ContactCenter: baseUrls.contactCenterProd,
    Analytics: baseUrls.analyticsProd,
  };

  const environments = {
    production: {
      Manager: baseUrls.managerProd,
      ...prodUrls,
    },
    candidate: {
      Manager: baseUrls.managerCandidate,
      ...defaultUrls,
    },
    uat: {
      Manager: `https://${Manager}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
      Studio: `https://${Studio}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
      ContactCenter: `https://${ContactCenter}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
      Analytics: `https://${Analytics}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
    },
    preview: (dynamicPart: string | null, appName: ProductName) => ({
      ...defaultUrls,
      Manager:
        dynamicPart && appName === ProductName.Manager
          ? `https://${dynamicPart}.${Manager}.${company}.${topLevelDomains.development}`
          : baseUrls.managerCandidate,
      Analytics:
        dynamicPart && appName === ProductName.Analytics
          ? `https://${Analytics}-pr-${dynamicPart}.preview.${company}.${topLevelDomains.development}`
          : baseUrls.analyticsCandidate,
    }),
    local: (appName: ProductName) => ({
      ...defaultUrls,
      Manager: appName === ProductName.Manager ? '' : baseUrls.managerCandidate,
      Analytics:
        appName === ProductName.Analytics
          ? baseUrls.analyticsLocal
          : baseUrls.analyticsCandidate,
    }),
  };

  if (host.endsWith(`.${company}.${topLevelDomains.production}`)) {
    return environments.production;
  }
  if (
    host === `${Manager}.${company}.${topLevelDomains.development}` ||
    host.endsWith(
      `.${Environment.Candidate}.${company}.${topLevelDomains.development}`
    )
  ) {
    return environments.candidate;
  }
  if (
    host.endsWith(
      `.${Environment.UAT}.${company}.${topLevelDomains.development}`
    )
  ) {
    return environments.uat;
  }

  const commitHashManagerRegex = new RegExp(
    `^[a-z0-9]{10}\\.${Manager}\\.${company}\\.${topLevelDomains.development}$`
  );

  if (commitHashManagerRegex.test(host)) {
    const commitHash = host.split('.')[0];
    return environments.preview(commitHash, ProductName.Manager);
  }

  const analyticsPreviewRegex = new RegExp(
    `^${Analytics}-pr-(\\d+)\\.preview\\.${company}\\.${topLevelDomains.development}$`
  );

  if (analyticsPreviewRegex.test(host)) {
    const match = host.match(analyticsPreviewRegex);
    const prNumber = match ? match[1] : null;
    return environments.preview(prNumber, ProductName.Analytics);
  }
  if (host === managerLocal) {
    return environments.local(ProductName.Manager);
  }

  if (host === analyticsLocal) {
    return environments.local(ProductName.Analytics);
  }

  const productPattern = [...Object.values(createEnumObject(ProductName))].join(
    '|'
  );
  const newEnvironmentRegex = new RegExp(
    `^(${productPattern})\\.([a-z0-9-]+)\\.${company}\\.${topLevelDomains.development}$`
  );
  const newEnvMatch = host.match(newEnvironmentRegex);

  if (newEnvMatch) {
    const product = newEnvMatch[1];
    const environment = newEnvMatch[2];
    const rest = host.split(`${product}.${environment}.`)[1];

    return {
      Manager: `https://${Manager}.${environment}.${rest}`,
      Studio: `https://${Studio}.${environment}.${rest}`,
      ContactCenter: `https://${ContactCenter}.${environment}.${rest}`,
      Analytics: `https://${Analytics}.${environment}.${rest}`,
    };
  }

  return environments.candidate;
};
