/* eslint-disable lit/no-invalid-html */
/* eslint-disable lit/binding-positions */
import { unsafeCSS, PropertyValues } from 'lit';
import { html, literal } from 'lit/static-html.js';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { ResizeController } from '@lit-labs/observers/resize-controller.js';
import debounce from 'lodash.debounce';
import {
  BaseElement,
  customElement,
  SlotObserver,
  PSCustomEvent,
} from '../../../components/base-element';
import '../../../components/icon/icon.wc';
import { ToggleWC } from '../../../components/toggle/toggle.wc';
import styles from './applet-card.scss?inline';

@customElement('ps-applet-card')
export class AppletCardWC extends SlotObserver(BaseElement, [
  '[slot]',
  '[slot="header"]',
  '[slot="footer"]',
]) {
  static styles = unsafeCSS(styles);

  /** Draws the toggle in a checked state. */
  @property({ type: Boolean, reflect: true }) checked = false;

  /** When set, applet-card will be rendered as an `<a>` tag. */
  @property({
    reflect: true,
  })
  href?: string;

  /** If true, applet-card will be rendered as an `<button>` tag */
  @property({ type: Boolean, reflect: true }) interactive = false;

  /** The applet-card's error state. */
  @property({ type: Boolean, attribute: 'has-error' }) hasError = false;

  /** The applet-card's disabled state. */
  @property({ type: Boolean, reflect: true }) disabled = false;

  /** Disables responsive behaviour. */
  @property({ type: Boolean, reflect: true }) vertical = false;

  @property({ type: Number }) cardWidth: number;

  @query('.c-applet-card') card: HTMLDivElement;

  breakpoint: number = 768;

  protected updated(_changedProperties: PropertyValues): void {
    super.updated(_changedProperties);

    if (_changedProperties.has('cardWidth')) {
      this.toogleCardBreakpoint();
    }
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    this.toogleCardBreakpoint();
  }

  toogleCardBreakpoint() {
    if (!this.cardWidth) return;

    if (this.cardWidth >= this.breakpoint) {
      this.card.classList.add('c-applet-card--bp-medium');
    } else {
      this.card.classList.remove('c-applet-card--bp-medium');
    }
  }

  handleToggleClick(e: PSCustomEvent<ToggleWC, { checked: boolean }>) {
    this.emit('toggle-click', {
      detail: { checked: e.detail.checked },
    });
  }

  private observerCallback = (value: ResizeObserverEntry[]) => {
    if (!value) return;
    const width = value[0]?.contentRect?.width;

    if (width) {
      this.cardWidth = width;
    }
  };

  private _observer = new ResizeController(this, {
    target: this,
    skipInitial: false,
    callback: debounce(this.observerCallback, 20),
  });

  render() {
    const isLink = !!this.href;
    // eslint-disable-next-line no-nested-ternary
    const tag = isLink
      ? literal`a`
      : this.interactive
        ? literal`button`
        : literal`div`;

    const isInteractive = !this.disabled && (this.interactive || isLink);

    const wrapperClasses = classMap({
      'c-applet-card': true,
      'c-applet-card--interactive': isInteractive,
      'c-applet-card--disabled': this.disabled,
      'c-applet-card--has-error': this.hasError,
      'c-applet-card--responsive': !this.vertical,
    });

    return html`
      <div class=${wrapperClasses}>
        <div class="c-applet-card__content">
          <${tag} class="c-applet-card__tag" href=${ifDefined(isLink && !this.disabled ? this.href : undefined)}>
            <slot></slot>
          </${tag}>
        </div>
        <div class="c-applet-card__footer">
          <slot name="footer"></slot>
          <ps-toggle size="large"
            ?disabled=${this.disabled} ?checked=${this.checked} @change=${this.handleToggleClick} class="c-applet-card__toggle"></ps-toggle>
        </div>
        <div class="c-applet-card__action">
          <ps-icon name="settings" size="small" color="gray"></ps-icon>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-applet-card': AppletCardWC;
  }
}
